import dayjs from 'dayjs';
import { cloneDeep } from 'lodash-es';
import { MorningHuddleTypes } from '../morning-huddle';
import { PracticeAnalyticsTypes } from '.';

type Data<T> = { data: Record<string, T | undefined> } | null | undefined;

const defaultTreatmentTotals = {
  accepted: 0,
  acceptedPercent: 0,
  diagnosed: 0,
  diagnosedPercent: 0,
  qualifiedVisits: 0,
  unscheduledTreatment: 0,
};

const appendLocationIdToPatients = (
  patients: PracticeAnalyticsTypes.PatientInfo[],
  locationId: string,
  initialList?: PracticeAnalyticsTypes.PatientInfo[]
) =>
  patients.reduce((acc, patient) => {
    acc.push({
      ...patient,
      locationId,
    });
    return acc;
  }, initialList || []);

const aggregatedHistoricalData = (
  accBuckets: PracticeAnalyticsTypes.HistoricalDataTotal[],
  buckets: PracticeAnalyticsTypes.HistoricalDataTotal[],
  locationId?: string
) => {
  return buckets.reduce((acc, { label, persons, total }) => {
    const lCaseLabel = label.toLowerCase();
    const index = acc.findIndex((item) => item.label.toLowerCase() === lCaseLabel);
    if (index > -1) {
      acc[index].total += total;
      acc[index].persons = [
        ...(acc[index].persons || []),
        ...(persons || []).map((personDetails) => ({ ...personDetails, locationId })),
      ];
    } else {
      acc.push({
        label: lCaseLabel,
        persons: persons?.map((personDetails) => ({ ...personDetails, locationId })),
        total,
      });
    }

    return acc;
  }, accBuckets);
};

const aggregatePatients = (
  classification: PracticeAnalyticsTypes.OpportunityPatientClassificationEnum,
  initialList: PracticeAnalyticsTypes.PatientInfo[],
  locationId: string,
  patients: PracticeAnalyticsTypes.PatientInfo[]
): PracticeAnalyticsTypes.PatientInfo[] =>
  patients.reduce((acc, patient) => {
    acc.push({
      ...patient,
      locationId,
      classification,
    });
    return acc;
  }, initialList);

export const activePatientsSummary = (data?: Data<PracticeAnalyticsTypes.ActivePatientsResponse>) => {
  return Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { location } = locationDetails || {};
      const { activePatients } = location || {};

      if (activePatients?.total) {
        acc.activePatients.total += activePatients.total;
      }

      if (activePatients?.historicalData) {
        // Historical data is expected to be an array of 24 weeks
        // First 12 weeks are for previous period and next 12 weeks are for current period
        // Club the historical data for matching label and timestamp
        acc.activePatients.historicalData = activePatients.historicalData?.reduce(
          (acc, { label, timestamp, total }) => {
            const index = acc.findIndex((item) => item.label === label && item.timestamp === timestamp);
            if (index > -1) {
              acc[index].total += total;
            } else {
              acc.push({ label, timestamp, total });
            }
            return acc;
          },
          acc.activePatients.historicalData || []
        );
      }

      return acc;
    },
    {
      activePatients: {
        historicalData: [] as PracticeAnalyticsTypes.HistoricalDataTotal[],
        total: 0,
      },
    }
  );
};

export const hygieneTreatmentPlanSummary = (data?: Data<PracticeAnalyticsTypes.HygieneTreatmentPlanResponse>) => {
  // Update rawData to correct percentage values
  const rawData: Record<string, PracticeAnalyticsTypes.HygieneTreatmentPlanResponse> = Object.entries(
    cloneDeep(data?.data) || {}
  ).reduce((acc, [locationId, locationDetails]) => {
    const { totals } = locationDetails?.location?.hygieneTreatmentPlan || {};
    const { accepted = 0, diagnosed = 0, qualifiedVisits = 0 } = totals || {};

    return {
      ...acc,
      [locationId]: {
        ...locationDetails,
        location: {
          ...(locationDetails?.location || {}),
          hygieneTreatmentPlan: {
            ...(locationDetails?.location?.hygieneTreatmentPlan || {}),
            totals: {
              ...(totals || {}),
              acceptedPercent: accepted / (diagnosed || 1),
              diagnosedPercent: diagnosed / (qualifiedVisits || 1),
            },
          },
        },
      },
    };
  }, {});

  const aggregatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { location } = locationDetails || {};
      const { hygieneTreatmentPlan, industryAvg } = location || {};
      const { benchmarks, totals } = hygieneTreatmentPlan || {};
      const { accepted = 0, diagnosed = 0, qualifiedVisits = 0, unscheduledTreatment = 0 } = totals || {};

      acc.hygieneTreatmentPlan.totals.accepted += accepted;
      acc.hygieneTreatmentPlan.totals.diagnosed += diagnosed;
      acc.hygieneTreatmentPlan.totals.qualifiedVisits += qualifiedVisits;
      acc.hygieneTreatmentPlan.totals.unscheduledTreatment += unscheduledTreatment;

      if (benchmarks) {
        // Benchmarks will be same for all locations so we don't need to aggregate it
        acc.hygieneTreatmentPlan.benchmarks = benchmarks;
      }

      if (industryAvg) {
        // Industry average will be same for all locations so we don't need to aggregate it
        acc.industryAvg = industryAvg;
      }

      return acc;
    },
    {
      hygieneTreatmentPlan: {
        benchmarks: [] as PracticeAnalyticsTypes.Benchmark[],
        totals: {
          ...defaultTreatmentTotals,
        },
      },
      industryAvg: {
        totals: {
          totalAcceptedHygiene: 0,
          totalDiagnosedHygiene: 0,
          totalUnscheduledTreatmentHygiene: 0,
        },
      },
    }
  );

  aggregatedData.hygieneTreatmentPlan.totals.acceptedPercent =
    aggregatedData.hygieneTreatmentPlan.totals.accepted / (aggregatedData.hygieneTreatmentPlan.totals.diagnosed || 1);

  aggregatedData.hygieneTreatmentPlan.totals.diagnosedPercent =
    aggregatedData.hygieneTreatmentPlan.totals.diagnosed /
    (aggregatedData.hygieneTreatmentPlan.totals.qualifiedVisits || 1);

  return {
    aggregatedData,
    rawData,
  };
};

export const restorativeTreatmentPlanSummary = (
  data?: Data<PracticeAnalyticsTypes.RestorativeTreatmentPlanResponse>
) => {
  // Update rawData to correct percentage values
  const rawData: Record<string, PracticeAnalyticsTypes.RestorativeTreatmentPlanResponse> = Object.entries(
    cloneDeep(data?.data) || {}
  ).reduce((acc, [locationId, locationDetails]) => {
    const { totals } = locationDetails?.location?.restorativeTreatmentPlan || {};
    const { accepted = 0, diagnosed = 0, qualifiedVisits = 0 } = totals || {};

    return {
      ...acc,
      [locationId]: {
        ...locationDetails,
        location: {
          ...(locationDetails?.location || {}),
          restorativeTreatmentPlan: {
            ...(locationDetails?.location?.restorativeTreatmentPlan || {}),
            totals: {
              ...(totals || {}),
              acceptedPercent: accepted / (diagnosed || 1),
              diagnosedPercent: diagnosed / (qualifiedVisits || 1),
            },
          },
        },
      },
    };
  }, {});

  const aggregatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { location } = locationDetails || {};
      const { industryAvg, restorativeTreatmentPlan } = location || {};
      const { benchmarks, totals } = restorativeTreatmentPlan || {};
      const { accepted = 0, diagnosed = 0, qualifiedVisits = 0, unscheduledTreatment = 0 } = totals || {};

      acc.restorativeTreatmentPlan.totals.accepted += accepted;
      acc.restorativeTreatmentPlan.totals.diagnosed += diagnosed;
      acc.restorativeTreatmentPlan.totals.qualifiedVisits += qualifiedVisits;
      acc.restorativeTreatmentPlan.totals.unscheduledTreatment += unscheduledTreatment;

      if (benchmarks) {
        // Benchmarks will be same for all locations so we don't need to aggregate it
        acc.restorativeTreatmentPlan.benchmarks = benchmarks;
      }

      if (industryAvg) {
        // Industry average will be same for all locations so we don't need to aggregate it
        acc.industryAvg = industryAvg;
      }

      return acc;
    },
    {
      industryAvg: {
        totals: {
          totalAcceptedRestorative: 0,
          totalDiagnosedRestorative: 0,
          totalUnscheduledTreatmentRestorative: 0,
        },
      },
      restorativeTreatmentPlan: {
        benchmarks: [] as PracticeAnalyticsTypes.Benchmark[],
        totals: {
          ...defaultTreatmentTotals,
        },
      },
    }
  );

  aggregatedData.restorativeTreatmentPlan.totals.acceptedPercent =
    aggregatedData.restorativeTreatmentPlan.totals.accepted /
    (aggregatedData.restorativeTreatmentPlan.totals.diagnosed || 1);

  aggregatedData.restorativeTreatmentPlan.totals.diagnosedPercent =
    aggregatedData.restorativeTreatmentPlan.totals.diagnosed /
    (aggregatedData.restorativeTreatmentPlan.totals.qualifiedVisits || 1);

  return {
    aggregatedData,
    rawData,
  };
};

export const pratitionerAnalysisSummary = (data?: Data<PracticeAnalyticsTypes.PractitionerAnalysisResponse>) => {
  // Update rawData to correct percentage values
  const rawData: Record<string, PracticeAnalyticsTypes.PractitionerAnalysisResponse> = Object.entries(
    cloneDeep(data?.data) || {}
  ).reduce((acc, [locationId, locationDetails]) => {
    const { totals: hygieneTotals } = locationDetails?.location?.hygieneTreatmentPlan || {};
    const { totals: restorativeTotals } = locationDetails?.location?.restorativeTreatmentPlan || {};

    return {
      ...acc,
      [locationId]: {
        ...locationDetails,
        location: {
          ...(locationDetails?.location || {}),
          hygieneTreatmentPlan: {
            ...(locationDetails?.location?.hygieneTreatmentPlan || {}),
            totals: {
              ...(hygieneTotals || {}),
              acceptedPercent: (hygieneTotals?.accepted || 0) / (hygieneTotals?.diagnosed || 1),
              diagnosedPercent: (hygieneTotals?.diagnosed || 0) / (hygieneTotals?.qualifiedVisits || 1),
            },
          },
          restorativeTreatmentPlan: {
            ...(locationDetails?.location?.restorativeTreatmentPlan || {}),
            totals: {
              ...(restorativeTotals || {}),
              acceptedPercent: (restorativeTotals?.accepted || 0) / (restorativeTotals?.diagnosed || 1),
              diagnosedPercent: (restorativeTotals?.diagnosed || 0) / (restorativeTotals?.qualifiedVisits || 1),
            },
          },
        },
      },
    };
  }, {});

  const aggregatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { location } = locationDetails || {};
      const { hygieneTreatmentPlan, restorativeTreatmentPlan } = location || {};

      acc.hygieneTreatmentPlan.totals.accepted += hygieneTreatmentPlan?.totals?.accepted || 0;
      acc.hygieneTreatmentPlan.totals.diagnosed += hygieneTreatmentPlan?.totals?.diagnosed || 0;
      acc.hygieneTreatmentPlan.totals.qualifiedVisits += hygieneTreatmentPlan?.totals?.qualifiedVisits || 0;
      acc.hygieneTreatmentPlan.totals.unscheduledTreatment += hygieneTreatmentPlan?.totals?.unscheduledTreatment || 0;

      acc.restorativeTreatmentPlan.totals.accepted += restorativeTreatmentPlan?.totals?.accepted || 0;
      acc.restorativeTreatmentPlan.totals.diagnosed += restorativeTreatmentPlan?.totals?.diagnosed || 0;
      acc.restorativeTreatmentPlan.totals.qualifiedVisits += restorativeTreatmentPlan?.totals?.qualifiedVisits || 0;
      acc.restorativeTreatmentPlan.totals.unscheduledTreatment +=
        restorativeTreatmentPlan?.totals?.unscheduledTreatment || 0;

      return acc;
    },
    {
      hygieneTreatmentPlan: {
        totals: {
          ...defaultTreatmentTotals,
        },
      },
      restorativeTreatmentPlan: {
        totals: {
          ...defaultTreatmentTotals,
        },
      },
    }
  );

  aggregatedData.hygieneTreatmentPlan.totals.acceptedPercent =
    aggregatedData.hygieneTreatmentPlan.totals.accepted / (aggregatedData.hygieneTreatmentPlan.totals.diagnosed || 1);

  aggregatedData.hygieneTreatmentPlan.totals.diagnosedPercent =
    aggregatedData.hygieneTreatmentPlan.totals.diagnosed /
    (aggregatedData.hygieneTreatmentPlan.totals.qualifiedVisits || 1);

  aggregatedData.restorativeTreatmentPlan.totals.acceptedPercent =
    aggregatedData.restorativeTreatmentPlan.totals.accepted /
    (aggregatedData.restorativeTreatmentPlan.totals.diagnosed || 1);

  aggregatedData.restorativeTreatmentPlan.totals.diagnosedPercent =
    aggregatedData.restorativeTreatmentPlan.totals.diagnosed /
    (aggregatedData.restorativeTreatmentPlan.totals.qualifiedVisits || 1);

  return {
    aggregatedData,
    rawData,
  };
};

export const cancellationsSummary = (data?: Data<PracticeAnalyticsTypes.CancellationsResponse>) => {
  const aggregatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { missedAppointments } = locationDetails?.location || {};
      const {
        cancelled,
        cancelledUnscheduled,
        recapturedProduction,
        rescheduled,
        totalPersonsCount,
        totalProduction,
        totalProductionRisk,
        unscheduledOpportunity,
      } = missedAppointments?.totals || {};

      acc.missedAppointments.totals.cancelled += cancelled || 0;
      acc.missedAppointments.totals.cancelledUnscheduled += cancelledUnscheduled || 0;
      acc.missedAppointments.totals.recapturedProduction += recapturedProduction || 0;
      acc.missedAppointments.totals.rescheduled += rescheduled || 0;
      acc.missedAppointments.totals.totalPersonsCount += totalPersonsCount || 0;
      acc.missedAppointments.totals.totalProduction += totalProduction || 0;
      acc.missedAppointments.totals.totalProductionRisk += totalProductionRisk || 0;
      acc.missedAppointments.totals.unscheduledOpportunity += unscheduledOpportunity || 0;

      return acc;
    },
    {
      missedAppointments: {
        totals: {
          cancelled: 0,
          cancelledUnscheduled: 0,
          recapturedProduction: 0,
          rescheduled: 0,
          totalPersonsCount: 0,
          totalProduction: 0,
          totalProductionRisk: 0,
          unscheduledOpportunity: 0,
        },
      },
    }
  );

  return {
    aggregatedData,
    rawData: data?.data,
  };
};

export const hygieneFollowUpSummary = (data?: Data<PracticeAnalyticsTypes.HygieneFollowUpResponse>) => {
  const aggregatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { location } = locationDetails || {};
      const { hygieneReappointment, industryAvg } = location || {};
      const { hygieneReappointments, patients, rescheduledSameDay, unscheduled } = hygieneReappointment?.totals || {};

      if (hygieneReappointments) {
        // Club the hygiene reappointments data for matching date
        acc.hygieneReappointment.totals.hygieneReappointments = hygieneReappointments.reduce(
          (acc1, { date, ...rest }) => {
            const existingIndex = acc1.findIndex((item) => item.date === date);
            if (existingIndex > -1) {
              acc1[existingIndex] = {
                ...acc1[existingIndex],
                ...rest,
              };
            } else {
              acc1.push({ date, ...rest });
            }
            return acc1;
          },
          acc.hygieneReappointment.totals.hygieneReappointments || []
        );

        // Sort the data by date in ascending order
        acc.hygieneReappointment.totals.hygieneReappointments.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
      }

      if (patients) {
        acc.hygieneReappointment.totals.patients += patients;
      }

      if (rescheduledSameDay) {
        acc.hygieneReappointment.totals.rescheduledSameDay += rescheduledSameDay;
      }

      if (unscheduled) {
        acc.hygieneReappointment.totals.unscheduled += unscheduled;
      }

      if (industryAvg) {
        // Industry average will be same for all locations so we don't need to aggregate it
        acc.industryAvg = industryAvg;
      }

      return acc;
    },
    {
      hygieneReappointment: {
        totals: {
          hygieneReappointments: [] as PracticeAnalyticsTypes.HygieneReappointmentBreakdown[],
          patients: 0,
          rescheduledSameDay: 0,
          unscheduled: 0,
        },
      },
      industryAvg: {
        totals: {
          hygieneFollowUpNotYetRescheduled: 0,
          hygieneFollowUpReappointmentSameDay: 0,
          hygieneFollowUpTotalVisits: 0,
        },
      },
    }
  );

  return {
    aggregatedData,
    rawData: data?.data,
  };
};

export const newPatientsSummary = (data?: Data<PracticeAnalyticsTypes.NewPatientsTrend>) => {
  const aggregatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { newPatients } = locationDetails?.location || {};
      const { historicalData, totals } = newPatients || {};
      const { patients, production, productionPerDay, unscheduledProduction, unscheduledProductionPerDay } =
        totals || {};

      historicalData?.forEach(({ label, timestamp, total }) => {
        const timestampMod = dayjs(timestamp).format('MM/DD/YYYY'); // Matching date format with other data objects in the response
        const index = acc.newPatients.historicalData.findIndex((item) => item.timestamp === timestampMod);
        if (index === -1) {
          acc.newPatients.historicalData.push({ total, label, timestamp: timestampMod });
        } else {
          acc.newPatients.historicalData[index].total += total;
        }
      });

      if (patients) {
        acc.newPatients.totals.patients += patients;
      }

      if (production) {
        acc.newPatients.totals.production += production;
      }

      if (unscheduledProduction) {
        acc.newPatients.totals.unscheduledProduction += unscheduledProduction;
      }

      productionPerDay?.forEach(({ date, amount }) => {
        const index = acc.newPatients.totals.productionPerDay.findIndex((item) => item.date === date);
        if (index === -1) {
          acc.newPatients.totals.productionPerDay.push({ date, amount });
        } else {
          acc.newPatients.totals.productionPerDay[index].amount += amount;
        }
      });

      unscheduledProductionPerDay?.forEach(({ date, amount }) => {
        const index = acc.newPatients.totals.unscheduledProductionPerDay.findIndex((item) => item.date === date);
        if (index === -1) {
          acc.newPatients.totals.unscheduledProductionPerDay.push({ date, amount });
        } else {
          acc.newPatients.totals.unscheduledProductionPerDay[index].amount += amount;
        }
      });

      // Sort required data by date in ascending order
      acc.newPatients.historicalData.sort((a, b) => dayjs(a.timestamp).diff(dayjs(b.timestamp)));
      acc.newPatients.totals.productionPerDay.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));
      acc.newPatients.totals.unscheduledProductionPerDay.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

      return acc;
    },
    {
      newPatients: {
        historicalData: [] as PracticeAnalyticsTypes.HistoricalDataTotal[],
        totals: {
          patients: 0,
          production: 0,
          productionPerDay: [] as PracticeAnalyticsTypes.ProductionPerDay[],
          unscheduledProduction: 0,
          unscheduledProductionPerDay: [] as PracticeAnalyticsTypes.ProductionPerDay[],
        },
      },
    }
  );

  return {
    aggregatedData,
    rawData: data?.data,
  };
};

export const recapturedPatientsSummary = (
  data?: Data<PracticeAnalyticsTypes.RecapturedPatientsTrend>,
  locationsCount?: number
) => {
  const aggregatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { recapturedPatients } = locationDetails?.location || {};
      const { totals } = recapturedPatients || {};

      if (recapturedPatients?.historicalData) {
        // Club the historical data for matching timestamps and labels
        acc.recapturedPatients.historicalData = recapturedPatients.historicalData.reduce(
          (acc, { label, leadTime = 0, production = 0, timestamp, total = 0 }) => {
            const index = acc.findIndex((item) => item.label === label && item.timestamp === timestamp);
            if (index >= 0) {
              acc[index].leadTime = (acc[index].leadTime ?? 0) + leadTime;
              acc[index].production = (acc[index].production ?? 0) + production;
              acc[index].total += total;
            } else {
              acc.push({ label, leadTime, production: production, timestamp, total });
            }

            return acc;
          },
          acc.recapturedPatients.historicalData
        );
      }

      if (totals?.leadTime) {
        acc.recapturedPatients.totals.leadTime += totals.leadTime;
      }

      if (totals?.patients) {
        acc.recapturedPatients.totals.patients += totals.patients;
      }

      if (totals?.production) {
        acc.recapturedPatients.totals.production += totals.production;
      }

      return acc;
    },
    {
      recapturedPatients: {
        historicalData: [] as PracticeAnalyticsTypes.HistoricalDataTotal[],
        totals: {
          leadTime: 0,
          patients: 0,
          production: 0,
        },
      },
    }
  );

  // Find the average of the lead time as per the selected number of locations
  aggregatedData.recapturedPatients.historicalData = aggregatedData.recapturedPatients.historicalData.map(
    ({ leadTime = 0, ...rest }) => ({
      ...rest,
      leadTime: leadTime / (locationsCount || 1),
    })
  );
  aggregatedData.recapturedPatients.totals.leadTime /= locationsCount || 1;

  return {
    aggregatedData,
    rawData: data?.data,
  };
};

export const activePatientsDetails = (
  data?: Data<PracticeAnalyticsTypes.ActivePatientsDemographicsResponse>,
  locationsCount?: number
) => {
  // Update raw data to fix the insurance providers data labels and merge duplicate values
  const rawData: Data<PracticeAnalyticsTypes.ActivePatientsDemographicsResponse> = {
    data: Object.entries(cloneDeep(data?.data) || {}).reduce((acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { patientDemographics } = location || {};

      if (patientDemographics?.details?.insuranceProviders) {
        acc = {
          ...acc,
          [locationId]: {
            ...locationDetails,
            location: {
              ...location,
              patientDemographics: {
                ...location?.patientDemographics,
                details: {
                  insuranceProviders: aggregatedHistoricalData([], patientDemographics.details.insuranceProviders),
                },
              },
            },
          },
        };
      }

      return acc;
    }, {}),
  };

  const aggregatedData = Object.entries(cloneDeep(rawData.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { activePatients, activePatientsScheduled, patientDemographics } = location || {};

      if (activePatients) {
        acc.activePatients.total += activePatients.total;
      }

      if (activePatientsScheduled?.percentage) {
        acc.activePatientsScheduled.percentage += activePatientsScheduled.percentage;
      }

      if (patientDemographics) {
        const { buckets, details } = patientDemographics;

        // Collect all buckets and subbuckets by their labels
        acc.patientDemographics.buckets =
          buckets?.reduce((acc1, { label, total, subbuckets }) => {
            const index = acc1.findIndex((item) => item.label === label);
            if (index > -1) {
              acc1[index].total += total;
              acc1[index].subbuckets = aggregatedHistoricalData(acc1[index].subbuckets, subbuckets, locationId);
            } else {
              acc1.push({
                label,
                total,
                subbuckets: aggregatedHistoricalData([], subbuckets, locationId),
              });
            }
            return acc1;
          }, acc.patientDemographics.buckets || []) || [];

        if (details?.insuranceProviders) {
          acc.patientDemographics.details.insuranceProviders = aggregatedHistoricalData(
            acc.patientDemographics.details.insuranceProviders,
            details.insuranceProviders,
            locationId
          );
        }
      }

      return acc;
    },
    {
      activePatients: {
        total: 0,
      },
      activePatientsScheduled: {
        percentage: 0,
      },
      patientDemographics: {
        buckets: [] as PracticeAnalyticsTypes.Buckets[],
        details: {
          insuranceProviders: [] as PracticeAnalyticsTypes.HistoricalDataTotal[],
        },
      },
    }
  );

  // Update average percentage based on number of locations
  aggregatedData.activePatientsScheduled.percentage /= locationsCount || 1;

  return {
    aggregatedData,
    rawData: rawData.data,
  };
};

export const activePatientsScheduledDetails = (
  data?: Data<PracticeAnalyticsTypes.ActivePatientsDemographicsScheduledResponse>,
  locationsCount?: number
) => {
  // Update raw data to fix the buckets data labels and merge duplicate values
  const rawData: Data<PracticeAnalyticsTypes.ActivePatientsDemographicsScheduledResponse> = {
    data: Object.entries(cloneDeep(data?.data) || {}).reduce((acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { unscheduledHygienePatients, unscheduledNonhygienePatients } = location || {};

      if (unscheduledHygienePatients && unscheduledNonhygienePatients) {
        acc = {
          ...acc,
          [locationId]: {
            ...locationDetails,
            location: {
              ...location,
              unscheduledHygienePatients: {
                ...unscheduledHygienePatients,
                buckets: aggregatedHistoricalData([], unscheduledHygienePatients.buckets),
              },
              unscheduledNonhygienePatients: {
                ...unscheduledNonhygienePatients,
                buckets: aggregatedHistoricalData([], unscheduledNonhygienePatients.buckets),
              },
            },
          },
        };
      }

      return acc;
    }, {}),
  };

  const aggregatedData = Object.entries(cloneDeep(rawData.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { activePatients, activePatientsScheduled, unscheduledHygienePatients, unscheduledNonhygienePatients } =
        location || {};

      if (activePatients) {
        acc.activePatients.total += activePatients.total;
      }

      if (activePatientsScheduled?.percentage) {
        acc.activePatientsScheduled.percentage += activePatientsScheduled.percentage;
      }

      if (unscheduledHygienePatients) {
        const { buckets, totals } = unscheduledHygienePatients;

        acc.unscheduledHygienePatients.buckets = aggregatedHistoricalData(
          acc.unscheduledHygienePatients.buckets,
          buckets,
          locationId
        );
        acc.unscheduledHygienePatients.totals.patients += totals.patients;
      }

      if (unscheduledNonhygienePatients) {
        const { buckets, totals } = unscheduledNonhygienePatients;

        acc.unscheduledNonhygienePatients.buckets = aggregatedHistoricalData(
          acc.unscheduledNonhygienePatients.buckets,
          buckets,
          locationId
        );
        acc.unscheduledNonhygienePatients.totals.patients += totals.patients;
      }

      return acc;
    },
    {
      activePatients: {
        total: 0,
      },
      activePatientsScheduled: {
        percentage: 0,
      },
      unscheduledHygienePatients: {
        buckets: [] as PracticeAnalyticsTypes.HistoricalDataTotal[],
        totals: {
          patients: 0,
        },
      },
      unscheduledNonhygienePatients: {
        buckets: [] as PracticeAnalyticsTypes.HistoricalDataTotal[],
        totals: {
          patients: 0,
        },
      },
    }
  );

  // Update average percentage based on number of locations
  aggregatedData.activePatientsScheduled.percentage /= locationsCount || 1;

  return {
    aggregatedData,
    rawData: rawData.data,
  };
};

export const hygieneTreatmentPlanDetails = (data?: Data<PracticeAnalyticsTypes.HygieneTreatmentPlanResponse>) => {
  const updatedData = Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { hygieneTreatmentPlan } = location || {};
      const { details, totals } = hygieneTreatmentPlan || {};
      const { accepted = 0, diagnosed = 0, qualifiedVisits = 0, unscheduledTreatment = 0 } = totals || {};

      acc.hygieneTreatmentPlan.totals.accepted += accepted;
      acc.hygieneTreatmentPlan.totals.diagnosed += diagnosed;
      acc.hygieneTreatmentPlan.totals.qualifiedVisits += qualifiedVisits;
      acc.hygieneTreatmentPlan.totals.unscheduledTreatment += unscheduledTreatment;

      // Club patient details in a single array and include relevant location ids to the data objects
      if (details?.patients) {
        acc.hygieneTreatmentPlan.details.patients = details.patients.reduce((acc, patient) => {
          acc.push({
            ...patient,
            locationId,
          });
          return acc;
        }, acc.hygieneTreatmentPlan.details.patients);
      }

      return acc;
    },
    {
      hygieneTreatmentPlan: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          ...defaultTreatmentTotals,
        },
      },
    }
  );

  updatedData.hygieneTreatmentPlan.totals.acceptedPercent =
    updatedData.hygieneTreatmentPlan.totals.accepted / (updatedData.hygieneTreatmentPlan.totals.diagnosed || 1);

  updatedData.hygieneTreatmentPlan.totals.diagnosedPercent =
    updatedData.hygieneTreatmentPlan.totals.diagnosed / (updatedData.hygieneTreatmentPlan.totals.qualifiedVisits || 1);

  return updatedData;
};

export const restorativeTreatmentPlanDetails = (
  data?: Data<PracticeAnalyticsTypes.RestorativeTreatmentPlanResponse>
) => {
  const updatedData = Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { restorativeTreatmentPlan } = location || {};
      const { details, totals } = restorativeTreatmentPlan || {};
      const { accepted = 0, diagnosed = 0, qualifiedVisits = 0, unscheduledTreatment = 0 } = totals || {};

      acc.restorativeTreatmentPlan.totals.accepted += accepted;
      acc.restorativeTreatmentPlan.totals.diagnosed += diagnosed;
      acc.restorativeTreatmentPlan.totals.qualifiedVisits += qualifiedVisits;
      acc.restorativeTreatmentPlan.totals.unscheduledTreatment += unscheduledTreatment;

      // Club patient details in a single array and include relevant location ids to the data objects
      if (acc.restorativeTreatmentPlan.details && details?.patients) {
        acc.restorativeTreatmentPlan.details.patients = details.patients.reduce((acc, patient) => {
          acc.push({
            ...patient,
            locationId,
          });
          return acc;
        }, acc.restorativeTreatmentPlan.details.patients);
      }

      return acc;
    },
    {
      restorativeTreatmentPlan: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          ...defaultTreatmentTotals,
        },
      },
    }
  );

  updatedData.restorativeTreatmentPlan.totals.acceptedPercent =
    updatedData.restorativeTreatmentPlan.totals.accepted / (updatedData.restorativeTreatmentPlan.totals.diagnosed || 1);

  updatedData.restorativeTreatmentPlan.totals.diagnosedPercent =
    updatedData.restorativeTreatmentPlan.totals.diagnosed /
    (updatedData.restorativeTreatmentPlan.totals.qualifiedVisits || 1);

  return updatedData;
};

export const cancellationsDetails = (data?: Data<PracticeAnalyticsTypes.CancellationsResponse>) => {
  return Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { missedAppointments } = locationDetails?.location || {};
      const { cancelled, cancelledUnscheduled, unscheduledOpportunity } = missedAppointments?.totals || {};

      acc.missedAppointments.totals.cancelled += cancelled || 0;
      acc.missedAppointments.totals.cancelledUnscheduled += cancelledUnscheduled || 0;
      acc.missedAppointments.totals.unscheduledOpportunity += unscheduledOpportunity || 0;

      // Club patient details in a single array and include relevant location ids to the data objects
      if (missedAppointments?.details?.patients) {
        acc.missedAppointments.details.patients = missedAppointments.details.patients.reduce((acc1, patient) => {
          acc1.push({
            ...patient,
            locationId,
          });
          return acc1;
        }, acc.missedAppointments.details.patients);
      }

      return acc;
    },
    {
      missedAppointments: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          cancelled: 0,
          cancelledUnscheduled: 0,
          unscheduledOpportunity: 0,
        },
      },
    }
  );
};

export const hygieneFollowUpDetails = (data?: Data<PracticeAnalyticsTypes.HygieneFollowUpResponse>) => {
  return Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { hygieneReappointment } = location || {};
      const { details, totals } = hygieneReappointment || {};
      const { patients, rescheduledSameDay, unscheduled } = totals || {};

      if (patients) {
        acc.hygieneReappointment.totals.patients += patients;
      }

      if (rescheduledSameDay) {
        acc.hygieneReappointment.totals.rescheduledSameDay += rescheduledSameDay;
      }

      if (unscheduled) {
        acc.hygieneReappointment.totals.unscheduled += unscheduled;
      }

      // Club patient details in a single array and include relevant location ids to the data objects
      if (details?.patients) {
        acc.hygieneReappointment.details.patients = details.patients.reduce((acc, patient) => {
          acc.push({
            ...patient,
            locationId,
          });
          return acc;
        }, acc.hygieneReappointment.details.patients);
      }

      return acc;
    },
    {
      hygieneReappointment: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          patients: 0,
          rescheduledSameDay: 0,
          unscheduled: 0,
        },
      },
    }
  );
};

export const newPatientsDetails = (data?: Data<PracticeAnalyticsTypes.NewPatientsTrend>) => {
  return Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { newPatients } = locationDetails?.location || {};
      const { details, totals } = newPatients || {};
      const { patients, production, unscheduledProductionPerDay } = totals || {};

      if (patients) {
        acc.newPatients.totals.patients += patients;
      }

      if (production) {
        acc.newPatients.totals.production += production;
      }

      unscheduledProductionPerDay?.forEach(({ date, amount }) => {
        const index = acc.newPatients.totals.unscheduledProductionPerDay.findIndex((item) => item.date === date);

        if (index === -1) {
          acc.newPatients.totals.unscheduledProductionPerDay.push({ date, amount });
        } else {
          acc.newPatients.totals.unscheduledProductionPerDay[index].amount += amount;
        }
      });

      // Club patient details in a single array and include relevant location ids to the data objects
      if (details?.patients) {
        acc.newPatients.details.patients = details.patients.reduce((acc, patient) => {
          acc.push({
            ...patient,
            locationId,
          });
          return acc;
        }, acc.newPatients.details.patients);
      }

      return acc;
    },
    {
      newPatients: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          patients: 0,
          production: 0,
          unscheduledProductionPerDay: [] as PracticeAnalyticsTypes.ProductionPerDay[],
        },
      },
    }
  );
};

export const recapturedPatientsDetails = (
  data?: Data<PracticeAnalyticsTypes.RecapturedPatientsTrend>,
  locationsCount?: number
) => {
  const updatedData = Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { recapturedPatients } = locationDetails?.location || {};
      const { details, totals } = recapturedPatients || {};

      if (totals?.leadTime) {
        acc.recapturedPatients.totals.leadTime += totals.leadTime;
      }

      if (totals?.patients) {
        acc.recapturedPatients.totals.patients += totals.patients;
      }

      if (totals?.production) {
        acc.recapturedPatients.totals.production += totals.production;
      }

      // Club patient details in a single array and include relevant location ids to the data objects
      if (details?.patients) {
        acc.recapturedPatients.details.patients = details.patients.reduce((acc1, patient) => {
          acc1.push({
            ...patient,
            locationId,
          });
          return acc1;
        }, acc.recapturedPatients.details.patients);
      }

      return acc;
    },
    {
      recapturedPatients: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          leadTime: 0,
          patients: 0,
          production: 0,
        },
      },
    }
  );

  // Find the average of the lead time as per the selected number of locations
  updatedData.recapturedPatients.totals.leadTime /= locationsCount || 1;

  return updatedData;
};

export const morningHuddleMonthProduction = (data?: Data<MorningHuddleTypes.MonthProductionResponse>) => {
  return Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { location } = locationDetails || {};
      const { lmtdProduction, mtdProduction } = location || {};

      if (lmtdProduction) {
        acc.lmtdProduction.totals.completedProduction += lmtdProduction.totals.completedProduction;
      }

      if (mtdProduction) {
        acc.mtdProduction.projections.totalScheduled += mtdProduction.projections.totalScheduled;
        acc.mtdProduction.totals.completedProduction += mtdProduction.totals.completedProduction;
      }

      return acc;
    },
    {
      lmtdProduction: {
        totals: {
          completedProduction: 0,
        },
      },
      mtdProduction: {
        projections: {
          totalScheduled: 0,
        },
        totals: {
          completedProduction: 0,
        },
      },
    } as MorningHuddleTypes.MonthProductionResponseLocation
  );
};

const defaultMorningHuddleProductionTypes: MorningHuddleTypes.LastDayProductionResponseLocation = {
  cancellations: {
    totals: {
      cancelled: 0,
      cancelledUnscheduled: 0,
    },
  },
  hygieneReappointment: {
    totals: {
      patients: 0,
      percentageSameDay: 0,
      unscheduled: 0,
    },
  },
  hygieneTx: {
    totals: {
      diagnosed: 0,
      diagnosedPercent: 0,
      unscheduled: 0,
      unscheduledTreatment: 0,
    },
  },
  newPatients: {
    totals: {
      needsAppointment: 0,
      patients: 0,
      percentUnscheduled: 0,
      production: 0,
    },
  },
  overdue: {
    totals: {
      patients: 0,
    },
  },
  production: {
    details: {
      totalProductionPerVisit: 0,
      totalVisits: 0,
    },
    totals: {
      completedProduction: 0,
      scheduledProduction: 0,
      sameDayProduction: 0,
    },
  },
  recaptured: {
    totals: {
      patients: 0,
      production: 0,
    },
  },
  restorativeTx: {
    totals: {
      diagnosed: 0,
      diagnosedPercent: 0,
      unscheduled: 0,
      unscheduledTreatment: 0,
    },
  },
  unscheduled: {
    totals: {
      patients: 0,
    },
  },
};

export const morningHuddleProductionTypes = (
  data?: Data<MorningHuddleTypes.LastDayProductionResponse>,
  locationsCount?: number
) => {
  const updatedData = Object.values(cloneDeep(data?.data) || {}).reduce((acc, locationDetails) => {
    const {
      cancellations,
      hygieneReappointment,
      hygieneTx,
      newPatients,
      overdue,
      production,
      recaptured,
      restorativeTx,
      unscheduled,
    } = locationDetails?.location || {};

    acc.cancellations.totals.cancelled += cancellations?.totals?.cancelled || 0;
    acc.cancellations.totals.cancelledUnscheduled += cancellations?.totals?.cancelledUnscheduled || 0;

    acc.hygieneReappointment.totals.patients += hygieneReappointment?.totals?.patients || 0;
    acc.hygieneReappointment.totals.percentageSameDay += hygieneReappointment?.totals?.percentageSameDay || 0;
    acc.hygieneReappointment.totals.unscheduled += hygieneReappointment?.totals?.unscheduled || 0;

    acc.hygieneTx.totals.diagnosed += hygieneTx?.totals?.diagnosed || 0;
    acc.hygieneTx.totals.diagnosedPercent += hygieneTx?.totals?.diagnosedPercent || 0;
    acc.hygieneTx.totals.unscheduled += hygieneTx?.totals?.unscheduled || 0;
    acc.hygieneTx.totals.unscheduledTreatment += hygieneTx?.totals?.unscheduledTreatment || 0;

    acc.newPatients.totals.needsAppointment += newPatients?.totals?.needsAppointment || 0;
    acc.newPatients.totals.patients += newPatients?.totals?.patients || 0;
    acc.newPatients.totals.percentUnscheduled += newPatients?.totals?.percentUnscheduled || 0;
    acc.newPatients.totals.production += newPatients?.totals?.production || 0;

    acc.overdue.totals.patients += overdue?.totals?.patients || 0;

    acc.production.details.totalProductionPerVisit += production?.details?.totalProductionPerVisit || 0;
    acc.production.details.totalVisits += production?.details?.totalVisits || 0;
    acc.production.totals.completedProduction += production?.totals?.completedProduction || 0;
    acc.production.totals.sameDayProduction += production?.totals?.sameDayProduction || 0;
    acc.production.totals.scheduledProduction += production?.totals?.scheduledProduction || 0;

    acc.recaptured.totals.patients += recaptured?.totals?.patients || 0;
    acc.recaptured.totals.production += recaptured?.totals?.production || 0;

    acc.restorativeTx.totals.diagnosed += restorativeTx?.totals?.diagnosed || 0;
    acc.restorativeTx.totals.diagnosedPercent += restorativeTx?.totals?.diagnosedPercent || 0;
    acc.restorativeTx.totals.unscheduled += restorativeTx?.totals?.unscheduled || 0;
    acc.restorativeTx.totals.unscheduledTreatment += restorativeTx?.totals?.unscheduledTreatment || 0;

    acc.unscheduled.totals.patients += unscheduled?.totals?.patients || 0;

    return acc;
  }, cloneDeep(defaultMorningHuddleProductionTypes));

  // Average the percentage values based on the number of locations
  updatedData.hygieneReappointment.totals.percentageSameDay /= locationsCount || 1;
  updatedData.hygieneTx.totals.diagnosedPercent /= locationsCount || 1;
  updatedData.newPatients.totals.percentUnscheduled /= locationsCount || 1;
  updatedData.restorativeTx.totals.diagnosedPercent /= locationsCount || 1;

  return updatedData;
};

export const morningHuddleScheduledProduction = (
  data?: Data<MorningHuddleTypes.ScheduledProductionResponse>,
  locationsCount?: number
) => {
  const updatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { avgProduction, production } = locationDetails?.location || {};

      acc.avgProduction.totals.averageCompletedProductionPerDay +=
        avgProduction?.totals?.averageCompletedProductionPerDay || 0;

      // Merge production details for matching timestamps
      acc.production.projections.details =
        production?.projections?.details?.reduce((acc, detail) => {
          const existingIndex = acc.findIndex((d) => d.timestamp === detail.timestamp);

          if (existingIndex > -1) {
            acc[existingIndex] = {
              ...acc[existingIndex],
              production: acc[existingIndex].production + detail.production,
            };
          } else {
            acc.push(detail);
          }
          return acc;
        }, acc.production.projections.details || []) || [];

      return acc;
    },
    {
      avgProduction: {
        totals: {
          averageCompletedProductionPerDay: 0,
        },
      },
      production: {
        projections: {
          details: [],
        },
      },
    } as MorningHuddleTypes.ScheduledProductionResponseLocation
  );

  // Find the average based on the number of locations
  updatedData.avgProduction.totals.averageCompletedProductionPerDay /= locationsCount || 1;

  return updatedData;
};

export const activePatientsScheduledSummary = (
  data?: Data<PracticeAnalyticsTypes.ActivePatientsScheduledResponse>,
  locationsCount?: number
) => {
  const updatedData = Object.values(cloneDeep(data?.data) || {}).reduce(
    (acc, locationDetails) => {
      const { location } = locationDetails || {};
      const { activePatientsScheduled } = location || {};

      if (activePatientsScheduled?.benchmarks) {
        // Benchmark would be same for all locations
        acc.activePatientsScheduled.benchmarks = activePatientsScheduled.benchmarks;
      }

      if (activePatientsScheduled?.percentage) {
        acc.activePatientsScheduled.percentage += activePatientsScheduled.percentage;
      }

      return acc;
    },
    {
      activePatientsScheduled: {
        benchmarks: [] as PracticeAnalyticsTypes.Benchmark[],
        percentage: 0,
      },
    }
  );

  // Update average percentage based on number of locations
  updatedData.activePatientsScheduled.percentage /= locationsCount || 1;

  const averages = updatedData.activePatientsScheduled.benchmarks?.reduce(
    (acc, { label, value }) => ({ ...acc, [label.replaceAll('.', '')]: value * 100 }),
    {} as { Avg: number; Top: number }
  );

  const percentages = {
    activePracticePercent: Math.round((updatedData.activePatientsScheduled?.percentage || 0) * 100),
    averagePracticePercent: averages?.['Avg'] || 0,
    topPracticePercent: averages?.['Top'] || 0,
  };

  return percentages;
};

const defaultOpportunityPatients = {
  details: {
    patients: [] as PracticeAnalyticsTypes.PatientInfo[],
  },
};

export const opportunityList = (data?: Data<PracticeAnalyticsTypes.OpportunityList>) => {
  return Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { location } = locationDetails || {};
      const { hygieneReappointment, hygieneTreatmentPlan, missedAppointments, newPatients, restorativeTreatmentPlan } =
        location || {};

      if (hygieneReappointment?.details?.patients) {
        acc.hygieneReappointment.details.patients = aggregatePatients(
          PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_FOLLOW_UP,
          acc.hygieneReappointment.details.patients,
          locationId,
          hygieneReappointment.details.patients
        );
      }

      if (hygieneTreatmentPlan?.details?.patients) {
        acc.hygieneTreatmentPlan.details.patients = aggregatePatients(
          PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_TREATMENT_PLAN,
          acc.hygieneTreatmentPlan.details.patients,
          locationId,
          hygieneTreatmentPlan.details.patients
        );
      }

      if (missedAppointments?.details?.patients) {
        acc.missedAppointments.details.patients = aggregatePatients(
          PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.CANCELLED_PATIENTS,
          acc.missedAppointments.details.patients,
          locationId,
          missedAppointments.details.patients
        );
      }

      if (newPatients?.details?.patients) {
        acc.newPatients.details.patients = aggregatePatients(
          PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.NEW_PATIENTS,
          acc.newPatients.details.patients,
          locationId,
          newPatients.details.patients
        );
      }

      if (restorativeTreatmentPlan?.details?.patients) {
        acc.restorativeTreatmentPlan.details.patients = aggregatePatients(
          PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.RESTORATIVE_TREATMENT_PLAN,
          acc.restorativeTreatmentPlan.details.patients,
          locationId,
          restorativeTreatmentPlan.details.patients
        );
      }

      return acc;
    },
    {
      hygieneReappointment: cloneDeep(defaultOpportunityPatients),
      hygieneTreatmentPlan: cloneDeep(defaultOpportunityPatients),
      missedAppointments: cloneDeep(defaultOpportunityPatients),
      newPatients: cloneDeep(defaultOpportunityPatients),
      restorativeTreatmentPlan: cloneDeep(defaultOpportunityPatients),
    }
  );
};

export const monthlyHistoricalTrend = (
  metric: PracticeAnalyticsTypes.Metric,
  data?: Data<PracticeAnalyticsTypes.MonthlyTrendHistoricalData>,
  locationsCount?: number
) => {
  const values = Object.values(cloneDeep(data?.data) || {});

  switch (metric) {
    case 'activePatients':
      return values.reduce((acc, locationDetails) => {
        return (
          locationDetails?.location.activePatients.historicalData.reduce((acc1, { label, total }) => {
            const index = acc1.findIndex((item) => item.label === label);

            if (index > -1) {
              acc1[index].total += total;
            } else {
              acc1.push({ label, total });
            }
            return acc1;
          }, acc) || []
        );
      }, [] as PracticeAnalyticsTypes.MonthlyTrendHistoricalData['location'][typeof metric]['historicalData']);

    case 'activePatientsScheduled':
    case 'cancellations': {
      const historicalData = values.reduce((acc, locationDetails) => {
        return (
          locationDetails?.location[metric].historicalData.reduce((acc1, { label, percentage }) => {
            const index = acc1.findIndex((item) => item.label === label);

            if (index > -1) {
              acc1[index].percentage += percentage;
            } else {
              acc1.push({ label, percentage });
            }
            return acc1;
          }, acc) || []
        );
      }, [] as PracticeAnalyticsTypes.MonthlyTrendHistoricalData['location'][typeof metric]['historicalData']);

      // Calculate average percentage based on number of locations
      return historicalData.map(({ label, percentage }) => ({
        label,
        percentage: percentage / (locationsCount || 1),
      }));
    }

    case 'hygieneReappointment': {
      const historicalData = values.reduce((acc, locationDetails) => {
        return (
          locationDetails?.location[metric].historicalData.reduce((acc1, { label, percentageSameDay }) => {
            const index = acc1.findIndex((item) => item.label === label);

            if (index > -1) {
              acc1[index].percentageSameDay += percentageSameDay;
            } else {
              acc1.push({ label, percentageSameDay });
            }
            return acc1;
          }, acc) || []
        );
      }, [] as PracticeAnalyticsTypes.MonthlyTrendHistoricalData['location'][typeof metric]['historicalData']);

      // Calculate average percentage based on number of locations
      return historicalData.map(({ label, percentageSameDay }) => ({
        label,
        percentageSameDay: percentageSameDay / (locationsCount || 1),
      }));
    }

    case 'hygieneTreatmentPlan':
    case 'restorativeTreatmentPlan': {
      const historicalData = values.reduce((acc, locationDetails) => {
        return (
          locationDetails?.location[metric].historicalData.reduce(
            (acc1, { label, acceptedPercent, diagnosedPercent }) => {
              const index = acc1.findIndex((item) => item.label === label);

              if (index > -1) {
                acc1[index].acceptedPercent += acceptedPercent;
                acc1[index].diagnosedPercent += diagnosedPercent;
              } else {
                acc1.push({ label, acceptedPercent, diagnosedPercent });
              }
              return acc1;
            },
            acc
          ) || []
        );
      }, [] as PracticeAnalyticsTypes.MonthlyTrendHistoricalData['location'][typeof metric]['historicalData']);

      // Calculate average percentage based on number of locations
      return historicalData.map(({ label, acceptedPercent, diagnosedPercent }) => ({
        label,
        acceptedPercent: acceptedPercent / (locationsCount || 1),
        diagnosedPercent: diagnosedPercent / (locationsCount || 1),
      }));
    }

    case 'newPatients':
    case 'recapturedPatients':
      return values.reduce((acc, locationDetails) => {
        return (
          locationDetails?.location[metric].historicalData.reduce((acc1, { label, production, total }) => {
            const index = acc1.findIndex((item) => item.label === label);

            if (index > -1) {
              acc1[index].production += production;
              acc1[index].total += total;
            } else {
              acc1.push({ label, production, total });
            }
            return acc1;
          }, acc) || []
        );
      }, [] as PracticeAnalyticsTypes.MonthlyTrendHistoricalData['location'][typeof metric]['historicalData']);

    default:
      return null;
  }
};

const fixPractitionerTotalPercentages = (data: PracticeAnalyticsTypes.PractitionerTreatmentPlanAnalytics[]) => {
  return data.map((item) => {
    const { accepted = 0, diagnosed = 0, qualifiedVisits = 0 } = item;

    return {
      ...item,
      acceptedPercent: accepted / (diagnosed || 1),
      diagnosedPercent: diagnosed / (qualifiedVisits || 1),
    };
  });
};

const mergePractitionerTotals = (data: PracticeAnalyticsTypes.PractitionerTreatmentPlanAnalytics[]) => {
  const mergedData: Record<string, PracticeAnalyticsTypes.PractitionerTreatmentPlanAnalytics> = {};

  data.forEach((item) => {
    const { accepted = 0, diagnosed = 0, practitionerName, qualifiedVisits = 0, unscheduledTreatment = 0 } = item;

    if (practitionerName) {
      if (mergedData[practitionerName]) {
        // If the practitioner already exists, update their data
        mergedData[practitionerName] = {
          ...mergedData[practitionerName],
          accepted: (mergedData[practitionerName].accepted || 0) + accepted,
          diagnosed: (mergedData[practitionerName].diagnosed || 0) + diagnosed,
          qualifiedVisits: (mergedData[practitionerName].qualifiedVisits || 0) + qualifiedVisits,
          unscheduledTreatment: (mergedData[practitionerName].unscheduledTreatment || 0) + unscheduledTreatment,
        };
      } else {
        // If the practitioner does not exist, add them to the merged data
        mergedData[practitionerName] = { ...item };
      }
    }
  });

  // Convert the merged data back into an array format
  return fixPractitionerTotalPercentages(Object.values(mergedData));
};

export const practitionerAnalysisDetails = (
  data?: Data<PracticeAnalyticsTypes.PractitionerAnalysisDetailsResponse>
) => {
  // Update rawData to correct percentage values
  const rawData: Record<string, PracticeAnalyticsTypes.PractitionerAnalysisDetailsResponse> = Object.entries(
    cloneDeep(data?.data) || {}
  ).reduce((acc, [locationId, locationDetails]) => {
    const { hygieneTreatmentPlan, restorativeTreatmentPlan, treatmentPlan } = locationDetails?.location || {};

    return {
      ...acc,
      [locationId]: {
        location: {
          ...(locationDetails?.location || {}),
          hygieneTreatmentPlan: {
            ...(hygieneTreatmentPlan || {}),
            totals: {
              ...(hygieneTreatmentPlan?.totals || {}),
              acceptedPercent:
                (hygieneTreatmentPlan?.totals?.accepted || 0) / (hygieneTreatmentPlan?.totals?.diagnosed || 1),
              diagnosedPercent:
                (hygieneTreatmentPlan?.totals?.diagnosed || 0) / (hygieneTreatmentPlan?.totals?.qualifiedVisits || 1),
            },
          },
          restorativeTreatmentPlan: {
            ...(restorativeTreatmentPlan || {}),
            totals: {
              ...(restorativeTreatmentPlan?.totals || {}),
              acceptedPercent:
                (restorativeTreatmentPlan?.totals?.accepted || 0) / (restorativeTreatmentPlan?.totals?.diagnosed || 1),
              diagnosedPercent:
                (restorativeTreatmentPlan?.totals?.diagnosed || 0) /
                (restorativeTreatmentPlan?.totals?.qualifiedVisits || 1),
            },
          },
          treatmentPlan: {
            ...(treatmentPlan || {}),
            practitionerTreatmentPlanAnalytics: {
              ...(treatmentPlan?.practitionerTreatmentPlanAnalytics || {}),
              practitionerHygieneTreatmentPlanTotals: fixPractitionerTotalPercentages(
                treatmentPlan?.practitionerTreatmentPlanAnalytics?.practitionerHygieneTreatmentPlanTotals || []
              ),
              practitionerRestorativeTreatmentPlanTotals: fixPractitionerTotalPercentages(
                treatmentPlan?.practitionerTreatmentPlanAnalytics?.practitionerRestorativeTreatmentPlanTotals || []
              ),
            },
          },
        },
      },
    };
  }, {});

  const aggregatedData = Object.entries(cloneDeep(data?.data) || {}).reduce(
    (acc, [locationId, locationDetails]) => {
      const { hygieneTreatmentPlan, treatmentPlan, restorativeTreatmentPlan } = locationDetails?.location || {};
      const { practitionerHygieneTreatmentPlanTotals = [], practitionerRestorativeTreatmentPlanTotals = [] } =
        treatmentPlan?.practitionerTreatmentPlanAnalytics || {};

      acc.treatmentPlan.practitionerTreatmentPlanAnalytics = {
        practitionerHygieneTreatmentPlanTotals: [
          ...acc.treatmentPlan.practitionerTreatmentPlanAnalytics.practitionerHygieneTreatmentPlanTotals,
          ...practitionerHygieneTreatmentPlanTotals,
        ],
        practitionerRestorativeTreatmentPlanTotals: [
          ...acc.treatmentPlan.practitionerTreatmentPlanAnalytics.practitionerRestorativeTreatmentPlanTotals,
          ...practitionerRestorativeTreatmentPlanTotals,
        ],
      };

      if (hygieneTreatmentPlan?.details?.patients) {
        acc.hygieneTreatmentPlan.details.patients = appendLocationIdToPatients(
          hygieneTreatmentPlan.details.patients,
          locationId,
          acc.hygieneTreatmentPlan.details.patients
        );
      }

      if (hygieneTreatmentPlan?.totals) {
        acc.hygieneTreatmentPlan.totals.accepted += hygieneTreatmentPlan.totals.accepted || 0;
        acc.hygieneTreatmentPlan.totals.diagnosed += hygieneTreatmentPlan.totals.diagnosed || 0;
        acc.hygieneTreatmentPlan.totals.qualifiedVisits += hygieneTreatmentPlan.totals.qualifiedVisits || 0;
        acc.hygieneTreatmentPlan.totals.unscheduledTreatment += hygieneTreatmentPlan.totals.unscheduledTreatment || 0;
      }

      if (restorativeTreatmentPlan?.details?.patients) {
        acc.restorativeTreatmentPlan.details.patients = appendLocationIdToPatients(
          restorativeTreatmentPlan.details.patients,
          locationId,
          acc.restorativeTreatmentPlan.details.patients
        );
      }

      if (restorativeTreatmentPlan?.totals) {
        acc.restorativeTreatmentPlan.totals.accepted += restorativeTreatmentPlan.totals.accepted || 0;
        acc.restorativeTreatmentPlan.totals.diagnosed += restorativeTreatmentPlan.totals.diagnosed || 0;
        acc.restorativeTreatmentPlan.totals.qualifiedVisits += restorativeTreatmentPlan.totals.qualifiedVisits || 0;
        acc.restorativeTreatmentPlan.totals.unscheduledTreatment +=
          restorativeTreatmentPlan.totals.unscheduledTreatment || 0;
      }

      return acc;
    },
    {
      hygieneTreatmentPlan: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          ...defaultTreatmentTotals,
        },
      },
      restorativeTreatmentPlan: {
        details: {
          patients: [] as PracticeAnalyticsTypes.PatientInfo[],
        },
        totals: {
          ...defaultTreatmentTotals,
        },
      },
      treatmentPlan: {
        practitionerTreatmentPlanAnalytics: {
          practitionerHygieneTreatmentPlanTotals: [] as PracticeAnalyticsTypes.PractitionerTreatmentPlanAnalytics[],
          practitionerRestorativeTreatmentPlanTotals: [] as PracticeAnalyticsTypes.PractitionerTreatmentPlanAnalytics[],
        },
      },
    }
  );

  // Merge duplicate practitionerHygieneTreatmentPlanTotals and practitionerRestorativeTreatmentPlanTotals
  aggregatedData.treatmentPlan.practitionerTreatmentPlanAnalytics = {
    practitionerHygieneTreatmentPlanTotals: mergePractitionerTotals(
      aggregatedData.treatmentPlan.practitionerTreatmentPlanAnalytics.practitionerHygieneTreatmentPlanTotals
    ),
    practitionerRestorativeTreatmentPlanTotals: mergePractitionerTotals(
      aggregatedData.treatmentPlan.practitionerTreatmentPlanAnalytics.practitionerRestorativeTreatmentPlanTotals
    ),
  };

  aggregatedData.hygieneTreatmentPlan.totals.acceptedPercent =
    aggregatedData.hygieneTreatmentPlan.totals.accepted / (aggregatedData.hygieneTreatmentPlan.totals.diagnosed || 1);

  aggregatedData.hygieneTreatmentPlan.totals.diagnosedPercent =
    aggregatedData.hygieneTreatmentPlan.totals.diagnosed /
    (aggregatedData.hygieneTreatmentPlan.totals.qualifiedVisits || 1);

  aggregatedData.restorativeTreatmentPlan.totals.acceptedPercent =
    aggregatedData.restorativeTreatmentPlan.totals.accepted /
    (aggregatedData.restorativeTreatmentPlan.totals.diagnosed || 1);

  aggregatedData.restorativeTreatmentPlan.totals.diagnosedPercent =
    aggregatedData.restorativeTreatmentPlan.totals.diagnosed /
    (aggregatedData.restorativeTreatmentPlan.totals.qualifiedVisits || 1);

  return {
    aggregatedData,
    rawData,
  };
};
