import { ListPhoneNumbersForLocationsIO } from '../types';
import { usePhoneNumbersV1Queries } from './use-phone-numbers-v1-queries';
import { UsePhoneNumbersV1QueryEndpointArgs, usePhoneNumbersV1Query } from './use-phone-numbers-v1-query';

/**
 * A hook that returns a query for the `ListPhoneNumbersForLocations` endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListPhoneNumbersForLocationsQuery = <E = unknown, D = ListPhoneNumbersForLocationsIO['output']>(
  args: UsePhoneNumbersV1QueryEndpointArgs<'ListPhoneNumbersForLocations', E, D>
) =>
  usePhoneNumbersV1Query({
    endpointName: 'ListPhoneNumbersForLocations',
    ...args,
  });

/**
 * A hook that returns an array of queries for the `ListPhoneNumbersForLocations` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListPhoneNumbersForLocationsQueries = <E = unknown, D = ListPhoneNumbersForLocationsIO['output']>(
  argsArr: UsePhoneNumbersV1QueryEndpointArgs<'ListPhoneNumbersForLocations', E, D>[]
) =>
  usePhoneNumbersV1Queries(
    argsArr.map((args) => ({
      endpointName: 'ListPhoneNumbersForLocations',
      ...args,
    }))
  );
