import { MessagesTypes } from '@frontend/api-messaging';
import { PersonHelpers } from '@frontend/api-person';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { dedupePhoneValidity } from '@frontend/thread-header';
import { theme } from '@frontend/theme';
import { DropdownField, Heading, Modal, SpinningLoader, Text, useFormField } from '@frontend/design-system';

type ContactNumberSelectionProps = {
  person: MessagesTypes.InboxPerson;
  validPhones: SMSNumberV1.Types.PhoneValidity[];
  onContinue: (personPhone: string) => void;
  onCancel: () => void;
  initPersonPhone?: string;
  isLoadingPopout: boolean;
};

export const ContactNumberSelection = ({
  person,
  validPhones,
  onContinue,
  onCancel,
  initPersonPhone,
  isLoadingPopout,
}: ContactNumberSelectionProps) => {
  const { t } = useTranslation('inbox');
  const personName = PersonHelpers.getFullName(person);

  const reset = () => {
    fieldProps.value = initPersonPhone || onlyValidPhoneNumber || '';
  };

  const dedupedPhones = dedupePhoneValidity(validPhones, 'combine-labels');
  const onlyValidPhoneNumber = dedupedPhones.length === 1 ? dedupedPhones[0].number : undefined;
  const fieldProps = useFormField({ type: 'dropdown', value: initPersonPhone || onlyValidPhoneNumber }, [
    initPersonPhone,
    onlyValidPhoneNumber,
  ]);

  return (
    <>
      <Modal.Header title={t("Select Contact's Number")}>
        <Heading level={2}>{t("Select Contact's Number")}</Heading>
      </Modal.Header>
      <Modal.Body css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <Text>{t('This contact has multiple numbers you can contact, which number would you like to message?')}</Text>
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Text size='small' color='light'>
            {t('Contact')}
          </Text>
          <span css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}>
            <Photos.ContactProfilePhoto personId={person.PersonID} name={personName} size='small' disableClick />
            <Text>{personName}</Text>
          </span>
        </div>
        {isLoadingPopout ? (
          <span
            css={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'center',
              padding: theme.spacing(1),
            }}
          >
            <SpinningLoader size='medium' />
          </span>
        ) : (
          <DropdownField {...fieldProps} name={t('Contact Number')} label={t('Contact Number')}>
            {dedupedPhones.map((phone) => (
              <DropdownField.Option key={phone.number} value={phone.number}>
                <Text>{`${formatPhoneNumber(phone.number, false)} (${phone.label})`}</Text>
              </DropdownField.Option>
            ))}
          </DropdownField>
        )}
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Continue')}
        onPrimaryClick={() => {
          onContinue(fieldProps.value);
          reset();
        }}
        disablePrimary={!fieldProps.value}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={() => {
          onCancel();
          reset();
        }}
        disableSecondary={isLoadingPopout}
      />
    </>
  );
};
