import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { useTranslation } from '@frontend/i18n';
import { ConfirmationModal, ModalControlModalProps, useAlert } from '@frontend/design-system';
import { trackingId } from '../../tracking';

export const DeleteCallRouteModal = ({
  deleteModalProps,
  callRouteId,
  onSuccess,
}: {
  deleteModalProps: ModalControlModalProps;
  callRouteId?: string;
  onSuccess?: () => void;
}) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const { mutate: deleteCallRoute } = CallRouteV1.Mutations.useDeleteMutation({});

  return (
    <ConfirmationModal
      {...deleteModalProps}
      title={t('Delete Call Route?')}
      message={t('Deleting this route is permanent and may disrupt active workflows.')}
      cancelLabel={t('No, Cancel')}
      confirmLabel={t('Yes, Delete')}
      destructive
      cancelTrackingId={trackingId({
        context: 'setting',
        feature: 'call-routes',
        details: 'delete::cancel-btn',
      })}
      confirmTrackingId={trackingId({
        context: 'setting',
        feature: 'call-routes',
        details: 'delete::confirm-btn',
      })}
      onCancel={async () => {
        deleteModalProps.onClose();
      }}
      onConfirm={async () => {
        if (callRouteId) {
          deleteCallRoute(
            { callRouteId },
            {
              onSuccess: () => {
                alerts.success(t('Call Route deleted successfully.'));
                deleteModalProps.onClose();
                onSuccess?.();
              },
              onError: () => {
                alerts.error(t('Failed to delete Call Route.'));
              },
            }
          );
        }
      }}
    />
  );
};
