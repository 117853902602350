import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { GoogleReCaptcha, GoogleReCaptchaProvider, IGoogleRecaptchaProps } from 'react-google-recaptcha-v3';
import { ScheduleDefaults, ScheduleQueries, ScheduleTypes } from '@frontend/api-schedule';
import env from '@frontend/env';
import { theme } from '@frontend/theme';
import { Stepper, useStepperCardContext, useStepperContext } from '@frontend/design-system';
import { useBookingSiteStore } from '../../../stores';
import { BOOKING_SITE_HTTP_OPTIONS } from '../../../utils';
import { RequestContact, getComplete } from '../RequestContact';

function tryParseJson<T>(jsonString?: string): T | undefined {
  try {
    return JSON.parse(jsonString ?? '');
  } catch (error) {
    return undefined;
  }
}

// eslint-disable-next-line react/display-name
const CaptchaComponent = memo((props: IGoogleRecaptchaProps) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={env.CAPTCHAV3_SITE_KEY}
      container={{
        element: 'recaptcha-v3',
        parameters: {
          badge: 'inline',
        },
      }}
    >
      <div id='recaptcha-v3' css={{ margin: theme.spacing(1), padding: theme.spacing(1, 0.25) }} />
      <GoogleReCaptcha {...props} />
    </GoogleReCaptchaProvider>
  );
});

interface InfoFormComponentProps {
  formDetails: ScheduleTypes.FormDetails;
  setFormDetails: Dispatch<SetStateAction<ScheduleTypes.FormDetails>>;
  complianceConsent: JSX.Element;
  isV3CaptchaEnabled: boolean;
}

const InfoFormComponent = ({
  formDetails,
  setFormDetails,
  complianceConsent,
  isV3CaptchaEnabled,
}: InfoFormComponentProps) => {
  const { selectedLocationId, captchaV3Token, setCaptchaV3Token } = useBookingSiteStore([
    'selectedLocationId',
    'setCaptchaV3Token',
    'captchaV3Token',
  ]);
  const { data } = ScheduleQueries.useGetCustomFields(selectedLocationId, BOOKING_SITE_HTTP_OPTIONS);

  useEffect(() => {
    // Reset captcha token when the form is mounted
    if (isV3CaptchaEnabled && captchaV3Token) {
      setCaptchaV3Token('');
    }
  }, []);

  const handleVerify = useCallback(
    (token: string) => {
      if (isV3CaptchaEnabled) {
        setCaptchaV3Token(token);
      }
    },
    [isV3CaptchaEnabled, setCaptchaV3Token]
  );

  const { customFields, overrideFields } = useMemo(() => {
    return {
      customFields: tryParseJson<ScheduleTypes.CustomFields>(data?.customFields),
      overrideFields: !!data?.overrideFields
        ? tryParseJson<ScheduleTypes.OverrideFields>(data?.overrideFields)
        : ScheduleDefaults.overrideFields,
    };
  }, [data]);

  // TODO: This is a temporary fix to make the address field required until we understand which PMSs require a patient address when creating an appointment from this request. Currently sometimes failing `desktop/v1/syncapp-writebacks/customer` due to a missing address.
  if (overrideFields && overrideFields.address) {
    overrideFields.address = {
      ...ScheduleDefaults.overrideFields.address,
    };
  }

  return (
    <Stepper.Content>
      <RequestContact
        customFields={customFields}
        overrideFields={overrideFields}
        value={formDetails?.patientInfo}
        errors={formDetails?.formErrors}
        onError={(formErrors?: Record<string, string>) => {
          const errors = { ...formDetails?.formErrors, ...formErrors };
          setFormDetails?.({ ...formDetails, formErrors: errors });
        }}
        onChange={(patientInfo: ScheduleTypes.PatientInfo, formErrors?: Record<string, string>) => {
          const val = { ...formDetails, patientInfo: { ...formDetails?.patientInfo, ...patientInfo } };
          const input = document.getElementsByTagName('input');
          const newValues = Object.values(input).reduce((values, field) => {
            if (field.required && field.value) {
              const name = field.name as keyof ScheduleTypes.PatientInfo;
              if (!patientInfo?.[name]) {
                return { ...patientInfo, ...values, [field.name]: field.value };
              }
            }
            return values;
          }, {} as Partial<ScheduleTypes.PatientInfo>);
          const completeFormValues = !!Object.entries(newValues).length ? newValues : patientInfo;
          const { isComplete, errors } = getComplete(completeFormValues, overrideFields, customFields, formErrors);
          setFormDetails?.((prev) => {
            const patientInfo = { ...prev?.patientInfo, ...val.patientInfo };
            return { ...val, patientInfo, formComplete: isComplete, formErrors: errors };
          });
        }}
      />
      {complianceConsent}
      {isV3CaptchaEnabled && <CaptchaComponent action='postrequest' onVerify={handleVerify} />}
    </Stepper.Content>
  );
};

export const InfoForm = (props: InfoFormComponentProps) => {
  const { stepStatus } = useStepperContext();
  const { step } = useStepperCardContext();

  const isCurrentStepActive = stepStatus[step] === 'active' || stepStatus[step] === 'currActive';

  // If the step is not active, we don't want to render the form to avoid unnecessary re-renders of google recaptcha
  if (props.isV3CaptchaEnabled && !isCurrentStepActive) {
    return null;
  }

  return <InfoFormComponent {...props} />;
};
