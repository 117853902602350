import { FormsLibraryV2Types } from '../../../types';
import { BASE_ROUTE } from '../base';

export const FORMS_LIBRARY_LIST: Record<FormsLibraryV2Types.ListType, FormsLibraryV2Types.ListType> = {
  forms: 'forms',
  packets: 'packets',
};

export const BASE_LIBRARY_ROUTE = `${BASE_ROUTE}/library`;
export const BASE_FORMS_LIBRARY_V2_ROUTE = `${BASE_LIBRARY_ROUTE}?list=${FORMS_LIBRARY_LIST.forms}`;
export const BASE_PACKETS_LIBRARY_V2_ROUTE = `${BASE_LIBRARY_ROUTE}?list=${FORMS_LIBRARY_LIST.packets}`;

/** @deprecated */
export const BASE_FORMS_LIBRARY_ROUTE = `${BASE_LIBRARY_ROUTE}/forms`;

/** @deprecated */
export const BASE_PACKETS_LIBRARY_ROUTE = `${BASE_LIBRARY_ROUTE}/packets`;

/** @deprecated */
export const BASE_IMPORTED_FORMS_LIBRARY_ROUTE = `${BASE_LIBRARY_ROUTE}/imported-forms`;
