import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CheckboxField, Text, useControlledField } from '@frontend/design-system';
import { FormsUpgradeModalTrackingIds } from '../../../constants/tracking-ids';
import { FORMS_DIGITIZATION_FEE } from './constants';

interface FormsSubscriptionAdditionalFieldProps {
  checked: boolean;
  onChange: (check: boolean) => void;
}

export const FormsSubscriptionAdditionalCheckboxField: FC<FormsSubscriptionAdditionalFieldProps> = ({
  checked,
  onChange,
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'digital-forms-promotion' });
  const acceptProps = useControlledField({
    type: 'checkbox',
    value: checked,
    onChange,
  });

  return (
    <CheckboxField
      css={checkboxStyle}
      name='forms-accept-digitization'
      trackingId={FormsUpgradeModalTrackingIds.additionalDigitizationCheckbox}
      {...acceptProps}
      label={
        <Text size='medium'>
          {t('Add forms digitization (${{amount}} one-time payment)', { amount: FORMS_DIGITIZATION_FEE })}
        </Text>
      }
    />
  );
};

const checkboxStyle = css({
  margin: theme.spacing(2, 0),
  alignItems: 'center',
});
