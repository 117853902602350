import { pendoTags } from '../../../../shared/constants';
import { BuilderFieldDivisionTypes } from '../../types';

export const PRIMARY_FIELD_CATEGORIES: BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory[] = [
  {
    label: 'Personal Information',
    fieldsOrder: [
      {
        key: 'firstName',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.firstName,
      },
      {
        key: 'middleName',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.middleName,
      },
      {
        key: 'lastName',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.lastName,
      },
      {
        key: 'preferredName',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.preferredName,
      },
      {
        key: 'birthdate',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.birthdate,
      },
      {
        key: 'gender',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.gender,
      },
      {
        key: 'maritalStatus',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.maritalStatus,
      },
      {
        key: 'socialSecurityNumber',
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.socialSecurityNumber,
      },
      {
        key: 'driversLicenseNumber',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.personalInformation.driversLicenseNumber,
      },
    ],
  },
  {
    label: 'Contact Information',
    fieldsOrder: [
      {
        key: 'mobilePhone',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.contactInformation.mobilePhone,
      },
      {
        key: 'homePhone',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.contactInformation.homePhone,
      },
      {
        key: 'workPhone',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.contactInformation.workPhone,
      },
      {
        key: 'email',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.contactInformation.email,
      },
    ],
  },
  {
    label: 'Residential Address',
    fieldsOrder: [
      {
        key: 'address1',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.residentialAddress.address1,
      },
      {
        key: 'address2',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.residentialAddress.address2,
      },
      {
        key: 'city',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.residentialAddress.city,
      },
      {
        key: 'state',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.residentialAddress.state,
      },
      {
        key: 'postalCode',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.residentialAddress.postalCode,
      },
    ],
  },
  {
    label: 'Insurance Information',
    fieldsOrder: [
      {
        key: 'primaryInsuranceId',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceId,
      },
      {
        key: 'primaryInsuranceAddress1',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceAddress1,
      },
      {
        key: 'primaryInsuranceAddress2',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceAddress2,
      },
      {
        key: 'primaryInsuranceCity',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceCity,
      },
      {
        key: 'primaryInsuranceName',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceName,
      },
      {
        key: 'primaryInsurancePostalCode',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsurancePostalCode,
      },
      {
        key: 'primaryInsuranceRelationship',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceRelationship,
      },
      {
        key: 'primaryInsuranceSSN',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceSSN,
      },
      {
        key: 'primaryInsuranceSpouseName',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceSpouseName,
      },
      {
        key: 'primaryInsuranceSpouseSSN',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceSpouseSSN,
      },
      {
        key: 'primaryInsuranceState',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryInsuranceState,
      },
      {
        key: 'primaryPolicyHolderName',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryPolicyHolderName,
      },
      {
        key: 'primaryGroupNumber',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryGroupNumber,
      },
      {
        key: 'primaryRxNumber',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.primaryRxNumber,
      },
      {
        key: 'secondaryInsuranceId',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceId,
      },
      {
        key: 'secondaryInsuranceAddress1',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceAddress1,
      },
      {
        key: 'secondaryInsuranceAddress2',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceAddress2,
      },
      {
        key: 'secondaryInsuranceCity',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceCity,
      },
      {
        key: 'secondaryInsuranceName',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceName,
      },
      {
        key: 'secondaryInsurancePostalCode',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsurancePostalCode,
      },
      {
        key: 'secondaryInsuranceRelationship',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceRelationship,
      },
      {
        key: 'secondaryInsuranceSSN',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceSSN,
      },
      {
        key: 'secondaryInsuranceSpouseName',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceSpouseName,
      },
      {
        key: 'secondaryInsuranceSpouseSSN',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceSpouseSSN,
      },
      {
        key: 'secondaryInsuranceState',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryInsuranceState,
      },
      {
        key: 'secondaryPolicyHolderName',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryPolicyHolderName,
      },
      {
        key: 'secondaryGroupNumber',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryGroupNumber,
      },
      {
        key: 'secondaryRxNumber',
        trackingId: pendoTags.formBuilder.categories.primaryFields.insuranceInformation.secondaryRxNumber,
      },
    ],
  },
];

interface BuilderFieldsMapping {
  [key: string]: {
    categoryIndex: number;
    orderIndex: number;
  };
}

/**
 * Mapping of primary fields to their category and order index.
 * This is used to determine the position of a field in the primary fields section.
 *
 * @example
 * {
 *  firstName: { categoryIndex: 0, orderIndex: 0 },
 *  middleName: { categoryIndex: 0, orderIndex: 1 },
 * }
 */
export const PRIMARY_FIELDS_MAPPING = PRIMARY_FIELD_CATEGORIES.reduce<BuilderFieldsMapping>(
  (acc, category, categoryIndex) => {
    category.fieldsOrder.forEach(({ key }, orderIndex) => {
      acc[key] = {
        categoryIndex,
        orderIndex,
      };
    });

    return acc;
  },
  {}
);
