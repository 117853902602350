import { Dispatch, FC, PropsWithChildren, SetStateAction, createContext, useContext, useState } from 'react';
import { FormsSubmissions } from '@frontend/api-forms';
import { BaseFieldProps, useDebouncedValue, useFormField } from '@frontend/design-system';
import { FormSubmissionsTypes } from '../../shared/types';

interface SubmissionsFilterContext {
  filters: FormSubmissionsTypes.SubmissionFilters;
  search: string;
  searchProps: BaseFieldProps<string, '', HTMLInputElement>;
  sortOrder: FormsSubmissions.Types.SubmissionListSortOrder;
  setSortOrder: Dispatch<SetStateAction<FormsSubmissions.Types.SubmissionListSortOrder>>;
  setFilters: Dispatch<SetStateAction<FormSubmissionsTypes.SubmissionFilters>>;
}

const SubmissionsFilter = createContext<SubmissionsFilterContext | undefined>(undefined);

export const SubmissionsFilterProvider: FC<PropsWithChildren<Record<never, never>>> = ({ children }) => {
  const searchProps = useFormField({
    type: 'text',
  });

  const search = useDebouncedValue(searchProps.value, 500);

  const [sortOrder, setSortOrder] = useState<FormsSubmissions.Types.SubmissionListSortOrder>('desc');
  const [filters, setFilters] = useState<FormSubmissionsTypes.SubmissionFilters>({ dateFrom: undefined });

  const contextValue: SubmissionsFilterContext = {
    filters,
    search,
    searchProps,
    sortOrder,
    setSortOrder,
    setFilters,
  };
  return <SubmissionsFilter.Provider value={contextValue}>{children}</SubmissionsFilter.Provider>;
};

export const useSubmissionsFilter = () => {
  const context = useContext(SubmissionsFilter);

  if (context === undefined) {
    throw new Error('useSubmissionsFilter must be used within a <SubmissionsFilter />.');
  }

  return context;
};
