import {
  GetInvoiceBalancesMultiRequest,
  GetInvoiceBalancesMultiResponse,
  ListInvoicesResponse,
  ListPaymentMethodsRequest,
  ListPaymentMethodsResponse,
  UpdateBillingAccountContactRequest,
} from '@weave/schema-gen-ts/dist/schemas/finance/finance_service_api.pb';
import {
  BillingAccount,
  CreateTwocpAccountRequest,
  NewPaymentMethod,
  PaymentMethod,
  TwocpAccount,
} from '@weave/schema-gen-ts/dist/schemas/finance/finance_types.pb';
import {
  MutationOptions,
  UseMutationOptions,
  UseQueryOptions,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from 'react-query';
import { HttpError } from '@frontend/fetch';
import { SchemaFinanceService } from '@frontend/schema';
import { GetInvoicesRequest, GetQueryOptions, InvoiceDetailOrPdfRequest, InvoiceDetailOrPdfResponse } from './types';

const queryKeys = {
  base: ['finance'] as const,
  billingAccountInfo: (locationId: string) => [locationId, ...queryKeys.base, 'billing-account-info'],
  paymentMethods: (locationId: string) => [locationId, ...queryKeys.base, 'payment-methods'],
  invoiceDetails: (locationId: string, invoiceId: string) => [
    locationId,
    ...queryKeys.base,
    'invoice-details',
    invoiceId,
  ],
  multiInvoiceBalanceDetails: (locationIds: string[]) => [...locationIds, 'multi-invoice-balance'],
  invoiceList: ({ locationId, pageSize, pageToken }: GetInvoicesRequest) => [
    locationId,
    ...queryKeys.base,
    'invoice-list',
    pageSize,
    pageToken,
  ],
  invoiceListInfiniteQuery: (locationId: string, pageSize: number) => [
    locationId,
    ...queryKeys.base,
    'invoice-list-infinite',
    pageSize,
  ],
  invoiceDetailOrPdf: ({ locationId, invoiceId }: { locationId?: string; invoiceId?: string }) => [
    ...queryKeys.base,
    locationId,
    invoiceId,
  ],
};

const logErrorInConsole = (actionName: string) => (err: unknown) =>
  console.error(`An error occurred while ${actionName}`, err);

export const useGetBillingAccount = (locationId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.billingAccountInfo(locationId),
    queryFn: () => SchemaFinanceService.GetBillingAccount({ locationId }),
    enabled: Boolean(locationId && !options?.disabled),
    onError: logErrorInConsole('getting billing account information'),
    retry: false,
  });
};

export const useGetInvoices = (req: GetInvoicesRequest, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.invoiceList(req),
    queryFn: () =>
      SchemaFinanceService.ListInvoices({
        locationId: req.locationId,
        pageSize: req.pageSize,
        pageToken: req.pageToken,
      }),
    enabled: Boolean(req.locationId && !options?.disabled),
    onError: logErrorInConsole('getting invoice list information'),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetInvoicesInfiniteQuery = (locationId: string, pageSize: number) => {
  return useInfiniteQuery({
    queryKey: queryKeys.invoiceListInfiniteQuery(locationId, pageSize),
    queryFn: ({ pageParam }) =>
      SchemaFinanceService.ListInvoices({
        locationId,
        pageSize,
        pageToken: pageParam,
      }),
    getNextPageParam: (lastPage: ListInvoicesResponse) => {
      if (!lastPage.nextPageToken) return undefined;
      return lastPage.nextPageToken;
    },
    onError: logErrorInConsole('getting invoice list information'),
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export const useGetInvoiceDetails = (locationId: string, invoiceId: string, options?: GetQueryOptions) => {
  return useQuery({
    queryKey: queryKeys.invoiceDetails(locationId, invoiceId),
    queryFn: () => SchemaFinanceService.GetInvoiceDetails({ locationId, id: invoiceId }),
    enabled: Boolean(locationId && invoiceId && !options?.disabled),
    onError: logErrorInConsole('getting invoice details'),
    retry: false,
  });
};

export const useMultiInvoiceBalanceDetails = (
  request: GetInvoiceBalancesMultiRequest,
  options?: UseQueryOptions<GetInvoiceBalancesMultiResponse>
) => {
  return useQuery({
    queryKey: queryKeys.multiInvoiceBalanceDetails(request.locationIds ?? []),
    queryFn: () => SchemaFinanceService.GetInvoiceBalancesForMulti(request),
    enabled: Boolean(request.locationIds?.length),
    ...options,
  });
};

export const useGetInvoiceDetailOrPdf = (
  request: InvoiceDetailOrPdfRequest,
  options?: UseQueryOptions<InvoiceDetailOrPdfResponse>
) => {
  return useQuery({
    queryKey: queryKeys.invoiceDetailOrPdf(request),
    queryFn: () => SchemaFinanceService.GetInvoiceDetailOrPdf(request),
    retry: false,
    ...options,
    enabled: !!(request.invoiceId && request.locationId) && (options?.enabled ?? true),
  });
};

export const useUpdateBillingAccountContactMutation = (
  locationId: string,
  options?: MutationOptions<BillingAccount, unknown, UpdateBillingAccountContactRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (req: UpdateBillingAccountContactRequest) => SchemaFinanceService.UpdateBillingAccountContact(req),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.billingAccountInfo(locationId));
    },
  });
};

//#region Payment Method Queries

export const useGetPaymentMethods = (
  request: ListPaymentMethodsRequest,
  options?: UseQueryOptions<ListPaymentMethodsResponse, HttpError, PaymentMethod[]>
) => {
  return useQuery({
    queryKey: queryKeys.paymentMethods(request.locationId ?? ''),
    queryFn: () => SchemaFinanceService.ListPaymentMethods(request),
    retry: false,
    ...options,
    enabled: !!request.locationId && (options?.enabled ?? true),
    select: (data) => data?.paymentMethods ?? [],
    useErrorBoundary: false,
    onError: (error) => {
      logErrorInConsole('getting payment methods')(error);
      options?.onError?.(error);
    },
  });
};

export const useSetPaymentMethodAsDefaultMutation = (
  locationId: string,
  options?: MutationOptions<unknown, unknown, string>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) =>
      SchemaFinanceService.SetPaymentMethodAsDefault(
        {
          locationId,
          id: paymentMethodId,
        },
        {
          headers: {
            'Location-Id': locationId,
          },
        }
      ),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.paymentMethods(locationId));
    },
  });
};

export const useDeletePaymentMethodMutation = (
  locationId: string,
  options?: MutationOptions<unknown, unknown, string>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethodId: string) =>
      SchemaFinanceService.DeletePaymentMethod(
        {
          locationId,
          id: paymentMethodId,
        },
        {
          headers: {
            'Location-Id': locationId,
          },
        }
      ),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.paymentMethods(locationId));
    },
  });
};

export const useCreatePaymentMethodMutation = (
  locationId: string,
  options?: MutationOptions<PaymentMethod, HttpError, NewPaymentMethod>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (paymentMethod: NewPaymentMethod) =>
      SchemaFinanceService.CreatePaymentMethod({
        locationId,
        paymentMethod,
      }),
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      queryClient.invalidateQueries(queryKeys.paymentMethods(locationId));
    },
  });
};

//#endregion

export const useCreateTwoCPAccount = (
  options?: UseMutationOptions<TwocpAccount, HttpError, CreateTwocpAccountRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: SchemaFinanceService.CreateTwocpAccountForm,
    ...options,
    onSuccess: (res, req, context) => {
      options?.onSuccess?.(res, req, context);
      !!req.locationId && queryClient.invalidateQueries(queryKeys.paymentMethods(req.locationId));
    },
  });
};
