import { InvoiceFilterType, InvoiceFilterStatus } from '@frontend/api-invoices';
import { InvoiceRefundStatus } from '../../types';
import { DefaultFilters, useQuickFilters } from '../utils';

export type PaymentRequestFilterTypes = 'allInvoices' | 'paid' | 'refunded' | 'unpaid';

export type UsePaymentRequestQuickFilterProps = {
  filter: InvoiceFilterType;
  onChange: (filter: InvoiceFilterType) => void;
};
export const usePaymentRequestQuickFilter = ({ filter, onChange }: UsePaymentRequestQuickFilterProps) => {
  const defaultFilters: DefaultFilters<PaymentRequestFilterTypes, InvoiceFilterType> = {
    allInvoices: {
      status: [],
    },
    paid: {
      status: [InvoiceFilterStatus.paid, InvoiceFilterStatus.partiallyPaid],
    },
    refunded: {
      status: [InvoiceRefundStatus.PartialRefund, InvoiceRefundStatus.Refunded],
    },
    unpaid: {
      status: [InvoiceFilterStatus.unpaid],
    },
  };

  return useQuickFilters({ defaultFilters, filter, onChange });
};
