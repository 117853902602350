import { isEqual } from 'lodash-es';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { LAYOUT_CONSTANTS, WIDTH_DIMS, WidthKey } from './constants';
import type { PanelConfig } from './PANEL-CONFIG';

type NavState = 'regular' | 'slim';

interface PanelRecord {
  id: PanelConfig;
  widthKey: WidthKey;
  widthValue: number;
  currentWidth: number | null;
  placementLocation: 'tray' | 'page';
  priority: number;
}

/**

Sorts panels based on a two-tier priority system:
- The `lastInteractedWith` panel always gets highest priority (Number.MAX_VALUE)
- All other panels are sorted by their base priority value
- This ensures the most recently interacted panel is positioned rightmost in the layout,
while maintaining a stable priority-based ordering for all other panels. It also makes sure that 
when the tray needs to open, the `lastInteractedWith` panel is the first one to jump on the tray.

@param {PanelRecord[]} panels - Array of panel records to be sorted
@param {string | null} lastInteractedWith - ID of the most recently interacted panel, if any

@returns {PanelRecord[]} New array of sorted panel records where the lastInteractedWith panel
(if present) comes last, and remaining panels are sorted by priority

**/

function sortPanelsWithPriority(panels: PanelRecord[], lastInteractedWith: string | null): PanelRecord[] {
  return [...panels].sort((a, b) => {
    const aEff = a.id === lastInteractedWith ? Number.MAX_VALUE : a.priority;
    const bEff = b.id === lastInteractedWith ? Number.MAX_VALUE : b.priority;
    return aEff - bEff;
  });
}

interface PanelState {
  availableWidth: number;
  navState: NavState;
  panels: PanelRecord[];
  panelIds: PanelConfig[];
  trayIds: PanelConfig[];
  hasTrayPanels: boolean;
  lastInteractedWith: PanelConfig | null;
  setNavState: (state: NavState, shouldRecalc?: boolean) => void;
  setLastInteractedWith: (id: PanelConfig) => void;
  registerPanel: (id: PanelConfig, width: WidthKey, onClose: () => void, priority?: number) => void;
  unregisterPanel: (id: PanelConfig) => void;
  updatePanelWidth: (id: PanelConfig, width: WidthKey) => void;
  updateAvailableWidth: (width: number) => void;
  recalculatePanels: () => void;
  getPanelCurrentWidth: (id: PanelConfig) => WidthKey;
  panelCloseFunctions: Partial<Record<PanelConfig, () => void>>;
  closeAllPanels: () => void;
  setPanelCloseFunction: (id: PanelConfig, closeFunction: () => void) => void;
  currentPanelWidths: Record<PanelConfig, WidthKey>;
  setCurrentPanelWidths: (id: PanelConfig, widthKey: WidthKey) => void;
}

function totalTrayWidth(trayPanels: PanelRecord[], currentPanelWidths: Record<PanelConfig, WidthKey>): number {
  return trayPanels.reduce((sum, p) => {
    const widthValue = WIDTH_DIMS[currentPanelWidths[p.id]];
    return sum + widthValue;
  }, 0);
}

/**

Manages tray overflow by closing panels with lowest priority until the total width of tray panels
fits within the maximum allowed tray width. This ensures the tray remains usable by preventing
overcrowding.

The function follows these steps:

- Returns early if there are 0 or 1 panels (no need for closure)
- Continuously checks if total tray width exceeds maximum allowed width
- If width is exceeded, sorts panels by priority and closes the lowest priority panel
- Repeats until remaining panels fit within tray or no panels remain


@param {PanelRecord[]} updatedPanels - All panel records with their current state
@param {string[]} trayIds - IDs of panels currently in the tray
@param {number} trayMaxWidth - Maximum allowed width for the tray
@param {Partial<Record<PanelConfig, () => void>>} panelCloseFunctions - Map of panel close handlers
@param {Record<PanelConfig, WidthKey>} currentPanelWidths - Current width settings for each panel

@returns {string[]} Array of panel IDs that remain in the tray after closing lowest priority panels
**/

function closeLowestPriorityFirst(
  updatedPanels: PanelRecord[],
  trayIds: string[],
  trayMaxWidth: number,
  panelCloseFunctions: Partial<Record<PanelConfig, () => void>>,
  currentPanelWidths: Record<PanelConfig, WidthKey>
): string[] {
  if (trayIds.length === 0) return [];
  if (trayIds.length === 1) return trayIds;

  let trayPanelRecords = trayIds.map((id) => updatedPanels.find((p) => p.id === id)!);

  while (trayPanelRecords.length > 0 && totalTrayWidth(trayPanelRecords, currentPanelWidths) > trayMaxWidth) {
    trayPanelRecords.sort((a, b) => a.priority - b.priority);
    const lowest = trayPanelRecords[0];
    panelCloseFunctions[lowest.id]?.();
    trayPanelRecords = trayPanelRecords.slice(1);
  }

  return trayPanelRecords.map((p) => p.id);
}

const navDifference = LAYOUT_CONSTANTS.NAV.REGULAR - LAYOUT_CONSTANTS.NAV.SLIM;

/**
Calculates the optimal placement of panels either on the page or in the tray based on available space
and panel widths. Uses a stable algorithm that preserves panel ordering and attempts to fit as many
panels as possible while respecting the minimum content width and navigation constraints.

The function processes panels in order of priority (and last interaction) and attempts to:

- Fit panels in the available space while maintaining regular nav width
- If tight on space, switches to slim nav to accommodate more panels
- Moves remaining panels to tray when they don't fit


@param {PanelRecord[]} sortedPanels - Array of panel records sorted by priority, where the lastInteractedWith panel
always gets maximum priority (placed rightmost, or if the tray needs to open, it's the first one to jump on the tray) regardless of its base priority value. Other panels are sorted by
their priority level in ascending order.
@param {number} availableWidth - Total available width of the viewport
@param {Record<PanelConfig, WidthKey>} currentPanelWidths - Current width settings for each panel

@returns {Object} An object containing:
@property {string[]} panelIds - IDs of panels that fit on the page
@property {string[]} trayIds - IDs of panels that should be moved to the tray
@property {PanelRecord[]} updatedPanels - All panels with updated placement locations
@property {NavState} [navSize] - Selected navigation size ('regular' or 'slim') based on space constraints

*/

function fitPanelsStable(
  sortedPanels: PanelRecord[],
  availableWidth: number,
  currentPanelWidths: Record<PanelConfig, WidthKey>
) {
  const { NAV, CONTENT, ACTION_BAR } = LAYOUT_CONSTANTS;
  const navWidth = NAV['REGULAR'];
  const spaceForPanels = availableWidth - (navWidth + CONTENT.MIN_WIDTH + ACTION_BAR.WIDTH);
  let navSize: NavState = 'regular';
  if (spaceForPanels <= 0) {
    const forcedTray = sortedPanels.map((p) => ({ ...p, placementLocation: 'tray' }));
    return {
      panelIds: [],
      trayIds: forcedTray.map((p) => p.id),
      updatedPanels: forcedTray as PanelRecord[],
    };
  }

  let remainingSpace = spaceForPanels;
  const updatedPanels: PanelRecord[] = [];

  let shouldTray = false;
  for (const panel of sortedPanels) {
    const currentWidth = currentPanelWidths[panel.id];
    const widthValue = WIDTH_DIMS[currentWidth];

    if (widthValue <= remainingSpace && !shouldTray) {
      updatedPanels.push({
        ...panel,
        placementLocation: 'page',
      });
      remainingSpace -= widthValue;
    } else if (widthValue <= remainingSpace + navDifference && !shouldTray) {
      updatedPanels.push({
        ...panel,
        placementLocation: 'page',
      });

      remainingSpace -= widthValue - navDifference;
      navSize = 'slim';
    } else {
      updatedPanels.push({
        ...panel,
        placementLocation: 'tray',
      });
      shouldTray = true;
    }
  }

  const panelIds: string[] = [];
  const trayIds: string[] = [];

  for (const up of updatedPanels) {
    if (up.placementLocation !== 'tray') {
      panelIds.push(up.id);
    } else {
      trayIds.push(up.id);
    }
  }

  return { panelIds, trayIds, updatedPanels, navSize };
}

export const usePanelStore = createStoreWithSubscribe<PanelState>(
  (set, get) => ({
    availableWidth: window.innerWidth,
    navState: 'regular',

    panels: [],
    panelIds: [],
    trayIds: [],
    hasTrayPanels: false,

    lastInteractedWith: null,

    panelCloseFunctions: {},

    setNavState: (state, shouldRecalc = false) => {
      set({ navState: state });
      if (shouldRecalc) get().recalculatePanels();
    },

    setLastInteractedWith: (id) => {
      set({ lastInteractedWith: id }, false, 'SET_LAST_INTERACTED_WITH');
    },

    registerPanel: (id, width, onClose, priority = 1) => {
      const widthValue = WIDTH_DIMS[width];

      get().setCurrentPanelWidths(id, width);
      get().setPanelCloseFunction(id, onClose);
      get().setLastInteractedWith(id);

      set((state) => {
        const newPanel: PanelRecord = {
          id,
          widthKey: width,
          widthValue,
          currentWidth: widthValue,
          placementLocation: 'page',
          priority,
        };

        const newPanels = [...state.panels, newPanel];
        return { panels: newPanels };
      });

      get().recalculatePanels();
    },

    unregisterPanel: (id) => {
      set((state) => {
        const newPanels = state.panels.filter((p) => p.id !== id);
        if (newPanels.length === 0) {
          return {
            navState: 'regular',
            panelIds: [],
            trayIds: [],
            hasTrayPanels: false,
            panels: newPanels,
          };
        }

        return { panels: newPanels };
      });
      get().recalculatePanels();
    },

    updatePanelWidth: (id, width) => {
      const widthValue = WIDTH_DIMS[width];
      if (widthValue > window.innerWidth) {
        return;
      }

      get().setCurrentPanelWidths(id, width);

      get().recalculatePanels();
    },

    updateAvailableWidth: (width) => {
      const oldWidth = get().availableWidth;
      // Minor threshold to avoid frequent calls
      if (Math.abs(oldWidth - width) > 1) {
        set({ availableWidth: width });
        get().recalculatePanels();
      }
    },

    recalculatePanels: () => {
      const {
        panels,
        availableWidth,
        lastInteractedWith,
        navState,
        panelIds: previousPanelIds,
        trayIds: previousTrayIds,
        hasTrayPanels,
        panelCloseFunctions,
        currentPanelWidths,
      } = get();

      if (panels.length === 0) {
        return;
      }

      const sortedPanels = sortPanelsWithPriority(panels, lastInteractedWith);

      const { panelIds, trayIds, updatedPanels, navSize } = fitPanelsStable(
        sortedPanels,
        availableWidth,
        currentPanelWidths
      );

      const trayMaxWidth = availableWidth;
      const finalTrayIds = closeLowestPriorityFirst(
        updatedPanels,
        trayIds,
        trayMaxWidth,
        panelCloseFunctions,
        currentPanelWidths
      );

      const previousState = {
        navState,
        panelIds: previousPanelIds,
        trayIds: previousTrayIds,
        hasTrayPanels,
        panels,
      };

      const newState = {
        navState: navSize,
        panelIds: panelIds as PanelConfig[],
        trayIds: finalTrayIds as PanelConfig[],
        hasTrayPanels: finalTrayIds.length > 0,
        panels: updatedPanels,
      };

      if (isEqual(previousState, newState)) return;

      set(newState);
    },

    getPanelCurrentWidth: (id) => {
      const { currentPanelWidths } = get();
      return currentPanelWidths[id];
    },

    setPanelCloseFunction: (id, closeFunction) => {
      set((state) => ({
        panelCloseFunctions: {
          ...state.panelCloseFunctions,
          [id]: closeFunction,
        },
      }));
    },
    closeAllPanels: () => {
      const { panels, panelCloseFunctions } = get();
      panels.forEach((panel) => {
        panelCloseFunctions[panel.id]?.();
      });
    },
    currentPanelWidths: {} as Record<PanelConfig, WidthKey>,
    setCurrentPanelWidths: (id, widthKey) => {
      set((state) => ({
        currentPanelWidths: {
          ...state.currentPanelWidths,
          [id]: widthKey,
        },
      }));
    },
  }),
  { name: 'multi-panel-engine' }
);

export const usePanelShallowStore = createShallowStore(usePanelStore);
