import { ComponentProps } from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

export const ErrorBoundary = (props: ComponentProps<typeof SentryErrorBoundary>) => {
  if (process.env.NODE_ENV === 'development') {
    return <SentryErrorBoundary {...props} />;
  }

  return <>{props.children}</>;
};
