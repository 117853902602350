import React from 'react';
import { css } from '@emotion/react';
import { motion, Reorder } from 'motion/react';
import { theme } from '@frontend/theme';
import { Text, NakedUl } from '@frontend/design-system';

type QuickActionsGroupBaseProps = {
  title: string;
  children: React.ReactNode;
  subtitle?: string;
  showSubtitleBorder?: boolean;
  constraintRef?: React.MutableRefObject<HTMLUListElement | null>;
};

export type QuickActionsGroupProps =
  | (QuickActionsGroupBaseProps & {
      type: 'reorder';
      values: string[];
      onReorder: (values: string[]) => void;
    })
  | ({
      type?: 'default';
    } & QuickActionsGroupBaseProps);

export const QuickActionsGroup = ({
  children,
  title,
  subtitle,
  showSubtitleBorder,
  constraintRef,
  type = 'default',
  ...rest
}: QuickActionsGroupProps) => {
  const renderAppropriate = () => {
    if (type === 'default') return <NakedUl>{children}</NakedUl>;

    if (!('values' in rest)) return null;

    return (
      <Reorder.Group
        ref={constraintRef}
        css={css`
          padding: 0;
        `}
        values={rest.values}
        onReorder={rest.onReorder}
      >
        {children}
      </Reorder.Group>
    );
  };

  return (
    <motion.section>
      <motion.header
        layout='position'
        transition={{ duration: 0.3 }}
        css={css`
          padding: ${theme.spacing(2, 3)};
          background-color: ${theme.colors.neutral5};
        `}
      >
        <Text as='h3' size='medium' weight='bold' color='subdued'>
          {title}
        </Text>
      </motion.header>
      {subtitle && (
        <motion.div
          css={[
            css`
              padding: ${theme.spacing(3)};
              background-color: ${theme.colors.white};
            `,
            showSubtitleBorder &&
              css`
                border-bottom: 1px solid ${theme.colors.neutral20};
              `,
          ]}
        >
          <Text textAlign='center' color='subdued'>
            {subtitle}
          </Text>
        </motion.div>
      )}
      {renderAppropriate()}
    </motion.section>
  );
};
