import { FC } from 'react';
import { FormsSourceTenants } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPMSName } from '@frontend/pms';
import { Button, Text } from '@frontend/design-system';
import { pendoTags } from '../../../../index';
import { containerStyle, getButtonStyle, textStyleForButton } from './pms-selector-button.styles';

interface PmsSelectorButtonProps {
  source: FormsSourceTenants.Types.ModifiedSourceTenant | undefined;
  availableSources: FormsSourceTenants.Types.ModifiedSourceTenant[];
  isOpen: boolean;
  isDisabled?: boolean;
}

const PmsSelectorButton: FC<PmsSelectorButtonProps> = ({ source, availableSources, isOpen, isDisabled = false }) => {
  const { t } = useTranslation('forms');
  const sourceName = source?.sourceName || t('Select PMS');
  const pmsName = source?.pmsName;

  return (
    <span css={containerStyle}>
      <Button
        variant='secondary'
        css={getButtonStyle(availableSources.length)}
        disabled={isDisabled}
        trackingId={pendoTags.medicalHistory.pmsSelectorButton}
      >
        <Text as='span' size='small' color={isDisabled ? 'disabled' : 'default'}>
          {sourceName}
        </Text>

        {pmsName && (
          <Text as='span' size='small' css={textStyleForButton} color={isDisabled ? 'disabled' : 'default'}>
            | {formatPMSName(pmsName)}
          </Text>
        )}

        <Icon name={isOpen ? 'caret-up-small' : 'caret-down-small'} />
      </Button>
    </span>
  );
};

export default PmsSelectorButton;
