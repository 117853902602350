import { MassMessagingSendRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { AddEntryRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { MappingType_Enum } from '@weave/schema-gen-ts/dist/schemas/syncapp/mappings/v1/service.pb';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import { useInfiniteQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { http, Options } from '@frontend/fetch';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaMappingsService, SchemaScheduleService } from '@frontend/schema';
import { useScopeStore } from '@frontend/scope';
import {
  addCustomContact,
  customerWriteback,
  getScheduleRequestList,
  deleteScheduleAlert,
  deleteAllScheduleAlerts,
  getScheduleAlerts,
  listLocations,
  getAppointmentTypeList,
  listProviders,
} from './api';
import { API_RESPONSE_LIMIT, CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS, LOCATION_ID_HEADER } from './defaults';
import { scheduleBookingSiteQueryKeys } from './query-keys';
import {
  AppointmentStatusRequest,
  CustomContactRequest,
  CustomerWritebackRequest,
  DeleteScheduleRequestType,
  GetScheduleRequestCountTypes,
  GetScheduleRequestType,
  GetScheduleRequestsTypes,
  ScheduleCreateAppointmentType,
  UpdateScheduleRequestType,
  ScheduleAlertsRequest,
  AppointmentDetails,
  ListAppointmentTypeTimesType,
  ListProvidersType,
  GetAppointmentTypeListType,
  ScheduleRequestHistoryType,
} from './types';

dayjs.extend(isoWeek);

const onlineSchedulingQueryKeys = {
  base: ['online-scheduling'] as const,
  appointmentType: (locationId: string, typeId?: string) => [
    ...onlineSchedulingQueryKeys.base,
    locationId,
    'appointment-type',
    typeId ?? '',
  ],
  appointmentStatuses: (locationId: string, statusId?: string) => [
    ...onlineSchedulingQueryKeys.base,
    locationId,
    'appointment-statuses',
    statusId ?? '',
  ],
  appointmentDetails: (appointmentIds: string) => [
    ...onlineSchedulingQueryKeys.base,
    'appointment-details',
    appointmentIds,
  ],
  quickfillRecipients: (locationId: string) => [...onlineSchedulingQueryKeys.base, 'quickfill-recipients', locationId],
  infiniteQuickfillRecipients: (locationId: string) => [
    ...onlineSchedulingQueryKeys.base,
    'infinite-quickfill-recipients',
    locationId,
  ],
  appointment: (appointmentId: string, locationId: string, isEnabled: boolean) => [
    ...onlineSchedulingQueryKeys.base,
    'single-appointment',
    appointmentId,
    locationId,
    isEnabled,
  ],
  alerts: (locationId: string) => [...onlineSchedulingQueryKeys.base, 'schedule-alerts', locationId],
};

export const useSchedulingInvalidation = () => {
  const client = useQueryClient();
  return {
    invalidateQuickfillRecipients: (locationId: string) =>
      client.invalidateQueries(onlineSchedulingQueryKeys.quickfillRecipients(locationId)),
  };
};

export const getLocationIdHeader = (locationId?: string) => ({
  headers: locationId ? { [LOCATION_ID_HEADER]: locationId } : undefined,
});

export const useGetAppointmentType = (appointmentTypeId?: string, options?: { locationId?: string }) => {
  const { selectedIds } = useScopeStore();
  const apiLocationId = options?.locationId ?? selectedIds?.[0];

  const appointmentTypeQuery = useQuery({
    queryKey: onlineSchedulingQueryKeys.appointmentType(apiLocationId, appointmentTypeId),
    queryFn: () => SchemaScheduleService.GetAppointmentType({ appointmentTypeId }, getLocationIdHeader(apiLocationId)),
    enabled: !!appointmentTypeId && !!apiLocationId,
    onError(error) {
      console.error('an error occurred while getting appointment type', error);
    },
  });

  return appointmentTypeQuery;
};

type GetAppointmentProps = {
  appointmentId: string;
  locationId: string;
  isEnabled?: boolean;
};

export const useGetAppointment = ({ appointmentId, locationId, isEnabled = false }: GetAppointmentProps) => {
  const appointmentQuery = useQuery({
    queryKey: onlineSchedulingQueryKeys.appointment(locationId, appointmentId, isEnabled),
    queryFn: () => SchemaScheduleService.GetAppointment({ id: appointmentId }, getLocationIdHeader(locationId)),
    enabled: !!appointmentId && isEnabled,
    onError(error) {
      console.error('an error occurred while getting appointment', error);
    },
    cacheTime: 1 * 60 * 1000, // 1 minute,
  });

  return appointmentQuery;
};

export const useGetAppointmentStatuses = (locationId: string) => {
  const appointmentStatusQuery = useQuery({
    queryKey: onlineSchedulingQueryKeys.appointmentStatuses(locationId),
    queryFn: () => SchemaMappingsService.ListMappings({ locationId, type: MappingType_Enum.APPOINTMENT }),
    onError(error) {
      console.error('an error occurred while getting appointment statuses', error);
    },
  });

  return appointmentStatusQuery;
};

const getAppointmentDetails = (appointmentId: string) => http.get<AppointmentDetails>(`/appointments/${appointmentId}`);

export const useAppointmentsQuery = (appointmentIds: string[], opts: UseQueryOptions<AppointmentDetails[]>) => {
  return useQuery({
    queryKey: onlineSchedulingQueryKeys.appointmentDetails(appointmentIds.toString()),
    queryFn: async () => await Promise.all(appointmentIds.map((appointmentId) => getAppointmentDetails(appointmentId))),
    ...opts,
  });
};

export const useMutateUpdateAppointmentStatus = (appointmentId: string) => {
  return useMutation({
    mutationFn: (requestData: AppointmentStatusRequest) => http.put(`/appointments/${appointmentId}`, requestData),
    onError(error) {
      console.error('an error occurred while updating the appointment status of online scheduling', error);
    },
  });
};

export const useGetQuickfillRecipientsList = (locationId: string) => {
  return useQuery({
    queryKey: onlineSchedulingQueryKeys.quickfillRecipients(locationId),
    queryFn: () => SchemaScheduleService.List({ locationId }),
    enabled: !!locationId,
    onError(error) {
      console.error('an error occurred while getting quickfill list of online scheduling', error);
    },
  });
};

export const useListQuickfillRecipients = (locationId: string) => {
  return useInfiniteQuery({
    queryKey: onlineSchedulingQueryKeys.infiniteQuickfillRecipients(locationId),
    queryFn: async ({ pageParam }) => {
      const response = await SchemaScheduleService.List({
        locationId,
        limit: pageParam?.limit || API_RESPONSE_LIMIT,
        skip: pageParam?.skip || 0,
      });
      return {
        rows: response.data,
        nextPage: response.meta?.links?.next,
      };
    },
    getNextPageParam: (prevPage) => {
      if (!prevPage.nextPage || !prevPage.rows?.length) return undefined;
      const queryString = prevPage.nextPage.split('?')[1]; // Get the query string part of the relative URL
      const params = new URLSearchParams(queryString);
      return {
        limit: params.get('limit') ? parseInt(params.get('limit') as string, 10) : API_RESPONSE_LIMIT,
        skip: params.get('skip') ? parseInt(params.get('skip') as string, 10) : 0,
      };
    },
    enabled: !!locationId,
    onError(error) {
      console.error('an error occurred while getting quickfill list of online scheduling', error);
    },
  });
};

export const useMutateDeleteQuickfillRecipient = (locationId: string) => {
  return useMutation({
    mutationFn: (personId: string) => SchemaScheduleService.Remove({ personId }, getLocationIdHeader(locationId)),
    onError(error) {
      console.error('an error occurred while deleting quickfill recipient of online scheduling', error);
    },
  });
};

export const useMutateAddCustomContact = (locationId: string) => {
  return useMutation({
    mutationFn: (req: CustomContactRequest) => addCustomContact(req, locationId),
    onError(error) {
      console.error('an error occurred while adding custom contact', error);
    },
  });
};

export const useMutateAddQuickfillRecipient = (locationId: string) => {
  const invalidate = useSchedulingInvalidation();
  return useMutation({
    mutationFn: (req: AddEntryRequest) => SchemaScheduleService.Add(req, getLocationIdHeader(locationId)),
    onSuccess: () => {
      invalidate.invalidateQuickfillRecipients(locationId);
    },
    onError(error) {
      console.error('an error occurred while adding quickfill recipient', error);
    },
  });
};

export const useMutateMassMessageSend = () => {
  return useMutation({
    mutationFn: (req: MassMessagingSendRequest) => SchemaScheduleService.MassMessageSend(req),
    onError(error) {
      console.error('an error occurred while sending mass message', error);
    },
  });
};
export const useGetScheduleRequestCount = (req: GetScheduleRequestCountTypes['input'], enabled = true) => {
  return useLocalizedQuery({
    queryKey: ['scheduleRequestCount', ...(req?.locationIds ?? [])],
    queryFn: () =>
      SchemaScheduleService.GetScheduleRequestCount(req).catch((error) => {
        console.error('an error occurred while getting schedule request count', error);
        return undefined;
      }),
    enabled: !!req?.locationIds?.length && enabled,
    onError(error) {
      console.error('an error occurred while getting schedule request count', error);
    },
  });
};

export const useMutateDeleteAlert = () => {
  return useMutation({
    mutationFn: ({ alertId, locationId }: ScheduleAlertsRequest) => deleteScheduleAlert(alertId, locationId),
    onError(error) {
      console.error('an error occurred while clearing the alert of online scheduling', error);
    },
  });
};

export const useGetScheduleRequestListInfiniteQuery = (
  req: GetScheduleRequestsTypes['input'],
  queryOptions?: { enabled?: boolean }
) => {
  return useInfiniteQuery({
    queryKey: ['scheduleRequestList', req.locationId ?? ''],
    queryFn: (queryContext) => {
      return getScheduleRequestList({ ...req, offset: queryContext.pageParam || req.offset });
    },
    getNextPageParam: (_lastGroup, scheduleRequest) => {
      if ((_lastGroup.rows?.length ?? 0) < (req.limit ?? 0)) return undefined;

      return scheduleRequest.length * (req.limit ?? 0);
    },
    enabled: !!req.locationId && (queryOptions?.enabled ?? true),
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });
};

export const useGetScheduleRequestById = (
  req: GetScheduleRequestType['input'],
  queryOptions?: UseQueryOptions<GetScheduleRequestType['output']>
) => {
  return useQuery({
    queryKey: ['scheduleRequest', req?.id, req?.locationId],
    queryFn: () => SchemaScheduleService.GetScheduleRequest(req, getLocationIdHeader(req?.locationId)),
    ...queryOptions,
    enabled: !!(req?.id && req?.locationId) && (queryOptions?.enabled ?? true),
    cacheTime: 10 * 60 * 1000, // 10 minutes,
    staleTime: 10 * 60 * 1000, // 10 minutes,
    onError(error) {
      console.error('an error occurred while getting schedule request by id', error);
    },
  });
};

export const useCreateAppointment = () => {
  return useMutation({
    mutationFn: (requestData: ScheduleCreateAppointmentType['input']) =>
      SchemaScheduleService.CreateAppointment(requestData),
    onError(error) {
      console.error('an error occurred while creating appointment', error);
    },
  });
};
export const useMutateDeleteAllAlerts = () => {
  return useMutation({
    mutationFn: (locationIds: string[]) => deleteAllScheduleAlerts(locationIds),
    onError(error) {
      console.error('an error occurred while clearing all the alerts of online scheduling', error);
    },
  });
};

export const useUpdateScheduleRequest = () => {
  return useMutation({
    mutationFn: (requestData: UpdateScheduleRequestType['input']) =>
      SchemaScheduleService.UpdateScheduleRequest(requestData),
    onError(error) {
      console.error('an error occurred while updating schedule request', error);
    },
  });
};

export const useDeleteScheduleRequest = () => {
  return useMutation({
    mutationFn: (requestData: DeleteScheduleRequestType['input']) =>
      SchemaScheduleService.DeleteScheduleRequest(requestData),
    onError(error) {
      console.error('an error occurred while deleting schedule request', error);
    },
  });
};

export const useAddWritebackCustomer = () => {
  return useMutation({
    mutationFn: (req: CustomerWritebackRequest) => customerWriteback(req),
    onError(error) {
      console.error('an error occurred while adding writeback customer', error);
    },
  });
};

export const useGetAllAlerts = (locationId: string) => {
  const alertQuery = useQuery({
    queryKey: onlineSchedulingQueryKeys.alerts(locationId),
    queryFn: () => getScheduleAlerts(locationId),
    enabled: !!locationId,
    onError(error) {
      console.error('an error occurred while getting alerts', error);
    },
  });

  return alertQuery;
};

export const useListLocations = (locationId: string, options?: Options) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.locations(locationId),
    queryFn: () => listLocations({ locationId }, options),
    enabled: !!locationId,
    retry: 1,
  });
};

export const useGetAppointmentTypeList = (
  req: GetAppointmentTypeListType['input'],
  queryOptions: Omit<UseQueryOptions<GetAppointmentTypeListType['output']>, 'select'>,
  httpOptions?: Options
) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.appointmentTypes(req),
    queryFn: () => getAppointmentTypeList(req, httpOptions),
    retry: 1,
    ...queryOptions,
    enabled: !!req.locationId && (queryOptions.enabled ?? true),
  });
};

export const useListProviders = (
  locationId: string,
  queryOptions: Omit<UseQueryOptions<ListProvidersType['output']>, 'select'>,
  httpOptions?: Options
) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.providers(locationId),
    queryFn: () => listProviders({ locationId }, httpOptions),
    retry: 1,
    ...queryOptions,
    enabled: !!locationId && (queryOptions.enabled ?? true),
  });
};

export const useListAppointmentTypeTimes = (
  req: ListAppointmentTypeTimesType['input'],
  queryOptions: Omit<UseQueryOptions<ListAppointmentTypeTimesType['output']>, 'select'>,
  httpOptions?: Options
) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.appointmentTypeTimes(req),
    queryFn: () => SchemaScheduleService.ListAppointmentTypeTimes(req, httpOptions),
    retry: 1,
    cacheTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS, // 3 mins
    staleTime: CACHE_AND_STALE_TIME_FOR_APPOINTMENT_OPENINGS, // 3 mins
    ...queryOptions,
    enabled:
      !!req.locationId && !!req.appointmentTypeId && !!req.startDate && !!req.endDate && (queryOptions.enabled ?? true),
  });
};

export const useGetCustomFields = (locationId: string, httpOptions?: Options) => {
  return useQuery({
    queryKey: scheduleBookingSiteQueryKeys.customFields(locationId),
    queryFn: () => SchemaScheduleService.GetCustomFields({ locationId }, httpOptions),
    retry: 1,
    enabled: !!locationId,
  });
};

export const useGetScheduleRequestsHistory = (
  req: ScheduleRequestHistoryType['input'],
  queryOptions?: UseQueryOptions<ScheduleRequestHistoryType['output']>,
  locationId?: string
) => {
  return useQuery({
    queryKey: ['schedule-requests-history', req],
    queryFn: () => SchemaScheduleService.GetScheduleRequestHistory(req, getLocationIdHeader(locationId ?? '')),
    ...queryOptions,
    enabled: !!req.locationIds?.length && (queryOptions?.enabled ?? true),
  });
};
