import { FC, useState, useEffect, Fragment } from 'react';
import { FormsSourceTenants } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { PopoverDialog, UsePopoverDialogResponse, RadioField, useControlledField, Text } from '@frontend/design-system';
import PmsSelectorButton from './pms-selector-button/pms-selector-button.component';
import {
  containerStyles,
  radioListContainerStyles,
  hrStyle,
  radioFieldContainerStyle,
  radioSelectStyle,
  radioValueContainerStyle,
  textStyle,
} from './pms-selector.styles';

interface CommonProps {
  popoverDialogControls: UsePopoverDialogResponse;
  availableSources: FormsSourceTenants.Types.ModifiedSourceTenant[];
  onSelectSource: (source: FormsSourceTenants.Types.ModifiedSourceTenant) => void;
  isDisabled?: boolean;
}

interface SourceTenantIdSelectorProps extends CommonProps {
  type: 'source-tenant-id';
  selectedSourceTenantId: string;
}

interface SourceIdSelectorProps extends CommonProps {
  type: 'source-id';
  selectedSourceId: string;
}

type PmsSelectorPopoverProps = SourceTenantIdSelectorProps | SourceIdSelectorProps;

export const PmsSelectorPopoverDialog: FC<PmsSelectorPopoverProps> = (props) => {
  const { t } = useTranslation('forms');
  const [selectedSource, setSelectedSource] = useState<FormsSourceTenants.Types.ModifiedSourceTenant | undefined>();
  const { popoverDialogControls, availableSources, onSelectSource, isDisabled = false, type } = props;
  const selectedId = type === 'source-tenant-id' ? props.selectedSourceTenantId : props.selectedSourceId;

  const radioFieldProps = useControlledField({
    type: 'radio',
    value: selectedId,
    onChange: (newId: string) => {
      const newSource = availableSources.find((source) =>
        type === 'source-tenant-id' ? source.sourceTenantId === newId : source.id === newId
      );
      if (newSource) {
        setSelectedSource(newSource);
        onSelectSource(newSource);
        popoverDialogControls.close();
      }
    },
  });

  /**
   * Sync selected source details when the selectedId changes.
   */
  useEffect(() => {
    if (selectedId) {
      const filteredSource = availableSources.find((source) =>
        type === 'source-tenant-id' ? source.sourceTenantId === selectedId : source.id === selectedId
      );

      if (filteredSource) {
        setSelectedSource(filteredSource);
        return;
      }
    }

    setSelectedSource(undefined);
  }, [selectedId]);

  return (
    <div>
      {!selectedSource && (
        <Text css={textStyle} size='medium'>
          {t('Select PMS below to setup list of allergy & other medical condition')}
        </Text>
      )}

      <span {...popoverDialogControls.getTriggerProps()}>
        <PmsSelectorButton
          availableSources={availableSources}
          source={selectedSource}
          isOpen={popoverDialogControls.isOpen}
          isDisabled={isDisabled}
        />
      </span>

      {!isDisabled && (
        <PopoverDialog {...popoverDialogControls.getDialogProps()} css={containerStyles}>
          <section css={radioListContainerStyles}>
            <RadioField {...radioFieldProps} name='pms-selector' label='' labelPlacement='left' css={radioSelectStyle}>
              {availableSources.map((source) => (
                <Fragment key={source.id}>
                  <RadioField.Radio
                    value={type === 'source-tenant-id' ? source.sourceTenantId : source.id}
                    css={radioValueContainerStyle}
                  >
                    <div css={radioFieldContainerStyle}>
                      <Text as='span' weight='bold'>
                        {source.sourceName}
                      </Text>
                      <Text weight='light'>{source.pmsName}</Text>
                    </div>
                  </RadioField.Radio>
                  <hr css={hrStyle} />
                </Fragment>
              ))}
            </RadioField>
          </section>
        </PopoverDialog>
      )}
    </div>
  );
};
