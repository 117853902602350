import { memo, useEffect } from 'react';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedSMSReport, useFormatBarChartData, useMessagingAnalyticsShallowStore } from '../hooks';

const colors = {
  current_patient: theme.colors.primary20,
  unknown: theme.colors.secondary.eggplant30,
};

const labels = {
  current_patient: i18next.t('Patient', { ns: 'analytics' }),
  unknown: i18next.t('Unknown', { ns: 'analytics' }),
};

interface PatientsVsUnknownChartProps {
  onLoadingStateChange?: (isLoading?: boolean) => void;
}

export const PatientsVsUnknownChart = memo(({ onLoadingStateChange }: PatientsVsUnknownChartProps) => {
  const { t } = useTranslation('analytics');
  const { data, isLoading } = useFetchAggregatedSMSReport('SourceType');
  const { filterHintText, isDemoAccount } = useMessagingAnalyticsShallowStore('filterHintText', 'isDemoAccount');
  const { defaultBarChartAppearance, exportPdfProps, processedData } = useFormatBarChartData({
    data,
    dataKeysSequence: ['current_patient', 'unknown'],
  });

  useEffect(() => {
    onLoadingStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Tells you the number of messages sent or received with patients. If phone number is present in PMS/EHR then it is marked as Patient.'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Contact Type: Patient vs Unknown')}
      />
      <Chart.BarChart appearance={defaultBarChartAppearance} data={processedData.chartData} />
      <Chart.Legends formatValue={formatters.value.format} values={processedData.totals} />
    </Chart>
  );
});

PatientsVsUnknownChart.displayName = 'PatientsVsUnknownChart';
