import { useEffect } from 'react';
import { useOnClickOutside } from '@frontend/design-system';
import { useTeamChatStore } from '../providers';

interface UseTeamChatFocusProps {
  chatRef: React.MutableRefObject<HTMLDivElement | null>;
}

export const useTeamChatFocus = ({ chatRef }: UseTeamChatFocusProps) => {
  const { setIsTeamChatFocused, isTeamChatFocused } = useTeamChatStore(['setIsTeamChatFocused', 'isTeamChatFocused']);

  const handler = (e: MouseEvent) => {
    if (
      chatRef.current &&
      e.target &&
      !isTeamChatFocused &&
      (chatRef.current.contains(e.target as Node) || chatRef.current.isSameNode(e.target as Node))
    ) {
      setIsTeamChatFocused(true);
    }
  };

  useOnClickOutside({
    ref: chatRef,
    handler: () => isTeamChatFocused && setIsTeamChatFocused(false),
  });

  useEffect(() => {
    document.addEventListener('mousedown', handler);

    () => {
      document.removeEventListener('mousedown', handler);
    };
  }, []);
};
