import { useMemo } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { formatters } from '../../../../utils';
import { PatientsBreakdownTable } from '../components';
import { MCTChartsBase } from './mct-charts-base';

// TODO: Replace with real data
const dummyData = {
  existingPatients: 186,
  messagesSent: 860,
  newPatients: 292,
  patientsContacted: 568,
  unscheduledLeads: 90,
};

export const MCTMessagesSentChart = () => {
  const { t } = useTranslation('analytics');

  const chartData = useMemo(() => {
    return {
      centerMetric: formatters.value.format(
        dummyData.newPatients + dummyData.existingPatients + dummyData.unscheduledLeads
      ),
      centerMetricLabel: t('Total'),
      groups: [
        {
          name: 'newPatients',
          value: dummyData.newPatients,
        },
        {
          name: 'existingPatients',
          value: dummyData.existingPatients,
        },
        {
          name: 'unscheduledLeads',
          value: dummyData.unscheduledLeads,
        },
      ],
    };
  }, [dummyData]);

  return (
    <MCTChartsBase
      chartConfig={{
        data: chartData,
        type: 'pie',
      }}
      legendsFormatValue={formatters.value.format}
      legendsValues={dummyData}
      message={
        <Trans t={t}>
          Patients were contacted through <strong>{{ messagesSent: dummyData.messagesSent }}</strong> messages sent
        </Trans>
      }
      title={t('Messages Sent')}
      value={formatters.value.format(dummyData.patientsContacted)}
    >
      <PatientsBreakdownTable />
    </MCTChartsBase>
  );
};
