import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const spacing = css({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
});

export const confirmationPage = css({
  maxWidth: 544,
  marginLeft: theme.spacing(4),
  marginTop: theme.spacing(-4),
  textAlign: 'center',
  p: {
    marginBottom: theme.spacing(6),
  },
  h1: {
    marginTop: theme.spacing(6),
  },
  button: {
    width: 'fit-content',
  },
});

export const textArea = css({
  minHeight: 120,
  marginTop: theme.spacing(2),
});

export const listRowRecipientList = css({
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
});

export const listRowTitle = css({
  display: 'flex',
});

export const recipientCheckList = css({
  margin: theme.spacing(2, -3, 3, -3),
  padding: 0,
  height: 340,
  overflowY: 'scroll',
});

export const formRow = css({
  alignItems: 'center',
  marginBottom: 0,
});

export const recipientCardSpacing = css({
  margin: theme.spacing(3, 0),
});

export const switchField = css({
  label: {
    fontSize: theme.fontSize(12),
    marginRight: theme.spacing(1),
    width: 'fit-content',
  },
});

export const nameSort = css({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const nameSortIcon = (isAscending: boolean) =>
  css({
    color: theme.colors.neutral40,
    marginLeft: theme.spacing(1),
    transformOrigin: 'center center',
    transitionDuration: '0.2s',
    transitionProperty: 'transform',
    transform: isAscending ? 'rotate(0)' : 'rotate(180deg)',
  });

export const calendar = css({
  margin: theme.spacing(3, 0, 4, 2),
});

export const title = css({
  textTransform: 'capitalize',
});

export const longChip = (isMobile: boolean) =>
  css({
    overflow: 'visible',
    maxWidth: 'unset',
    margin: isMobile ? theme.spacing(1, 0) : theme.spacing(0, 1, 0.5, 1),
  });
