import { useCallback } from 'react';
import { Reader, ConnectOptions, ExposedError, ErrorResponse } from '@stripe/terminal-js';
import { useMutation } from 'react-query';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, paymentsSentry } from '@frontend/payments-hooks';
import { PaymentsTerminalController, ReaderConnectErrors } from '@frontend/payments-terminal-controller';
import { useAlert } from '@frontend/design-system';
import { devLogger, hasActivePayment, isReaderConnected } from '../../../reader-payment';
import { useTerminalShallowStore } from '../../store';
import { useTerminal } from './use-terminal';

export type ConnectReaderResponse = {
  error?: ExposedError;
  connectedReader: Reader | null;
};

export type ConnectReaderOptions = {
  reader: Reader;
  failIfInUse?: boolean;
};

export const useTerminalMethods = () => {
  const alerts = useAlert();
  const { t } = useTranslation('payments');
  const { stripeLocationId, locationId, paymentsUrl } = useMerchant();
  const { stripeTerminal, disconnectReader } = useTerminal();
  const { connectionError, setConnectionError } = useTerminalShallowStore('connectionError', 'setConnectionError');

  const currentReader = stripeTerminal?.getConnectedReader();
  const connectionStatus = stripeTerminal?.getConnectionStatus();
  const paymentStatus = stripeTerminal?.getPaymentStatus();

  const connectReaderHelper = async (reader: Reader, config?: ConnectOptions): Promise<ConnectReaderResponse> => {
    try {
      if (!locationId || !paymentsUrl) throw new Error('Location Id or Payments Url is empty');
      const result = await stripeTerminal?.connectReader(reader, config);

      const error = (result as ErrorResponse).error;

      if (error) {
        return { error, connectedReader: null };
      }

      const connectedReader = (result as { reader: Reader }).reader;
      PaymentsTerminalController.storeReader(
        locationId,
        paymentsUrl,
        PaymentsTerminalController.formatReaderToTerminalReader(reader)
      );

      return { connectedReader };
    } catch (error) {
      console.error('Unexpected error connecting to terminal', error);
      paymentsSentry.error((error as ExposedError).message, 'connectReader', 'Unexpected error connecting to terminal');
      return { error: error as ExposedError, connectedReader: null };
    }
  };

  const { mutate: connectReader, isLoading: isConnecting } = useMutation<
    Reader | null,
    ExposedError,
    ConnectReaderOptions
  >({
    mutationFn: async ({ reader, failIfInUse = true }) => {
      if (!stripeTerminal) throw { message: t('Stripe terminal instance not set') };
      if (!stripeLocationId) throw { message: t('Stripe Location Id is empty') };

      await disconnectReader(stripeTerminal);

      const config = { fail_if_in_use: failIfInUse };
      const { error: connectError, connectedReader } = await connectReaderHelper(reader, config);

      if (!connectedReader) {
        const error = connectError ?? { message: t('no reader returned') };
        throw error;
      }
      return connectedReader;
    },
    onSuccess: () => {
      alerts.success(t('Terminal Connected'));
    },
    onError: (error) => {
      const connectErrorMsg = t('Failed to connect to terminal');
      if (error?.message !== ReaderConnectErrors.alreadyInUse) alerts.error(connectErrorMsg);
      setConnectionError(error);
    },
  });

  const clearReaderDisplay = useCallback(
    async (sentryContextName = 'clearReaderDisplay') => {
      try {
        if (isReaderConnected(stripeTerminal)) {
          await stripeTerminal?.clearReaderDisplay();
        }
      } catch (error) {
        console.error('unexpected error clearing reader display in ', sentryContextName, error);
        paymentsSentry.error(
          (error as ExposedError).message,
          sentryContextName,
          'unexpected error clearing reader display'
        );
      }
    },
    [stripeTerminal]
  );

  const cancelPayment = useCallback(
    async (sentryContextName = 'handleCancelCollectPaymentMethod') => {
      devLogger.log('Canceling Payment');
      try {
        if (hasActivePayment(stripeTerminal)) {
          await stripeTerminal?.cancelCollectPaymentMethod();
        }
      } catch (error) {
        console.error('unexpected failure canceling collect payment method in ', sentryContextName, error);
      }
    },
    [stripeTerminal]
  );

  return {
    currentReader,
    connectionError,
    connectionStatus,
    paymentStatus,
    isConnecting,
    connectReader,
    connectReaderHelper,
    disconnectReader,
    clearReaderDisplay,
    cancelPayment,
  };
};
