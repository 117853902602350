import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Button, Combobox, FieldChangeEvent, PhoneField, useFormField } from '@frontend/design-system';
import { ReducerDispatchAction } from '../hooks/use-contact-details-reducer';

export const ContactTextField = ({
  number,
  label,
  removeable,
  onDispatch,
  index,
  contactId,
  contactName,
}: {
  number: string;
  label: string;
  index: string;
  removeable: boolean;
  onDispatch: (action: ReducerDispatchAction) => void;
  contactId: string;
  contactName: string;
}) => {
  const { t } = useTranslation('phone');
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const phoneNumberField = useFormField(
    { type: 'phone', value: number, format: 'hyphenated', required: true, validateOnChange: true },
    []
  );
  const labelField = useFormField({ type: 'text', value: label, required: true, validateOnChange: true }, [label]);

  const onRemoveNumber = (index: string) => {
    onDispatch({
      type: 'REMOVE_NUMBER',
      payload: {
        id: contactId,
        name: contactName,
        phoneNumberIndex: index,
      },
    });
  };

  const labelFieldProps = {
    ...labelField,
    onChange: (e?: FieldChangeEvent) => {
      labelField.onChange(e);

      onDispatch({
        type: 'UPDATE_LABEL',
        payload: {
          id: contactId,
          name: contactName,
          phoneNumberIndex: index,
          // @ts-expect-error TODO: Fix types involved with this onChange.
          phoneNumberLabel: e?.target?.value ?? e.value,
          phoneNumberNumber: number,
        },
      });
    },
  };

  const phoneNumberFieldProps = {
    ...phoneNumberField,
    onChange: (e?: FieldChangeEvent) => {
      phoneNumberField.onChange(e);

      onDispatch({
        type: 'UPDATE_NUMBER',
        payload: {
          id: contactId,
          name: contactName,
          phoneNumberIndex: index,
          phoneNumberLabel: label,
          // @ts-expect-error TODO: Fix types involved with this onChange.
          phoneNumberNumber: e.value,
        },
      });
    },
  };

  const onDeleteNumber = () => {
    onRemoveNumber(index);
  };

  return (
    <div
      css={{
        display: 'flex',
        gap: theme.spacing(1),
      }}
    >
      {removeable && (
        <Button
          destructive
          onClick={onDeleteNumber}
          variant='secondary'
          css={{ minWidth: theme.spacing(5), marginTop: theme.spacing(0.5) }}
          iconName='remove-small'
        />
      )}
      <div
        css={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          gap: theme.spacing(1),
        }}
      >
        <Combobox
          options={[t('Main'), t('Work'), t('Office'), t('Mobile'), t('Other')]}
          name='label'
          label={t('Label')}
          fieldProps={labelFieldProps}
          itemWidth={150}
        />
        <PhoneField
          containerCss={css`
            width: 100%;
          `}
          {...phoneNumberFieldProps}
          label='Phone Number'
          name='phone-number'
        />
      </div>
    </div>
  );
};
