import React, {
  useState,
  useReducer,
  useLayoutEffect,
  useRef,
  ElementRef,
  PropsWithChildren,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { css } from '@emotion/react';
import {
  useClientPoint,
  useInteractions,
  useFloating,
  offset,
  flip,
  shift,
  useHover,
  autoUpdate,
} from '@floating-ui/react';
import useResizeObserver from '@react-hook/resize-observer';
import composeRefs from '@seznam/compose-react-refs';
import { motion, AnimatePresence } from 'motion/react';
import { transparentize } from '@frontend/colors';
import { useEventListener } from '@frontend/event';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { debounce, useInterval } from '@frontend/timer';
import { theme } from '@frontend/theme';
import {
  Avatar,
  useTooltip,
  AvatarProps,
  Text,
  styles,
  NakedButton,
  useScrollShadow,
  usePortal,
  useAlert,
} from '@frontend/design-system';
import { isUUID } from '../../views/Calendar/utils';
import { AppointmentEventsCarousel } from './appointments-carousel';
import { OVERLAPPING_EVENTS_BUTTON_WIDTH, PROVIDER_COLUMN_GAP, PROVIDER_COLUMN_MIN_WIDTH } from './constants';
import { EventCard } from './event-card';
import { useCalendarView, useCalendarViewProps } from './providers';
import { timelineReducer, initialTimelineState } from './reducers';
import { CalendarViewOffices, EventData, ProcessedEventData } from './types';
import {
  generateHourLabels,
  calculateTimeProgress,
  scrollContainerToPercentage,
  getTimestamp,
  calculatePercentage,
  checkIntersection,
  zIndex,
  EVENT_CARD_DEFAULT_MEASUREMENTS,
  createGridColumnsFromStartAndEndTimestamp,
  adjustedOneHourInMinutes,
  adjustedOneMinuteInMilliseconds,
  ADJUSTED_ONE_HOUR_IN_MINUTES,
  groupOverlappingEventsV2,
  moveUnassignedProviderEvents,
  moveUnassignedProviderHeading,
} from './utils';

const scrollableButtonWidth = 40;

// This is the width of the vertical hours lane where hours get displayed
const HOURS_LANE_WIDTH = 76;

const verticalLastEventGridSectionMarginRight = scrollableButtonWidth + PROVIDER_COLUMN_GAP;

export const CalendarViewMain = () => {
  const { startHour, endHour, data, id, NoProviderComponent } = useCalendarViewProps();
  const calendarViewWrapperRef = useRef<ElementRef<'article'>>(null);
  const hourLabels = generateHourLabels(startHour, endHour);
  const numberOfHours = hourLabels.length;
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  useLayoutEffect(() => {
    if (calendarViewWrapperRef.current) {
      const calendarViewWrapper = calendarViewWrapperRef.current;
      const { scrollWidth, clientWidth, clientHeight, scrollHeight } = calendarViewWrapper;

      if (clientWidth < scrollWidth || clientHeight < scrollHeight) {
        scrollContainerToPercentage(
          calculateTimeProgress(numberOfHours).progressPercentage,
          calendarViewWrapper,
          isVerticalView
        );
      }
    }
  }, [isVerticalView]);

  const { hasProviders, gridTemplateColumns } = useMemo(() => {
    const hasProviders = data?.some((office) => !!office?.providers?.length);
    // this is use to set the grid-template-columns for the vertical view for both top sticky provider list and the event grid
    const gridTemplateColumns = data?.map((office) => `${(office?.providers?.length || 0) + 1}fr`)?.join(' ');
    return { hasProviders, gridTemplateColumns };
  }, [data]);

  return (
    <>
      <CalendarViewMainWrapper containerRef={calendarViewWrapperRef} id={id}>
        {!hasProviders && NoProviderComponent && <NoProviderComponent />}
        <MultiOfficeProvidersContainer>
          <MultiOfficeProvidersScrollableWrapper containerRef={calendarViewWrapperRef}>
            <MultiOfficeProviders multiOfficeProviders={data} gridTemplateColumns={gridTemplateColumns} />
          </MultiOfficeProvidersScrollableWrapper>
        </MultiOfficeProvidersContainer>
        <CalendarViewContainer numberOfHours={numberOfHours} hasProviders={hasProviders}>
          <BackgroundOverlay />
          <HoursOfTheDay hourLabels={hourLabels} />
          <article
            css={[
              css`
                grid-column: 1/-1;
              `,
              !isVerticalView &&
                css`
                  display: grid;
                  grid-row: 2;
                  grid-auto-rows: auto;
                `,
              isVerticalView &&
                css`
                  grid-row: 1 / -1;
                  padding-left: ${HOURS_LANE_WIDTH}px;
                  padding-right: ${verticalLastEventGridSectionMarginRight}px;
                  display: grid;
                  grid-template-columns: ${gridTemplateColumns};
                  grid-template-rows: auto;
                  gap: ${PROVIDER_COLUMN_GAP}px;
                `,
            ]}
          >
            {data &&
              data?.map((office, index) => {
                // NOTE: This sorts the providers so that the unassigned provider is always at the end of the list
                const sortedProviders = moveUnassignedProviderEvents(office?.providers);

                return (
                  <CalendarEventGridSection key={office.officeName}>
                    <EventTimelineWrapper
                      numberOfHours={numberOfHours}
                      locationId={office.locationId}
                      calendarDateValue={office.calendarDateValue}
                      isRegisterResizeObserver={index === 0 && isVerticalView} // NOTE: Register ResizeObserver only for the first office as every EventTimeLineWrapper has the same width
                    >
                      {office.officeBreaks?.map((breakEvent) => {
                        return (
                          <EventCard
                            key={breakEvent.eventId}
                            ref={calendarViewWrapperRef}
                            id={breakEvent.eventId}
                            title={breakEvent.name}
                            locationName={office.officeName}
                            type={breakEvent.id ? 'outOfOffice' : 'break'}
                            startHour={breakEvent.startHour}
                            endHour={breakEvent.endHour}
                            locationId={office.locationId}
                            laneCount={1}
                            laneIndex={0}
                            visibleLaneCount={1}
                          />
                        );
                      })}
                    </EventTimelineWrapper>
                    {sortedProviders.map((provider) => {
                      return (
                        <EventTimelineWrapper
                          key={provider.providerId}
                          numberOfHours={numberOfHours}
                          providerName={provider.name}
                          providerId={provider.providerId}
                          locationId={office.locationId}
                          calendarDateValue={office.calendarDateValue}
                        >
                          <EventCards
                            events={provider.events}
                            providerId={provider.providerId}
                            providerName={provider.name}
                            locationName={office.officeName}
                            calendarViewWrapperRef={calendarViewWrapperRef}
                          />
                        </EventTimelineWrapper>
                      );
                    })}
                  </CalendarEventGridSection>
                );
              })}
          </article>
          <CalendarEventHourSections numberOfHours={numberOfHours}>
            <HoveredTimeProgressLine numberOfHours={numberOfHours} />
            <TimeProgress numberOfHours={numberOfHours} />
            {hourLabels.map((hourLabels, idx) => (
              <motion.div
                layout='position'
                key={hourLabels + idx}
                css={[
                  css`
                    background: ${theme.colors.neutral20};
                  `,
                  !isVerticalView &&
                    css`
                      width: 1px;
                    `,
                  isVerticalView &&
                    css`
                      height: 1px;
                      z-index: 0;
                    `,
                ]}
              />
            ))}
          </CalendarEventHourSections>
        </CalendarViewContainer>
      </CalendarViewMainWrapper>
      <CalendarScrollButtons containerRef={calendarViewWrapperRef} />
      <AppointmentEventsCarousel />
      <ZoomButton calendarViewWrapperRef={calendarViewWrapperRef} />
    </>
  );
};

interface EventCardsProps {
  events: EventData[];
  providerId: string;
  providerName: string;
  locationName: string;
  calendarViewWrapperRef: React.RefObject<ElementRef<'article'>>;
}

const EventCards: React.FC<EventCardsProps> = ({
  events,
  providerId,
  providerName,
  locationName,
  calendarViewWrapperRef,
}) => {
  const { isVerticalView, visibleLaneCount } = useCalendarView(['isVerticalView', 'visibleLaneCount']);
  const eventsGroupedByOverlap = useMemo(() => groupOverlappingEventsV2(events), [events]);

  return eventsGroupedByOverlap.map((groupEvents) => {
    // For horizontal view, show overlapping events button if there are more than 2 events
    const isHorizontalEventsMaxed = !isVerticalView && groupEvents.length > 2;
    return (
      <>
        {!isHorizontalEventsMaxed &&
          groupEvents.map((event, index) => {
            return (
              <EventCard
                index={index}
                key={event.eventId}
                ref={calendarViewWrapperRef}
                id={event.eventId}
                title={event.name}
                startHour={event.startHour}
                endHour={event.endHour}
                type={event.eventType}
                laneCount={event.laneCount}
                laneIndex={event.laneIndex}
                providerId={providerId}
                locationName={locationName}
                providerName={providerName}
                locationId={event.locationId}
                visibleLaneCount={visibleLaneCount}
              />
            );
          })}

        {(isVerticalView || isHorizontalEventsMaxed) && (
          <OverlappingEvents
            overlappingEvents={groupEvents}
            containerRef={calendarViewWrapperRef}
            locationName={locationName}
          />
        )}
      </>
    );
  });
};

type CalendarScrollButtonsProps = {
  containerRef: React.RefObject<ElementRef<'article'>>;
};

const CalendarScrollButtons = ({ containerRef }: CalendarScrollButtonsProps) => {
  const {
    containerState: { isLeft, isRight },
  } = useCalendarView(['isVerticalView', 'containerState']);

  return (
    <>
      {!isLeft && <ScrollableButton isCircularButton direction='left' containerRef={containerRef} />}
      {!isRight && <ScrollableButton isCircularButton direction='right' containerRef={containerRef} />}
    </>
  );
};

const MultiOfficeProvidersScrollableWrapper = ({
  children,
  containerRef,
}: PropsWithChildren<CalendarScrollButtonsProps>) => {
  const {
    isVerticalView,
    containerState: { isLeft, isRight, isScrollable },
  } = useCalendarView(['isVerticalView', 'containerState']);

  return (
    <>
      <TopCover>
        {isVerticalView && isScrollable && (
          <ScrollableButton disabled={isLeft} direction='left' containerRef={containerRef} />
        )}
      </TopCover>
      {children}
      {isVerticalView && (
        <div
          css={css`
            position: sticky;
            top: 0;
            right: 0;
            width: ${scrollableButtonWidth}px;
            height: 100%;
            z-index: 100;
            background: ${theme.colors.white};
            display: flex;
            justify-content: center;
          `}
        >
          {isScrollable && <ScrollableButton disabled={isRight} direction='right' containerRef={containerRef} />}
        </div>
      )}
    </>
  );
};

type OverlappingEventsProps = {
  overlappingEvents: ProcessedEventData[];
  containerRef: React.RefObject<ElementRef<'article'>>;
  locationName: string;
};

const OverlappingEvents = ({ overlappingEvents, locationName }: OverlappingEventsProps) => {
  const [visible, setVisible] = useState(false);
  const { isVerticalView, setEventCarouselData, isZoomedIn, visibleLaneCount } = useCalendarView([
    'isVerticalView',
    'setEventCarouselData',
    'isZoomedIn',
    'visibleLaneCount',
  ]);
  const { startHour: calendarStartHour, endHour: calendarEndHour } = useCalendarViewProps();

  const hiddenEvents = useMemo(() => {
    return isVerticalView
      ? overlappingEvents.filter((event) => event.laneIndex + 1 > visibleLaneCount)
      : overlappingEvents;
  }, [overlappingEvents, visibleLaneCount, isVerticalView]);

  const { startHour, endHour } = useMemo(
    () => ({ startHour: overlappingEvents?.[0]?.startHour, endHour: overlappingEvents?.[0]?.endHour }),
    [overlappingEvents]
  );

  useEffect(() => {
    setVisible(!!hiddenEvents.length);
  }, [hiddenEvents]);

  if (startHour === 'undefined') return null;

  const { startGridColumn } = createGridColumnsFromStartAndEndTimestamp(
    calendarStartHour,
    calendarEndHour,
    !isVerticalView && isZoomedIn
  )({
    startHourTimestamp: startHour,
    endHourTimeStamp: endHour,
  });

  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0, zIndex: zIndex.eventCardDefault }}
          transition={{ duration: 0.3, ease: 'easeInOut' }}
          css={[
            css`
              z-index: ${zIndex.overlappingEventButton};
              :hover {
                z-index: ${zIndex.eventCardDefault};
              }
            `,
            isVerticalView &&
              css`
                grid-column: 1;
                grid-row: ${startGridColumn};
                right: 0;
                position: absolute;
              `,
            !isVerticalView &&
              css`
                grid-column: ${startGridColumn};
                grid-row: 1;
              `,
          ]}
        >
          <NakedButton
            onClick={(e) => {
              e.stopPropagation();
              setEventCarouselData({
                events: hiddenEvents,
                locationName,
                isOpen: true,
              });
            }}
            onMouseDown={(e) => e.stopPropagation()}
            css={[
              css`
                width: ${OVERLAPPING_EVENTS_BUTTON_WIDTH}px;
                height: 40px;
                color: ${theme.colors.neutral90};
                background-color: ${theme.colors.white};
                border-radius: 500px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: ${theme.font.size.medium};
                font-weight: bold;
                user-select: none;
                cursor: pointer;
                box-shadow: ${theme.shadows.light};
                transition: box-shadow 0.2s ease-in-out, scale 0.2s ease-in-out;
                :hover {
                  scale: 1.1;
                  box-shadow: ${theme.shadows.floating};
                }
              `,
            ]}
          >
            +{hiddenEvents.length}
          </NakedButton>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

type CalendarViewMainWrapperProps = {
  children: React.ReactNode;
  containerRef: React.RefObject<ElementRef<'article'>>;
  id?: string;
};

const CalendarViewMainWrapper = ({ children, containerRef, id }: CalendarViewMainWrapperProps) => {
  const { clientWidth, isLeft, isRight, isScrollable, scrollRef } = useScrollShadow(true);
  const { setContainerState, isVerticalView } = useCalendarView(['setContainerState', 'isVerticalView']);

  useLayoutEffect(() => {
    setContainerState({ isLeft, isRight, isScrollable, clientWidth });
  }, [isLeft, isRight, isScrollable, clientWidth]);

  return (
    <motion.article
      id={id}
      layoutScroll
      ref={composeRefs(containerRef, scrollRef)}
      css={[
        css`
          overflow: auto;
          isolation: isolate;
          height: 100%;
          width: 100%;
          display: grid;
          grid-template-columns: auto 1fr;
          background-color: ${theme.colors.neutral5};
          position: relative;
          overscroll-behavior: contain;
        `,
        isVerticalView &&
          css`
            grid-template-rows: auto 1fr;
            grid-template-columns: auto;
            flex: 1;
          `,
      ]}
    >
      {children}
    </motion.article>
  );
};

const BackgroundOverlay = () => {
  const { backgroundView, isVerticalView } = useCalendarView(['backgroundView', 'isVerticalView']);

  return (
    <AnimatePresence>
      {backgroundView ? (
        <motion.article
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          css={[
            css`
              background-color: rgba(0, 33, 82, 0.1);
              z-index: ${zIndex.backgroundOverlay};
              width: calc(100% + ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px);
              height: 100%;
              position: absolute;
              left: 0;
              margin-left: -${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
              top: 0;
            `,
            isVerticalView &&
              css`
                height: calc(100% + ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px);
                width: calc(100% + ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px);
                margin-top: -${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
              `,
          ]}
        />
      ) : null}
    </AnimatePresence>
  );
};

type TopCoverProps = {
  children: React.ReactNode;
};

const TopCover = ({ children }: TopCoverProps) => {
  return (
    <div
      css={[
        css`
          position: sticky;
          top: 0;
          left: 0;
          height: 100%;
          display: flex;
          justify-content: space-between;
          background: ${theme.colors.white};
          z-index: ${theme.zIndex.higher};
          margin-right: ${theme.spacing(2.5)};
          width: ${scrollableButtonWidth}px;
        `,
      ]}
    >
      {children}
    </div>
  );
};

type MultiOfficeProvidersContainerProps = {
  children: React.ReactNode;
};

const MultiOfficeProvidersContainer = ({ children }: MultiOfficeProvidersContainerProps) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return (
    <motion.section
      css={[
        css`
          box-shadow: ${theme.shadows.floating};
          border-right: 1px solid ${theme.colors.neutral20};
          position: sticky;
          background: ${theme.colors.white};
          z-index: ${zIndex.providerSection};
        `,
        !isVerticalView &&
          css`
            left: 0;
            grid-row: 1 / -1;
            width: 72px;
          `,
        isVerticalView &&
          css`
            width: 100%;
            border-right: none;
            transition: height 0.3s ease;
            max-height: ${theme.spacing(19)};
            top: 0;
            grid-column: 1 / -1;
            display: flex;
            gap: ${PROVIDER_COLUMN_GAP}px;
          `,
      ]}
    >
      {children}
    </motion.section>
  );
};

type ScrollableButtonProps = {
  containerRef: React.RefObject<ElementRef<'article'>>;
  disabled?: boolean;
  direction: 'left' | 'right';
  isCircularButton?: boolean;
};

const ScrollableButton = ({ containerRef, disabled, direction, isCircularButton = false }: ScrollableButtonProps) => {
  const isLeft = direction === 'left';

  const handleScroll = () => {
    if (containerRef.current === null) return;
    // Remove the width of the scrollable buttons from the scroll amount
    const scrollAmount = containerRef.current.clientWidth - scrollableButtonWidth * 2;
    containerRef.current.scrollTo({
      left: containerRef.current.scrollLeft + (isLeft ? -scrollAmount : scrollAmount),
      behavior: 'smooth',
    });
  };

  return (
    <div
      css={[
        css`
          display: flex;
          height: 100%;
          top: 0;
          width: ${scrollableButtonWidth}px;
          align-items: center;
          justify-content: center;
          background: ${theme.colors.white};
          z-index: 100;
        `,
        isCircularButton && {
          position: 'absolute',
          top: 'calc(50% + 50px)',
          right: isLeft ? 'auto' : 0,
          opacity: 0.8,
          borderRadius: theme.borderRadius.full,
          background: theme.colors.neutral10,
          border: `1px solid ${theme.colors.neutral20}`,
          height: 44,
          width: 44,
          margin: theme.spacing(2),
          transition: 'all 0.3s ease',
          ':hover': {
            opacity: 0.9,
            boxShadow: theme.shadows.light,
            background: theme.colors.white,
            border: `1px solid ${theme.colors.primary50}`,
          },
        },
      ]}
    >
      <NakedButton
        css={css`
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.3s ease;
          ${isCircularButton ? `border-radius: ${theme.borderRadius.full}` : ''}
          :hover:not(:disabled) {
            background: ${theme.colors.neutral5};
          }

          box-shadow: ${!isCircularButton ? theme.shadows.light : 'none'};
          :disabled {
            cursor: not-allowed;
            box-shadow: none;
          }
        `}
        disabled={disabled}
        onClick={handleScroll}
      >
        <Icon name={isLeft ? 'caret-left' : 'caret-right'} color={disabled ? 'disabled' : 'subdued'} />
      </NakedButton>
    </div>
  );
};

type CalendarViewContainerProps = PropsWithChildren<{
  numberOfHours: number;
  hasProviders?: boolean;
}>;
const CalendarViewContainer = ({ children, numberOfHours, hasProviders = true }: CalendarViewContainerProps) => {
  const hoveredTimestamp = useRef('0');
  const [isOpen, setIsOpen] = useState(false);

  const { startHour, endHour } = useCalendarViewProps();
  const { backgroundView, setHoveredTimeProgressPosition, isVerticalView, isZoomedIn, floatingTooltipData } =
    useCalendarView([
      'backgroundView',
      'setHoveredTimeProgressPosition',
      'isVerticalView',
      'isZoomedIn',
      'floatingTooltipData',
    ]);

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen && !backgroundView,
    placement: 'top',
    middleware: [offset(20), flip(), shift()],
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
  });

  const clientPoint = useClientPoint(context);
  const hover = useHover(context, { restMs: 1000 });
  const { getReferenceProps, getFloatingProps } = useInteractions([clientPoint, hover]);

  return (
    <>
      <section
        ref={refs.setReference}
        {...getReferenceProps({
          onMouseDown: () => setIsOpen(false),
          onMouseMove: (event) => {
            const timestamp = getTimestamp({
              event,
              element: refs.domReference.current,
              startHour,
              endHour,
              isVerticalView,
              isZoomedIn: !isVerticalView && isZoomedIn,
            });

            if (!timestamp) return;

            const { formattedTime, totalMinutes } = timestamp;

            setHoveredTimeProgressPosition(totalMinutes);
            hoveredTimestamp.current = formattedTime;
          },
        })}
        css={[
          css`
            display: grid;
            position: relative;
            background-color: ${theme.colors.neutral5};
          `,
          !isVerticalView &&
            css`
              margin-left: ${theme.spacing(5)};
              grid-template-columns: repeat(${numberOfHours}, minmax(${adjustedOneHourInMinutes(isZoomedIn)}px, 1fr));
              grid-template-rows: auto auto 1fr;
              height: 100%;
            `,
          isVerticalView &&
            css`
              margin-top: ${theme.spacing(5)};
              grid-template-rows: repeat(${numberOfHours}, 120px);
              grid-template-columns: auto auto 1fr;
              width: 100%;
            `,
          !hasProviders && { overflow: 'hidden' },
        ]}
      >
        {children}
      </section>
      <AnimatePresence>
        {isOpen && !backgroundView ? (
          <motion.div
            ref={refs.setFloating}
            layout={false}
            style={floatingStyles}
            {...getFloatingProps()}
            css={css`
              pointer-events: none;
              z-index: ${theme.zIndex.hints};
            `}
          >
            <TimeTooltip>
              {floatingTooltipData ? (
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                  `}
                >
                  <span
                    css={css`
                      font-weight: ${theme.font.weight.bold};
                    `}
                  >
                    {floatingTooltipData.name}
                  </span>
                  <span
                    css={css`
                      font-size: ${theme.font.size.small};
                    `}
                  >
                    {floatingTooltipData.value}
                  </span>
                </div>
              ) : (
                hoveredTimestamp.current
              )}
            </TimeTooltip>
          </motion.div>
        ) : null}
      </AnimatePresence>
    </>
  );
};

type CalendarEventHourSectionsProps = {
  children: React.ReactNode;
  numberOfHours: number;
};
const CalendarEventHourSections = ({ children, numberOfHours }: CalendarEventHourSectionsProps) => {
  const { isVerticalView, isZoomedIn } = useCalendarView(['isVerticalView', 'isZoomedIn']);

  return (
    <div
      css={[
        css`
          display: grid;
          grid-row: 1/-1;
          grid-column: 1/-1;
          position: relative;
          background-color: ${theme.colors.neutral5};
        `,
        !isVerticalView &&
          css`
            grid-template-columns: repeat(${numberOfHours}, minmax(${adjustedOneHourInMinutes(isZoomedIn)}px, 1fr));
          `,
        isVerticalView &&
          css`
            grid-template-rows: repeat(${numberOfHours}, minmax(${ADJUSTED_ONE_HOUR_IN_MINUTES}px, 1fr));
            z-index: 0;
          `,
      ]}
    >
      {children}
    </div>
  );
};

type CalendarEventGridSectionProps = {
  children: React.ReactNode;
};

const CalendarEventGridSection = ({ children }: CalendarEventGridSectionProps) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return (
    <section
      css={[
        isVerticalView &&
          css`
            height: 100%;
            display: flex;
            column-gap: ${PROVIDER_COLUMN_GAP}px;
          `,
        !isVerticalView &&
          css`
            margin-top: ${theme.spacing(2.5)};
            width: 100%;
          `,
      ]}
    >
      {children}
    </section>
  );
};

type EventTimelineWrapper = {
  children?: React.ReactNode;
  numberOfHours: number;
  providerName?: string;
  providerId?: string;
  locationId?: string;
  calendarDateValue?: string;
  isRegisterResizeObserver?: boolean;
};

const EventTimelineWrapper = ({
  children,
  numberOfHours,
  providerName,
  providerId,
  locationId,
  calendarDateValue,
  isRegisterResizeObserver,
}: EventTimelineWrapper) => {
  const { startHour, endHour, onEventCreate, shouldAllowEventCreation } = useCalendarViewProps();
  const {
    eventTransitionComplete,
    isVerticalView,
    eventCarouselData,
    isZoomedIn,
    setVisibleLaneCountBasedOnTotalWidth,
  } = useCalendarView([
    'eventTransitionComplete',
    'isVerticalView',
    'eventCarouselData',
    'isZoomedIn',
    'setVisibleLaneCountBasedOnTotalWidth',
  ]);
  const [{ isActivated: isEventActivated, eventStartX, eventStartTime, eventEndX, eventEndTime }, dispatch] =
    useReducer(timelineReducer, initialTimelineState);

  const isActivated = isEventActivated && !eventCarouselData.isOpen;
  const [isIntersecting, setIntersecting] = useState(false);
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarViewMain');

  const timelineRef = useRef<ElementRef<'section'>>(null);
  const eventBoxRef = useRef<ElementRef<'div'>>(null);

  const handleMouseDown = (e: React.MouseEvent) => {
    if (eventTransitionComplete || !shouldAllowEventCreation) return;
    const shouldRound = !e.shiftKey;
    const timestamp = getTimestamp({
      event: e,
      element: timelineRef.current,
      nearestQuarter: shouldRound,
      startHour,
      endHour,
      isVerticalView,
      isZoomedIn: !isVerticalView && isZoomedIn,
    });

    if (!timestamp) return;

    const { formattedTime, totalMinutes } = timestamp;

    dispatch({
      type: 'ACTIVATE',
      payload: {
        eventStartX: totalMinutes,
        eventEndX: totalMinutes,
        eventStartTime: formattedTime,
        eventEndTime: formattedTime,
      },
    });
  };

  function handleMouseMove(e: MouseEvent) {
    if (!eventBoxRef.current || !timelineRef.current || !shouldAllowEventCreation) return;

    setIntersecting(
      checkIntersection({
        targetElement: eventBoxRef.current,
        containerElement: timelineRef.current,
      })
    );

    const shouldRound = !e.shiftKey;

    const timestamp = getTimestamp({
      event: e,
      element: timelineRef.current,
      nearestQuarter: shouldRound,
      startHour,
      endHour,
      isVerticalView,
      isZoomedIn: !isVerticalView && isZoomedIn,
    });

    if (!timestamp) return;

    const { formattedTime, totalMinutes } = timestamp;

    dispatch({
      type: 'UPDATE_POSITION',
      payload: {
        eventEndX: totalMinutes,
        eventEndTime: formattedTime,
      },
    });
  }

  const handleMouseUp = () => {
    if (!!providerId && !isUUID(providerId ?? '') && shouldAllowEventCreation) {
      !isIntersecting && alert.error(t('Event cannot be created. This provider does not have a provider ID'));
    } else {
      onEventCreate?.(
        eventStartX > eventEndX ? eventEndTime : eventStartTime,
        eventStartX > eventEndX ? eventStartTime : eventEndTime,
        providerName,
        providerId,
        !isIntersecting,
        locationId ?? '',
        calendarDateValue ?? ''
      );
    }

    dispatch({
      type: 'DEACTIVATE',
    });
  };

  useEventListener('mousemove', handleMouseMove, isActivated);
  useEventListener('mouseup', handleMouseUp, isActivated);

  // Observe only provider timeline for resize to update event-cards width (and placement in event group)
  useResizeObserver(
    isRegisterResizeObserver ? timelineRef : null,
    debounce((entry) => setVisibleLaneCountBasedOnTotalWidth(Math.floor(entry.contentRect.width)), 100)
  );

  const disableEventCreate = isIntersecting || (!!providerId && !isUUID(providerId ?? ''));

  return (
    <section
      ref={timelineRef}
      onMouseDown={handleMouseDown}
      css={[
        css`
          display: grid;
          width: 100%;
          position: relative;
          &::after {
            content: '';
            position: absolute;
            height: 100%;
            transition: 0.2s ease;
            opacity: 0;
            background-color: ${transparentize(0.5, theme.colors.primary10)};
          }
        `,
        !isVerticalView &&
          css`
            grid-template-columns: repeat(${numberOfHours * adjustedOneHourInMinutes(isZoomedIn)}, minmax(1px, 1fr));
            grid-template-rows: ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
            padding: ${theme.spacing(1, 0)};
            &::after {
              border-top: 1px dashed ${theme.colors.primary20};
              border-bottom: 1px dashed ${theme.colors.primary20};
              margin-left: -40px;
              width: calc(100% + 40px);
            }
          `,
        isVerticalView &&
          css`
            grid-template-rows: repeat(${numberOfHours * ADJUSTED_ONE_HOUR_IN_MINUTES}, 1px);
            grid-template-columns: minmax(${PROVIDER_COLUMN_MIN_WIDTH}px, 1fr);
            &::after {
              border-left: 1px dashed ${theme.colors.primary20};
              border-right: 1px dashed ${theme.colors.primary20};
              height: calc(100% + 40px);
              margin-top: -40px;
              width: 100%;
            }
          `,
        eventTransitionComplete &&
          css`
            &::after {
              transition: none;
            }
          `,
        !eventTransitionComplete &&
          css`
            &:hover::after {
              opacity: 1;
            }
            z-index: ${zIndex.eventTimelineWrapper};
          `,
      ]}
    >
      {children}
      <AnimatePresence>
        {isActivated && (
          <motion.div
            ref={eventBoxRef}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              background: `${
                disableEventCreate ? transparentize(0.2, theme.colors.critical20) : theme.colors.primary20
              }`,
              border: `1px dashed ${
                disableEventCreate ? transparentize(0.2, theme.colors.critical50) : theme.colors.primary40
              }`,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            css={[
              css`
                position: relative;
                z-index: ${zIndex.eventCreatorCard};
                border-radius: 4px;
                user-select: none;
                background: ${isIntersecting ? theme.colors.critical20 : theme.colors.primary20};
                border: 1px solid ${isIntersecting ? theme.colors.critical50 : theme.colors.primary40};
              `,
              !isVerticalView &&
                css`
                  height: ${EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight}px;
                  grid-row: 1;
                  grid-column: ${eventStartX + 1} / ${eventEndX + 1};
                `,
              isVerticalView &&
                css`
                  grid-column: 1;
                  grid-row: ${eventStartX + 1} / ${eventEndX + 1};
                `,
            ]}
          >
            <motion.div
              css={css`
                pointer-events: none;
                z-index: ${theme.zIndex.hints};
                position: absolute;
                left: 50%;
                top: -60px;
                transform: translateX(-50%);
                width: auto;
              `}
            >
              <TimeTooltip>
                {eventEndX - eventStartX < 0
                  ? `${eventEndTime} - ${eventStartTime}`
                  : `${eventStartTime} - ${eventEndTime}`}
              </TimeTooltip>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

type HoursOfTheDayProps = {
  hourLabels: string[];
};
const HoursOfTheDay = ({ hourLabels }: HoursOfTheDayProps) => {
  const numberOfHours = hourLabels.length;
  const { isVerticalView, isZoomedIn } = useCalendarView(['isVerticalView', 'isZoomedIn']);

  return (
    <motion.section
      layout='position'
      css={[
        css`
          position: sticky;
          z-index: ${zIndex.hoursOfTheDay};
          display: grid;
          grid-column: 1/-1;
          background-color: ${theme.colors.neutral5};
        `,
        !isVerticalView &&
          css`
            padding-top: ${theme.spacing(3)};
            height: 54px;
            grid-template-columns: repeat(${numberOfHours}, minmax(${adjustedOneHourInMinutes(isZoomedIn)}px, 1fr));
            grid-row: 1 / auto;
            top: 0;
          `,
        isVerticalView &&
          css`
            grid-template-rows: repeat(${numberOfHours}, ${ADJUSTED_ONE_HOUR_IN_MINUTES}px);
            grid-row: 1 / -1;
            height: calc(100% + 40px);
            margin-top: -40px;
            padding-top: 40px;
            width: ${HOURS_LANE_WIDTH}px;
            left: 0;
          `,
      ]}
    >
      {hourLabels.map((hour, index) => (
        <motion.div
          layout='position'
          key={hour + index}
          css={[
            css`
              display: flex;
            `,
            !isVerticalView &&
              css`
                justify-content: center;
              `,
            isVerticalView &&
              css`
                justify-content: flex-end;
                padding-right: ${theme.spacing(2)};
              `,
          ]}
        >
          <Text
            css={[
              css`
                margin: 0;
                user-select: none;
              `,
              !isVerticalView &&
                css`
                  margin-left: -100%;
                `,
              isVerticalView &&
                css`
                  margin-top: -9px;
                `,
            ]}
            size='small'
            color='light'
          >
            {hour}
          </Text>
        </motion.div>
      ))}
    </motion.section>
  );
};

type MultiOfficeProviders = {
  multiOfficeProviders: CalendarViewOffices;
  gridTemplateColumns: string;
};

const MultiOfficeProviders = ({ multiOfficeProviders, gridTemplateColumns }: MultiOfficeProviders) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  const locationCount = multiOfficeProviders?.length ?? 0;
  return (
    <div
      css={
        isVerticalView
          ? css`
              display: grid;
              grid-template-columns: ${gridTemplateColumns};
              grid-template-rows: auto;
              flex: 1;
              gap: ${theme.spacing(2)};
            `
          : css`
              display: flex;
              flex-direction: column;
            `
      }
    >
      {!!multiOfficeProviders &&
        multiOfficeProviders?.map((provider, index) => (
          <section
            key={`${provider.officeName}-${index}`}
            css={[
              isVerticalView &&
                css`
                  display: flex;
                  flex: 1;
                  max-height: 150px;
                  position: relative;
                  transition: height 0.3s ease;
                  align-items: center;
                  gap: ${theme.spacing(2)};
                  ${index !== 0 &&
                  `::after {
                    content: '';
                    position: absolute;
                    width: 2px;
                    height: 60px;
                    background: ${theme.colors.neutral5};
                    top: ${theme.spacing(1)};
                    left: -10px;
                  }`};
                `,
              !isVerticalView &&
                css`
                  margin-top: ${theme.spacing(2.5)};
                `,
            ]}
          >
            <MultiOfficeSectionWrapper
              officeName={provider.officeName}
              providerCount={provider?.providers?.length ?? 0}
              showLocationHeader={locationCount > 1}
            >
              {isVerticalView && (
                <>
                  <section
                    css={css`
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      justify-content: space-between;
                      min-width: ${PROVIDER_COLUMN_MIN_WIDTH}px;
                      gap: ${theme.spacing(1)};
                      flex: 1;
                      padding: ${theme.spacing(1.5)};
                    `}
                  >
                    <Icon
                      name='location'
                      color='subdued'
                      height={32}
                      width={32}
                      css={{
                        margin: theme.spacing(0.5),
                        padding: theme.spacing(0.5),
                        background: theme.colors.neutral5,
                        borderRadius: theme.borderRadius.medium,
                      }}
                    />
                    <Text
                      css={[
                        styles.truncate,
                        { maxWidth: `calc(${PROVIDER_COLUMN_MIN_WIDTH}px - ${theme.spacing(3)})`, flex: 'none' },
                      ]}
                      size='medium'
                      textAlign='center'
                      weight='bold'
                    >
                      {provider?.officeName}
                    </Text>
                  </section>
                </>
              )}
              <OfficeProviders
                officeName={provider?.officeName}
                providersData={provider?.providers?.map((provider) => {
                  return { name: provider.name, src: provider.src };
                })}
              />
            </MultiOfficeSectionWrapper>
          </section>
        ))}
    </div>
  );
};

type UnassignedProviderTypes = {
  name: string;
};

const UnassignedProvider = ({ name }: UnassignedProviderTypes) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);
  const capitalizedProviderName = name.charAt(0).toUpperCase() + name.slice(1);

  return (
    <>
      {isVerticalView ? (
        <>
          <Icon
            name='help'
            color='subdued'
            height={32}
            width={32}
            css={{
              margin: theme.spacing(0.5),
              padding: theme.spacing(0.5),
              background: theme.colors.neutral5,
              borderRadius: theme.borderRadius.medium,
            }}
          />
          <Text size='medium' textAlign='center' weight='bold'>
            {capitalizedProviderName}
          </Text>
        </>
      ) : (
        <section css={[styles.flexCenter, { height: 56, padding: theme.spacing(1) }, styles.truncate]}>
          <OfficeName>{capitalizedProviderName}</OfficeName>
        </section>
      )}
    </>
  );
};

type MultiOfficeSectionWrapperProps = {
  children: React.ReactNode;
  officeName: string;
  providerCount: number;
  showLocationHeader: boolean;
};

const MultiOfficeSectionWrapper = ({
  children,
  officeName,
  providerCount,
  showLocationHeader,
}: MultiOfficeSectionWrapperProps) => {
  const { isVerticalView, containerState } = useCalendarView(['isVerticalView', 'containerState']);

  return isVerticalView ? (
    <div
      css={css`
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: ${theme.spacing(1.5)};
      `}
    >
      {showLocationHeader && (
        <div css={{ height: 36, width: '100%' }}>
          <div
            css={css`
              display: flex;
              justify-content: center;
              position: sticky;
              max-width: ${containerState?.clientWidth - scrollableButtonWidth}px;
              background: ${theme.colors.neutral5};
              border-radius: ${theme.borderRadius.medium};
              left: 0;
              height: 100%;
            `}
          >
            <SlidingOfficeName
              officeName={officeName}
              maxWidth={(providerCount + 1) * PROVIDER_COLUMN_MIN_WIDTH - 60}
            />
          </div>
        </div>
      )}
      <section
        css={css`
          display: flex;
          gap: ${PROVIDER_COLUMN_GAP}px;
          justify-content: space-around;
        `}
      >
        {children}
      </section>
    </div>
  ) : (
    <>{children}</>
  );
};

type SlidingOfficeName = {
  officeName: string;
  maxWidth: number;
};

const SlidingOfficeName = ({ officeName, maxWidth }: SlidingOfficeName) => (
  <div
    css={css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(0.5)};
      position: sticky;
      left: 116px;
      right: 56px;
      margin: 0 ${theme.spacing(2)};
    `}
  >
    <Icon name='location' color='subdued' />
    <Text css={[styles.truncate, { maxWidth }]} textAlign='center' size='medium' weight='bold' color='subdued'>
      {officeName}
    </Text>
  </div>
);

type ProviderNameProps = {
  providerName: string;
};

const ProviderName = ({ providerName }: ProviderNameProps) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'bottom',
    hoverDelay: 300,
  });

  return (
    <div css={styles.flexCenter} {...triggerProps}>
      <Text
        textAlign='center'
        size='medium'
        weight='bold'
        css={[{ maxWidth: `calc(${PROVIDER_COLUMN_MIN_WIDTH}px - ${theme.spacing(3)})` }, styles.truncate]}
      >
        {providerName}
      </Text>
      <Tooltip {...tooltipProps}>{providerName}</Tooltip>
    </div>
  );
};

type OfficeProviders = {
  providersData: { name: string; src: string }[];
  officeName: string;
};
const OfficeProviders = ({ providersData, officeName }: OfficeProviders) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);
  // NOTE: This sorts the providers so that the unassigned provider is always at the end of the list
  const sortedProviders = moveUnassignedProviderHeading(providersData);

  return (
    <>
      {!isVerticalView && (
        <section css={[styles.flexCenter, { height: 56, padding: theme.spacing(1) }]}>
          <OfficeName>{officeName}</OfficeName>
        </section>
      )}
      {sortedProviders.map((providerData, idx) => (
        <section
          key={providerData.name + idx}
          css={[
            css`
              display: flex;
              justify-content: space-between;
            `,
            !isVerticalView &&
              css`
                justify-content: center;
                padding: ${theme.spacing(1)};
              `,
            isVerticalView &&
              css`
                min-width: ${PROVIDER_COLUMN_MIN_WIDTH}px;
                flex: 1;
                align-items: center;
                flex-direction: column;
                padding: ${theme.spacing(1.5)};
                gap: ${theme.spacing(1)};
              `,
          ]}
        >
          {providerData.name.toLowerCase() === 'unassigned' ? (
            <UnassignedProvider name={providerData.name} />
          ) : (
            <>
              <ProviderAvatar {...providerData} />
              {isVerticalView && <ProviderName providerName={providerData.name} />}
            </>
          )}
        </section>
      ))}
    </>
  );
};

type OfficeNameProps = {
  children: React.ReactNode;
};
const OfficeName = ({ children }: OfficeNameProps) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'bottom', hoverDelay: 300 });

  return (
    <>
      <Text
        size='small'
        css={[
          css`
            margin: 0;
            border-radius: ${theme.borderRadius.small};
            padding: ${theme.spacing(0.25, 1)};
            background: ${theme.colors.neutral5};
          `,
          styles.truncate,
        ]}
        {...triggerProps}
      >
        {children}
      </Text>
      <Tooltip {...tooltipProps}>{children}</Tooltip>
    </>
  );
};

type ProviderAvatarProps = Omit<AvatarProps, 'size'>;
const ProviderAvatar = (props: ProviderAvatarProps) => {
  return (
    <Avatar
      {...props}
      isUser
      css={css`
        place-self: center;
        justify-content: center;
      `}
    />
  );
};

type TimeProgressProps = {
  numberOfHours: number;
};

const TimeProgress = ({ numberOfHours }: TimeProgressProps) => {
  const { isVerticalView, isZoomedIn } = useCalendarView(['isVerticalView', 'isZoomedIn']);

  const [timeProgressPercentage, setTimeProgressPercentage] = useState(
    calculateTimeProgress(numberOfHours).progressPercentage
  );
  const [hasCrossedTotalDuration, setHasCrossedTotalDuration] = useState(
    calculateTimeProgress(numberOfHours).hasCrossedTotalDuration
  );

  useInterval(
    () => {
      const { hasCrossedTotalDuration, progressPercentage } = calculateTimeProgress(numberOfHours);
      setTimeProgressPercentage(progressPercentage);
      setHasCrossedTotalDuration(hasCrossedTotalDuration);
    },
    adjustedOneMinuteInMilliseconds(isZoomedIn && !isVerticalView),
    hasCrossedTotalDuration
  );

  return (
    <ProgressLine
      show={!hasCrossedTotalDuration}
      percentage={timeProgressPercentage}
      color={theme.colors.primary50}
      scrollIntoView
    />
  );
};

const HoveredTimeProgressLine = ({ numberOfHours }: { numberOfHours: number }) => {
  const { hoveredTimeProgressPosition, backgroundView, isZoomedIn, isVerticalView } = useCalendarView([
    'hoveredTimeProgressPosition',
    'backgroundView',
    'isZoomedIn',
    'isVerticalView',
  ]);
  const progressLinePosition = calculatePercentage(
    hoveredTimeProgressPosition,
    numberOfHours * adjustedOneHourInMinutes(isZoomedIn && !isVerticalView)
  );

  return (
    <ProgressLine
      show={!backgroundView && progressLinePosition < 99.5}
      percentage={progressLinePosition}
      color={theme.colors.critical40}
    />
  );
};

type ProgressLineProps = {
  percentage: number;
  show?: boolean;
  color: string;
  scrollIntoView?: boolean;
};

const ProgressLine = ({ percentage, show = true, color, scrollIntoView = false }: ProgressLineProps) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current && scrollIntoView) {
      // scroll vertically to the progress line (for current time progress line)
      ref.current.scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [scrollIntoView]);

  return show ? (
    <div
      ref={ref}
      css={[
        css`
          position: absolute;
          z-index: ${zIndex.progressLine};
        `,
        !isVerticalView &&
          css`
            height: 100%;
            width: 1.25px;
            left: ${percentage}%;
            height: 100%;
            background: ${color};
          `,
        isVerticalView &&
          css`
            height: 1.25px;
            top: ${percentage}%;
            width: 100%;
            left: 0;
          `,
      ]}
    >
      <div
        css={[
          css`
            position: absolute;
          `,
          !isVerticalView &&
            css`
              top: -5px;
              left: 50%;
              height: 100%;
              transform: translateX(-50%);
            `,
          isVerticalView &&
            css`
              left: 50px;
              right: 0;
              top: 50%;
              height: 1px;
              transform: translateY(-50%);
              background: ${color};
            `,
        ]}
      >
        <div
          css={[
            css`
              position: sticky;
              width: 11px;
              height: 11px;
              background: ${color};
              border-radius: 50px;
            `,
            !isVerticalView &&
              css`
                top: 54px;
              `,
            isVerticalView &&
              css`
                left: ${HOURS_LANE_WIDTH}px;
                top: 50%;
                transform: translateY(-50%);
              `,
          ]}
        />
      </div>
    </div>
  ) : null;
};

type TimeTooltip = {
  children: React.ReactNode;
};
const TimeTooltip = ({ children }: TimeTooltip) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0 }}
      css={css`
        pointer-events: none;
        user-select: none;
        border-radius: ${theme.borderRadius.medium};
        background: ${theme.colors.black};
        color: ${theme.colors.white};
        padding: ${theme.spacing(1.5)};
        white-space: nowrap;
      `}
    >
      {children}
    </motion.div>
  );
};

type ZoomButtonProps = {
  calendarViewWrapperRef: React.RefObject<ElementRef<'article'>>;
};

const ZoomButton = ({ calendarViewWrapperRef }: ZoomButtonProps) => {
  const { Portal } = usePortal({
    mountTarget: '#calendar-view-wrapper',
    attributes: {
      style: `position: absolute; bottom: 25px; right: 21px; z-index: ${theme.zIndex.highest};`,
    },
  });
  const { isZoomedIn, setZoomedIn, isVerticalView } = useCalendarView(['isZoomedIn', 'setZoomedIn', 'isVerticalView']);

  const handleZoom = useCallback(() => {
    setZoomedIn(!isZoomedIn);
    if (!calendarViewWrapperRef.current) return;
    calendarViewWrapperRef.current.scrollLeft = isZoomedIn
      ? calendarViewWrapperRef.current.scrollLeft / 4
      : calendarViewWrapperRef.current.scrollLeft * 4;
  }, [isZoomedIn, setZoomedIn]);

  if (isVerticalView) return null;

  return (
    <Portal>
      <NakedButton
        onClick={handleZoom}
        css={css`
          width: 40px;
          height: 40px;
          background: ${theme.colors.white};
          border-radius: ${theme.borderRadius.full};
          box-shadow: ${theme.shadows.floating};
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background 0.3s ease;
          :hover,
          :focus {
            background: ${theme.colors.primary10};
          }
        `}
      >
        <Icon name={isZoomedIn ? 'zoom-out' : 'zoom-in'} />
      </NakedButton>
    </Portal>
  );
};
