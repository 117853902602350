import { css } from '@emotion/react';
import { DateTypeFilter } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';

export type FilterHeaderProps = {
  dateType?: DateTypeFilter;
};
export const FilterHeader = ({ dateType = DateTypeFilter.Paid }: FilterHeaderProps) => {
  const { t } = useTranslation('payments');
  const dateTypeLabel = dateType === DateTypeFilter.Paid ? t('Paid & Refunded') : t('Created');

  return (
    <Heading
      level={3}
      as='h3'
      css={css`
        margin-bottom: ${theme.spacing(3)};
        flex-grow: 1;
        text-transform: capitalize;
      `}
    >
      {dateTypeLabel}
    </Heading>
  );
};
