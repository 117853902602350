import { HTMLAttributes } from 'react';
import { useStyles } from '../../../use-styles';
import { Text } from '../../text';
import { useTemplateEditorValue, useTemplateSettings } from '../message-template.context';
import { getNodeCharCount } from './get-node-char-count';
import { CharCountOptions } from './get-template-char-count';

type TemplateCharCountProps = HTMLAttributes<HTMLParagraphElement> &
  CharCountOptions & {
    hasError?: boolean;
    limit?: number;
  };

export const TemplateCharCount = ({ hasError, limit, trimWhitespace }: TemplateCharCountProps) => {
  const templateCharCountStyles = useStyles('MessageTemplate', 'templateCharCount');
  const nodes = useTemplateEditorValue();
  const { shortenUrlsTo } = useTemplateSettings();

  return (
    <div className='message-template-char-count' css={templateCharCountStyles}>
      <Text size='large' weight='bold' as='span' color={hasError ? 'error' : 'default'}>
        {getNodeCharCount(nodes, { shortenUrlsTo, trimWhitespace })}
        {Number.isInteger(limit) && ` / ${limit}`}
      </Text>
      <Text as='span' size='small' color={hasError ? 'error' : 'default'}>
        {' '}
        characters
      </Text>
    </div>
  );
};
