import { FC } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { BuilderTypes } from '@frontend/forms-builder-engine';
import { DroppableType } from '../../../../../constants';
import { BuilderFieldDivisionTypes } from '../../../../../types';
import {
  getOtherFieldDraggableId,
  getPrimaryFieldDraggableId,
  getFieldCategoryDroppableId,
  getPaymentsFieldDraggableId,
} from '../../../../../utils';
import DraggableField from '../../draggable-field/draggable-field.component';
import { builderFieldsStyle } from './droppable-field-list.styles';

interface PrimaryFieldCategoryProps {
  category: BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory;
  fields: BuilderTypes.BuilderFormElementTypes.PrimaryFields.ParsedFields;
  type: 'primary';
  usedFields: BuilderTypes.BuilderFormElementTypes.PrimaryFields.UsedPrimaryFields;
}

interface PaymentsFieldCategoryProps {
  category: BuilderFieldDivisionTypes.PaymentsFieldsDivisionCategory;
  fields: BuilderTypes.BuilderFormElementTypes.PaymentsFields.ParsedFields;
  type: 'payments';
}

interface OtherFieldCategoryProps {
  category: BuilderFieldDivisionTypes.OtherFieldsDivisionCategory;
  fields: BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFields;
  type: 'other';
}

type DropableFieldListProps = PrimaryFieldCategoryProps | PaymentsFieldCategoryProps | OtherFieldCategoryProps;

const DroppableFieldList: FC<DropableFieldListProps> = (props) => {
  const { category, fields, type } = props;

  return (
    <Droppable droppableId={getFieldCategoryDroppableId(category.label)} isDropDisabled type={DroppableType.FIELD}>
      {(provided) => (
        <ul css={builderFieldsStyle} {...provided.droppableProps} ref={provided.innerRef}>
          {category.fieldsOrder.map((field, fieldOrderIndex) => {
            let displayName = '';
            let draggableId = '';
            let isAlreadyUsed = false;
            const isSyncable = field.syncable || false;

            switch (type) {
              case 'primary': {
                const fieldKey = field.key as BuilderTypes.BuilderFormElementTypes.PrimaryFields.PrimaryFieldKey;
                const fieldData = fields[fieldKey];
                displayName = fieldData.meta.displayName;
                draggableId = getPrimaryFieldDraggableId(fieldKey);
                isAlreadyUsed = !!props.usedFields[fieldKey];
                break;
              }

              case 'payments': {
                const fieldKey = field.key as BuilderTypes.BuilderFormElementTypes.PaymentsFields.ParsedFieldKey;
                const fieldData = fields[fieldKey];
                displayName = fieldData.meta.displayName;
                draggableId = getPaymentsFieldDraggableId(fieldKey);
                break;
              }

              default: {
                const fieldKey = field.key as BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFieldsKey;
                const fieldData = fields[fieldKey];
                displayName = fieldData.meta.displayName;
                draggableId = getOtherFieldDraggableId(fieldKey);
                break;
              }
            }

            const isSubdued = !isSyncable;
            return (
              <DraggableField
                displayName={displayName}
                index={fieldOrderIndex}
                draggableId={draggableId}
                key={field.key}
                appearance={isAlreadyUsed ? 'disabled' : isSubdued ? 'subdued' : 'default'}
                trackingId={field.trackingId}
              />
            );
          })}
        </ul>
      )}
    </Droppable>
  );
};

export default DroppableFieldList;
