import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text, Button } from '@frontend/design-system';

type NoDevicePairedModalProps = {
  modalProps: ModalControlModalProps;
  pairDeviceButton?: React.ReactNode;
};

export const NoDevicePairedModal = ({ modalProps, pairDeviceButton }: NoDevicePairedModalProps) => {
  const { t } = useTranslation('phone-call');

  return (
    <div>
      <Modal {...modalProps} maxWidth={400}>
        <Modal.Header onClose={modalProps.onClose} title={t('No Device Paired')}>
          {t('No Device Paired')}
        </Modal.Header>
        <Modal.Body css={styles.container}>
          <section css={styles.attribute}>
            <Text as='span'>{t('You must pair a phone device in order to place a call through the Weave app.')}</Text>
          </section>
        </Modal.Body>
        <Modal.Footer
          css={css`
            grid-template-columns: 1fr auto;
            justify-items: end;
          `}
        >
          <Button variant='secondary' onClick={modalProps.onClose}>
            {t('Cancel')}
          </Button>
          {pairDeviceButton}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const styles = {
  attribute: css`
    margin-top: ${theme.spacing(2)};
    max-width: 400px;

    @media (max-width: 400px) {
      max-width: auto;
    }
  `,

  container: css`
    margin-bottom: ${theme.spacing(2)};
  `,
};
