import { useCallback } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { Recipient } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb.js';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BulkMessageHooks } from '@frontend/bulk-message-service';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, ConfirmationModal, Text, useAlert, useModalControl } from '@frontend/design-system';
import { useShowBulkMessageButton } from '../../hooks';
import { trackingIds } from '../../tracking-ids';

interface Props {
  isLoadingData?: boolean;
  selection?: PracticeAnalyticsTypes.PatientInfo[];
  trackingIdBase?: string;
}

export const BulkMessageButton = ({ isLoadingData, selection = [], trackingIdBase }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { setRecipients } = BulkMessageHooks.useBulkTextPassedRecipientsShallowStore('setRecipients');
  const navigate = useNavigate();
  const confirmModalControl = useModalControl();
  const isBulkMessagingEnabled = useShowBulkMessageButton();
  const selectionCount = selection.length;

  const navigaetToBulkMessaging = useCallback(() => {
    const recipients: Recipient[] = [];

    selection.forEach(({ FirstName, id, LastName, locationId, MobilePhone }) => {
      if (MobilePhone) {
        recipients.push({
          firstName: FirstName,
          lastName: LastName,
          locationId,
          recipientId: id,
          smsNumber: MobilePhone,
        });
      }
    });

    setRecipients(recipients);
    navigate({
      to: '/bulk/all-campaigns/text/new?usePassedRecipients=true',
    });
  }, [navigate, selection, setRecipients]);

  const handleBulkMessageClick = () => {
    if (!selection.length) {
      alert.warning(t('Select patients for bulk messaging'));
      return;
    }

    confirmModalControl.openModal();
  };

  if (!isBulkMessagingEnabled) {
    return null;
  }

  return (
    <>
      <Button
        disabled={isLoadingData}
        onClick={handleBulkMessageClick}
        style={{ width: 'auto' }}
        trackingId={`practice-analytics-bulk-messaging-${trackingIdBase}`}
      >
        <Icon name='bulk-messaging-small' style={{ marginLeft: 0, marginRight: theme.spacing(1) }} />
        {t('Bulk Message')}
      </Button>

      <ConfirmationModal
        {...confirmModalControl.modalProps}
        cancelTrackingId={trackingIds.practiceAnalytics.cancelBulkMessage}
        confirmLabel={t('Proceed')}
        confirmTrackingId={trackingIds.practiceAnalytics.confirmBulkMessage}
        message={
          <>
            <Text>
              <Trans t={t}>
                You have selected <strong>{{ selectionCount }} Patients</strong>.
              </Trans>
            </Text>
            <Text>{t('Are you sure you want to proceed with sending bulk message to these patients?')}</Text>
          </>
        }
        onConfirm={navigaetToBulkMessaging}
        title={t('Bulk Message Confirmation')}
      />
    </>
  );
};

BulkMessageButton.displayName = 'BulkMessageButton';
