import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { BarChartAppearance, BarChartData, Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { TimeRangeDefinition } from '../time-range-types';
import { formatTimeValue } from '../util';
import { compareBarChartData, tooltipRenderer } from './tooltip';

dayjs.extend(duration);

const appearance: BarChartAppearance = {
  showYAxis: true,
  showXAxis: true,
  customYAxisTickFormat(value) {
    return formatTimeValue(value);
  },
};

export function AverageWaitChart({
  data,
  pastData,
  groupLabels,
  dateLabel,
  locationLabel,
  timeRangeDefinition,
  isLoading,
}: {
  data: BarChartData;
  pastData: BarChartData;
  groupLabels: Record<string, string>;
  dateLabel?: string;
  locationLabel?: string;
  timeRangeDefinition: TimeRangeDefinition;
  isLoading?: boolean;
}) {
  const { t } = useTranslation('phone');
  const comparisonData = compareBarChartData({
    base: data,
    candidate: pastData,
    timeRangeDefinition,
  });

  return (
    <>
      <Chart
        isLoading={isLoading}
        colors={{
          answer: theme.colors.primary20,
          abandon: theme.colors.warning20,
        }}
        labels={{
          answer: t('Average Wait Before Answer'),
          abandon: t('Average Wait Before Abandon'),
          ...groupLabels,
        }}
      >
        <Chart.Header
          title={t('Average Wait')}
          bottomElement={
            <Text size='medium' as='span' color='subdued'>
              {locationLabel}
            </Text>
          }
          subtitle={dateLabel}
        />
        <Chart.BarChart
          appearance={appearance}
          data={data}
          tooltipRenderer={tooltipRenderer({ comparisonData })}
          formatValue={formatTimeValue}
        />
        <Chart.Legends />
      </Chart>
    </>
  );
}
