import { FallbackRender } from '@sentry/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Modal, Text, useModalControl } from '@frontend/design-system';
import { ErrorDetails, ErrorMessage } from './common';

export const TopBarBoundary: FallbackRender = ({ error, eventId, resetError }) => {
  const { t } = useTranslation('base');
  const { triggerProps, modalProps } = useModalControl();

  if (!(error instanceof Error)) {
    return <Text weight='bold'>{t('Something went wrong.')}</Text>;
  }
  return (
    <div
      css={{
        display: 'grid',
        gridAutoFlow: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
        borderBottom: `1px solid ${theme.colors.neutral20}`,
      }}
    >
      <ErrorMessage error={error} />

      <div style={{ display: 'grid', gridAutoFlow: 'column', gap: theme.spacing(1) }}>
        <Button variant='tertiary' {...triggerProps}>
          {t('View Details')}
        </Button>
        <Button onClick={resetError}>{t('Try again')}</Button>
      </div>
      <Modal {...modalProps}>
        <Modal.Header onClose={modalProps.onClose}>{t('Error Details')}</Modal.Header>
        <Modal.Body>
          <ErrorMessage>{error.message}</ErrorMessage>
          <ErrorDetails error={error} eventId={eventId} />
        </Modal.Body>
        <Modal.Footer secondary={{ label: 'Close', onClick: modalProps.onClose }} />
      </Modal>
    </div>
  );
};
