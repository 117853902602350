import { FC, useMemo } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Text, TextLink, useTooltip } from '@frontend/design-system';
import { OverdueMessageBaseProps } from './OverdueBy30DaysMessage';

interface OverdueBy45DaysMessageProps extends OverdueMessageBaseProps {
  accountSuspended: boolean;
}

export const OverdueBy45DaysMessage: FC<OverdueBy45DaysMessageProps> = ({
  accountSuspended,
  locationCount,
  locationNames,
  showMultiLocationBanner,
  onAction,
}) => {
  const { navigate } = useSettingsNavigate();
  const { t } = useTranslation('billingAlerts');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  const goToBilling = () => {
    onAction?.();
    navigate({ to: '/organization/invoice-history' });
  };

  const title = useMemo(() => {
    return showMultiLocationBanner ? (
      <Trans t={t} count={locationCount} values={{ locationCount }}>
        Your bill is critically overdue for{' '}
        <Text as='span' weight='bold' {...triggerProps}>
          {{ locationCount }} locations.
        </Text>
      </Trans>
    ) : (
      t('Your bill is critically overdue.')
    );
  }, [locationCount, showMultiLocationBanner, triggerProps]);

  if (!accountSuspended) {
    return (
      <>
        <Text>
          {title}{' '}
          <Trans t={t}>
            Your account will be suspended soon if the outstanding dues are not resolved{' '}
            <TextLink as='span' onClick={goToBilling} trackingId='billing-overdue-45-resolve-balance-here-btn'>
              here
            </TextLink>
            . Contact support at{' '}
            <TextLink as='span' href='tel:+18885795668' trackingId='billing-overdue-45-support-number-link-btn'>
              (888)-579-5668
            </TextLink>{' '}
            option 2, then option 3
          </Trans>
        </Text>
        <Tooltip {...tooltipProps}>{locationNames}</Tooltip>
      </>
    );
  }

  return (
    <Text>
      <Trans t={t}>
        Your account has been suspended. You will be unable to access this application without first resolving your
        overdue bill at{' '}
        <TextLink as='span' href='tel:+18885795668' trackingId='billing-overdue-45-support-number-link-btn'>
          888.579.5668
        </TextLink>{' '}
        option 2, then option 3
      </Trans>
    </Text>
  );
};
