import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingNode,
  FloatingPortal,
  offset,
  useClick,
  useDismiss,
  useFloating,
  useFloatingNodeId,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import { kebabCase } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import type { WeaveTheme as OriginalTheme } from '@frontend/theme-original';
import { ComponentProps } from '@frontend/types';
import { theme, WeaveTheme } from '@frontend/theme';
import {
  FieldChangeEvent,
  FieldLayout,
  FieldLayoutWithAction,
  RadioField,
  Text,
  useFormField,
  useThemeValues,
} from '@frontend/design-system';
import { useIsMobile } from '../../../hooks';
import { resolveTrackingPlaceholders } from '../../../tracking';
import EditMenu from '../menu/edit-menu';
import { BadgeDropdownInput } from './badge-dropdown-input';

export type BadgeOption = {
  chip: React.ReactNode;
  label?: string;
};

type WidthConfig = {
  menuWidth?: string;
  fieldWidth?: string;
};

type Props = {
  emptyValue?: string;
  isLoading?: boolean;
  label?: string;
  name: string;
  onChange?: (e?: FieldChangeEvent) => void;
  onSuggestion?: (name: string) => void;
  options: Record<string, BadgeOption>;
  required?: boolean;
  showSaveChanges?: boolean;
  showSuggestion?: boolean;
  trackingIds?: {
    fieldId?: string;
    optionId?: string;
    saveBtnId?: string;
    suggestionBtnId?: string;
  };
  useFormLevelSave?: boolean;
  value?: string;
  widthConfig?: WidthConfig;
};

export const MAX_FLOATING_HEIGHT = 400;
export const MAX_FLOATING_WIDTH = 300;

const RadioBadgeDropdownMenu = ({
  emptyValue = '-',
  isLoading = false,
  label = '',
  name,
  onChange,
  onSuggestion,
  options,
  required = false,
  showSaveChanges = true,
  showSuggestion = false,
  trackingIds,
  useFormLevelSave = true,
  value = '',
  widthConfig = {
    fieldWidth: '100%',
    menuWidth: '166px',
  },
}: Props) => {
  const { t } = useTranslation('analytics');
  const isMobile = useIsMobile();

  const [isOpen, setIsOpen] = useState(false);
  const theme = useThemeValues();

  const fieldProps = useFormField(
    {
      type: 'radio',
      value,
      required,
    },
    [value]
  );

  const nodeId = useFloatingNodeId();

  const isChanged = useFormLevelSave ? true : (!!fieldProps.error && required) || fieldProps.value !== value;

  const { refs, context, floatingStyles } = useFloating<HTMLInputElement>({
    nodeId,
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [offset(4), flip()],
    placement: !isMobile ? 'bottom-end' : 'bottom-start',
  });

  const { getReferenceProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'listbox' }),
    useDismiss(context, {
      outsidePress: (event) => {
        const isParent = (event?.target as HTMLElement)?.closest('.edit-menu');
        if (isParent) {
          return false;
        }
        if (!useFormLevelSave) {
          fieldProps.onChange({ name, value });
        }
        fieldProps.onBlur();
        return !(event?.target as HTMLElement)?.closest('svg');
      },
    }),
  ]);

  const refProps = getReferenceProps({
    onClick: () => {
      fieldProps.onBlur();
    },
  });

  const handleChange = (e?: FieldChangeEvent) => {
    //@ts-ignore - until FieldChangeEvent is fixed
    const value = e?.target?.value ?? e.value;
    fieldProps.onChange({ name, value });
    useFormLevelSave && !!onChange && onChange({ name, value });
  };

  useEffect(() => {
    if (!useFormLevelSave && !isLoading && isOpen) {
      setIsOpen(false);
    }
  }, [isLoading, useFormLevelSave]);

  return (
    <FieldLayout
      label=''
      /**
       * The types don't know that the fieldComponentProps are being passed to component here
       * We'll cast it for now until the types are fixed
       */
      field={BadgeDropdownInput as unknown as ComponentProps<typeof FieldLayoutWithAction>['field']}
      fieldComponentProps={{
        emptyValue,
        trackingId: trackingIds?.fieldId,
        options,
      }}
      css={[
        styles.fieldLayout,
        css`
          width: ${widthConfig.fieldWidth};
        `,
      ]}
      className='checklist-tag-menu-field'
      containerCss={css({
        ':focus': {
          outline: 'none',
        },
        ':focus .checklist-tag-menu-field': {
          outline: `1px solid ${(theme as WeaveTheme).colors.primary50 ?? (theme as OriginalTheme).colors.weaveBlue}`,
          borderColor: (theme as WeaveTheme).colors.primary50 ?? (theme as OriginalTheme).colors.weaveBlue,
        },
      })}
      endAdornment={
        <Icon
          css={[
            css`
              cursor: pointer;
              transition: transform 250ms ease-out;
            `,

            isOpen &&
              css`
                transform: rotate(180deg);
              `,
          ]}
          color='subdued'
          data-trackingid={trackingIds?.fieldId}
          name='alt-caret-down-tiny'
          onClick={() => setIsOpen((prev) => !prev)}
          size={8}
        />
      }
      ref={refs.setReference}
      {...refProps}
      {...fieldProps}
      name={name}
      hasPadding={false}
      onChange={() => {}}
      hideErrorText
    >
      <FloatingNode id={nodeId}>
        {isOpen && (
          <FloatingPortal>
            <FloatingFocusManager context={context}>
              <EditMenu
                errorMsg={
                  !!fieldProps.error && required
                    ? t(!label ? fieldProps.error : t(`Please select at least one ${label}.`))
                    : ''
                }
                isLoading={isLoading}
                isPrimaryDisabled={!isChanged}
                ref={refs.setFloating}
                onSave={() => {
                  if (!useFormLevelSave) {
                    !!onChange && onChange({ name, value: fieldProps.value });
                  }
                }}
                onSuggest={() => {
                  !!onSuggestion && onSuggestion(name);
                }}
                showSaveChanges={showSaveChanges}
                showSuggestion={showSuggestion}
                style={{
                  ...floatingStyles,
                  boxShadow: theme.shadows.heavy,
                  borderRadius: theme.borderRadius.small,
                  backgroundColor: theme.colors.white,
                  zIndex: theme.zIndex.popover,
                  overflow: 'hidden',
                  maxHeight: MAX_FLOATING_HEIGHT,
                  maxWidth: MAX_FLOATING_WIDTH,
                  width: widthConfig.menuWidth,
                }}
                trackingIds={{ saveBtnId: trackingIds?.saveBtnId, suggestionBtnId: trackingIds?.suggestionBtnId }}
              >
                <RadioField
                  disabled={false}
                  label=''
                  {...fieldProps}
                  name={name}
                  error=''
                  css={styles.fieldWrapper}
                  onChange={handleChange}
                >
                  {Object.entries(options).map(([key, value]) => (
                    <RadioField.Radio
                      css={styles.optionList}
                      key={key}
                      value={key}
                      trackingId={resolveTrackingPlaceholders(trackingIds?.optionId ?? 'badge-dropdown', {
                        menuOption: kebabCase(`option-type-${key?.toLowerCase()}`),
                      })}
                    >
                      <div css={styles.optionWrapper}>
                        {value.chip}
                        {value.label && <Text>{value.label}</Text>}
                      </div>
                    </RadioField.Radio>
                  ))}
                </RadioField>
              </EditMenu>
            </FloatingFocusManager>
          </FloatingPortal>
        )}
      </FloatingNode>
    </FieldLayout>
  );
};

export default RadioBadgeDropdownMenu;

const styles = {
  fieldWrapper: css`
    padding: ${theme.spacing(2, 2, 0, 2)};
  `,
  fieldLayout: css`
    display: grid;
    gap: ${theme.spacing(1)};
    align-items: center;
    grid-template-columns: 1fr auto;
    padding: ${theme.spacing(1)};
    cursor: pointer;
    min-height: 40px;
    height: 42px;
    max-width: 256px;
  `,
  chip: css`
    max-width: none;
  `,
  optionList: css`
    align-items: center;
  `,
  optionWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
};
