import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { ContactWithError, ReducerState } from '../hooks';
import { CommonModalWrapper } from './modal-wrapper';

type Props = ModalControlModalProps & {
  state: ReducerState;
  contactDirectoryName: string | undefined;
  closeModal: () => void;
  onSave: (contacts: ContactWithError[]) => void;
};
export const SaveNewContactsModal = ({ state, contactDirectoryName, closeModal, onSave, ...modalProps }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });

  const onExit = () => {
    closeModal();
    modalProps.onClose();
  };
  const contactsToSave = state.filter((contact) => contact.errors?.length === 0);
  const errorStates = state.filter((contact) => !!contact.errors?.length && contact.errors?.length > 0);

  return (
    <CommonModalWrapper {...modalProps}>
      <Modal.Header onClose={onExit}>{t('Save Completed Contacts')}</Modal.Header>
      <Modal.Body>
        {!!contactsToSave.length && (
          <Text as='div' css={styles}>
            <Icon name='confirm-badge' color='success' />
            <Text>
              <Text as='span'>
                {t('{{ count }} completed contacts will be saved', { count: contactsToSave.length })}
              </Text>
              {contactDirectoryName && (
                <>
                  {' '}
                  <Text as='span'>{t('to')}</Text>{' '}
                  <Text as={'span'} weight='bold'>
                    {contactDirectoryName}
                  </Text>
                </>
              )}
            </Text>
          </Text>
        )}
        {!!errorStates.length && (
          <Text as='div' css={styles}>
            <Icon name='error-badge' color='error' />
            <Text>
              {t('{{count}} incomplete contact entries will not be added to your list.', {
                count: errorStates.length,
              })}
            </Text>
          </Text>
        )}
        <Text css={{ paddingTop: theme.spacing(2) }}>{t('Are you sure you want to finish adding new contacts?')}</Text>
      </Modal.Body>
      <Modal.Actions
        disablePrimary={!contactsToSave.length}
        primaryLabel={'Save Contacts'}
        secondaryLabel={t('Continue Adding')}
        onSecondaryClick={modalProps.onClose}
        onPrimaryClick={() => onSave(contactsToSave)}
      />
    </CommonModalWrapper>
  );
};

const styles = css`
  display: flex;
  gap: ${theme.spacing(1)};
`;
