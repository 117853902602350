import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';

const BetaBadge = () => {
  const { t } = useTranslation('analytics');

  return (
    <Chip variant='seaweed' maxWidth='46px' css={styles.badge}>
      {t('Beta')}
    </Chip>
  );
};

const styles = {
  badge: css`
    color: ${theme.colors.secondary.seaweed70};
    padding-top: ${theme.spacing(0.125)};
    padding-bottom: ${theme.spacing(0.125)};
  `,
};

export default BetaBadge;
