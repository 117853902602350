import {
  AppointmentScheduledChart,
  MCTDetailedConversionChart,
  MCTMessagesSentChart,
  MCTRevenueChart,
} from '../charts';

export const MCTCharts = () => {
  return (
    <div>
      <MCTMessagesSentChart />
      <AppointmentScheduledChart />
      <MCTRevenueChart />
      <MCTDetailedConversionChart />
    </div>
  );
};
