import { createContext, useContext } from 'react';

export const customContextFactory = <T,>(error?: string, initialState?: T) => {
  const Context = createContext<T | undefined>(initialState);

  const useContextFactory = (): T => {
    const context = useContext(Context);

    if (typeof context === 'undefined') {
      throw Error(error ?? '1Hook from contextFactory must be used in an associated provider');
    }

    return context;
  };

  const Provider = ({ children, value }: { children: React.ReactNode; value: T }) => (
    <Context.Provider value={value}>{children}</Context.Provider>
  );

  return [Provider, useContextFactory] as const;
};
