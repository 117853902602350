import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

interface Props {
  message: string | JSX.Element;
  value: number | string;
}

export const MCTChartMeasureValue = ({ message, value }: Props) => {
  return (
    <div css={styles.wrapper}>
      <Text style={{ fontSize: theme.fontSize(96), lineHeight: 1 }} weight='bold'>
        {value}
      </Text>
      <Text color='subdued' size='medium'>
        {message}
      </Text>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    flex-direction: column;
    flex: auto;
    justify-content: center;
    max-width: 500px;
  `,
};
