import { useMemo } from 'react';
import { css } from '@emotion/react';
import { chunk } from 'lodash-es';
import { Dashboard } from '@frontend/dashboard';
import { DashboardWidget, useDashboardWidget, type DashboardWidgetFC } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { type IconName } from '@frontend/icons';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { SlideCarousel } from '@frontend/design-system';
import { AppFeatureButton } from '../dashboard/all-apps-module';

export const AllAppsWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('dashboard');
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const faxFeatureFlag = flagValues['fax-2-0'];
  const { isEditMode } = useDashboardWidget();

  const appFeatures = useMemo(
    () =>
      [
        { icon: 'phone', to: '/calls/recent-calls', label: t('Phone') },
        { icon: 'messaging', to: '/messages/inbox', label: t('Messages') },
        { icon: 'contacts', to: '/contacts/all-contacts', label: t('Contacts') },
        { icon: 'lists', to: '/contacts/lists', label: t('Lists') },
        { icon: 'calendar', to: '/schedule/calendar', label: t('Schedule') },
        { icon: 'forms', to: '/forms/submissions/inbox/all', label: t('Forms') },
        { icon: 'analytics', to: '/analytics/dashboard', label: t('Analytics') },
        { icon: 'reviews-dash', to: '/reviews/all-reviews', label: t('Reviews') },
        { icon: 'payments', to: '/payments/invoices', label: t('Payments') },
        faxFeatureFlag && { icon: 'fax', to: '/fax/inbox', label: t('Fax') },
      ].filter(Boolean),
    [t, faxFeatureFlag]
  );

  const featureChunks = useMemo(() => {
    return chunk(appFeatures, 9);
  }, [appFeatures]);

  return (
    <DashboardWidget>
      <DashboardWidget.Header title={t('All Apps')} />
      <Dashboard.Module.Content
        css={css`
          display: flex;
          flex-direction: column;
        `}
      >
        {appFeatures.length >= 10 ? (
          <SlideCarousel canDrag={!isEditMode} slides={['0', '1']}>
            <SlideCarousel.Container>
              {featureChunks.map((featureChunk, index) => (
                <SlideCarousel.Panel key={index} id={`${index}`}>
                  <AppFeatureGridContainer>
                    {featureChunk.map((feature) => (
                      <AppFeatureButton
                        key={feature.to}
                        icon={feature.icon as IconName}
                        to={feature.to}
                        label={feature.label}
                        size='small'
                      />
                    ))}
                  </AppFeatureGridContainer>
                </SlideCarousel.Panel>
              ))}
            </SlideCarousel.Container>
            <SlideCarousel.Navigation />
          </SlideCarousel>
        ) : (
          <AppFeatureGridContainer>
            {appFeatures.map((feature) => (
              <AppFeatureButton
                key={feature.to}
                icon={feature.icon as IconName}
                to={feature.to}
                label={feature.label}
                size='small'
              />
            ))}
          </AppFeatureGridContainer>
        )}
      </Dashboard.Module.Content>
    </DashboardWidget>
  );
};

AllAppsWidget.config = {
  size: 'large-narrow',
  feature: 'general',
};

interface AppFeatureGridContainerProps {
  children: React.ReactNode;
}

const AppFeatureGridContainer = ({ children }: AppFeatureGridContainerProps) => {
  return (
    <div
      css={css`
        display: grid;
        max-width: 360px;
        margin: auto;
        width: 100%;
        grid-template-columns: repeat(3, 1fr);
        row-gap: ${theme.spacing(1.5)};
        padding: ${theme.spacing(1)};
        margin-top: ${theme.spacing(2)};
      `}
    >
      {children}
    </div>
  );
};
