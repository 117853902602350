import { PetQueries, PetTypes } from '@frontend/api-pet';
import { AdaptoActions } from '@frontend/adapto-actions';
import { PopoverMenu, usePopoverMenu } from '@frontend/design-system';
import { PetListAction } from './pet-list-action';
import { useSendPetReport } from './use-send-pet-report';

type GenerateServiceReportType = {
  label: string;
  context: { personId: string; locationId: string; mobilePhone: string; petOwnerName: string };
  trackingId: string;
};

export const GenerateServiceReport = ({ label, context, trackingId }: GenerateServiceReportType) => {
  const { openModal, SendInThreadModal } = useSendPetReport(context);
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({
    placement: 'left-start',
    middlewareOptions: { offset: 0 },
  });
  const { data: petsData = [] } = PetQueries.useGetPersonPets({ personId: context.personId });

  return (
    <>
      {SendInThreadModal}
      {petsData?.length === 1 ? (
        <SinglePetAction trackingId={trackingId} label={label} openModal={openModal} petId={petsData?.[0].petId} />
      ) : (
        <>
          <AdaptoActions.Action
            trackingId={trackingId}
            label={label}
            disableCloseOnSelect
            icon='list-small'
            {...getTriggerProps()}
          />
          <PopoverMenu {...getMenuProps()}>
            {petsData?.map((pet, index) => (
              <PetListAction
                key={pet.petId}
                pet={pet}
                index={index}
                getItemProps={getItemProps}
                handleClick={(e) => {
                  AdaptoActions.close();
                  openModal(e);
                }}
                trackingId='contact-action-waterfall-send-vaccine-history'
              />
            ))}
          </PopoverMenu>
        </>
      )}
    </>
  );
};

const SinglePetAction = ({
  trackingId,
  label,
  openModal,
  petId,
}: {
  trackingId: string;
  label: string;
  openModal: ReturnType<typeof useSendPetReport>['openModal'];
  petId: PetTypes.Pet['petId'];
}) => {
  const { data: petExtended } = PetQueries.useGetPetByPetId(petId || '');

  return (
    <AdaptoActions.Action
      trackingId={trackingId}
      label={label}
      icon='list-small'
      onClick={() => {
        if (!petExtended) return;
        openModal(petExtended);
      }}
    />
  );
};
