import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headerPanelStyle = css`
  > div {
    > div {
      overflow: hidden;
    }
  }
`;

export const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
`;

export const headerTitleStyle = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const actionButtonsContainerStyle = css`
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
  justify-content: end;
  flex-shrink: 0;
`;
