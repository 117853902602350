import { useState } from 'react';
import { InvoiceStatus } from '@frontend/api-invoices';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { CardACHSelection, MIN_ACH_AMOUNT } from '@frontend/payments-card-on-file';
import { useLatencyBustPoll } from '@frontend/payments-hooks';
import {
  useGetInvoiceNonQuery,
  useSelectedInvoice,
  useUpdateInvoiceQueryCache,
} from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { ContentLoader } from '@frontend/design-system';
import { usePaymentModalContext } from '../hooks';
import { useCardOnFilePayment } from './hooks';

export const CardOnFile = () => {
  const { t } = useTranslation('payments');
  const { goBack, closeModal } = useMultiStepModal();
  const { invoice } = useSelectedInvoice();
  const { onPaymentSuccess } = usePaymentModalContext() || {};

  const [selectedPM, setSelectedPM] = useState<PaymentMethod>();

  const { makeCardOnFilePayment, makingCardOnFilePayment } = useCardOnFilePayment({
    paymentMethod: selectedPM,
  });

  const updateInvoiceCache = useUpdateInvoiceQueryCache(invoice?.id);

  const getInvoice = useGetInvoiceNonQuery(invoice?.id);

  const { getPollPromise } = useLatencyBustPoll({
    queryMethod: getInvoice,
    onPollEnd: (data) => {
      updateInvoiceCache(data);
      onPaymentSuccess?.();
    },
    accessor: (data) => {
      return data?.status;
    },
  });

  const onPrimaryClick = async () => {
    const workflowId = await makeCardOnFilePayment();

    /**
     * If card is declided, or failure happens, workflowId will be undefined.
     */
    if (workflowId) {
      if (invoice) {
        getPollPromise(invoice);

        /**
         * Optimistically update the status to processing.
         */
        updateInvoiceCache({ ...invoice, status: 'Processing' as InvoiceStatus });
      }
      /**
       * Matt's idea. Temporarily set a processing status.
       * It won't hold up on refresh. But will chill the user until the status is updated.
       *
       * This status doesn't actually exist as a valid status.
       */
      closeModal();
    }
  };

  return (
    <>
      <ContentLoader
        show={makingCardOnFilePayment}
        message={
          selectedPM?.type === 'us_bank_account'
            ? t('Making ACH on File Payment...')
            : t('Making Card on File Payment...')
        }
      />
      <CardACHSelection
        patientId={invoice?.person.id}
        patientEmail={invoice?.person.emailAddress || ''}
        selectedPM={selectedPM}
        onChangePM={setSelectedPM}
        paymentAmount={invoice?.billedAmount}
        minACHAmount={MIN_ACH_AMOUNT}
        addCardContent={<CardACHSelection.AddCardForm />}
        addACHContent={<CardACHSelection.AddACHForm />}
      >
        <CardACHSelection.Content />
        <CardACHSelection.Action
          onBack={goBack}
          onCancel={goBack}
          onPrimaryClick={onPrimaryClick}
          primaryLabel={t('Complete Payment')}
        />
      </CardACHSelection>
    </>
  );
};
