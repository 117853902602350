import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { useMerchant } from '@frontend/payments-hooks';

export const useTerminalSettings = () => {
  const { locationId, hasFullPayments } = useMerchant();
  const { isFeatureActiveInAllLocations } = CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
    locationIds: [locationId],
    customizationFlag: Feature.PAYMENT_TERMINALS,
  });

  const hasTerminalsEnabled = hasFullPayments && isFeatureActiveInAllLocations;

  return { hasTerminalsEnabled };
};
