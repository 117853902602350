import { createElement, useCallback, useEffect, useRef, useState } from 'react';
import { useEditor } from '@craftjs/core';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { SIDEBAR_BREAKPOINTS } from '../../constants';
import { useContentComposer } from '../content-composer-provider';
import { Toolbox } from '.';

export const Sidebar = () => {
  const { activePanel, setActivePanel } = useContentComposer();
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // TODO: Revisit this scroll down logic to close the toolbar panel
  const handleSwipe = useCallback((deltaX: number, deltaY: number) => {
    if (Math.abs(deltaX) < Math.abs(deltaY) && deltaY > 0) {
      setActivePanel('unset');
    }
  }, []);

  const handleTouchStart = useCallback((e?: TouchEvent) => {
    if (!containerRef?.current?.contains(e?.target as Node)) return;
    // e.preventDefault();
    if (e?.touches[0]) {
      setPositionX(e.touches[0].clientX);
      setPositionY(e.touches[0].clientY);
    }
  }, []);

  const handleTouchEnd = useCallback(
    (e?: TouchEvent) => {
      if (!containerRef?.current?.contains(e?.target as Node)) return;
      // e.preventDefault();
      const endX = e?.changedTouches[0]?.clientX;
      const endY = e?.changedTouches[0]?.clientY;
      if (!endX || !endY) return;
      const deltaX = endX - positionX;
      const deltaY = endY - positionY;
      handleSwipe(deltaX, deltaY);
    },
    [positionX, positionY, handleSwipe]
  );

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchStart, handleTouchEnd]);

  const { selectedSetting } = useEditor((state) => {
    const [currentNodeId] = state.events.selected;
    return {
      selectedSetting: currentNodeId ? state.nodes[currentNodeId]?.related?.settings : null,
    };
  });

  // TODO: Play around with passing the props in and strongly typing the settings
  return (
    <AnimatePresence>
      <motion.section
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        ref={containerRef}
        css={(theme) => css`
          grid-area: sidebar;
          overflow-y: auto;
          padding: ${theme.spacing(2)};
          border-right: solid 1px ${theme.colors.neutral20};
          min-height: calc(100vh - 276px - ${theme.heightOffset}px); // TODO: Remove this calc!
          display: flex;
          flex-direction: column;
          @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
            background-color: ${theme.colors.white};
            border-right: none;
            width: 100vw;
            min-height: unset;
            height: 45%;
            position: absolute;
            bottom: 0;
            transform: ${activePanel !== 'unset' ? 'translateY(0)' : 'translateY(100%)'};
            border-radius: ${theme.borderRadius.medium} ${theme.borderRadius.medium} 0 0;
            border-top: solid 1px ${theme.colors.neutral20};
            transition: transform 0.3s ease-in-out;
            padding: 0;
            z-index: ${theme.zIndex.middle + 1};
          }
          ${!selectedSetting &&
          `
            gap: ${theme.spacing(2)};
          `}
        `}
      >
        {!selectedSetting && (
          <div
            css={css`
              justify-content: center;
              margin: ${theme.spacing(2)} auto ${theme.spacing(1)};
              background: ${theme.colors.neutral20};
              min-height: ${theme.spacing(1)};
              width: ${theme.spacing(20)};
              border-radius: ${theme.borderRadius.small};
              display: flex;
              @media (min-width: ${SIDEBAR_BREAKPOINTS.mobile + 1}px) {
                display: none;
              }
            `}
          />
        )}
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(2)};
            padding-bottom: ${theme.spacing(4)};
            @media (max-width: ${SIDEBAR_BREAKPOINTS.mobile}px) {
              overflow-y: scroll;
              padding: ${selectedSetting ? 0 : theme.spacing(2, 2, 10)};
            }
          `}
        >
          {selectedSetting ? createElement(selectedSetting) : <Toolbox />}
        </div>
      </motion.section>
    </AnimatePresence>
  );
};
