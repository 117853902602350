// you can add here common methods and constants for phone features only

import { PrimitiveType } from '@weave/schema-gen-ts/dist/shared/phonedata/v1/primitive.pb';
import { i18next } from '@frontend/i18n';

export const fileExtensionRegex = /\.wav|\.mp3|\.mp4|\.wma|\.ogg|\.m4a/;

export const noMediaID = '00000000-0000-0000-0000-000000000000';

/* defaultMediaID should actually be empty string. Keeping empty string creates an issue in frontend and that is
 * that the option in the dropdown is never shown selected. it always shows 'select one' if your value is empty string.
 */
export const defaultMediaID = 'weave-default-id-is-empty-string';
export const alphabeticalSort = (label1: string, label2: string) => {
  const aName = label1 || '';
  const bName = label2 || '';
  return aName.localeCompare(bName);
};
export const isStringNumeric = (callerName: string) => {
  return /^[+-]?\d+(\.\d+)?$/.test(callerName);
};

export const primitiveTypeTranslation: Record<PrimitiveType, string> = {
  PRIMITIVE_TYPE_UNSPECIFIED: i18next.t('Unspecified', { ns: 'phone' }),
  PRIMITIVE_TYPE_TENANT: i18next.t('Tenant', { ns: 'phone' }),
  PRIMITIVE_TYPE_SIP_PROFILE: i18next.t('Sip Profile', { ns: 'phone' }),
  PRIMITIVE_TYPE_INSTRUCTION_SET: i18next.t('Call Route', { ns: 'phone' }),
  PRIMITIVE_TYPE_PHONE_NUMBER: i18next.t('Phone Number', { ns: 'phone' }),
  PRIMITIVE_TYPE_DEVICE: i18next.t('Device', { ns: 'phone' }),
  PRIMITIVE_TYPE_LABEL: i18next.t('Label', { ns: 'phone' }),
  PRIMITIVE_TYPE_VOICEMAILBOX: i18next.t('Voicemailbox', { ns: 'phone' }),
  PRIMITIVE_TYPE_SITE: i18next.t('Site', { ns: 'phone' }),
  PRIMITIVE_TYPE_CALL_LEG: i18next.t('Call Leg', { ns: 'phone' }),
  PRIMITIVE_TYPE_RULE_SET: i18next.t('Rule Set', { ns: 'phone' }),
  PRIMITIVE_TYPE_RULE: i18next.t('Rule', { ns: 'phone' }),
  PRIMITIVE_TYPE_INSTRUCTION: i18next.t('Instruction', { ns: 'phone' }),
  PRIMITIVE_TYPE_CALL_GROUP: i18next.t('Call Group', { ns: 'phone' }),
  PRIMITIVE_TYPE_FAX_PROVIDER: i18next.t('Fax Provider', { ns: 'phone' }),
  PRIMITIVE_TYPE_SMS_PROVIDER: i18next.t('Sms Provider', { ns: 'phone' }),
  PRIMITIVE_TYPE_VOICE_PROVIDER: i18next.t('Voice Provider', { ns: 'phone' }),
  PRIMITIVE_TYPE_E911_PROVIDER: i18next.t('E911 Provider', { ns: 'phone' }),
  PRIMITIVE_TYPE_MEDIA_FILE: i18next.t('Media File', { ns: 'phone' }),
  PRIMITIVE_TYPE_SCHEDULE: i18next.t('Schedule', { ns: 'phone' }),
  PRIMITIVE_TYPE_CALL_QUEUE: i18next.t('Call Queue', { ns: 'phone' }),
  PRIMITIVE_TYPE_IVR_MENU: i18next.t('Phone Tree', { ns: 'phone' }),
  PRIMITIVE_TYPE_CONDITION: i18next.t('Condition', { ns: 'phone' }),
  PRIMITIVE_TYPE_CONTACT_DIRECTORY: i18next.t('Contact Directory', { ns: 'phone' }),
  PRIMITIVE_TYPE_CONTACT_ENTRY: i18next.t('Contact Entry', { ns: 'phone' }),
  PRIMITIVE_TYPE_EXTENSION: i18next.t('Extension', { ns: 'phone' }),
  PRIMITIVE_TYPE_CALL_QUEUE_AGENT: i18next.t('Call Queue Agent', { ns: 'phone' }),
  PRIMITIVE_TYPE_CALL_QUEUE_FLOW: i18next.t('Call Queue Flow', { ns: 'phone' }),
  PRIMITIVE_TYPE_CALL_QUEUE_TIER: i18next.t('Call Queue Tier', { ns: 'phone' }),
  PRIMITIVE_TYPE_CONTACT: i18next.t('Contact', { ns: 'phone' }),
  PRIMITIVE_TYPE_DATA_ENDPOINT: i18next.t('Data Endpoint', { ns: 'phone' }),
  PRIMITIVE_TYPE_FORWARDING_NUMBER: i18next.t('Forwarding Number', { ns: 'phone' }),
  PRIMITIVE_TYPE_IVR_ENTRY: i18next.t('Ivr Entry', { ns: 'phone' }),
  PRIMITIVE_TYPE_BLOCKED_NUMBER: i18next.t('Blocked Number', { ns: 'phone' }),
  PRIMITIVE_TYPE_LINE_KEY: i18next.t('Line Key', { ns: 'phone' }),
  PRIMITIVE_TYPE_PARKING_LOT: i18next.t('Parking Lot', { ns: 'phone' }),
  PRIMITIVE_TYPE_ADDRESS: i18next.t('Address', { ns: 'phone' }),
  PRIMITIVE_TYPE_DOCUMENT_MODIFIER: i18next.t('Document Modifier', { ns: 'phone' }),
};

export const getFullName = (firstName?: string, lastName?: string, defaultValue = '') => {
  if (firstName || lastName) {
    return `${firstName || ''} ${lastName || ''}`.trim();
  }
  return defaultValue || '';
};
