import { BASE_ROUTE } from '../base';
import { RouterPayload } from '../route.types';
import { BASE_LIBRARY_ROUTE, FORMS_LIBRARY_LIST } from './forms-library.constants';

export interface FormsV2ListRoutePayload {
  triggerNewFormModal?: boolean;
  relativePath?: boolean;
}

/**
 * Helper function to get the route for the forms list page to be used with the `navigate` function.
 */
export function getFormV2ListRoute(payload: FormsV2ListRoutePayload = {}): RouterPayload {
  const { triggerNewFormModal = false, relativePath = false } = payload;
  const searchParams: Record<string, string> = {
    list: FORMS_LIBRARY_LIST.forms,
  };
  // Remove the "/forms" from the route if the relativePath is true.
  const basePath = relativePath ? BASE_LIBRARY_ROUTE.split(BASE_ROUTE)[1] : BASE_LIBRARY_ROUTE;

  if (triggerNewFormModal) {
    searchParams['triggerNewFormModal'] = 'true';
  }

  return {
    to: basePath,
    search: searchParams,
  };
}

export interface FormV2DetailsRoutePayload {
  formId?: string;
}

/**
 * Helper function to get the route object for the form details page to be used with the `navigate` function.
 */
export function getFormV2DetailsRoute({ formId }: FormV2DetailsRoutePayload): RouterPayload {
  const searchParams: Record<string, string> = {
    list: FORMS_LIBRARY_LIST.forms,
  };

  if (formId) {
    searchParams['documentId'] = formId;
  }

  return {
    to: BASE_LIBRARY_ROUTE,
    search: searchParams,
  };
}

/**
 * Helper function to get the route for the packets list page to be used with the `navigate` function.
 */
export function getPacketsV2ListRoute(): RouterPayload {
  return {
    to: BASE_LIBRARY_ROUTE,
    search: {
      list: FORMS_LIBRARY_LIST.packets,
    },
  };
}

export interface PacketV2DetailsRoutePayload {
  packetId?: string;
}

/**
 * Helper function to get the route object for the packet details page to be used with the `navigate` function.
 */
export function getPacketsV2DetailsRoute({ packetId }: PacketV2DetailsRoutePayload): RouterPayload {
  const searchParams: Record<string, string> = {
    list: FORMS_LIBRARY_LIST.packets,
  };

  if (packetId) {
    searchParams['documentId'] = packetId;
  }

  return {
    to: BASE_LIBRARY_ROUTE,
    search: searchParams,
  };
}
