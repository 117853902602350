import {
  AuthService,
  PasswordPwnedResponse,
  UpdateUserProfileRequest,
  VerifyAuthRequest,
} from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { BulkUsersService, InvitedUser } from '@weave/schema-gen-ts/dist/schemas/bulk-users-api/v1/bulk_users.pb';
import { LocationsApi, LocationsTypes } from '@frontend/api-locations';
import { SoftphoneApi, SoftphoneTypes } from '@frontend/api-softphone';
import { formatAvailableRoles, formatMultiOfficeRoles } from '@frontend/account-helpers';
import { getInitialParams } from '@frontend/env';
import { APIResponse, http, HTTP } from '@frontend/fetch';
import { Location } from '@frontend/location-helpers';
import { bindHTTP } from '@frontend/schema';
import type { UsersTypes } from '@frontend/user-helpers';

const currentEnvBaseURL = getInitialParams().backendApi;
const baseUrl = 'portal/v1/users';

/* public pages have unique setup - since they don't allow the auth headers inside the calls or location-id I have to create a new instance without all that added so the api calls will work. */
const customHttpInstance = new HTTP({ baseUrl: `${currentEnvBaseURL}/portal/users` });

/* Password updates need a different http method */
const passwordPolicyHTTP = new HTTP({ baseUrl: `${currentEnvBaseURL}/auth/locations` });
export const setupAuthHeader = (token: string) => passwordPolicyHTTP.setAuthorizationHeader(token);

export const list = {
  passwordPolicies: (payload: UsersTypes.PasswordPayload) => {
    return passwordPolicyHTTP.get<UsersTypes.PasswordRequestAndResponse>(`${payload.locationId}/policy`, {
      skipValidation: true,
    });
  },
  users: async (showChildren?: boolean, locationId?: string) => {
    const multiOptions = locationId ? { headers: { 'Location-Id': locationId } } : {};

    const users = await http.getData<Omit<UsersTypes.User[], 'softphone'>>(`${baseUrl}`, {
      ...multiOptions,
      ...(showChildren ? { params: { showChildren } } : {}),
    });
    const softphoneUserList = await SoftphoneApi.list().catch((err) => console.error(err));

    if (softphoneUserList) {
      const softphoneMap: Map<SoftphoneTypes.SoftphoneInfo['user_id'], SoftphoneTypes.SoftphoneInfo> = new Map();
      // softphoneUserList might return undefined
      softphoneUserList.forEach((item: SoftphoneTypes.SoftphoneInfo) => {
        softphoneMap.set(item.user_id, item);
      });
      // before returning the users for this location, add the softphone field to the user object
      return users.map((user) => {
        if (softphoneMap.has(user.UserID)) {
          return { ...user, softphone: softphoneMap.get(user.UserID) };
        } else {
          return { ...user, softphone: undefined };
        }
      });
    } else {
      // before returning the users for this location, add the softphone field to the user object
      return users.map((user) => {
        return { ...user, softphone: undefined };
      });
    }
  },

  userSearch: async (email: string): Promise<UsersTypes.User> => {
    try {
      return await http.getData<UsersTypes.User>(`${baseUrl}/search/${email}`);
    } catch (err: any) {
      if (err === 404 || err?.status === 404) {
        return {
          FirstName: '',
          LastName: '',
          Type: '',
          Status: 'Invited',
          MobileNumber: '',
          MobileNumberVerifiedAt: '',
          UserID: '',
          Username: email,
          Locations: undefined,
          Roles: undefined,
          softphone: undefined,
        };
      }
      throw new Error('Error with user invitation');
    }
  },

  getUserProfileByUserID: async (userId: string): Promise<UsersTypes.User> => {
    const user = await http.getData<UsersTypes.User>(`${baseUrl}/${userId}`, { skipValidation: true });
    return user;
  },

  getWeaveUserProfileByUserID: async (userId: string): Promise<UsersTypes.User> => {
    const weaveUserBaseUrl = 'portal/users';
    const user = await http.getData<UsersTypes.User>(`${weaveUserBaseUrl}/${userId}`, { skipValidation: true });
    return user;
  },

  user: async (email: string, childLocation = '') =>
    await http.getData<UsersTypes.User>(
      `${baseUrl}/${email}${childLocation !== '' ? '?childLocation=' + childLocation : ''}`
    ),

  Roles: async () => await http.getData<UsersTypes.UserRoleResponse[]>(`${baseUrl}/roles`),

  userLocationRoles: async (userId: string, locationId: string) =>
    await http.getData<LocationsTypes.UserLocationRolesResponse>(`${baseUrl}/${userId}/children/${locationId}`),

  userMultiLocationData: async (
    userLocationRoles: Record<string, { roles: number[] }>,
    availableRoles: UsersTypes.RolesModel[],
    location: Location
  ): Promise<UsersTypes.MultiOfficeRolesModel[]> => {
    const children = await LocationsApi.getChildrenLocations(location.LocationID);
    const childLocations: UsersTypes.UserLocationModel[] = children.map((childLocation) => ({
      name: childLocation.Name,
      id: childLocation.LocationID,
    }));
    childLocations.unshift(
      {
        id: 'select-all',
        name: 'Activate All Locations',
      },
      {
        name: location.Name,
        id: location.LocationID,
      }
    );
    const multiOfficeRoles: UsersTypes.MultiOfficeRolesModel[] = formatMultiOfficeRoles(
      userLocationRoles,
      availableRoles,
      childLocations
    );
    return multiOfficeRoles;
  },

  userLocationEditData: async ({
    location,
    profileId,
    locationType,
  }: {
    location: Location;
    profileId: string;
    locationType: 'single' | 'multi';
  }): Promise<UsersTypes.EditUserLocationDataRes> => {
    if (!location) throw new Error('Error requesting profile without location');
    const availableRoles = formatAvailableRoles(await list.Roles());
    const userLocationRoles = await (await list.userLocationRoles(profileId, location.LocationID)).roles;
    const anyChildLocation: string =
      userLocationRoles[Object.keys(userLocationRoles)[0]]?.roles?.length > 0 ? Object.keys(userLocationRoles)[0] : '';
    const profile = await list.user(profileId, locationType === 'multi' ? anyChildLocation : '');

    if (locationType === 'multi') {
      const multiOfficeRoles = await list.userMultiLocationData(userLocationRoles, availableRoles, location);
      return {
        profile,
        availableRoles,
        multiOfficeRoles,
        userLocationRoles,
      };
    } else {
      return {
        profile,
        availableRoles,
        userLocationRoles,
      };
    }
  },

  userLocationInviteData: async ({
    location,
    profile,
    locationType,
  }: {
    profile: UsersTypes.User;
    location: Location;
    locationType: 'single' | 'multi';
  }): Promise<UsersTypes.EditUserLocationDataRes> => {
    if (!location) throw new Error('Error requesting profile without location');
    const availableRoles = formatAvailableRoles(await list.Roles());
    const userLocationRoles = {};

    if (locationType === 'multi') {
      const multiOfficeRoles = await list.userMultiLocationData(userLocationRoles, availableRoles, location);
      return {
        profile,
        availableRoles,
        multiOfficeRoles,
        userLocationRoles,
      };
    } else {
      return {
        profile,
        availableRoles,
        userLocationRoles,
      };
    }
  },

  userNonce: async (payload: UsersTypes.NonceInvitePayload) => {
    const user = await customHttpInstance.getData<UsersTypes.GetUserResponse>(`profile/${payload.nonceToken}`, {
      skipValidation: true,
      headers: {
        'Location-Id': payload.locationId,
      },
    });
    return user;
  },
};
export const create = {
  isPasswordPawned: async (payload: { password: string; locationId: string }) => {
    return await http.post<PasswordPwnedResponse>(
      `${currentEnvBaseURL}/auth-api/v3/password/pwned`,
      { password: payload.password },
      {
        skipValidation: true,
        headers: {
          'Location-Id': payload.locationId,
        },
      }
    );
  },

  passwordPolicies: (payload: UsersTypes.PasswordPayload) => {
    if (!payload.reqBody) throw new Error('500 request body required.');
    return passwordPolicyHTTP.post<UsersTypes.PasswordRequestAndResponse>(
      `${payload.locationId}/policy`,
      { ...payload.reqBody },
      { skipValidation: true }
    );
  },

  inviteUser: async (payload: UsersTypes.InviteUserDetails) => {
    const { data } = await http.post<APIResponse<string>>(`${baseUrl}/inviteUser`, payload);
    return data;
  },

  inviteMultiUser: async (payload: UsersTypes.InviteMultiLocationPayload) => {
    const userId = (await create.inviteUser(payload.userDetails)) as string;
    const locationsToUpdate: { locationId: string; roles: number[] }[] = [];
    payload.locationData.forEach((location, i) => {
      /* we ignore the first index of the array because that data is used for the initial user creation */
      if (i !== 0) {
        locationsToUpdate.push({ locationId: location.locationId, roles: location.roles });
      }
    });
    return await update.multiLocation({ userId, locationsToUpdate, locationsToDelete: [] });
  },

  smsPasswordReset: (payload: UsersTypes.MobileVerificationReqBody) => {
    return http.post(`${baseUrl}/sms/setup`, payload);
  },

  setUpMobileNumber: (payload: UsersTypes.NonceInvitePayload) => {
    return http.post(`${baseUrl}/smsverify/${payload.nonceToken}`, {
      skipValidation: true,
      headers: {
        'Location-Id': payload.locationId,
      },
    });
  },
};

export const update = {
  mobileAccess: async ({
    userId,
    locationId,
    roleIds,
  }: {
    userId: UsersTypes.User['UserID'];
    locationId: string;
    roleIds: UsersTypes.UserRoleResponse['ID'][];
  }) => {
    const response = await http.put(`${baseUrl}/${userId}/access/${locationId}`, roleIds);
    return response;
  },

  multiLocation: async (payload: UsersTypes.MultiLocationReqBody) => {
    const { locationsToUpdate, locationsToDelete, userId } = payload;

    const updatePromises = locationsToUpdate.map((location) => {
      return http.put(`${baseUrl}/${userId}/access/${location.locationId}`, location.roles, {
        headers: { 'Location-Id': location.locationId },
      });
    });
    const deletePromises = locationsToDelete.map((location) => {
      return http.delete(`${baseUrl}/${userId}/access/${location.locationId}`, {
        headers: { 'Location-Id': location.locationId },
      });
    });
    return await Promise.all([...updatePromises, ...deletePromises]);
  },

  userProfile: (payload: UsersTypes.UpdateUserProfileReqBody) => {
    return http.put(`${baseUrl}/${payload.profile.UserID}`, payload);
  },

  userNonce: (payload: UsersTypes.PutUserPayload) => {
    return http.put<unknown>(`portal/users/profile`, payload, {
      skipValidation: true,
      responseType: 'json',
      headers: { 'location-id': '' },
    });
  },

  locationInvite: (payload: UsersTypes.NonceInvitePayload) => {
    return customHttpInstance.put(
      `acceptInvite/${payload.nonceToken}/location/${payload.locationId}`,
      {},
      {
        skipValidation: true,
        headers: {
          'Location-Id': payload.locationId,
          Authorization: `Bearer ${payload.nonceToken}`,
        },
      }
    );
  },
};

export const destroy = {
  passwordPolicies: (payload: UsersTypes.PasswordPayload) => {
    return passwordPolicyHTTP.delete(`${payload.locationId}/policy`, payload.reqBody, { skipValidation: true });
  },

  user: async ({ userId, locationId }: { userId: UsersTypes.User['UserID']; locationId: string }) => {
    const response = await http.delete(`${baseUrl}/${userId}/access/${locationId}`);
    return response;
  },
};

export const SchemaAuthService = bindHTTP(AuthService);

//SKIP CHALLENGE & ORIGIN
export const getAuthVeify = async (payload: VerifyAuthRequest) => {
  return SchemaAuthService.VerifyAuth(payload);
};

export const getUserPermissions = async () => {
  return SchemaAuthService.GetRoleCategoriesByType({});
};

export const getUserDetails = async (userId: string) => {
  return SchemaAuthService.GetUserDetails({ userId });
};

export const getUsersInLocation = async (locationIds: string[]) => {
  return SchemaAuthService.GetUsersInLocations({ locationIds });
};

export const updateUserProfile = async (profile: UpdateUserProfileRequest) => {
  return SchemaAuthService.UpdateUserProfile(profile);
};

export const SchemaBulkService = bindHTTP(BulkUsersService);

export const bulkInviteUsers = async (users: InvitedUser[]) => {
  return SchemaBulkService.InviteUsers({ users });
};
