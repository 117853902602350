import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { BarChartData, Chart, CustomLegendsData, PieChartData, XAxisLabelValueTick } from '@frontend/charts';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { MCTChartMeasureValue } from '../components';

type ChartConfig =
  | {
      data: PieChartData;
      type: 'pie';
    }
  | {
      data: BarChartData;
      maxValue: number;
      type: 'bar';
    };

interface Props extends PropsWithChildren {
  chartConfig: ChartConfig;
  customLegendsData?: CustomLegendsData;
  legendsFormatValue: (value: number) => string;
  legendsValues: Record<string, number>;
  message: string | JSX.Element;
  title: string;
  value: number | string;
}

const labels = {
  existingPatients: i18next.t('Existing Patients', { ns: 'analytics' }),
  newPatients: i18next.t('New Patients', { ns: 'analytics' }),
  unscheduledLeads: i18next.t('Unscheduled Leads', { ns: 'analytics' }),
};

const colors = {
  existingPatients: theme.colors.primary20,
  newPatients: theme.colors.indigo50,
  unscheduledLeads: theme.colors.warning10,
};

export const MCTChartsBase = ({
  chartConfig,
  children,
  customLegendsData,
  legendsFormatValue,
  legendsValues,
  message,
  title,
  value,
}: Props) => {
  return (
    <Chart colors={colors} labels={labels}>
      <Chart.Header title={title} />
      <Chart.HorizontalContainer marginBottom={0}>
        <MCTChartMeasureValue message={message} value={value} />
        <div css={styles.chartWrapper}>
          {chartConfig.type === 'pie' ? (
            <Chart.PieChart
              appearance={{
                hideTooltipTitle: true,
                innerRadius: '60%',
              }}
              data={chartConfig.data}
              formatValue={legendsFormatValue}
            />
          ) : (
            <div css={styles.barChart}>
              <Chart.BarChart
                appearance={{
                  barBackground: true,
                  barBackgroundStrokeWidth: 1,
                  barSize: 80,
                  customXAxisTick: ({ groupName, ...rest }) => {
                    return (
                      <XAxisLabelValueTick
                        {...rest}
                        value={legendsFormatValue?.(legendsValues[groupName])}
                        valueFontSize={24}
                        y={(rest.y || 0) + 8}
                      />
                    );
                  },
                  groupsGap: 40,
                  height: 210,
                  hideTooltipTitle: true,
                  maxValue: chartConfig.maxValue,
                  showXAxis: true,
                  width: '300px',
                }}
                data={chartConfig.data}
                formatValue={legendsFormatValue}
              />
            </div>
          )}
          <Chart.Legends
            customData={customLegendsData}
            formatValue={legendsFormatValue}
            style={{ marginTop: theme.spacing(3) }}
            values={legendsValues}
          />
        </div>
      </Chart.HorizontalContainer>
      {children}
    </Chart>
  );
};

const styles = {
  chartWrapper: css`
    display: flex;
    flex-direction: column;
    position: relative;
  `,

  barChart: css`
    display: flex;
    justify-content: center;
  `,
};
