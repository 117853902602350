import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type EditableFieldProps = {
  displayComponent?: React.ReactNode;
  editComponent?: React.ReactNode;
  editMode?: boolean;
  isEdited?: boolean;
  label: string;
};

export const EditableField: React.FC<EditableFieldProps> = ({
  displayComponent,
  editComponent,
  editMode = false,
  label,
  isEdited = false,
}) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.fieldWrapper}>
      <div css={styles.labelWrapper}>
        <Text color='subdued'>{label}</Text>
        {isEdited && (
          <Text
            color='subdued'
            size='small'
            css={css`
              font-style: italic;
            `}
          >
            {t('Edited')}
          </Text>
        )}
      </div>
      <div>{editMode ? editComponent : displayComponent}</div>
    </div>
  );
};

const styles = {
  fieldWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: ${theme.spacing(1.5)};

    @media screen and (max-width: ${breakpoints.small.min}px) {
      align-items: flex-start;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    }
  `,
  labelWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
};
