import { i18next } from '@frontend/i18n';
import { BundleType, PlanType } from './types';

export const BundleTypeRegex = {
  ESSENTIALS: /(_|^)essentials(_|$)/i,
  PRO: /(_|^)pro(_|$)/i,
  ULTIMATE: /(_|^)ultimate(_|$)/i,
  ELITE: /(_|^)elite(_|$)/i,
};

export const PlanTypeRegex = {
  PLAN_CORE: /core/i,
  PLAN_PLUS: /plus/i,
  SOFTWARE_ONLY: /software_only/i,
};

export const PlanNameByTypeMap: Record<PlanType, string> = {
  [PlanType.UNKNOWN]: '',
  [PlanType.CORE]: i18next.t('Core', { ns: 'bundle' }),
  [PlanType.PLUS]: i18next.t('Plus', { ns: 'bundle' }),
};

export const BundleNameByTypeMap: Record<BundleType, string> = {
  [BundleType.UNKNOWN]: '',
  [BundleType.ESSENTIALS]: i18next.t('Essentials', { ns: 'bundle' }),
  [BundleType.PRO]: i18next.t('Pro', { ns: 'bundle' }),
  [BundleType.ELITE]: i18next.t('Elite', { ns: 'bundle' }),
  [BundleType.ULTIMATE]: i18next.t('Ultimate', { ns: 'bundle' }),
};

const SCHEDULE_1_ON_1_TRAINING_URL = 'https://calendly.com/weave-one-on-one-trainings/ultimate-bundle-training';

export const Schedule1On1TrainingData = {
  title: i18next.t('Schedule 1:1 Training', { ns: 'bundle>>ultimate-bundle' }),
  description: i18next.t(
    '1:1 Training sessions are included in the Ultimate Bundle. Get the most out of Weave by scheduling now!',
    { ns: 'bundle>>ultimate-bundle' }
  ),
  actionLink: SCHEDULE_1_ON_1_TRAINING_URL,
};
