import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { useAppScopeStore } from '@frontend/scope';
import { BreadcrumbPathProps, Button, ContentLoader, useAlert } from '@frontend/design-system';
import { DemoDataBanner } from '../components';
import { PracticeAnalyticsURLs } from '../components/practice/constants';
import { useIsPADemoAccount, usePracticeAnalyticsShallowStore } from '../components/practice/hooks';
import {
  ActivePatientsSubView,
  ActivePatientsUnscheduledSubView,
  CancellationsSubView,
  HygieneFollowUpSubView,
  HygieneTreatmentSubView,
  NewPatientsSubView,
  PractitionerAnalysisSubView,
  RecapturedPatientsSubView,
  RestorativeTreatmentSubView,
} from '../components/practice/sub-views';
import { URLs } from '../constants';
import { useAnalyticsOrgLocations } from '../hooks';
import { trackingIds } from '../tracking-ids';

interface Props {
  id: string;
}

const flattenProcedures = (patients: PracticeAnalyticsTypes.PatientInfo[]) =>
  patients.map((patient) => ({
    ...patient,
    procedures: (patient.procedures || []).map(({ adaCode }) => adaCode).join(' '),
  }));

export const PracticeAnalyticsSubView: FC<React.PropsWithChildren<Props>> = ({ id }) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const { filterHintText, filters, isDemoModal, isPageReady, setIsDemoAccount, setIsPageReady, showDemoChipAndBanner } =
    usePracticeAnalyticsShallowStore(
      'filterHintText',
      'filters',
      'isDemoModal',
      'isPageReady',
      'setIsDemoAccount',
      'setIsPageReady',
      'showDemoChipAndBanner'
    );
  const isDemoAccount = useIsPADemoAccount();
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });
  const isSuperAdmin = hasSchemaACL(selectedLocationIdsWithParents[0], Permission.ANALYTICS_READ);
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [patients, setPatients] = useState<PracticeAnalyticsTypes.PatientInfo[]>([]);
  const [multiLevelBreadcrumbData, setMultiLevelBreadcrumbData] = useState<BreadcrumbPathProps>({ label: '', to: '' });

  const commonSubViewProps: PracticeAnalyticsTypes.MetricSubViewProps = {
    exportEnabled: isSuperAdmin,
    isDemoAccount,
    onPatientsLoad: setPatients,
  };

  const subView = useMemo(() => {
    if (!isPageReady) {
      return null;
    }

    // Multi-level sub view pages
    if (id.startsWith('practitioner-analysis/') && !id.endsWith('practitioner-analysis/')) {
      const [locationId] = id.split('/').slice(1);
      setMultiLevelBreadcrumbData({
        label: t('Practitioner Analysis'),
        to: `/analytics/practice/practitioner-analysis`,
      });

      return {
        title: locationNames[locationId] || locationId,
        view: <PractitionerAnalysisSubView {...commonSubViewProps} locationId={locationId} />,
      };
    }

    // One level sub view pages
    switch (id) {
      case 'cancelled-appointments':
        return {
          title: t('Cancellations'),
          view: <CancellationsSubView {...commonSubViewProps} />,
        };

      case 'demographics':
        return {
          view: <ActivePatientsSubView {...commonSubViewProps} />,
          title: t('Active Patients'),
        };

      case 'hygiene-follow-up':
        return {
          view: <HygieneFollowUpSubView {...commonSubViewProps} />,
          title: t('Hygiene Follow-Up'),
        };

      case 'hygiene-treatment':
        return {
          view: <HygieneTreatmentSubView {...commonSubViewProps} />,
          title: t('Hygiene Treatment Plan'),
        };

      case 'new-patients':
        return {
          view: <NewPatientsSubView {...commonSubViewProps} />,
          title: t('New Patients'),
        };

      case 'practitioner-analysis':
        return {
          view: <PractitionerAnalysisSubView {...commonSubViewProps} />,
          title: t('Practitioner Analysis'),
        };

      case 'recaptured-patients':
        return {
          view: <RecapturedPatientsSubView {...commonSubViewProps} />,
          title: t('Recaptured'),
        };

      case 'restorative-treatment':
        return {
          view: <RestorativeTreatmentSubView {...commonSubViewProps} />,
          title: t('Restorative Treatment Plan'),
        };

      case 'unscheduled-patients':
        return {
          view: <ActivePatientsUnscheduledSubView {...commonSubViewProps} />,
          title: t('Unscheduled Patients'),
        };

      default:
        return null;
    }
  }, [id, locationNames, isPageReady]);

  const handleExport = async (fileName: string) => {
    setIsExporting(true);
    const isAuthorized = await PracticeAnalyticsApi.auditPracticeAnalyticsExport(fileName);

    if (isAuthorized || isDemoAccount) {
      // When isDemoAccount is true, mock data will be exported
      const hasProcedures = !!patients.find(({ procedures }) => procedures?.length);
      const data = (hasProcedures ? flattenProcedures(patients) : patients) as PracticeAnalyticsTypes.PatientInfo[];
      const csv = unparse(data, {});
      saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), fileName + '.csv');
      setIsExporting(false);
    } else {
      alert.warning(t('You are not authorized to export data. This attempt has been logged.'));
      setIsExporting(false);
    }
  };

  const getDownloadAction = () => {
    switch (id) {
      case 'demographics':
      case 'practitioner-analysis':
      case 'unscheduled-patients':
        return undefined;

      default:
        return () => handleExport(subView?.title || '');
    }
  };

  const breadcrumbs: BreadcrumbPathProps[] = useMemo(() => {
    return [
      {
        label: isDemoModal ? '' : t('Analytics'),
        to: '/analytics/dashboard',
      },
      {
        label: t('Practice Analytics'),
        to: PracticeAnalyticsURLs.MAIN,
      },
      multiLevelBreadcrumbData,
      {
        label: subView?.title || '',
      },
    ].filter(({ label }) => !!label);
  }, [id, isDemoModal, multiLevelBreadcrumbData, subView?.title]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  useEffect(() => {
    // Go back to the main page when filters not available
    if (!Object.keys(filters).length) {
      navigate({ to: PracticeAnalyticsURLs.MAIN });
    } else {
      setIsPageReady(true);
    }
  }, []);

  return (
    <>
      {showDemoChipAndBanner && (
        <DemoDataBanner
          css={styles.demoBanner}
          onCTAClick={() => navigate({ to: URLs.PRACTICE_ANALYTICS_SUBSCRIBE })}
          startTrialTrackingId={trackingIds.practiceAnalyticsPLG.plgFreeTrialFromDemoSubPage}
          subtitle={t("As a subscriber, you'll see your own practice's data updated daily.")}
          title={t("You're taking Practice Analytics for a test drive with demo data!")}
        />
      )}
      <Page>
        <Page.Header>
          <Page.Header.Breadcrumbs breadcrumbs={breadcrumbs} />
          <Page.Header.Heading>
            <Page.Header.Title title={subView?.title} />
            <Page.Header.Subtitle
              subtitle={
                id === 'demographics' || id === 'unscheduled-patients'
                  ? ''
                  : t('Showing results for {{filtersInfo}}', { filtersInfo: filterHintText })
              }
            />
            <Page.Header.Action>
              {isSuperAdmin && typeof getDownloadAction() === 'function' && (
                <Button
                  disabled={!patients.length}
                  iconName='download'
                  label={t('Download')}
                  onClick={getDownloadAction()}
                  size='large'
                  trackingId={`export-main-${id}-button`}
                  variant='secondary'
                />
              )}
            </Page.Header.Action>
          </Page.Header.Heading>
        </Page.Header>

        <Page.Body>
          <div style={{ width: '100%' }} ref={containerRef}>
            {subView?.view}
          </div>
          <ContentLoader show={isExporting || !isPageReady} />
        </Page.Body>
      </Page>
    </>
  );
};

const styles = {
  demoBanner: css`
    isolation: isolate;
    margin-bottom: 0;
    position: sticky;
    top: 0;
    z-index: 4;
  `,
};
