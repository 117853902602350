import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';

type RenderProps<T> = {
  isLoadingData?: boolean;
  selection?: T[];
  trackingIdBase?: string;
};

export interface TableCustomToolbarActionsProps<T> extends RenderProps<T> {
  onExportClick: () => void;

  actions?: (props: RenderProps<T>) => ReactNode;
  showExportIcon?: boolean;
  isExportReady?: boolean;
}

export const TableCustomToolbarActions = <T,>({
  actions,
  isExportReady,
  isLoadingData,
  onExportClick,
  selection = [],
  showExportIcon = true,
  trackingIdBase = '',
}: TableCustomToolbarActionsProps<T>) => {
  const { t } = useTranslation('analytics');

  return (
    <div css={styles.wrapper}>
      {actions?.({ isLoadingData, selection, trackingIdBase })}

      {showExportIcon && (
        <Button
          className='export-button'
          disabled={isLoadingData || !isExportReady}
          iconName='download'
          label={t('Export')}
          onClick={onExportClick}
          size='large'
          trackingId={`practice-analytics-export-${trackingIdBase}`}
          variant='secondary'
        />
      )}
    </div>
  );
};

TableCustomToolbarActions.displayName = 'TableCustomToolbarActions';

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};

    .export-button {
      padding: ${theme.spacing(0.5)};
    }
  `,
};
