import { css } from '@emotion/react';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { LocationChip } from '../../views/Calendar/components/HeaderBar/LocationChip';
import { CalendarViewHeaderDateSelector } from '../CalendarViewHeaderDateSelector';
import { CalendarViewV3HeaderFilter } from './CalendarViewV3HeaderFilter';
import { CalendarViewV3HeaderSummary } from './CalendarViewV3HeaderSummary';

export const CalendarViewV3HeaderContainer = () => {
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const isMedium = useMatchMedia({ minWidth: breakpoints.medium.min, maxWidth: breakpoints.large.max });
  const isLarge = useMatchMedia({ minWidth: breakpoints.large.min });

  return (
    <div css={calendarViewHeaderStyles(isSmall)}>
      <div css={{ display: 'flex', flexDirection: 'row', paddingBottom: theme.spacing(2) }}>
        <CalendarViewHeaderDateSelector width={isSmall ? 300 : 240} />
        <LocationChip show={isMedium || isLarge} />
      </div>
      <div css={calendarSummaryAndFilterContainerStyles}>
        <CalendarViewV3HeaderSummary />
        <CalendarViewV3HeaderFilter />
      </div>
    </div>
  );
};

const calendarSummaryAndFilterContainerStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: theme.spacing(2),
});

const calendarViewHeaderStyles = (isSmall: boolean) =>
  css({
    backgroundColor: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.neutral20}`,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: isSmall ? 'column' : 'row',
    alignItems: isSmall ? 'start' : 'flex-end',
    padding: isSmall ? theme.spacing(3) : theme.spacing(3, 5, 0, 5),
  });
