import { LineKey } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { Text } from '@frontend/design-system';
import { truncateLineKeyName } from '../../utils';

type Props = {
  keys: (string | number | undefined)[];
  lineKeys: LineKey[] | undefined;
};
export const LayoutNameRender = ({ keys, lineKeys }: Props) => {
  return (
    <>
      {keys.map((key) => {
        if (typeof key === 'number' && !!lineKeys?.length) {
          const name = lineKeys[key - 1]?.name ?? '';
          return (
            <li key={key}>
              <Text as='span'>{truncateLineKeyName(name)}</Text>
            </li>
          );
        }
        return (
          <li key={key}>
            <Text as='span' className='line-key-placeholder'>
              {key}
            </Text>
          </li>
        );
      })}
    </>
  );
};
