import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { BASE_URL_ANALYTICS, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useGetChartPayloadAndConfig } from './use-get-chart-payload-and-config';
import { usePhoneAnalyticsShallowStore } from './use-phone-analytics-store';

type ApiCondition = 'call_direction' | 'incoming_call_status' | 'source_type';

type KeyAggregate = {
  calls?: {
    daily: number;
    hourly?: number[];
  };
  duration?: {
    daily: number;
    hourly?: number[];
  };
};

type DateAggregate<T extends string> = {
  key: T;
  values: KeyAggregate;
};

type DateData<T extends string> = {
  date: string;
  aggregates?: DateAggregate<T>[];
};

type LocationData<T extends string> = {
  id: string;
  dates?: DateData<T>[];
};

export type TotalCallsAndDurationResponse<T extends string> = {
  locationIds?: LocationData<T>[];
};

type FormattedLocations<T extends string> = {
  [locationId: string]: {
    [date: string]: {
      [key in T]?: KeyAggregate;
    };
  };
};

type AggregatedMultiLocations<T extends string> = {
  [locationId: string]: {
    calls?: {
      [key in T]?: number;
    };
    duration?: {
      [key in T]?: number;
    };
  };
};

type DataState<T extends string> = {
  aggregatedMultiLocations?: AggregatedMultiLocations<T>;
  locations: FormattedLocations<T>;
};

const formatLocationsData = <T extends string>(locationIds?: LocationData<T>[]): DataState<T> => {
  const aggregatedMultiLocations: AggregatedMultiLocations<T> = {};

  const formattedLocations =
    locationIds?.reduce((acc, { dates, id }) => {
      return {
        ...acc,
        [id]:
          dates?.reduce(
            (acc2, { aggregates, date }) =>
              aggregates?.reduce<{ [key: string]: KeyAggregate }>((acc3, { key, values }) => {
                aggregatedMultiLocations[id] = {
                  ...(aggregatedMultiLocations[id] || {}),
                  calls: {
                    ...(aggregatedMultiLocations[id]?.calls || {}),
                    [key]: (aggregatedMultiLocations[id]?.calls?.[key] || 0) + (values.calls?.daily || 0),
                  },
                  duration: {
                    ...(aggregatedMultiLocations[id]?.duration || {}),
                    [key]: (aggregatedMultiLocations[id]?.duration?.[key] || 0) + (values.duration?.daily || 0),
                  },
                };

                return {
                  ...acc3,
                  [date]: {
                    ...acc3[date],
                    [key]: {
                      calls: {
                        daily: values.calls?.daily || 0,
                        hourly: values.calls?.hourly || [],
                      },
                      duration: {
                        daily: values.duration?.daily || 0,
                        hourly: values.duration?.hourly || [],
                      },
                    },
                  },
                };
              }, acc2) || {},
            {}
          ) || {},
      };
    }, {}) || {};

  return {
    aggregatedMultiLocations,
    locations: formattedLocations,
  };
};

const api = async <T extends string>(filters: Partial<PhoneAnalyticsTypes.Filters>, condition: ApiCondition) => {
  const interceptedDates = PhoneAnalyticsApi.getDatesIntercepted(filters);

  const { data } = await http.post<{ data: TotalCallsAndDurationResponse<T> }>(
    `${BASE_URL_ANALYTICS}/${'phonereports/v2/getcallaggregates'}/${condition}`,
    interceptedDates
  );
  return data;
};

export const useFetchAggregatedCallsAndDurationReport = <T extends string>(
  condition: ApiCondition,
  customFilters?: Partial<PhoneAnalyticsTypes.Filters>,
  shouldFetch = true
): PhoneAnalyticsTypes.UseFetchPhoneReports<DataState<T>> => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePhoneAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const [data, setData] = useState<DataState<T> | undefined>();
  const { applicableFilters, config } = useGetChartPayloadAndConfig(
    customFilters || filters,
    `calls-and-durations-${condition}`
  );

  const isMultiLocation = (applicableFilters?.location_id || []).length > 1;
  const isHourlyData =
    !isMultiLocation &&
    !!applicableFilters.start_date &&
    !!applicableFilters.end_date &&
    dayjs(applicableFilters.start_date).isSame(applicableFilters.end_date, 'day');
  const hasRequiredFilters =
    !!applicableFilters.start_date && !!applicableFilters.end_date && !!applicableFilters.location_id?.length;

  const { data: phoneReport, isLoading } = useScopedQuery({
    ...config,
    queryFn: () => api<T>(applicableFilters, condition),
    onError: () => {
      alert.error(t("Couldn't load the aggregated phone reports. Please try again."));
    },
    enabled: shouldFetch && hasRequiredFilters,
  });

  useEffect(() => {
    let dataToUse = phoneReport;

    if (isDemoAccount) {
      switch (condition) {
        case 'call_direction':
          dataToUse = demoData?.chartsData.totalCallsAndDurations as TotalCallsAndDurationResponse<T>;
          break;

        case 'incoming_call_status':
          dataToUse = demoData?.chartsData.incomingCallsAndDurations as TotalCallsAndDurationResponse<T>;
          break;

        case 'source_type':
          dataToUse = demoData?.chartsData.sourceTypeCallsAndDurations as TotalCallsAndDurationResponse<T>;
          break;
      }
    }

    setData(formatLocationsData(dataToUse?.locationIds));
  }, [condition, demoData, isDemoAccount, phoneReport]);

  return {
    data,
    isHourlyData,
    isLoading,
    isMultiLocation,
  };
};
