export enum MassMessageTagTypes {
  FIRST_NAME = 'FIRST_NAME',
  LAST_NAME = 'LAST_NAME',
  PREFERRED_NAME = 'PREFERRED_NAME',
  PRACTICE_NAME = 'PRACTICE_NAME',
  BUSINESS_GROUP_NAME = 'BUSINESS_GROUP_NAME',
  DATETIME_FORMAL = 'DATETIME_FORMAL',
  TIME = 'TIME',
}

export type MassMessageTagLabel =
  | 'First Name'
  | 'Last Name'
  | 'Preferred Name'
  | 'Practice Name'
  | 'Date & Time'
  | 'Time';

export const MassMessageTagLabelMap: Record<MassMessageTagTypes, MassMessageTagLabel> = {
  [MassMessageTagTypes.FIRST_NAME]: 'First Name',
  [MassMessageTagTypes.LAST_NAME]: 'Last Name',
  [MassMessageTagTypes.PREFERRED_NAME]: 'Preferred Name',
  [MassMessageTagTypes.PRACTICE_NAME]: 'Practice Name',
  [MassMessageTagTypes.BUSINESS_GROUP_NAME]: 'Practice Name',
  [MassMessageTagTypes.DATETIME_FORMAL]: 'Date & Time',
  [MassMessageTagTypes.TIME]: 'Time',
};
