import { useMemo } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { formatters } from '../../../../utils';
import { PatientsBreakdownTable } from '../components';
import { MCTChartsBase } from './mct-charts-base';

// TODO: Replace with real data
const dummyData = {
  appointmentsCompleted: 210,
  production: 42000,
  existingPatients: 22000,
  newPatients: 20000,
};

export const MCTRevenueChart = () => {
  const { t } = useTranslation('analytics');

  const chartData = useMemo(() => {
    return {
      centerMetric: `${formatters.currency.shortenNumber(dummyData.production)}`,
      centerMetricLabel: t('Total'),
      groups: [
        {
          name: 'newPatients',
          value: dummyData.newPatients,
        },
        {
          name: 'existingPatients',
          value: dummyData.existingPatients,
        },
      ],
    };
  }, [dummyData]);

  return (
    <MCTChartsBase
      chartConfig={{
        data: chartData,
        type: 'pie',
      }}
      legendsFormatValue={formatters.currency.shortenNumber}
      legendsValues={dummyData}
      message={
        <Trans t={t}>
          <strong>{{ appointments: dummyData.appointmentsCompleted }}</strong> appointments were completed leading to{' '}
          <strong>{{ revenue: formatters.currency.format(dummyData.production) }}</strong> Production value
        </Trans>
      }
      title={t('Revenue Generated')}
      value={formatters.currency.format(dummyData.production)}
    >
      <PatientsBreakdownTable />
    </MCTChartsBase>
  );
};
