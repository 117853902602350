import { createProvider } from '@frontend/store';
import { ModalControlModalProps, contextFactory } from '@frontend/design-system';

type CreateSoftphoneStore = {
  showConfirmedExitModal: boolean;
  setShowConfirmedExitModal: (value: boolean) => void;
};

export const { Provider: CreateSoftphoneProvider, useStore: useCreateSoftphone } =
  createProvider<CreateSoftphoneStore>()((set) => ({
    showConfirmedExitModal: false,
    setShowConfirmedExitModal: (showConfirmedExitModal) => {
      set(() => ({ showConfirmedExitModal }));
    },
  }));

type CreateSoftphoneProps = {
  /**
   * Is the modal Open
   */
  closeModal: ModalControlModalProps['onClose'];
};

export const [CreateSoftphoneContext, useCreateSoftphoneContext] = contextFactory<CreateSoftphoneProps>();
