import { useEffect, useState } from 'react';
import { TenCoordinatorV1 } from '@frontend/api-tendlc-coordinator';

type Props = {
  locationIds: string[];
};

export const useBrandVerification = ({ locationIds }: Props) => {
  const [isMissingBusinessInfo, setIsMissingBusinessInfo] = useState(false);

  const { data: brandCompliance } = TenCoordinatorV1.Queries.useGetLocationsBrandComplianceQuery({
    locationIds,
  });

  const filteredLocationIds = brandCompliance?.locationBrandCompliances
    .filter((item) => !item.isCanada && !item.isSoleProprietor)
    .map((item) => item.locationId);

  const { data: unverifiedCount } = TenCoordinatorV1.Queries.useGetUnverifiedLocationCountQuery(locationIds);

  const { data: unregisteredCount } = TenCoordinatorV1.Queries.useGetUnregisteredLocationCountQuery(
    filteredLocationIds || [],
    {
      enabled: !!filteredLocationIds && filteredLocationIds?.length > 0,
    }
  );

  useEffect(() => {
    if (unverifiedCount !== 0 && unregisteredCount !== 0) {
      setIsMissingBusinessInfo(true);
    } else {
      setIsMissingBusinessInfo(false);
    }
  }, [unverifiedCount, unregisteredCount]);

  return {
    unverifiedCount,
    unregisteredCount: unregisteredCount || 0,
    isMissingBusinessInfo,
  };
};
