import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { dispatchTopBarTraySelectionEvent } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';
import { QuickfillFullHistoryTable } from '../../components/QuickfillFullHistoryTable';

const CALENDAR_ROUTE = '/schedule/calendar';

export const QuickfillHistory = () => {
  const { t } = useTranslation('scheduleQuickfill');
  return (
    <Page>
      <Page.Header>
        <Page.Header.Breadcrumbs
          breadcrumbs={[
            {
              label: t('Calendar'),
              to: CALENDAR_ROUTE,
            },
            {
              label: t('Quick Fill History'),
            },
          ]}
        />
        <Page.Header.Heading>
          <Page.Header.Title title={t('Quick Fill History')} />
          <Page.Header.Action>
            <Button onClick={(e) => dispatchTopBarTraySelectionEvent('quickfill', e)}>{t('Start Quick Fill')}</Button>
          </Page.Header.Action>
          <Page.Header.Subtitle
            subtitle={t(
              "A summary of the practice's text message outreach for filling last-minute appointments. It includes message details, patient responses, appointment outcomes, and key metrics, enabling assessment of the campaign's effectiveness and patient engagement."
            )}
          />
        </Page.Header.Heading>
      </Page.Header>
      <Page.Body css={bodyStyle}>
        <QuickfillFullHistoryTable />
      </Page.Body>
    </Page>
  );
};

const bodyStyle = css({
  height: '100%',
  marginBottom: theme.spacing(3),
});
