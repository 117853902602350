import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { theme } from '@frontend/theme';
import { URLs } from '../../../../constants';
import { Filters } from '../../components';
import { useROIShallowStore } from '../../hooks';
import { MCTCharts } from '../components';

export const MCTRoiDashboard = () => {
  const { t } = useTranslation('analytics');
  const { filterHintText: subtitle } = useROIShallowStore('filterHintText');

  return (
    <Page>
      <Page.Header>
        <Page.Header.Breadcrumbs
          breadcrumbs={[
            {
              label: t('Analytics'),
              to: URLs.ANALYTICS_DASHBOARD,
            },
            {
              label: t('Missed Call Text Conversion'),
            },
          ]}
        />
        <Page.Header.Heading>
          <Page.Header.Title title={t('Missed Call Text Conversion')} />
          <Page.Header.Subtitle subtitle={t('Showing results for {{subtitle}}', { subtitle })} />
        </Page.Header.Heading>
      </Page.Header>

      <Page.Body css={styles.body}>
        <div css={styles.body}>
          {/* TODO :: add isLoading prop to the Filters when apis are connected */}
          <Filters />
          <MCTCharts />
        </div>
      </Page.Body>
    </Page>
  );
};

const styles = {
  body: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
  `,
};
