import { RouterPayload } from './route.types';

/**
 * Helper function to get the route path as string.
 */
function getRoutePathAsString(routerPayload: RouterPayload): string {
  const searchParams = new URLSearchParams(routerPayload.search);
  const search = searchParams.toString();
  return `${routerPayload.to}${search ? `?${search}` : ''}`;
}

export const routeUtils = {
  getRoutePathAsString,
};
