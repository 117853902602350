import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';

type CarouselButtonProps = {
  direction: 'left' | 'right';
  onClick: () => void;
};

export const CarouselButton = ({ direction, onClick }: CarouselButtonProps) => (
  <Button
    iconName={`caret-${direction}`}
    onClick={onClick}
    size='large'
    variant='secondary'
    label={direction === 'left' ? 'Previous' : 'Next'}
    css={css`
      position: fixed;
      top: 50%;
      ${direction}: calc(50% - 360px);
      z-index: ${theme.zIndex.modals};
      background: ${theme.colors.white};
      box-shadow: ${theme.shadows.heavy};
      @media (max-width: 800px) {
        ${direction}: 20%;
        top: initial;
        bottom: ${theme.spacing(2)};
      }
    `}
  ></Button>
);
