import { memo, useEffect, useMemo } from 'react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { DemoChip } from '..';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { CompareLocationsButton } from '../compare-locations-button';
import { PracticeAnalyticsURLs } from './constants';
import { usePaNavigate, usePracticeAnalyticsShallowStore } from './hooks';
import { PracticeAnalyticsInfoTips, monthlyTrendChartModal } from './';

const query = gql`
  query {
    location {
      activePatients {
        historicalData(count: 24, step: WEEKLY) {
          total
          label
          timestamp
        }
        total
      }
    }
  }
`;

export type PatientsCount = {
  lastPeriod?: number;
  total?: number;
};

type Props = {
  onUpdatePatientCount: (patientsCount: PatientsCount) => void;
  renderBottomContent: () => JSX.Element;
  windowWidth: number;
};

const colors = {
  currentPeriodTrend: theme.colors.primary50,
  previousPeriodTrend: theme.colors.primary20,
};

export const ActivePatientsMetric = memo(
  ({
    clickNoop,
    isDrillDownPage,
    onFetchStateChange,
    onUpdatePatientCount,
    renderBottomContent,
    windowWidth,
  }: PracticeAnalyticsTypes.MetricProps & Props) => {
    const alert = useAlert();
    const { t } = useTranslation('analytics');
    const navigate = usePaNavigate();
    const { demoData, filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
      'demoData',
      'filters',
      'isDemoAccount',
      'showDemoChipAndBanner'
    );

    const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

    const labels = {
      currentPeriodTrend: t('This Period'),
      previousPeriodTrend: t('Last Period'),
    };

    const activePatientsModal = monthlyTrendChartModal({
      defaultKey: 'total',
      metric: 'activePatients',
      title: t('Trend: Active Patients'),
      trackingId: trackingIds.practiceAnalytics.activePatientsTrend,
    });

    const { data, isLoading } = useScopedQuery({
      queryKey: queryKeys.practiceAnalyticsCharts(
        `summaryMetricActivePatients-${isDemoAccount}-${JSON.stringify(filters.locations)}`
      ),
      queryFn: () =>
        isDemoAccount || !filters.locations?.length
          ? null
          : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.ActivePatientsResponse>({
              locationIds: filters.locations,
              queries: [query],
            }),
      onError: () => {
        alert.error(t("Couldn't load the dashboard data. Please try again."));
      },
      select: (data) => (isDemoAccount ? demoData?.activePatients : data),
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

    const chartData = useMemo(() => {
      const aggregatedData = PracticeAnalyticsAggregations.activePatientsSummary(data);
      const { activePatients } = aggregatedData;

      // As discussed, we need to divide the result into 2 parts to show 12 weeks trend for current vs previous period
      // Also, make sure that if the historicalData data length is less than 24, then divide them into 2 parts
      const historicalDataLength = activePatients?.historicalData?.length || 0;
      const currentPeriodPatients = activePatients?.historicalData?.slice(historicalDataLength / 2) || [];
      const previousPeriodPatients = activePatients?.historicalData?.slice(0, historicalDataLength / 2) || [];

      const totalPatients = activePatients?.total || 0;
      const lastPeriodTotalPatients = previousPeriodPatients[previousPeriodPatients.length - 1]?.['total'] || 0;

      return {
        data: {
          groups: currentPeriodPatients.map(({ total }, index) => ({
            name: t('Week {{value}}', { value: index + 1 }),
            values: {
              currentPeriodTrend: total,
              previousPeriodTrend: previousPeriodPatients[index]?.total,
            },
          })),
        },
        lastPeriodTotalPatients,
        totalPatients,
      };
    }, [data]);

    useEffect(() => {
      onUpdatePatientCount({
        lastPeriod: chartData?.lastPeriodTotalPatients || 0,
        total: chartData?.totalPatients || 0,
      });
    }, [chartData]);

    useEffect(() => {
      onFetchStateChange?.(isLoading);
    }, [isLoading]);

    return (
      <>
        <Chart
          bottomContentRenderer={windowWidth < breakpoints.medium.min ? renderBottomContent() : null}
          colors={colors}
          isLoading={isLoading}
          labels={labels}
          onClick={
            clickNoop
              ? undefined
              : () => {
                  navigate({
                    to: `${PracticeAnalyticsURLs.BASE}/demographics`,
                  });
                }
          }
          trackingId={trackingIds.practiceAnalytics.activePatientsChart}
        >
          {isDrillDownPage ? (
            showDemoChipAndBanner && <DemoChip />
          ) : (
            <Chart.Header
              actions={[activePatientsModal.triggerProps]}
              bottomElement={multipleLocationsSelected ? <CompareLocationsButton /> : null}
              infoTip={<PracticeAnalyticsInfoTips tip='activePatients' />}
              leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
              title={t('Active Patients')}
            />
          )}
          <Chart.AreaChart
            appearance={{
              gradientOpacity: {
                currentPeriodTrend: 0,
              },
              showXAxis: true,
              showYAxis: true,
            }}
            data={chartData.data}
            formatValue={formatters.value.format}
          />
          <Chart.Legends />
        </Chart>

        {activePatientsModal.modalRenderer()}
      </>
    );
  }
);

ActivePatientsMetric.displayName = 'ActivePatientsMetric';
