export const arrayMatches = (a: string[], b: string[]): boolean => {
  return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((v, i) => v === b[i]);
};

export const arrayMove = <T>(arr: T[], fromIndex: number, toIndex: number) => {
  const element = arr[fromIndex];
  const newArr = [...arr] as T[];
  newArr.splice(fromIndex, 1);
  newArr.splice(toIndex, 0, element);
  return newArr;
};

/**
 * Returns the common items in the provided string or number arrays.
 */
export function getCommonItemsFromArrays<T extends string | number>(...itemsArray: T[][]): T[] {
  if (itemsArray.length === 0) {
    // This is to handle the error -> "TypeError: Reduce of empty array with no initial value"
    return [];
  }

  return itemsArray.reduce((acc, items) => {
    return acc.filter((item) => items.includes(item));
  });
}

/**
 * Checks if the given array is a subset of the main array.
 */
export function isSubsetOfMainArray<T extends string | number>(mainArray: T[], arrayToCheck: T[]) {
  return arrayToCheck.every((item) => mainArray.includes(item));
}
