import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { Drawer, useDrawer } from '@frontend/design-system';
import { usePanelStore, usePanelShallowStore } from './store';

//drawer should appear below settings modal
const drawerZIndex = theme.zIndex.modals - 1;

export const PanelLayout: React.FC = React.memo(() => {
  const { updateAvailableWidth, hasTrayPanels } = usePanelShallowStore('updateAvailableWidth', 'hasTrayPanels');

  const { modalProps } = useDrawer({
    open: hasTrayPanels,
    onOpenChange: (open) => {
      if (!open) {
        const trayIds = usePanelStore.getState().trayIds;
        const panelCloseFunctions = usePanelStore.getState().panelCloseFunctions;
        trayIds.forEach((id) => panelCloseFunctions[id]?.());
      }
    },
  });

  const resizeRafId = useRef<number | null>(null);

  useEffect(() => {
    function onResize() {
      if (resizeRafId.current) {
        cancelAnimationFrame(resizeRafId.current);
      }
      resizeRafId.current = requestAnimationFrame(() => {
        updateAvailableWidth(window.innerWidth);
      });
    }

    window.addEventListener('resize', onResize, { passive: true });
    return () => {
      window.removeEventListener('resize', onResize);
      if (resizeRafId.current) cancelAnimationFrame(resizeRafId.current);
    };
  }, [updateAvailableWidth]);

  return (
    <motion.div
      css={css`
        height: 100%;
      `}
    >
      <div
        id='panel-root'
        css={css`
          display: flex;
          height: 100%;
        `}
      />
      <Drawer
        keepMounted
        css={[
          css`
            width: auto;
            display: flex;
            padding: 0;
            max-width: 100%;
          `,
        ]}
        {...modalProps}
        size='auto'
        zIndex={drawerZIndex}
      >
        <motion.div
          css={css`
            display: flex;
            height: 100%;
          `}
          id='tray-root'
        />
      </Drawer>
    </motion.div>
  );
});
