import { useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { TextLink } from '@frontend/design-system';
import { AnalyticsFeatureFlags, AnalyticsTrackingIds } from '..';
import { HomepageAnalyticsBody, HomepageAnalyticsHeader } from '../components/homepage-analytics';
import { useIsOnboardingCompleted } from '../hooks';
import { defaultDateRangeMap } from '../utils';

const thisWeekDateRange = defaultDateRangeMap['this-week'];

export const AnalyticsOnPortalHomepage = () => {
  const { t } = useTranslation('analytics');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const navigate = useNavigate();
  const { isOnboardingCompleted } = useIsOnboardingCompleted();

  const isPhoneAnalyticsEnabled = useHasFeatureFlag(AnalyticsFeatureFlags.phoneAnalytics);
  const isMessagingAnalyticsEnabled = useHasFeatureFlag(AnalyticsFeatureFlags.messagingAnalytics);

  // Homepage shows only phone and messaging analytics. So one of these two needs to be enabled for the analytics section to show
  const showAnalyticsOnPortalHomepage =
    isOnboardingCompleted && (isPhoneAnalyticsEnabled || isMessagingAnalyticsEnabled);

  const filters: AnalyticsCommonTypes.AnalyticsSummaryFilters = {
    startDate: dayjs(thisWeekDateRange.startDate),
    endDate: dayjs(thisWeekDateRange.endDate),
    timeZone: getSelectedLocationData()[selectedLocationIdsWithParents[0]]?.timezone || '',
  };

  const handleExploreAnalytics = () => {
    navigate({ to: '/analytics/dashboard' });
  };

  if (!showAnalyticsOnPortalHomepage) {
    return null;
  }

  return (
    <section>
      <HomepageAnalyticsHeader
        action={
          // Using onClick for the text link because utilizing href causes a complete app refresh and leads to a lengthy loading time.
          <TextLink
            onClick={handleExploreAnalytics}
            trackingId={AnalyticsTrackingIds.analytics.exploreAnalytics}
            weight='bold'
          >
            {t('Explore Analytics')}
          </TextLink>
        }
        title={t('This Week at a Glance')}
      />
      <HomepageAnalyticsBody filters={filters} />
    </section>
  );
};
