import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, ChipProps } from '@frontend/design-system';
import { SharedNodeProps } from '../data';

export const TerminateNode = memo(({ data }: SharedNodeProps) => {
  return (
    <>
      <Handle
        type='target'
        position={Position.Top}
        id={data.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <PhoneChip>{data.label}</PhoneChip>
    </>
  );
});
TerminateNode.displayName = 'TerminateNode';

const PhoneChip = (props: ChipProps) => {
  return (
    <Chip
      css={{ background: theme.colors.neutral90, color: theme.colors.white }}
      leftElement={<Icon name='phone-call-small' />}
      {...props}
    />
  );
};
