import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { Page } from '@frontend/components';
import { i18next, Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Heading, Text, useModalControl } from '@frontend/design-system';
import { DemoChip, FeedbackButton } from '../components';
import {
  CallIntelDemoBanner,
  CallIntelFilters,
  CallIntelLocationComparisonCharts,
  CallRecordsTable,
  ExploreLocationsTray,
} from '../components/call-intelligence';
import { CallRecordingsBanner } from '../components/call-intelligence/call-recordings-banner';
import { CallIntelMockData } from '../components/call-intelligence/demo-data';
import {
  CallIntelSubViewId,
  CallIntelSubViewType,
  DataLabelsAndColors,
  MultiLocationsView,
  useCallIntelLocations,
  useCallIntelShallowStore,
  useCallTakeawayPanelShallowStore,
  useIsCallIntelDemoAccount,
} from '../components/call-intelligence/hooks';
import { useLocationsMatch } from '../components/call-intelligence/hooks/use-locations-match';
import {
  OpportunitiesBySentimentsSubView,
  OpportunitiesByTypeSubView,
} from '../components/call-intelligence/sub-views';
import { ServiceQualitySubView } from '../components/call-intelligence/sub-views/service-quality-sub-view';
import { URLs } from '../constants';
import { trackingIds } from '../tracking-ids';
import { callIntelligenceUtils } from '../utils';
import { callIntelStyles, filtersStyles, stickyTabsStyles } from './common-styles';

type PageTypeConfig = {
  getLabel: (dataLabels: DataLabelsAndColors, pageId: string) => string;
  getMetrics: (dataLabels: DataLabelsAndColors, pageId: string) => string;
  title: string;
  withHeading: boolean;
};

type CallRecordsTableProps = {
  defaultAccordion?: CallIntelligenceTypes.CallTakeawayAccordion;
  priorityColumns?: string[];
  hideFilters?: (keyof CallIntelligenceTypes.Filters)[];
};

const pageTypeConfig: Record<CallIntelSubViewType, PageTypeConfig> = {
  'appointment-type': {
    getLabel: (dataLabels, pageId) => dataLabels.appointmentTypes?.[pageId] || '',
    getMetrics: (dataLabels, pageId) => dataLabels.appointmentTypes?.[pageId] || '',
    title: i18next.t('Appointment Type', { ns: 'analytics' }),
    withHeading: true,
  },
  category: {
    getLabel: (dataLabels, pageId) => dataLabels.categories?.[pageId] || '',
    getMetrics: (dataLabels, pageId) => dataLabels.categories?.[pageId] || '',
    title: i18next.t('Category', { ns: 'analytics' }),
    withHeading: true,
  },
  sentiment: {
    getLabel: (dataLabels, pageId) => dataLabels.sentimentsWithEmoji?.[pageId] || '',
    getMetrics: (dataLabels, pageId) => dataLabels.sentiments?.[pageId] || '',
    title: i18next.t('Customer Sentiment', { ns: 'analytics' }),
    withHeading: true,
  },
  'service-quality': {
    getLabel: (dataLabels, pageId) => dataLabels.serviceQualityFlag?.[pageId] || '',
    getMetrics: (dataLabels, pageId) => dataLabels.serviceQualityFlag?.[pageId] || '',
    title: '',
    withHeading: false,
  },
};

const getPageConfig = (pageType: CallIntelSubViewType): PageTypeConfig | null => {
  return pageTypeConfig[pageType] || null;
};

const dummyLocationNames = CallIntelMockData.dummyLocationNames();

export const CallIntelSubView = () => {
  const { t } = useTranslation('analytics');
  const stickyTabsRef = useRef<HTMLDivElement | null>(null);
  const { modalProps, triggerProps } = useModalControl();
  const {
    dataLabels,
    defaultFilters,
    filterHintText,
    filters,
    isDemoModal,
    setFilters,
    setFiltersToRestore,
    subView,
    setIsDemoAccount,
    trayFilters,
  } = useCallIntelShallowStore(
    'dataLabels',
    'defaultFilters',
    'filterHintText',
    'filters',
    'isDemoModal',
    'setFilters',
    'setFiltersToRestore',
    'setIsDemoAccount',
    'subView',
    'trayFilters'
  );
  const { setEditedCallIds } = useCallTakeawayPanelShallowStore('setEditedCallIds');
  const navigate = useNavigate();
  const { current } = useLocation();
  const isDemoAccount = useIsCallIntelDemoAccount();
  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? dummyLocationNames : undefined,
  });
  const areLocationsMatching = useLocationsMatch(locations);
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [activeMultiView, setActiveMultiView] = useState<MultiLocationsView>('summary');
  const [isLoadingChartsData, setIsLoadingChartsData] = useState<boolean>(false);
  const pageId = subView.id as CallIntelSubViewId;
  const pageType = subView.type;
  const pageConfig = pageType ? getPageConfig(pageType) : null;
  const pageLabel = pageConfig ? pageConfig.getLabel(dataLabels, pageId) : '';
  const metricsFor = pageConfig ? pageConfig.getMetrics(dataLabels, pageId) : '';
  const pageTypeTitle = pageConfig?.title || '';

  const drillDownFilters = callIntelligenceUtils.getDrillDownFilterKeys(subView);

  const getSubView = () => {
    switch (pageType) {
      case 'appointment-type':
      case 'category':
        return {
          overview: (
            <OpportunitiesByTypeSubView metricsFor={metricsFor} onChangeLoadingState={setIsLoadingChartsData} />
          ),
        };

      case 'sentiment':
        return {
          overview: (
            <OpportunitiesBySentimentsSubView
              metricsFor={metricsFor}
              onChangeLoadingState={setIsLoadingChartsData}
              sentimentCode={pageId}
            />
          ),
        };

      case 'service-quality':
        return {
          overview: <ServiceQualitySubView metricsFor={metricsFor} type={pageId} />,
        };

      default:
        return null;
    }
  };

  const getPageSubtitleText = () =>
    `${
      isMultiLocation && filters.locations?.length
        ? filters.locations.length === Object.keys(locations).length
          ? t('all locations, ')
          : filters.locations.length === 1
          ? t('1 location, ')
          : t('{{count}} locations, ', { count: filters.locations.length })
        : ''
    }${filterHintText}`;

  useEffect(() => {
    // TODO :: This is temporary and need to be updated by allowing passing ref to the Page component
    const scrollableParent = stickyTabsRef.current?.parentElement?.parentElement?.parentElement;

    const handleScroll = () => {
      if (scrollableParent && stickyTabsRef.current) {
        const { top: stickyTop } = stickyTabsRef.current.getBoundingClientRect();
        const { top: pageTop } = scrollableParent.getBoundingClientRect();

        if (pageTop === stickyTop) {
          stickyTabsRef.current.classList.add('sticky-shadow');
        } else {
          stickyTabsRef.current.classList.remove('sticky-shadow');
        }
      }
    };

    scrollableParent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableParent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Navigate to the overveiw page when main location changed while viewing drill down page
    if (!areLocationsMatching) {
      navigate({ to: URLs.CALL_INTEL_MAIN_PAGE });
      setFiltersToRestore({});
    }
  }, [areLocationsMatching]);

  useEffect(() => {
    if (!pageId || !pageType) {
      navigate({ to: URLs.CALL_INTEL_MAIN_PAGE });
    }

    // In case of multi location comparison chart click, the search param will have the clicked location id
    // and the filters state will be updated accordingly
    const searchLocationId = current.search['locationId'] as string;

    // Format drill down filters and apply them to the filters state for visual purpose
    // Consider the defaultFilters so older filters are not retained except for date and location filters
    const filtersMod = {
      ...defaultFilters,
      endDate: filters.endDate,
      locations: searchLocationId ? [searchLocationId] : filters.locations,
      startDate: filters.startDate,
      contactTypes: filters.contactTypes,
    };
    const { key, value } = drillDownFilters || {};

    switch (key) {
      case 'appointment_types':
        filtersMod.appointmentTypes = value;
        break;

      case 'categories':
        filtersMod.categories = value;
        break;

      case 'sentiments':
        filtersMod.sentiments = value;
        break;

      case 'service_quality_flags':
        filtersMod.serviceQualityFlag = value as CallIntelligenceTypes.ServiceQualityFlagEnum[];
        break;

      default:
        break;
    }

    setFilters(filtersMod);

    // Using it to avoid multiple api calls on initial load
    setFiltersReady(true);

    return () => {
      setEditedCallIds([]);
    };
  }, []);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  const callRecordsTableProps: CallRecordsTableProps =
    pageType === 'service-quality'
      ? {
          defaultAccordion: 'service-quality',
          priorityColumns: ['person', 'locationName', 'time', 'contactType', 'serviceQualityFlags', 'tasks'],
          hideFilters: [
            'locations',
            'startDate',
            'endDate',
            'contactTypes',
            'schedulingOutcomes',
          ] as (keyof CallIntelligenceTypes.Filters)[],
          ...(pageId === CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE && {
            trackingOptions: {
              page: `${pageType}-drilldown`,
              context: CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE.toLowerCase()
                .split('_')
                .join('-'),
            },
          }),
        }
      : {};

  const trayNotificationMessage =
    pageId === CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE && trayFilters.officeUsers?.length ? (
      <Trans t={t}>
        <Text as='span' size='medium' weight='bold'>
          Unresolved Calls are intended to highlight unmet patient needs, and are not meant to evaluate staff
          performance.
        </Text>{' '}
        <Text as='span' size='medium'>
          Please review important calls carefully to ensure fairness and address potential AI bias.
        </Text>
      </Trans>
    ) : null;

  return (
    <>
      <CallIntelDemoBanner />
      <CallRecordingsBanner />

      <Page
        action={
          <FeedbackButton
            productCode={Feature.CALL_INTELLIGENCE}
            trackingId={trackingIds.callIntel.generalFeedbackDrillDownPage}
          />
        }
        breadcrumbs={[
          {
            label: isDemoModal ? '' : t('Analytics'),
            to: '/analytics/dashboard',
          },
          {
            label: t('Call Intelligence'),
            to: URLs.CALL_INTEL_MAIN_PAGE,
          },
          {
            label: `${metricsFor} ${pageTypeTitle}`,
          },
        ].filter(({ label }) => !!label)}
        css={styles.page}
        subtitle={t('Showing results for {{time}}', { time: getPageSubtitleText() })}
        title={pageLabel}
      >
        <div
          css={[filtersStyles.mainWrapper, stickyTabsStyles]}
          ref={stickyTabsRef}
          style={{ margin: theme.spacing(-2, 0, 1), paddingTop: theme.spacing(2) }}
        >
          <CallIntelFilters
            activeTab='overview'
            hideFilters={['officeUsers']}
            isDemoAccount={isDemoAccount}
            isLoadingData={isLoadingChartsData}
            onlyChipFilters
          />

          {pageType !== 'service-quality' && isMultiLocation && (
            <div css={callIntelStyles.multiComparison}>
              <Button
                {...triggerProps}
                size='large'
                style={{ fontSize: theme.fontSize(14), textWrap: 'nowrap' }}
                variant='secondary'
              >
                <span className='icon'>
                  <Icon color='light' name='locations' />
                </span>
                {t('Explore Location Data')}
              </Button>
              <ExploreLocationsTray {...modalProps} onChange={setActiveMultiView} value={activeMultiView} />
            </div>
          )}
        </div>

        {filtersReady && (
          <>
            {activeMultiView === 'summary' ? (
              getSubView()?.overview
            ) : (
              <CallIntelLocationComparisonCharts
                betweenLocations={activeMultiView === 'betweenLocations'}
                drillDownFilters={drillDownFilters}
                internalPage
                key={JSON.stringify(filters)}
                onChangeLoadingState={setIsLoadingChartsData}
              />
            )}

            {pageConfig?.withHeading && (
              <Heading css={styles.tableHeader} level={3}>
                {isDemoAccount && <DemoChip />}
                {t('{{name}} Call Recordings', { name: metricsFor })}
              </Heading>
            )}

            <CallRecordsTable
              drillDownFilters={drillDownFilters}
              hideFilters={drillDownFilters ? ['locations', 'startDate', 'endDate', 'contactTypes'] : []}
              isDemoAccount={isDemoAccount}
              marginTop={16}
              {...callRecordsTableProps}
              trayNotificationMessage={trayNotificationMessage}
            />
          </>
        )}
      </Page>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
    min-height: auto;
    height: auto;
  `,

  chartsTabPanel: css`
    padding: ${theme.spacing(1, 0)};
  `,

  recordsTablePanel: css`
    flex-grow: 1;
    padding-top: ${theme.spacing(1)};
  `,

  tableHeader: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(0.5)};
    margin-top: ${theme.spacing(5)};

    > span {
      font-size: inherit;
    }
  `,
};
