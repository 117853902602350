import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import {
  ReactFlow,
  ReactFlowProvider,
  Panel,
  useNodesState,
  useEdgesState,
  useReactFlow,
  Controls,
  Background,
  BackgroundVariant,
  Edge,
  Node,
  useOnSelectionChange,
  EdgeProps,
  NodeProps,
  useNodesInitialized,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';
import { CustomProps, DataPayload, EdgeType, NodeType, NodeTypes, SharedNodeProps } from './data';
import { AnimatedSVGEdge, AnimatedSVGEdgeReverse, BasicEdge, PhoneTreeEdge } from './edges/custom';
import { BasicNode } from './nodes/basic-node';
import { BooleanNode } from './nodes/boolean-node';
import { HoursTypeNode } from './nodes/hours-type-node';
import {
  VoicemailNode,
  CallGroupNode,
  PlayNode,
  ForwardingDeviceNode,
  ForwardingNumberNode,
  PhoneTreeNode,
  OfficeHoursNode,
  CallQueueNode,
  TreeOptionNode,
  RepeatNode,
  FallbackNode,
  CallRouteNode,
} from './nodes/instruction-nodes';
import { PlaygroundNode } from './nodes/playground-node';
import { StartNode } from './nodes/start-node';
import { TerminateNode } from './nodes/terminate-node';
import { InteractionProvider } from './provider';
import { useCalculateLayout } from './util';
import { css } from '@emotion/react';

// It’s important that the nodeTypes are memoized or defined outside of the component.
// Otherwise React creates a new object on every render which leads to performance issues and bugs.
const nodeTypes: (
  commonProps: CustomProps
) => Record<
  (typeof NodeType)[keyof typeof NodeType] | 'playground',
  (props: NodeProps<Node<DataPayload, NodeTypes>>) => ReactNode
> = (commonProps) => ({
  playground: PlaygroundNode,
  [NodeType.Start]: StartNode,
  [NodeType.Terminate]: TerminateNode,
  [NodeType.VoicemailPrompt]: ({ ...props }) => <VoicemailNode {...props} {...commonProps} />,
  [NodeType.CallGroup]: ({ ...props }) => <CallGroupNode {...props} {...commonProps} />,
  [NodeType.CallRoute]: ({ ...props }) => <CallRouteNode {...props} {...commonProps} />,
  [NodeType.OfficeHours]: ({ ...props }) => <OfficeHoursNode {...props} {...commonProps} />,
  [NodeType.OpenPhoneHours]: ({ ...props }) => <HoursTypeNode {...props} {...commonProps} />,
  [NodeType.ClosedPhoneHours]: ({ ...props }) => <HoursTypeNode {...props} {...commonProps} />,
  [NodeType.BreakPhoneHours]: ({ ...props }) => <HoursTypeNode {...props} {...commonProps} />,
  [NodeType.PlayMessage]: ({ ...props }) => <PlayNode {...props} {...commonProps} />,
  [NodeType.Boolean]: BooleanNode,
  [NodeType.PhoneTree]: ({ ...props }) => <PhoneTreeNode {...props} {...commonProps} />,
  [NodeType.TreeOption]: ({ ...props }) => <TreeOptionNode {...props} {...commonProps} />,
  [NodeType.ForwardDevice]: ({ ...props }) => <ForwardingDeviceNode {...props} {...commonProps} />,
  [NodeType.ForwardNumber]: ({ ...props }) => <ForwardingNumberNode {...props} {...commonProps} />,
  [NodeType.VoicemailBox]: ({ ...props }) => <VoicemailNode {...props} {...commonProps} />,
  [NodeType.CallQueue]: ({ ...props }) => <CallQueueNode {...props} {...commonProps} />,
  [NodeType.Repeat]: ({ ...props }) => <RepeatNode {...props} {...commonProps} />,
  [NodeType.Fallback]: ({ ...props }) => <FallbackNode {...props} {...commonProps} />,
  [NodeType.Basic]: ({ ...props }) => <BasicNode {...props} {...commonProps} />,
});

const edgeTypes: Record<(typeof EdgeType)[keyof typeof EdgeType], (props: EdgeProps) => ReactNode> = {
  [EdgeType.ToTarget]: AnimatedSVGEdge,
  [EdgeType.FromSource]: AnimatedSVGEdgeReverse,
  [EdgeType.TreeOption]: PhoneTreeEdge,
  [EdgeType.Basic]: BasicEdge,
};

export const FlowContent = ({
  nodes: initialNodes,
  edges: initialEdges,
  showDebugButtons = false,
  onClickLink,
  AudioPlayerComponent,
}: {
  nodes: Node<DataPayload>[];
  edges: Edge[];
  showDebugButtons?: boolean;
  onClickLink?: SharedNodeProps['onClickLink'];
  AudioPlayerComponent?: SharedNodeProps['AudioPlayerComponent'];
}) => {
  const { setCenter } = useReactFlow();
  const nodesInitialized = useNodesInitialized({
    includeHiddenNodes: false,
  });
  const [nodes, _setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [mode, setMode] = useState<'edit' | 'view'>('view');
  const calculate = useCalculateLayout();

  const onChange = useCallback(({ nodes: selectedNodes }: { nodes: Node[]; edges: Edge[] }) => {
    setEdges((edges: (Edge & { originalType?: (typeof EdgeType)[keyof typeof EdgeType] })[]) => {
      return edges.map((edge) => {
        if (selectedNodes.length > 0) {
          if (edge.source === selectedNodes[0].id) {
            return { ...edge, type: EdgeType.ToTarget };
          } else if (edge.target === selectedNodes[0].id) {
            return { ...edge, type: EdgeType.FromSource };
          }
        }
        return { ...edge, type: edge.originalType ?? EdgeType.Basic };
      });
    });
  }, []);

  const commonProps = useMemo(
    () => ({
      onClickLink,
      AudioPlayerComponent,
    }),
    [onClickLink, AudioPlayerComponent]
  );

  useOnSelectionChange({
    onChange,
  });

  const onLayout = useCallback((direction: 'TB' | 'LR') => {
    const { nodes: calculatedNodes } = calculate({ direction });
    window.requestAnimationFrame(() => {
      const startNode = calculatedNodes[0];

      if (direction === 'TB') {
        // Center the view when viewing the flow vertically
        setCenter(
          startNode.position.x,
          startNode.position.y + 200, // Center the view a little below the start node
          { zoom: 1, duration: 300 }
        );
      } else {
        // Center the view when viewing the flow horizontally
        setCenter(
          startNode.position.x + 200, // Center the view a little to the right of the start node
          startNode.position.y,
          { zoom: 1, duration: 300 }
        );
      }
    });
  }, []);

  const memoizedNodeTypes = useMemo(() => nodeTypes(commonProps), [commonProps]);

  useEffect(() => {
    if (nodesInitialized && nodes.length > 1) {
      onLayout('TB');
    }
  }, [nodesInitialized]);

  return (
    <InteractionProvider mode={mode}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        edgeTypes={edgeTypes}
        nodeTypes={memoizedNodeTypes}
        css={css`
          .react-flow__edges {
            /**
              Need to add width: 1005; to this class to make the edges show. This is the parent of the edges
              SVGs edges and without it, the parent div has a calculated width of 0px which causes the edges
              to not show.
             */
            width: 100%;
          }
        `}
      >
        <Controls />
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        <Panel position='top-right'>
          {showDebugButtons && (
            <div style={{ display: 'flex', gap: theme.spacing(1) }}>
              <Button variant='secondary' css={{ background: theme.colors.white }} onClick={() => onLayout('TB')}>
                Vertical
              </Button>
              <Button variant='secondary' css={{ background: theme.colors.white }} onClick={() => onLayout('LR')}>
                Horizontal
              </Button>
              <Button
                variant='secondary'
                css={{ background: theme.colors.white }}
                onClick={() => setMode((mode) => (mode === 'edit' ? 'view' : 'edit'))}
              >
                {`Switch to ${mode === 'edit' ? 'view' : 'edit'}`}
              </Button>
            </div>
          )}
        </Panel>
      </ReactFlow>
    </InteractionProvider>
  );
};

export function Flow() {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlowProvider>
        <FlowContent
          nodes={[
            {
              id: 'playground',
              type: 'playground',
              position: { x: -100, y: -100 },
              data: { id: 'playground', label: 'Playground' },
            },
          ]}
          edges={[]}
        />
      </ReactFlowProvider>
    </div>
  );
}

export function CallRouteFlow({
  nodes,
  edges,
  showDebugButtons = false,
  onClickLink,
  AudioPlayerComponent,
}: {
  nodes: {
    id: string;
    type?: string;
    isPhoneTreeDescendent?: boolean;
    callObject: {
      primitiveId: string;
      primitiveName: string;
      instructionId: string;
      instructionSetId: string;
    };
  }[];
  edges: { sourceId: string; targetId: string; label?: string; type?: string }[];
  showDebugButtons?: boolean;
  onClickLink?: SharedNodeProps['onClickLink'];
  AudioPlayerComponent: SharedNodeProps['AudioPlayerComponent'];
}) {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <ReactFlowProvider>
        <FlowContent
          nodes={
            !!nodes.length
              ? nodes.map((node) => ({
                  id: node.id,
                  type: node.type ?? 'basic',
                  position: { x: -100, y: -100 }, // Set an arbitrary position (it will be overwritten later)
                  data: { ...node, id: node.id, label: node.callObject.primitiveName, callObject: node.callObject },
                }))
              : [
                  // Default to showing only a start node if no nodes are provided
                  {
                    id: 'start',
                    type: NodeType.Start,
                    position: { x: -100, y: -100 },
                    data: { id: 'start', label: 'Incoming Call' },
                  },
                ]
          }
          edges={edges.map((edge) => ({
            id: edge.sourceId + ':' + edge.targetId,
            source: edge.sourceId,
            target: edge.targetId,
            type: edge.type ?? 'basic',
            data: {
              label: edge.label,
            },
          }))}
          showDebugButtons={showDebugButtons}
          onClickLink={onClickLink}
          AudioPlayerComponent={AudioPlayerComponent}
        />
      </ReactFlowProvider>
    </div>
  );
}
