import { usePopoverDialog, UsePopoverDialogResponse, UsePopoverDialogProps } from '@frontend/design-system';
import { PmsSelectorPopoverDialog } from './pms-selector.component';

interface UsePmsSelectorProps {
  popoverDialogConfig?: UsePopoverDialogProps;
}

interface UsePmsSelectorResults {
  PmsSelectorPopoverDialog: typeof PmsSelectorPopoverDialog;
  pmsSelectorPopoverDialogControls: UsePopoverDialogResponse<HTMLButtonElement, false, HTMLDialogElement>;
}

export const usePmsSelector = ({ popoverDialogConfig = {} }: UsePmsSelectorProps = {}): UsePmsSelectorResults => {
  const pmsSelectorPopoverDialogControls = usePopoverDialog(popoverDialogConfig);

  return {
    PmsSelectorPopoverDialog,
    pmsSelectorPopoverDialogControls,
  };
};
