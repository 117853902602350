import { SMSNumberV1 } from '@frontend/api-sms-number';

export const dedupePhoneValidity = (
  phoneValidity: SMSNumberV1.Types.PhoneValidity[],
  strategy: 'combine-labels' | 'remove-duplicates'
): SMSNumberV1.Types.PhoneValidity[] => {
  return phoneValidity.reduce<SMSNumberV1.Types.PhoneValidity[]>((acc, curr) => {
    const existingNumberEntry = acc.find((phone) => phone.number === curr.number);

    if (existingNumberEntry) {
      switch (strategy) {
        case 'combine-labels':
          return acc.map((phone) => {
            if (phone.number === curr.number) {
              return {
                ...phone,
                label: (phone.label ?? '') + (curr.label ? `/${curr.label}` : ''),
              };
            } else {
              return phone;
            }
          });
        case 'remove-duplicates':
          return acc;
      }
    }

    acc.push(curr);
    return acc;
  }, []);
};
