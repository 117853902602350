import { localStorageHelper } from '@frontend/auth-helpers';
import { dispatchTopBarTraySelectionEvent } from '@frontend/shared';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

export const useScheduleRequestNotification = () => {
  const { create, remove } = useNotification<'schedule-request-new'>();

  useCommonActionSubscriptions('schedule-request-new');
  useNotificationActionSubscription('schedule-request-new', 'view', ({ notification }) => {
    localStorageHelper.create('weave.schedule-pulse.selected-tab', 'appointment-requests');
    dispatchTopBarTraySelectionEvent('schedulePulse');
    remove(notification.id);
  });

  return {
    create,
    remove,
  };
};
