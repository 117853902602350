import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { CreditCardBrand, InvoiceFilterType } from '@frontend/api-invoices';
import { PaymentQueries } from '@frontend/api-payments';
import { RefundFilterType } from '@frontend/api-refunds';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { useQueryPaginatedInvoices, useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import {
  InvoiceList,
  PaymentsPage,
  InvoiceSummary,
  DateFilters,
  NewInvoiceAction,
  PrintInvoiceList,
} from '../../../components';
import { FilterHeader } from '../../../components/PaymentRequests';
import { QuickFilterOptions } from '../../../components/PaymentRequests/DateFilters/quick-filters';
import { PrintRefundList, RefundList, RefundSummaryCard } from '../../../components/Refunds';
import {
  usePaymentRequestQuickFilter,
  usePaymentRole,
  usePrintDialogue,
  useRefundSearch,
  useRefundShallowStore,
} from '../../../hooks';

const styles = {
  marginBottom: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

const emptyOnChange = () => {};

type StatusView = 'invoices' | 'refunds';

export const PaymentRequests = () => {
  const { t } = useTranslation('payments');
  const { locationId } = useMerchant();
  const { hasAnalyticsRole } = usePaymentRole(locationId);

  const { summary, loading } = useQueryPaginatedInvoices();

  const search = useSearch();

  const { showPrint, setShowPrint, handlePrint } = usePrintDialogue();

  const [selectedStatus, setSelectedStatus] = useState<QuickFilterOptions>('allInvoices');

  const { invalidateUnrecordedCount } = PaymentQueries.usePaymentsInvalidation();

  const { filter: invoiceFilter, setFilter: setInvoicesFilter } = useInvoiceShallowStore('filter', 'setFilter');

  const { filter: refundFilter, setFilter: setRefundFilter } = useRefundShallowStore('filter', 'setFilter');

  const { handleQuickFilter } = usePaymentRequestQuickFilter({
    filter: invoiceFilter,
    onChange: setInvoicesFilter,
  });

  const onChangeStatus = useCallback(
    (selected: QuickFilterOptions) => {
      setSelectedStatus(selected);

      if (selected !== 'refunded') {
        handleQuickFilter(selected);
      }
    },
    [invoiceFilter]
  );

  const onChangeDateRange = useCallback(
    (dateRange: InvoiceFilterType['dateRange']) => {
      setInvoicesFilter({ ...invoiceFilter, dateRange });
      setRefundFilter({ ...refundFilter, dateRefunded: { lte: dateRange?.end, gte: dateRange?.start } });
    },
    [invoiceFilter, refundFilter]
  );

  const statusView: StatusView = useMemo(() => {
    if (['allInvoices', 'paid', 'unpaid'].includes(selectedStatus)) {
      return 'invoices';
    }

    return 'refunds';
  }, [selectedStatus]);

  const printDialog = useCallback(
    ({ onClose }: { onClose: () => void }) => {
      return (
        <>
          {statusView === 'invoices' && (
            <PrintInvoiceList
              headerContent={
                <>
                  <DateFilters
                    dateRange={invoiceFilter.dateRange}
                    selectedStatus={selectedStatus}
                    onChangeStatus={emptyOnChange}
                  />
                  <InvoiceSummary summary={summary} loading={false} style={styles.marginBottom} />
                </>
              }
              onClose={onClose}
            />
          )}
          {statusView === 'refunds' && (
            <>
              <PrintRefundList
                onClose={onClose}
                headerContent={
                  <>
                    <DateFilters
                      dateRange={invoiceFilter.dateRange}
                      selectedStatus={selectedStatus}
                      onChangeStatus={emptyOnChange}
                    />
                    {hasAnalyticsRole && <RefundSummaryCardPerformanceWrapper />}
                  </>
                }
              />
            </>
          )}
        </>
      );
    },
    [statusView, invoiceFilter, selectedStatus, summary]
  );

  /**
   * When refund store changes are made, map them, as best we can, to the invoice store, to
   * preserve continuity
   */
  const onChangeRefundFilter = useCallback(
    (newRefundFilter: RefundFilterType) => {
      const cardType = newRefundFilter.cardType?.map((c) =>
        c === '' ? CreditCardBrand.CardBrandUnknown : (c.toUpperCase() as CreditCardBrand)
      );

      setInvoicesFilter({
        ...invoiceFilter,
        person: newRefundFilter.personName,
        paymentType: newRefundFilter.origin,
        paymentMethod: newRefundFilter.paymentType,
        cardType,
      });
    },
    [invoiceFilter]
  );

  const onChangeInvoiceFilter = useCallback(
    (newInvoiceFilter: InvoiceFilterType) => {
      const cardType = newInvoiceFilter.cardType?.map((c) =>
        c === CreditCardBrand.CardBrandUnknown ? '' : (c.toLowerCase() as CreditCardBrand)
      );
      setInvoicesFilter(newInvoiceFilter);

      setRefundFilter({
        ...refundFilter,
        personName: newInvoiceFilter.person,
        origin: newInvoiceFilter.paymentType,
        paymentType: newInvoiceFilter.paymentMethod,
        cardType,
      });
    },
    [refundFilter]
  );

  useEffect(() => {
    // Revalidate unrecorded on mount
    invalidateUnrecordedCount();

    // sync refund date range
    setRefundFilter({
      ...refundFilter,
      dateRefunded: { lte: invoiceFilter?.dateRange?.end, gte: invoiceFilter?.dateRange?.start },
    });

    const defaultView = String(search.view || '') as StatusView;

    if (defaultView === 'refunds') {
      setSelectedStatus('refunded');
    }
  }, []);

  return (
    <PaymentsPage
      title={t('invoiceHeaderTitle')}
      maxWidth={1420}
      variant='payments-app'
      action={<NewInvoiceAction trackingId='pay-portal-invoices-button-new' />}
      showPrint={showPrint}
      setShowPrint={setShowPrint}
      printDialogue={printDialog}
    >
      <FilterHeader dateType={invoiceFilter?.dateType} />
      <DateFilters
        dateRange={invoiceFilter?.dateRange}
        onChangeDateRange={onChangeDateRange}
        selectedStatus={selectedStatus}
        onChangeStatus={onChangeStatus}
      />
      {statusView === 'invoices' && (
        <>
          {hasAnalyticsRole && <InvoiceSummary summary={summary} loading={loading} style={styles.marginBottom} />}
          <InvoiceList
            showPrint={showPrint}
            handlePrint={handlePrint}
            variant='payments-app'
            onChangeFilter={onChangeInvoiceFilter}
          />
        </>
      )}
      {statusView === 'refunds' && (
        <>
          {hasAnalyticsRole && <RefundSummaryCardPerformanceWrapper />}
          <RefundList handlePrint={handlePrint} onChangeFilter={onChangeRefundFilter} />
        </>
      )}
    </PaymentsPage>
  );
};

const RefundSummaryCardPerformanceWrapper = () => {
  const { summary } = useRefundSearch();

  return <RefundSummaryCard loading={false} summary={summary} css={{ marginBottom: 24 }} />;
};
