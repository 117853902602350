import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const stickyShadow = (isHovering?: boolean) => css`
  position: relative;
  transform-style: preserve-3d;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: ${isHovering ? theme.spacing(3.5) : theme.spacing(1)}px;
    width: calc(100% - ${isHovering ? 40 : 20}px);
    background: linear-gradient(178deg, rgba(0, 0, 0, 0.4) 46.25%, rgba(0, 0, 0, 0) 100.5%);
    transform: skew(-15deg, 0deg) translateZ(-1px);
    transform-origin: 0 0;
    transition: width 0.5s ease, right 0.5s ease;
    z-index: ${theme.zIndex.low};
  }

  &::before {
    height: 65%;
    opacity: 0.4;
    filter: blur(8px);
  }

  &::after {
    height: 70%;
    opacity: 0.3;
    filter: blur(4px);
  }
`;

export const customFontStyle = css`
  font-family: Martian Mono;
  line-height: 1.4;
  font-size: ${theme.fontSize(14)};
`;
