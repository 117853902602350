import { memo, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { AreaChartData, Chart, sequentialChartColors } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  DropdownFilterChipField,
  SwitchChipGroup,
  useFormField,
  useSwitchChipGroup,
  styles as dsStyles,
  Text,
} from '@frontend/design-system';
import { trackingIds } from '../../../tracking-ids';
import { formatHourlyInsights, formatters, sumArrayItems } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import {
  DeviceExtensionsCallType,
  useFetchDeviceExtensionsReport,
  useIsPhoneAnalyticsDemoAccount,
  usePhoneAnalyticsShallowStore,
} from '../hooks';
import { ChartProps } from './shared-types';

type DeviceExtensionsFilterProps = {
  callType: DeviceExtensionsCallType;
  extensions?: { list: string[]; renamedUUIDs: Record<string, string> };
  selectedExtensions: string[];
  setCallType: (value: DeviceExtensionsCallType) => void;
  setSelectedExtensions: (value: string[]) => void;
  disabled?: boolean;
};

type ProcessedData = {
  chartData: AreaChartData;
  totals: Record<string, number>;
};

const PhoneExtensionChartFilters = memo(
  ({
    callType,
    extensions,
    disabled,
    selectedExtensions,
    setCallType,
    setSelectedExtensions,
  }: DeviceExtensionsFilterProps) => {
    const { t } = useTranslation('analytics');

    const switchChipProps = useSwitchChipGroup({
      defaultValue: [callType],
      onChange: (val) => {
        setCallType(val[0] as DeviceExtensionsCallType);
      },
    });

    const extensionsFieldProps = useFormField(
      {
        type: 'checklist',
        value: selectedExtensions,
      },
      [selectedExtensions]
    );

    return (
      <div className='no-pdf' css={styles.filtersWrapper}>
        <SwitchChipGroup {...switchChipProps} singleSelect>
          <SwitchChipGroup.Option
            disabled={disabled}
            trackingId={trackingIds.phoneAnalytics.deviceExtensionsCallsAnswered}
            value='answered'
          >
            {t('Calls Answered')}
          </SwitchChipGroup.Option>
          <SwitchChipGroup.Option
            disabled={disabled}
            trackingId={trackingIds.phoneAnalytics.deviceExtensionsCallsPlaced}
            value='placed'
          >
            {t('Calls Placed')}
          </SwitchChipGroup.Option>
        </SwitchChipGroup>

        <DropdownFilterChipField
          {...extensionsFieldProps}
          icon='phone'
          label={t('Extensions')}
          name='extension-filter'
          onApply={({ value, close }) => {
            setSelectedExtensions(value);
            close();
          }}
          showClearAll={false}
          trackingIdBase={trackingIds.phoneAnalytics.deviceExtensionsFilter}
        >
          {extensions?.list.map((extension) => (
            <DropdownFilterChipField.Option key={extension} value={extension}>
              <Text css={[dsStyles.truncate, styles.filterOption]}>
                {extensions.renamedUUIDs[extension] || extension}
              </Text>
            </DropdownFilterChipField.Option>
          ))}
        </DropdownFilterChipField>
      </div>
    );
  }
);

export const PhoneExtensionChart = memo(({ exportPdfProps, onFetchStateChange }: ChartProps) => {
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const { filterHintText } = usePhoneAnalyticsShallowStore('filterHintText');
  const [extensions, setExtensions] = useState<string[]>([]);
  const [callType, setCallType] = useState<DeviceExtensionsCallType>('answered');
  const { data, isHourlyData, isLoading } = useFetchDeviceExtensionsReport();

  const processedData: ProcessedData = useMemo(() => {
    if (!extensions.length || !data?.records) {
      return {
        chartData: {
          groups: [],
        },
        totals: {},
      };
    }

    let groups: AreaChartData['groups'] = [];

    if (isHourlyData) {
      // isHourlyInsights will be true only when there is just one date record
      // Hence we can directly pick the first record set from the extensionsRecords
      const record = Object.values(data.records)[0] || {};
      groups = Object.entries(record).reduce<AreaChartData['groups']>((acc, [extension, values]) => {
        if (!extensions.includes(extension)) {
          return acc;
        }
        return formatHourlyInsights(extension, values[callType], { groups: acc });
      }, []);
    } else {
      groups = Object.entries(data.records || {}).map(([date, data]) => {
        return {
          name: formatters.date.format(date),
          values: extensions.reduce<Record<string, number>>((acc, extension) => {
            acc[extension] = sumArrayItems(data[extension]?.[callType]);
            return acc;
          }, {}),
        };
      });
    }

    return {
      chartData: {
        groups,
      },
      totals: groups.reduce<Record<string, number>>((acc, group) => {
        Object.entries(group.values).forEach(([extension, value]) => {
          acc[extension] = (acc[extension] || 0) + value;
        });
        return acc;
      }, {}),
    };
  }, [callType, data?.records, extensions, isHourlyData]);

  const chartColors = useMemo(() => {
    return data?.details?.list?.reduce<Record<string, string>>((acc, extension, index) => {
      acc[extension] = sequentialChartColors[index];
      return acc;
    }, {});
  }, [data?.details]);

  useEffect(() => {
    setExtensions(data?.details?.list || []);
  }, [data?.details?.list]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart
      colors={chartColors}
      emptyStateConfig={{
        type: 'phone_extensions',
        label: t('Sorry! Phone extension report is not available'),
      }}
      isLoading={isLoading}
      labels={{ ...(data?.details?.renamedUUIDs || {}), unknown: t('Unknown') }}
    >
      <Chart.Header
        allowExportToPdf
        bottomElement={
          <PhoneExtensionChartFilters
            callType={callType}
            extensions={data?.details}
            selectedExtensions={extensions}
            setCallType={setCallType}
            setSelectedExtensions={setExtensions}
          />
        }
        infoTip={
          <InfoTipPopover>
            {t(
              'It shows which device extension answered or placed the call. It only includes first interaction of the call.'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        pdfDetails={[
          { label: t('Type'), value: callType === 'answered' ? t('Answered Calls') : t('Placed Calls') },
          ...exportPdfProps.pdfDetails,
        ]}
        subtitle={filterHintText}
        title={t('Device Extension')}
      />
      <Chart.AreaChart
        appearance={{
          margin: { left: 20 },
          showXAxis: true,
          showYAxis: true,
          tooltipLabelsTransform: 'capitalize',
          xAxisTickWidth: 120,
        }}
        data={processedData.chartData}
      />
      <Chart.Legends formatValue={formatters.value.format} labelsTransform='capitalize' values={processedData.totals} />
    </Chart>
  );
});

PhoneExtensionChartFilters.displayName = 'PhoneExtensionChartFilters';
PhoneExtensionChart.displayName = 'PhoneExtensionChart';

const styles = {
  filtersWrapper: css`
    margin: ${theme.spacing(2, 0)};
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
    justify-content: space-between;
  `,

  filterOption: css`
    max-width: 200px;
    text-transform: capitalize;
  `,
};
