import { useQuery, useQueries, UseQueryOptions } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaTenCoordinatorV1Service } from '../service';
import { GetLocationRegistrationStatusIO } from '../types';

export const getGetLocationRegistrationStatusQueryKey = (req: GetLocationRegistrationStatusIO['input']) =>
  ['TenCoordinatorV1', 'GetLocationRegistrationStatus', req] as const;
type QueryKey = ReturnType<typeof getGetLocationRegistrationStatusQueryKey>;

export const getUnregisteredLocationCountQueryKey = (req: string[]) =>
  ['TenCoordinatorV1', 'GetLocationRegistrationStatus', 'UnregisteredCount', ...req] as const;
type UnregisteredLocationCountQueryKey = ReturnType<typeof getUnregisteredLocationCountQueryKey>;

/**
 * A hook that returns a query for the `GetLocationRegistrationStatus` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetLocationRegistrationStatusQuery = <E = unknown, D = GetLocationRegistrationStatusIO['output']>(
  req: GetLocationRegistrationStatusIO['input'],
  options?: LimitedSchemaQueryOptions<GetLocationRegistrationStatusIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<GetLocationRegistrationStatusIO['output'], E, D, QueryKey>({
    queryKey: getGetLocationRegistrationStatusQueryKey(req),
    queryFn: () => SchemaTenCoordinatorV1Service.GetLocationRegistrationStatus(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `GetLocationRegistrationStatus` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetLocationRegistrationStatusQueries = <E = unknown, D = GetLocationRegistrationStatusIO['output']>(
  argsArr: {
    req: GetLocationRegistrationStatusIO['input'];
    options?: LimitedSchemaQueryOptions<GetLocationRegistrationStatusIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getGetLocationRegistrationStatusQueryKey(req),
      queryFn: () => SchemaTenCoordinatorV1Service.GetLocationRegistrationStatus(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useGetLocationRegistrationStatusQuery<E, D>>[];

/**
 * A hook that returns the count of unregistered locations in a multilocation.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 */
export const useGetUnregisteredLocationCountQuery = <E = unknown, D = number>(
  req: string[],
  options?: UseQueryOptions<number, E, D, UnregisteredLocationCountQueryKey>
) =>
  useQuery<number, E, D, UnregisteredLocationCountQueryKey>({
    queryKey: getUnregisteredLocationCountQueryKey(req),
    queryFn: async () => {
      const locationData = await Promise.all(
        req.map((locationId) =>
          SchemaTenCoordinatorV1Service.GetLocationRegistrationStatus(
            {
              locationId,
            },
            {
              headers: {
                'Location-Id': locationId,
              },
            }
          )
        )
      );

      return locationData.filter((result) => result.registered === false).length;
    },
    ...options,
  });
