import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css({
  borderWidth: `1px 0 1px 1px`,
  borderColor: theme.colors.neutral10,
  borderStyle: 'solid',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

export const chatContentStyle = css({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
});

export const paddedStyle = css({
  padding: theme.spacing(4),
  width: '434px',
  borderLeft: `1px solid ${theme.colors.neutral10}`,
});

export const weaveUserContainerStyle = css({ display: 'flex', justifyContent: 'flex-end' });

export const panelStyle = css({
  display: 'flex',
  flexDirection: 'column',
  borderColor: theme.colors.neutral10,

  '> section': {
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    minWidth: 'min-content !important',
    overflow: 'hidden',
    borderColor: theme.colors.neutral10,
    height: '100%',
  },
});
export const chatsContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  flex: 1,
});
