import { useState } from 'react';
import { css } from '@emotion/react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { ContactDirectoriesQuery } from '@frontend/api-contact-directories';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber, removeNonDigits } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import {
  EmptyStateConfig,
  PreviewIcon,
  Table,
  TableBulkActions,
  TableInstance,
  TableRowActions,
  Tray,
  useModalControl,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { ViewContactDetailsModal } from '../modals';

type Props = {
  emptyStateConfig: EmptyStateConfig<Contact>;
  bulkActions: TableBulkActions<Contact>[];
  rowActions: TableRowActions<Contact>[];
  fullHeightConfig: number;
  rowSelectionConfig?: TableInstance<Contact>['rowSelectionConfig'];
  tableActions?: TableInstance<Contact>['tableActions'];
  data?: TableInstance<Contact>['data'];
  contactDirectoryId?: string;
  contactIds?: string[];
  onRemove?: (data: Contact) => Promise<void> | undefined;
};
export const ContactsTable = ({
  emptyStateConfig,
  bulkActions,
  rowActions,
  fullHeightConfig,
  rowSelectionConfig,
  tableActions,
  contactDirectoryId,
  contactIds,
  onRemove,
  data,
}: Props) => {
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId;
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });

  const [selectedViewContact, setSelectedViewContact] = useState<Contact>();
  const { modalProps: viewContactyModalProps, triggerProps: viewContactTriggerProps } = useModalControl();

  const { useGetContacts } = ContactDirectoriesQuery.useContactDirectoryQueries({ tenantId });
  const { data: { contacts } = { contactLists: [] }, isLoading } = useGetContacts();
  const [filteredGlobalData, setFilteredGlobalData] = useState<Contact[] | null>(null);
  const tableData = data ?? contacts;

  return (
    <>
      <Table
        data={filteredGlobalData ?? tableData ?? []}
        fullHeight
        fullHeightConfig={{
          offset: fullHeightConfig,
        }}
        hasResponsiveColWidths
        isLoading={isLoading}
        emptyStateConfig={emptyStateConfig}
        colConfig={[
          {
            Header: t('Name'),
            id: 'contacts-name',
            accessor: (cell) => cell.name,
          },
          {
            Header: t('Phone Number'),
            id: 'phone-number',
            accessor: (cell) => cell.phoneNumbers?.[0].number,
            cellRenderer: (number) => formatPhoneNumber(number),
          },
        ]}
        isPaginated
        clientPaginationConfig={{
          defaultRowsPerPage: 25,
        }}
        hasGlobalSearch
        isSelectable
        globalSearchConfig={{
          label: t('Search Name or Phone Number'),
          searchHandler: (term) => {
            const filteredContacts = contacts?.filter(
              (data) =>
                data.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                removeNonDigits(data.phoneNumbers?.[0].number).includes(term)
            );
            setFilteredGlobalData(filteredContacts ?? null);
          },
        }}
        uniqueRowId={(data) => data.id}
        bulkActions={bulkActions}
        tableActions={tableActions}
        rowActions={{
          onRowClick: (contact) => {
            setSelectedViewContact(contact);
            viewContactTriggerProps.onClick();
          },
          actions: [
            {
              label: t('View Contact'),
              Icon: PreviewIcon,
              onClick: (contact) => {
                setSelectedViewContact(contact);
                viewContactTriggerProps.onClick();
              },
            },
            ...(rowActions[0]?.actions ?? []),
          ],
        }}
        rowSelectionConfig={rowSelectionConfig}
        wrapperStyle={css`
          margin-top: ${theme.spacing(2)};
          .table-toolbar {
            [class*='GlobalFilterComponent'] {
              min-width: ${theme.spacing(35)};
            }
          }
          & > div:last-child {
            padding: ${theme.spacing(3, 0, 1, 0)};
          }
        `}
      />
      <Tray width='smallXL' {...viewContactyModalProps} disableCloseOnEscape>
        <ViewContactDetailsModal
          contactDirectoryId={contactDirectoryId}
          contact={selectedViewContact}
          contactIds={contactIds}
          onRemove={onRemove}
          {...viewContactyModalProps}
        />
      </Tray>
    </>
  );
};
