import { ElementType, useEffect, useRef } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { useInView } from 'motion/react';
import { PersonsV3 } from '@frontend/api-person';
import { PolymorphicComponentPropWithoutRef } from '@frontend/design-system';
import { PersonSelectionListItem } from './person-selection-list-item';

type PersonSelectionListProps = {
  persons: Person[];
  onSelect: (person: Person) => void;
  multiSelect?: boolean;
  selectedPersons?: Person[];
  disableSelection?: boolean;
  onEndReached?: () => void;
};

type PolymorphicProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C, PersonSelectionListProps>;

export const PersonSelectionList = <C extends ElementType = 'div'>({
  as,
  persons,
  onSelect,
  multiSelect = false,
  selectedPersons,
  disableSelection,
  onEndReached,
  ...rest
}: PolymorphicProps<C>) => {
  const Component = as || 'div';
  const visibilityRef = useRef<HTMLButtonElement>(null);

  const endOfListIsVisible = useInView(visibilityRef);

  useEffect(() => {
    if (endOfListIsVisible) {
      onEndReached?.();
    }
  }, [endOfListIsVisible]);

  return (
    <Component
      css={{
        height: '100%',
        overflowY: 'auto',
      }}
      {...rest}
    >
      {persons.map((person, index) => (
        <PersonSelectionListItem
          ref={index === persons.length - 1 ? visibilityRef : undefined}
          key={person.personId}
          personId={person.personId}
          personName={PersonsV3.PersonHelpers.getFullName(person)}
          personAge={PersonsV3.PersonHelpers.getAge(person)?.toString() || 'Unknown'}
          personStatus={person.status || 'Unknown'}
          onSelect={() => onSelect(person)}
          multiSelect={multiSelect}
          isSelected={selectedPersons?.some(({ personId }) => personId === person.personId)}
          disabled={disableSelection && !selectedPersons?.some(({ personId }) => personId === person.personId)}
        />
      ))}
    </Component>
  );
};
