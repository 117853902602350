import { useMemo } from 'react';
import { Button, Section } from '@react-email/components';
import { theme } from '@frontend/theme';
import { useComposerSection } from '../../hooks';
import { ButtonSectionProps, ComposerSectionOutputProps, UsableComposerSectionProps } from '../../types';
import { getPaddingStyles } from '../../utils/get-padding-styles';
import { FacebookNoBorderSvg } from '../assets/facebook-no-border-svg';
import { GoogleNoBorderSvg } from '../assets/google-no-border-svg';
import { WeaveLogoSvg } from '../assets/weave-logo-svg';
import { cornersStyle, frameColorStyle } from './styles';

export const ReviewButtonOutput = ({ renderMode }: ComposerSectionOutputProps) => {
  const { props } = useComposerSection<ButtonSectionProps>();
  const { reviewLinkType, link } = props;

  const buttonLink = useMemo(() => {
    const normalizedLink = link?.startsWith('http') ? link : `https://${link}`;
    if (renderMode === 'preview') {
      return normalizedLink;
    }
    switch (reviewLinkType) {
      case 'google':
        return '{{GOOGLE_REVIEW_LINK}}';
      case 'facebook':
        return '{{FACEBOOK_REVIEW_LINK}}';
      case 'private':
        return '{{PRIVATE_REVIEW_LINK}}';
      default:
        return normalizedLink;
    }
  }, [reviewLinkType, renderMode, link]);

  return (
    <Section style={createFrameStyle(props)}>
      <Button style={createButtonStyle(props)} href={buttonLink}>
        <div style={buttonContentStyle}>
          {reviewLinkType === 'google' ? (
            <GoogleNoBorderSvg fillColor={props.textColor} />
          ) : reviewLinkType === 'facebook' ? (
            <FacebookNoBorderSvg fillColor={props.backgroundColor} innerColor={props.textColor} />
          ) : reviewLinkType === 'private' ? (
            <WeaveLogoSvg fillColor={props.textColor} />
          ) : null}
          {props.text ?? ''}
        </div>
      </Button>
    </Section>
  );
};

const createFrameStyle = (props: UsableComposerSectionProps<ButtonSectionProps>) => ({
  ...getPaddingStyles(props),
  ...frameColorStyle(props),
  textAlign: props.frameAlignment,
});

const createButtonStyle = (props: UsableComposerSectionProps<ButtonSectionProps>) => ({
  ...cornersStyle(props),
  background: props.backgroundColor,
  color: props.textColor,
  border: '0',
  cursor: 'pointer',
});

const buttonContentStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(0.75),
  height: theme.spacing(4.5),
  padding: theme.spacing(0.25, 2),
  fontSize: theme.spacing(1.75),
  fontFamily: "'DM Sans', sans-serif",
  fontWeight: theme.font.weight.bold,
  lineHeight: '1.5',
  color: 'inherit',
  '&:hover, &:focus': {
    boxShadow: theme.shadows.floating,
  },
};
