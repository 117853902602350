import { css } from '@emotion/react';
import { CreditCardBrand, PaymentType } from '@frontend/api-invoices';
import { RefundEdge, RefundModel } from '@frontend/api-refunds';
import { Chips } from '@frontend/chips';
import { formatDate } from '@frontend/date';
import { CardBrand } from '@frontend/payments-card-brand';
import { TableColumnConfig } from '@frontend/design-system';
import { paymentTypeMap } from '../../../hooks';
import { GetLocationName, formatCentsToCurrency, getPaymentMethod, prettify } from '../../../utils';
import { RefundStatusChip } from '../RefundStatusChip';

export const generateColumns = (
  getLocationName: GetLocationName,
  showLocationName: boolean,
  forPrint = false
): TableColumnConfig<RefundModel>[] => [
  {
    id: 'location',
    Header: 'Location Name',
    accessor: (refund: RefundModel) => refund.locationId,
    cellRenderer: (locationId: string) =>
      forPrint ? (
        getLocationName(locationId ?? '')
      ) : (
        <Chips.LocationChip
          css={css`
            max-width: none;
          `}
        >
          {getLocationName(locationId ?? '')}
        </Chips.LocationChip>
      ),
    disableSortBy: true,
    omit: !showLocationName,
  },
  {
    id: 'person',
    Header: 'Person',
    accessor: (refund) => refund?.person?.name,
    minWidth: 150,
    maxWidth: 170,
    disableSortBy: true,
  },
  {
    id: 'patientId',
    Header: 'Patient Id',
    accessor: (refund) => refund?.person?.id,
    minWidth: 90,
    width: 90,
    disableSortBy: true,
  },
  {
    id: 'refundType',
    Header: 'Refund Type',
    accessor: (refund) => <RefundStatusChip refund={refund} />,
    minWidth: 64,
    maxWidth: 120,
    disableResizing: true,
    disableSortBy: true,
  },
  {
    id: 'refundAmount',
    Header: 'Refund Amount',
    accessor: (refund) => (refund?.refundAmount ? formatCentsToCurrency(refund.refundAmount) : ''),
    minWidth: 120,
    maxWidth: 150,
    disableResizing: true,
    disableSortBy: true,
  },
  {
    id: 'dateRefunded',
    Header: 'Refund Date',
    accessor: (refund) => (refund?.dateRefunded ? formatDate(refund.dateRefunded, 'MMM D, YYYY') : ''),
    disableResizing: true,
    minWidth: 120,
    maxWidth: 150,
    disableSortBy: true,
  },
  {
    id: 'paymentType',
    Header: 'Payment Type',
    accessor: (refund) => (refund?.payment ? paymentTypeMap[refund.payment.origin] : ''),
    minWidth: 64,
    maxWidth: 120,
    disableResizing: true,
    disableSortBy: true,
  },
  {
    id: 'paymentMethod',
    Header: 'Payment Method',
    accessor: (refund) => refund,
    cellRenderer: (refund: RefundModel) => {
      const paymentType = refund.payment?.paymentType;
      const cardBrand = refund.payment?.brand;
      const last4 = refund.payment?.last4;
      let paymentMethod = getPaymentMethod(paymentType, cardBrand?.toUpperCase() as CreditCardBrand);
      paymentMethod = paymentMethod?.toUpperCase() as CreditCardBrand | PaymentType;
      return <CardBrand brand={paymentMethod ?? CreditCardBrand.CardBrandUnknown} last4={last4} />;
    },
    maxWidth: 180,
    disableSortBy: true,
  },
  {
    id: 'paidAmount',
    Header: 'Payment Amount',
    accessor: (refund) => (refund?.payment?.amount ? formatCentsToCurrency(+refund.payment?.amount) : ''),
    minWidth: 120,
    maxWidth: 150,
    disableResizing: true,
    disableSortBy: true,
  },
  {
    id: 'paidAt',
    Header: 'Payment Date',
    maxWidth: 150,
    accessor: (refund) => (refund?.payment?.date ? formatDate(refund.payment?.date, 'MMM D, YYYY') : ''),
    disableResizing: true,
    disableSortBy: true,
  },
  {
    id: 'paymentId',
    Header: 'Payment ID',
    accessor: (refund) => refund?.payment?.id || '',
    minWidth: 90,
    width: 90,
    disableSortBy: true,
  },
  {
    id: 'refundId',
    Header: 'Refund ID',
    minWidth: 90,
    width: 90,
    accessor: (refund) => refund?.id,
    disableSortBy: true,
  },
];

export const transformDataForCsvExport = (
  data: RefundEdge[] = [],
  getLocationName: GetLocationName,
  showLocationName: boolean
) => {
  return data.map((refund) => {
    const refundId = refund.node.id || '';
    const refundType = prettify(refund.node.type ?? '');
    const personName = refund.node.person?.name || '';
    const refundAmount = formatCentsToCurrency(refund.node.refundAmount)?.replace(',', '') || 'Not Available';
    const refundDate = formatDate(refund.node.dateRefunded, 'YYYY-MM-D');
    const personId = refund.node.person?.id || '';
    const paymentAmount = formatCentsToCurrency(refund.node.payment?.amount)?.replace(',', '') || 'Not Available';
    const paymentDate = formatDate(refund.node.payment?.date, 'YYYY-MM-D');
    const paymentId = refund.node.payment?.id || '';
    const locationName = getLocationName(refund.node.locationId ?? '') ?? '';

    return {
      ...(showLocationName ? { 'Location Name': locationName } : {}),
      'Patient Id': personId,
      Name: personName,
      'Refund Id': refundId,
      'Refund Type': refundType,
      'Refund Amount': refundAmount,
      'Refund Date': refundDate,
      'Payment Id': paymentId,
      'Payment Amount': paymentAmount,
      'Payment Date': paymentDate,
    };
  });
};
