import { FC, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { SlideType } from '@weave/schema-gen-ts/dist/schemas/threads-of-success/v1';
import { useQuery } from 'react-query';
import { Trans, useTranslation } from '@frontend/i18n';
import { breakpoints } from '@frontend/responsiveness';
import { SchemaThreadsOfSuccessService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import {
  CallsClip,
  AnsweredClip,
  FormsClip,
  MinutesClip,
  PaymentsClip,
  TextClip,
  WeaveClip,
} from '@frontend/thread-of-success#svg';
import { theme } from '@frontend/theme';
import { Heading, SlideCarousel, useSlideCarousel, Text } from '@frontend/design-system';

const stringToBannerType = (str: string): SlideType | undefined => {
  return SlideType[str as keyof typeof SlideType];
};

const SCROLL_INTERVAL = 8000;

type YearEndBannerProps = {
  type: SlideType;
  count: number;
};

const getBannerColors = (type: SlideType | undefined) => {
  switch (type) {
    case SlideType.SLIDE_TYPE_UNSPECIFIED:
    case SlideType.SLIDE_TYPE_SMS_SENT:
      return {
        body: theme.colors.indigo10,
        text: theme.colors.indigo70,
        carouselActiveState: theme.colors.indigo60,
        carouselBackground: theme.colors.indigo20,
      };
    case SlideType.SLIDE_TYPE_CALLS_DURATION:
    case SlideType.SLIDE_TYPE_PAYMENTS_PROCESSED:
      return {
        body: theme.colors.secondary.eggplant10,
        text: theme.colors.secondary.eggplant70,
        carouselActiveState: theme.colors.secondary.eggplant60,
        carouselBackground: theme.colors.secondary.eggplant20,
      };
    case SlideType.SLIDE_TYPE_CALLS_OUTBOUND:
    case SlideType.SLIDE_TYPE_FORMS_RECEIVED:
      return {
        body: theme.colors.rose10,
        text: theme.colors.rose70,
        carouselActiveState: theme.colors.rose60,
        carouselBackground: theme.colors.rose20,
      };
    case SlideType.SLIDE_TYPE_CALLS_INBOUND_ANSWERED:
      return {
        body: theme.colors.secondary.seaweed10,
        text: theme.colors.secondary.seaweed70,
        carouselActiveState: theme.colors.secondary.seaweed60,
        carouselBackground: theme.colors.secondary.seaweed20,
      };
    default:
      return {
        body: theme.colors.indigo10,
        text: theme.colors.indigo70,
        carouselActiveState: theme.colors.indigo60,
        carouselBackground: theme.colors.indigo20,
      };
  }
};

const useBannerContent = (dataPoint: number, type: SlideType) => {
  const { t } = useTranslation('dashboard');

  return useMemo(() => {
    const formattedCount = `${type === SlideType.SLIDE_TYPE_PAYMENTS_PROCESSED ? '$' : ''}${Intl.NumberFormat(
      'en-US'
    ).format(dataPoint)}`;

    switch (type) {
      case SlideType.SLIDE_TYPE_UNSPECIFIED:
        return {
          heading: t('Thread of Success'),
          content: (
            <Trans t={t} values={{ value: formattedCount }}>
              <Text as='span' size='large' weight='bold' style={{ color: 'inherit' }}>
                This reflection highlights your achievements with Weave,
              </Text>
              <br />
              showing how every effort contributed to growth, connection, and success—
              <strong>a celebration of meaningful impact built together.</strong>
            </Trans>
          ),
          icon: <WeaveClip css={bannerStyle.imageStyle} />,
        };
      case SlideType.SLIDE_TYPE_CALLS_DURATION:
        return {
          heading: t('Meaningful Minutes'),
          content: (
            <Trans t={t} values={{ formattedCount }}>
              <Text as='span' size='large' weight='bold' style={{ color: 'inherit' }}>
                Last year, your office spent <span css={bannerCountStyle}>{{ formattedCount }}</span> minutes
              </Text>
              <br /> on the phone —connecting, collaborating, and building relationships. That’s a whole lot of talkin’,{' '}
              <strong>and time well spent making a difference!</strong>
            </Trans>
          ),
          icon: <MinutesClip css={bannerStyle.imageStyle} />,
        };
      case SlideType.SLIDE_TYPE_CALLS_OUTBOUND:
        return {
          heading: t('Every Call Counts'),
          content: (
            <Trans t={t} values={{ formattedCount }}>
              <Text as='span' size='large' weight='bold' style={{ color: 'inherit' }}>
                Last year, your team placed <span css={bannerCountStyle}>{{ formattedCount }}</span> calls
              </Text>
              <br />
              —some to share good news, some to solve urgent problems,{' '}
              <strong>and many to support the people who count on you.</strong>
            </Trans>
          ),
          icon: <CallsClip css={bannerStyle.imageStyle} />,
        };
      case SlideType.SLIDE_TYPE_CALLS_INBOUND_ANSWERED:
        return {
          heading: t('Ringing Opportunity'),
          content: (
            <Trans t={t} values={{ formattedCount }}>
              <Text as='span' size='large' weight='bold' style={{ color: 'inherit' }}>
                Last year, your office answered <span css={bannerCountStyle}>{{ formattedCount }}</span> calls
              </Text>
              <br />
              —each one an opportunity to tackle critical challenges, seize growth opportunities,{' '}
              <strong>and drive performance that moves the business forward.</strong>
            </Trans>
          ),
          icon: <AnsweredClip css={bannerStyle.imageStyle} />,
        };
      case SlideType.SLIDE_TYPE_SMS_SENT:
        return {
          heading: t('Texts That Matter'),
          content: (
            <Trans t={t} values={{ formattedCount }}>
              <Text as='span' size='large' weight='bold' style={{ color: 'inherit' }}>
                Last year, your office sent <span css={bannerCountStyle}>{{ formattedCount }}</span> messages
              </Text>
              <br />
              —now that’s efficient communication. Whether confirming appointments, sending reminders, or processing
              payments,{' '}
              <strong>you ensured your customers had the information they needed, exactly when they needed it.</strong>
            </Trans>
          ),
          icon: <TextClip css={bannerStyle.imageStyle} />,
        };
      case SlideType.SLIDE_TYPE_PAYMENTS_PROCESSED:
        return {
          heading: t('Proof’s in the Payments'),
          content: (
            <Trans t={t} values={{ formattedCount }}>
              <Text as='span' size='large' weight='bold' style={{ color: 'inherit' }}>
                Last year, your office processed <span css={bannerCountStyle}>{{ formattedCount }}</span> in payments
              </Text>
              <br />
              —reflecting the professionalism, reputation, and personalized service that define your business,{' '}
              <strong>and the trust you've earned from your customers.</strong>
            </Trans>
          ),
          icon: <PaymentsClip css={bannerStyle.imageStyle} />,
        };
      case SlideType.SLIDE_TYPE_FORMS_RECEIVED:
        return {
          heading: t('Forms That Perform'),
          content: (
            <Trans t={t} values={{ formattedCount }}>
              <Text as='span' size='large' weight='bold' style={{ color: 'inherit' }}>
                Last year, your office received <span css={bannerCountStyle}>{{ formattedCount }}</span> patient forms
              </Text>
              <br />
              —demonstrating how your efficient systems allowed you to care for more patients with fewer bottlenecks.
            </Trans>
          ),
          icon: <FormsClip css={bannerStyle.imageStyle} />,
        };
    }
  }, [type, dataPoint]);
};

const YearEndBanner: FC<React.PropsWithChildren<YearEndBannerProps>> = ({ count, type }) => {
  const { heading, content, icon } = useBannerContent(count, type);
  const { text, body } = getBannerColors(type);
  return (
    <div css={bannerStyle.container(body)}>
      <Heading level={2} css={bannerStyle.headingStyle(type, text)}>
        {heading}
      </Heading>
      <div css={bannerStyle.textContainer}>
        <Text size='medium' css={{ color: text }}>
          {content}
        </Text>
      </div>
      {icon}
    </div>
  );
};

const useYearEndSlides = () => {
  const { selectedLocationIds } = useAppScopeStore();

  return useQuery({
    queryKey: ['threads-of-success', ...selectedLocationIds],
    queryFn: () =>
      SchemaThreadsOfSuccessService.GetSlides({
        locationIds: selectedLocationIds,
      }),
  });
};

export const YearEndCarousel = () => {
  const { data: carousalData, isLoading } = useYearEndSlides();
  if (carousalData?.slides?.length === 0) {
    return null;
  }

  if (isLoading) {
    return null;
  }

  const slides = [{ type: SlideType.SLIDE_TYPE_UNSPECIFIED, value: 0 }].concat(carousalData?.slides || []);
  return (
    <div css={carousalStyle.container}>
      <SlideCarousel slides={slides.map((slide) => slide.type)}>
        <SlideCarousel.Container>
          {slides?.map((slide) => (
            <SlideCarousel.Panel key={slide.type} id={slide.type}>
              <YearEndBanner type={slide.type} count={slide.value} />
            </SlideCarousel.Panel>
          ))}
        </SlideCarousel.Container>
        <CarouselNavigation />
      </SlideCarousel>
    </div>
  );
};

const CarouselNavigation = () => {
  const { activeId, slideIds, setActiveId } = useSlideCarousel();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentIndex = slideIds.indexOf(activeId);
      const nextIndex = (currentIndex + 1) % slideIds.length;
      setActiveId(slideIds[nextIndex]);
    }, SCROLL_INTERVAL);

    return () => clearInterval(interval);
  }, [activeId, slideIds, setActiveId]);
  const { carouselActiveState, carouselBackground } = getBannerColors(stringToBannerType(activeId));

  return (
    <div css={carousalStyle.slideNavStyle(carouselBackground)}>
      <SlideCarousel.Navigation
        variant='centeredIndicators'
        activeCenteredIndicatorColor={carouselActiveState}
        trackingId='tos-slides'
      />
    </div>
  );
};

const bannerCountStyle = css`
  font-size: ${theme.fontSize(32)};
  vertical-align: bottom;
  line-height: 1;
  @media screen and (max-width: ${breakpoints.medium.max}px) {
    margin-bottom: ${theme.spacing(1)};
    font-size: ${theme.fontSize(48)};
    display: block;
  }
`;

const carousalStyle = {
  container: css`
    height: 228px;
    position: relative;
    margin: ${theme.spacing(2)};

    @media screen and (max-width: ${breakpoints.medium.max}px) {
      height: 360px;
      margin: ${theme.spacing(2)};
    }
  `,
  slideNavStyle: (color: string) => css`
    position: absolute;
    bottom: ${theme.spacing(2)};
    padding: ${theme.spacing(1, 1.5)};
    border-radius: ${theme.borderRadius.full};
    background: ${color};
    left: 45%;
    @media screen and (max-width: ${breakpoints.medium.max}px) {
      left: 30%;
    }
  `,
};

const bannerStyle = {
  container: (bodyColor: string) => css`
    width: 100%;
    height: 228px;
    border-radius: 24px;
    background-color: ${bodyColor};
    padding-top: ${theme.spacing(4.75)};
    padding-left: ${theme.spacing(7)};

    @media screen and (max-width: ${breakpoints.medium.max}px) {
      height: 360px;
      padding-top: ${theme.spacing(3)};
      padding-left: ${theme.spacing(5)};
    }
  `,
  headingStyle: (type: SlideType, textColor: string) => css`
    color: ${textColor};
    margin-bottom: ${theme.spacing(2)};
    font-size: ${theme.fontSize(28)};
    @media screen and (max-width: ${breakpoints.medium.max}px) {
      margin-bottom: ${type === SlideType.SLIDE_TYPE_UNSPECIFIED ? theme.spacing(3) : theme.spacing(1)};
    }
  `,
  textContainer: css`
    width: 541px;
    z-index: 2;
    position: absolute;
    @media screen and (max-width: ${breakpoints.medium.max}px) {
      width: 260px;
    }
  `,
  imageStyle: css`
    position: absolute;
    right: 1px;
    top: 0px;
    height: 228px;
    border-radius: ${theme.borderRadius.large};

    @media screen and (max-width: ${breakpoints.medium.max}px) {
      height: 80px;
      right: 0px;
      bottom: 0px;
      top: unset;
      position: absolute;
      z-index: 0;
    }
  `,
};
