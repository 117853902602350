import { useMemo } from 'react';
import { PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { useSoftphoneClient } from '../providers/softphone-client-provider';
import { useSoftphoneDirectory } from '../providers/softphone-directory-provider';
import { useSoftphoneUsers } from '../providers/softphone-users-provider';
import { RemoteParty } from '../types';
import {
  formatPhoneNumber,
  getURIType,
  getUsernameFromURI,
  isAnExtensionIntercomCall,
  isPhoneNumber,
} from '../utils/formatting-utils';

type Props = RemoteParty & {
  enabled?: boolean;
};

type CallerInfoResult = {
  title: string | undefined;
  isLoading: boolean;
  isFetched: boolean;
  type: string | undefined;
  contact: PersonTypes.Person | undefined;
  phoneNumber: string | false;
};

export const UNKNOWN_TITLE_NAME = 'Unknown';

export const useCallerInfo = ({ uri, displayName, enabled = true }: Props) => {
  const { t } = useTranslation('softphone');
  const type = getURIType(uri);
  const useGetPersonByPhone = useSoftphoneDirectory((ctx) => ctx.queries.useGetPersonByPhoneQuery);
  const getUser = useSoftphoneUsers((ctx) => ctx.getUser);
  const extensions = useSoftphoneClient((ctx) => ctx.extensions);

  // should be phone or park type only
  const shouldFetch = enabled && !!uri && type !== 'address';
  const {
    data: contact,
    isLoading,
    isFetched,
  } = useGetPersonByPhone(getUsernameFromURI(uri), {
    enabled: shouldFetch,
  });

  // should be address or park type only
  const user = useMemo(() => (!!uri && type !== 'phone' ? getUser(uri) : undefined), [uri, type]);
  const userExtensionName = useMemo(
    () =>
      !user && type !== 'phone'
        ? extensions.find((extension) => {
            if (isAnExtensionIntercomCall(uri)) return extension.number === +uri.slice(1);
            return extension.number === +uri;
          })?.name
        : undefined,
    [uri, type, user]
  );

  const title = useMemo(() => {
    const contactFullName = contact && `${contact?.FirstName} ${contact?.LastName}`;
    if (type === 'phone') {
      return contactFullName ? contactFullName : displayName ?? t(`${UNKNOWN_TITLE_NAME}`);
    } else if (type === 'address') {
      return user?.name ?? userExtensionName ?? displayName ?? t(`${UNKNOWN_TITLE_NAME}`);
    } else if (type === 'park') {
      return contactFullName ? contactFullName : user ? user?.name : displayName ?? t(`${UNKNOWN_TITLE_NAME}`);
    }
    return t(`${UNKNOWN_TITLE_NAME}`);
  }, [contact, user, displayName, type]);

  const isDone = !!uri && (shouldFetch ? isFetched : true);

  const phoneNumber = isPhoneNumber(uri) && formatPhoneNumber(uri);

  return {
    title,
    isLoading,
    isFetched: isDone,
    type,
    contact,
    phoneNumber,
  } satisfies CallerInfoResult;
};
