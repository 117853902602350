import { useState, useRef, forwardRef, useMemo } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { isRefObject } from '@frontend/types';
import { theme } from '@frontend/theme';
import { useOnClickOutside, KeyNames, useTabbable } from '@frontend/design-system';
import { useGetAppointmentDetails } from '../../../hooks';
import { useCalendarView, useCalendarViewProps } from '../providers';
import { EventType } from '../types';
import {
  getDistanceFromParentEdges,
  maxTotalGridColumns,
  zIndex,
  createGridColumnsFromStartAndEndTimestamp,
  getDurationFromHours,
  EVENT_CARD_DEFAULT_MEASUREMENTS,
  getEventCardCssForVerticalView,
} from '../utils';

type EventCardProps = {
  id: string;
  locationName: string;
  locationId?: string;
  providerName?: string;
  providerId?: string;
  title: string;
  startHour: string;
  endHour: string;
  eventTypeColor?: string;
  disabled?: boolean;
  laneCount?: number;
  laneIndex?: number;
  type: EventType;
  index?: number;
  visibleLaneCount: number;
};

const EXPANDED_CARD_DEFAULT_HEIGHT = 248;

export const EventCard = forwardRef(
  (
    {
      id,
      title,
      type,
      locationName,
      locationId,
      providerName,
      providerId,
      startHour,
      endHour,
      eventTypeColor,
      disabled = false,
      laneCount = 0,
      laneIndex = 0,
      index = 0,
      visibleLaneCount,
    }: EventCardProps,
    ref: React.Ref<HTMLElement>
  ) => {
    const [isEventCardExpanded, setEventCardExpanded] = useState(false);
    const [resetZIndex, setResetZIndex] = useState(false);

    const { startHour: calendarStartHour, endHour: calendarEndHour, EventCardComponent } = useCalendarViewProps();
    const { setBackgroundView, setEventTransitionComplete, isVerticalView, isZoomedIn, setFloatingTooltipData } =
      useCalendarView([
        'setBackgroundView',
        'setEventTransitionComplete',
        'isVerticalView',
        'isZoomedIn',
        'setFloatingTooltipData',
      ]);

    const eventCardWrapperRef = useRef<HTMLDivElement | null>(null);
    const eventCardContentWrapperRef = useRef<HTMLDivElement | null>(null);
    const measureRef = useRef(EXPANDED_CARD_DEFAULT_HEIGHT);

    const duration = useMemo(() => getDurationFromHours(startHour, endHour), [startHour, endHour]);

    const isAppointment = type === 'appointment';
    const eventColor = eventTypeColor ?? (isAppointment ? theme.colors.warning50 : theme.colors.neutral50);

    const { triggerProps: viewAppointmentTriggerProps, Modal: AppointmentDetailsModal } = useGetAppointmentDetails({
      appointmentId: id,
      locationId: locationId ?? '',
      eventColor: eventColor,
      onModalClose: () => {
        collapseEventCard();
      },
      enabled: isAppointment,
    });

    const expandEventCard = () => {
      viewAppointmentTriggerProps.onClick();
      if (!(isRefObject(ref) && eventCardWrapperRef.current && ref.current)) return;
      if (!disabled) setEventCardExpanded(true);
      setBackgroundView(true);
    };

    const collapseEventCard = () => {
      setEventCardExpanded(false);
      setBackgroundView(false);
      setFloatingTooltipData(undefined);
    };

    useTabbable({ ref: eventCardContentWrapperRef, autofocus: 'first' });
    useOnClickOutside({
      ref: eventCardContentWrapperRef,

      handler: collapseEventCard,
      active: isEventCardExpanded,
      captureClick: false,
    });

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if ((e.key === KeyNames.Enter || e.key === KeyNames.Space) && !isEventCardExpanded) {
        e.preventDefault();
        expandEventCard();
        eventCardContentWrapperRef.current?.focus();
      }
      if (e.key === KeyNames.Escape && isEventCardExpanded) {
        e.preventDefault();
        collapseEventCard();
      }
    };

    const shouldOpenUp = () => {
      if (!(isRefObject(ref) && eventCardWrapperRef.current && ref.current)) return false;

      return (
        getDistanceFromParentEdges(eventCardWrapperRef.current, ref.current).bottom < measureRef.current + 10 //measureRef is the measured height of the container + 10 offset
      );
    };

    const { startGridColumn, endGridColumn } = createGridColumnsFromStartAndEndTimestamp(
      calendarStartHour,
      calendarEndHour,
      !isVerticalView && isZoomedIn
    )({
      startHourTimestamp: startHour,
      endHourTimeStamp: endHour,
    });

    const shouldHideEventCard = () => {
      const maxTotalMinutes = maxTotalGridColumns(calendarStartHour, calendarEndHour);

      return !(
        (startGridColumn <= 1 && endGridColumn <= 1) ||
        (startGridColumn === maxTotalMinutes && endGridColumn === maxTotalMinutes) ||
        // hide event card if it's outside of the visible lane count
        laneIndex >= visibleLaneCount
      );
    };

    return (
      <motion.div
        data-event='true'
        tabIndex={isEventCardExpanded ? -1 : 0}
        onKeyDown={handleKeyDown}
        onMouseEnter={() => setFloatingTooltipData({ name: title, value: duration })}
        onMouseLeave={() => setFloatingTooltipData(undefined)}
        ref={eventCardWrapperRef}
        layout
        css={[
          !shouldHideEventCard() &&
            css`
              display: none;
            `,
          isVerticalView && [
            css`
              grid-column: 1;
              grid-row: ${startGridColumn} / ${endGridColumn};
              transition: left 0.5s ease-in-out, width 0.5s ease-in-out;
            `,
            getEventCardCssForVerticalView(visibleLaneCount, laneCount, laneIndex),
          ],
          !isVerticalView &&
            css`
              grid-row: 1;
              grid-column: ${startGridColumn} / ${endGridColumn};
              height: ${laneCount > 1 ? '45%' : 'auto'};
              margin-top: ${index === 0 ? 0 : 'auto'};
            `,
          css`
            user-select: none;
            position: relative;
            outline: none;
            &::after {
              content: '';
              width: calc(100% + 8px);
              height: ${isVerticalView ? '100%' : 'calc(100% + 8px)'};
              position: absolute;
              left: 50%;
              top: 50%;
              pointer-events: none;
              border-radius: ${theme.borderRadius.medium};
              opacity: 0;
              transition: 0.2s ease;
              transform: translate(-50%, -50%);
              border: 2px solid ${theme.colors.primary30};
            }
            &:focus-visible {
              &::after {
                opacity: 1;
              }
            }
          `,
          !isEventCardExpanded &&
            css`
              cursor: pointer;
            `,
        ]}
        style={{ zIndex: resetZIndex ? zIndex.eventCardExpanded : zIndex.eventCardDefault }}
      >
        <AnimatePresence>
          {isEventCardExpanded && (
            <EventDurationLine shouldOpenUp={index === 0} shouldOpenLeft={!isAppointment} eventColor={eventColor} />
          )}
        </AnimatePresence>
        <motion.article
          data-event-id={id}
          tabIndex={isEventCardExpanded ? 0 : -1}
          onKeyDown={handleKeyDown}
          ref={eventCardContentWrapperRef}
          onMouseDown={(e) => e.stopPropagation()}
          layout={!isAppointment}
          onClick={() => {
            if (!isEventCardExpanded) expandEventCard();
          }}
          onLayoutMeasure={(box) => {
            if (!isAppointment && isEventCardExpanded && box) {
              measureRef.current = box.y.max - box.y.min;
              setResetZIndex(true);
              setEventTransitionComplete(true);
            }
          }}
          onLayoutAnimationComplete={() => {
            if (!isEventCardExpanded) {
              setResetZIndex(false);
              setEventTransitionComplete(false);
            }
          }}
          style={{
            borderRadius: theme.borderRadius.small,
            padding:
              !isAppointment && isEventCardExpanded
                ? theme.spacing(isAppointment ? 3 : 2)
                : theme.spacing(0, 1.5, 0, 1.5),
            width: isEventCardExpanded && !isAppointment ? EVENT_CARD_DEFAULT_MEASUREMENTS.expandedWidth : 'auto',
            ...(isEventCardExpanded && !isAppointment && { height: 'auto' }),
            ...(isEventCardExpanded &&
              !isAppointment &&
              shouldOpenUp() && { marginTop: -measureRef.current + EVENT_CARD_DEFAULT_MEASUREMENTS.condensedHeight }),
          }}
          css={[
            css`
              box-shadow: ${theme.shadows.light};
              position: relative;
              overflow: hidden;
              outline: none;
              background: ${isAppointment ? theme.colors.white : theme.colors.neutral20};
              height: 100%;
              transition: box-shadow 0.2s ease-in-out, scale 0.2s ease-in-out;
              &:hover {
                box-shadow: ${theme.shadows.floating};
                scale: 1.02;
              }
            `,
          ]}
        >
          <EventColoredLine eventColor={eventColor} expanded={isEventCardExpanded && !isAppointment} />
          {/* remove this condition to allow modal to animate */}
          {AppointmentDetailsModal}
          <EventCardComponent
            id={id}
            eventType={type}
            title={title}
            duration={duration}
            locationName={locationName}
            locationId={locationId}
            providerId={providerId}
            providerName={providerName}
            isExpanded={isEventCardExpanded}
          />
        </motion.article>
      </motion.div>
    );
  }
);

EventCard.displayName = 'EventCard';

type EventDurationLineProps = {
  eventColor: string;
  shouldOpenUp?: boolean;
  shouldOpenLeft?: boolean;
};

const EventDurationLine = ({ eventColor, shouldOpenUp, shouldOpenLeft = false }: EventDurationLineProps) => {
  const { isVerticalView } = useCalendarView(['isVerticalView']);

  return (
    <motion.div
      initial='hidden'
      animate='visible'
      exit='hidden'
      variants={
        isVerticalView
          ? {
              hidden: {
                height: 0,
                opacity: 0,
              },
              visible: {
                height: '100%',
                opacity: 1,
              },
            }
          : {
              hidden: {
                width: 0,
                opacity: 0,
              },
              visible: {
                width: '100%',
                opacity: 1,
              },
            }
      }
      transition={{ duration: 0.3 }}
      css={[
        css`
          position: absolute;
          width: 100%;
          height: 2px;
          background: ${eventColor};
        `,
        isVerticalView &&
          !shouldOpenLeft &&
          css`
            width: 2px;
            height: 100%;
            right: -10px;
          `,
        isVerticalView &&
          shouldOpenLeft &&
          css`
            width: 2px;
            height: 100%;
            left: -10px;
          `,
        !isVerticalView &&
          shouldOpenUp &&
          css`
            top: -10px;
          `,
        !isVerticalView &&
          !shouldOpenUp &&
          css`
            bottom: -10px;
          `,
      ]}
    >
      <motion.div
        css={[
          css`
            position: absolute;
            height: 10px;
            width: 10px;
            border-radius: 30px;
            background: ${eventColor};
          `,
          isVerticalView &&
            css`
              left: 50%;
              transform: translateX(-50%);
              bottom: 0;
            `,
          !isVerticalView &&
            css`
              right: 0;
              top: 50%;
              transform: translateY(-50%);
            `,
        ]}
      />
    </motion.div>
  );
};

const EventColoredLine = ({ eventColor, expanded }: { eventColor: string; expanded: boolean }) => {
  return (
    <motion.div
      layout='preserve-aspect'
      style={{
        position: 'absolute',
        background: eventColor,
        left: 0,
        top: 0,
        height: '200%',
        width: theme.spacing(0.5),
        borderRadius: `${theme.borderRadius.small} 0 0 ${theme.borderRadius.small}`,
      }}
      transition={{ duration: 0.3, ease: 'linear' }}
      animate={{
        width: theme.spacing(expanded ? 1 : 0.5),
      }}
    />
  );
};
