import { useState } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import {
  ContactDirectoriesQuery,
  ContactDirectoriesQueryKeys,
  ContactDirectoriesTypes,
  ContactDirectoriesMutation,
} from '@frontend/api-contact-directories';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import {
  Button,
  Heading,
  Modal,
  ModalControlModalProps,
  PopoverDialog,
  styles,
  Table,
  Text,
  useAlert,
  usePopoverDialog,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { CommonModalWrapper } from './modal-wrapper';

type Props = ModalControlModalProps & {
  contactDirectoryId: string;
};

export const AddExisitingContactModal = ({ onClose, show, contactDirectoryId }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const [filteredGlobalData, setFilteredGlobalData] =
    useState<ContactDirectoriesTypes.ListContactsType['output']['contacts']>();
  const alerts = useAlert();
  const queryClient = useQueryClient();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const [selectedIds, setSelectedIds] = useState<string[]>();

  const { useGetContacts, useGetContactDirectoryContacts } = ContactDirectoriesQuery.useContactDirectoryQueries({
    tenantId,
  });

  const { data, isLoading: isContactsLoading } = useGetContactDirectoryContacts({
    contactListId: contactDirectoryId,
  });
  const { data: { contacts: allContacts } = { contacts: [] } } = useGetContacts();

  const { useReplaceContactDirectoryContactsMutation } = ContactDirectoriesMutation.useContactDirectoryMutations();
  const { mutate } = useReplaceContactDirectoryContactsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([
        tenantId,
        ...ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId),
      ]);
      onClose();
      alerts.success(t('Successfully added existing contacts to list'));
    },
  });

  const mappedContactIds = data?.contactIds?.reduce<Record<string, boolean>>((acc, sum) => {
    return {
      ...acc,
      [sum]: true,
    };
  }, {} as Record<string, boolean>);

  return (
    <CommonModalWrapper onClose={onClose} show={show}>
      <Modal.Header onClose={onClose}>{t('Add Existing Contact')}</Modal.Header>
      <Modal.Body>
        <Table
          isLoading={isContactsLoading}
          data={filteredGlobalData ?? allContacts ?? ([] as typeof allContacts)}
          colConfig={[
            {
              Header: t('Name'),
              id: 'contacts-name',
              accessor: (cell) => cell.name,
            },
            {
              Header: t('Phone Number'),
              id: 'phone-number',
              accessor: (cell) => cell.phoneNumbers?.[0]?.number,
              cellRenderer: (number) => formatPhoneNumber(number),
            },
            {
              Header: '',
              id: 'actions',
              accessor: (cell) => cell,
              cellRenderer: (contact) => <ViewContact contact={contact} />,
              sticky: 'right',
              disableSortBy: true,
              width: 50,
            },
          ]}
          isSelectable
          isPaginated
          hasGlobalSearch
          globalSearchConfig={{
            label: t('Search Name or Phone Number'),
            searchHandler: (term) => {
              const filteredContacts = allContacts?.filter(
                (data) =>
                  data.name?.toLocaleLowerCase().includes(term.toLocaleLowerCase()) ||
                  data.phoneNumbers?.[0].number?.replace(/-/g, '').toString().includes(term)
              );
              setFilteredGlobalData(filteredContacts);
            },
          }}
          uniqueRowId={({ id }) => id}
          rowSelectionConfig={{
            initialState: mappedContactIds,
            onSelectionToggle: (_, __, ids) => {
              setSelectedIds(Object.keys(ids));
            },
          }}
          wrapperStyle={css`
            margin-top: ${theme.spacing(2)};
            max-height: 800px;
            & > div:first-of-type > div:nth-of-type(2) {
              width: 300px;
            }
          `}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Save')}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        disablePrimary={!selectedIds?.length}
        onPrimaryClick={() => {
          if (!!selectedIds?.length) mutate({ contactListId: contactDirectoryId, contactIds: selectedIds });
        }}
      />
    </CommonModalWrapper>
  );
};

type Contact = ContactDirectoriesTypes.ListContactsType['output']['contacts'][0];

const ViewContact = ({ contact }: { contact: Contact }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const { getTriggerProps, getDialogProps } = usePopoverDialog<HTMLButtonElement>({
    placement: 'left-start',
  });
  const { notes, name, phoneNumbers } = contact;
  return (
    <div>
      <Button variant='secondary' iconName='preview' {...getTriggerProps()} />
      <PopoverDialog {...getDialogProps()}>
        <Heading level={3} css={{ paddingBottom: theme.spacing(2) }}>
          {name}
        </Heading>
        {phoneNumbers?.map((phoneNumber) => (
          <div css={listStyles} key={phoneNumber.number}>
            <Text css={styles.truncate} color='light' size='medium'>
              {phoneNumber.label}
            </Text>
            <Text weight='bold' size='medium'>
              {formatPhoneNumber(phoneNumber.number, false)}
            </Text>
          </div>
        ))}
        {notes && (
          <div css={listStyles}>
            <Text color='light' size='medium'>
              {t('Note')}
            </Text>
            <Text size='medium' weight='bold'>
              {notes}
            </Text>
          </div>
        )}
      </PopoverDialog>
    </div>
  );
};

const listStyles = {
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  paddingBottom: theme.spacing(1.5),
  '& :first-child': {
    minWidth: theme.spacing(11),
    maxWidth: theme.spacing(11),
  },
};
