import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const displayHidden = css({ display: 'none' });

export const containerStyle = css({
  display: 'flex',
  flexDirection: 'column',
});

export const chatsContainerStyle = css({
  padding: theme.spacing(1),
  display: 'flex',
  flex: 1,
  gap: theme.spacing(2),
  overflowY: 'auto',
  flexDirection: 'column',
});

export const textAreaStyle = css({
  margin: theme.spacing(1, 0, 0, 0),
});

export const modalTextStyle = css({
  color: theme.colors.black,
  marginTop: theme.spacing(1),
});

export const modalButtonStyle = css({ float: 'right', width: 'fit-content' });
