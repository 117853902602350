import { FallbackRender } from '@sentry/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { ErrorAccordion } from './common';

export const WidgetBoundary: FallbackRender = ({ error, eventId, resetError }) => {
  const { t } = useTranslation('base');

  if (!(error instanceof Error)) {
    return <Text weight='bold'>{t('Something went wrong.')}</Text>;
  }
  return (
    <div
      style={{
        background: theme.colors.white,
        display: 'grid',
        alignContent: 'center',
        justifyItems: 'center',
        gap: theme.spacing(2),
      }}
    >
      <Text weight='bold'>{t('Something went wrong.')}</Text>
      <ErrorAccordion error={error} eventId={eventId} />
      <Button onClick={resetError}>{t('Try again')}</Button>
    </div>
  );
};
