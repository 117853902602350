import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import dayjs from 'dayjs';
import { createProvider } from '@frontend/store';
import { CustomProviderType } from '../hooks/booking-site';

interface State {
  isSchedularV3Enabled: boolean;

  selectedLocationId: string;
  selectedLocationName: string;
  selectedLocationTimeZone: string;
  paymentLocationPortalSlug: string;

  selectedAppointmentType?: AppointmentType;
  selectedAppointmentLeadTime: string; // ISO date string
  weekInView: string; // ISO date string
  minSlots: number;

  availableProviders: CustomProviderType[];
  selectedProvider?: CustomProviderType | 'any';
  selectedDay?: string;

  selectedSlots: string[];
  captchaV3Token: string;
}

interface BookingSiteStore extends State {
  setIsSchedularV3Enabled: (isSchedularV3Enabled: boolean) => void;

  setLocationTimeZone: (timezone: string) => void;
  setSelectedLocation: (locationId: string, locationName: string, paymentLocationPortalSlug: string) => void;

  setSelectedAppointmentType: (appointmentType: AppointmentType, minSlots?: number) => void;
  clearSelectedAppointmentType: () => void;

  setWeekInView: (weekInView: string) => void;

  setAvailableProviders: (providers: CustomProviderType[]) => void;
  setSelectedProvider: (provider: CustomProviderType | 'any', selectedDay?: string) => void;
  clearSelectedProvider: () => void;

  setSelectedSlots: (slots: string[]) => void;
  setCaptchaV3Token: (captchaV3Token: string) => void;
}

export const { Provider: BookingSiteStoreProvider, useStore: useBookingSiteStore } = createProvider<BookingSiteStore>()(
  (set) => ({
    isSchedularV3Enabled: false,
    setIsSchedularV3Enabled: (isSchedularV3Enabled) => {
      set({ isSchedularV3Enabled });
    },

    selectedLocationId: '',
    selectedLocationName: '',
    selectedLocationTimeZone: '',
    paymentLocationPortalSlug: '',
    setLocationTimeZone: (selectedLocationTimeZone) => set({ selectedLocationTimeZone }),
    setSelectedLocation: (selectedLocationId, selectedLocationName, paymentLocationPortalSlug) => {
      set({ selectedLocationId, selectedLocationName, paymentLocationPortalSlug });
    },

    selectedAppointmentType: undefined,
    selectedAppointmentLeadTime: dayjs().toISOString(),
    minSlots: 3,
    setSelectedAppointmentType: (selectedAppointmentType, minSlots = 3) => {
      const selectedAppointmentLeadTime = dayjs()
        .startOf('day')
        .add(selectedAppointmentType?.requestBufferDuration ?? 0, 'second')
        .toISOString();
      set({
        selectedAppointmentType,
        selectedAppointmentLeadTime: selectedAppointmentType ? selectedAppointmentLeadTime : dayjs().toISOString(),
        weekInView: selectedAppointmentType ? selectedAppointmentLeadTime : dayjs().toISOString(),
        minSlots,
        selectedProvider: undefined,
      });
    },
    clearSelectedAppointmentType: () => {
      set({
        selectedAppointmentType: undefined,
        selectedAppointmentLeadTime: dayjs().toISOString(),
        availableProviders: [],
        weekInView: dayjs().toISOString(),
        selectedProvider: undefined,
        selectedDay: undefined,
        selectedSlots: [],
      });
    },

    weekInView: dayjs().toISOString(),
    setWeekInView: (weekInView) => set({ weekInView }),

    availableProviders: [],
    selectedProvider: undefined,
    selectedDay: undefined,
    setAvailableProviders: (availableProviders) => {
      set({ availableProviders });
    },
    setSelectedProvider: (selectedProvider, selectedDay) => {
      set({ selectedProvider, selectedDay });
    },
    clearSelectedProvider: () => {
      set({ selectedProvider: undefined, selectedDay: undefined, selectedSlots: [] });
    },

    selectedSlots: [],
    setSelectedSlots: (selectedSlots) => {
      set({ selectedSlots });
    },

    captchaV3Token: '',
    setCaptchaV3Token: (captchaV3Token) => {
      set({ captchaV3Token });
    },
  })
);
