const prefix = 'phone';
type Feature =
  | 'call-queues'
  | 'call-groups'
  | 'voicemail-boxes'
  | 'audio-library'
  | 'phone-trees'
  | 'call-routes'
  | 'call-route';
type Context = 'page' | 'setting';

export const trackingId = ({ context, feature, details }: { context: Context; feature: Feature; details: string }) =>
  `${prefix}::${context}::${feature}::${details}`;
