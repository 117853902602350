import { CSSProperties, useEffect } from 'react';
import { getInitialParams } from '@frontend/env';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownField, Text, TextLink, BaseFieldProps, useModalControl } from '@frontend/design-system';
import { buildAudioLibraryPath } from '../../utils/media-path';
import { useAudioQuery } from '../../utils/use-audio-query';
import { CachedAudioScrubber } from '../common/cached-audio-scrubber';
import { AudioUploadModal } from './audio-upload-modal';
import type { AudioItem } from './types';

/** This is the path we will use for default greetings */
const DEFAULT_GREETING_PATH = getInitialParams().backendApi?.includes('api.weavedev.net')
  ? 'https://api.weavedev.net/phone/audio-library/v1/system/download/305a7cd9-21ae-4430-af05-1d2a9a24dc6a'
  : 'https://api.weaveconnect.com/phone/audio-library/v1/system/download/3144d7d9-e84a-45e1-b94d-a4e209990899';
const numberToPx = (num: number | string) => (typeof num === 'number' ? `${num}px` : num);

export const AudioPicker = ({
  tenantId,
  mailboxId,
  field,
  singlePlayer = true,
  hidePlayer = false,
  allowedOptions = {
    standard: false,
    custom: true,
    mailbox: false,
    add: false,
    none: false,
    default: false,
  },
  labels,
  helperText,
  name,
  onSelect,
  widths,
  className,
}: {
  tenantId: string;
  mailboxId?: string;
  field: BaseFieldProps;
  singlePlayer?: boolean;
  hidePlayer?: boolean;
  allowedOptions?: {
    standard?: boolean;
    custom?: boolean;
    mailbox?: boolean;
    add?: boolean;
    none?: boolean;
    default?: boolean;
  };
  labels?: {
    field?: string;
    placeholder?: string;
    standard?: string;
    custom?: string;
    mailbox?: string;
    add?: string;
    none?: string;
    default?: string;
  };
  helperText?: string;
  name: string;
  /**
   * Typically the id of the selected audio item will be available in the field value.
   * However, if that is not sufficient, this callback will provide access to the selected audio item itself.
   * @param item The selected audio item
   */
  onSelect?: (item: AudioItem) => void;
  widths?: {
    field?: CSSProperties['width'];
    scrubber?: CSSProperties['width'];
  };
  className?: string;
}) => {
  const { t } = useTranslation('phone');
  const { modalProps, triggerProps } = useModalControl();
  const { data } = useAudioQuery({ tenantId, allowedOptions, mailboxId });

  useEffect(() => {
    if (onSelect && data?.allMedia) {
      const media = findMediaItem(field.value, data.allMedia);
      if (media) {
        onSelect(media);
      }
    }
  }, [field.value, data]);

  if (!data) {
    return null;
  }

  const media = findMediaItem(field.value, data.allMedia);
  const resolvedPath = media ? buildAudioLibraryPath({ media }) : '';
  const resolvedWidths = {
    field: widths?.field ?? 'auto',
    scrubber: widths?.scrubber ?? 'auto',
  };

  return (
    <>
      <div
        css={{
          display: 'grid',
          gridTemplateColumns: widths
            ? `${numberToPx(resolvedWidths.field)} ${numberToPx(resolvedWidths.scrubber)}`
            : 'repeat(auto-fit, minmax(100px, 100%))',
          gap: theme.spacing(2),
        }}
        className={`audio-picker ${className ?? ''}`}
      >
        <DropdownField
          {...field}
          name={name}
          label={labels?.field ?? (mailboxId ? t('Greeting') : t('Audio File'))}
          placeholder={labels?.placeholder ?? t('Select a file')}
          helperText={helperText}
        >
          {/* Add Option */}
          {allowedOptions.add && (
            <DropdownField.OptionGroup label=''>
              <div onClick={triggerProps.onClick}>
                <DropdownField.Option value='' isSelectable={false}>
                  <TextLink css={{ cursor: 'pointer' }} weight='bold'>
                    {labels?.add ?? t('Add Audio File')}
                  </TextLink>
                </DropdownField.Option>
              </div>
            </DropdownField.OptionGroup>
          )}

          {/* None Option */}
          {allowedOptions.none && (
            <DropdownField.Option key={'none'} value={'none'}>
              <Text color='light' weight='bold'>
                {labels?.none ?? t('No Greeting')}
              </Text>
            </DropdownField.Option>
          )}

          {/* Default Option */}
          {allowedOptions.default && (
            <DropdownField.OptionGroup label={labels?.default ?? t('Weave Media')}>
              <DropdownField.Option key={'default'} value={'default'}>
                <Text color='light' weight='bold'>
                  {labels?.default ?? t('Default Greeting')}
                </Text>
              </DropdownField.Option>
            </DropdownField.OptionGroup>
          )}

          {/* Standard Options */}
          {allowedOptions.standard && (
            <DropdownField.OptionGroup label={labels?.standard ?? t('Weave Audio')}>
              {data?.standardMedia.map((media) => (
                <DropdownField.Option key={media.id} value={media.id}>
                  {media.name}
                </DropdownField.Option>
              ))}
            </DropdownField.OptionGroup>
          )}
          {/* Voicemail Greeting options */}
          {allowedOptions.mailbox && data.mailboxGreetings.length > 0 && (
            <DropdownField.OptionGroup label={labels?.mailbox ?? t('VM Box Greetings')}>
              {data?.mailboxGreetings.map((media) => (
                <DropdownField.Option key={media.id} value={media.id}>
                  {`${media.number}: ${media.name || t('Unnamed Greeting')}`}
                </DropdownField.Option>
              ))}
            </DropdownField.OptionGroup>
          )}
          {/* Custom Options */}
          {allowedOptions.custom && (
            <DropdownField.OptionGroup label={labels?.custom ?? t('Audio Library')}>
              {data?.customMedia.map((media) => (
                <DropdownField.Option key={media.id} value={media.id}>
                  {media.name}
                </DropdownField.Option>
              ))}
            </DropdownField.OptionGroup>
          )}
        </DropdownField>
        {!hidePlayer && resolvedPath && (
          <CachedAudioScrubber
            singlePlayer={singlePlayer}
            css={{
              alignSelf: 'start',
            }}
            mediaId={field.value}
            filePath={resolvedPath}
          />
        )}
      </div>
      <AudioUploadModal
        modalProps={modalProps}
        tenantId={tenantId}
        onSuccess={(id) => {
          // @ts-ignore
          field.onChange({ name: field.name, value: id });
        }}
      />
    </>
  );
};

const findMediaItem = (mediaId: string, data: AudioItem[]) => {
  if (mediaId === 'default') {
    return {
      id: 'default',
      path: DEFAULT_GREETING_PATH,
      isGlobal: true,
      name: 'Default Greeting',
      type: 'standard' as const,
    };
  }

  return data.find((media) => media.id === mediaId);
};
