import { useState } from 'react';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  Avatar,
  BaseFieldProps,
  CaretDownIconSmall,
  Chip,
  ChipCombobox,
  IconButton,
  ListRow,
  Modal,
  MoreIcon,
  PopoverMenu,
  PopoverMenuItem,
  Text,
  TextButton,
  TextField,
  useModalControl,
  usePopoverMenu,
} from '@frontend/design-system';
import { MAX_GROUP_NAME_LENGTH } from '../constants';
import { trackingId } from '../tracking';
import { Recipient } from '../types';
import { getUserFullName } from '../utils';
import { ChatStatusIcon } from './primitives';

type RecipientsProps = {
  selectedRecipients: Recipient[];
  availableRecipients: Recipient[];
  handleRemoveRecipient: (rec: Recipient) => void;
  handleAddRecipient: (rec: Recipient) => void;
};

const MAX_MODULE_HEIGHT = 250;
const MAX_CHIPS_CONTAINER_HEIGHT = 100;

export const Recipients = ({
  selectedRecipients,
  availableRecipients,
  handleRemoveRecipient,
  handleAddRecipient,
  toggleEditGroup,
  handleUpdateGroup,
  handleLeaveGroup,
  handleDeleteGroup,
  handleCancelEditGroup,
  field,
  isEditing = false,
  mode = 'create',
  isPrivate,
  disableSave = false,
  ...rest
}: RecipientsProps & {
  isEditing?: boolean;
  mode?: 'create' | 'edit';
  isPrivate: boolean;
  field: BaseFieldProps<string>;
  toggleEditGroup: (value: boolean) => void;
  handleUpdateGroup: () => void;
  handleLeaveGroup: () => void;
  handleDeleteGroup: () => void;
  handleCancelEditGroup: () => void;
  disableSave?: boolean;
}) => {
  const { t } = useTranslation('chat');
  const Component = isEditing ? (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        maxHeight: MAX_MODULE_HEIGHT,
        overflow: 'auto',
      }}
      {...rest}
    >
      {!isPrivate && (
        <TextField
          label={t('Custom Name (optional)')}
          maxLength={MAX_GROUP_NAME_LENGTH}
          name='custom-name'
          {...field}
        />
      )}
      <RecipientEditor
        availableRecipients={availableRecipients}
        handleAddRecipient={handleAddRecipient}
        selectedRecipients={selectedRecipients}
        handleRemoveRecipient={handleRemoveRecipient}
      />
      {mode === 'edit' && (
        <div style={{ display: 'flex', justifyContent: 'end' }}>
          <TextButton css={{ width: 'auto' }} onClick={handleCancelEditGroup}>
            {t('Cancel')}
          </TextButton>
          <TextButton
            css={{ width: 'auto', color: theme.colors.primary50 }}
            onClick={handleUpdateGroup}
            trackingId={trackingId({ component: 'tab', context: 'edit-group-save' })}
            disabled={disableSave}
          >
            {t('Save')}
          </TextButton>
        </div>
      )}
    </div>
  ) : (
    <RecipientViewer
      recipients={selectedRecipients}
      toggleEditGroup={toggleEditGroup}
      isPrivate={isPrivate}
      handleLeaveGroup={handleLeaveGroup}
      handleDeleteGroup={handleDeleteGroup}
    />
  );

  return Component;
};

const RecipientViewer = ({
  recipients,
  toggleEditGroup,
  handleLeaveGroup,
  handleDeleteGroup,
  isPrivate,
}: {
  recipients: Recipient[];
  toggleEditGroup: (value: boolean) => void;
  handleLeaveGroup: () => void;
  handleDeleteGroup: () => void;
  isPrivate: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { getTriggerProps, getMenuProps, getItemProps } = usePopoverMenu({ placement: 'bottom-end' });
  const { t } = useTranslation('chat');

  const { triggerProps, modalProps, closeModal } = useModalControl();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: theme.spacing(1, 2),
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
          }}
        >
          {recipients.length === 0 ? (
            <div>
              <Text>{t("You're all alone here.")}</Text>
              <Text>{t('Add a recipient to get started.')}</Text>
            </div>
          ) : (
            <>
              {recipients.length === 1 ? (
                <Text as='span'>{t('{{count}} Recipient', { count: recipients.length })}</Text>
              ) : (
                <Text as='span'>{t('{{count}} Recipients', { count: recipients.length })}</Text>
              )}
              <IconButton
                size='small'
                label='expand'
                onClick={() => {
                  setIsExpanded((prev) => !prev);
                }}
              >
                <CaretDownIconSmall
                  css={{
                    transform: isExpanded ? 'rotate(180deg)' : 'none',
                    transition: `transform 250ms ease-in-out`,
                  }}
                />
              </IconButton>
            </>
          )}
        </div>
        {!isPrivate && (
          <>
            <IconButton
              size='small'
              label={t('More actions')}
              {...getTriggerProps()}
              trackingId={trackingId({ component: 'tab', context: 'group-chat-actions' })}
            >
              <MoreIcon />
            </IconButton>
            <PopoverMenu {...getMenuProps()}>
              <PopoverMenuItem
                {...getItemProps({ index: 0, onClick: () => toggleEditGroup(true) })}
                trackingId={trackingId({ component: 'tab', context: 'edit-group' })}
              >
                {t('Edit Group')}
              </PopoverMenuItem>
              <PopoverMenuItem
                {...getItemProps({ index: 1, onClick: handleLeaveGroup })}
                trackingId={trackingId({ component: 'tab', context: 'leave-group' })}
              >
                {t('Leave Group')}
              </PopoverMenuItem>
              <PopoverMenuItem
                {...getItemProps({ index: 2, onClick: triggerProps.onClick })}
                trackingId={trackingId({ component: 'tab', context: 'delete-group' })}
              >
                {t('Delete Group')}
              </PopoverMenuItem>
            </PopoverMenu>
            <Modal {...modalProps}>
              <Modal.Header>{t('Delete Group')}</Modal.Header>
              <Modal.Body>
                <Text>
                  {t(
                    'This will remove all participants and delete the message history of the group. It will no longer appear in search or the group messages list.'
                  )}
                </Text>
              </Modal.Body>
              <Modal.Actions
                destructive
                primaryLabel={t('Delete')}
                onPrimaryClick={handleDeleteGroup}
                secondaryLabel={t('Cancel')}
                onSecondaryClick={closeModal}
              />
            </Modal>
          </>
        )}
      </div>
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            variants={{
              open: {
                height: 'auto',
                opacity: 1,
                transition: {
                  ease: 'easeInOut',
                },
              },
              closed: {
                height: 0,
                opacity: 0,
                transition: {
                  ease: 'easeInOut',
                },
              },
            }}
            initial={'closed'}
            animate={'open'}
            exit={'closed'}
            style={{
              borderTop: `1px solid ${theme.colors.neutral20}`,
            }}
          >
            {/* Adding the padding here instead of the motion.div helps with creating a smoother animation */}
            <div
              style={{
                padding: theme.spacing(2),

                maxHeight: MAX_CHIPS_CONTAINER_HEIGHT,
                overflow: 'auto',
              }}
            >
              <RecipientChips recipients={recipients} />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const RecipientEditor = ({
  selectedRecipients,
  availableRecipients,
  handleRemoveRecipient,
  handleAddRecipient,
}: RecipientsProps) => {
  return (
    <>
      <RecipientPicker
        selectedRecipients={selectedRecipients}
        availableRecipients={availableRecipients}
        handleAddRecipient={handleAddRecipient}
        handleRemoveRecipient={handleRemoveRecipient}
      />
    </>
  );
};

const MENU_ITEM_HEIGHT = 73;
const MENU_ITEM_WIDTH = 300;

const RecipientItem = ({ item }: { item: Recipient }) => {
  return (
    <ListRow as='div' css={{ gridTemplateAreas: '"lead content"' }}>
      <ListRow.Lead>
        <div style={{ position: 'relative' }}>
          <Avatar name={getUserFullName(item)}>
            {item.status?.isOnline ? <Avatar.Dot variant='success' /> : null}
          </Avatar>
        </div>
      </ListRow.Lead>
      <ListRow.Content
        css={{
          textAlign: 'initial',
          alignItems: 'center',
          position: 'relative',
        }}
      >
        <ListRow.Content.Title>{getUserFullName(item)}</ListRow.Content.Title>
        {item.status?.status && (
          <ListRow.Content.Subtitle css={{ display: 'grid', gridTemplateColumns: '24px auto', alignItems: 'center' }}>
            <span style={{ fill: theme.colors.neutral50, flexShrink: 1 }}>
              <ChatStatusIcon />
            </span>
            {item.status.status}
          </ListRow.Content.Subtitle>
        )}
      </ListRow.Content>
    </ListRow>
  );
};

const RecipientPicker = ({
  selectedRecipients,
  availableRecipients,
  handleAddRecipient,
  handleRemoveRecipient,
}: {
  selectedRecipients: Recipient[];
  availableRecipients: Recipient[];
  handleAddRecipient: (rec: Recipient) => void;
  handleRemoveRecipient: RecipientsProps['handleRemoveRecipient'];
}) => {
  return (
    <>
      <ChipCombobox<Recipient>
        clearable={false}
        options={availableRecipients}
        label=''
        name='person-search'
        accessor={(item) => getUserFullName(item)}
        itemHeight={MENU_ITEM_HEIGHT}
        itemWidth={MENU_ITEM_WIDTH}
        onOptionSelect={(item) => handleAddRecipient(item)}
        onOptionRemove={(item) => handleRemoveRecipient(item)}
        tags={selectedRecipients}
        ChipComponent={RecipientChip}
        MenuItem={RecipientItem}
      />
    </>
  );
};

const RecipientChip = ({ children, onClick }: { children?: string; onClick?: () => void }) => {
  return (
    <Chip.Person avatar={<Avatar name={children} size='xxs' />} onClick={onClick}>
      {children}
    </Chip.Person>
  );
};

const RecipientChips = ({
  recipients,
  handleRemoveRecipient,
}: {
  recipients: Recipient[];
  handleRemoveRecipient?: RecipientsProps['handleRemoveRecipient'];
}) => {
  const { t } = useTranslation('chat');
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', gap: theme.spacing(1), minHeight: theme.spacing(3) }}>
      {recipients.length ? (
        recipients.map((rec) => {
          const name = getUserFullName(rec);
          return (
            <RecipientChip
              key={name}
              /**
               * If left undefined, the chip will not have a remove button
               */
              onClick={handleRemoveRecipient && (() => handleRemoveRecipient(rec))}
            >
              {getUserFullName(rec)}
            </RecipientChip>
          );
        })
      ) : (
        <Text as='span' color='light' size='medium'>
          {t('No Recipients')}
        </Text>
      )}
    </div>
  );
};
