import { useCallRouteV1QueryUpdaters } from '../query-updaters';
import { UpdatePhoneNumbersIO } from '../types';
import { UseCallRouteV1MutationEndpointArgs, useCallRouteV1Mutation } from './use-call-route-v1-mutation';

type MutationContext<C = unknown> = {
  // Add internal mutation context here if needed (useful for optimistic updates)
  otherContext?: C;
};

/**
 * A hook that returns a mutation for the `UpdatePhoneNumbers` mutation endpoint.
 * It handles query invalidation for the relevant query endpoints internally.
 * @param options (optional) The options to pass to `useMutation`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param optimisticUpdate (optional) Whether to perform an optimistic update. Defaults to `false`.
 */
export const useUpdatePhoneNumbersMutation = <
  E = unknown,
  C = unknown,
  OtherOptions extends object = never,
  RequestOverride extends UpdatePhoneNumbersIO['input'] = UpdatePhoneNumbersIO['input']
>({
  options,
  // optimisticUpdate = false,
  ...args
}: UseCallRouteV1MutationEndpointArgs<'UpdatePhoneNumbers', E, C | undefined, OtherOptions, RequestOverride> = {}) => {
  const { updateQuery } = useCallRouteV1QueryUpdaters();

  return useCallRouteV1Mutation<'UpdatePhoneNumbers', E, MutationContext<C>, OtherOptions, RequestOverride>({
    endpointName: 'UpdatePhoneNumbers',
    ...args,
    options: {
      ...options,
      onMutate: async (request) => {
        // Implement optimistic updates here

        return {
          // Add custom context type here (if exists in `MutationContext`)
          otherContext: await options?.onMutate?.(request),
        };
      },
      onSuccess: (response, request, context) => {
        // Implement query updates or invalidation for affected queries here

        // When successfully updating a call route, update the new call route in the list of call routes
        // to reflect the updated phone numbers
        updateQuery({
          endpointName: 'List',
          queryFilters: {},
          updater: (data) => ({
            callRoutes: data.callRoutes.map((callRoute) =>
              callRoute.callRouteId === response.callRouteId
                ? { ...callRoute, phoneNumberIds: response.phoneNumberIds }
                : callRoute
            ),
          }),
        });

        return options?.onSuccess?.(response, request, context?.otherContext);
      },
      onError: (error, request, context) => {
        // Revert optimistic update here (if implemented)

        return options?.onError?.(error, request, context?.otherContext);
      },
      onSettled: (response, error, request, context) => {
        // Only pass context of type C into provided `onSettled` option
        return options?.onSettled?.(response, error, request, context?.otherContext);
      },
    },
  });
};
