import React, {
  MouseEvent,
  ReactNode,
  useEffect,
  useRef,
  useState,
  forwardRef,
  MouseEventHandler,
  RefObject,
  useCallback,
} from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import {
  GetUserStatusForTeamChatResponse,
  UserStatusType,
} from '@weave/schema-gen-ts/dist/schemas/team-chat/tc_messages.pb';
import { AnimatePresence, motion } from 'motion/react';
import { AccountManagerQueries } from '@frontend/api-account-manager';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { useAccessibilityModeEnabled } from '@frontend/accessibility';
import { AccountManagerPopupController, AccountManagerProfile } from '@frontend/account-manager-profile';
import { ActionBar } from '@frontend/action-bar';
import { PortalUser, getUser, clearLastVisitedPage } from '@frontend/auth-helpers';
import { DeviceSection } from '@frontend/calls-v2';
import { ChatTrayContents, trackingId as chatTrackingId, useChatStatusShallowStore, useStrategy } from '@frontend/chat';
import { Chips } from '@frontend/chips';
import { useDialpadManagerClient } from '@frontend/dialpad';
import { ActionBarBoundary, ErrorBoundary, MainBoundary } from '@frontend/error-boundary';
import { useFeedback } from '@frontend/feedback';
import { http } from '@frontend/fetch';
import { useTranslation, i18next } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { NotificationTray, useNotificationContext, BulkNotificationHistoryActions } from '@frontend/notifications';
import { PanelPortal, PanelHeader, PanelContent } from '@frontend/panel-engine';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { PopupBarManagerProps } from '@frontend/popup-bar';
import { breakpoints, useNavSize, useWindowWidth } from '@frontend/responsiveness';
import {
  QuickFillContent,
  SchedulePulseTrayContent,
  quickfillTrackingIds,
  useGetScheduleRequestAndAlertsCount,
} from '@frontend/schedule';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import {
  SelectableIcons,
  useFeatureFlagShallowStore,
  useMultiLocationFeature,
  useTopBarTraySelection,
} from '@frontend/shared';
import { useShell, IPCRendererCallback } from '@frontend/shell-utils';
import { useTaskTrayShallowStore } from '@frontend/task-tray';
import { useTeamChatStore } from '@frontend/team-chat';
import { TourGuideHelpers } from '@frontend/tour-guide';
import { theme } from '@frontend/theme';
import {
  Avatar,
  ContentLoader,
  getAllFocusableElements,
  HamburgerMenuIcon,
  Heading,
  NakedButton,
  NakedUl,
  Text,
  TextLink,
  useModalControl,
  useTooltip,
  WeaveLogoIcon,
  styles,
  PrimaryButton,
  Dot,
  NotificationBadge,
  usePopoverMenu,
  PopoverMenu,
  PopoverMenuItem,
  BannerNotification,
  Button,
  Tray,
  IconButton,
  XIcon,
} from '@frontend/design-system';
import { ICON_BUTTON_WIDTH, ICON_SIZE, TOP_BAR_HEIGHT } from '../constants';
import { signOut } from '../helpers/sign-out';
import { useNavTrigger } from '../layout';
import { useAppStatus } from '../providers/app-status.provider';
import { WeaveActionBar } from './action-bar';
import { DialpadWrap } from './dialpad-wrap';
import { GlobalSearchView } from './global-search';
import { DownloadBanner } from './grid-dashboard';
import { DecoratedLocationPicker } from './location-picker';
import { SoftphoneWrap } from './softphone-wrap';
import { AnimatedLogo } from './top-bar-logo';
import { useTeamChatMauReduction } from './use-team-chat-mau-reduction';
import WeaveLogoDark from './Weave-Logo-Dark.svg';

const iconButtonStyles = ({ isSelected, isMobile }: { isSelected: boolean; isMobile: boolean }) => [
  css`
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    padding: ${theme.spacing(1)};
    transition: background 250ms ease-in-out;
    border-bottom: solid 2px transparent;
    min-width: ${ICON_BUTTON_WIDTH}px;
    :hover {
      background: ${theme.colors.neutral5};
    }
    svg {
      fill: ${theme.colors.neutral90};
      color: ${theme.colors.neutral90};
    }
  `,
  isSelected &&
    css`
      background: ${isMobile ? null : theme.colors.neutral10};
      :hover {
        background: ${theme.colors.neutral5};
      }
      color: ${isMobile ? theme.colors.icon.select : theme.colors.neutral90};
      svg {
        fill: ${isMobile ? theme.colors.icon.select : theme.colors.neutral90};
        color: ${isMobile ? theme.colors.icon.select : theme.colors.neutral90};
      }
    `,

  isSelected &&
    isMobile &&
    css`
      border-bottom: solid 2px ${theme.colors.icon.select};
    `,
];

const popoverItemStyles = () => css`
  font-size: ${theme.fontSize(14)};
  height: auto;
  cursor: pointer;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const navItemStyles = ({
  isXsmall,
  isSelected,
  isMobile,
}: {
  isXsmall: boolean;
  isMobile: boolean;
  isSelected: boolean;
}) => {
  if (isXsmall) {
    return css([
      {
        transition: 'color 250ms ease-in-out',
        maxWidth: 175,
        fontSize: theme.fontSize(14),
        flexGrow: 1,
        textAlign: 'left',
      },
      styles.truncate,
    ]);
  }

  return css([
    {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      color: isSelected && isMobile ? theme.colors.icon.select : theme.colors.neutral90,
      transition: 'color 250ms ease-in-out',
      maxWidth: 175,
    },
    styles.truncate,
  ]);
};

const labelMap: Record<SelectableIcons, string> = {
  location: i18next.t('Location', { ns: 'base' }),
  multiLocations: i18next.t('Filter Locations', { ns: 'base' }),
  notifications: i18next.t('Notifications', { ns: 'base' }),
  profile: i18next.t('Profile', { ns: 'base' }),
  chat: i18next.t('Team Chat', { ns: 'base' }),
  taskTray: i18next.t('Task Tray', { ns: 'base' }),
  quickfill: i18next.t('Quick Fill', { ns: 'base' }),
  schedulePulse: i18next.t('Schedule Pulse', { ns: 'base' }),
  chatV2: i18next.t('Team Chat', { ns: 'base' }),
  actions: i18next.t('Actions', { ns: 'base' }),
} as const;

const useDetectChatStatus = ({ show, icon }: { show: boolean; icon: string }) => {
  const { setTrayStatus } = useChatStatusShallowStore('setTrayStatus');
  const trayStatus = useRef<boolean>(show);

  useEffect(() => {
    /**
     * The icon is reset to undefined after the modal closes.
     * We don't want that to re-trigger the chat status detection.
     *
     * Added a ref to compare the last vales of chat modal show attribute because
     * In case when chat tray is being closed via chat icon click, icon comes an empty string
     * leading to a case where the tray status would never be set to closed
     */
    if (icon === 'chat' || trayStatus.current !== show) {
      trayStatus.current = show;
      const chatTrayOpen = show;
      setTrayStatus(!chatTrayOpen ? 'closed' : 'open');
    }
  }, [show, icon]);
};

export const SimpleTopBar = () => {
  const { t } = useTranslation('base');
  const navigate = useNavigate();

  return (
    <header
      css={css`
        align-items: center;
        background: ${theme.colors.white};
        display: flex;
        justify-content: space-between;
        max-height: ${TOP_BAR_HEIGHT}px;
        min-height: ${TOP_BAR_HEIGHT}px;
        padding: ${theme.spacing(1)};
      `}
    >
      <img src={WeaveLogoDark} alt='weave-app-logo' css={{ height: ICON_SIZE }} data-tour-guide='portal-logo' />
      <Button
        variant='tertiary'
        onClick={() => {
          signOut().then(() => {
            clearLastVisitedPage();
            navigate({ to: '/sign-in' });
          });
        }}
      >
        {t('Sign Out')}
      </Button>
    </header>
  );
};

export const TopBar = ({
  PopupBarManager,
  ChatProviders,
}: {
  PopupBarManager: (props: PopupBarManagerProps) => React.ReactElement;
  ChatProviders: (props: { children: React.ReactNode }) => React.ReactElement;
}) => {
  // react hooks
  const shell = useShell();
  const [selectedIcon, setSelectedIcon] = useState<SelectableIcons | ''>('');
  const ulRef = useRef<HTMLUListElement>(null);
  const locationsTriggerRef = useRef<HTMLButtonElement>(null);
  const profileTriggerRef = useRef<HTMLButtonElement>(null);
  const notificationTriggerRef = useRef<HTMLButtonElement>(null);
  const quickfillTriggerRef = useRef<HTMLButtonElement>(null);
  const taskTrayTriggerRef = useRef<HTMLButtonElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  // custom hooks
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  const { t } = useTranslation();
  const user = getUser();
  const { setOpen, hamburgerButtonRef, open } = useNavTrigger();

  /**
   * I'm adding this state here because when team chat v1 is launched it causes re-mounting of everything
   * that's inside `ChatProviders`
   */
  const [isTeamChatOpen, setIsTeamChatOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [isSchedulePulseOpen, setIsSchedulePulseOpen] = useState(false);
  const [isQuickFillOpen, setIsQuickFillOpen] = useState(false);
  const { navigate: settingsNavigate, isOpen: settingsIsOpen } = useSettingsNavigate();
  const { modalProps, openModal, closeModal } = useModalControl({
    disableReturnFocus: true,
  });
  const { isEnabled: accessibilityModeEnabled } = useAccessibilityModeEnabled('isEnabled');
  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');
  const { isMultiLocationFeature } = useMultiLocationFeature();
  const { isTaskTrayOpen, toggleTaskTrayOpen } = useTaskTrayShallowStore('isTaskTrayOpen', 'toggleTaskTrayOpen');
  const { getFlag } = useFeatureFlagShallowStore('getFlag', 'flags');
  const {
    selectedLocationIdsWithParents,
    selectedLocationIds,
    accessibleLocationIds,
    getLocationName,
    accessibleLocationData,
  } = useAppScopeStore();

  const hasOnboardingTaskFeatureFlag = getFlag('portal-onboarding-tasks');

  const singleLocationReferenceName = getLocationName(selectedLocationIdsWithParents[0]);

  // other hooks
  const navigate = useNavigate();
  const navSize = useNavSize();
  const isXsmall = navSize.isLte('xsmall');
  const isMobile = navSize.isLte('mobile');

  // Note: temporary hide this as its creating strange UX, Design team working on new UX for this mobile case
  const isTaskTrayIconButtonVisible = false && hasOnboardingTaskFeatureFlag;
  let focusableElements: HTMLElement[] = [];
  const isDevEnv = localStorage.getItem('backendEnv') === 'dev';
  const isTeamChatMauReductionEnabled = getFlag('team-chat-mau-reduction');
  const { setActive } = useTeamChatMauReduction();
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();
  const windowWidth = useWindowWidth({ tolerance: 5 });
  const allowSoftphoneTrayViewInTopBar = windowWidth > 850;

  const [isActionMobileOpen, setIsActionMobileOpen] = useState(false);

  const { isTrayOpen } = useTeamChatStore(['setTrayOpen', 'isTrayOpen']);

  useLocalizedQuery({
    queryKey: ['team-chat-active'],
    queryFn: ({ queryKey }) =>
      http.get<GetUserStatusForTeamChatResponse>(`team-chat/v1/user-status?location_id=${queryKey[0]}`),
    onSuccess: (data) => {
      // if the reduction ff is on, but the user is active,
      // go ahead and init the twilio client in the background.
      if (data.userStatus === UserStatusType.ACTIVE) {
        setActive(true);
      }
    },
    // only check the user status if the reduction ff is turned on.
    // if the ff is off, we don't care about user status,
    // bc we want to init twilio no matter what
    enabled: isTeamChatMauReductionEnabled,
  });

  const handleIconClick = useCallback(
    (tab: SelectableIcons, e?: MouseEvent) => {
      // set user as active at this point

      e?.stopPropagation();
      if (tab === 'taskTray') return;

      if (tab === selectedIcon) {
        closeModal();
        setSelectedIcon('');
      } else {
        setSelectedIcon(tab);
        openModal();
      }
    },
    [selectedIcon, isTrayOpen]
  );
  const topBarTraySelectionCallback = useCallback((icon: SelectableIcons, e?: MouseEvent) => {
    e?.stopPropagation();

    switch (icon) {
      case 'schedulePulse':
        setIsSchedulePulseOpen(true);
        break;
      case 'quickfill':
        setIsQuickFillOpen(true);
        break;
      default:
        console.error("TopBarTraySelectionEvent doesn't support this icon", { icon });
    }
  }, []);

  useTopBarTraySelection({ callback: topBarTraySelectionCallback });

  const handleTaskTrayIconClick = (e: MouseEvent) => {
    e.stopPropagation();
    if (modalProps.show) {
      closeModal();
    }

    toggleTaskTrayOpen('taskTray' !== selectedIcon);
    setSelectedIcon('taskTray');
  };

  const handleClose = useCallback(() => {
    setIsNotificationsOpen(false);
  }, []);

  // close location selection modal if its already open and locationData get changes.
  useEffect(() => {
    if (modalProps.show) {
      closeModal();
      setSelectedIcon('');
    }
  }, [selectedLocationIdsWithParents]);

  // When new team chat closes with the cross button the selectedIcon state does not updates here as the component
  // does not have access ot it. So, we need to update the selectedIcon state here.
  // useEffect(() => {
  //   if (!isTrayOpen && selectedIcon === 'chatV2') {
  //     setSelectedIcon('');
  //   }
  // }, [isTrayOpen, selectedIcon]);

  useEffect(() => {
    if (isTaskTrayIconButtonVisible && isTaskTrayOpen && !selectedIcon) {
      // if task tray opened & task tray icon button visible but its not selected then set as selected
      // this will happen for case when screen size get changed from desktop to mobile
      setSelectedIcon('taskTray');
    } else if (!isTaskTrayIconButtonVisible && selectedIcon === 'taskTray') {
      // if task tray icon button got hidden then unselect task tray icon button if its already selected
      // this will happen for case when screen size get changed from mobile to desktop
      setSelectedIcon('');
    } else if (isTaskTrayIconButtonVisible && !isTaskTrayOpen && selectedIcon === 'taskTray') {
      // if task tray panel get closed & task tray button visible then unselect task tray icon and focus on task tray button
      // this will happen for case when user click on task tray panel close button
      taskTrayTriggerRef?.current?.focus();
      setSelectedIcon('');
    }
  }, [isTaskTrayIconButtonVisible, isTaskTrayOpen]);

  useEffect(() => {
    // don't use useState for focusableElements because it's async, and we need to get
    // the first focusable element and focus it all in the same lifecycle
    if (containerRef.current) {
      focusableElements = getAllFocusableElements(containerRef.current);
    }
    if (focusableElements.length) {
      focusableElements?.[0].focus();
    }
  }, [containerRef.current, modalProps.show, selectedIcon]);

  useEffect(() => {
    // send focus back to trigger element when modal is closed
    if (!modalProps.show) {
      // this resets the selectedIcon if the user clicks outside of the tray to close it
      if (selectedIcon === 'taskTray') return;

      if (selectedIcon === 'location') locationsTriggerRef.current?.focus();
      if (selectedIcon === 'profile') profileTriggerRef.current?.focus();
      if (selectedIcon === 'notifications') notificationTriggerRef.current?.focus();
      setSelectedIcon('');
    }
  }, [modalProps.show]);

  const closeSchedulePulse = useCallback(() => setIsSchedulePulseOpen(false), []);
  const closeQuickFill = useCallback(() => setIsQuickFillOpen(false), []);

  /**
   * Decide if "actions" item is selected, based on menu open state.
   * It can be closed elsewhere, so...
   */
  useEffect(() => {
    if (isActionMobileOpen && selectedIcon !== 'actions') {
      setSelectedIcon('actions');
    } else if (!isActionMobileOpen && selectedIcon === 'actions') {
      setSelectedIcon('');
    }
  }, [isActionMobileOpen]);

  /**
   * Close action menu if another nav icon is selected
   */
  useEffect(() => {
    if (selectedIcon !== 'actions') {
      setIsActionMobileOpen(false);
    }
  }, [selectedIcon]);

  useDetectChatStatus({ show: isTeamChatOpen, icon: isTeamChatOpen ? 'chat' : '' });

  const [nextVersion, setNextVersion] = useState<string>();

  //TODO: this is a legacy way of subscribing to an update-available message. Now we're going to ask ipc ourselves and listen
  //After the next release of 2.0.15 we can remove this whole useEffect
  useEffect(() => {
    if (!shell?.isShell) {
      return;
    }
    const id = 'update:new-version-available' + Math.random();
    const cb: IPCRendererCallback<'update:new-version-available'> = (_e, payload) => {
      const version = payload?.version;
      setNextVersion(version);
    };
    shell.on?.('update:new-version-available', cb, id);
    return () => {
      shell.off?.('update:new-version-available', cb, id);
    };
  }, [shell.isShell]);

  //This is the new way to request the version. This will make sure we don't miss the event if it's already happened
  //right now we're doing it on load, but we could put this on a timer to check every x minutes/hours
  useEffect(() => {
    if (!shell.isShell) {
      return;
    }
    const cb: IPCRendererCallback<'info:update-available'> = (_e, payload) => {
      const version = payload?.version;
      if (version) {
        setNextVersion(version);
      }
    };
    const id = 'info:update-available' + Math.random();
    shell.emit?.('info:update-available', undefined);
    shell.on?.('info:update-available', cb, id);
    return () => {
      shell.off?.('info:update-available', cb, id);
    };
  }, [shell.isShell]);

  const handleOpenProfilePanel = () => {
    setSelectedIcon('profile');
    openModal();
  };

  const isSoftphoneDeviceConnected = useDialpadManagerClient((ctx) => ctx?.isSoftphoneDeviceConnected);
  const shouldDisallowPicker = accessibleLocationIds.length === 1 && user?.type !== 'weave';

  const verticalAnimationRef = useRef({ hasShown: false });

  useEffect(() => {
    // reset hasShown if the user changes location
    verticalAnimationRef.current.hasShown = false;
  }, [singleLocationReferenceName]);

  const hasShellThemeEnabled = shell?.isShell && shell?.featureAvailability?.has('shell-theme');

  const showHamburgerIcon = isXsmall;

  const locationText = (
    <Text
      css={navItemStyles({
        isXsmall,
        isMobile,
        isSelected: 'location' === selectedIcon,
      })}
    >
      {isMultiLocationFeature && selectedLocationIds.length > 1
        ? `${selectedLocationIds.length} Locations`
        : singleLocationReferenceName || t('No location selected')}
    </Text>
  );

  const notificationsButton = (
    <NotificationButtonWrapper
      isTrayOpen={isNotificationsOpen}
      isMobile={isMobile}
      onClick={() => setIsNotificationsOpen((prev) => !prev)}
      notificationTriggerRef={notificationTriggerRef}
      css={css([
        iconButtonStyles({
          isSelected: isNotificationsOpen,
          isMobile,
        }),
      ])}
    />
  );

  const {
    getTriggerProps: getMiniPopoverTriggerProps,
    getMenuProps: getMiniPopverMenuProps,
    getItemProps: getMinPopverItemProps,
    close: closeMiniPopover,
    isOpen: isMiniPopoverOpen,
  } = usePopoverMenu({
    placement: 'bottom-end',
  });

  return (
    <>
      <header
        css={css`
          align-items: center;
          background: ${theme.colors.white};
          display: flex;
          justify-content: space-between;
          max-height: ${TOP_BAR_HEIGHT}px;
          min-height: ${TOP_BAR_HEIGHT}px;
        `}
        onClick={() => {
          if (modalProps.show) {
            closeModal();
          }
        }}
      >
        <section
          className='top-bar__section'
          css={css`
            align-items: center;
            color: ${theme.colors.white};
            display: flex;
            height: 100%;
            width: 100%;
            column-gap: ${theme.spacing(1)};

            @media (max-width: ${breakpoints.xsmall.max}px) {
              column-gap: 0;
              justify-content: space-between;

              & > * {
                flex-grow: 0;
                height: 100%;
              }

              & > .top-bar__ul {
                flex-grow: 2;
                justify-content: space-between;
              }
            }
          `}
        >
          {showHamburgerIcon && (
            <div>
              <NakedButton
                onClick={() => setOpen((prev) => !prev)}
                ref={hamburgerButtonRef}
                css={[
                  css`
                    align-items: center;
                    color: ${theme.colors.white};
                    display: flex;
                    font-size: ${theme.fontSize(24)};
                    font-weight: bold;
                    height: 100%;
                    justify-content: center;
                    padding: ${theme.spacing(1)};
                    transition: background 250ms ease-in-out;
                    width: ${ICON_BUTTON_WIDTH}px;
                    :hover {
                      background: ${theme.colors.neutral5};
                    }
                  `,
                  iconButtonStyles({
                    isSelected: open,
                    isMobile,
                  }),
                ]}
              >
                <HamburgerMenuIcon color={'default'} size={ICON_SIZE} />
              </NakedButton>
            </div>
          )}

          {(navSize.isGt('mobile') || !isXsmall) && (
            <NakedButton
              css={css`
                align-items: center;
                color: ${theme.colors.white};
                display: flex;
                font-size: ${theme.fontSize(24)};
                padding: ${theme.spacing(0, 1)};
              `}
              onClick={() => navigate({ to: '/home/dashboard' })}
            >
              {navSize.isGte('mobile') && (
                <>
                  {verticalAnimationRef.current.hasShown ? (
                    <img
                      src={WeaveLogoDark}
                      alt='weave-app-logo'
                      css={{ height: ICON_SIZE }}
                      data-tour-guide='portal-logo'
                    />
                  ) : (
                    <AnimatedLogo
                      vertical={accessibleLocationData?.[selectedLocationIds?.[0]]?.vertical}
                      onComplete={() => {
                        verticalAnimationRef.current.hasShown = true;
                      }}
                    />
                  )}
                </>
              )}

              {navSize.isLt('mobile') && !isXsmall && (
                <WeaveLogoIcon color={'default'} size={ICON_SIZE} data-tour-guide='portal-logo' />
              )}
            </NakedButton>
          )}
          <ErrorBoundary fallback={ActionBarBoundary}>
            {hasPhoneSystemAccess && <Dialpad isSoftphone={isSoftphoneDeviceConnected} />}
          </ErrorBoundary>
          {!hasShellThemeEnabled && (
            <ErrorBoundary fallback={ActionBarBoundary}>
              <GlobalSearchView
                css={css`
                  /**
                 * Top bar / NWX styling here to match other icons.
                 *
                 * Not putting this specific styling in actual component,
                 * as it only applies to the top bar.
                 */
                  @media (min-width: ${breakpoints.large.min}px) {
                    margin: 0 auto;
                  }

                  @media (max-width: ${breakpoints.mobile.max}px) and (min-width: ${breakpoints.small.min}px) {
                    margin-left: auto;
                  }

                  .global-search__trigger {
                    border-bottom: solid 2px transparent;
                  }
                  .global-search__trigger--open,
                  .global-search__trigger--open:hover {
                    border-bottom: solid 2px ${theme.colors.icon.select};
                    :hover {
                      background: ${theme.colors.neutral5};
                    }
                    svg {
                      fill: ${theme.colors.icon.select};
                      color: ${theme.colors.icon.select};
                    }
                  }
                `}
              />
            </ErrorBoundary>
          )}

          {!isXsmall && (
            <NakedUl
              className='top-bar__ul'
              css={css([
                {
                  display: 'flex',
                  height: '100%',
                },
                hasShellThemeEnabled &&
                  !isXsmall &&
                  css`
                    margin-left: auto;
                  `,
              ])}
              ref={ulRef}
            >
              {isDevEnv && (
                <li>
                  <Text
                    css={{
                      alignItems: 'center',
                      background: theme.colors.secondary.eggplant50,
                      color: 'white',
                      display: 'flex',
                      fontWeight: theme.font.weight.bold,
                      height: '100%',
                      padding: theme.spacing(2),
                    }}
                    {...triggerProps}
                  >
                    DEV
                  </Text>
                  <Tooltip {...tooltipProps}>
                    <Text color='white'>Using DEV endpoints.</Text>
                    <Text color='white'>
                      Use{' '}
                      <Text as='code' style={{ color: theme.colors.primary20 }}>
                        ctrl + shift + e
                      </Text>{' '}
                      to open the endpoint switcher.
                    </Text>
                  </Tooltip>
                </li>
              )}

              <li>
                <NakedButton
                  css={[
                    iconButtonStyles({
                      isSelected: 'location' === selectedIcon,
                      isMobile,
                    }),

                    shouldDisallowPicker &&
                      css`
                        :hover {
                          background: inherit;
                        }
                        pointer-events: none;
                      `,
                  ]}
                  onClick={(e) => {
                    if (!shouldDisallowPicker) {
                      handleIconClick('location', e);
                    }
                  }}
                  ref={locationsTriggerRef}
                  data-test-id='nav-location-picker-button'
                  trackingId='nav-location-picker-button'
                >
                  {navSize.isGt('small') && locationText}
                  <Icon name='location' />
                </NakedButton>
              </li>
              <li css={{ position: 'relative' }}>{notificationsButton}</li>

              {!isShowIntakeForm && (
                <li>
                  <NakedButton
                    css={iconButtonStyles({
                      isSelected: settingsIsOpen,
                      isMobile,
                    })}
                    onClick={() => {
                      settingsNavigate({ to: '/organization' });
                    }}
                    trackingId='top-bar-button-settings-2.0'
                  >
                    <Icon name='settings' />
                  </NakedButton>
                </li>
              )}

              <li>
                <NakedButton
                  css={[
                    iconButtonStyles({ isSelected: 'profile' === selectedIcon, isMobile }),
                    css`
                      position: relative;
                    `,
                  ]}
                  onClick={(e) => {
                    handleIconClick('profile', e);
                  }}
                  ref={profileTriggerRef}
                  trackingId='top-bar-profile-tray-trigger'
                >
                  <Avatar isUser name={user?.firstName + ' ' + user?.lastName} size='xs' disableClick>
                    {(nextVersion || accessibilityModeEnabled) && (
                      <Dot
                        color='critical'
                        css={{
                          width: theme.spacing(1.5),
                          height: theme.spacing(1.5),
                          top: theme.spacing(-0.75),
                          right: theme.spacing(-0.5),
                          border: `1.5px solid ${theme.colors.white}`,
                        }}
                      />
                    )}
                  </Avatar>
                </NakedButton>
              </li>

              {isTaskTrayIconButtonVisible && (
                <li>
                  <NakedButton
                    id='top-nav-task-tray-button'
                    css={iconButtonStyles({ isSelected: selectedIcon === 'taskTray', isMobile })}
                    onClick={handleTaskTrayIconClick}
                    ref={taskTrayTriggerRef}
                  >
                    <Icon name='list' />
                  </NakedButton>
                </li>
              )}
            </NakedUl>
          )}
          {isXsmall && (
            <>
              <div style={{ position: 'relative' }}>{notificationsButton}</div>
              <div>
                <NakedButton
                  {...getMiniPopoverTriggerProps({
                    onClick: () => {
                      setSelectedIcon('');
                      closeModal();
                    },
                  })}
                  css={[
                    iconButtonStyles({
                      isSelected: selectedIcon === 'profile' || isMiniPopoverOpen,
                      isMobile,
                    }),
                    css`
                      position: relative;
                    `,
                  ]}
                  trackingId='top-bar-profile-dropdown'
                >
                  <Avatar isUser name={user?.firstName + ' ' + user?.lastName} size='xs' disableClick>
                    {(nextVersion || accessibilityModeEnabled) && (
                      <Dot
                        color='critical'
                        css={{
                          width: theme.spacing(1.5),
                          height: theme.spacing(1.5),
                          top: theme.spacing(-0.75),
                          right: theme.spacing(-0.5),
                          border: `1.5px solid ${theme.colors.white}`,
                        }}
                      />
                    )}
                  </Avatar>
                  <div
                    css={css`
                      position: absolute;
                      top: 50%;
                      right: 4px;
                      transform: translateY(-50%);
                    `}
                  >
                    <Icon
                      className='mini-display__icon'
                      css={css`
                        transition: transform 0.3s ease-out;
                        transform: rotate(${isMiniPopoverOpen ? '180deg' : '0deg'});
                      `}
                      size={8}
                      name='alt-caret-down-tiny'
                    />
                  </div>
                </NakedButton>
                <PopoverMenu
                  {...getMiniPopverMenuProps()}
                  css={css`
                    width: 212px;
                  `}
                >
                  <PopoverMenuItem
                    className='mini-popover__item'
                    css={popoverItemStyles()}
                    {...getMinPopverItemProps({ index: 0 })}
                    Icon={() => <Icon size={16} name='user' />}
                    onClick={(e) => {
                      handleIconClick('profile', e);
                      closeMiniPopover();
                    }}
                    trackingId='top-bar-profile-dropdown--profile'
                  >
                    <span>{t('My profile')}</span>
                  </PopoverMenuItem>
                  <PopoverMenuItem
                    className='mini-popover__item'
                    css={popoverItemStyles()}
                    {...getMinPopverItemProps({ index: 1 })}
                    Icon={() => <Icon size={16} name='location' />}
                    onClick={(e) => {
                      handleIconClick('location', e);
                      closeMiniPopover();
                    }}
                    trackingId='top-bar-profile-dropdown--location'
                  >
                    {locationText}
                  </PopoverMenuItem>

                  {!isShowIntakeForm && (
                    <PopoverMenuItem
                      className='mini-popover__item'
                      css={popoverItemStyles()}
                      {...getMinPopverItemProps({ index: 0 })}
                      Icon={() => <Icon size={16} name='settings' />}
                      onClick={() => {
                        settingsNavigate({ to: '/organization' });
                        closeMiniPopover();
                      }}
                      trackingId='top-bar-profile-dropdown--settings'
                    >
                      <span>{t('Settings')}</span>
                    </PopoverMenuItem>
                  )}
                </PopoverMenu>
              </div>
              <motion.div
                transition={{
                  type: 'tween',
                }}
                initial={{ x: 100 }}
                animate={{ x: 0 }}
              >
                <NakedButton
                  onClick={() => {
                    if (selectedIcon === 'actions' || isActionMobileOpen) {
                      setIsActionMobileOpen(false);
                    } else {
                      setIsActionMobileOpen(true);
                    }
                    closeModal();
                  }}
                  css={[iconButtonStyles({ isSelected: 'actions' === selectedIcon, isMobile })]}
                  trackingId='top-bar-actions-open-close'
                >
                  <Icon name='sidebar-right' />
                </NakedButton>
              </motion.div>
            </>
          )}
        </section>
      </header>
      {!allowSoftphoneTrayViewInTopBar && hasPhoneSystemAccess && (
        <>
          <SoftphoneWrap allowTrayView={true} allowButtonView={false} allowPopout={true} />
        </>
      )}
      {/* We want the chat provider to be inside the Tray, so a change in the chat provider doesn't trigger a Tray rerender
          However, the chat provider has to also wrap the PopupbarManager, which can't be inside of the side modal,
          because it can't conditionally render with the Tray.
          Meaning it has to be outside of the side modal. That is why the Tray flashes when we get the chat strategy.
        */}
      <ChatProviders>
        {!isShowIntakeForm && (
          <ActionBarContent
            isActionMobileOpen={isActionMobileOpen}
            setIsActionMobileOpen={setIsActionMobileOpen}
            isTeamChatOpen={isTeamChatOpen}
            setIsTeamChatOpen={setIsTeamChatOpen}
            isQuickFillOpen={isQuickFillOpen}
            setIsQuickFillOpen={setIsQuickFillOpen}
            isSchedulePulseOpen={isSchedulePulseOpen}
            setIsSchedulePulseOpen={setIsSchedulePulseOpen}
            isMultiLocationFeature={isMultiLocationFeature}
            singleLocationReferenceName={singleLocationReferenceName}
            quickfillTriggerRef={quickfillTriggerRef}
            setActive={setActive}
          />
        )}
        <AnimatePresence>
          <Tray
            mountTarget='#app-container'
            width='medium'
            autoFocusTimeout={3600000 * 2} // prevent autofocusing the modal container for 2hrs
            css={{
              padding: 0,
              borderTop: `1px solid ${theme.colors.neutral20}`,
            }}
            {...modalProps}
          >
            <ErrorBoundary fallback={MainBoundary}>
              {/* chatv2 uses a different header */}
              {selectedIcon !== 'chat' && (
                <PaddedContent css={{ paddingBottom: 0 }}>
                  <Tray.Header
                    Buttons={
                      <>
                        <IconButton label='close' onClick={() => closeModal()}>
                          <XIcon />
                        </IconButton>
                      </>
                    }
                  >
                    <Heading
                      level={isMultiLocationFeature ? 2 : 1}
                      css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
                      data-testid='tray-header'
                    >
                      {(selectedIcon !== 'location' || !isMultiLocationFeature) &&
                        selectedIcon &&
                        labelMap[selectedIcon]}
                      {isMultiLocationFeature && selectedIcon === 'location' && labelMap['multiLocations']}
                    </Heading>
                  </Tray.Header>
                </PaddedContent>
              )}
              <div
                ref={containerRef}
                css={css`
                  display: flex;
                  flex-direction: column;
                  position: relative;
                  overflow: auto;
                  flex-grow: 1;
                `}
              >
                {selectedIcon === 'location' && (
                  <PaddedContent css={{ height: '100%', paddingTop: 0 }}>
                    <DecoratedLocationPicker closeModal={closeModal} />
                  </PaddedContent>
                )}
                {selectedIcon === 'profile' && (
                  <Profile
                    user={user}
                    closeModal={closeModal}
                    nextVersion={nextVersion}
                    handleOpenProfilePanel={handleOpenProfilePanel}
                  />
                )}
              </div>
            </ErrorBoundary>
          </Tray>
        </AnimatePresence>
        {isNotificationsOpen && (
          <NotificationsPanel
            isMultiLocationFeature={isMultiLocationFeature}
            onClose={handleClose}
            onLocationTrayOpen={(e) => {
              handleIconClick('location', e);
              setIsTeamChatOpen(false);
            }}
          />
        )}
        <PopupBarManager />
      </ChatProviders>
      {isSchedulePulseOpen && (
        <SchedulePulsePanel onClose={closeSchedulePulse} isMultiLocationFeature={isMultiLocationFeature} />
      )}
      {isQuickFillOpen && <QuickFillPanel onClose={closeQuickFill} isMultiLocationFeature={isMultiLocationFeature} />}
    </>
  );
};

interface ActionBarContentProps {
  isActionMobileOpen: boolean;
  setIsActionMobileOpen: (value: boolean) => void;
  isTeamChatOpen: boolean;
  setIsTeamChatOpen: (value: React.SetStateAction<boolean>) => void;
  isQuickFillOpen: boolean;
  setIsQuickFillOpen: (value: React.SetStateAction<boolean>) => void;
  isSchedulePulseOpen: boolean;
  setIsSchedulePulseOpen: (value: React.SetStateAction<boolean>) => void;
  isMultiLocationFeature: boolean;
  singleLocationReferenceName: string;
  quickfillTriggerRef: React.RefObject<HTMLButtonElement>;
  setActive: (value: boolean) => void;
}

export const ActionBarContent: React.FC<ActionBarContentProps> = ({
  isActionMobileOpen,
  setIsActionMobileOpen,
  isTeamChatOpen,
  setIsTeamChatOpen,
  isQuickFillOpen,
  setIsQuickFillOpen,
  isSchedulePulseOpen,
  setIsSchedulePulseOpen,
  isMultiLocationFeature,
  singleLocationReferenceName,
  quickfillTriggerRef,
  setActive,
}) => {
  const { scheduleAlertsCount, scheduleRequestCount } = useGetScheduleRequestAndAlertsCount();
  const { flagValues } = useFeatureFlagShallowStore('flagValues');

  const isChatEnabled = flagValues['new-chat-experience'];
  const isTeamChatV2Enabled = flagValues['team-chat-2.0'];

  return (
    <WeaveActionBar isMobileOpen={isActionMobileOpen} setIsMobileOpen={setIsActionMobileOpen}>
      <ErrorBoundary fallback={ActionBarBoundary}>
        {isChatEnabled && (
          <>
            {isTeamChatV2Enabled ? (
              <ChatV2Button />
            ) : (
              <ChatButton
                isActive={isTeamChatOpen}
                onClick={() => {
                  if (!isTeamChatOpen) {
                    setActive(true);
                  }
                  setIsTeamChatOpen((prev) => !prev);
                }}
                isMultiLocationFeature={isMultiLocationFeature}
                singleLocationReferenceName={singleLocationReferenceName}
              />
            )}
          </>
        )}
      </ErrorBoundary>
      <ErrorBoundary fallback={ActionBarBoundary}>
        <QuickfillButton
          isActive={isQuickFillOpen}
          onClick={() => setIsQuickFillOpen((prev) => !prev)}
          ref={quickfillTriggerRef}
        />
      </ErrorBoundary>
      <ErrorBoundary fallback={ActionBarBoundary}>
        <SchedulePulseButton
          isActive={isSchedulePulseOpen}
          onClick={() => {
            setIsSchedulePulseOpen((prev) => !prev);
          }}
          hasDot={!!scheduleAlertsCount || !!scheduleRequestCount?.total}
        />
      </ErrorBoundary>
    </WeaveActionBar>
  );
};

const PaddedContent = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div css={{ padding: theme.spacing(3), display: 'flex', flexDirection: 'column' }} className={className}>
      {children}
    </div>
  );
};

const NotificationButtonWrapper = ({
  isTrayOpen,
  onClick,
  notificationTriggerRef,
  isMobile,
}: {
  isTrayOpen: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  notificationTriggerRef: RefObject<HTMLButtonElement>;
  isMobile: boolean;
}) => {
  const { unreadCount, setNotificationTrayIsOpen } = useNotificationContext();
  const [isHovering, setIsHovering] = useState(false);
  const shell = useShell();

  const newNotificationCountExact = unreadCount ? unreadCount : 0;

  useEffect(() => {
    if (shell.isShell) return;
    // if the notification tray is open and the app is running in the web,
    // disable ephemeral notifications
    const notificationTrayIsOpen = isTrayOpen;
    setNotificationTrayIsOpen(notificationTrayIsOpen);
  }, [isTrayOpen]);

  return (
    <span onMouseEnter={() => setIsHovering(true)} onMouseLeave={() => setIsHovering(false)}>
      <NotificationButton
        count={newNotificationCountExact}
        showFullCount={isTrayOpen || isHovering}
        onClick={onClick}
        notificationTriggerRef={notificationTriggerRef}
        css={css([
          iconButtonStyles({
            isSelected: isTrayOpen,
            isMobile,
          }),
        ])}
      />
    </span>
  );
};

const Dialpad = ({ isSoftphone }: { isSoftphone: boolean }) => {
  const windowWidth = useWindowWidth({ tolerance: 5 });
  const allowSoftphoneTrayViewInTopBar = windowWidth > 850;
  if (isSoftphone) {
    return (
      <SoftphoneWrap
        allowTrayView={allowSoftphoneTrayViewInTopBar}
        allowButtonView={true}
        allowPopout={allowSoftphoneTrayViewInTopBar}
      />
    );
  }

  return <DialpadWrap />;
};

const NotificationButton = ({
  count,
  showFullCount,
  onClick,
  notificationTriggerRef,
  className,
}: {
  count: number;
  showFullCount: boolean;
  onClick: MouseEventHandler<HTMLButtonElement>;
  notificationTriggerRef: RefObject<HTMLButtonElement>;
  className?: string;
}) => {
  return (
    <>
      {count ? (
        <NotificationBadge
          css={css`
            position: absolute;
            right: 8px;
            top: 12px;
            z-index: 0;
          `}
        >
          {count > 99 ? <motion.span layout='position'>{showFullCount ? count : '99+'}</motion.span> : <>{count}</>}
        </NotificationBadge>
      ) : null}
      <NakedButton
        css={className}
        onClick={onClick}
        data-testid='notification-tray-trigger'
        trackingId='notify-2.0-tray-trigger'
        ref={notificationTriggerRef}
      >
        <Icon name='notification' />
      </NakedButton>
    </>
  );
};

const ChatButton = forwardRef<
  HTMLButtonElement,
  {
    onClick: (e?: MouseEvent<HTMLButtonElement>) => void;
    isActive?: boolean;
    isMultiLocationFeature: boolean;
    singleLocationReferenceName: string;
  }
>(({ onClick, isActive: isTeamChatOpen, isMultiLocationFeature, singleLocationReferenceName, ...rest }, ref) => {
  const { t } = useTranslation('base');
  const user = getUser();
  const { useUnreadCount } = useStrategy('chat');
  const unreadCount = useUnreadCount();

  return (
    <>
      <ActionBar.Action
        label={t('Team Chat')}
        id='team-chat'
        icon='chat'
        onClick={onClick}
        isActive={isTeamChatOpen}
        trackingId={chatTrackingId({ component: 'tray', context: 'trigger' })}
        ref={ref}
        hasCount
        hasDot={unreadCount > 0}
        {...rest}
      />
      {isTeamChatOpen && (
        <PanelPortal id='teamchat' width='medium' onClose={() => onClick?.()}>
          <PanelHeader
            css={css`
              padding-bottom: 0;
              margin-bottom: ${theme.spacing(2)};
            `}
          >
            <div>
              <Heading
                level={isMultiLocationFeature ? 2 : 1}
                css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
                data-testid='tray-header'
              >
                {labelMap['chat']}
                <Chips.LocationChip css={{ fontWeight: theme.font.weight.regular }}>
                  {singleLocationReferenceName}
                </Chips.LocationChip>
              </Heading>
            </div>
            <PanelHeader.Close iconName='x' size='large' />
          </PanelHeader>
          <ChatTrayContents user={user ?? { userID: '' }} />
        </PanelPortal>
      )}
    </>
  );
});
ChatButton.displayName = 'ChatButton';

const ChatV2Button = () => {
  const { t } = useTranslation('base');
  const { totalUnreadCount, isTrayOpen, unreadMessageCount, setTrayOpen, setIsChatListExpanded } = useTeamChatStore([
    'totalUnreadCount',
    'isTrayOpen',
    'unreadMessageCount',
    'setTrayOpen',
    'setIsChatListExpanded',
  ]);

  return (
    <ActionBar.Action
      label={t('Team Chat')}
      id='team-chat-v2'
      icon='chat'
      onClick={() => {
        setTrayOpen(!isTrayOpen);
        if (!isTrayOpen) {
          setIsChatListExpanded(false);
        }
      }}
      trackingId={'chat-v2-tray-trigger'} // TODO: update tracking id later
      hasCount
      hasDot={!!(totalUnreadCount > 0 || unreadMessageCount > 0)}
      isActive={isTrayOpen}
    />
  );
};
ChatV2Button.displayName = 'ChatV2Button';

const QuickfillButton = forwardRef<
  HTMLButtonElement,
  { onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean }
>(({ onClick, isActive, ...rest }, ref) => {
  const { t } = useTranslation('base');

  return (
    <ActionBar.Action
      label={t('Quick Fill')}
      id='quickfill'
      icon='quickfill'
      isActive={isActive}
      onClick={onClick}
      trackingId={quickfillTrackingIds.quickfillEventPanelButton}
      ref={ref}
      {...rest}
    />
  );
});
QuickfillButton.displayName = 'QuickfillButton';

const SchedulePulseButton = forwardRef<
  HTMLButtonElement,
  { onClick: MouseEventHandler<HTMLButtonElement>; isActive?: boolean; hasDot?: boolean }
>(({ onClick, isActive, hasDot, ...rest }, ref) => {
  const { t } = useTranslation('base');

  return (
    <ActionBar.Action
      label={t('Schedule Pulse')}
      id='schedule-action'
      icon='schedule-pulse'
      isActive={isActive}
      onClick={onClick}
      trackingId={quickfillTrackingIds.quickfillEventPanelButton}
      ref={ref}
      hasDot={hasDot}
      {...rest}
    />
  );
});

SchedulePulseButton.displayName = 'SchedulePulseButton';

type ProfileSectionProps = {
  user: PortalUser | undefined;
  nextVersion: string | undefined;
  closeModal: () => void;
  handleOpenProfilePanel: () => void;
};
const Profile = ({ user, closeModal, nextVersion, handleOpenProfilePanel }: ProfileSectionProps) => {
  const shell = useShell();
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <div style={{ height: '100%', display: 'grid', gridTemplateRows: 'min-content 1fr min-content' }}>
        <Section css={{ display: 'flex', gap: theme.spacing(2), flexDirection: 'column' }}>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div
              css={{
                display: 'grid',
                alignSelf: 'start',
                gridTemplateAreas: '"avatar name" "avatar username"',
                gridTemplateColumns: 'auto 1fr',
                columnGap: theme.spacing(2),
              }}
            >
              <Avatar
                isUser
                css={{ gridArea: 'avatar' }}
                disableClick
                name={user?.firstName + ' ' + user?.lastName}
                size='xl'
              />
              <Heading level={2} css={{ gridArea: 'name' }}>
                {user?.firstName + ' ' + user?.lastName}
              </Heading>
              <Text color='light' css={{ gridArea: 'username' }}>
                {user?.username}
              </Text>
            </div>
            <TextLink
              size='medium'
              weight='bold'
              onClick={() => {
                setIsLoading(true);
                closeModal();
                signOut().then(() => {
                  TourGuideHelpers.tourGuideLocalStorageHelper.clearInfo();
                  setIsLoading(false);
                  clearLastVisitedPage();
                  navigate({ to: '/sign-in' });
                });
              }}
            >
              {t('Sign Out')}
            </TextLink>
          </div>
          {nextVersion && <VersionSection nextVersion={nextVersion} />}
        </Section>
        {/* Mid Section */}
        <Section
          css={{
            padding: 0,
            display: 'flex',
            flexDirection: 'column',
            '> :last-child': {
              flexGrow: 1,
            },
          }}
        >
          <Section>{hasPhoneSystemAccess && <DeviceSection closeModal={closeModal} />}</Section>
          <HealthStatusSection />
        </Section>
        {/* Bottom Section */}
        <Section
          render={(setShouldHide) => (
            <AccountManagerSection
              handleOpenProfilePanel={handleOpenProfilePanel}
              hideSection={() => setShouldHide(true)}
            />
          )}
        ></Section>
        {!shell.isShell && (
          <Section>
            <DownloadBanner actionLabel={t('Download Desktop App', { ns: 'dashboard' })} type='download-permanent'>
              {t('Get faster performance, more features, native notifications, and enhanced personalization!', {
                ns: 'dashboard',
              })}
            </DownloadBanner>
          </Section>
        )}
        <LinkSection closeModal={closeModal} isLoading={isLoading} />
      </div>
    </>
  );
};

const AccountManagerSection = ({
  handleOpenProfilePanel,
  hideSection,
}: {
  handleOpenProfilePanel: () => void;
  hideSection: () => void;
}) => {
  const { data: accountManagerData } = AccountManagerQueries.useAccountManagerDataQuery();

  useEffect(() => {
    if (!accountManagerData) {
      hideSection();
    }
  }, [accountManagerData]);

  return (
    accountManagerData && (
      <>
        <AccountManagerProfile accountManagerData={accountManagerData} />
        <AccountManagerPopupController onShowPopup={handleOpenProfilePanel} />
      </>
    )
  );
};

type VersionSectionProps = {
  nextVersion: string | undefined;
};
const VersionSection = ({ nextVersion }: VersionSectionProps) => {
  const { t } = useTranslation('base');
  const shell = useShell();
  const onUpdateClick = () => {
    if (nextVersion) {
      shell?.emit?.('restart', undefined);
    }
  };

  if (!nextVersion) {
    return <></>;
  }
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1) }}>
      <Text color='subdued' size='small' css={{ alignSelf: 'end' }}>
        {t('Version {{currentVersion}}', { currentVersion: shell.version ?? 'Unknown' })}
      </Text>
      <div
        style={{
          borderRadius: theme.borderRadius.medium,
          boxShadow: theme.shadows.floating,
          display: 'grid',
          gridTemplateRows: `repeat(4, minmax(${theme.spacing(2)}, auto))`,
          padding: theme.spacing(1, 2),
        }}
      >
        <Text size='medium' weight='bold' as='span' css={{ gridArea: '1/1' }}>
          {t('A new version is available: {{version}}', { version: nextVersion })}
        </Text>
        <Text size='medium' color='subdued' as='span' css={{ gridArea: '2/1' }}>
          {t('Please restart the app to update')}
        </Text>
        <PrimaryButton css={{ gridArea: '4/1', width: 'max-content' }} onClick={onUpdateClick}>
          {t('Restart Now')}
        </PrimaryButton>
      </div>
    </div>
  );
};

export const Section = ({
  children,
  render,
  className,
}: {
  children?: ReactNode;
  render?: (setShouldHide: (val: boolean) => void) => ReactNode;
  className?: string;
}) => {
  const [shouldHide, setShouldHide] = useState(false);

  const content = render ? render(setShouldHide) : children;

  return (
    <ErrorBoundary fallback={MainBoundary}>
      {!shouldHide && (
        <section
          css={{
            width: '100%',
            padding: theme.spacing(3),
            '&:not(:first-of-type)': {
              borderTop: `1px solid ${theme.colors.neutral10}`,
            },
          }}
          className={className}
        >
          {content}
        </section>
      )}
    </ErrorBoundary>
  );
};

const LinkSection = ({ closeModal, isLoading }: { closeModal: () => void; isLoading: boolean }) => {
  const { t } = useTranslation();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const { FeedbackModalTrigger, FeedbackModalFlow } = useFeedback({
    feedbackId: 'weave-2-dot-0-feedback',
  });
  const { isEnabled: accessibilityModeEnabled } = useAccessibilityModeEnabled('isEnabled');

  const links = [
    // TODO: Uncomment when we have a personal settings page
    // { label: t('Personal Settings'), onClick: () => {}, href: '/' },
    {
      label: t('Weave Help'),
      href: 'https://weavehelp.com/',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Refer Weave'),
      href: 'https://www.getweave.com/refer',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Weave Training Camp'),
      href: 'https://trainingcamp.weavehelp.com/',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Accessibility Preferences'),
      hasDot: accessibilityModeEnabled,
      onClick: () => {
        settingsNavigate({ to: '/personal/preferences/accessibility' });
        closeModal();
      },
    },
    {
      label: t('Privacy Policy'),
      href: 'https://www.getweave.com/legal/privacy/',
      target: 'blank',
      rel: 'noopener noreferrer',
      isExternal: true,
    },
    {
      label: t('Personal Preferences'),
      onClick: () => {
        settingsNavigate({ to: '/personal' });
        closeModal();
      },
    },
    {
      label: <FeedbackModalTrigger onClick={closeModal}>{t('Feedback')}</FeedbackModalTrigger>,
    },
  ];
  return (
    <Section css={{ alignSelf: 'end' }}>
      <ContentLoader message={t('Signing out')} show={isLoading} />

      <NakedUl css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        {links.map(({ label, onClick, href, isExternal, hasDot, ...rest }) => (
          <li key={`link-key-${label}`} css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            {typeof label === 'string' ? (
              <TextLink
                href={href}
                onClick={!href ? onClick : undefined}
                css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
                {...rest}
              >
                {label} {isExternal && <Icon name='external-link' size={12} />}
              </TextLink>
            ) : (
              label
            )}
            {hasDot && <Dot color='critical' css={{ position: 'relative' }} />}
          </li>
        ))}
      </NakedUl>
      {FeedbackModalFlow}
    </Section>
  );
};

const HealthStatusSection = () => {
  const navigate = useSettingsNavigate();
  const { t } = useTranslation();
  const { notifications, websocket } = useAppStatus();
  const shouldShowStatus = notifications?.state === 'error' || websocket?.state === 'error';
  if (!shouldShowStatus) {
    return null;
  }
  return (
    <Section>
      <Text
        size='large'
        weight='bold'
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        {t('System Health Alerts')}
      </Text>

      {notifications?.state === 'error' && (
        <BannerNotification
          status='warn'
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          <Text
            css={css`
              display: flex;
              justify-content: space-between;
              gap: ${theme.spacing(2)};
            `}
          >
            <span>{t('There is a problem showing notifications')} </span>
            <TextLink onClick={() => navigate.navigate({ to: '/personal/diagnostics' })}>{t('More')}</TextLink>
          </Text>
        </BannerNotification>
      )}

      {websocket?.state === 'error' && notifications?.state !== 'error' && (
        <BannerNotification status='warn'>
          <Text
            css={css`
              display: flex;
              justify-content: space-between;
              gap: ${theme.spacing(2)};
            `}
          >
            <span>{t('There is a problem connecting to the websocket server')} </span>
            <TextLink onClick={() => navigate.navigate({ to: '/personal/diagnostics' })}>{t('More')}</TextLink>
          </Text>
        </BannerNotification>
      )}
    </Section>
  );
};

interface TopBarPanelHeaderProps {
  title: string;
  isMultiLocationFeature?: boolean;
}

export const TopBarPanelHeader = ({ title, isMultiLocationFeature }: TopBarPanelHeaderProps) => (
  <header
    css={css`
      display: flex;
      justify-content: space-between;
    `}
  >
    <Heading
      level={isMultiLocationFeature ? 2 : 1}
      css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
      data-testid='tray-header'
    >
      {title}
    </Heading>
    <PanelHeader.Close iconName='x' size='large' />
  </header>
);

interface PanelContentProps {
  children: React.ReactNode;
}

export const TopBarPanelContent = ({ children }: PanelContentProps) => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      position: relative;
      overflow: auto;
      flex-grow: 1;
    `}
  >
    {children}
  </div>
);

interface SchedulePulsePanelProps {
  onClose: () => void;
  isMultiLocationFeature: boolean;
}

export const SchedulePulsePanel = ({ onClose, isMultiLocationFeature }: SchedulePulsePanelProps) => {
  return (
    <PanelPortal id='schedulePulse' width='medium' onClose={onClose}>
      <PaddedContent css={{ paddingBottom: 0 }}>
        <header
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Heading
            level={isMultiLocationFeature ? 2 : 1}
            css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
            data-testid='tray-header'
          >
            {labelMap['schedulePulse']}
          </Heading>
          <PanelHeader.Close iconName='x' size='large' />
        </header>
      </PaddedContent>
      <TopBarPanelContent>
        <SchedulePulseTrayContent onClose={onClose} />
      </TopBarPanelContent>
    </PanelPortal>
  );
};

interface QuickFillPanelProps {
  onClose: () => void;
  isMultiLocationFeature: boolean;
}

export const QuickFillPanel = ({ onClose, isMultiLocationFeature }: QuickFillPanelProps) => (
  <PanelPortal enableAnimations id='quickFill' width='medium' onClose={onClose}>
    <PaddedContent css={{ paddingBottom: 0 }}>
      <header
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Heading
          level={isMultiLocationFeature ? 2 : 1}
          css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
          data-testid='tray-header'
        >
          {labelMap['quickfill']}
        </Heading>
        <PanelHeader.Close iconName='x' size='large' />
      </header>
    </PaddedContent>
    <TopBarPanelContent>
      <QuickFillContent closeModal={onClose} />
    </TopBarPanelContent>
  </PanelPortal>
);

interface NotificationsPanelProps {
  onClose: () => void;
  isMultiLocationFeature: boolean;
  onLocationTrayOpen: (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
}

export const NotificationsPanel = ({
  onClose,
  isMultiLocationFeature,
  onLocationTrayOpen,
}: NotificationsPanelProps) => (
  <PanelPortal enableAnimations id='notifications' width='medium' onClose={onClose}>
    <PaddedContent css={{ paddingBottom: 0 }}>
      <header
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Heading
          level={isMultiLocationFeature ? 2 : 1}
          css={{ marginBottom: 0, display: 'flex', gap: theme.spacing(2), alignItems: 'center' }}
          data-testid='tray-header'
        >
          {labelMap['notifications']}
        </Heading>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}
        >
          <BulkNotificationHistoryActions />
          <PanelHeader.Close iconName='x' size='large' />
        </div>
      </header>
    </PaddedContent>
    <PanelContent css={{ padding: 0 }}>
      <NotificationTray closeModal={onClose} selectLocationTray={onLocationTrayOpen} />
    </PanelContent>
  </PanelPortal>
);
