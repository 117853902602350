import { useState } from 'react';
import { css } from '@emotion/react';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { useTranslation } from '@frontend/i18n';
import { PickerLocation } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  BackIcon,
  Heading,
  Modal,
  Stepper,
  TextField,
  useAlert,
  useForm,
  Button,
  Text,
  DropdownField,
} from '@frontend/design-system';
import { trackingId } from '../../tracking';
import { PhoneNumber } from '../../views/settings/types';
import { AssignExtensionsStep } from './assign-extensions-step';
import { PhoneNumbersTable } from './phone-numbers-table';

export const CreateCallRouteModal = ({
  tenantLocation,
  unallocatedPhoneNumbers,
  onClose,
}: {
  tenantLocation: PickerLocation;
  unallocatedPhoneNumbers: PhoneNumber[];
  onClose: () => void;
}) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const [selectedPhoneNumberIds, setSelectedPhoneNumberIds] = useState<string[]>([]);
  const [extensions, setExtensions] = useState<string[]>([]);

  const hasChildLocations = Boolean(tenantLocation.children?.length);

  const form = useForm({
    fields: {
      name: {
        type: 'text',
        required: true,
        value: '',
      },
      location: {
        type: 'dropdown',
        required: hasChildLocations,
      },
    },
  });
  const textFieldProps = form.getFieldProps('name');

  const { mutate: createCallRouteMutation } = CallRouteV1.Mutations.useCreateMutation({
    options: {
      onSuccess: () => {
        alerts.success('Call Route created successfully.');
        onClose();
      },
      onError: () => {
        alerts.error('Failed to create Call Route.');
      },
    },
  });

  const createCallRoute = (formValues: typeof form.values, tenantId: string, exts: string[]) => {
    createCallRouteMutation({
      tenantId: tenantId,
      name: formValues.name ?? '',
      locationId: formValues.location ?? '',
      phoneNumberIds: selectedPhoneNumberIds,
      extensionNumbers: exts.map((ext) => parseInt(ext)),
    });
  };

  const cancel = () => {
    form.reset();
    setExtensions([]);
    onClose();
  };

  const stepTitles: Record<string, string> = {
    1: t('Call Route Name'),
    2: t('Assign Phone Numbers'),
    3: t('Assign Extensions'),
  };
  if (hasChildLocations) {
    stepTitles[4] = t('Location');
  }

  const handleExtensionStepChange = (extensions: string[], stepChangeType: 'back' | 'continue' | 'done') => {
    setExtensions(extensions);

    if (stepChangeType === 'done') {
      createCallRoute(form.values, tenantLocation.phoneTenantId ?? '', extensions);
    }
  };

  return (
    <>
      <Modal.Body>
        <Stepper
          stepTitleText={stepTitles}
          isVerticalSingleView
          maxWidth={800}
          css={css`
            & > .stepper-vertical {
              min-width: 150px;
            }
          `}
        >
          {/* Call Route Name */}
          <Stepper.Card css={stepperCardStyles}>
            <div>
              <StepperCardHeader onCancelClick={cancel} />
              <Stepper.Content css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
                <Heading level={3}>{t('Call Route Name')}</Heading>
                <TextField
                  {...textFieldProps}
                  label={t('Call Route Name')}
                  name='name'
                  data-trackingid={trackingId({
                    context: 'setting',
                    feature: 'call-routes',
                    details: 'create::route-name-input',
                  })}
                ></TextField>
              </Stepper.Content>
            </div>

            <Stepper.ButtonBarAlternate>
              <Stepper.ButtonAlternate
                type='next'
                position='primary'
                disabled={!textFieldProps.value}
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'create::route-name-next-btn',
                })}
              >
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate type='none' position='secondary' onClick={cancel}>
                {t('Cancel')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Assign Phone Numbers */}
          <Stepper.Card css={stepperCardStyles}>
            <div>
              <StepperCardHeader onCancelClick={cancel} />
              <Stepper.Content>
                <div
                  css={css`
                    margin-bottom: ${theme.spacing(2)};
                  `}
                >
                  <Heading level={3}>{t('Assign Phone Numbers')}</Heading>
                  <Text size='large'>{t('Assign phone numbers to this Call Route so it can receive calls.')}</Text>
                </div>
                <PhoneNumbersTable
                  tenantLocation={tenantLocation}
                  phoneNumbers={unallocatedPhoneNumbers}
                  selectedPhoneNumberIds={selectedPhoneNumberIds}
                  setSelectedPhoneNumberIds={setSelectedPhoneNumberIds}
                  trackingIdBase={trackingId({
                    context: 'setting',
                    feature: 'call-routes',
                    details: 'create::phone-numbers',
                  })}
                />
              </Stepper.Content>
            </div>

            <Stepper.ButtonBarAlternate>
              <Stepper.ButtonAlternate
                type='next'
                position='primary'
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'create::phone-numbers-next-btn',
                })}
              >
                {t('Continue')}
              </Stepper.ButtonAlternate>
              <Stepper.ButtonAlternate
                type='none'
                position='secondary'
                onClick={cancel}
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'create::phone-numbers-cancel-btn',
                })}
              >
                {t('Cancel')}
              </Stepper.ButtonAlternate>
              {selectedPhoneNumberIds.length > 0 && (
                <Text color='light' textAlign='right'>
                  {selectedPhoneNumberIds.length > 1
                    ? t('{{count}} phone numbers selected', { count: selectedPhoneNumberIds.length })
                    : t('{{count}} phone number selected', { count: selectedPhoneNumberIds.length })}
                </Text>
              )}
              <Stepper.ButtonAlternate
                type='previous'
                position='tertiary'
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'create::phone-numbers-back-btn',
                })}
              >
                <BackIcon />
                {t('Back')}
              </Stepper.ButtonAlternate>
            </Stepper.ButtonBarAlternate>
          </Stepper.Card>

          {/* Assign Extensions */}
          <Stepper.Card css={stepperCardStyles}>
            <AssignExtensionsStep
              initialExtensions={extensions}
              tenantLocation={tenantLocation}
              onCancelClick={cancel}
              showContinueButton={hasChildLocations}
              onStepChange={handleExtensionStepChange}
            />
          </Stepper.Card>

          {/* Location */}
          {hasChildLocations && (
            <Stepper.Card css={stepperCardStyles}>
              <div>
                <StepperCardHeader onCancelClick={cancel} />
                <Stepper.Content>
                  <div
                    css={css`
                      margin-bottom: ${theme.spacing(2)};
                    `}
                  >
                    <Heading level={3}>{t('Location')}</Heading>
                    <Text size='large'>{t('Select the location that this Call Route will belong to.')}</Text>
                  </div>

                  <DropdownField
                    label={t('Select Location')}
                    {...form.getFieldProps('location')}
                    data-trackingid={trackingId({
                      context: 'setting',
                      feature: 'call-routes',
                      details: 'create::location-dropdown',
                    })}
                  >
                    {tenantLocation.children?.map((location) => (
                      <DropdownField.Option
                        key={location.locationId}
                        value={location.locationId ?? ''}
                        trackingId={trackingId({
                          context: 'setting',
                          feature: 'call-routes',
                          details: 'create::location-menu-option',
                        })}
                      >
                        {location.name}
                      </DropdownField.Option>
                    ))}
                  </DropdownField>
                </Stepper.Content>
              </div>

              <Stepper.ButtonBarAlternate>
                <Stepper.ButtonAlternate
                  type='none'
                  position='primary'
                  disabled={!form.isComplete}
                  onClick={() => createCallRoute(form.values, tenantLocation.phoneTenantId ?? '', extensions)}
                  trackingId={trackingId({
                    context: 'setting',
                    feature: 'call-routes',
                    details: 'create::location-done-btn',
                  })}
                >
                  {t('Done')}
                </Stepper.ButtonAlternate>
                <Stepper.ButtonAlternate
                  type='none'
                  position='secondary'
                  onClick={cancel}
                  trackingId={trackingId({
                    context: 'setting',
                    feature: 'call-routes',
                    details: 'create::location-cancel-btn',
                  })}
                >
                  {t('Cancel')}
                </Stepper.ButtonAlternate>
                <Stepper.ButtonAlternate
                  type='previous'
                  position='tertiary'
                  trackingId={trackingId({
                    context: 'setting',
                    feature: 'call-routes',
                    details: 'create::location-back-btn',
                  })}
                >
                  <BackIcon />
                  {t('Back')}
                </Stepper.ButtonAlternate>
              </Stepper.ButtonBarAlternate>
            </Stepper.Card>
          )}
        </Stepper>
      </Modal.Body>
    </>
  );
};

const stepperCardStyles = css`
  // The Stepper.Card section html element has an html style attribute that
  // sets height to auto which is difficult to override so need to use important here
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: none;
`;

export const StepperCardHeader = ({ onCancelClick }: { onCancelClick: () => void }) => {
  const { t } = useTranslation('phone');

  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        & > div {
          display: inline-flex;
        }
      `}
    >
      <Stepper.Title level={3}>{t('Create Call Route')}</Stepper.Title>
      <Button iconName='x-small' size='small' variant='secondary' onClick={onCancelClick} />
    </div>
  );
};
