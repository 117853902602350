import { useMemo, useState } from 'react';
import { CSSObject } from '@emotion/react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { AnimatePresence, HTMLMotionProps, motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { Button, NakedUl, Text } from '@frontend/design-system';
import { ScheduledMessageListItem } from './scheduled-message-list-item';

const MotionUl = motion(NakedUl);

type ScheduledMessageListProps = {
  scheduledMessages: ScheduledSms[];
  onSelectScheduledMessage: (messageId?: string) => void;
  selectedScheduledMessage?: ScheduledSms;
  listMaxHeight?: CSSObject['maxHeight'];
} & HTMLMotionProps<'div'>;

export const ScheduledMessageList = ({
  scheduledMessages,
  onSelectScheduledMessage,
  selectedScheduledMessage,
  listMaxHeight,
  ...rest
}: ScheduledMessageListProps) => {
  const { t } = useTranslation('thread-sending-area');
  const formatTimestamp = useTimestampFormatter();
  const [isOpen, setIsOpen] = useState(false);
  const { pausedCount, scheduledCount } = scheduledMessages.reduce<{ pausedCount: number; scheduledCount: number }>(
    (acc, curr) => {
      if (curr.status === OutboundMessageStatus.PAUSED) {
        acc.pausedCount++;
      } else if (curr.status === OutboundMessageStatus.SCHEDULED) {
        acc.scheduledCount++;
      }
      return acc;
    },
    { pausedCount: 0, scheduledCount: 0 }
  );
  const title = useMemo(() => {
    if (!pausedCount && !scheduledCount) return '';
    if (selectedScheduledMessage) {
      return t('Edit Message');
    }

    if (!!pausedCount && !!scheduledCount) return t('{{count}} Messages', { count: pausedCount + scheduledCount });

    if (scheduledCount === 1) {
      return t('Message Scheduled');
    } else if (scheduledCount > 1) {
      return t('{{count}} Messages Scheduled', { count: scheduledCount });
    } else if (pausedCount === 1) {
      return t('Message Paused');
    } else {
      return t('{{count}} Messages Paused', { count: pausedCount });
    }
  }, [t, pausedCount, scheduledCount, !!selectedScheduledMessage, scheduledMessages.length]);

  const variant: 'list' | 'editing' | 'closed' = selectedScheduledMessage ? 'editing' : isOpen ? 'list' : 'closed';
  const timestampToShow =
    variant === 'editing'
      ? formatTimestamp(selectedScheduledMessage?.sendAt)
      : variant === 'closed' &&
        scheduledMessages.length === 1 &&
        scheduledMessages[0]?.status !== OutboundMessageStatus.PAUSED
      ? formatTimestamp(scheduledMessages[0]?.sendAt)
      : undefined;
  const backgroundColor = useMemo(() => {
    switch (selectedScheduledMessage?.status) {
      case OutboundMessageStatus.PAUSED:
        return theme.colors.neutral5;
      case OutboundMessageStatus.SCHEDULED:
        return theme.colors.warning5;
      default:
        if (pausedCount) {
          return theme.colors.neutral5;
        } else {
          return theme.colors.warning5;
        }
    }
  }, [pausedCount, selectedScheduledMessage?.status]);

  return (
    <AnimatePresence>
      {!!(pausedCount + scheduledCount) && (
        <motion.div
          css={{
            width: '100%',
            maxHeight: '40%',
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'clip',
          }}
          animate={{
            height: 'auto',
            padding: variant === 'closed' ? theme.spacing(1) : 0,
          }}
          initial={{
            height: scheduledMessages.length ? 'auto' : 0,
            padding: scheduledMessages.length ? (variant === 'closed' ? theme.spacing(1) : 0) : 0,
          }}
          exit={{
            height: 0,
            padding: 0,
          }}
          {...rest}
        >
          <motion.div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
            animate={{
              padding: variant === 'closed' ? theme.spacing(0.25, 1) : theme.spacing(0.5, 1.5),
              borderRadius: variant === 'closed' ? theme.borderRadius.small : 0,
              backgroundColor,
            }}
            initial={{
              padding: variant === 'closed' ? theme.spacing(0.25, 1) : theme.spacing(0.5, 1.5),
              borderRadius: variant === 'closed' ? theme.borderRadius.small : 0,
              backgroundColor,
            }}
          >
            <div
              css={{
                display: 'flex',
                gap: theme.spacing(1),
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Icon name='calendar-small' />
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  width: '100%',
                }}
              >
                <Text size='medium' weight='bold'>
                  {title}
                </Text>
                {!!timestampToShow && (
                  <Text size='small' color='light'>
                    {timestampToShow}
                  </Text>
                )}
              </div>
            </div>
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!!selectedScheduledMessage && scheduledMessages.length > 1 && (
                <>
                  <Button
                    variant='tertiary'
                    onClick={(e) => {
                      onSelectScheduledMessage(undefined);
                      setIsOpen(true);
                      e.currentTarget.blur();
                    }}
                    css={{
                      backgroundColor: 'transparent',
                      ':hover, :focus': {
                        backgroundColor: pausedCount ? theme.colors.neutral10 : theme.colors.warning10,
                      },
                    }}
                  >
                    {t('View All')}
                  </Button>
                  <div
                    css={{
                      width: 1,
                      backgroundColor: theme.colors.neutral20,
                      height: theme.spacing(2),
                    }}
                  ></div>
                </>
              )}
              <Button
                variant='tertiary'
                onClick={(e) => {
                  if (selectedScheduledMessage) {
                    onSelectScheduledMessage(undefined);
                    setIsOpen(false);
                  } else if (scheduledMessages.length === 1) {
                    onSelectScheduledMessage(scheduledMessages[0]?.id);
                  } else {
                    setIsOpen((prev) => !prev);
                  }
                  e.currentTarget.blur();
                }}
                css={{
                  backgroundColor: 'transparent',
                  ':hover, :focus': {
                    backgroundColor: pausedCount ? theme.colors.neutral10 : theme.colors.warning10,
                  },
                }}
              >
                {variant === 'closed' ? (scheduledMessages.length === 1 ? t('Edit') : t('View')) : t('Cancel')}
              </Button>
            </div>
          </motion.div>
          <AnimatePresence>
            {variant === 'list' && (
              <MotionUl
                initial={{ height: 0, padding: 0 }}
                animate={{ height: '100%', padding: theme.spacing(1) }}
                exit={{ height: 0, padding: 0 }}
                css={{
                  overflowY: 'auto',
                  backgroundColor,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: theme.spacing(1),
                  maxHeight: listMaxHeight ?? 250,
                }}
              >
                {scheduledMessages.map((message) => {
                  return (
                    <ScheduledMessageListItem
                      key={message.id}
                      message={message}
                      onClick={() => {
                        onSelectScheduledMessage(message.id);
                        setIsOpen(false);
                      }}
                    />
                  );
                })}
              </MotionUl>
            )}
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
