import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { createShallowStore, createStore } from '@frontend/store';

type AppointmentsStoreType = {
  appointments: Appointment[] | undefined;
  setAppointments: (data: Appointment[]) => void;
  selectedDate: string;
  setSelectedDate: (date: string) => void;
  refreshAppointments: () => void;
  setRefreshAppointments: (refreshAppointmentsMethod: () => void) => void;
};

const useAppointmentsStore = createStore<AppointmentsStoreType>(
  (set) => ({
    selectedDate: '',
    appointments: [],
    refreshAppointments: () => {},
    setSelectedDate: (date: string) => {
      set((state) => {
        state.selectedDate = date;
      });
    },
    setAppointments: (data) => {
      set((state) => {
        state.appointments = data;
      });
    },
    setRefreshAppointments: (refreshAppointmentsMethod) => {
      set((state) => {
        state.refreshAppointments = refreshAppointmentsMethod;
      });
    },
  }),
  { name: 'appointments' }
);

export const useAppointmentsInfoShallowStore = createShallowStore<AppointmentsStoreType>(useAppointmentsStore);
