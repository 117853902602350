import { FC, useMemo } from 'react';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonTypes } from '@frontend/api-person';
import { AdaptoActions } from '@frontend/adapto-actions';
import { useDigitalFormsLocationsContext, DigitalFormsLocationsWrapper } from '@frontend/digital-forms-scope';
import {
  useDigitalFormsSendingExperience,
  FormsSendingExperienceTypes,
} from '@frontend/digital-forms-sending-experience';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { FormsPromotionActionButtonWrapper } from '@frontend/self-upgrade';
import { ContactActionPrefixes } from '@frontend/tracking-prefixes';
import { ActionButton } from '@frontend/design-system';

export interface SendFormButtonProps {
  label?: string;
  context: {
    locationId: string;
    person: PersonTypes.Person;
  };
  trackingIdSuffix?: string;
}

const EMPTY_LOCATION_IDS: string[] = [];

const SendFormButtonContent: FC<SendFormButtonProps> = ({ label, context, trackingIdSuffix }) => {
  const { DigitalFormsSendingExperience, sendFormsModalControls } = useDigitalFormsSendingExperience();
  const { isValidFormsLocation } = useDigitalFormsLocationsContext();
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource, isLoading: isLoadingLocationsByDataSource } =
    DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);
  const locations = getLocationsByDatasource(context.person.SourceID, context.person.ClientLocationID);

  const minimalPersonData = useMemo<FormPacketSelectorTypes.MinimalPersonData>(() => {
    return {
      id: context.person.PersonID,
      firstName: context.person.FirstName,
      lastName: context.person.LastName,
    };
  }, [context.person]);

  const locationIds = useMemo(() => {
    if (isLoadingLocationsByDataSource) {
      return EMPTY_LOCATION_IDS;
    }

    if (!locations || locations.length === 0) {
      if (!context.locationId) {
        return EMPTY_LOCATION_IDS;
      }
      return [context.locationId];
    }

    return locations.map((location) => location.locationId);
  }, [locations, context.locationId]);

  function shouldDisable() {
    if (!isValidFormsLocation(context.locationId)) {
      return true;
    }

    const person = context.person;
    const hasEmail = !!person.Email;
    const hasHomePhone = !!person.HomePhone;
    const hasMobilePhone = !!person.MobilePhone;
    const hasWorkPhone = !!person.WorkPhone;

    if (!hasEmail && !hasHomePhone && !hasMobilePhone && !hasWorkPhone) {
      return true;
    }

    return false;
  }
  return (
    <>
      <ActionButton
        {...sendFormsModalControls.triggerProps}
        trackingId={`${ContactActionPrefixes.SendForm}:${trackingIdSuffix}`}
        label={label}
        disabled={shouldDisable()}
      >
        <Icon name='forms' size={16} />
      </ActionButton>

      <DigitalFormsSendingExperience
        origin={FormsSendingExperienceTypes.SendFormsOrigin.CONTACTS}
        locationIds={locationIds}
        sendFormsModalControls={sendFormsModalControls}
        person={minimalPersonData}
      />
    </>
  );
};

export const SendFormButton: FC<SendFormButtonProps> = (props) => {
  return (
    <DigitalFormsLocationsWrapper>
      <SendFormButtonContent {...props} />
    </DigitalFormsLocationsWrapper>
  );
};

export interface SendFormActionProps {
  label: string;
  context: {
    locationId: string;
    person: PersonTypes.Person;
  };
  trackingIdSuffix?: string;
}

const SendFormActionContent: FC<SendFormActionProps> = ({ label, context, trackingIdSuffix }) => {
  const { DigitalFormsSendingExperience, sendFormsModalControls } = useDigitalFormsSendingExperience();
  const { isValidFormsLocation, refreshFormsLocationValidity } = useDigitalFormsLocationsContext();
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { getLocationsByDatasource, isLoading: isLoadingLocationsByDataSource } =
    DataSourcesHooks.useDatasourceToLocations(selectedLocationIds, getLocationName);
  const locations = getLocationsByDatasource(context.person.SourceID, context.person.ClientLocationID);

  const minimalPersonData = useMemo<FormPacketSelectorTypes.MinimalPersonData>(() => {
    return {
      id: context.person.PersonID,
      firstName: context.person.FirstName,
      lastName: context.person.LastName,
    };
  }, [context.person]);

  const locationIds = useMemo(() => {
    if (isLoadingLocationsByDataSource) {
      return EMPTY_LOCATION_IDS;
    }

    if (!locations || locations.length === 0) {
      if (!context.locationId) {
        return EMPTY_LOCATION_IDS;
      }
      return [context.locationId];
    }

    return locations.map((location) => location.locationId);
  }, [locations, context.locationId]);

  function shouldDisable() {
    const person = context.person;
    const hasEmail = !!person.Email;
    const hasHomePhone = !!person.HomePhone;
    const hasMobilePhone = !!person.MobilePhone;
    const hasWorkPhone = !!person.WorkPhone;

    if (!hasEmail && !hasHomePhone && !hasMobilePhone && !hasWorkPhone) {
      return true;
    }

    return false;
  }
  return (
    <>
      <FormsPromotionActionButtonWrapper
        onUpgradeSuccess={refreshFormsLocationValidity}
        shouldShowFeaturePromotion={() => {
          return !isValidFormsLocation(context.locationId) && !shouldDisable();
        }}
      >
        <AdaptoActions.Action
          {...sendFormsModalControls.triggerProps}
          trackingId={`${ContactActionPrefixes.SendForm}:${trackingIdSuffix}`}
          label={label}
          icon='forms'
          disabled={shouldDisable()}
        />
      </FormsPromotionActionButtonWrapper>

      <DigitalFormsSendingExperience
        origin={FormsSendingExperienceTypes.SendFormsOrigin.CONTACTS}
        locationIds={locationIds}
        sendFormsModalControls={sendFormsModalControls}
        person={minimalPersonData}
      />
    </>
  );
};

export const SendFormAction: FC<SendFormActionProps> = (props) => {
  return (
    <DigitalFormsLocationsWrapper>
      <SendFormActionContent {...props} />
    </DigitalFormsLocationsWrapper>
  );
};
