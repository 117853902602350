import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';

export enum BundleType {
  UNKNOWN = 'UNKNOWN',
  ESSENTIALS = 'ESSENTIALS',
  PRO = 'PRO',
  ELITE = 'ELITE',
  ULTIMATE = 'ULTIMATE',
}

export enum PlanType {
  UNKNOWN = 'UNKNOWN',
  CORE = 'CORE',
  PLUS = 'PLUS',
}

export type GbbSalesforceBundleInfo = {
  isGbbBundle: boolean;
  salesforceProductBundleEnum: SalesforceAccountProductBundle;
  bundleDisplayName: string;
  isIntegrated: boolean;
  isSoftwareOnlyBundle: boolean;
  locationId?: string;
};

export type UseIsGbbSalesforceBundleResponseType = GbbSalesforceBundleInfo & {
  isLoading: boolean;
  isError: boolean;
};
