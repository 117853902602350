import { BASE_ROUTE } from './base';
import { FORM_BUILDER_ROUTES } from './form-builder';
import { FORM_SUBMISSION_ROUTES } from './form-submissions';
import { FORM_TEMPLATES_ROUTES } from './form-templates';
import { FORMS_KIOSK_ROUTES } from './forms-kiosk';
import { FORMS_LIBRARY_ROUTES } from './forms-library';

export { routeUtils } from './route.utils';

export const routes = {
  base: BASE_ROUTE,
  submissions: FORM_SUBMISSION_ROUTES,
  library: FORMS_LIBRARY_ROUTES,
  kiosk: FORMS_KIOSK_ROUTES,
  templates: FORM_TEMPLATES_ROUTES,
  formBuilder: FORM_BUILDER_ROUTES,
};
