import { useState } from 'react';
import { useQueryClient } from 'react-query';
import { ContactDirectoriesMutation, ContactDirectoriesQueryKeys } from '@frontend/api-contact-directories';
import { Trans, useTranslation } from '@frontend/i18n';
import { ModalControlModalProps, Tray, Text, Heading, useAlert, Button } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { AssignDevicesToDirectoryTable } from '../tables';

type Props = ModalControlModalProps & {
  name: string;
  contactDirectoryId: string;
};

export const AssignDevicesToDirectoryModal = ({ onClose, name, contactDirectoryId }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const [selectedDevicesIds, setSelectedDevicesIds] = useState<string[]>();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { useReplaceContactDirectoryDevicesMutation } = ContactDirectoriesMutation.useContactDirectoryMutations();
  const { mutateAsync: mutateAssignDevices } = useReplaceContactDirectoryDevicesMutation({
    onSuccess: () => {
      onClose();
      alert.success(t('Successfully updated devices using this list'));
      queryClient.invalidateQueries([tenantId, ...ContactDirectoriesQueryKeys.queryKeys.getContactDirectories()]);
      queryClient.invalidateQueries([
        tenantId,
        ...ContactDirectoriesQueryKeys.queryKeys.getDevicesbyContactDirectoryId(contactDirectoryId),
      ]);
    },
    onSettled: () => {
      setSelectedDevicesIds(undefined);
    },
  });

  return (
    <>
      <Tray.Header
        css={{ alignItems: 'start' }}
        Buttons={<Button onClick={onClose} iconName='x' variant='secondary' size='large' label={t('Close')} />}
      >
        <div>
          <Heading level={2}>{t('Assign or Remove List')}</Heading>
          <Trans t={t}>
            <Text color='light'>Choose which devices use {name} in their phone directory.</Text>
          </Trans>
        </div>
      </Tray.Header>
      <Tray.Body>
        <AssignDevicesToDirectoryTable
          setSelectedDevicesIds={setSelectedDevicesIds}
          contactDirectoryId={contactDirectoryId}
        />
      </Tray.Body>
      <Tray.Actions
        onSecondaryClick={onClose}
        secondaryLabel='Cancel'
        primaryLabel='Save'
        primaryTrackingId='assign-devices-directories'
        onPrimaryClick={() => {
          mutateAssignDevices({ contactListId: contactDirectoryId, deviceIds: selectedDevicesIds ?? [] });
        }}
      />
    </>
  );
};
