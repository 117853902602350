import { useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useMatch } from '@tanstack/react-location';
import { CallRoute } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { PhoneNumbersV1 } from '@frontend/api-phone-numbers';
import { PhoneOverrideV2 } from '@frontend/api-phone-override-v2';
import { SettingsPageLayout } from '@frontend/components';
import { getInitialParams } from '@frontend/env';
import { i18next, useTranslation } from '@frontend/i18n';
import { NodeTypes, NodeType, CallRouteFlow } from '@frontend/node-flow';
import { Page } from '@frontend/page';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Button, Modal, Tray, useModalControl, ContentLoader } from '@frontend/design-system';
import { ActionBarButton } from '../../components/call-routes/call-route-details/action-bar-button';
import { LocationPanel } from '../../components/call-routes/call-route-details/location-panel';
import { ChangeLocationModal } from '../../components/call-routes/change-location-modal copy';
import { DeleteCallRouteModal } from '../../components/call-routes/delete-call-route-modal';
import { EditExtensionsModal } from '../../components/call-routes/edit-extensions-modal';
import { EditPhoneNumbersModal } from '../../components/call-routes/edit-phone-numbers-modal';
import { getUnallocatedPhoneNumbers } from '../../components/call-routes/helpers';
import { NumbersPanel } from '../../components/call-routes/numbers-panel';
import { RenameCallRouteModal } from '../../components/call-routes/rename-call-route-modal';
import { CachedAudioScrubber } from '../../components/common/cached-audio-scrubber';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { trackingId } from '../../tracking';
import { buildAudioLibraryPath } from '../../utils/media-path';

const SidePanel = {
  Numbers: i18next.t('Numbers', { ns: 'phone' }),
  Location: i18next.t('Location', { ns: 'phone' }),
  Override: i18next.t('Override', { ns: 'phone' }),
} as const;

export const CallRouteDetails = () => {
  const { t } = useTranslation('phone');
  const {
    params: { id: callRouteId },
    search: { crid: redirectCallRouteId, crn: redirectCallRouteName },
  } = useMatch<{ Params: { id: string }; Search: { crid?: string; crn?: string } }>();

  const { navigate: settingsNavigate } = useSettingsNavigate();
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const [activePanel, setActivePanel] = useState<keyof typeof SidePanel | undefined>();

  const { modalProps: deleteModalProps, triggerProps: deleteTriggerProps } = useModalControl();
  const { modalProps: sidePanelModalProps, triggerProps: sidePanelTriggerProps } = useModalControl();
  const { modalProps: editPhoneNumbersModalProps, triggerProps: editPhoneNumbersTriggerProps } = useModalControl();
  const { modalProps: editExtensionsModalProps, triggerProps: editExtensionsTriggerProps } = useModalControl();
  const { modalProps: changeLocationModalProps, triggerProps: changeLocationTriggerProps } = useModalControl();
  const { modalProps: renameCallRouteModalProps, triggerProps: renameCallRouteTriggerProps } = useModalControl();

  const { data: callRoute, isLoading: callRouteIsLoading } = CallRouteV1.Queries.useReadQuery({
    request: { callRouteId },
    options: {
      enabled: !!callRouteId,
      staleTime: 0,
      cacheTime: 0,
    },
  });

  const { data: phoneOverrides, isLoading: phoneOverridesIsLoading } = PhoneOverrideV2.Queries.useListQuery({
    request: { tenantId: settingsTenantLocation?.phoneTenantId ?? '' },
    options: {
      enabled: !!settingsTenantLocation?.phoneTenantId,
      select: (data) => data?.phoneOverrides ?? [],
      cacheTime: 0,
      staleTime: 0,
      retry: false,
    },
  });

  const { data: allPhoneNumbers, isLoading: phoneNumbersIsLoading } =
    PhoneNumbersV1.Queries.useListPhoneNumbersForLocationsQuery({
      request: { locationIds: selectedLocationIds },
      options: { enabled: !!selectedLocationIds, select: (data) => data?.phoneNumbers ?? [] },
    });

  // For now, we have to pull the full call routes list to get the call route data since the
  // singular call route query does not return all the necessary data.
  const { data: callRoutes, isLoading: callRoutesIsLoading } = CallRouteV1.Queries.useListQuery({
    request: { tenantId: settingsTenantLocation?.phoneTenantId ?? '' },
    options: {
      enabled: !!settingsTenantLocation?.phoneTenantId,
      select: ({ callRoutes }) =>
        // Sort the extension numbers so that they are displayed in ascending order.
        callRoutes.reduce<CallRoute[]>((acc, callroute) => {
          return [
            ...acc,
            {
              ...callroute,
              extensionNumbers: callroute.extensionNumbers.sort(),
            },
          ];
        }, []),
      cacheTime: 1000,
      staleTime: 1000,
    },
  });

  const extendedCallRoute = useMemo(() => {
    if (!callRoute || !callRoutes) {
      return undefined;
    }

    const callRouteData = callRoutes.find((route) => route.callRouteId === callRoute.callRouteId);

    const phoneNumberIds = callRouteData?.phoneNumberIds ?? [];
    const phoneNumbersForCallRoute =
      allPhoneNumbers?.filter((phoneNumber) => phoneNumberIds.includes(phoneNumber.id)) ?? [];
    const callRoutePhoneOverride = phoneOverrides?.find(
      (phoneOverride) => phoneOverride.instructionSetId === callRouteData?.rootInstructionSetId
    );

    if (!callRouteData) {
      return undefined;
    }

    return {
      ...callRouteData,
      phoneNumbers: phoneNumbersForCallRoute,
      callRoutePhoneOverride,
    };
  }, [callRoute, allPhoneNumbers, phoneOverrides, callRoutes]);

  //  The phone numbers that are not allocated to any call route for a specific tenant.
  const unallocatedPhoneNumbers = useMemo(() => {
    if (!allPhoneNumbers || !callRoutes || !settingsTenantLocation?.phoneTenantId) {
      return [];
    }
    return getUnallocatedPhoneNumbers(allPhoneNumbers, callRoutes, settingsTenantLocation.phoneTenantId);
  }, [allPhoneNumbers, callRoutes, settingsTenantLocation?.phoneTenantId]);

  useEffect(() => {
    if (sidePanelModalProps.show === false) {
      setActivePanel(undefined);
    }
  }, [sidePanelModalProps.show]);

  const isLoading = callRouteIsLoading || phoneNumbersIsLoading || phoneOverridesIsLoading || callRoutesIsLoading;

  const callRouteName = callRoute?.callRoute.name ?? 'Unknown Call Route Name';

  // Callback function for when a call object link is clicked in a node.
  // Redirects the user to the appropriate page based on the type of node clicked.
  const handleCallObjectLinkClicked = useCallback(
    ({ callObjectId, type }: { callObjectId: string; type: NodeTypes }) => {
      switch (type) {
        case NodeType.CallGroup:
          settingsNavigate({
            to: `/phone/call-groups/:id`,
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name },
          });
          break;
        case NodeType.CallRoute:
          settingsNavigate({
            to: '/phone/call-routes/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name },
          });
          break;
        case NodeType.CallQueue:
          settingsNavigate({
            to: '/phone/call-queues/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name },
          });
          break;
        case NodeType.PhoneTree:
          settingsNavigate({
            to: '/phone/phone-tree/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name },
          });
          break;
        case NodeType.VoicemailBox:
          settingsNavigate({
            to: '/phone/voicemail-box/:id',
            params: { id: callObjectId },
            search: { crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name },
          });
          break;
        case NodeType.ForwardDevice:
          settingsNavigate({
            to: '/phone/devices/:id',
            params: { id: callObjectId },
            search: { id: callObjectId, crid: extendedCallRoute?.callRouteId, crn: extendedCallRoute?.name },
          });
          break;
        default:
          console.error(`Unhandled node type: ${type}`);
      }
    },
    [extendedCallRoute?.callRouteId, extendedCallRoute?.name, settingsNavigate]
  );

  /**
   * This is a custom audio player component that is used in the call route flow for the PlayMessage and VoicemailBox nodes.
   * In the case of the voicemailBox node, the downloadUrl is passed in as a prop. In the case of the PlayMessage node, the
   * mediaItemId is passed in as a prop and so we need to build the download the URL for the audio file in those cases.
   */
  const AudioPlayerComponent = useCallback(
    (props: { mediaItemId: string; downloadUrl?: string }) => {
      if (!props.mediaItemId && !props.downloadUrl) {
        console.error('Missing mediaItemId or downloadUrl');
        return null;
      }

      let filePath = '';
      if (props.downloadUrl) {
        filePath = props.downloadUrl;
      } else {
        const currentEnvApi = getInitialParams().backendApi;
        const downloadUrl = `${currentEnvApi}/phone/audio-library/v1/download/${settingsTenantLocation?.phoneTenantId}/${props.mediaItemId}`;

        filePath = buildAudioLibraryPath({
          media: { id: props.mediaItemId, path: downloadUrl, isGlobal: false },
        });
      }

      return <CachedAudioScrubber customWidth={205} filePath={filePath} mediaId={props.mediaItemId} />;
    },
    [settingsTenantLocation?.phoneTenantId]
  );

  // The call route nodes with some extra data for the flow.
  const processedNodes = useMemo(() => {
    if (!callRoute?.nodes || !callRoute?.edges) {
      return [];
    }

    // We need to check if the nodes are a descendent of a phone tree node, and if so, we add
    // an extra property to the node to indicate that it is a descendent of a phone tree. To determine if a node
    // is a descendent of a phone tree, we need to traverse the callroute edges starting from the current node
    // as the target and work our way up to the root node. If we encounter a node with a type of phone tree then it is a descendent.
    const isPhoneTreeDescendent = (nodeId: string): boolean => {
      const node = callRoute.nodes.find((n) => n.id === nodeId);
      if (!node) {
        return false;
      }

      const edge = callRoute.edges.find((e) => e.targetId === nodeId);
      if (!edge) {
        return false;
      }

      if (node.type === NodeType.PhoneTree) {
        return true;
      }

      return isPhoneTreeDescendent(edge.sourceId);
    };

    return callRoute.nodes.map((node) => {
      return {
        ...node,
        isPhoneTreeDescendent: node.type !== NodeType.PhoneTree && isPhoneTreeDescendent(node.id),
      };
    });
  }, [callRoute?.nodes, callRoute?.edges]);

  return (
    <SettingsPageLayout css={{ scrollbarGutter: 'stable' }}>
      <Page
        bgColor={theme.colors.white}
        css={css`
          max-width: 100%;
          padding: 0;
          display: flex;
          flex-direction: column;

          .page-header {
            padding: ${theme.spacing(2, 5)};
            margin-bottom: 0;
            border-bottom: 1px solid ${theme.colors.neutral10};
            height: 96px;
          }

          nav {
            margin-bottom: 0;
          }
        `}
      >
        <Page.Header>
          <Page.Header.SettingsBreadcrumbs
            breadcrumbs={
              redirectCallRouteId
                ? [
                    { label: t('Phone'), to: '/phone/main' },
                    { label: t('Call Routes'), to: '/phone/call-routes' },
                    {
                      label: redirectCallRouteName ?? '',
                      to: '/phone/call-routes/:id',
                      params: { id: redirectCallRouteId },
                    },
                    { label: callRouteName },
                  ]
                : [
                    { label: t('Phone'), to: '/phone/main' },
                    { label: t('Call Routes'), to: '/phone/call-routes' },
                    { label: callRouteName },
                  ]
            }
          />

          <Page.Header.Heading>
            <Page.Header.Title title={callRouteName}>
              {' '}
              <Button
                size='small'
                variant='secondary'
                iconName='edit-small'
                trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'edit-call-route-name' })}
                css={{
                  '.button-icon': {
                    color: theme.colors.neutral60,
                  },
                }}
                {...renameCallRouteTriggerProps}
              />
            </Page.Header.Title>
            <Page.Header.Action>
              <Button
                variant='secondary'
                size='large'
                destructive
                disabled={callRouteIsLoading}
                {...deleteTriggerProps}
                trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'delete-call-route-btn' })}
              >
                {t('Delete Call Route')}
              </Button>
            </Page.Header.Action>
          </Page.Header.Heading>
        </Page.Header>

        <Page.Body
          css={css`
            display: flex;
            flex: 1;
          `}
        >
          {/* Main Content */}
          <section
            id='call-route-flow-section'
            css={css`
              flex: 1;
              background-color: ${theme.colors.neutral5};
              position: relative;
            `}
          >
            {callRoute?.nodes && callRoute.edges && (
              <CallRouteFlow
                nodes={processedNodes}
                edges={callRoute.edges}
                onClickLink={handleCallObjectLinkClicked}
                AudioPlayerComponent={AudioPlayerComponent}
              />
            )}
            <ContentLoader show={isLoading} />
          </section>

          {/* Sidebar */}
          <aside
            css={css`
              width: 56px;
              border-left: 1px solid ${theme.colors.neutral10};
            `}
          >
            <ActionBarButton
              iconName='porting-numbers'
              label={SidePanel.Numbers}
              isActive={activePanel === SidePanel.Numbers}
              trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'numbers-side-btn' })}
              onClick={() => {
                setActivePanel('Numbers');
                sidePanelTriggerProps.onClick();
              }}
            />
            {!!settingsTenantLocation?.children?.length && (
              <ActionBarButton
                iconName='location'
                label={SidePanel.Location}
                isActive={activePanel === SidePanel.Location}
                trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'location-side-btn' })}
                onClick={() => {
                  setActivePanel('Location');
                  sidePanelTriggerProps.onClick();
                }}
              />
            )}
            <ActionBarButton
              iconName='voicemail-override'
              label={SidePanel.Override}
              isActive={activePanel === SidePanel.Override}
              trackingId={trackingId({ context: 'setting', feature: 'call-route', details: 'override-side-btn' })}
              onClick={() => {
                setActivePanel('Override');
                sidePanelTriggerProps.onClick();
              }}
            />
          </aside>
        </Page.Body>
      </Page>

      {extendedCallRoute && (
        <Tray mountTarget='#call-route-flow-section' width='medium' {...sidePanelModalProps}>
          <Tray.Header
            Buttons={
              <Button
                iconName='x'
                onClick={() => {
                  setActivePanel(undefined);
                  sidePanelModalProps.onClose();
                }}
                variant='secondary'
              />
            }
          >
            {activePanel ? SidePanel[activePanel] : ''}
          </Tray.Header>

          {activePanel === SidePanel.Numbers && (
            <NumbersPanel
              callRoute={extendedCallRoute}
              hasChildLocations={Boolean(settingsTenantLocation?.children?.length)}
              onEditPhoneNumbers={() => {
                setActivePanel(undefined);
                sidePanelModalProps.onClose();
                editPhoneNumbersTriggerProps.onClick();
              }}
              onEditExtensions={() => {
                setActivePanel(undefined);
                sidePanelModalProps.onClose();
                editExtensionsTriggerProps.onClick();
              }}
            />
          )}

          {activePanel === SidePanel.Location && (
            <LocationPanel
              locationName={getLocationName(extendedCallRoute.locationId)}
              onChangeLocationClick={() => {
                setActivePanel(undefined);
                sidePanelModalProps.onClose();
                changeLocationTriggerProps.onClick();
              }}
            />
          )}
        </Tray>
      )}

      {extendedCallRoute && settingsTenantLocation && (
        <Modal {...editPhoneNumbersModalProps} minWidth={600}>
          <EditPhoneNumbersModal
            onClose={() => {
              editPhoneNumbersModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
            tenantLocation={settingsTenantLocation}
            unallocatedPhoneNumbers={unallocatedPhoneNumbers ?? []}
          />
        </Modal>
      )}

      {extendedCallRoute && settingsTenantLocation && (
        <Modal {...editExtensionsModalProps} minWidth={600}>
          <EditExtensionsModal
            onClose={() => {
              editExtensionsModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
            tenantLocation={settingsTenantLocation}
          />
        </Modal>
      )}

      {extendedCallRoute && settingsTenantLocation && (
        <Modal {...changeLocationModalProps} minWidth={580}>
          <ChangeLocationModal
            onClose={() => {
              changeLocationModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
            settingsTenantLocation={settingsTenantLocation}
          />
        </Modal>
      )}

      {extendedCallRoute && (
        <Modal {...renameCallRouteModalProps} minWidth={400}>
          <RenameCallRouteModal
            onClose={() => {
              renameCallRouteModalProps.onClose();
            }}
            callRoute={extendedCallRoute}
          />
        </Modal>
      )}

      <DeleteCallRouteModal
        deleteModalProps={deleteModalProps}
        callRouteId={callRouteId}
        onSuccess={() => {
          settingsNavigate({ to: '/phone/call-routes' });
        }}
      />
    </SettingsPageLayout>
  );
};
