import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Navigate, useSearch } from '@tanstack/react-location';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { ScheduleQueries } from '@frontend/api-schedule';
import { WeaveLogo } from '@frontend/assets';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { Page } from '@frontend/page';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import {
  CheckboxField,
  Heading,
  SpinningLoader,
  Stepper,
  Text,
  useFormField,
  styles as dsStyles,
} from '@frontend/design-system';
import { useSetupScheduleUiV3FeatureFlag } from '../../../hooks/booking-site';
import { useAppointmentRequestForm } from '../../../hooks/booking-site';
import { useBookingSiteStore } from '../../../stores';
import { BOOKING_SITE_HTTP_OPTIONS, getFullName } from '../../../utils';
import { ApptTypeSelect } from './appt-type-select';
import { InfoForm } from './info-form';
import { LocationSelect } from './location-select';
import { ProviderSelect } from './ProviderSelect';
import { SuccessRequestAppointment } from './success';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);

interface Props {
  locationId: string;
  renderAppointmentTypePaymentsModule: (
    paymentAmount: number,
    locationPortalSlug?: string,
    formError?: string
  ) => JSX.Element;
  isPaymentSuccess?: boolean;
  isPaymentError?: boolean;
  paymentTransactionId?: string;
  resetPaymentDetails?: () => void;
}

export const RequestAppointment = ({
  locationId,
  renderAppointmentTypePaymentsModule,
  isPaymentSuccess = false,
  paymentTransactionId = '',
  resetPaymentDetails,
  isPaymentError = false,
}: Props) => {
  const { t } = useTranslation('bookingSite');
  const { debug } = useSearch<{ Search: { debug?: boolean } }>();
  const { isLoadingFeatureFlagStatus } = useSetupScheduleUiV3FeatureFlag(locationId, !!debug);

  const {
    isSchedularV3Enabled,
    selectedLocationId,
    selectedLocationName,
    paymentLocationPortalSlug,
    selectedAppointmentType,
    selectedAppointmentLeadTime,
    minSlots,
    selectedSlots,
    selectedProvider,
    captchaV3Token,
    setLocationTimeZone,
    setSelectedLocation,
    clearSelectedAppointmentType,
    clearSelectedProvider,
    setWeekInView,
  } = useBookingSiteStore([
    'isSchedularV3Enabled',
    'selectedLocationId',
    'selectedLocationName',
    'paymentLocationPortalSlug',
    'setSelectedLocation',
    'selectedAppointmentType',
    'selectedAppointmentLeadTime',
    'selectedProvider',
    'clearSelectedAppointmentType',
    'clearSelectedProvider',
    'setWeekInView',
    'selectedSlots',
    'minSlots',
    'setLocationTimeZone',
    'captchaV3Token',
  ]);
  const isMobile = useMatchMedia({ maxWidth: breakpoints.small.max });
  const [availableOpenings, setAvailableOpenings] = useState<number>(0);
  const {
    formDetails,
    isFormValid,
    setFormDetails,
    formError,
    isFormCompleted,
    resetFormDetails,
    submitAppointmentRequest,
    isSubmittingRequest,
  } = useAppointmentRequestForm(paymentTransactionId);

  const {
    data: locationData,
    isError,
    isLoading: isLoadingLocation,
  } = ScheduleQueries.useListLocations(locationId, BOOKING_SITE_HTTP_OPTIONS);
  const officeName = locationData?.officeName ?? '';
  const childLocations = locationData?.childrenLocations ?? [];
  const hasChildren = !!locationData?.childrenLocations?.length;

  useEffect(() => {
    if (isLoadingLocation) return;

    // for multi/single location, set location timezone (timezone is set based on parent location for multi)
    setLocationTimeZone(locationData?.timezone ?? '');

    if (hasChildren) return;

    // set location if its single/child location
    setSelectedLocation(
      locationData?.locationId ?? '',
      locationData?.officeName ?? '',
      locationData?.paymentLocationPortalSlug ?? ''
    );
  }, [locationData, isLoadingLocation, hasChildren]);

  const clearSelectedLocation = () => {
    // no need to clear location timezone as its set based on parent location
    if (hasChildren) {
      setSelectedLocation('', '', '');
    }
  };

  const reset = () => {
    clearSelectedAppointmentType();
    clearSelectedProvider();
    clearSelectedLocation();
    resetFormDetails();
    if (paymentLocationPortalSlug) {
      resetPaymentDetails?.();
    }
    complianceCheckBox.value = false;
  };

  const getAppointmentTypeStepValueElement = () => (
    <Text css={styles.cardTitle}>
      {t('Appointment Type')}
      <HeaderDot />
      <Text as='span' weight='light' color='light'>
        {selectedAppointmentType?.name ?? ''}
      </Text>
    </Text>
  );

  const displayProviderSelection = () => {
    let providerName = 'No Preference';
    if (typeof selectedProvider !== 'string') {
      providerName =
        `${selectedProvider?.publicDisplayName}` || `${selectedProvider?.firstName} ${selectedProvider?.lastName}`;
    }
    return (
      <Text as='span' css={styles.cardTitle}>
        {t('Provider & Time')}
        {!!selectedProvider && (
          <>
            <HeaderDot />
            <Text as='span' weight='light' color='light'>
              {providerName}
            </Text>
            <HeaderDot />
            {selectedSlots?.map((date, index, array) => {
              const formattedDate = formatDate(date, 'ddd, MMM D h:mma');
              return (
                <Text key={formattedDate} as='span' weight='light' color='light'>
                  {formattedDate}
                  {index + 1 !== array.length && ', '}
                </Text>
              );
            })}
          </>
        )}
      </Text>
    );
  };

  const complianceCheckBox = useFormField({
    type: 'checkbox',
    required: true,
  });

  // NOTE: First step of three to ensure proper 10DLC compliance in the booking site. This first step is to ensure that the user is aware that they are providing their phone number and that they actively consent to receiving text messages and phone calls from the office they're requesting an appointment from.
  const complianceConsent = () => {
    return (
      <section css={{ marginLeft: theme.spacing(1), display: 'flex', alignItems: 'start' }}>
        <CheckboxField {...complianceCheckBox} name='10dlc-compliance-consent' label='*' />
        {/* // NOTE: To avoid accidental clicks we opted to use a Text component instead of the label property in the CheckboxField component. */}
        <Text css={{ margin: theme.spacing(0, 0, 0, 1) }}>
          {t(
            "I understand that this form is using Google's reCaptcha to verify requests. By providing your phone number, you consent to receiving SMS text messages and phone calls from {{officeName}} – Online Scheduling and Text Connect, including texts and calls that promote our products and services. Text messages and data rates may apply. You may withdraw your consent at any time. Reply STOP to any text message to unsubscribe, or HELP for more options.",
            { officeName: selectedLocationName }
          )}
        </Text>
      </section>
    );
  };

  const isValidSlotSelection =
    (selectedSlots.length > 0 && selectedSlots.length === minSlots) ||
    (!!availableOpenings && selectedSlots.length === availableOpenings);
  const isValidSlotSelectionForV3 =
    selectedSlots.length > 0 && (selectedSlots.length <= minSlots || selectedSlots.length <= availableOpenings);

  const appointmentPaymentAmount = useMemo<number>(() => {
    if (selectedAppointmentType?.hasGlobalBookingDeposit && selectedAppointmentType?.globalBookingDepositAmount) {
      return selectedAppointmentType?.globalBookingDepositAmount;
    } else if (selectedAppointmentType?.hasBookingDeposit && selectedAppointmentType?.bookingDepositAmount) {
      return selectedAppointmentType?.bookingDepositAmount;
    }
    return 0;
  }, [selectedAppointmentType]);

  // clears the payment details if there is a payment error when user goes back from payment screen
  const handleOnPreviousInPaymentsModule = () => {
    if (!isPaymentSuccess) {
      resetPaymentDetails?.();
    }
  };

  // show payments module if location has portal slug id, selected appointment type has payment amount
  const showPaymentsModule = !!paymentLocationPortalSlug && !!selectedAppointmentType && !!appointmentPaymentAmount;

  const isInfoFormValid = isFormValid && (showPaymentsModule || !!captchaV3Token) && complianceCheckBox.value;

  // condition to enable submit request button
  const isSubmitRequestButtonEnabledInPaymentScreen =
    (isSchedularV3Enabled ? isValidSlotSelectionForV3 : isValidSlotSelection) && isPaymentSuccess && isInfoFormValid;

  // condition to show submit request button in payment screen if form is not valid or payment is not success
  const shouldShowSubmitRequestButtonInPaymentScreen = (!isInfoFormValid && !isPaymentSuccess) || formError;

  // condition to submit booking request on payment success
  const shouldSubmitBookingRequestOnPaymentSuccess =
    paymentTransactionId && showPaymentsModule && isSubmitRequestButtonEnabledInPaymentScreen;

  // useEffect to submit booking request on payment success
  useEffect(() => {
    if (shouldSubmitBookingRequestOnPaymentSuccess) {
      submitAppointmentRequest();
    }
  }, [shouldSubmitBookingRequestOnPaymentSuccess]);

  if (isLoadingLocation || isLoadingFeatureFlagStatus) {
    return (
      <div css={[dsStyles.flexCenter, { height: '50vh' }]}>
        <SpinningLoader size='large' css={styles.spinningLoader} />
      </div>
    );
  }

  if (isError) {
    return <Navigate to='/error' replace />;
  }

  const patientFullName = getFullName(formDetails?.patientInfo);
  return (
    <Page css={styles.page}>
      <div css={styles.pageHeader}>
        {officeName ? (
          <Heading level={1} className='request-office-name'>
            {officeName}
          </Heading>
        ) : (
          <span className='request-office-logo'>
            {/* Placeholder until we get the office logo */}
            <WeaveLogo />
          </span>
        )}
        <Heading level={1} className='request-title'>
          {t('Request an Appointment')}
        </Heading>
      </div>
      {isFormCompleted ? (
        <SuccessRequestAppointment reset={reset} />
      ) : (
        <Stepper maxWidth={880} isHorizontal={isMobile} css={styles.stepper(isMobile)}>
          {/* select location step */}
          {hasChildren && (
            <Stepper.Card stepValue={selectedLocationName}>
              <Stepper.Title>{t('Select a Location')}</Stepper.Title>
              <LocationSelect locations={childLocations} />
              <Stepper.ButtonBar>
                <Stepper.ClearButton clearStep={clearSelectedLocation}>{t('Clear')}</Stepper.ClearButton>
                <Stepper.NextButton isValid={!!selectedLocationId} />
              </Stepper.ButtonBar>
            </Stepper.Card>
          )}
          {/* select appointment type step */}
          <Stepper.Card stepValue={getAppointmentTypeStepValueElement()}>
            <Stepper.Title>{t("Let's get you scheduled")}</Stepper.Title>
            <ApptTypeSelect />
            <Stepper.ButtonBar>
              <Stepper.ClearButton clearStep={clearSelectedAppointmentType}>{t('Clear')}</Stepper.ClearButton>
              {hasChildren && <Stepper.PreviousButton />}
              <Stepper.NextButton isValid={!!selectedAppointmentType?.id} />
            </Stepper.ButtonBar>
          </Stepper.Card>
          {/* select provider & time step */}
          <Stepper.Card stepValue={displayProviderSelection()}>
            <Stepper.Title>{t('Choose your provider & time')}</Stepper.Title>
            <Stepper.Content>
              <ProviderSelect setAvailableOpenings={setAvailableOpenings} />
            </Stepper.Content>
            <Stepper.ButtonBar>
              {selectedProvider && (
                <Stepper.ClearButton
                  clearStep={() => {
                    clearSelectedProvider();
                    setWeekInView(selectedAppointmentLeadTime);
                  }}
                >
                  {t('Clear')}
                </Stepper.ClearButton>
              )}
              <Stepper.PreviousButton />
              <Stepper.NextButton isValid={isSchedularV3Enabled ? isValidSlotSelectionForV3 : isValidSlotSelection} />
            </Stepper.ButtonBar>
          </Stepper.Card>
          <Stepper.Card>
            <Stepper.Title icon={<Icon name='forms' />}>{t('Patient info')}</Stepper.Title>
            <InfoForm
              formDetails={formDetails || {}}
              setFormDetails={setFormDetails}
              complianceConsent={complianceConsent()}
              isV3CaptchaEnabled={!showPaymentsModule}
            />
            <Stepper.ButtonBar helperText={formError}>
              <Stepper.PreviousButton />
              <Stepper.NextButton
                isValid={isInfoFormValid}
                onSubmit={!showPaymentsModule ? submitAppointmentRequest : undefined}
              >
                {showPaymentsModule ? t('Next') : t('Submit Request')}
              </Stepper.NextButton>
            </Stepper.ButtonBar>
          </Stepper.Card>
          {showPaymentsModule && (
            <Stepper.Card>
              <Stepper.Title icon={<Icon name='credit-card' />}>
                {`${patientFullName || t('Payment')} • $${appointmentPaymentAmount}`}
              </Stepper.Title>
              <Stepper.Content>
                {renderAppointmentTypePaymentsModule(
                  appointmentPaymentAmount,
                  paymentLocationPortalSlug,
                  !isFormValid ? formError : ''
                )}
              </Stepper.Content>
              <Stepper.ButtonBar>
                <Stepper.PreviousButton onClick={handleOnPreviousInPaymentsModule} />
                {shouldShowSubmitRequestButtonInPaymentScreen && (
                  <Stepper.NextButton
                    isValid={isSubmitRequestButtonEnabledInPaymentScreen}
                    onSubmit={submitAppointmentRequest}
                  >
                    {t('Submit Request')}
                  </Stepper.NextButton>
                )}
                {isPaymentError && (
                  <Stepper.NextButton isValid onSubmit={resetPaymentDetails}>
                    {t('Retry Payment')}
                  </Stepper.NextButton>
                )}
                {isSubmittingRequest && <SpinningLoader css={{ marginLeft: theme.spacing(2) }} />}
              </Stepper.ButtonBar>
            </Stepper.Card>
          )}
        </Stepper>
      )}
    </Page>
  );
};

const HeaderDot = () => (
  <Text as='span' css={{ margin: theme.spacing(1) }}>
    &bull;
  </Text>
);

const styles = {
  page: css({
    maxWidth: 880,
    margin: 'auto',
    padding: theme.spacing(2),
    '.request-office-logo, .request-office-name': {
      fontSize: theme.fontSize(24),
      margin: theme.spacing(0),
      '@media (max-width: 699px)': {
        fontSize: theme.fontSize(20),
        marginRight: theme.spacing(1),
      },
    },
    '.request-title': {
      fontSize: theme.fontSize(24),
      color: theme.colors.neutral50,
      margin: theme.spacing(0),
      textAlign: 'right',
      '@media (max-width: 699px)': {
        fontSize: theme.fontSize(20),
      },
    },
    'h2.step-content-title': {
      width: 'auto',
    },
  }),
  stepper: (isMobile: boolean) =>
    css({
      margin: isMobile ? theme.spacing(-1) : theme.spacing(1),
    }),
  pageHeader: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.colors.neutral20}`,
  }),
  cardTitle: css({
    fontSize: theme.fontSize(20),
    margin: theme.spacing(0),
    width: 'fit-content',
    span: {
      fontWeight: 'normal',
    },
    '@media (max-width: 699px)': {
      fontSize: theme.fontSize(16),
    },
  }),
  cardWidth: css({
    maxWidth: 692,
  }),
  spinningLoader: css({
    margin: 'auto',
  }),
};
