import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';
import { CustomAxisTickProps } from '../types';

const defaultAxisMargin = 4;
const defaultClipLength = 15;

export const XAxisLabelValueTick = ({
  clipLength = defaultClipLength,
  color,
  doNotClip,
  highlightOnSegmentHover,
  isHovered,
  label = '',
  labelColor,
  labelWeight,
  value,
  valueFontSize = 20,
  x,
  y,
}: CustomAxisTickProps) => {
  const { Tooltip: Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top' });
  const opacity = highlightOnSegmentHover && typeof isHovered === 'boolean' && !isHovered ? 0.5 : 1;
  const labelMod = !doNotClip && label.length > clipLength ? `${label.slice(0, clipLength)}...` : label;
  const hasValue = typeof value !== 'undefined';

  return (
    <g {...(label !== labelMod ? triggerProps : {})}>
      <text
        fill={theme.font.colors.default}
        style={{
          opacity,
          textAnchor: 'middle',
        }}
        transform={x && y ? `translate(${x}, ${y + 16})` : `translate(0, ${defaultAxisMargin + valueFontSize})`}
      >
        {hasValue && (
          <tspan fontSize={valueFontSize} fontWeight='bold' fill={color || theme.font.colors.default}>
            {value}
          </tspan>
        )}
        {!!labelMod && (
          <tspan
            fontSize={14}
            fontWeight={highlightOnSegmentHover && isHovered ? 'bold' : labelWeight}
            x='0'
            dy={hasValue ? valueFontSize : 0}
            fill={labelColor || theme.font.colors.default}
            textDecoration={highlightOnSegmentHover && isHovered ? 'underline' : 'none'}
          >
            {labelMod}
          </tspan>
        )}
      </text>

      <Tooltip {...tooltipProps}>
        <Text size='medium' color='white'>
          {label}
        </Text>
      </Tooltip>
    </g>
  );
};
