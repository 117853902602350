import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';
import { PhoneNumbersPageTab } from '../constants';

interface PhoneNumbersPageStore {
  selectedTab: PhoneNumbersPageTab | null;
  setSelectedTab: (tab: PhoneNumbersPageTab | null) => void;

  // this is used for both port-request table to highlight row
  selectedPortingId: string | null;
  setSelectedPortingId: (portingId: string | null) => void;

  setPageSelectionInfo: (tab: PhoneNumbersPageTab, portingId: string) => void;
}

export const usePhoneNumbersPageStore = createStoreWithSubscribe<PhoneNumbersPageStore>(
  (set) => ({
    selectedTab: null,
    setSelectedTab: (tab) => {
      set({ selectedTab: tab }, false, { type: 'SET_SELECTED_TAB' });
    },
    selectedPortingId: null,
    setSelectedPortingId: (portingId) => {
      set({ selectedPortingId: portingId }, false, { type: 'SET_SELECTED_PORTING_ID' });
    },

    setPageSelectionInfo: (tab, portingId) => {
      set({ selectedTab: tab, selectedPortingId: portingId }, false, { type: 'SET_PAGE_SELECTION_INFO' });
    },
  }),
  {
    name: 'PhoneNumbersPageStore',
    trace: true,
  }
);

export const usePhoneNumbersPageShallowStore = createShallowStore(usePhoneNumbersPageStore);
