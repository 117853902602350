import { createRef, forwardRef, useImperativeHandle } from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useLargeDownloads, UseLargeDownloads } from '../hooks/use-large-downloads';
import { DownloadProgressCard } from './download-progress-card';

export const largeDownloadsRef = createRef<UseLargeDownloads>();

export const LargeDownloads = forwardRef((_props, ref) => {
  const root = document.getElementById('root');
  const chatbot = document.getElementById('ada-entry');
  const { downloadsWaitState, waitForDownload } = useLargeDownloads();

  useImperativeHandle(
    ref,
    () => ({
      downloadsWaitState,
      waitForDownload,
    }),
    []
  );

  return root
    ? createPortal(
        <div className='large-downloads-container' css={getStyles(!!chatbot)}>
          {Object.entries(downloadsWaitState).map(([key, value]) => (
            <DownloadProgressCard options={value} key={key} />
          ))}
        </div>,
        root
      )
    : null;
});

LargeDownloads.displayName = 'LargeDownloads';

const getStyles = (hasChatbot: boolean) => css`
  bottom: ${theme.spacing(hasChatbot ? 7 : 1)};
  position: fixed;
  right: ${theme.spacing(1)};
  z-index: 10;
`;
