import { Vertical } from '@weave/schema-gen-ts/dist/shared/vertical/vertical.pb';
import { useQueryClient } from 'react-query';
import {
  useCustomizationFlagShallowStore,
  CustomizationFlagQueries,
  CustomizationFlagTypes,
} from '@frontend/api-customization-flags';
import { DataSourcesHooks, DataSourcesQueries } from '@frontend/api-data-sources';
import { LocationsApi } from '@frontend/api-locations';
import { authnClient } from '@frontend/auth';
import { getUser, setLoginData } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { i18next, useTranslation } from '@frontend/i18n';
import { Location, useLastUsedVerticalShallowStore, useLocationDataShallowStore } from '@frontend/location-helpers';
import { SchemaFeatureFlags } from '@frontend/schema';
import { FeatureFlag, useFeatureFlagShallowStore } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { multiLocationCheck } from './multi-location-checker';

interface ConfigureLocationDataFnParams {
  newLocationId: string;
  onSuccess?: (locationInfo: Location) => Promise<void> | void;
  onError?: (error: string) => Promise<void> | void;
}

export const determineOrgID = (locationInfo: Location): string => {
  return !!locationInfo.ParentID ? locationInfo.ParentID : locationInfo.LocationID;
};

const GENERIC_ERROR_MESSAGE = i18next.t(
  'Failed to load location information. Please contact support at (888) 579-5668 option 2',
  { ns: 'base' }
);

export const useConfigureLocation = () => {
  const { setLocationIdHeader } = http;
  const { multiSelectedIds, setIsConfiguredLocationInfo, setMultiSelectedIds, setLocationInfo } =
    useLocationDataShallowStore(
      'multiSelectedIds',
      'setIsConfiguredLocationInfo',
      'setMultiSelectedIds',
      'setLocationInfo'
    );
  const { t } = useTranslation('base');
  const { setLastUsedVertical } = useLastUsedVerticalShallowStore('setLastUsedVertical');
  const configureFeatureFlags = useConfigureFeatureFlags();
  const configureCustomizationFlags = useConfigureCustomizationFlags();
  const configureDemoLocationSourceIds = useConfigureDemoLocationSourceIds();
  const queryClient = useQueryClient();

  const configureLocationData = async ({
    newLocationId,
    onSuccess,
    onError,
  }: ConfigureLocationDataFnParams): Promise<void> => {
    setIsConfiguredLocationInfo(false);
    try {
      setLocationIdHeader(newLocationId);

      const locationInfo = await LocationsApi.getLocation(newLocationId).catch(() => {
        throw new Error(GENERIC_ERROR_MESSAGE);
      });

      queryClient.setQueryData(['location-data', newLocationId], locationInfo);
      // casting here because the Vertical from the getLocation call is capital case, whereas the Vertical enum is upper case.
      setLastUsedVertical(locationInfo.Vertical.toUpperCase() as Vertical);

      const { isMultiLocation, isParentLocation, childLocations } = await multiLocationCheck({
        locationId: locationInfo.LocationID,
        parentId: locationInfo.ParentID,
      }).catch(() => {
        throw new Error(GENERIC_ERROR_MESSAGE);
      });

      const orgID = determineOrgID(locationInfo);
      try {
        await authnClient.assureSessionToken(orgID);
      } catch (error) {
        // ignore the error for now
      }

      setLocationInfo({ location: locationInfo, childLocations, isMultiLocation, isParentLocation });
      const user = getUser();
      if (user) {
        setLoginData(user.userID, { lastLocationId: newLocationId });
      }
      localStorage.setItem('locationId', locationInfo.LocationID);
      sentry.instance.setContext('Location', {
        name: locationInfo.Name,
        id: locationInfo.LocationID,
      });
      sentry.instance.setTag('locationId', locationInfo.LocationID);

      const set = new Set(multiSelectedIds);
      set.add(locationInfo.LocationID);
      setMultiSelectedIds([...set]);

      await configureFeatureFlags(newLocationId).catch(() => {
        throw new Error(GENERIC_ERROR_MESSAGE);
      });

      const customizationFlags = await configureCustomizationFlags(newLocationId).catch(() => {
        throw new Error(GENERIC_ERROR_MESSAGE);
      });

      await configureDemoLocationSourceIds({
        location: locationInfo,
        customizationFlags,
      }).catch(() => {
        throw new Error(
          t(
            "Viewing Demo Location. If you believe you're seeing this message in error, please contact support at (888) 579-5668 option 2"
          )
        );
      });

      if (onSuccess && typeof onSuccess === 'function') {
        await onSuccess(locationInfo);
      }
    } catch (error) {
      if (onError && typeof onError === 'function') {
        const message = error instanceof Error ? error.message : GENERIC_ERROR_MESSAGE;
        await onError(message);
      }
      console.error(error);
    }

    setIsConfiguredLocationInfo(true);
  };

  return { configureLocationData };
};

const useQueryFeatureFlags = () => {
  const qc = useQueryClient();
  return ({ locationId }: { locationId: string }) =>
    qc.fetchQuery({
      queryKey: [locationId, 'features'],
      queryFn: () => {
        return SchemaFeatureFlags.ListLocationFlags({ locationId }, { headers: { 'Location-Id': locationId } });
      },
    });
};

const useConfigureFeatureFlags = () => {
  const { setFlags, setFlagValues } = useFeatureFlagShallowStore('setFlags', 'setFlagValues');
  const ffQuery = useQueryFeatureFlags();
  return async (locationId: string) => {
    const featureFlags = await ffQuery({ locationId: locationId }).catch((err) => {
      if (err.message.includes('forbidden locationID')) {
        localStorage.removeItem('locationId');
        localStorage.setItem('sent-to-location-picker-reason', 'forbidden');
        location.reload();
      }
    });

    const { flags, values } = featureFlags?.flags?.reduce(
      (acc, flag) => {
        if (flag.flag?.name) {
          acc.flags[flag.flag.name] = {
            Name: flag.flag?.name ?? '',
            DisplayName: flag.flag?.description ?? '',
            Value: !!flag.value,
          };
          acc.values[flag.flag.name] = !!flag.value;
        }
        return acc;
      },
      { flags: {}, values: {} } as { flags: Record<string, FeatureFlag>; values: Record<string, boolean> }
    ) ?? { flags: {}, values: {} };

    setFlags(flags);
    setFlagValues(values);
  };
};

const useQueryCustomizationFlags = () => {
  const qc = useQueryClient();
  return ({ locationId }: { locationId: string }) =>
    qc.fetchQuery({
      queryKey: [locationId, 'customization-flags'],
      queryFn: () => CustomizationFlagQueries.getCustomizationFlagsByLocationId(locationId),
    });
};

const useConfigureCustomizationFlags = () => {
  const { setFlags } = useCustomizationFlagShallowStore('setFlags');
  const cfQuery = useQueryCustomizationFlags();
  return async (locationId: string) => {
    const customizationFlags = await cfQuery({ locationId: locationId });
    setFlags(customizationFlags);
    return customizationFlags;
  };
};

const useConfigureDemoLocationSourceIds = () => {
  const { setDemoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('setDemoSourceIds');
  const qc = DataSourcesQueries.useQueryDemoLocationSourcesIds();
  return async (config: { location: Location; customizationFlags: CustomizationFlagTypes.CustomizationFlag[] }) => {
    const sourceIds = await qc(config);
    setDemoSourceIds(sourceIds);
  };
};
