import { useContext } from 'react';
import { BaseEdge, Edge, EdgeLabelRenderer, EdgeProps, getSmoothStepPath } from '@xyflow/react';
import { theme } from '@frontend/theme';
import { Button, Heading, Text, Tray, useModalControl } from '@frontend/design-system';
import { InteractionContext } from '../provider';

const BASIC_EDGE_STYLES = {
  stroke: theme.colors.neutral70,
  strokeWidth: 2,
};

export function AnimatedSVGEdge({ id, sourceX, sourceY, targetX, targetY, sourcePosition, targetPosition }: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={BASIC_EDGE_STYLES} />
    </>
  );
}

export function AnimatedSVGEdgeReverse({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
}: EdgeProps) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} style={BASIC_EDGE_STYLES} />
    </>
  );
}

export function PhoneTreeEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps<Edge<{ label?: string }>>) {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    centerY: targetY - 50,
  });

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={{ ...BASIC_EDGE_STYLES, ...style }} />
      {data?.label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${targetX}px,${targetY - 20}px)`,
              pointerEvents: 'all',
              width: 24,
              height: 24,
              backgroundColor: theme.colors.black,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className='nodrag nopan'
          >
            <Text as='span' color='white' weight='bold' size='small'>
              {data.label}
            </Text>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
}

export function BasicEdge({
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}: EdgeProps<Edge<{ label?: string }>>) {
  const { modalProps, triggerProps } = useModalControl();

  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { mode } = useContext(InteractionContext);

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={{ ...BASIC_EDGE_STYLES, ...style }}
        className='nodrag nopan'
      />
      {data?.label && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -300%) translate(${targetX}px,${targetY}px)`,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
              padding: theme.spacing(0, 1),
              backgroundColor: theme.colors.black,
              borderRadius: theme.borderRadius.small,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            className='nodrag nopan'
          >
            <Text as='span' color='white' weight='bold' size='small'>
              {data.label}
            </Text>
          </div>
        </EdgeLabelRenderer>
      )}

      {mode === 'edit' && (
        <EdgeLabelRenderer>
          <Button
            variant='secondary'
            {...triggerProps}
            className='nodrag nopan'
            css={{
              position: 'absolute',
              transform: data?.label
                ? `translate(-50%, -150%) translate(${targetX}px,${targetY}px)`
                : `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              // everything inside EdgeLabelRenderer has no pointer events by default
              // if you have an interactive element, set pointer-events: all
              pointerEvents: 'all',
              backgroundColor: theme.colors.white,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: theme.spacing(1),
              height: 30,
              width: 30,
            }}
            iconName='plus'
          ></Button>
        </EdgeLabelRenderer>
      )}

      <Tray width='medium' {...modalProps}>
        <Heading level={2}>Add Step</Heading>
      </Tray>
    </>
  );
}
