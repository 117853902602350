import { SchemaIO, SchemaPaymentsCommandsService } from '@frontend/schema';

type StripeAccountType = 'STANDARD' | 'EXPRESS';

type MerchantPlanType = 'CardNotPresent' | 'FullPayments' | 'Full';

export enum MerchantPlanTypes {
  Partial = 'CardNotPresent',
  FullPayments = 'FullPayments',
  Full = 'Full', // Remnant of old naming, some offices still have this as their plan name
}

export type Processor = {
  id: string;
  processorType: string;
  isActive: boolean;
  stripeId: string;
  chargesEnabled: boolean;
  displayName: string;
  mcc: string;
  payoutsEnabled: boolean;
  supportPhone: string;
  supportEmail: string;
  stripeAccountType: StripeAccountType;
  stripeOnboarded: boolean;
};

export type Merchant = {
  id: string;
  name: string;
  email: string;
  receiptEmail: string;
  phoneNumber: string;
  statementDescriptor?: string;
  processors?: Processor[];
  planName?: MerchantPlanType;
  terminalLocation?: string;
  hasTerminalLocation?: boolean;
  lastActivityAt?: string;
  locationPortalSlug: string;
  onlineBillPayLink: string;
  affirmAvailable?: boolean;
};

export type PaymentSettingsState = {
  merchant?: Merchant;
  logo?: string; //image as blob
  url?: string;
  hasPayments: boolean;
};
export type UpdateMerchant = Partial<
  Pick<Merchant, 'name' | 'email' | 'receiptEmail' | 'phoneNumber' | 'statementDescriptor'>
>;

export enum StripeBusinessTypes {
  INDIVIDUAL = 'INDIVIDUAL',
  COMPANY = 'COMPANY',
  NON_PROFIT = 'NON_PROFIt',
}

export type StripeOnboardingLink = {
  next: string;
};

export interface PollWorkflowResponse {
  id: string;
  runId: string;
  status: string;
  urlPolicy?: string;
  error?: string;
  errors?: string[];
  typeName: string;
}

export interface PaymentNotificationSettingsResponse {
  location_id: string;
  terminal_enabled: boolean;
  manual_pay_enabled: boolean;
  text_to_pay_enabled: boolean;
  online_bill_pay_enabled: boolean;
  mobile_tap_to_pay_enabled: boolean;
  online_scheduling_enabled: boolean;
  payment_plan_enabled: boolean;
}

export type PaymentNotificationType = Exclude<
  Extract<keyof PaymentNotificationSettingsResponse, string>,
  'location_id'
>;

export type PaymentsUnrecordedCountResponse = SchemaIO<
  (typeof SchemaPaymentsCommandsService)['GetUnrecordedPaymentsCount']
>['output'];

export type PaymentsUnrecordedCountRequest = SchemaIO<
  (typeof SchemaPaymentsCommandsService)['GetUnrecordedPaymentsCount']
>['input'];
