import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { AppointmentAnalyticsApi, AppointmentAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { queryKeys } from '../../../query-keys';
import { DateRange, formatDateByTimezone } from '../../../utils';
import { useAppointmentAnalyticsShallowStore } from './use-appointment-analytics-store';
import { useAppointmentAnalyticsDemoData } from './use-appointment-demo-data';
import { useIsAppointmentDemoAccount } from './use-is-appointment-demo-account';

const defaultChartsData: AppointmentAnalyticsTypes.ChartsData = {
  allAppointmentCounts: {},
  patientsTypeCounts: {},
  pmsStatusCounts: {},
  weaveStatusCounts: {},
};

interface Props {
  dateRangeMap?: Record<string, DateRange>;
  customFilters?: AppointmentAnalyticsTypes.Filters;
}

export const useFetchAggregatedAppointmentsData = ({ customFilters, dateRangeMap }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { filters } = useAppointmentAnalyticsShallowStore('filters');
  const isDemoAccount = useIsAppointmentDemoAccount();
  const demoData = useAppointmentAnalyticsDemoData();
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'APPOINTMENT',
  });
  const filtersToUse = customFilters || filters;

  const getChartLabelText = useCallback(
    (locationId: string, date: string) => {
      return filtersToUse.LocationID?.length === 1 ? formatDateByTimezone(date) : locationNames[locationId];
    },
    [filtersToUse.LocationID?.length, locationNames]
  );

  const queryString = useMemo(() => {
    // Use only dates (without timestamp) for query string to avoid unnecessary and repeated api calls
    const startDate = dayjs(filtersToUse.StartDate).format('YYYY-MM-DD');
    const endDate = dayjs(filtersToUse.EndDate).format('YYYY-MM-DD');
    return `${startDate}-${endDate}-${filtersToUse.LocationID?.join('-')}-${filtersToUse.IsPastData}-${
      filtersToUse.TimeZone
    }`;
  }, [filtersToUse]);

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.appointmentAnalyticsCharts(queryString),
    queryFn: () => AppointmentAnalyticsApi.getAppointmentChartsData(filtersToUse, dateRangeMap),
    onError: () => {
      alert.error(t("Couldn't load the appointment aggregated data. Please try again."));
    },
    enabled: !isDemoAccount && !!filtersToUse.StartDate && !!filtersToUse.EndDate && !!filtersToUse.LocationID?.length,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  const formattedData = useMemo(() => {
    const dataToUse = isDemoAccount ? demoData?.chartsData : data;

    return dataToUse
      ? dataToUse.reduce((final, { aggregateData, AppointmentDate, LocationID }) => {
          const label = getChartLabelText(LocationID, AppointmentDate);
          const { PatientType, PMSStatus = {}, TotalApts, WeaveStatus = {} } = aggregateData;

          return {
            ...final,
            allAppointmentCounts: {
              ...final.allAppointmentCounts,
              [label]: (final.allAppointmentCounts[label] || 0) + TotalApts,
            },
            patientsTypeCounts: {
              ...final.patientsTypeCounts,
              [label]: {
                ...final.patientsTypeCounts[label],
                existingPatient:
                  (final.patientsTypeCounts[label]?.Existing_Patient || 0) + (PatientType?.Existing_Patient || 0),
                newPatient: (final.patientsTypeCounts[label]?.New_Patient || 0) + (PatientType?.New_Patient || 0),
                retained: (final.patientsTypeCounts[label]?.Retained || 0) + (PatientType?.Retained || 0),
              },
            },
            pmsStatusCounts: {
              ...final.pmsStatusCounts,
              [label]: {
                ...final.pmsStatusCounts[label],
                ...Object.entries(PMSStatus).reduce(
                  (f, [key, value]) => ({
                    ...f,
                    [key.trim() || 'other']: (final.pmsStatusCounts[label]?.[key] || 0) + value,
                  }),
                  {}
                ),
              },
            },
            weaveStatusCounts: {
              ...final.weaveStatusCounts,
              [label]: {
                ...final.weaveStatusCounts[label],
                ...Object.entries(WeaveStatus).reduce(
                  (f, [key, value]) => ({ ...f, [key]: (final.weaveStatusCounts[label]?.[key] || 0) + value }),
                  {}
                ),
              },
            },
          };
        }, defaultChartsData)
      : defaultChartsData;
  }, [data, isDemoAccount, demoData?.chartsData, locationNames]);

  return {
    data: formattedData,
    isLoading,
  };
};
