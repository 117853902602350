import { css } from '@emotion/react';
import { Link } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, TextLink } from '@frontend/design-system';
import { useRoutes } from '../providers/routes.provider';

export default function NotFoundPage() {
  const { t } = useTranslation();
  const { isRedirecting } = useRoutes();
  return (
    <main
      css={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%' }}
    >
      {isRedirecting ? (
        <Heading
          css={css`
            color: ${theme.colors.neutral30};
            margin-top: ${theme.spacing(2)};
          `}
          level={3}
        >
          {t('Redirecting to New Page...')}
        </Heading>
      ) : (
        <>
          <Heading css={{ 'margin-bottom': theme.spacing(1) }}>{t('404 - Page Not Found')}</Heading>
          <TextLink as={Link} to={'/'}>
            {t('Go home')}
          </TextLink>
        </>
      )}
    </main>
  );
}
