import { useCallback, useEffect, useRef, memo, MutableRefObject } from 'react';
import { EventType } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-call/v1/call_pops.pb';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { NotificationQueries } from '@frontend/api-notifications';
import { SoftphoneTypes } from '@frontend/api-softphone';
import { i18next, useTranslation } from '@frontend/i18n';
import { isPhoneNumber } from '@frontend/phone-numbers';
import { CallPopActionHandler, useCallPopStateSync, useSubscribeToPopAction } from '@frontend/pop';
import { allowedNotificationTypes } from '@frontend/settings-routing';
import {
  isAutoAnswerCall,
  isIntercomCall,
  isOccupiedParkSlot,
  isTerminatedCall,
  useSoftphoneCallActions,
  useSoftphoneCallState,
  useSoftphoneEventSubscription,
  useSoftphoneParkSlots,
  useSoftphoneSettings,
} from '@frontend/softphone2';
import TempoTracing from '@frontend/tempo-tracing';
import { sentry } from '@frontend/tracking';
import { PhoneEventV2Payload, useWebsocketEventSubscription } from '@frontend/websocket';
import beep from '../audio/call-waiting-indicator.mp3';
import ring from '../audio/ringing.mp3';

type CallPopManagerProps = {
  softphoneData: SoftphoneTypes.SoftphoneData | undefined;
  isCallWaitingIndicatorBeepEnabled: boolean;
  hasActiveCalls: boolean;
};

type StartPlaybackProps = {
  audio: HTMLAudioElement;
  audioSetIntervals: Record<string, NodeJS.Timeout>;
  isCallWaitingIndicatorBeepEnabled: boolean;
  hasActiveCalls: boolean;
  callId: string;
};

const startPlayback = ({
  audio,
  isCallWaitingIndicatorBeepEnabled,
  hasActiveCalls,
  audioSetIntervals,
  callId,
}: StartPlaybackProps) => {
  audio.loop = isCallWaitingIndicatorBeepEnabled && hasActiveCalls ? false : true;
  audio.play();
  if (isCallWaitingIndicatorBeepEnabled && hasActiveCalls) {
    audioSetIntervals[callId] = setInterval(() => {
      audio.play();
    }, 7000);
  }
};
const DEVTOOLS_ID = 'devtools';
export const SoftphoneCallPopManager = memo(function SoftphoneCallPopManager({
  isCallWaitingIndicatorBeepEnabled,
  hasActiveCalls,
  softphoneData,
}: CallPopManagerProps) {
  const { t } = useTranslation('softphone');
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const isDoNotDisturb = useSoftphoneSettings((ctx) => ctx.isDoNotDisturb);
  const answer = useSoftphoneCallActions((ctx) => ctx.answerIncomingCall);
  const reject = useSoftphoneCallActions((ctx) => ctx.endCall);
  const parkSlots = useSoftphoneParkSlots((ctx) => ctx.parkSlotsWithPresence);
  const { addNotification, removeNotification } = useCallPopStateSync();
  const inboundCallWebsocketMessages = useRef<PhoneEventV2Payload[]>([]);
  const currentInboundTrace = useRef<string | undefined>(undefined);
  const nonTerminatedCalls = calls.filter((call) => !isTerminatedCall(call));
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const audioSet = useRef<Record<string, HTMLAudioElement>>({});
  const audioSetIntervals = useRef<Record<string, NodeJS.Timeout>>({});

  const { data: notificationSettings } = NotificationQueries.useNotificationSettingsQuery();
  const callPopNotificationId = allowedNotificationTypes.find(({ type }) => type === 'incoming-call')?.id;
  const autoShowCallerProfile =
    callPopNotificationId && !!notificationSettings?.find(({ id }) => id === callPopNotificationId)?.sendNotification;

  useSoftphonePopActions({ audioSet });

  useWebsocketEventSubscription('PhoneSystemEventsV2', (payload) => {
    const event = payload.params.event;
    if (event === 'inbound_call') {
      const traceId = (payload as PhoneEventV2Payload).trace_id;
      if (!!traceId) {
        TempoTracing.continueTrace(traceId, TempoTracing.spanNameGenerators.callPopSoftphoneSpan(event), {
          parentSpanName: TempoTracing.spanNameGenerators.websocketSpan(),
          endTraceOnTimeout: true,
        });
      }
      inboundCallWebsocketMessages.current = [payload as PhoneEventV2Payload];
    }
  });

  const getWebsocketData = (phone: string) =>
    new Promise<PhoneEventV2Payload | undefined>((resolve, reject) => {
      const interval = setInterval(() => {
        const payload = inboundCallWebsocketMessages.current.find(
          (payload) => payload.params.caller_id_number === phone || phone === DEVTOOLS_ID
        );

        /**
         * Only resolve if there is a payload, if we never resolve here, it will be caught by the timeout
         * and we will reject the promise
         **/
        if (payload) {
          resolve(payload);
        }
      }, 200);

      setTimeout(() => {
        clearInterval(interval);
        reject(new Error('No payload found'));
      }, 2000);
    }).catch(() => undefined);

  useSoftphoneEventSubscription(
    'incoming-call.received',
    async (call) => {
      let traceId: string | undefined;
      try {
        if (!softphoneData) {
          return;
        }

        const otherActiveCalls = nonTerminatedCalls.filter((c) => c.id !== call.id);

        const isParkRingback = () => {
          const occupiedSlots = parkSlots.filter(isOccupiedParkSlot);
          return occupiedSlots.some(
            (slot) =>
              slot.remoteParty.displayName === call.remoteParty.displayName ||
              slot.remoteParty.uri.split('@')[0]?.replace('sip:', '') === call.remoteParty.uri
          );
        };

        const shouldAutoReject =
          (isIntercomCall(call.invitation) || isAutoAnswerCall(call.invitation)) && !!otherActiveCalls.length;
        if (shouldAutoReject) {
          reject(call);
          return;
        }

        const shouldAutoAnswer = isIntercomCall(call.invitation) || isAutoAnswerCall(call.invitation);
        if (shouldAutoAnswer) {
          answer(call);
          return;
        }

        const shouldIgnore = isParkRingback() || isDoNotDisturb;

        if (shouldIgnore) {
          console.info('Not showing callpop because it is a park ringback or doNotDisturb is turned on');
          return;
        }

        const isProbablyExtension = call.remoteParty.uri.length < 5;
        const extension = isProbablyExtension
          ? softphoneData?.extensions.find(
              (ext) => ext.presenceUri === call.remoteParty.uri || `${ext.number}` === call.remoteParty.uri
            )
          : undefined;

        const shouldWaitForWebsocket = !extension;
        const websocketData = await (shouldWaitForWebsocket ? getWebsocketData(call.remoteParty.uri) : undefined);

        if (!audioSet.current[call.id]) {
          audioSet.current[call.id] =
            isCallWaitingIndicatorBeepEnabled && hasActiveCalls ? new Audio(beep) : new Audio(ring);
        }

        let notification: Parameters<typeof addNotification>[0] | undefined;
        if (websocketData) {
          // For events not originating from an extension
          traceId = websocketData.trace_id;

          notification = {
            id: call.id,
            timestamp: new Date().toDateString(),
            payload: {
              type: 'softphone',
              autoShowCallerProfile: !!autoShowCallerProfile,
              recipientLocationName: websocketData.params.recipient_location_name,
              callerContext: websocketData.params.caller_context,
              headOfHousehold: websocketData.params.call_pop_head_of_household,
              contacts: getContacts(websocketData, demoSourceIds),
            },
            trace_id: traceId,
          };
        } else {
          notification = {
            id: call.id,
            timestamp: new Date().toDateString(),
            payload: {
              type: 'softphone',
              autoShowCallerProfile: !!autoShowCallerProfile,
              recipientLocationName: '',
              callerContext: '',
              isCallWaitingIndicatorBeepEnabled,
              hasActiveCalls,
              headOfHousehold: {
                head_of_household_id: '',
                head_of_household_person_id: '',
              },
              contacts: [
                {
                  birthdate: 0,
                  callerName: extension ? extension.name : call.remoteParty.displayName ?? t('Unknown Caller'),
                  callerNumber: extension
                    ? t(`Ext. ${extension.number}`)
                    : isPhoneNumber(call.remoteParty.uri)
                    ? call.remoteParty.uri
                    : '',
                  gender: '',
                  householdId: '',
                  matchedLocationId: '',
                  patientId: '',
                  personId: '',
                  recipientLocationName: '',
                  source: '',
                },
              ],
            },
          };
        }

        if (notification) {
          if (!!notification.trace_id) {
            currentInboundTrace.current = notification.trace_id;
            TempoTracing.addEvent(
              notification.trace_id,
              TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'),
              {
                eventMessage: 'Notification presented to user',
                eventType: EventType.EVENT_TYPE_INFO,
              }
            );
          }
          addNotification(notification);
        }

        if (notification && (!hasActiveCalls || !!isCallWaitingIndicatorBeepEnabled)) {
          startPlayback({
            audio: audioSet.current[call.id],
            isCallWaitingIndicatorBeepEnabled,
            hasActiveCalls,
            audioSetIntervals: audioSetIntervals.current,
            callId: call.id,
          });
        }

        //we don't need to hang on to these any longer than a few seconds
        inboundCallWebsocketMessages.current = [];
      } catch (err) {
        sentry.error({
          topic: 'notification',
          error: err,
          addContext: {
            name: 'notification',
            context: {
              errMessage: 'error during websocket processing in softphone, failed to handle',
            },
          },
        });
        console.error(err);
        if (!!traceId) {
          TempoTracing.addEvent(traceId, TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'), {
            eventMessage: 'Failure to capture incoming call. Reason: error' + err,
            eventType: EventType.EVENT_TYPE_INFO,
          });
          TempoTracing.endSpan(traceId, TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'));
        }
      }
    },
    [softphoneData, parkSlots, nonTerminatedCalls, inboundCallWebsocketMessages.current]
  );

  // Need to capture the action that caused the ringing to end
  const ringingEndedTraceHandler = (action: string) => () => {
    if (!!currentInboundTrace.current) {
      TempoTracing.addEvent(
        currentInboundTrace.current,
        TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'),
        {
          eventMessage: 'Ringing stopped, removing notification. Reason: ' + action,
          eventType: EventType.EVENT_TYPE_INFO,
        }
      );
      TempoTracing.endSpan(
        currentInboundTrace.current,
        TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call')
      );
    }
    currentInboundTrace.current = undefined;
  };
  // I tried to do: ['incoming-call.answered', 'incoming-call.completed-elsewhere', ...].forEach((callAction) => ... ), but types were not exported in a way that I could make this happen :(
  useSoftphoneEventSubscription(['incoming-call.answered'], ringingEndedTraceHandler('Call answered.'));

  useSoftphoneEventSubscription(
    ['incoming-call.completed-elsewhere'],
    ringingEndedTraceHandler('Call completed elsewhere.')
  );
  useSoftphoneEventSubscription(['incoming-call.missed'], ringingEndedTraceHandler('Call missed.'));
  useSoftphoneEventSubscription(['incoming-call.rejected'], ringingEndedTraceHandler('Call rejected.'));

  useSoftphoneEventSubscription(
    ['incoming-call.answered', 'incoming-call.completed-elsewhere', 'incoming-call.missed', 'incoming-call.rejected'],
    (e) => {
      if (audioSet.current[e.id]) {
        audioSet.current[e.id].pause();
        delete audioSet.current[e.id];
      }
      if (audioSetIntervals.current[e.id]) {
        clearInterval(audioSetIntervals.current[e.id]);
        delete audioSetIntervals.current[e.id];
      }
      removeNotification(e.id);
    }
  );

  return null;
});

const getContacts = (data: PhoneEventV2Payload, demoSourceIds: string[]) => {
  if (data.params.contact_matches) {
    return data.params.contact_matches
      ?.filter((contact) => (demoSourceIds.length > 0 ? demoSourceIds.includes(contact.source_id) : true))
      .map((item) => {
        return {
          personId: item.person_id,
          patientId: item.patient_id,
          householdId: item.household_id,
          callerName: `${item.first_name} ${item.last_name}`,
          callerNumber: data.params.caller_id_number,
          recipientLocationName: item.client_location_name,
          gender: item.gender,
          birthdate: item.birthdate.seconds,
          source: item.data_source_name,
          matchedLocationId: item.weave_locations_matched[0]?.location_id ?? '',
        };
      });
  } else {
    return [
      {
        personId: '',
        patientId: '',
        householdId: '',
        callerName: i18next.t('Unknown Caller', { ns: 'softphone' }),
        callerNumber: data.params.caller_id_number,
        recipientLocationName: data.params.recipient_location_name,
        gender: '',
        birthdate: 0,
        source: '',
        matchedLocationId: '',
      },
    ];
  }
};

const useSoftphonePopActions = ({ audioSet }: { audioSet: MutableRefObject<Record<string, HTMLAudioElement>> }) => {
  const { notifications, removeNotification } = useCallPopStateSync();
  const incomingCalls = useSoftphoneCallState((ctx) => ctx.incomingCalls);
  const answer = useSoftphoneCallActions((ctx) => ctx.answerIncomingCall);
  const reject = useSoftphoneCallActions((ctx) => ctx.endCall);

  //storing this stuff in refs so I don't have to keep re-subscribing to events whenever these change
  const notificationsRef = useRef(notifications);
  const incomingCallsRef = useRef(incomingCalls);
  const answerRef = useRef(answer);
  const rejectRef = useRef(reject);

  useEffect(() => {
    notificationsRef.current = notifications;
    incomingCallsRef.current = incomingCalls;
    answerRef.current = answer;
    rejectRef.current = reject;
  }, [notifications, incomingCalls, answer, reject]);

  const stopPlayback = (callId: string) => {
    audioSet.current[callId].pause();
  };

  const answerHandler: CallPopActionHandler<'answer'> = useCallback((data) => {
    const notification = notificationsRef.current.find((notification) => notification.id === data.id);
    const call = incomingCallsRef.current.find((call) => call.id === notification?.id);

    if (notification?.id === DEVTOOLS_ID) {
      stopPlayback(notification.id);
      removeNotification(notification.id);
      return;
    }

    if (!!notification?.trace_id) {
      TempoTracing.addEvent(
        notification.trace_id,
        TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'),
        {
          eventMessage: 'User answered call using softphone',
          eventType: EventType.EVENT_TYPE_INFO,
        }
      );
      TempoTracing.endSpan(notification.trace_id, TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'));
    }
    if (!notification || !call) {
      return;
    }
    answerRef.current(call);
    stopPlayback(call.id);
  }, []);

  const hangupHandler: CallPopActionHandler<'hangup'> = useCallback((data) => {
    const notification = notificationsRef.current.find((notification) => notification.id === data.id);
    const call = incomingCallsRef.current.find((call) => call.id === notification?.id);

    if (notification?.id === DEVTOOLS_ID) {
      stopPlayback(notification.id);
      removeNotification(notification.id);
      return;
    }

    if (!!notification?.trace_id) {
      TempoTracing.addEvent(
        notification.trace_id,
        TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'),
        {
          eventMessage: 'User rejected call using softphone',
          eventType: EventType.EVENT_TYPE_INFO,
        }
      );
      TempoTracing.endSpan(notification.trace_id, TempoTracing.spanNameGenerators.callPopSoftphoneSpan('inbound_call'));
    }
    if (!notification || !call) {
      return;
    }
    rejectRef.current(call);
    stopPlayback(call.id);
  }, []);

  useSubscribeToPopAction('answer', answerHandler);
  useSubscribeToPopAction('hangup', hangupHandler);
};
