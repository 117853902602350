import { FC } from 'react';
import { Icon } from '@frontend/icons';
import { SwitchField, Text, useControlledField } from '@frontend/design-system';
import { ToggleMedicalConditionStatePayload } from './condition-list.component';

interface MedicalConditionOptionProps {
  label: string;
  value: string;
  state: boolean;
  disabled?: boolean;
  onToggle: (payload: ToggleMedicalConditionStatePayload) => void;
}

const MedicalConditionOption: FC<MedicalConditionOptionProps> = ({ label, state, value, onToggle }) => {
  const switchProps = useControlledField({
    type: 'switch',
    value: state,
    onChange: (newValue: boolean) => {
      onToggle({ value, state: !newValue });
    },
  });

  const disabled = !state;

  return (
    <li key={value} className='option-item'>
      <Icon name='data-sync' className='sync-icon' color={disabled ? 'light' : 'primary'} />
      <Text title={label} weight='bold' color={disabled ? 'light' : 'default'}>
        {label}
      </Text>

      <SwitchField className='switch-field' name='' label='' {...switchProps} />
    </li>
  );
};

export default MedicalConditionOption;
