import { useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import {
  DynamicFieldProperty_Enum,
  Template,
  TemplateType_Slug,
} from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { MMS_MAX_SIZE, MessagesHooks } from '@frontend/api-messaging';
import { ScheduleQueries } from '@frontend/api-schedule';
import { Trans, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { SuperTextarea } from '@frontend/super-textarea';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  Text,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  useModalControl,
  Modal,
  FileUpload,
  formatDate,
  ContentLoader,
  Heading,
  useAlert,
} from '@frontend/design-system';
import { MediaUploadPreview } from '../../../components/MediaUploadPreview';
import { MessageTemplatesModal } from '../../../components/MessageTemplate';
import { tags, templateTags } from './constants';
import { ScheduleMassMessageTrackingIds } from './trackingIds';
import { MassMessageTagLabel } from './types';
import { getMassMessageTagValue } from './utils';

type Props = {
  selectedAppointments: Appointment[];
  date: string;
  onClose: () => void;
  onBack: () => void;
};

export const SendScheduledMessageToPatients = ({ selectedAppointments, date, onClose, onBack }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarActions');
  const [message, setMessage] = useState<string>('');
  const [isNewMessage, setIsNewMessage] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template>();
  const { selectedLocationIdsWithParents, accessibleLocationData } = useAppScopeStore();

  const imageUploadProps = MessagesHooks.useMediaUploader({
    maxFileSize: MMS_MAX_SIZE,
    onExceedMaxFiles: () => {
      alert.error({ message: t('You can only attach up to 10 images at a time.') });
    },
    locationId: selectedLocationIdsWithParents[0],
  });

  const messageTemplatesModalControls = useModalControl();
  const imageUploadModalControl = useModalControl();
  const locationData = accessibleLocationData[selectedLocationIdsWithParents[0]];

  const {
    mutateAsync: sendMassMessage,
    isLoading: isSendMassMessageLoading,
    isError: isSendMassMessageError,
  } = ScheduleQueries.useMutateMassMessageSend();

  const handleSendMassMessage = () => {
    const recipients = selectedAppointments?.map((selectedAppointment) => {
      return {
        personId: selectedAppointment?.person?.personId ?? '',
        bindingsList: getBindingsList(selectedAppointment).map((binding) => {
          return {
            property: binding.property,
            values: binding.values.filter((value) => !!value) as string[],
          };
        }),
      };
    });

    const location = {
      locationId: selectedLocationIdsWithParents[0],
      locale: 'en_US',
      timezone: locationData?.timezone ?? '',
      recipients: recipients,
    };

    const sendMassMessageReq = {
      locations: [location],
      mediaIds: imageUploadProps.files.map((file) => file.mediaId ?? ''),
      templatedMessage: message,
      templateTypeSlug: selectedTemplate?.templateTypeSlug ?? TemplateType_Slug.UNSPECIFIED_TEMPLATE_TYPE,
    };

    sendMassMessage(sendMassMessageReq).then((res) => {
      if (res) {
        alert.success({ message: t('Message sent successfully.') });
        onClose();
      }
    });
  };

  const getBindingsList = useCallback(
    (appointmentData: Appointment) =>
      templateTags?.map((tag) => {
        const property = tag.property ?? DynamicFieldProperty_Enum.UNKNOWN;
        const value = getMassMessageTagValue(
          tag.label as MassMessageTagLabel,
          appointmentData?.person,
          locationData.name ?? '',
          appointmentData?.start ?? ''
        );

        return {
          property,
          values: [value],
        };
      }),

    [templateTags, message]
  );

  useEffect(() => {
    if (isSendMassMessageError) {
      alert.error({ message: t('Failed to send message. Please try again.') });
    }
  }, [isSendMassMessageError]);

  return (
    <div
      css={css`
        flex: 1;
        display: flex;
        flex-direction: column;
      `}
    >
      <div
        css={{
          backgroundColor: theme.colors.white,
          padding: theme.spacing(0, 3, 2, 3),
        }}
      >
        <ContentLoader show={isSendMassMessageLoading} />
        <Heading level={2}>{t('Message Patients ({{count}})', { count: selectedAppointments.length })}</Heading>
        <Trans t={t} count={selectedAppointments.length}>
          <Text color='light'>
            Compose your message below or choose a template. We’ll send this message to{' '}
            <strong>{{ count: selectedAppointments.length }}</strong> recipients scheduled for appointments on{' '}
            <strong>{formatDate(date, 'LL')}</strong>
          </Text>
        </Trans>
      </div>
      <div
        css={css`
          flex: 1;
          display: flex;
          flex-direction: column;
          padding: ${theme.spacing(4, 4, 3, 4)};
          background: ${theme.colors.neutral5};
        `}
      >
        <MediaUploadPreview
          media={imageUploadProps.files}
          onDelete={(id) => {
            imageUploadProps.deleteFile(id);
          }}
        />
        <SuperTextarea
          onChange={(val) => setMessage(val)}
          onImageClick={imageUploadModalControl.triggerProps.onClick}
          onTemplateClick={messageTemplatesModalControls.triggerProps.onClick}
          onSubmit={handleSendMassMessage}
          showTagList={false}
          templateTags={tags}
          isNewTag
          value={message}
          isValueReRendered={isNewMessage}
          setIsValueReRendered={setIsNewMessage}
          trackingIds={{
            template: ScheduleMassMessageTrackingIds.textAreaTemplateBtn,
            emoji: ScheduleMassMessageTrackingIds.textAreaEmojiBtn,
            image: ScheduleMassMessageTrackingIds.textAreaImageBtn,
          }}
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            background-color: white;
            border: 0;
            & > section {
              align-items: start;
              cursor: text;
              flex: 1;
            }
          `}
        />
      </div>
      <ButtonBar
        css={{ padding: theme.spacing(4), display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <IconButton
          trackingId={ScheduleMassMessageTrackingIds.sendMassMessageModalBackBtn}
          css={css`
            color: ${theme.colors.primary50};
          `}
          label={t('Back')}
          showLabelAlways
          onClick={onBack}
        >
          <Icon color='primary' name='back' />
        </IconButton>
        <div css={{ display: 'flex', gap: theme.spacing(1) }}>
          <SecondaryButton trackingId={ScheduleMassMessageTrackingIds.sendMassMessageModalCancelBtn} onClick={onClose}>
            {t('Cancel')}
          </SecondaryButton>
          <PrimaryButton
            trackingId={ScheduleMassMessageTrackingIds.sendMassMessageBtn}
            css={css`
              gap: ${theme.spacing(1)};
              svg {
                margin: 0;
              }
            `}
            onClick={handleSendMassMessage}
            disabled={!message || isSendMassMessageLoading}
          >
            <Icon size={30} color='white' name='send' />
            {t('Send')}
          </PrimaryButton>
        </div>
      </ButtonBar>
      <Modal {...imageUploadModalControl.modalProps} maxWidth={600}>
        <Modal.Header textAlign='left'>{t('Upload Image')}</Modal.Header>
        <Modal.Body>
          <FileUpload
            onFileUpload={(files: File[]) => {
              imageUploadProps.upload(files);
              imageUploadModalControl.closeModal();
            }}
            acceptedFileType={['png', 'jpg', 'jpeg']}
            helperText={t('Drop image here (PNG or JPG files only)')}
            multiple
          />
        </Modal.Body>
      </Modal>
      <MessageTemplatesModal
        {...messageTemplatesModalControls}
        setDraft={(val) => {
          setIsNewMessage(true);
          setMessage(val);
        }}
        setSelectedTemplate={setSelectedTemplate}
        groupId={selectedLocationIdsWithParents[0]}
      />
    </div>
  );
};
