import { useState, useEffect } from 'react';
import { useFormBuilderStore } from '../../provider';

interface UseMedicalHistoryFieldsInfoProps {
  formHasPrimaryMedicalHistoryFields: boolean;
}

/**
 * This hook is responsible for providing information about
 * the primary medical history fields' presence in the form.
 */
export const useMedicalHistoryFieldsInfo = (): UseMedicalHistoryFieldsInfoProps => {
  const [hasPrimaryMedicalHistoryFields, setHasPrimaryMedicalHistoryFields] = useState(false);
  const { usedPrimaryFieldElements } = useFormBuilderStore(['usedPrimaryFieldElements']);

  useEffect(() => {
    // Check if any of the primary medical history fields are used.
    setHasPrimaryMedicalHistoryFields(
      usedPrimaryFieldElements['allergies'] ||
        usedPrimaryFieldElements['medications'] ||
        usedPrimaryFieldElements['disease'] ||
        false
    );
  }, [usedPrimaryFieldElements]);

  return {
    formHasPrimaryMedicalHistoryFields: hasPrimaryMedicalHistoryFields,
  };
};
