import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyles = css`
  display: flex;
  flex-direction: column;
  border-radius: ${theme.borderRadius.small};
  min-width: 300px;
  padding: 0;
`;
export const checkboxListContainerStyles = css`
  max-height: 25vh;
  overflow-y: auto;
  padding: ${theme.spacing(2)};
`;

export const buttonsContainerStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 1.5)};
`;

export const locationCheckboxStyles = css`
  margin-bottom: ${theme.spacing(1.5)};
  padding: ${theme.spacing(0, 1.5)};

  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const radioListContainerStyles = css`
  max-height: 25vh;
  overflow-y: auto;
`;

export const radioValueContainerStyle = css`
  padding-left: ${theme.spacing(2)};
  padding-right: ${theme.spacing(2)};
`;

export const textStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;

export const hrStyle = css`
  height: 1px;
  border: none;
  background-color: ${theme.colors.neutral20};
`;

export const radioFieldContainerStyle = css`
  margin: ${theme.spacing(1.5)};
`;

export const radioSelectStyle = css`
  > div {
    margin-bottom: 0;
  }
`;
