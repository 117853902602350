import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { formatDateByTimezone, formatters, getHourlyInsightsLabel } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.secondary.seaweed30,
  missed: theme.colors.critical30,
};

const labels = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
};

type ProcessedData = {
  chartData: BarChartData;
  totals: {
    abandoned: number;
    answered: number;
    missed: number;
  };
};

export const IncomingCallVolumeBreakdownChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.IncomingCallsAggregationKey>('incoming_call_status');

  const processedData: ProcessedData = useMemo(() => {
    let groups: BarChartData['groups'] = [];
    const totals: ProcessedData['totals'] = {
      abandoned: 0,
      answered: 0,
      missed: 0,
    };

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { calls = {} }]) => {
        const abandoned = calls.abandoned || 0;
        const answered = calls.answered || 0;
        const missed = calls.missed || 0;

        totals.abandoned += abandoned;
        totals.answered += answered;
        totals.missed += missed;

        return {
          name: locationNames?.[key] || key,
          values: {
            abandoned,
            answered,
            missed,
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { abandoned, answered, missed } = Object.values(locationData || {})[0] || {};
        const abandonedHourly = abandoned?.calls?.hourly || [];
        const answeredHourly = answered?.calls?.hourly || [];
        const missedHourly = missed?.calls?.hourly || [];

        groups = Array.from({ length: 24 }).map((_, i) => {
          const abandoned = abandonedHourly[i] || 0;
          const answered = answeredHourly[i] || 0;
          const missed = missedHourly[i] || 0;

          totals.abandoned += abandoned;
          totals.answered += answered;
          totals.missed += missed;

          return {
            name: getHourlyInsightsLabel(i),
            values: {
              abandoned,
              answered,
              missed,
            },
          };
        });
      } else {
        groups = Object.entries(locationData).map(([date, { abandoned, answered, missed }]) => {
          const abandonedCalls = abandoned?.calls?.daily || 0;
          const answeredCalls = answered?.calls?.daily || 0;
          const missedCalls = missed?.calls?.daily || 0;

          totals.abandoned += abandonedCalls;
          totals.answered += answeredCalls;
          totals.missed += missedCalls;

          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              abandoned: abandonedCalls,
              answered: answeredCalls,
              missed: missedCalls,
            },
          };
        });
      }
    }

    return {
      chartData: {
        groups,
      },
      totals,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locationNames]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Tells the end result of the incoming calls')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Incoming Call Breakdown')}
      />
      <Chart.BarChart appearance={defaultChartAppearance} data={processedData.chartData} />
      <Chart.Legends formatValue={formatters.value.format} values={processedData.totals} />
    </Chart>
  );
};
