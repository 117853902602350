import { css } from '@emotion/react';
import { SuccessGraphic, WeaveLogo } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text, Button } from '@frontend/design-system';

interface Props {
  reset: () => void;
}

export function SuccessRequestAppointment({ reset }: Props) {
  const { t } = useTranslation('bookingSite');

  return (
    <section css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(3) }}>
      <SuccessGraphic css={{ maxWidth: 350 }} />
      <div>
        <Heading textAlign='center' css={{ margin: 0 }}>
          {t('Request Submitted')}
        </Heading>
        <Text textAlign='center' css={{ margin: theme.spacing(0, 2) }}>
          {t('Your appointment request has been received and is pending review by our office staff.')}
        </Text>
      </div>
      <Button onClick={reset}>{t('Book Again')}</Button>
      <div css={footerStyle}>
        <Text as='span' color='subdued'>
          {t('Scheduling powered by')}
        </Text>
        <WeaveLogo />
      </div>
    </section>
  );
}

const footerStyle = css({
  marginTop: theme.spacing(5),
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  svg: { height: 20, width: 'auto' },
});
