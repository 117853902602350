import { useNavigate } from '@tanstack/react-location';
import { PlanDetailsType, createPaymentPlan } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, usePollWorkflowsHook } from '@frontend/payments-hooks';
import { Modal, useModalLoadingState, useAlert } from '@frontend/design-system';
import { getLinkBase, useAppVariantContext } from '../../providers';
import { createFutureISODateTimeInUTC } from '../../utils';
import { PaymentPlanDetailsModalBody } from './payment-plan-details-modal-body';

export const CreatePlanDetailsModal = ({
  planDetails,
  onClose,
}: {
  planDetails: PlanDetailsType;
  onClose: () => void;
}) => {
  const { t } = useTranslation('payments');
  const { setLoading } = useModalLoadingState();
  const { paymentsUrl } = useMerchant();
  const { variant } = useAppVariantContext();
  const navigate = useNavigate();
  const alert = useAlert();

  const { startPolling } = usePollWorkflowsHook({
    onFailure: () => {
      alert.error(t('Error creating plan, please try again.'));
    },
    onComplete: () => {
      alert.success(t('Payment plan successfully created!'));
      navigate({
        to: `${getLinkBase(variant)}/payment-plans`,
        search: { updateList: true },
      });
    },
    onPollingEnd: async () => {
      onClose();
      setLoading(false);
    },
  });

  const handleSubmitPaymentPlan = async () => {
    setLoading(true, 'Creating payment plan');
    try {
      const { workflowId } = await createPaymentPlan(paymentsUrl, {
        name: planDetails.planName,
        amount: planDetails.planAmount,
        frequency: planDetails.frequency,
        personId: planDetails.patient?.id,
        customerEmailId: planDetails?.customerEmailId,
        paymentMethodId: planDetails?.billingMethod?.id,
        paymentTerm: Number(planDetails.paymentTerm),
        startAt: createFutureISODateTimeInUTC(planDetails.paymentDate || ''),
        ...(planDetails.invoiceId && { externalInvoiceId: planDetails.invoiceId }),
        ...(planDetails.memo && { notes: planDetails?.memo }),
      });

      startPolling([workflowId]);
    } catch (err) {
      alert.error(t('Error creating plan, please try again.'));
    }
  };

  return (
    <>
      <Modal.Header textAlign='left'>{t('Create Payment Plan')}</Modal.Header>
      <PaymentPlanDetailsModalBody planDetails={planDetails} />
      <Modal.Footer
        primary={{
          onClick: handleSubmitPaymentPlan,
          label: t('Create Payment Plan'),
        }}
        secondary={{
          onClick: onClose,
          label: t('Continue Editing'),
        }}
      />
    </>
  );
};
