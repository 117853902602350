export type DefaultFilters<K extends string, T> = {
  [key in K]: T;
};

interface UseQuickFiltersOptions<K extends string, T> {
  filter: T;
  defaultFilters: DefaultFilters<K, T>;
  onChange: (filter: T) => void;
}

const hasValue = (filterProperty: unknown) =>
  filterProperty && Array.isArray(filterProperty)
    ? !!filterProperty.length
    : typeof filterProperty === 'object' && !!Object.getOwnPropertyNames(filterProperty).length;

export const compareFilters = <T extends Record<string, unknown>>(filter: T, subFilter: T) => {
  const subFilterKeys = Object.getOwnPropertyNames(subFilter);
  const areAllSubFilterPropertiesEmpty = subFilterKeys.every((k) => !hasValue(subFilter[k]));
  if (areAllSubFilterPropertiesEmpty) {
    return subFilterKeys.every((k) => !hasValue(filter[k]));
  } else {
    return subFilterKeys.every((k) => {
      const JSONfilter = JSON.stringify(filter[k], null, 0);
      const JSONSubFilter = JSON.stringify(subFilter[k], null, 0);
      return JSONfilter === JSONSubFilter;
    });
  }
};

export const removeQuickFilter = <T extends Record<string, unknown>>(filter: T, remove: T) => {
  const newFilter = { ...filter };
  Object.getOwnPropertyNames(filter).forEach((key) => {
    if (key in remove) {
      delete newFilter[key as keyof T];
    }
  });
  return newFilter;
};

export const useQuickFilters = <K extends string, T extends Record<string, unknown>>({
  defaultFilters,
  filter,
  onChange,
}: UseQuickFiltersOptions<K, T>) => {
  const isFilterActive = (filterType: K) => {
    return compareFilters(filter, defaultFilters[filterType]);
  };

  const handleQuickFilter = (filterType: K) => {
    if (!isFilterActive(filterType)) {
      onChange({ ...filter, ...defaultFilters[filterType] });
    } else {
      onChange(removeQuickFilter(filter, defaultFilters[filterType]));
    }
  };

  return { defaultFilters, isFilterActive, handleQuickFilter };
};
