import { css } from '@emotion/react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { ActionsUI } from '@frontend/contact-actions';
import { FirstWordBoldText, WidgetizedDashboardTrackingIds } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Avatar, Button, Text } from '@frontend/design-system';
import { formatters } from '../../utils';

interface Props {
  item: PracticeAnalyticsTypes.PatientInfo;
}

const opportunityListTrackingId = WidgetizedDashboardTrackingIds.widgetSpecificTrackingId('opportunity-list-action');

export const OpportunityItem = ({ item }: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIdsWithParents } = useAppScopeStore();

  const { Modal: PhoneCallModal, triggerProps: callTriggerProps } = ActionsUI.actions.usePhoneCallAction({
    context: {
      personId: item.id,
      phoneNumber: item.MobilePhone,
    },
  });

  const {
    disabled: disableMessageAction,
    disabledDetails: disabledMessageDetails,
    Modal: MessageModal,
    triggerProps: messageTriggerProps,
  } = ActionsUI.actions.useMessageAction({
    context: {
      personId: item.id,
      locationId: selectedLocationIdsWithParents[0] ?? '',
      phoneNumber: item.MobilePhone,
    },
  });
  const personName = `${item.FirstName} ${item.LastName}`.trim();
  const personNumber = item.MobilePhone || item.HomePhone || item.WorkPhone;
  return (
    <li css={itemStyle}>
      <div>
        <Avatar css={{ color: theme.colors.success20 }} name={personName} size='small' />
        <FirstWordBoldText size='medium'>{personName}</FirstWordBoldText>
      </div>
      <Text>{item.productionAmount ? formatters.currency.format(item.productionAmount) : '-'}</Text>
      <div>
        <Button
          {...callTriggerProps}
          iconName='phone-small'
          label={t('Call {{name}}', {
            name: personName || personNumber,
          })}
          size='large'
          trackingId={opportunityListTrackingId('call')}
          variant='secondary'
        />

        <Button
          {...messageTriggerProps}
          disabled={disableMessageAction}
          iconName='messaging-small'
          label={
            disableMessageAction
              ? disabledMessageDetails
              : t('Message {{name}}', {
                  name: personName || personNumber,
                })
          }
          size='large'
          trackingId={opportunityListTrackingId('message')}
          variant='secondary'
        />
        {PhoneCallModal}
        {MessageModal}
      </div>
    </li>
  );
};

const itemStyle = css({
  background: theme.colors.white,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(0.5, 1),
  marginBottom: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  '> div:first-of-type': {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
    width: '60%',
  },
  '> div:last-of-type': {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
});
