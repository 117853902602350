import { Fragment, useEffect, useState, useRef } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Message } from '../../../types';
import { MessageItem } from './message-item';
import { NewMessageDivider } from './new-messages-divider';

interface VirtuosoItemProps {
  index: number;
  message: Message;
  unreadCount: number;
  unreadMessageIndex: number | null;
  isTeamChatFocused: boolean;
  firstItemIndex: number;
  totalMessagesCount: number;
}

export const VirtuosoItem = ({
  index,
  message,
  unreadCount,
  unreadMessageIndex,
  isTeamChatFocused,
  firstItemIndex,
  totalMessagesCount,
}: VirtuosoItemProps) => {
  /* 
    Since the Virtuoso Item works on initially passed index there not a better way to the real message index position
    in the array, so we are making a equation to get the real index so that we could display the new message line.
  */
  const timer = useRef<NodeJS.Timeout | undefined>();
  const currentIndex = totalMessagesCount + index - firstItemIndex;
  const [showNewLine, setShowNewLine] = useState<boolean>(unreadCount > 0 && unreadMessageIndex === currentIndex);

  useEffect(() => {
    if (showNewLine) {
      if (isTeamChatFocused) {
        timer.current = setTimeout(() => {
          setShowNewLine(false);
        }, 5000);
      } else {
        clearTimeout(timer.current);
      }
    }

    () => {
      if (showNewLine) {
        clearTimeout(timer.current);
      }
    };
  }, [unreadMessageIndex, unreadCount, isTeamChatFocused]);

  if (message.type === 'system') {
    return <div css={systemMessageStyles}>{message.text}</div>;
  }

  return (
    <Fragment key={message.id}>
      {showNewLine && <NewMessageDivider />}
      <MessageItem message={message} />
    </Fragment>
  );
};

const systemMessageStyles = css({
  padding: theme.spacing(1, 0),
  textAlign: 'center',
  color: theme.colors.neutral50,
  fontSize: theme.spacing(1.5),
  fontWeight: theme.font.weight.regular,
});
