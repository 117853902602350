import { useCallback, useState } from 'react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { useQueryClient } from 'react-query';
import {
  ContactDirectoriesMutation,
  ContactDirectoriesQuery,
  ContactDirectoriesQueryKeys,
} from '@frontend/api-contact-directories';
import { useTranslation } from '@frontend/i18n';
import {
  ContentLoader,
  EditSimpleIcon,
  ModalControlResponse,
  PlusIcon,
  TrashIcon,
  UserMinusIcon,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { DeleteContactModal, EditContactModal, RemoveContactFromDirectory } from '../modals';
import { TrayContainer } from '../side-modals';
import { CreateNewDirectory } from '../side-modals/create-new-directory-modal';
import type { SelectedContactsType } from '../types';
import { ContactsTable } from './contacts';

type Props = {
  newContactModalControls: ModalControlResponse;
  addExistingContactControls: ModalControlResponse;
  id: string;
  contactDirectoryName?: string;
};

export const ContactDirectoryTable = ({
  newContactModalControls,
  addExistingContactControls,
  id: contactDirectoryId,
  contactDirectoryName,
}: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const queryClient = useQueryClient();
  const alerts = useAlert();

  const [selectedContacts, setSelectedContacts] = useState<SelectedContactsType>({
    contacts: [],
    contactNumber: '',
  });

  const { modalProps: deleteContactModalProps, triggerProps: deleteContactTriggerProps } = useModalControl();
  const { modalProps: editContactModalProps, triggerProps: editContactTriggerProps } = useModalControl();
  const { modalProps: createNewDirectoryModalProps, triggerProps: createNewDirectoryTriggerProps } = useModalControl();
  const { modalProps: removeFromDirectoryModalProps, triggerProps: removeFromDirectoryTriggerProps } =
    useModalControl();

  const { useGetContactDirectoryContacts, useGetContactDirectoryDevices } =
    ContactDirectoriesQuery.useContactDirectoryQueries({ tenantId });

  const { data: { contacts, contactIds } = { contacts: [], contactIds: [] }, isRefetching: isContactsRefetching } =
    useGetContactDirectoryContacts({
      contactListId: contactDirectoryId,
    });

  const { data: devices, isLoading: isDevicesLoading } = useGetContactDirectoryDevices({
    contactListId: contactDirectoryId,
  });

  const { useReplaceContactDirectoryContactsMutation } = ContactDirectoriesMutation.useContactDirectoryMutations();

  const { mutateAsync: replaceContacts } = useReplaceContactDirectoryContactsMutation({
    onSuccess: () => {
      queryClient.invalidateQueries([tenantId, ...ContactDirectoriesQueryKeys.queryKeys.getContactDirectories()]);
      queryClient.invalidateQueries([
        tenantId,
        ...ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId),
      ]);
      removeFromDirectoryModalProps.onClose();
    },
  });

  const removeContact = useCallback(
    (contact: Contact) => {
      if (!devices?.deviceIds?.length) {
        const filteredContactIds = contactIds.filter((contactId) => contactId !== contact.id);
        return replaceContacts({ contactListId: contactDirectoryId, contactIds: filteredContactIds }).then(() => {
          alerts.success(t('Successfully removed a contact from list'));
        });
      } else {
        setSelectedContacts({
          contacts: [contact],
        });
        removeFromDirectoryTriggerProps.onClick();
        return;
      }
    },
    [devices?.deviceIds, contactIds]
  );

  const isLoading = isContactsRefetching || isDevicesLoading;

  return (
    <>
      {isLoading ? (
        <ContentLoader show />
      ) : (
        <ContactsTable
          data={contacts}
          fullHeightConfig={500}
          onRemove={removeContact}
          contactDirectoryId={contactDirectoryId}
          contactIds={contactIds}
          emptyStateConfig={{
            type: 'contact_directories',
            header: t('No Contacts'),
            description: t('Add contacts to this list and assign this list to your devices'),
            action: {
              label: t('Add Contact'),
              actions: [
                {
                  label: t('Add New Contact'),
                  onClick: newContactModalControls.openModal,
                },
                {
                  label: t('Add Existing Contact'),
                  onClick: addExistingContactControls.openModal,
                },
              ],
            },
          }}
          rowActions={[
            {
              actions: [
                {
                  label: t('Edit Contact'),
                  Icon: EditSimpleIcon,
                  onClick: (contact) => {
                    setSelectedContacts({
                      contacts: [contact],
                    });
                    editContactTriggerProps.onClick();
                  },
                },
                {
                  label: t('Remove from List'),
                  Icon: UserMinusIcon,
                  onClick: (contact) => {
                    removeContact(contact);
                  },
                },
                {
                  label: t('Delete Contact'),
                  Icon: TrashIcon,
                  onClick: (contact) => {
                    setSelectedContacts({
                      contacts: [contact],
                    });
                    deleteContactTriggerProps.onClick();
                  },
                  destructive: true,
                },
              ],
            },
          ]}
          bulkActions={[
            {
              Icon: PlusIcon,
              onClick: (_, contacts) => {
                setSelectedContacts({
                  contacts,
                });
                createNewDirectoryTriggerProps.onClick();
              },
              label: t('Add to New List'),
            },
            {
              Icon: UserMinusIcon,
              onClick: (_, contacts) => {
                const idsSelected = contacts.map(({ id }) => id);
                if (!devices?.deviceIds?.length) {
                  const filteredContactIds: string[] = contactIds.filter(
                    (contactId) => !idsSelected.includes(contactId)
                  );
                  replaceContacts({ contactListId: contactDirectoryId, contactIds: filteredContactIds }).then(() => {
                    alerts.success(
                      t('Successfully removed {{count}} contacts from list', { count: idsSelected.length })
                    );
                  });
                } else {
                  setSelectedContacts({
                    contacts,
                  });
                  removeFromDirectoryTriggerProps.onClick();
                }
              },
              label: t('Remove from List'),
            },
            {
              Icon: TrashIcon,
              onClick: (_, contacts) => {
                setSelectedContacts({
                  contacts,
                });
                deleteContactTriggerProps.onClick();
              },
              label: t('Delete Contacts'),
              destructive: true,
            },
          ]}
          rowSelectionConfig={{
            onSelectionToggle: (_, contacts) => {
              setSelectedContacts({
                contacts,
              });
            },
          }}
        />
      )}
      <EditContactModal
        selectedContacts={selectedContacts}
        contactDirectoryId={contactDirectoryId}
        {...editContactModalProps}
      />
      <RemoveContactFromDirectory
        contactDirectoryName={contactDirectoryName ?? ''}
        devices={devices?.devices}
        contactDirectoryId={contactDirectoryId}
        contactIds={contactIds}
        selectedContacts={selectedContacts}
        onSave={replaceContacts}
        {...removeFromDirectoryModalProps}
      />
      {deleteContactModalProps.show && (
        <DeleteContactModal
          selectedContacts={selectedContacts}
          contactDirectoryId={contactDirectoryId}
          {...deleteContactModalProps}
        />
      )}
      <TrayContainer modalProps={createNewDirectoryModalProps}>
        <CreateNewDirectory {...createNewDirectoryModalProps} assignableContacts={contacts} />
      </TrayContainer>
    </>
  );
};
