import { SalesforceAccountProductBundle } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { BundleNameByTypeMap, BundleTypeRegex, PlanNameByTypeMap, PlanTypeRegex } from './constants';
import { BundleType, PlanType } from './types';

export const getBundleTypeBySfEnum = (salesforceBundleEnum: SalesforceAccountProductBundle): BundleType => {
  if (BundleTypeRegex.ESSENTIALS.test(salesforceBundleEnum)) {
    return BundleType.ESSENTIALS;
  } else if (BundleTypeRegex.PRO.test(salesforceBundleEnum)) {
    return BundleType.PRO;
  } else if (BundleTypeRegex.ELITE.test(salesforceBundleEnum)) {
    return BundleType.ELITE;
  } else if (BundleTypeRegex.ULTIMATE.test(salesforceBundleEnum)) {
    return BundleType.ULTIMATE;
  } else {
    return BundleType.UNKNOWN;
  }
};

export const getBundleNameBySfEnum = (salesforceBundle: SalesforceAccountProductBundle): string => {
  return BundleNameByTypeMap[getBundleTypeBySfEnum(salesforceBundle)];
};

export const getPlanNameBySfEnum = (salesforceBundle: SalesforceAccountProductBundle): string => {
  const planType = getPlanTypeBySfEnum(salesforceBundle);
  return PlanNameByTypeMap[planType] ?? '';
};

export const getPlanTypeBySfEnum = (salesforceBundle: SalesforceAccountProductBundle): PlanType => {
  if (PlanTypeRegex.PLAN_CORE.test(salesforceBundle)) {
    return PlanType.CORE;
  } else if (PlanTypeRegex.PLAN_PLUS.test(salesforceBundle)) {
    return PlanType.PLUS;
  } else {
    return PlanType.UNKNOWN;
  }
};
