import { useMemo } from 'react';
import { useScheduleActionsContainerMethod } from '../../../../context/ScheduleActionsContainerContext';
import { useGetScheduleRequestAndAlertsCount } from '../../../../hooks/use-get-schedule-request-and-alerts-count';
import { ScheduleActionsContextType, ScheduleActionsContext } from './ScheduleActionsContext';
import { ScheduleActionsTabContent } from './ScheduleActionsTabContent';
import { SchedulePulseContactPatientsPanel } from './ScheduleMassMessages/schedule-pulse-contact-patients-panel';

export const ScheduleActionsModalContainer = () => {
  const { hideTabs, closeModal } = useScheduleActionsContainerMethod();

  const {
    isAppointmentRequestCountLoading,
    isScheduleAlertsLoading,
    refetchAlertCount,
    scheduleAlertsCount,
    scheduleRequestCount,
    refetchAppointmentRequestCount,
  } = useGetScheduleRequestAndAlertsCount();

  const providerValue = useMemo<ScheduleActionsContextType>(
    () => ({
      isAppointmentRequestCountLoading,
      isScheduleAlertsLoading,
      closeModal: closeModal ? closeModal : () => {},
      refetchAlertCount: () => refetchAlertCount(),
      scheduleAlertsTotalCount: scheduleAlertsCount ?? 0,
      scheduleRequestsTotalCount: scheduleRequestCount?.total ?? 0,
      refetchAppointmentRequestCount: () => refetchAppointmentRequestCount(),
      scheduleRequestsCountByLocation: scheduleRequestCount?.locationScheduleRequestCounts ?? [],
      closeScheduleActionsModal: closeModal ? closeModal : () => {},
    }),
    [scheduleAlertsCount, scheduleRequestCount, isScheduleAlertsLoading, isAppointmentRequestCountLoading, closeModal]
  );

  if (hideTabs) {
    return <SchedulePulseContactPatientsPanel />;
  }

  return (
    <ScheduleActionsContext.Provider value={providerValue}>
      <ScheduleActionsTabContent />
    </ScheduleActionsContext.Provider>
  );
};
