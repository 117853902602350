import { useState } from 'react';
import { motion } from 'motion/react';
import { InvoiceDetailModel } from '@frontend/api-invoices';
import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useViewAttachment } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Heading, NakedButton, Text, TextLink } from '@frontend/design-system';

type PaymentSummaryProps = {
  invoice: InvoiceDetailModel;
};

export const PaymentSummary = ({ invoice }: PaymentSummaryProps) => {
  const { t } = useTranslation('payments');
  const { viewAttachment } = useViewAttachment();
  const [viewDetails, setViewDetails] = useState(false);

  return (
    <section
      css={{
        backgroundColor: theme.colors.white,
        borderRadius: theme.spacing(0, 0, 1, 1),
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
      }}
    >
      <div>
        <Heading level={2}>{invoice?.person.name}</Heading>
        <Text color='subdued'>{`${t('Request Created ')} ${formatDate(invoice?.billedAt, 'MMMM D, YYYY')}`}</Text>
      </div>
      <div
        css={{
          display: 'flex',
          gap: theme.spacing(8),
        }}
      >
        <Info title={t('Amount')} info={formatCentsToCurrency(invoice?.billedAmount)} isBold />
        <Info title={t('Provider')} info={invoice.providerName} />
      </div>
      <NakedButton
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
        onClick={() => setViewDetails(!viewDetails)}
      >
        <Text color='primary'>{viewDetails ? t('Close Details') : t('Show Details')}</Text>
        <Icon name={viewDetails ? 'caret-up-small' : 'caret-down-small'} size={10} />
      </NakedButton>
      <motion.div
        initial={{ height: 0, opacity: 0 }}
        animate={{ height: viewDetails ? 'auto' : 0, opacity: viewDetails ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        css={{
          overflow: 'hidden',
        }}
      >
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
          }}
        >
          <div>
            <Text color='subdued' weight='semibold' size='medium'>
              {t('Attachment')}
            </Text>
            {!!invoice.attachments?.length && invoice.links.attachment ? (
              <div css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
                <Icon name='attachment-small' />
                <TextLink
                  css={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  onClick={() => viewAttachment(invoice.links.attachment ?? '')}
                >
                  {invoice.attachments?.[0].filename}
                </TextLink>
              </div>
            ) : (
              <Text weight='bold'>-</Text>
            )}
          </div>
          <Info title={t('Internal Notes')} info={invoice.memo} />
        </div>
      </motion.div>
    </section>
  );
};

const Info = ({ title, info, isBold }: { title: string; info?: string; isBold?: boolean }) => (
  <div>
    <Text color='subdued' weight='semibold' size='medium'>
      {title}
    </Text>
    <Text
      weight={isBold ? 'bold' : 'regular'}
      css={{
        display: '-webkit-box',
        overflow: 'hidden',
        '-webkit-box-orient': 'vertical',
        '-webkit-line-clamp': '2',
      }}
    >
      {info || '-'}
    </Text>
  </div>
);
