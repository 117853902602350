import React from 'react';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { BannerType } from '../../types';
import { getManagerContainerStyles } from '../styles';

interface BannerContainerProps {
  ref?: React.RefObject<HTMLDivElement>;
  bannerType?: BannerType;

  children: React.ReactNode;
}

export const BannerContainer = React.forwardRef<HTMLDivElement, BannerContainerProps>(
  ({ bannerType, children }, ref) => (
    <motion.div
      ref={ref}
      css={[
        css`
          container-type: inline-size;
        `,
        getManagerContainerStyles(bannerType),
      ]}
    >
      {children}
    </motion.div>
  )
);

BannerContainer.displayName = 'BannerContainer';
