import { useEffect, useState } from 'react';
import { BASE_URL_ANALYTICS, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useGetChartPayloadAndConfig } from './use-get-chart-payload-and-config';
import { usePhoneAnalyticsShallowStore } from './use-phone-analytics-store';

type Aggregates = {
  hourly?: number[];
  total?: number;
};

type LocationData = {
  aggregates?: (Aggregates & { key: PhoneAnalyticsTypes.HourlyInsightsAggregationKey })[];
  id: string;
};

export type HourlyInsightsResponse = {
  locationIds?: LocationData[];
};

type FormattedLocations = {
  [locationId: string]: {
    [key in PhoneAnalyticsTypes.HourlyInsightsAggregationKey]?: Aggregates;
  };
};

type DataState = {
  locations?: FormattedLocations;
};

type UseFetchAggregatedHourlyInsights = {
  data: DataState | undefined;
  isLoading?: boolean;
};

const formatLocationsData = (locationIds?: LocationData[]): DataState => {
  const formattedLocations =
    locationIds?.reduce((acc, { aggregates, id }) => {
      return {
        ...acc,
        [id]:
          aggregates?.reduce((acc, { hourly = [], total = 0, key }) => {
            return {
              ...acc,
              [key]: {
                hourly,
                total,
              },
            };
          }, {}) || {},
      };
    }, {}) || {};

  return {
    locations: formattedLocations,
  };
};

const api = async (filters: Partial<PhoneAnalyticsTypes.Filters>) => {
  const { data } = await http.post<{ data: HourlyInsightsResponse }>(
    `${BASE_URL_ANALYTICS}/${'phonereports/v2/gethourlyinsights'}`,
    PhoneAnalyticsApi.getDatesIntercepted(filters)
  );
  return data;
};

export const useFetchAggregatedHourlyInsights = (): UseFetchAggregatedHourlyInsights => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePhoneAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const [data, setData] = useState<DataState | undefined>();
  const { applicableFilters, config } = useGetChartPayloadAndConfig(filters, 'houlry-insights');

  const { data: hourlyInsights, isLoading } = useScopedQuery({
    ...config,
    queryFn: () => api(applicableFilters),
    onError: () => {
      alert.error(t("Couldn't load the aggregated hourly insights. Please try again."));
    },
  });

  useEffect(() => {
    const dataToUse = isDemoAccount ? demoData?.chartsData.hourlyInsights : hourlyInsights;
    setData(formatLocationsData(dataToUse?.locationIds));
  }, [demoData, hourlyInsights, isDemoAccount]);

  return {
    data,
    isLoading,
  };
};
