import { motion, MotionProps } from 'motion/react';
import { Text, TextProps, styles } from '@frontend/design-system';

interface MotionTextProps extends MotionProps {
  hasLayoutAnimation?: boolean;
}

export const MotionText = ({ hasLayoutAnimation = false, ...rest }: TextProps & MotionTextProps) => {
  return (
    <Text
      as={motion.p}
      // Text component is not properly typed
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      layout={hasLayoutAnimation ? 'position' : false}
      css={[{ margin: 0 }, styles.truncate]}
      textAlign='left'
      {...rest}
    />
  );
};
