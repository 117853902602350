import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const accordionBaseStyle = css`
  > div {
    border-bottom: 1px solid ${theme.colors.neutral10} !important;
    border-radius: 0px !important;
  }
`;

export const applyBackgroundStyle = css`
  background-color: ${theme.colors.white};
`;

export const spacingFixStyle = css`
  > div:first-child {
    border-top: 1px solid ${theme.colors.neutral10} !important;
  }
`;
