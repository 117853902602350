import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  flex: 1;

  .field-wrapper {
    border: none;
    border-bottom: 2px solid transparent;
    padding-left: 0;

    input {
      text-overflow: ellipsis;
      font-weight: ${theme.font.weight.bold};
      font-size: ${theme.font.size.h2};
    }

    &:has(:is(input):focus) {
      box-shadow: none;
      border-bottom-color: ${theme.colors.primary[50]};
      border-radius: 0;
    }
  }
`;

export const editIconStyle = css`
  cursor: pointer;
`;
