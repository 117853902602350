import { BASE_ROUTE } from '../base';

const BASE_KIOSK_ROUTE = `${BASE_ROUTE}/kiosk`;

export const FORMS_KIOSK_ROUTES = {
  base: BASE_KIOSK_ROUTE,
  patientKiosk: {
    base: `${BASE_KIOSK_ROUTE}/patient-kiosk`,
  },
};
