import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const listContainerStyle = css`
  overflow: auto;
  position: relative;
  height: 100%;
`;

const listItemContainerStyle = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
  border-left: 8px solid transparent;
  border-bottom: 1px solid ${theme.colors.neutral10};
  padding: ${theme.spacing(3, 2.5)};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.neutral5};
  }
`;

interface ContainerStyleConfig {
  isActive: boolean;
}

export function getListItemContainerStyle({ isActive }: ContainerStyleConfig) {
  if (isActive) {
    return [
      listItemContainerStyle,
      css`
        background-color: ${theme.colors.neutral5};
        border-left-color: ${theme.colors.text.interactive};
      `,
    ];
  }

  return listItemContainerStyle;
}
