import { useMemo } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { FinanceQueries } from '@frontend/api-finance';
import { useAppScopeStore } from '@frontend/scope';
import { useModalControl } from '@frontend/design-system';
import { OverdueBy30DaysMessage, OverdueBy40DaysMessage, OverdueBy45DaysMessage } from '../components';

type LocationAlertData = {
  locationId: string;
  isTerminating?: boolean;
  overdueBy?: number;
  warningDayCount?: number;
};

export const useBillingAlerts = () => {
  const { selectedLocationIds, accessibleLocationData } = useAppScopeStore();
  const billingAlertModalControls = useModalControl();

  const { data: featureFlags, isLoading: isLoadingFF } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery({
    flagName: 'Update Credit Card & Subscriber Info',
    groupIds: selectedLocationIds,
  });

  const filteredLocationIds = useMemo(() => {
    if (!featureFlags) return [];
    return selectedLocationIds.filter((locationId) => featureFlags[locationId]);
  }, [featureFlags]);

  const { data: invoiceBalanceResponse, isLoading: isLoadingInvoiceBalance } =
    FinanceQueries.useMultiInvoiceBalanceDetails(
      {
        locationIds: filteredLocationIds,
      },
      {
        enabled: !!filteredLocationIds.length,
      }
    );

  const locationAlertsList = useMemo<LocationAlertData[]>(() => {
    return (invoiceBalanceResponse?.response ?? [])
      .map(({ balanceStatus, locationId = '' }) => {
        const isOverDueBy30Days = Number(balanceStatus?.overdue30 ?? '0') > 0;
        const isOverDueBy40Days = Number(balanceStatus?.overdue40 ?? '0') > 0;
        const isOverDueBy45Days = Number(balanceStatus?.overdue45 ?? '0') > 0;

        return {
          locationId,
          isTerminating: isOverDueBy45Days,
          overdueBy: isOverDueBy45Days ? 45 : isOverDueBy40Days ? 40 : isOverDueBy30Days ? 30 : undefined,
          warningDayCount: balanceStatus?.warningDayCount,
        };
      })
      .filter(({ locationId, overdueBy }) => !!overdueBy && !!locationId)
      .sort(({ overdueBy: overdueByA = 0 }, { overdueBy: overdueByB = 0 }) => overdueByB - overdueByA);
  }, [accessibleLocationData, invoiceBalanceResponse]);

  const billingAlertRender = useMemo(() => {
    if (!locationAlertsList.length) return null;

    const highestPriorityAlert = locationAlertsList[0];
    const locationNames = locationAlertsList
      .map(({ locationId }) => accessibleLocationData[locationId]?.name)
      .join(', ');
    const accountSuspended = locationAlertsList.every(({ locationId }) => !accessibleLocationData[locationId]?.active);
    const locationCount = locationAlertsList.length;

    if (highestPriorityAlert.overdueBy === 45) {
      return (
        <OverdueBy45DaysMessage
          accountSuspended={accountSuspended}
          locationNames={locationNames}
          locationCount={locationCount}
          showMultiLocationBanner={selectedLocationIds.length > 1}
          onAction={billingAlertModalControls.closeModal}
        />
      );
    } else if (highestPriorityAlert.overdueBy === 40) {
      return (
        <OverdueBy40DaysMessage
          locationNames={locationNames}
          locationCount={locationCount}
          warningDayCount={highestPriorityAlert?.warningDayCount ?? 0}
          showMultiLocationBanner={selectedLocationIds.length > 1}
          onAction={billingAlertModalControls.closeModal}
        />
      );
    } else if (highestPriorityAlert.overdueBy === 30) {
      return (
        <OverdueBy30DaysMessage
          locationNames={locationNames}
          locationCount={locationCount}
          showMultiLocationBanner={selectedLocationIds.length > 1}
          onAction={billingAlertModalControls.closeModal}
        />
      );
    }
    return null;
  }, [locationAlertsList, billingAlertModalControls.closeModal, selectedLocationIds]);

  return {
    billingAlertRender,
    isTerminating: !!locationAlertsList?.[0]?.isTerminating,
    isLoading: isLoadingFF || isLoadingInvoiceBalance,
    openBillingAlertModal: billingAlertModalControls.openModal,
    billingAlertModalProps: billingAlertModalControls.modalProps,
  };
};
