import { createContext, useContext } from 'react';

export interface MultiStepModalContext {
  title?: string;
  activeStep?: string;
  hideHeader?: boolean;
  hideBackButtonDefault: boolean;
  setTitle: (title: string) => void;
  goToStep: (step: string) => void;
  goBack: () => void;
  closeModal: () => void;
  resetSteps: (stepToResetTo?: string) => void;
  setDisableCloseOnOverlayClick: (disableCloseOnOverlayClick: boolean) => void;
  setDisableCloseOnEscape: (disableCloseOnEscape: boolean) => void;
  setHideHeader: (hideHeader: boolean) => void;
  setBlueHeader: (blueHeader: boolean) => void;
  setHideBackButton: (hideBackButton: boolean) => void;
}
const initialValues = {
  hideBackButtonDefault: false,
  setTitle: () => {},
  goToStep: () => {},
  goBack: () => {},
  closeModal: () => {},
  resetSteps: () => {},
  setDisableCloseOnOverlayClick: () => {},
  setDisableCloseOnEscape: () => {},
  setHideHeader: () => {},
  setBlueHeader: () => {},
  setHideBackButton: () => {},
};

export const MultiStepModalContext = createContext<MultiStepModalContext>(initialValues);

export const useMultiStepModal = () => {
  const modalContext = useContext(MultiStepModalContext);

  return modalContext;
};
