import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { theme } from '@frontend/theme';
import { Button, ContentLoader, Tabs, useModalControl } from '@frontend/design-system';
import { DemoDataBanner, HelpMenu } from '../components';
import { WeekDaySelector } from '../components/filter-selectors';
import {
  DataSyncInProgress,
  MorningHuddleTabPanel,
  OpportunitiesListTabPanel,
  PracticeAnalyticsFilters,
  PracticeChartsView,
} from '../components/practice';
import {
  useIsPADemoAccount,
  usePracticeAnalyticsDemoData,
  usePracticeAnalyticsShallowStore,
} from '../components/practice/hooks';
import { URLs } from '../constants';
import { useAnalyticsOrgLocations } from '../hooks';
import { trackingIds } from '../tracking-ids';

const initialTab = 'overview';

export const PracticeAnalytics = () => {
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsPADemoAccount();
  const navigate = useNavigate();
  const { hasDataSynced, isLoadingLocations } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });
  const [activeTab, setActiveTab] = useState<string>(initialTab);
  const [reportDate, setReportDate] = useState<string>(dayjs().format('YYYY-MM-DD'));
  const [isFetchingChartsData, setIsFetchingChartsData] = useState<boolean>();
  const morningHuddleModal = useModalControl();
  const { isPageReady, setDemoData, setIsDemoAccount, setIsPageReady, showDemoChipAndBanner } =
    usePracticeAnalyticsShallowStore(
      'isPageReady',
      'setDemoData',
      'setIsDemoAccount',
      'setIsPageReady',
      'showDemoChipAndBanner'
    );
  const demoData = usePracticeAnalyticsDemoData();

  const renderMorningHuddlePageAction = () => (
    <div css={styles.morningHuddleActions}>
      <WeekDaySelector onChange={setReportDate} value={reportDate} />
      <Button
        {...morningHuddleModal.triggerProps}
        iconName='settings'
        label={t('Edit Users')}
        size='large'
        trackingId={trackingIds.practiceAnalytics.editMHUsersButton}
        variant='secondary'
      />
      <HelpMenu feedbackUrl={URLs.PRACTICE_ANALYTICS_FEEDBACK} helpUrl={URLs.PRACTICE_ANALYTICS_HELP} />
    </div>
  );

  useEffect(() => {
    setDemoData(demoData);
  }, [demoData]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  useEffect(() => {
    setIsPageReady(true);
  }, []);

  return (
    <>
      {showDemoChipAndBanner && (
        <DemoDataBanner
          onCTAClick={() => navigate({ to: URLs.PRACTICE_ANALYTICS_SUBSCRIBE })}
          startTrialTrackingId={trackingIds.practiceAnalyticsPLG.plgFreeTrialFromDemo}
          subtitle={t("As a subscriber, you'll see your own practice's data updated daily.")}
          title={t("You're taking Practice Analytics for a test drive with demo data!")}
        />
      )}
      <Page>
        <Page.Header>
          <Page.Header.Heading>
            <Page.Header.Title title={t('Practice Analytics')} />
            <Page.Header.Action>
              {activeTab === 'morning-huddle' ? (
                renderMorningHuddlePageAction()
              ) : hasDataSynced || isDemoAccount ? (
                <HelpMenu feedbackUrl={URLs.PRACTICE_ANALYTICS_FEEDBACK} helpUrl={URLs.PRACTICE_ANALYTICS_HELP} />
              ) : null}
            </Page.Header.Action>
          </Page.Header.Heading>
        </Page.Header>

        <Page.Body>
          {hasDataSynced || isDemoAccount ? (
            <Tabs initialTab={initialTab} onChange={setActiveTab}>
              <Tabs.Bar css={styles.tabBar}>
                <Tabs.Tab
                  id='overview'
                  controls='overview-panel'
                  trackingId={trackingIds.practiceAnalytics.overviewTab}
                >
                  {t('Overview')}
                </Tabs.Tab>
                <Tabs.Tab
                  id='opportunities'
                  controls='opportunities-panel'
                  trackingId={trackingIds.practiceAnalytics.opportunitiesTab}
                >
                  {t('Opportunity List')}
                </Tabs.Tab>
                <Tabs.Tab
                  controls='morning-huddle-panel'
                  id='morning-huddle'
                  trackingId={trackingIds.practiceAnalytics.morningHuddleTab}
                >
                  {t('Morning Huddle')}
                </Tabs.Tab>
              </Tabs.Bar>

              <Tabs.Panel controller='overview' id='overview-panel'>
                <PracticeAnalyticsFilters isLoadingData={isFetchingChartsData} />
                {isPageReady && activeTab === 'overview' && (
                  <PracticeChartsView onFetchStateChange={setIsFetchingChartsData} />
                )}
              </Tabs.Panel>

              <Tabs.Panel controller='opportunities' id='opportunities-panel'>
                {isPageReady && <OpportunitiesListTabPanel />}
              </Tabs.Panel>

              <Tabs.Panel controller='morning-huddle' id='morning-huddle-panel'>
                {isPageReady && activeTab === 'morning-huddle' && (
                  <MorningHuddleTabPanel reportDate={reportDate} settingModalProps={morningHuddleModal.modalProps} />
                )}
              </Tabs.Panel>
            </Tabs>
          ) : (
            <DataSyncInProgress />
          )}
          <ContentLoader show={!isPageReady} />
        </Page.Body>
      </Page>
      <ContentLoader show={isLoadingLocations} />
    </>
  );
};

const styles = {
  tabBar: css`
    margin-bottom: ${theme.spacing(2)};
  `,

  morningHuddleActions: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
  `,
};
