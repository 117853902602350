import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyWeaveClip = lazy(() => import('./svg-components/weave-clip'));

const WeaveClip = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyWeaveClip {...rest} />
  </Suspense>
);

export default WeaveClip;
