import { css } from '@emotion/react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { PetQueries, PetTypes } from '@frontend/api-pet';
import { PetIcon, getPetAge } from '@frontend/vet-components';
import { theme } from '@frontend/theme';
import { Accordion, NakedUl, SpinningLoader, Text } from '@frontend/design-system';
import { PetDetails } from './pet-details';

dayjs.extend(relativeTime);

export const PetList = ({ petsData }: { petsData: PetTypes.Pet[] }) => (
  <NakedUl css={{ width: '100%' }}>
    {petsData?.map((pet) => (
      <PetAccordion key={pet.petId} pet={pet} />
    ))}
  </NakedUl>
);

const PetAccordion = ({ pet }: { pet: PetTypes.Pet }) => {
  const { data: petExtended, isLoading, isSuccess } = PetQueries.useGetPetByPetId(pet.petId || '');

  return (
    <>
      <Accordion
        showBoxShadow={false}
        variant='blank'
        padding={1}
        as='li'
        css={css`
          border-top: 1px solid ${theme.colors.neutral20};
          margin: ${theme.spacing(0, 1)};
          :last-child {
            border-bottom: 1px solid ${theme.colors.neutral20};
          }
          .header-container {
            display: grid;
            grid-template-columns: 48px auto;
            padding-right: 0;
          }
        `}
      >
        <Accordion.Item value={pet.petId} trackingId='contact-panel-pet-detail-services-accordion'>
          <Accordion.Header>
            <div
              css={css`
                align-items: center;
                background: ${theme.colors.neutral10};
                border-radius: 50%;
                display: flex;
                height: 48px;
                justify-content: center;
              `}
            >
              <PetIcon imageType={pet.imageType} />
            </div>
            <div css={{ marginLeft: theme.spacing(1), display: 'grid' }}>
              <Text weight='bold' textAlign='left'>
                {pet.name}
              </Text>
              <NakedUl
                css={css`
                  display: flex;
                  justify-content: flex-start;
                  .pet-detail {
                    overflow: hidden;
                    text-wrap: nowrap;
                    max-width: 79px;
                    margin-right: ${theme.spacing(0.75)};
                    text-overflow: ellipsis;
                    :last-child {
                      margin-right: 0;
                    }
                  }
                `}
              >
                {pet.classification ? (
                  <Text size='small' color='light' as='li' title={pet.classification} className='pet-detail'>
                    {pet.classification}
                  </Text>
                ) : null}
                {pet.breed ? (
                  <Text size='small' color='light' as='li' title={pet.breed} className='pet-detail'>
                    {pet.breed}
                  </Text>
                ) : null}
                {pet.gender ? (
                  <Text size='small' color='light' as='li' title={pet.gender} className='pet-detail'>
                    {pet.gender}
                  </Text>
                ) : null}
              </NakedUl>
              <Text size='small' color='light' textAlign='left'>
                {getPetAge(pet.birthdate)}
              </Text>
            </div>
          </Accordion.Header>
          <Accordion.Body>
            {isSuccess && !isLoading ? <PetDetails petExtended={petExtended} /> : <SpinningLoader />}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
