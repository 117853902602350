import { useCallback, useLayoutEffect, useState } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useComposerSection } from '../../../hooks';
import { ComposerSectionProps } from '../../../types';

type Orientation = 'top' | 'bottom';

type FloatingToolbarChildProps = {
  children: React.ReactNode;
  orientation?: Orientation;
  moveOut?: boolean;
};

type SectionPaddingProps = ComposerSectionProps & {
  paddingTop?: string;
  paddingBottom?: string;
};

// Move floating toolbar out of the section when padding top/bottom is less than 24px to avoid overlapping with section content.
const useGetFloatingPositionStyle = (orientation: Orientation = 'top'): Interpolation<Theme> => {
  const { props } = useComposerSection<SectionPaddingProps>();
  const [translateYValue, setTranslateYValue] = useState<string>(orientation === 'top' ? '-50%' : '50%');

  const isPaddingValueLessThan24 = useCallback((padding = '0') => parseFloat(padding) < 24, []);

  useLayoutEffect(() => {
    if (orientation === 'top') {
      if (isPaddingValueLessThan24(props.paddingTop)) setTranslateYValue('-105%');
      else setTranslateYValue('-50%');
    } else {
      if (isPaddingValueLessThan24(props.paddingBottom)) setTranslateYValue('105%');
      else setTranslateYValue('50%');
    }
  }, [orientation, props.paddingBottom, props.paddingTop]);

  return {
    [orientation === 'top' ? 'top' : 'bottom']: '0',
    transform: `translateY(${translateYValue})`,
    transition: 'transform 0.2s ease-in-out',
  };
};

export const FloatingToolbarLeft = ({ children, orientation = 'top', ...rest }: FloatingToolbarChildProps) => {
  const floatingPositionStyles = useGetFloatingPositionStyle(orientation);

  return (
    <div
      css={[
        {
          display: 'inline-flex',
          justifyContent: 'flex-start',
          columnGap: theme.spacing(1),
          zIndex: theme.zIndex.middle,
          marginLeft: theme.spacing(1),
          position: 'absolute',
          left: 0,
        },
        floatingPositionStyles,
      ]}
      className='floating-toolbar'
      {...rest}
    >
      {children}
    </div>
  );
};

// TODO: Move center positioning logic here. Current logic always depends on parent flex container.
export const FloatingToolbarCenter = ({ children, orientation = 'top', ...rest }: FloatingToolbarChildProps) => {
  const floatingPositionStyles = useGetFloatingPositionStyle(orientation);

  return (
    <div
      css={[
        {
          display: 'inline-flex',
          justifyContent: 'center',
          position: 'absolute',
          zIndex: theme.zIndex.middle,
        },
        floatingPositionStyles,
      ]}
      className='floating-toolbar'
      {...rest}
    >
      {children}
    </div>
  );
};

export const FloatingToolbarRight = ({ children, orientation = 'top', ...rest }: FloatingToolbarChildProps) => {
  const floatingPositionStyles = useGetFloatingPositionStyle(orientation);

  return (
    <div
      css={[
        {
          display: 'inline-flex',
          justifyContent: 'flex-end',
          columnGap: theme.spacing(1),
          marginRight: theme.spacing(1),
          zIndex: theme.zIndex.middle,
          position: 'absolute',
          right: 0,
        },
        floatingPositionStyles,
      ]}
      className='floating-toolbar'
      {...rest}
    >
      {children}
    </div>
  );
};
