import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';

type LocationPanelProps = {
  locationName: string;
  onChangeLocationClick: () => void;
};

export const LocationPanel = ({ locationName, onChangeLocationClick }: LocationPanelProps) => {
  const { t } = useTranslation('phone');

  return (
    <>
      <Text>{t('This call route is assigned to the location below.')}</Text>

      <div
        css={css`
          display: flex;
          border: 1px solid ${theme.colors.neutral20};
          border-radius: ${theme.borderRadius.medium};
          padding: ${theme.spacing(2)};
          justify-content: space-between;
          height: 64px;
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            gap: ${theme.spacing(0.5)};
          `}
        >
          <Icon name='location' />
          <Text weight='bold'>{locationName}</Text>
        </div>

        <Button variant='tertiary' onClick={onChangeLocationClick}>
          <Icon name='sync' />
          {t('Change')}
        </Button>
      </div>
    </>
  );
};
