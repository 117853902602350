import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../../utils';
import { PatientsBreakdownTable } from '../components';
import { MCTChartsBase } from './mct-charts-base';

// TODO: Replace with real data
const dummyData = {
  appointmentsScheduled: 444,
  existingPatients: 110,
  newPatients: 188,
};

export const AppointmentScheduledChart = () => {
  const { t } = useTranslation('analytics');

  return (
    <MCTChartsBase
      chartConfig={{
        data: {
          groups: [
            {
              name: 'newPatients',
              values: {
                newPatients: dummyData.newPatients,
              },
            },
            {
              name: 'existingPatients',
              values: {
                existingPatients: dummyData.existingPatients,
              },
            },
          ],
        },
        maxValue: dummyData.newPatients + dummyData.existingPatients,
        type: 'bar',
      }}
      customLegendsData={{
        newPatients: {
          label: t('New Patients'),
        },
        existingPatients: {
          label: t('Existing Patients'),
        },
        total: {
          color: theme.colors.neutral20,
          label: t('Total'),
          value: dummyData.newPatients + dummyData.existingPatients,
        },
      }}
      legendsFormatValue={formatters.value.format}
      legendsValues={dummyData}
      message={
        <Trans t={t}>
          Patients scheduled <strong>{{ appointments: dummyData.appointmentsScheduled }}</strong> appointments
        </Trans>
      }
      title={t('Appointments Scheduled')}
      value={formatters.value.format(dummyData.newPatients + dummyData.existingPatients)}
    >
      <PatientsBreakdownTable />
    </MCTChartsBase>
  );
};
