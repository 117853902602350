import { Heading } from '@frontend/design-system';
import { useLineKeyContext } from '../store/line-key-provider';

export const DeviceLayoutHeader = () => {
  const { model, device } = useLineKeyContext();

  return (
    <Heading
      as='div'
      className={`device-model-layout-header ${device?.model === 't57w' && 'touch-screen'}`}
      level={2}
      textAlign='center'
      color='white'
    >
      {model?.name ?? device?.model}
    </Heading>
  );
};
