import { pendoTags } from '../../../../shared/constants';
import { BuilderFieldDivisionTypes } from '../../types';

export const MEDICAL_HISTORY_WITH_WRITEBACK: BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory[] = [
  {
    label: 'Medical History',
    fieldsOrder: [
      {
        key: 'allergies',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.medicalHistory.allergies,
      },
      {
        key: 'medications',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.medicalHistory.medications,
      },
      {
        key: 'disease',
        syncable: true,
        trackingId: pendoTags.formBuilder.categories.primaryFields.medicalHistory.disease,
      },
    ],
  },
];
