import React, { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import {
  useInteractions,
  useFloating,
  FloatingFocusManager,
  FloatingPortal,
  useDismiss,
  useClick,
} from '@floating-ui/react';
import { AnimatePresence, motion, type DragHandlers } from 'motion/react';
import { KeyNames, useEventListener } from '@frontend/design-system';
import { CarouselButton } from './atoms/carousel-button';
import { CarouselCounter } from './atoms/carousel-counter';
import { MotionFloatingOverlay, overlayAnimationVariants } from './atoms/motion-overlay';
import { calculateAnimation } from './atoms/utils';

type ModalCarouselConfig = {
  visibleItemCount?: number;
  showNavigationButtons?: boolean;
  showCounter?: boolean;
};

type ModalCarouselProps<T> = ModalCarouselConfig & {
  isOpen: boolean;
  onClose: () => void;
  items: T[];
  initialIndex?: number;
  getUniqueId?: (item: T) => string;
  renderItem: (item: T, index: number) => React.ReactNode;
};

export function ModalCarousel<T>({
  isOpen,
  onClose,
  items,
  initialIndex = 0,
  getUniqueId,
  renderItem,
  visibleItemCount = 3,
  showNavigationButtons = true,
  showCounter = true,
}: ModalCarouselProps<T>) {
  const [activeIndex, setActiveIndex] = useState(initialIndex);
  const [direction, setDirection] = useState<'next' | 'prev'>('next');

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (open) => {
      if (!open) {
        setActiveIndex(0);
        onClose();
      }
    },
  });

  const click = useClick(context);
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' });
  const { getFloatingProps } = useInteractions([click, dismiss]);

  const handleNext = useCallback(() => {
    setDirection('next');
    setActiveIndex((prev) => (prev + 1) % items.length);
  }, [items.length]);

  const handlePrev = useCallback(() => {
    setDirection('prev');
    setActiveIndex((prev) => (prev - 1 + items.length) % items.length);
  }, [items.length]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KeyNames.Left) handlePrev();
      if (event.key === KeyNames.Right) handleNext();
    },
    [handleNext, handlePrev]
  );

  useEventListener('keydown', handleKeyDown, isOpen);

  const indexes = Array.from(
    { length: Math.min(visibleItemCount, items.length) },
    (_, i) => (activeIndex + i) % items.length
  );
  const visibleItems = indexes.map((i) => items[i]);

  const handleDragEnd: DragHandlers['onDragEnd'] = (_, info) => {
    const swipeThreshold = 100;
    const swipeDirection = info.offset.x > 0 ? 'right' : 'left';

    if (Math.abs(info.offset.x) > swipeThreshold) {
      if (swipeDirection === 'right') handlePrev();
      else handleNext();
    }
  };

  return (
    <FloatingPortal>
      <FloatingFocusManager context={context}>
        <AnimatePresence initial={false} custom={direction} mode='sync'>
          {isOpen && (
            <MotionFloatingOverlay
              lockScroll
              initial='initial'
              animate='animate'
              exit='exit'
              variants={overlayAnimationVariants}
              css={(theme) => css`
                overflow: hidden !important;
                background: rgba(0, 33, 82, 0.3);
                top: ${theme.heightOffset}px !important;
              `}
            >
              <motion.div {...getFloatingProps()}>
                <motion.div style={{ display: 'flex', justifyContent: 'center' }} ref={refs.setFloating}>
                  <AnimatePresence initial={false} custom={direction} mode='sync'>
                    {visibleItems.map((item, stackIndex) => {
                      if (!item) return null;

                      const uniqueId = getUniqueId?.(item);
                      const isTopItem = stackIndex === 0;
                      const { zIndex, initial, animate, exit, whileHover } = calculateAnimation(
                        stackIndex,
                        direction,
                        visibleItems.length
                      );

                      return (
                        <motion.div
                          key={uniqueId ?? `${activeIndex}-${stackIndex}`}
                          custom={direction}
                          drag={isTopItem ? 'x' : false}
                          dragConstraints={{ left: 0, right: 0 }}
                          onDragEnd={handleDragEnd}
                          dragElastic={0.1}
                          onClick={() => {
                            if (!isTopItem) {
                              setDirection('next');
                              setActiveIndex((activeIndex + stackIndex) % items.length);
                            }
                          }}
                          initial={initial}
                          animate={animate}
                          exit={exit}
                          whileHover={whileHover}
                          transition={{ duration: 0.3 }}
                          style={{
                            zIndex,
                            position: 'absolute',
                            width: 'auto',
                            height: '100%',
                            pointerEvents: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            translateY: '-50%',
                            maxWidth: 600,
                            top: '50%',
                          }}
                        >
                          <div
                            css={css`
                              display: flex;
                              flex-direction: column;
                              align-items: center;
                            `}
                          >
                            <div
                              style={{
                                pointerEvents: 'auto',
                              }}
                            >
                              {renderItem(item, (activeIndex + stackIndex) % items.length)}
                            </div>
                          </div>
                        </motion.div>
                      );
                    })}
                  </AnimatePresence>
                  {showCounter && <CarouselCounter activeIndex={activeIndex} total={items.length} />}
                  {showNavigationButtons && items.length > 1 && (
                    <>
                      <CarouselButton direction='left' onClick={handlePrev} />
                      <CarouselButton direction='right' onClick={handleNext} />
                    </>
                  )}
                </motion.div>
              </motion.div>
            </MotionFloatingOverlay>
          )}
        </AnimatePresence>
      </FloatingFocusManager>
    </FloatingPortal>
  );
}
