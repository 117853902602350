import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel, toHHmmss } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  incoming: theme.colors.secondary.seaweed30,
  outgoing: theme.colors.warning50,
};

const labels = {
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
};

type ProcessedData = {
  chartData: BarChartData;
  totals: {
    incoming: number;
    outgoing: number;
  };
};

export const AverageCallDurationChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.CallsAggregationKey>('call_direction');

  const processeData: ProcessedData = useMemo(() => {
    let groups: BarChartData['groups'] = [];
    const totals: ProcessedData['totals'] = {
      incoming: 0,
      outgoing: 0,
    };

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { calls, duration }]) => {
        const averageIncoming = (duration?.inbound || 0) / (calls?.inbound || 1);
        const averageOutgoing = (duration?.outbound || 0) / (calls?.outbound || 1);

        totals.incoming += averageIncoming;
        totals.outgoing += averageOutgoing;

        return {
          name: locationNames?.[key] || key,
          values: {
            incoming: averageIncoming,
            outgoing: averageOutgoing,
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { inbound, outbound } = Object.values(locationData || {})[0] || {};
        const limitedIncomingCalls = inbound?.calls?.hourly || [];
        const limitedIncomingDuration = inbound?.duration?.hourly || [];
        const limitedOutgoingCalls = outbound?.calls?.hourly || [];
        const limitedOutgoingDuration = outbound?.duration?.hourly || [];

        groups = limitedIncomingCalls.map((incomingCalls, i) => {
          const averageIncoming = (limitedIncomingDuration[i] || 0) / (incomingCalls || 1);
          const averageOutgoing = (limitedOutgoingDuration[i] || 0) / (limitedOutgoingCalls[i] || 1);

          totals.incoming += averageIncoming;
          totals.outgoing += averageOutgoing;

          return {
            name: getHourlyInsightsLabel(i),
            values: {
              incoming: averageIncoming,
              outgoing: averageOutgoing,
            },
          };
        });
      } else {
        groups = Object.entries(locationData).map(([date, { inbound, outbound }]) => {
          const averageIncoming = (inbound?.duration?.daily || 0) / (inbound?.calls?.daily || 1);
          const averageOutgoing = (outbound?.duration?.daily || 0) / (outbound?.calls?.daily || 1);

          totals.incoming += averageIncoming;
          totals.outgoing += averageOutgoing;

          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              incoming: averageIncoming,
              outgoing: averageOutgoing,
            },
          };
        });
      }
    }

    return {
      chartData: {
        groups,
      },
      totals,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locationNames]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Incoming vs Outgoing: Refers to the average duration of the incoming and outgoing calls. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Average Call Duration')}
      />
      <Chart.BarChart
        appearance={defaultChartAppearance}
        data={processeData.chartData}
        formatValue={(value) => toHHmmss(value)}
      />
      <Chart.Legends formatValue={(value) => toHHmmss(value)} values={processeData.totals} />
    </Chart>
  );
};
