import React, { NamedExoticComponent, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useId } from '@floating-ui/react';
import { AnimatePresence, motion } from 'motion/react';
import { theme } from '@frontend/theme';
import {
  CustomChangeEvent,
  FieldChangeEvent,
  Heading,
  NakedButton,
  NakedUl,
  SearchField,
  Text,
  useFormField,
  useOnClickOutside,
} from '@frontend/design-system';
import { DropdownListSelectorOption } from './DropdownListSelectorOption';
import { DropdownProvider } from './DropdownListSelectorProvider';

const DEFAULT_HEIGHT = 40;
const DEFAULT_PLACEHOLDER_FONT_SIZE = 16;

interface DropdownListSelectorProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onChange'> {
  placeHolder: string;
  value: string;
  displayValue?: string;
  children: React.ReactNode;
  name: string;
  onChange: (value: string) => void;
  icon?: React.ReactNode;
  hasDottedBorder?: boolean;
  shouldHaveBorder?: boolean;
  isDisabled?: boolean;
  listWidth?: string | number;
  shouldShowOnlyDisplayValueOnSelect?: boolean;
  height?: number;
  placeHolderFontSize?: number;
  setSearchValue?: (value: string) => void;
}

interface DropdownListSelectorInterface {
  Option: React.MemoExoticComponent<typeof DropdownListSelectorOption>;
}

const DropdownListSelectorContainer = ({
  icon,
  placeHolder,
  hasDottedBorder = false,
  shouldHaveBorder = true,
  isDisabled = false,
  value,
  displayValue = '',
  children,
  name,
  onChange,
  listWidth,
  shouldShowOnlyDisplayValueOnSelect = false,
  height = DEFAULT_HEIGHT,
  placeHolderFontSize = DEFAULT_PLACEHOLDER_FONT_SIZE,
  setSearchValue,
  ...rest
}: DropdownListSelectorProps) => {
  const id = useId();
  const dropdownSelectorContainerRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  const searchFieldProps = useFormField({ type: 'text', value: '' });

  const openList = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const handleOnChange = (value?: CustomChangeEvent<any> | FieldChangeEvent<any>) => {
    const hasValue = value && 'value' in value && typeof value.value === 'string';
    if (hasValue) {
      onChange(value.value);
      setIsOpen(false);
    }
  };

  useOnClickOutside({
    ref: dropdownSelectorContainerRef,
    handler: openList,
    active: isOpen,
    captureClick: false,
  });

  const filterChildrenBySearchValue = useMemo(() => {
    const childrenArray = React.Children.toArray(children);
    if (searchFieldProps.value === '') {
      isOpen && setSearchValue && setSearchValue('');
      return childrenArray;
    }
    return childrenArray.filter(
      (child): child is React.ReactElement<any, string | React.JSXElementConstructor<any>> => {
        if (React.isValidElement(child)) {
          if (isOpen && name === 'search-patient-selector') {
            setSearchValue && setSearchValue(searchFieldProps.value);
            return !!searchFieldProps.value;
          } else {
            const searchValue = child.props['searchValue'];
            if (typeof searchValue === 'string') {
              return searchValue.toLowerCase().includes(searchFieldProps.value?.toLowerCase());
            }
          }
        }
        return false;
      }
    );
  }, [searchFieldProps.value, children, isOpen]);

  const selectedChildren = useMemo(() => {
    const childrenArray = React.Children.toArray(children);
    return childrenArray.find((child): child is React.ReactElement<any, string | React.JSXElementConstructor<any>> => {
      if (React.isValidElement(child)) {
        const childrenValue = child.props['value'];
        if (typeof childrenValue === 'string') {
          return childrenValue === value || childrenValue.toLowerCase().includes(value);
        }
      }
      return false;
    });
  }, [value, children]);

  // clear search field when dropdown is re-opened
  useLayoutEffect(() => {
    if (isOpen) {
      searchFieldProps.onChange({ value: '', name: 'dropdown-search' });
    }
  }, [isOpen]);

  return (
    <div ref={dropdownSelectorContainerRef} {...rest}>
      <DropdownProvider
        active={isOpen}
        setActive={setIsOpen}
        name={name}
        id={id}
        value={value}
        onChange={(e) => handleOnChange(e)}
        onFocus={openList}
        onBlur={openList}
      >
        <NakedButton
          onClick={openList}
          css={dropdownListSelectorStyles(hasDottedBorder, shouldHaveBorder, height, isDisabled)}
          disabled={isDisabled}
        >
          {!value ? (
            <>
              {!!icon && icon}
              <Text
                weight='bold'
                color='light'
                css={{ marginLeft: icon ? theme.spacing(1) : 0, fontSize: placeHolderFontSize }}
              >
                {placeHolder}
              </Text>
            </>
          ) : (
            <div css={listItemsContainerStyles}>
              {name === 'search-patient-selector' ? (
                <Heading
                  as={'h2'}
                  css={{ marginLeft: theme.spacing(-2), color: isDisabled ? theme.colors.neutral20 : 'inherit' }}
                >
                  {displayValue}
                </Heading>
              ) : (
                <>
                  <div>{selectedChildren?.props['icon'] ? selectedChildren?.props['icon'] : icon}</div>
                  <div css={{ marginLeft: theme.spacing(1) }}>
                    {shouldShowOnlyDisplayValueOnSelect
                      ? selectedChildren?.props['displayValue']
                      : selectedChildren?.props['children']}
                  </div>
                </>
              )}
            </div>
          )}
        </NakedButton>
        {isOpen ? (
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              css={listContainerStyles(listWidth ?? (dropdownSelectorContainerRef.current?.clientWidth || '100%'))}
            >
              <SearchField {...searchFieldProps} css={{ margin: theme.spacing(0.5) }} name='dropdown-search' />
              <div css={ulContainerStyles}>
                <NakedUl css={listItemStyles}>{filterChildrenBySearchValue}</NakedUl>
              </div>
            </motion.div>
          </AnimatePresence>
        ) : null}
      </DropdownProvider>
    </div>
  );
};

const DropdownListSelectorCompoundComponent =
  DropdownListSelectorContainer as NamedExoticComponent<DropdownListSelectorProps> & DropdownListSelectorInterface;

DropdownListSelectorCompoundComponent.Option = React.memo(DropdownListSelectorOption);

export const DropdownListSelector = DropdownListSelectorCompoundComponent;

// css styles
const dropdownListSelectorStyles = (
  hasDottedBorder: boolean,
  shouldHaveBorder: boolean,
  height: number,
  isDisabled: boolean
) =>
  css({
    border:
      hasDottedBorder && shouldHaveBorder
        ? `1px dotted  ${theme.colors.neutral30}`
        : shouldHaveBorder
        ? `1px solid ${theme.colors.neutral40}`
        : 'none',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing(1, 2),
    maxHeight: height ? height : '40px',
    cursor: isDisabled ? 'default' : 'pointer',
  });

const listContainerStyles = (width: number | string) =>
  css({
    backgroundColor: theme.colors.white,
    borderRadius: theme.borderRadius.small,
    position: 'absolute',
    zIndex: 9999,
    padding: theme.spacing(1),
    boxShadow: theme.shadows.floating,
    width,
  });

const listItemStyles = css({
  '> li': {
    padding: theme.spacing(1),
  },
});

const listItemsContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  '> h1, h2': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '360px',
  },
});

const ulContainerStyles = css({ maxHeight: '360px', overflowY: 'auto' });
