import { useCallback } from 'react';
import { limitDateRange } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { debounce } from '@frontend/timer';
import { ExportIcon, useAlert } from '@frontend/design-system';
import { useCanDoAction, useExportRefundsQuery } from '../../../hooks';
import {
  FilterExportWarning,
  InvoiceExportModal,
  useInvoiceExportModalControl,
} from '../../Invoices/InvoiceExport/invoice-export-confirmation';

type CsvExportProps = {
  dateRange: { start?: string; end?: string };
  hasSearchParams: boolean;
  hasFiltersOtherThanDates: boolean;
};

export const useCsvExport = ({ dateRange, hasFiltersOtherThanDates, hasSearchParams }: CsvExportProps) => {
  const alert = useAlert();
  const { canExport } = useCanDoAction();
  const { t } = useTranslation('payments');
  const { exportRefunds, isExporting } = useExportRefundsQuery();

  const { showInvoiceExportWarning, ...invoiceModalProps } = useInvoiceExportModalControl();

  const handleExportRefundsClick = useCallback(
    debounce(async () => {
      if (canExport) {
        const warnings: FilterExportWarning = {};

        if (hasSearchParams) {
          warnings.searchParams = true;
        }
        const dateRangeExists = !!(dateRange.start && dateRange.end);
        // see if data will be truncated
        if (dateRangeExists && limitDateRange([dateRange.start, dateRange.end]).limited) {
          warnings.truncateDateRange = true;
        }
        // when other filters are applied with out a date range, they are truncated to the
        // default MAX range (3 months) because the result will show for all time
        else if (!dateRangeExists && hasFiltersOtherThanDates) {
          warnings.truncateDateRange = true;
        }

        // show warnings if any
        if (Object.keys(warnings).length > 0) {
          showInvoiceExportWarning(warnings);
        } else {
          exportRefunds();
        }
      } else {
        alert.error(t('Payment admin role required'));
      }
    }, 1000),
    [dateRange, hasFiltersOtherThanDates, hasSearchParams]
  );

  const ExportAction = {
    label: t('Export'),
    Icon: ExportIcon,
    onClick: handleExportRefundsClick,
  };

  return {
    ExportAction,
    InvoiceExportModal: <InvoiceExportModal {...invoiceModalProps} onConfirm={exportRefunds} />,
    isExporting,
  };
};
