import { FC, useMemo } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Text, TextLink, useTooltip } from '@frontend/design-system';

export type OverdueMessageBaseProps = {
  locationNames?: string;
  locationCount: number;
  showMultiLocationBanner?: boolean;
  onAction?: () => void;
};

export const OverdueBy30DaysMessage: FC<OverdueMessageBaseProps> = ({
  locationNames,
  locationCount,
  showMultiLocationBanner,
  onAction,
}) => {
  const { navigate } = useSettingsNavigate();
  const { t } = useTranslation('billingAlerts');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  const goToBilling = () => {
    onAction?.();
    navigate({ to: '/organization/invoice-history' });
  };

  const title = useMemo(() => {
    return showMultiLocationBanner ? (
      <Trans t={t} count={locationCount} values={{ locationCount }}>
        Your bill is critically overdue for{' '}
        <Text as='span' weight='bold' {...triggerProps}>
          {{ locationCount }} locations.
        </Text>
      </Trans>
    ) : (
      t('Your bill is critically overdue.')
    );
  }, [locationCount, showMultiLocationBanner, triggerProps]);

  return (
    <>
      <Text>
        {title}{' '}
        <Trans t={t}>
          Your account will be suspended soon. Please resolve your balance{' '}
          <TextLink as='span' onClick={goToBilling} trackingId='billing-overdue-30-resolve-balance-here-btn'>
            here
          </TextLink>{' '}
          to avoid interruption of services. Call{' '}
          <TextLink as='span' href='tel:+18885795668' trackingId='billing-overdue-30-call-support-link'>
            888.579.5668
          </TextLink>{' '}
          option 2, then option 3
        </Trans>
      </Text>
      <Tooltip {...tooltipProps}>{locationNames}</Tooltip>
    </>
  );
};
