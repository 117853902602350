import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Chart } from '@frontend/charts';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { useLocationFilter } from '@frontend/location-filter';
import { theme } from '@frontend/theme';
import { ContentLoader, Text } from '@frontend/design-system';
import { URLs } from '../../../constants';
import { trackingIds } from '../../../tracking-ids';
import { API_URLs } from '../constants';
import { useFetchMissedCallTextConversionSummary } from '../hooks';

/**
 * @dashboard-widget
 *
 * id: missed-call-text-roi
 * title: Missed Call Text Conversion
 * description: Highlights the conversion of the missed call text.
 * icon: message-small
 */

export const MissedCallTextROIWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();

  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState('last30days');
  const { filteredLocationIds } = useLocationFilter();
  const { currentSize, isEditMode } = useDashboardWidget();
  const isTiny = currentSize === 'tiny-slim';

  const { conversionValue, data, isLoading } = useFetchMissedCallTextConversionSummary({
    apiParams: {
      payload: {
        start_date: startDate,
        end_date: endDate,
        location_id: filteredLocationIds,
      },
      url: API_URLs.missedCallText.appointmentScheduledSummary,
    },
    totalCountKey: 'sms_count',
  });

  return (
    <DashboardWidget
      className='clickable'
      css={styles.mainWrapper}
      {...(!isEditMode
        ? {
            'data-trackingid': trackingIds.roi.missedCallTextRoiWidget,
            onClick: () => {
              navigate({
                to: URLs.ROI_MISSED_CALL_TEXT,
              });
            },
          }
        : {})}
    >
      <DashboardWidget.Header>
        {!isTiny && <TimePeriodListBoxMenu {...timePeriodMenuState} readonly value='last30days' />}
      </DashboardWidget.Header>
      <DashboardWidget.Content css={styles.contentWrapper}>
        <div style={{ width: theme.spacing(17) }}>
          <Text css={styles.totalText} weight='bold'>
            {conversionValue}
          </Text>
          <Text style={{ color: theme.colors.white }}>{t('Appts Scheduled')}</Text>
        </div>

        {!isTiny && (
          <div style={{ flex: 1 }}>
            <Chart.TinyAreaChart data={data?.map(({ count = 0 }) => count) ?? []} color={theme.colors.white} />
          </div>
        )}
        <ContentLoader show={isLoading} size='small' />
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

MissedCallTextROIWidget.config = {
  size: {
    large: 'small-narrow',
    medium: 'small-narrow',
    small: 'tiny-slim',
    extraSmall: 'tiny-slim',
  },
  feature: 'messaging',
};

const styles = {
  mainWrapper: css`
    background: linear-gradient(45deg, ${theme.colors.primary40}, #94bfff);

    * {
      color: ${theme.colors.white};
    }

    &.clickable {
      &:hover {
        // The second gradient color is not available in the theme
        background: linear-gradient(45deg, ${theme.colors.primary50}, #94bfff);
      }
    }
  `,

  contentWrapper: css`
    display: flex;
    gap: ${theme.spacing(3)};

    p {
      line-height: 1;
    }
  `,

  totalText: css`
    color: ${theme.colors.white};
    font-size: ${theme.fontSize(48)};
  `,
};
