import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyTextClip = lazy(() => import('./svg-components/text-clip'));

const TextClip = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyTextClip {...rest} />
  </Suspense>
);

export default TextClip;
