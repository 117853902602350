import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyAnsweredClip = lazy(() => import('./svg-components/answered-clip'));

const AnsweredClip = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyAnsweredClip {...rest} />
  </Suspense>
);

export default AnsweredClip;
