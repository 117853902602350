import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { BulkEmailPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  BannerNotification,
  Button,
  ButtonBar,
  ModalControlTriggerProps,
  NakedButton,
  Text,
  TextLink,
  useTooltip,
} from '@frontend/design-system';
import { Mode, TabMode } from '../types';

type FooterProps = {
  audienceCount?: number;
};

const FormModeCountFooter = ({ audienceCount }: FooterProps) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <Text css={{ textAlign: 'right' }}>
      {audienceCount === undefined
        ? t('Counting contacts...')
        : t('{{count}} Contacts Match Your Filters', { count: audienceCount })}
    </Text>
  );
};

const DisplayModeCountFooter = ({
  audienceCount,
  duplicateCount,
  sourceText,
}: {
  audienceCount?: number;
  duplicateCount?: number;
  sourceText: string;
}) => {
  const { t } = useTranslation('bulk-messaging');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'top-end' });

  return (
    <>
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
        <Text color='subdued' css={{ fontSize: theme.fontSize(24) }} weight='bold'>
          {t('{{count}} Contact', { count: audienceCount })}
        </Text>
        <Text css={{ display: 'flex', alignItems: 'center' }}>
          {sourceText}
          <NakedButton {...triggerProps}>
            <Icon name='info-small' css={{ marginLeft: theme.spacing(0.5) }} />
          </NakedButton>
        </Text>
        <Tooltip {...tooltipProps}>
          {t(
            'If identical contacts are found across segments, they will be de-duplicated upon the creation of this audience. For this reason, your final audience could be slightly smaller than predicted.'
          )}
        </Tooltip>
      </div>
      {!!duplicateCount && (
        <BannerNotification
          message={
            duplicateCount === 1
              ? t(
                  '1 contact will receive this campaign more than once, due to being associated with multiple selected locations.'
                )
              : t(
                  '{{number}} contacts will receive this campaign more than once, due to being associated with multiple selected locations.',
                  { number: duplicateCount }
                )
          }
          status='info'
          css={{ marginTop: theme.spacing(2) }}
        />
      )}
    </>
  );
};

type TrayFooterProps = FooterProps & {
  duplicateCount?: number;
  isValid: boolean;
  mode: Mode;
  onCancel: () => void;
  onConfirm: () => void;
  sourceText: string;
  tabMode: TabMode;
  triggerProps: ModalControlTriggerProps;
};

export const TrayFooter = ({
  audienceCount,
  duplicateCount,
  isValid,
  mode,
  onCancel,
  onConfirm,
  sourceText,
  tabMode,
  triggerProps,
}: TrayFooterProps) => {
  const { t } = useTranslation('bulk-messaging');

  return (
    <>
      <footer css={{ borderTop: `1px solid ${theme.colors.neutral20}`, padding: theme.spacing(2, 4, 0) }}>
        {mode === 'form' ? (
          <FormModeCountFooter audienceCount={audienceCount} />
        ) : (
          <DisplayModeCountFooter
            audienceCount={audienceCount}
            duplicateCount={duplicateCount}
            sourceText={sourceText}
          />
        )}
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: theme.spacing(3),
          }}
        >
          <TextLink
            css={{
              display: 'flex',
              alignItems: 'center',
              columnGap: theme.spacing(0.75),
              minWidth: 'fit-content',
            }}
            trackingId={`${BulkEmailPrefixes.Audience}-message-allotment-btn`}
            {...triggerProps}
          >
            <Icon name='locations' />
            <Text as='span' weight='bold' color='primary'>
              {t('Message Allotment')}
            </Text>
          </TextLink>
          <ButtonBar css={{ justifyContent: 'flex-end', padding: 0 }}>
            {mode === 'form' && (
              <Button
                variant='secondary'
                onClick={onCancel}
                trackingId={`${BulkEmailPrefixes.Audience}-filter-back-btn`}
              >
                {t('Back')}
              </Button>
            )}
            <Button
              onClick={onConfirm}
              trackingId={`${BulkEmailPrefixes.Audience}-filter-apply-btn`}
              disabled={!isValid}
            >
              {mode === 'form' ? t('Create Segment') : tabMode === 'saved-lists' ? t('Apply List') : t('Close')}
            </Button>
          </ButtonBar>
        </div>
      </footer>
    </>
  );
};
