import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, useTooltip } from '@frontend/design-system';

export const ActionBarButton = ({
  iconName,
  label,
  isActive,
  trackingId,
  onClick,
}: {
  iconName: IconName;
  label: string;
  isActive?: boolean;
  trackingId?: string;
  onClick: () => void;
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip({ placement: 'right-end' });

  return (
    <>
      <NakedButton
        {...triggerProps}
        onClick={onClick}
        trackingId={trackingId}
        css={css`
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          width: 100%;
          color: ${isActive ? theme.colors.primary[50] : theme.colors.neutral70};
          background-color: ${isActive ? theme.colors.primary5 : theme.colors.white};
          transition: background-color 0.2s, color 0.2s, border 0.2s;
          cursor: pointer;
          :hover {
            background-color: ${theme.colors.neutral5};
            color: ${theme.colors.primary50};
          }
        `}
      >
        <Icon name={iconName} />
      </NakedButton>
      <Tooltip {...tooltipProps}>{label}</Tooltip>
    </>
  );
};
