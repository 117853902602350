// TODO :: Update to use Schema

import { Rating } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { AnalyticsCommonTypes } from '../common';
// Enums

export enum AppointmentTypeEnum {
  APPOINTMENT_TYPE_UNKNOWN = 'APPOINTMENT_TYPE_UNKNOWN',
  APPOINTMENT_TYPE_IMAGING = 'APPOINTMENT_TYPE_IMAGING',
  APPOINTMENT_TYPE_COSMETIC = 'APPOINTMENT_TYPE_COSMETIC',
  APPOINTMENT_TYPE_ORTHODONTICS = 'APPOINTMENT_TYPE_ORTHODONTICS',
  APPOINTMENT_TYPE_SURGERY = 'APPOINTMENT_TYPE_SURGERY',
  APPOINTMENT_TYPE_PREVENTIVE = 'APPOINTMENT_TYPE_PREVENTIVE',
  APPOINTMENT_TYPE_HYGIENE = 'APPOINTMENT_TYPE_HYGIENE',
  APPOINTMENT_TYPE_RESTORATIVE = 'APPOINTMENT_TYPE_RESTORATIVE',
  APPOINTMENT_TYPE_EMERGENCY = 'APPOINTMENT_TYPE_EMERGENCY',
}

export enum CallDirectionEnum {
  DIRECTION_INBOUND = 'DIRECTION_INBOUND',
  DIRECTION_OUTBOUND = 'DIRECTION_OUTBOUND',
}

export enum CallStatusEnum {
  CALL_STATUS_COMPLETE = 'CALL_STATUS_COMPLETE',
  CALL_STATUS_ERROR = 'CALL_STATUS_ERROR',
  CALL_STATUS_FAILED = 'CALL_STATUS_FAILED',
  CALL_STATUS_INFERENCING = 'CALL_STATUS_INFERENCING',
  CALL_STATUS_PENDING = 'CALL_STATUS_PENDING',
  CALL_STATUS_SKIPPED = 'CALL_STATUS_SKIPPED',
  CALL_STATUS_TRANSCRIBING = 'CALL_STATUS_TRANSCRIBING',
}

export enum CategoryEnum {
  CATEGORY_UNKNOWN = 'CATEGORY_UNKNOWN',
  CATEGORY_SCHEDULING = 'CATEGORY_SCHEDULING',
  CATEGORY_EMERGENCY = 'CATEGORY_EMERGENCY',
  CATEGORY_MEDICATION = 'CATEGORY_MEDICATION',
  CATEGORY_COMPLAINT = 'CATEGORY_COMPLAINT',
  CATEGORY_BILLING = 'CATEGORY_BILLING',
  CATEGORY_VENDOR = 'CATEGORY_VENDOR',
  CATEGORY_OTHER = 'CATEGORY_OTHER',
}

export enum ContactTypeEnum {
  CONTACT_NEW_PATIENT = 'CONTACT_NEW_PATIENT',
  CONTACT_EXISTING_PATIENT = 'CONTACT_EXISTING_PATIENT',
  CONTACT_NOT_A_PATIENT = 'CONTACT_NOT_A_PATIENT',
  CONTACT_UNKNOWN = 'CONTACT_UNKNOWN',
}

export enum FilterTypeEnum {
  FILTER_BY_UNKNOWN = 0,
  FILTER_BY_DATE_RANGE = 1,
  FILTER_BY_LOCATION = 2,
  FILTER_BY_SENTIMENT = 3,
  FILTER_BY_CATEGORY = 4,
  FILTER_BY_APPOINTMENT_TYPE = 5,
  FILTER_BY_CALL_STATUS = 6,
  FILTER_BY_SCHEDULING_OUTCOME = 7,
  FILTER_BY_OPPORTUNITY = 8,
  FILTER_BY_OFFICE_USER = 9,
  FILTER_BY_OFFICE_NUMBER = 10,
  FILTER_BY_CALL_DIRECTION = 11,
  FILTER_BY_CONTACT_TYPE = 12,
  FILTER_BY_CALL_ID = 13,
  FILTER_BY_SERVICE_QUALITY_FLAG = 14,
}

export enum FollowupTaskFilterEnum {
  FILTER_BY_DATE_RANGE = 1,
  FILTER_BY_LOCATION = 2,
  FILTER_BY_OFFICE_USER = 3,
  FILTER_BY_CONTACT_TYPE = 4,
  FILTER_BY_TASK_TYPE = 5,
  FILTER_BY_TASK_STATUS = 6,
}

export enum FollowupSortFieldEnum {
  SORT_UNKNOWN = 0,
  SORT_LAST_CALL = 1,
  SORT_CALLS_COUNT = 2,
}

export enum SchedulingOpportunityEnum {
  SCHEDULING_OPPORTUNITY_IDENTIFIED = 'SCHEDULING_OPPORTUNITY_IDENTIFIED',
  SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED = 'SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED',
}

export enum SchedulingOutcomeEnum {
  SCHEDULING_OUTCOME_UNKNOWN = 'SCHEDULING_OUTCOME_UNKNOWN',
  SCHEDULING_OUTCOME_SCHEDULED = 'SCHEDULING_OUTCOME_SCHEDULED',
  SCHEDULING_OUTCOME_UNSCHEDULED = 'SCHEDULING_OUTCOME_UNSCHEDULED',
}

export enum SearchTypeEnum {
  SEARCH_UNKNOWN = 0,
  SEARCH_BY_NAME = 1,
  SEARCH_BY_NUMBER = 2,
}

export enum SentimentEnum {
  SENTIMENT_UNKNOWN = 'SENTIMENT_UNKNOWN',
  SENTIMENT_NEGATIVE = 'SENTIMENT_NEGATIVE',
  SENTIMENT_NEUTRAL = 'SENTIMENT_NEUTRAL',
  SENTIMENT_POSITIVE = 'SENTIMENT_POSITIVE',
}

export enum ServiceQualityFlagEnum {
  FLAG_EXCELLENT_RESOLUTION = 'FLAG_EXCELLENT_RESOLUTION',
  FLAG_UNRESOLVED_ISSUE = 'FLAG_UNRESOLVED_ISSUE',
  FLAG_UNKNOWN = 'FLAG_UNKNOWN',
}

export enum SortTypeEnum {
  SORT_ASC = 1,
  SORT_DESC = 2,
}

export enum TaskFilterTypeEnum {
  FILTER_BY_UNKNOWN = 0,
  FILTER_BY_TASK_ID = 1,
  FILTER_BY_LOCATION = 2,
  FILTER_BY_SOURCE = 3,
  FILTER_BY_STATUS = 4,
  FILTER_BY_ASSIGNEE = 5,
  FILTER_BY_SOURCE_ID = 6,
}

export enum TaskStatusEnum {
  STATUS_UNKNOWN = 'STATUS_UNKNOWN',
  STATUS_TODO = 'STATUS_TODO',
  STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS',
  STATUS_COMPLETED = 'STATUS_COMPLETED',
  STATUS_DELETED = 'STATUS_DELETED',
}

export enum TaskTypeEnum {
  TYPE_SCHEDULING = 'TYPE_SCHEDULING',
  TYPE_WAITLIST = 'TYPE_WAITLIST',
  TYPE_PATIENT_CARE = 'TYPE_PATIENT_CARE',
  TYPE_INSURANCE = 'TYPE_INSURANCE',
  TYPE_BILLING = 'TYPE_BILLING',
  TYPE_OTHER = 'TYPE_OTHER',
  TYPE_UNKNOWN = 'TYPE_UNKNOWN',
}

//Types

export type AggregateChart = {
  aggregates: HourlyAggregatesByDay[];
};

export type ApiPayloadProps = {
  drillDownOptions?: DrillDownOptions;
  filters: Filters;
};

export type ApiSearchPayloadProps = {
  search_type: SearchTypeEnum;
  search: string;
};

export type ApiOverviewStatsParams = {
  filter: Filters;
  includes: StatsIncludes;
};

type AppointmentTypeInfereces = {
  inferences: Partial<Record<AppointmentTypeEnum, number>>;
};

export type Call = {
  appointmentTypes?: AppointmentTypeEnum[];
  categories?: CategoryEnum[];
  contactType?: ContactTypeEnum;
  direction?: CallDirectionEnum;
  id: string;
  isFieldEdited?: IsFieldEdited;
  locationId: string;
  mosScore?: number;
  officeNumber?: OfficeNumber | null;
  officeUser?: OfficeUser | null;
  person?: Person;
  phoneNumber?: PhoneNumber;
  schedulingOpportunity?: boolean;
  schedulingOutcome?: boolean;
  sentiment?: SentimentEnum;
  serviceQualityFlags?: ServiceQualityFlagEnum[];
  startTime?: string;
  status?: CallStatusEnum;
  taskCount: number;
  taskTypes: TaskTypeEnum[];
};

export type CallIntelTab = 'overview' | 'serviceQuality' | 'records';

export type CallMetadata = Omit<CallMetadataResponse, 'callMetadata'> & {
  callParticipantsMetadata: CallParticipantsMetadata;
  serviceQualityMetadata: ServiceQualityMetadata;
  tasks: Task[];
};

export type CallMetadataResponse = {
  rating?: keyof typeof Rating;
  summary: string;
  transcript: string;
  callMetadata: CallParticipantsMetadata;
};

export type CallsStats = {
  count?: number;
};

export type CallParticipantsMetadata = { caller: string; receptionist: string };

export type CallsResponse = {
  calls?: Call[];
  hasNextPage?: boolean;
  pageConfig: AnalyticsCommonTypes.PageConfig;
};

export type CallTaskListResponse = {
  tasks: Task[];
};

export type CallTaskParams = {
  locations?: string[];
  source?: TaskSource;
  callIds?: string[];
  includeMetaData?: boolean;
  sortType?: SortTypeEnum;
};

export type CallTakeawayAccordion =
  | 'call-tasks'
  | 'call-summary'
  | 'call-analysis'
  | 'call-details'
  | 'service-quality';

type CategoryInfereces = {
  inferences: Partial<Record<CategoryEnum, number>>;
};

export type ChangeType = 'decreased' | 'increased' | 'noData' | 'unchanged';

export type ContactTypeFilterEnum = Omit<ContactTypeEnum, ContactTypeEnum.CONTACT_UNKNOWN>;

export type DrillDownOptions = {
  index: FilterTypeEnum;
  key: string;
  value: string[];
};

export type DateInterval = {
  startDate: string;
  endDate: string;
};

export type FilterPayload = {
  filter: {
    [key in FilterTypeEnum]?: {
      [subKey: string]: any;
    };
  };
  limit?: number;
  offset?: number;
  sort_type?: SortTypeEnum;
};

export type Filters = {
  appointmentTypes?: string[];
  callDirection?: CallDirectionEnum;
  contactTypes?: ContactTypeFilterEnum[];
  categories?: string[];
  endDate?: string;
  hideNonAnalyzedCalls?: boolean;
  locations?: string[];
  officeNumbers?: string[];
  officeUsers?: string[];
  schedulingOpportunity?: string;
  schedulingOutcomes?: SchedulingOutcomeEnum[];
  sentiments?: string[];
  serviceQualityFlag?: ServiceQualityFlagEnum[];
  startDate?: string;
};

export type FollowUp = {
  calls: Call[];
  totalTasks: number;
  totalCalls: number;
};

export type FollowUpItem = {
  allCalls: Call[];
  contact: Call & { contactId: string };
  callTaskMapping: Record<string, Task[]>;
  totalCalls: number;
  totalTasks: number;
};

export type FollowUpResponse = {
  followUps: FollowUp[];
  hasNextPage?: boolean;
  pageConfig: AnalyticsCommonTypes.PageConfig;
  totalFollowUps: number;
};

export type FollowUpStatsResponse = {
  stats: TaskStats;
};

export type FollowupTaskTypeFilters = {
  startDate?: string;
  endDate?: string;
  locations?: string[];
  officeUsers?: string[];
  contactTypes?: ContactTypeFilterEnum[];
  taskTypes?: TaskTypeEnum[];
  taskStatus?: TaskStatusEnum[];
};

export type GetCalls = {
  drillDownOptions?: DrillDownOptions;
  filters: Filters;
  pageConfig: AnalyticsCommonTypes.PageConfig;
  showOnlyUnscheduled?: boolean;
  searchQuery?: string;
  sortType?: SortTypeEnum;
};

export type GetFollowUpsParams = {
  filters: FollowupTaskTypeFilters;
  sortBy: FollowupSortFieldEnum;
  sortType?: SortTypeEnum;
  searchQuery?: string;
  pageConfig: AnalyticsCommonTypes.PageConfig;
};

export type GetFollowUpsStatsParams = {
  filters: FollowupTaskTypeFilters;
};

export type HighlightedCitation = {
  text: string;
  time: string;
};

export type HourlyAggregatesByDay = {
  date: string; // Format: 'yyyy-mm-dd'
  values: string[]; // Array of values, representing 24 hourly values
};

export type InsightChartType = 'bar' | 'gauge';
export type InsightType = 'scheduled' | 'unresolved';

export type IsFieldEdited = {
  isAppointmentTypeEdited: boolean;
  isCategoryEdited: boolean;
  isContactTypeEdited: boolean;
  isSchedulingOpportunityEdited: boolean;
  isSchedulingOutcomeEdited: boolean;
  isSentimentEdited: boolean;
  isServiceQualityFlagEdited: boolean;
};

type Location = {
  id: string;
  name: string;
  slug: string;
};

export type Locations = {
  locations: Location[];
};

export type LocationStat<T> = {
  [locationId: string]: T;
};

export type LocationsStats = {
  appointmentTypesByLocation: LocationStat<AppointmentTypeInfereces>;
  categoriesByLocation: LocationStat<CategoryInfereces>;
  opportunitiesByLocation: LocationStat<OpportunitiesByLocation>;
  sentimentsByLocation: LocationStat<SentimentInfereces>;
  taskTypesByLocation: LocationStat<TaskTypeInfereces>;
};

export type OpportunitiesByLocation = {
  totalOpportunities: number;
  unscheduledOpportunities: number;
};

export type Overview = {
  appointmentTypes: Partial<Record<AppointmentTypeEnum, number>> | null;
  categories: Partial<Record<CategoryEnum, number>> | null;
  sentiments: Partial<Record<SentimentEnum, number>> | null;
  summary: Summary;
  serviceQualitySummary: ServiceQualitySummary;
  taskTypes: Partial<Record<TaskTypeEnum, number>> | null;
  totalCallsAnalyzed: number;
};

export type PatientProvidedInfo = {
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  ssn?: string;
  dob?: string;
  faxNumber?: string;
  dependentName?: string[];
  dependentPhone?: string[];
  dependentEmail?: string[];
  dependentDob?: string[];
  dependentSsn?: string[];
  insuranceProvider?: string;
  insurancePhoneNumber?: string;
  insuranceFax?: string;
  insuranceEmail?: string;
  insuranceMemberId?: string[];
};

export type Person = {
  firstName?: string;
  id: string;
  lastName?: string;
  preferredName?: string;
};

export type PhoneNumber = {
  countryCode?: number;
  nationalNumber?: string;
};

type SentimentInfereces = {
  inferences: Partial<Record<SentimentEnum, number>>;
};

export type SearchState = { query: string; value: string };

export type ServiceQualityFlag = Exclude<ServiceQualityFlagEnum, ServiceQualityFlagEnum.FLAG_UNKNOWN>;

export type ServiceQualityFlagData = {
  summary: string;
  tsStart: string;
};

export type ServiceQualityFlagTimeMapping = {
  [time: string]: { type: ServiceQualityFlagEnum };
};

export type ServiceQualityMetadata = {
  flagData: ServiceQualityResponse;
  flagTimeMapping: ServiceQualityFlagTimeMapping;
};

export type ServiceQualityCallVolumeResponse = {
  callsAnalyzed: AggregateChart;
  excellentService: AggregateChart;
  unresolved: AggregateChart;
};

export type ServiceQualityResponse = {
  issues: ServiceQualityFlagData[];
  resolution: ServiceQualityFlagData[];
};

export type StatsIncludes = {
  opportunities?: boolean;
  serviceQuality?: boolean;
};

type Summary = {
  isPartial: boolean;
  scheduledOpportunities?: number;
  totalOpportunities?: number;
};

export type ServiceQualitySummary = {
  callsToReview: number;
  callsResolved: number;
  isPartial: boolean;
};

export type OverviewStatsResponse = {
  serviceQualitySummary: ServiceQualitySummary;
  summary: Summary;
  totalCallsAnalyzed: number;
};

export type DemoServiceQualityChartStatsResponse = {
  stats: OverviewStatsResponse;
  callVolume: ServiceQualityCallVolumeResponse;
};

export type Task = {
  assignee: string;
  citation: string;
  citationTime?: string;
  description: string;
  id: string;
  locationId: string;
  metadata: string;
  sourceId: string;
  status: TaskStatusEnum;
  title: string;
  type: TaskTypeEnum;
};

export type TaskStats = {
  [key in TaskStatusEnum]?: number;
};

export enum TaskSource {
  'CALL_INTELLIGENCE' = 'SOURCE_CALL_INTELLIGENCE',
}

export type TaskTypeInfereces = {
  inferences: Partial<Record<TaskTypeEnum, number>>;
};

export type TaskUpdateRequest = {
  id: string;
  status: TaskStatusEnum;
};

export type TranscriptTimeDataMapping = {
  [time: string]: { popoverLabel: string; hightlightColor: string };
};

type OfficeNumber = {
  countryCode: number;
  nationalNumber: string;
};

export type OfficeUser = {
  firstName?: string;
  lastName?: string;
  status?: string;
  userId?: string;
  userName?: string;
};

/**Intentionally added this at last, TBR in coming PRs */

export enum AnalysisTypeEnum {
  ANALYSIS_TYPE_CONTACT_TYPE = 'ANALYSIS_TYPE_CONTACT_TYPE',
  ANALYSIS_TYPE_SENTIMENT = 'ANALYSIS_TYPE_SENTIMENT',
  ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY = 'ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY',
  ANALYSIS_TYPE_SCHEDULING_OUTCOME = 'ANALYSIS_TYPE_SCHEDULING_OUTCOME',
  ANALYSIS_TYPE_CATEGORY = 'ANALYSIS_TYPE_CATEGORY',
  ANALYSIS_TYPE_APPOINTMENT_TYPE = 'ANALYSIS_TYPE_APPOINTMENT_TYPE',
  ANALYSIS_TYPE_SERVICE_QUALITY = 'ANALYSIS_TYPE_SERVICE_QUALITY',
}

export type GetCallEditHistoryResponse = {
  original: CallEditableFields;
  current: CallEditedField[];
};

export type ServiceQualityFlagState = {
  serviceQualityFlag: ServiceQualityFlagEnum;
  isDisabled: boolean;
};

export type CallEditableFields = {
  contactType?: ContactTypeEnum;
  sentiment?: SentimentEnum;
  schedulingOutcome?: boolean;
  schedulingOpportunity?: boolean;
  categories?: CategoryEnum[];
  appointmentTypes?: null | { appointmentType: AppointmentTypeEnum[] };
  serviceQualityFlags?: ServiceQualityFlagState[];
};

export type CallEditedField = {
  fields: CallEditableFields;
  analysisType: AnalysisTypeEnum;
  editedBy: OfficeUser;
  editedAt: string;
};

export type EditAIOutputRequest = {
  callId: string;
  location_id: string;
  user_id: string;
  current_fields: CallEditableFields;
  edited_fields: CallEditableFields;
  type?: TransformEditableFieldType;
};

export type CreateSuggestionRequest = {
  callId: string;
  location_id: string;
  user_id: string;
  suggestion: string;
  vertical?: string;
  type: AnalysisTypeEnum;
};

export type TransformEditableFieldType = 'edit' | 'restore';
