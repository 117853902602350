import { useCallback, useEffect, useMemo, useState } from 'react';
import { saveAs } from 'file-saver';
import { unparse } from 'papaparse';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { processExportableData } from '@frontend/file-download-helper';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Chip, Table, TableColumnConfig, TableLoadingSkeleton, useAlert } from '@frontend/design-system';
import { useAnalyticsOrgLocations, useShowBulkMessageButton } from '../../hooks';
import { createAllSelectTableStateObject, formatters } from '../../utils';
import { ColumnHeaderInfoTip } from '../column-header-info-tip';
import { InfoTipPopover } from '../info-tip-popover';
import { LocationChip } from '../location-chip';
import { TableActions } from '../table-actions';
import { TableCustomToolbarActions } from '../table-custom-toolbar-actions';
import { UserCard } from '../user-card';
import { BulkMessageButton } from './bulk-message-button';
import { PatientsHelpers, isPatientScheduled } from './helpers';
import { useGetOpportunityListDetails, usePatientsLastContacted, usePracticeAnalyticsShallowStore } from './hooks';
import { OpportunityCards, UnscheduledOpportunities } from './opportunity-cards';
import { PAOpportunityFilters } from './pa-opportunity-filters';
import { ProceduresColumn } from './procedures-column';

interface UnscheduledPatients {
  allPatients: PracticeAnalyticsTypes.PatientInfo[];
  opportunities: UnscheduledOpportunities;
}

export const OpportunitiesListTabPanel = () => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });
  const [isExporting, setIsExporting] = useState<boolean>(false);
  const [selectedPatients, setSelectedPatients] = useState<PracticeAnalyticsTypes.PatientInfo[]>([]);
  const [selectedOpportunities, setSelectedOpportunities] = useState<(keyof UnscheduledOpportunities)[] | null>([
    'hygieneTreatment',
  ]);
  const { data, isLoading } = useGetOpportunityListDetails({ disabled: isDemoAccount, filters });
  const isBulkMessagingEnabled = useShowBulkMessageButton();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  const unscheduledPatients: UnscheduledPatients = useMemo(() => {
    const aggregatedData = PracticeAnalyticsAggregations.opportunityList(
      isDemoAccount ? demoData?.opportunityList : data
    );

    if (!aggregatedData) {
      return {
        allPatients: [],
        opportunities: {
          cancelledPatients: [],
          hygieneFollowUp: [],
          hygieneTreatment: [],
          newPatients: [],
          restorativeTreatment: [],
        },
      };
    }

    const {
      hygieneReappointment: hygieneFollowUp,
      hygieneTreatmentPlan,
      missedAppointments: cancelledPatients,
      newPatients,
      restorativeTreatmentPlan,
    } = aggregatedData;

    const unscheduledHygieneFollowUpPatients = hygieneFollowUp.details.patients.filter(
      ({ nextHygieneAppointmentDate }) => !nextHygieneAppointmentDate
    );

    const unscheduledHygieneTreatmentPatients = hygieneTreatmentPlan.details.patients.filter(
      (patient) => !isPatientScheduled(patient)
    );

    const unscheduledNewPatients = newPatients.details.patients.filter(
      ({ nextAppointmentDate }) => !nextAppointmentDate
    );

    const unscheduledRestorativeTreatmentPatients = restorativeTreatmentPlan.details.patients.filter(
      (patient) => !isPatientScheduled(patient)
    );

    return {
      allPatients: [
        ...cancelledPatients.details.patients,
        ...unscheduledHygieneFollowUpPatients,
        ...unscheduledHygieneTreatmentPatients,
        ...unscheduledNewPatients,
        ...unscheduledRestorativeTreatmentPatients,
      ],
      opportunities: {
        cancelledPatients: cancelledPatients.details.patients,
        hygieneFollowUp: unscheduledHygieneFollowUpPatients,
        hygieneTreatment: unscheduledHygieneTreatmentPatients,
        newPatients: unscheduledNewPatients,
        restorativeTreatment: unscheduledRestorativeTreatmentPatients,
      },
    };
  }, [data?.data, isDemoAccount, demoData?.opportunityList]);

  const selectedCategorizedOpportunities = useMemo(() => {
    return selectedOpportunities?.reduce<PracticeAnalyticsTypes.PatientInfo[]>((acc, opportunity) => {
      return [...acc, ...unscheduledPatients.opportunities[opportunity]];
    }, []);
  }, [selectedOpportunities, unscheduledPatients.opportunities]);

  const patientIds = useMemo(() => {
    return PatientsHelpers.collectPatientIds(unscheduledPatients.allPatients);
  }, [unscheduledPatients.allPatients]);

  const { lastContactedDates, isLoading: isLoadingLastContacted } = usePatientsLastContacted({
    patientIds,
  });

  const handleSelectedOpportunityChange = useCallback(
    (opportunity: keyof UnscheduledOpportunities) => {
      setSelectedOpportunities((prev) => {
        if (prev?.includes(opportunity)) {
          return prev.filter((item) => item !== opportunity);
        }

        return [...(prev || []), opportunity];
      });
    },
    [selectedOpportunities]
  );

  const handleExport = async () => {
    const fileName = `Opportunities List - ${selectedOpportunities?.join('-')}`; // Translation not needed

    setIsExporting(true);
    const isAuthorized = await PracticeAnalyticsApi.auditPracticeAnalyticsExport(fileName);

    if (isAuthorized || isDemoAccount) {
      // When isDemoAccount is true, mock data will be exported
      const csv = unparse(
        processExportableData({
          columns: [
            'Patient Name',
            ...(multipleLocationsSelected ? ['Location Name'] : []),
            'Phone Number',
            'Email',
            'Last Contacted',
            '$ Value',
            'Procedure Codes',
            'Classification',
          ],
          data: selectedCategorizedOpportunities ?? [],
          deriveExportValue: (params) =>
            PatientsHelpers.deriveExportValue({
              ...params,
              lastContactedDates,
              locationNames,
            }),
          sortColumn: 'productionAmount',
        }),
        {}
      );
      saveAs(new Blob([csv], { type: 'text/csv;charset=utf-8' }), fileName + '.csv');
      setIsExporting(false);
    } else {
      alert.warning(t('You are not authorized to export data. This attempt has been logged.'));
      setIsExporting(false);
    }
  };

  const callConfig: TableColumnConfig<PracticeAnalyticsTypes.PatientInfo>[] = [
    {
      // Converting it to string so table's inbuilt string sorting can work properly
      accessor: ({ FirstName, id, LastName, locationId }) => JSON.stringify({ FirstName, LastName, id, locationId }),
      cellRenderer: (value) => {
        const { FirstName, LastName, id, locationId } = JSON.parse(value);
        return (
          <UserCard
            firstName={FirstName}
            key={id}
            lastName={LastName}
            locationId={locationId}
            openProfileOnClick={!isDemoAccount}
            showOnlyName
            userId={id}
          />
        );
      },
      disableColumnFilter: true,
      Header: t('Patient Name'),
      id: 'patient',
      sticky: 'left',
      width: 200,
    },
    {
      accessor: ({ locationId }) => locationId,
      cellRenderer: (value: string) => {
        return value ? <LocationChip locationName={locationNames[value] || value} maxWidth={180} /> : '-';
      },
      disableSortBy: true,
      Header: t('Location'),
      id: 'locationName',
      omit: !multipleLocationsSelected,
      width: 220,
    },
    {
      accessor: ({ HomePhone, MobilePhone, WorkPhone }) => MobilePhone || HomePhone || WorkPhone,
      cellRenderer: (value) => (value ? formatPhoneNumber(value) : '-'),
      disableSortBy: true,
      Header: t('Phone'),
      id: 'phone',
      width: 140,
    },
    {
      Header: (
        <ColumnHeaderInfoTip
          columnTitle={t('Last Contacted')}
          infoTip={
            <InfoTipPopover>{t('Date and Time of last outbound answered call to patient phone number')}</InfoTipPopover>
          }
        />
      ),
      headerLabel: t('Last Contacted'),
      accessor: ({ id }: PracticeAnalyticsTypes.PatientInfo) => lastContactedDates?.[id],
      cellRenderer: (lastContacted: string) =>
        isLoadingLastContacted ? <TableLoadingSkeleton /> : lastContacted ? formatters.date.format(lastContacted) : '-',
      id: 'lastContacted',
      width: 180,
    },
    {
      accessor: ({ productionAmount }) => productionAmount,
      cellRenderer: (value) => (value ? formatters.currency.format(value) : '-'),
      Header: t('$ Value'),
      id: 'productionAmount',
      width: 100,
      startingSortBy: 'desc',
    },
    {
      Header: t('Procedure Codes'),
      headerLabel: t('Procedure Codes'),
      accessor: ({ procedures }: PracticeAnalyticsTypes.PatientInfo) => procedures,
      cellRenderer: (procedures: PracticeAnalyticsTypes.PatientInfo['procedures']) => (
        <ProceduresColumn procedures={procedures} />
      ),
      id: 'procedures',
      width: 180,
    },
    {
      accessor: ({ classification }) => classification,
      cellRenderer: (value) => {
        switch (value) {
          case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.CANCELLED_PATIENTS:
            return (
              <Chip style={{ maxWidth: 'none' }} variant='critical'>
                {t('Cancelled')}
              </Chip>
            );

          case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_FOLLOW_UP:
            return (
              <Chip style={{ maxWidth: 'none' }} variant='primary'>
                {t('Hygiene Follow Up')}
              </Chip>
            );

          case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_TREATMENT_PLAN:
            return (
              <Chip style={{ maxWidth: 'none' }} variant='seaweed'>
                {t('Hygiene Treatment Plan')}
              </Chip>
            );

          case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.NEW_PATIENTS:
            return (
              <Chip style={{ maxWidth: 'none' }} variant='tangerine'>
                {t('New Patient')}
              </Chip>
            );

          case PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.RESTORATIVE_TREATMENT_PLAN:
            return (
              <Chip style={{ maxWidth: 'none' }} variant='eggplant'>
                {t('Restorative Treatment Plan')}
              </Chip>
            );

          default:
            return '-';
        }
      },
      Header: t('Classification'),
      id: 'classification',
      width: 220,
    },
    {
      accessor: (patient) => patient,
      cellRenderer: (patient: PracticeAnalyticsTypes.PatientInfo) => {
        const phoneNumber = patient.MobilePhone || patient.HomePhone || patient.WorkPhone;
        return (
          <TableActions
            isDemoAccount={isDemoAccount}
            personId={patient.id}
            personName={patient.FirstName}
            phoneNumber={phoneNumber}
            trackingIdBase='pa-opportunity-list'
          />
        );
      },
      disableSortBy: true,
      Header: t('Actions'),
      headerAlign: 'right',
      id: 'actions',
      sticky: 'right',
      width: 128,
    },
  ];

  useEffect(() => {
    if (isBulkMessagingEnabled) {
      setSelectedPatients(selectedCategorizedOpportunities ?? []);
    }
  }, [isBulkMessagingEnabled, selectedCategorizedOpportunities]);

  return (
    <>
      <PAOpportunityFilters isLoadingData={isLoading || isExporting}>
        <TableCustomToolbarActions<PracticeAnalyticsTypes.PatientInfo>
          isExportReady={!isLoading && !isLoadingLastContacted}
          isLoadingData={isLoading || isExporting}
          onExportClick={handleExport}
          trackingIdBase='opportunity-list'
        />
      </PAOpportunityFilters>
      <OpportunityCards
        onChange={handleSelectedOpportunityChange}
        selectedOpportunities={selectedOpportunities}
        unscheduledOpportunities={unscheduledPatients.opportunities}
      />
      <Table
        clientPaginationConfig={{
          defaultRowsPerPage: 25,
          rowsPerPageOptions: [10, 25, 50, 100],
        }}
        colConfig={callConfig}
        customToolbarRender={() => (
          <BulkMessageButton
            isLoadingData={isLoading || isExporting}
            selection={selectedPatients}
            trackingIdBase='opportunity-list'
          />
        )}
        data={selectedCategorizedOpportunities ?? []}
        disableMultiSort
        emptyStateConfig={{
          type: 'sync_your_phone',
        }}
        fullHeight
        fullHeightConfig={{
          offset: isDemoAccount ? (isBulkMessagingEnabled ? 682 : 622) : isBulkMessagingEnabled ? 572 : 512,
        }}
        globalTrackingId='pa-opportunities-records'
        hasResizeColumns
        isLoading={isLoading}
        isPaginated
        isSelectable={isBulkMessagingEnabled}
        rowSelectionConfig={{
          initialState:
            isBulkMessagingEnabled && selectedCategorizedOpportunities
              ? createAllSelectTableStateObject(selectedCategorizedOpportunities.length)
              : {},
          onSelectionToggle: (selectedRows) => {
            setSelectedPatients(selectedRows.map(({ original }) => original));
          },
        }}
        tableInstanceId='pa-opportunities-records'
      />
    </>
  );
};
