import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { createShallowStore, createStore } from '@frontend/store';

type AppointmentEventCardStoreType = {
  isIntegratedOffice: boolean;
  appointmentDetails: Appointment | null;
  setIsIntegratedOffice: (isIntegratedOffice: boolean) => void;
  setAppointmentDetails: (appointmentDetails: Appointment) => void;
  resetAppointmentDetails: () => void;
  resetIsIntegratedOffice: () => void;
  closeAppointmentDetailsModal?: () => void;
  setCloseAppointmentDetailsModal: (closeAppointmentDetailsModal: () => void) => void;
};

const useAppointmentEventCardStore = createStore<AppointmentEventCardStoreType>(
  (set) => ({
    appointmentDetails: null,
    isIntegratedOffice: false,
    setIsIntegratedOffice: (isIntegratedOffice: boolean) => set({ isIntegratedOffice }),
    setAppointmentDetails: (appointmentDetails: Appointment) => set({ appointmentDetails }),
    resetAppointmentDetails: () => set({ appointmentDetails: null }),
    resetIsIntegratedOffice: () => set({ isIntegratedOffice: false }),
    setCloseAppointmentDetailsModal: (closeAppointmentDetailsModal: () => void) =>
      set({ closeAppointmentDetailsModal }),
  }),
  {
    name: 'appointmentEventCard',
  }
);

export const useAppointmentEventCardShallowStore =
  createShallowStore<AppointmentEventCardStoreType>(useAppointmentEventCardStore);
