import { useQuery } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { GetPMSMedicalConditionsIO } from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

/**
 * @param companyId - AKA locationId
 * @param sourceTenantId
 */
export const useGetPMSMedicalConditions = <E = unknown, T = GetPMSMedicalConditionsIO['output']>(
  req: Compulsory<GetPMSMedicalConditionsIO['input'], 'sourceTenantId' | 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetPMSMedicalConditionsIO, E, T>
) => {
  const queryKey = requestKeys.medicalConditions(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetPMSMedicalConditions(req),
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};
