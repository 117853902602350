export function calculateAnimation(index: number, direction: 'next' | 'prev', itemsLength: number) {
  const zIndex = itemsLength - index;
  const opacity = 1 - index * 0.4;
  const scale = 1 - index * 0.15;
  const translateX = index * 150;

  return {
    zIndex,
    initial: {
      opacity: 0,
      translateX: direction === 'next' ? 100 : -100,
      scale: 0,
    },
    animate: {
      opacity,
      translateX,
      scale,
    },
    exit: {
      opacity: 0,
      scale: 0,
      translateX: direction === 'next' ? -100 : 100,
    },
    whileHover: {
      translateX: index === 0 ? translateX : translateX + 10,
    },
  };
}
