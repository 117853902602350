import { useState } from 'react';
import { css } from '@emotion/react';
import { DeviceType_Enum, LineKey, ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { DevicesQueries } from '@frontend/api-devices';
import { LineKeysApi, LineKeysTypes } from '@frontend/api-line-keys';
import { useQuery } from '@frontend/react-query-helpers';
import { useNavSize } from '@frontend/responsiveness';
import { useShell } from '@frontend/shell-utils';
import { genUUIDV4 } from '@frontend/string';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { LineKeyCards } from '../../../components/devices/line-keys/cards-layout/cards';
import { LineKeyFooter } from '../../../components/devices/line-keys/footer';
import { Layout } from '../../../components/devices/line-keys/preview-layout';
import { LineKeyContext, LineKeyProvider } from '../../../components/devices/line-keys/store/line-key-provider';
import { isLocalContactExtension } from '../../../components/devices/line-keys/utils';
import { queryKeys } from '../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../store/settings';

export const LineKeyWrapper = ({ deviceId }: { deviceId: string }) => {
  const { selectedSettingsLocationIds: locationIds, settingsTenantLocation } = usePhoneSettingsShallowStore(
    'selectedSettingsLocationIds',
    'settingsTenantLocation'
  );
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';

  const { data: lineKeyOptions } = useQuery({
    queryKey: [tenantId, 'line-keys', 'mapped-line-key-options'],
    queryFn: () =>
      LineKeysApi.GetLineKeyOptions({
        tenantId,
      }),
    select: ({ localContacts, officeExtensions, userExtensions, voicemailExtensions }) => {
      const allExtensions: LineKeysTypes.ExtensionsUnionType[] = [
        ...userExtensions,
        ...voicemailExtensions,
        ...localContacts,
        ...officeExtensions,
      ];
      const mappedExtensions = allExtensions.reduce<Record<string, LineKeysTypes.ExtensionsUnionType>>((acc, sum) => {
        const id = isLocalContactExtension(sum) ? sum.contactId : sum.extensionId;
        return {
          ...acc,
          [id]: sum,
        };
      }, {});

      return mappedExtensions;
    },
  });

  const { data: lineKeys, isLoading: isLoadingLineKeys } = useQuery({
    queryKey: [tenantId, deviceId, ...queryKeys.settings.listLineKeys()],
    queryFn: () => LineKeysApi.GetLineKeys(deviceId),
    select: (lineKeys) => {
      const obj: Record<string, LineKey> = {};
      lineKeys?.lineKeys.forEach((item) => {
        const id = genUUIDV4();
        obj[id] = item;
      });
      return obj;
    },
  });

  const { data: device, isLoading: isLoadingDevice } = DevicesQueries.useGetDevicesList(
    {
      locationIds,
      deviceType: DeviceType_Enum.DESK_PHONE,
      tenantId: settingsTenantLocation?.phoneTenantId,
    },
    {
      select: ({ devices }) => {
        return devices.find((device) => device.deviceId === deviceId);
      },
    }
  );

  const { data: model, isLoading: isLoadingModel } = useQuery({
    queryKey: [tenantId, 'line-keys', 'device-models'],
    queryFn: () => LineKeysApi.GetDeviceModelInfo(),
    select: ({ models }) => {
      return models.find((model) => device?.model === model.model);
    },
  });

  const maxKeys = (model?.lineKeys?.maxKeys ?? 12) - (device?.extensions.length ?? 0);

  const isLoading = isLoadingModel || isLoadingDevice || isLoadingLineKeys;
  if (isLoading) return <ContentLoader show />;

  return (
    <LineKeyContext.Provider
      value={{
        lineKeyOptions,
        lineKeys,
        model,
        maxKeys,
        device,
      }}
    >
      <LineKeyProvider>
        <LineKeyContainer device={device} />
      </LineKeyProvider>
    </LineKeyContext.Provider>
  );
};

type Props = {
  device: ListDevice | undefined;
};

const LineKeyContainer = ({ device }: Props) => {
  const deviceId = device?.deviceId ?? '';
  const [showDeviceLayoutPreview, setShowDeviceLayoutPreview] = useState(false);
  const navSize = useNavSize();
  const isSmallerThanDesktop = navSize.isLt('large');
  const isMobile = navSize.isLte('medium');
  const shell = useShell();

  const shellHeightPx = isSmallerThanDesktop ? theme.spacing(15) : theme.spacing(18.5);
  const browserHeightPx = isSmallerThanDesktop ? theme.spacing(8) : theme.spacing(12);
  const heightPx = shell.isShell ? shellHeightPx : browserHeightPx;
  return (
    <div
      css={[
        lineKeyContainerStyles,
        css`
          height: calc(100vh - ${heightPx});
        `,
      ]}
    >
      <div css={styles({ isMobile, showDeviceLayoutPreview })}>
        {!showDeviceLayoutPreview && (
          <LineKeyCards setShowDeviceLayoutPreview={setShowDeviceLayoutPreview} deviceId={deviceId} />
        )}
        {(!isMobile || showDeviceLayoutPreview) && (
          <Layout previewOnly={isMobile} setShowDeviceLayoutPreview={setShowDeviceLayoutPreview} />
        )}
      </div>
      <LineKeyFooter />
    </div>
  );
};

const lineKeyContainerStyles = css`
  display: grid;
  grid-template-rows: 1fr auto;
`;

const styles = ({ isMobile, showDeviceLayoutPreview }: { isMobile: boolean; showDeviceLayoutPreview: boolean }) => css`
  display: grid;
  grid-template-columns: ${isMobile ? 'auto' : 'auto 1fr'};
  overflow: hidden;
  height: 100%;

  ${showDeviceLayoutPreview &&
  isMobile &&
  `
     padding: ${theme.spacing(2)} 
  `}
`;
