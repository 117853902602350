import { FloatingOverlay } from '@floating-ui/react';
import { motion } from 'motion/react';

export const MotionFloatingOverlay = motion(FloatingOverlay);

export const overlayAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};
