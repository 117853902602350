import { useTranslation } from '@frontend/i18n';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { ContactWithError, ReducerState } from '../hooks';
import { CommonModalWrapper } from './modal-wrapper';

type Props = ModalControlModalProps & {
  closeModal: () => void;
  state: ReducerState;
  onSave: (contacts: ContactWithError[]) => void;
};

export const CancelNewContactsModal = ({ closeModal, state, onSave, ...modalProps }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });

  const onExit = () => {
    closeModal();
    modalProps.onClose();
  };

  const contactsToSave = state.filter((contact) => contact.errors?.length === 0);

  return (
    <CommonModalWrapper {...modalProps}>
      <Modal.Header onClose={onExit}>{t('Quit Adding Contacts')}</Modal.Header>
      <Modal.Body>
        <Text>{t('Are you sure you want to quit adding new contacts?')}</Text>
      </Modal.Body>
      <Modal.Actions
        destructive
        primaryLabel={t('Quit Adding')}
        secondaryLabel={t('Continue Adding')}
        onSecondaryClick={modalProps.onClose}
        onPrimaryClick={onExit}
        backLabel={
          !!contactsToSave.length && t('Save {{ count }} Completed Contacts', { count: contactsToSave.length })
        }
        onBackClick={() => onSave(contactsToSave)}
      />
    </CommonModalWrapper>
  );
};
