import { useCallback } from 'react';
import { PanelPortal } from '@frontend/panel-engine';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { slidePanels } from './slide-panels';

export const ResponsiveSlidePanel = () => {
  const { show, panelType, setShow } = useSlidePanelShallowStore('show', 'mode', 'setShow', 'panelType');

  const onClose = useCallback(() => {
    slidePanels[panelType]?.cleanupFn?.();
    setShow(false);
  }, [panelType]);

  return (
    show && (
      <PanelPortal id='slide-panels' enableAnimations onClose={onClose} width={slidePanels[panelType]?.size}>
        {slidePanels[panelType]?.panel}
      </PanelPortal>
    )
  );
};
