import { ReactNode } from 'react';
import { NodeProps, Node } from '@xyflow/react';

export const NodeType = {
  Start: 'start',
  Basic: 'basic',
  Boolean: 'boolean',
  ForwardDevice: 'forwardDevice',
  ForwardNumber: 'forwardNumber',
  VoicemailBox: 'voicemailBox',
  VoicemailPrompt: 'voicemailPrompt',
  Terminate: 'terminate',
  CallGroup: 'callGroup',
  CallQueue: 'callQueue',
  CallRoute: 'callRoute',
  PhoneTree: 'phoneTree',
  Repeat: 'repeat',
  TreeOption: 'treeOption',
  PlayMessage: 'playMessage',
  OfficeHours: 'officeHours',
  OpenPhoneHours: 'openPhoneHours',
  ClosedPhoneHours: 'closedPhoneHours',
  BreakPhoneHours: 'breakPhoneHours',
  Fallback: 'fallback',
} as const;

export const EdgeType = {
  Basic: 'basic',
  ToTarget: 'toTarget',
  FromSource: 'fromSource',
  TreeOption: 'treeOption',
} as const;

export type NodeTypes =
  | typeof NodeType.Start
  | typeof NodeType.Basic
  | typeof NodeType.Terminate
  | typeof NodeType.VoicemailPrompt
  | typeof NodeType.CallGroup
  | typeof NodeType.CallRoute
  | typeof NodeType.PhoneTree
  | typeof NodeType.TreeOption
  | typeof NodeType.PlayMessage
  | typeof NodeType.Boolean
  | typeof NodeType.Repeat
  | typeof NodeType.OfficeHours
  | typeof NodeType.OpenPhoneHours
  | typeof NodeType.ClosedPhoneHours
  | typeof NodeType.BreakPhoneHours
  | typeof NodeType.ForwardDevice
  | typeof NodeType.ForwardNumber
  | typeof NodeType.VoicemailBox
  | typeof NodeType.CallQueue
  | typeof NodeType.Fallback;

// This is the type for the custom data added to the nodes passed to the graph. Most of
// the data is returned from the API.
export type DataPayload = {
  /** The ID of the node returned from the API */
  id: string;
  label: string;
  isPhoneTreeDescendent?: boolean;
  callObject?: {
    primitiveId: string;
    primitiveName: string;
    instructionId: string;
    instructionSetId: string;
    callGroupExpansion?: CallGroupExpansion;
    callQueueExpansion?: CallQueueExpansion;
    deviceExpansion?: DeviceExpansion;
    playMessageExpansion?: PlayMessageExpansion;
    phoneTreeExpansion?: PhoneTreeExpansion;
    voicemailExpansion?: VoicemailExpansion;
  };
};

type CallGroupExpansion = {
  callerLabel: string;
};
type CallQueueExpansion = {
  callerLabel: string;
};
type DeviceExpansion = {
  callerLabel: string;
};
type PlayMessageExpansion = {
  mediaFileName: string;
  mediaItemId: string;
};
type PhoneTreeExpansion = {
  maxFailures: number;
  maxTimeouts: number;
  promptMediaFileName: string;
  promptMediaItemId: string;
  timeout: number;
};
type VoicemailExpansion = {
  greetingMediaItemId: string; // deprecated
  greetingMediaFileName: string;
  downloadUrl: string;
};

// This type is used to define the props that are passed to the nodes in the graph. It is meant
// to be a shared type that can be used for all custom nodes.
export type SharedNodeProps = NodeProps<Node<DataPayload, NodeTypes>> & CustomProps;

export type CustomProps = {
  onClickLink?: (data: { callObjectId: string; type: NodeTypes }) => void;
  AudioPlayerComponent?: (props: { mediaItemId: string; downloadUrl?: string }) => ReactNode;
};

export type EdgeData = {
  id: string;
  source: string;
  target: string;
  label?: string;
  type?: typeof EdgeType.Basic | typeof EdgeType.ToTarget | typeof EdgeType.FromSource | typeof EdgeType.TreeOption;
  originalType?:
    | typeof EdgeType.Basic
    | typeof EdgeType.ToTarget
    | typeof EdgeType.FromSource
    | typeof EdgeType.TreeOption;
};
