import { useEffect, useState, createContext, useContext } from 'react';
import { css } from '@emotion/react';
import { ErrorBoundary } from '@sentry/react';
import { QueryClient } from 'react-query';
import { getUser } from '@frontend/auth-helpers';
import { defaultMissingModuleFallback, defaultModuleFetchErrorFallback } from '@frontend/file-based-routing';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { locationRedirect, LOCATION_REDIRECT_STORAGE_KEY } from '../../utils/location-redirect';
import { getWeaveRoutes } from '../../utils/routes/routes';
import {
  transFormAndExtractLocationIdFromPortalRoute,
  getNewRouteFromLegacyPortalRoute,
} from './legacy-portal-redirects';
import { getMurderThePortalRedirects } from './portal-redirects';

type ContextValue = {
  routes: ReturnType<typeof getWeaveRoutes>;
  isRedirecting: boolean;
  refresh: () => void;
};

const RoutesContext = createContext<ContextValue>({
  routes: [],
  isRedirecting: false,
  refresh: () => {},
});

export const useRoutes = () => useContext(RoutesContext);

type Props = {
  children: React.ReactNode;
  queryClient?: QueryClient;
};
export const RoutesProvider = ({ children, queryClient }: Props) => {
  const [isRedirecting, setIsRedirecting] = useState(false);
  const [routes, setRoutes] = useState<ReturnType<typeof getWeaveRoutes>>(() => {
    return getWeaveRoutes({
      queryClient,
      missingModuleFallback: defaultMissingModuleFallback,
      moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
    });
  });

  //this whole function is to help support the migration from the old portal to the new portal.
  //after a few months of the new portal being released, we should remove this
  const interceptLegacyRoutes = (path: string) => {
    const { locationId, transformedRoute } = transFormAndExtractLocationIdFromPortalRoute(path);
    if (locationId) {
      localStorage.setItem(LOCATION_REDIRECT_STORAGE_KEY, locationId);
    }

    // Set up location for when user has already been logged in (not going through sign in callback route)
    locationRedirect(getUser());

    const legacyRedirect = transformedRoute ? getNewRouteFromLegacyPortalRoute(transformedRoute) : null;
    const murderThePortalRedirect = getMurderThePortalRedirects(legacyRedirect ?? path);
    const finalRedirect = murderThePortalRedirect || legacyRedirect;

    if (finalRedirect) {
      const nextUrl = new URL(window.location.href);
      const finalRedirectUrl = new URL(window.location.origin + '/' + finalRedirect);
      nextUrl.pathname = finalRedirectUrl.pathname;
      if (finalRedirectUrl.hash) {
        nextUrl.hash = finalRedirectUrl.hash;
      }
      if (finalRedirectUrl.search) {
        nextUrl.search = finalRedirectUrl.search;
      }

      nextUrl.searchParams.delete('forwardPath');
      console.info(`Hit Legacy Path: ${path}. Navigating to ${nextUrl.href} to handle Legacy Path`, nextUrl);
      window.location.href = nextUrl.href;
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (!location.pathname) {
      return;
    }

    if (!routes) {
      return;
    }
    const intercepted = interceptLegacyRoutes(location.pathname);
    if (intercepted) {
      setIsRedirecting(true);
      return;
    }
  }, [location.pathname, routes]);

  const value: ContextValue = {
    routes,
    isRedirecting,
    refresh: () =>
      setRoutes(
        getWeaveRoutes({
          queryClient,
          missingModuleFallback: defaultMissingModuleFallback,
          moduleFetchErrorFallback: defaultModuleFetchErrorFallback,
        })
      ),
  };
  return (
    <RoutesContext.Provider value={value}>
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('topic', 'routeError');
          scope.setLevel('fatal');
        }}
        fallback={(props) => <RouteError {...props} />}
      >
        {children}
      </ErrorBoundary>
    </RoutesContext.Provider>
  );
};

const RouteError = ({ error }: { error: Error | unknown }) => {
  return (
    <main
      css={css`
        max-width: 100%;
        padding: ${theme.spacing(1)};
      `}
    >
      <Heading
        css={css`
          margin-bottom: ${theme.spacing(1)};
        `}
      >
        Error Loading Route
      </Heading>
      {error instanceof Error && (
        <>
          <Text>{error.message}</Text>
          <br />
          <Text
            css={css`
              padding: ${theme.spacing(1)};
              background: ${theme.colors.neutral10};
              border-radius: ${theme.borderRadius.small};
            `}
          >
            {error.stack}
          </Text>
        </>
      )}
    </main>
  );
};
