import { useState } from 'react';
import {
  useFloating,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useId,
  useFloatingNodeId,
  UseDismissProps,
  FloatingContext,
  ReferenceType,
  UseInteractionsReturn,
} from '@floating-ui/react';
import { drawerBackdropId } from './constants';

interface UseDrawerReturn {
  open: boolean;
  setOpen: (value: boolean) => void;
  labelId: string;
  descriptionId: string;
  triggerProps: {
    ref: ((node: ReferenceType | null) => void) & ((node: ReferenceType | null) => void);
  } & ReturnType<UseInteractionsReturn['getReferenceProps']>;
  modalProps: {
    context: FloatingContext<ReferenceType>;
    ref: (node: HTMLElement | null) => void;
    labelId: string;
    descriptionId: string;
    nodeId: string;
    isOpen: boolean;
    close: () => void;
  };
}

interface DrawerOptions {
  initialOpen?: boolean;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  outsidePress?: UseDismissProps['outsidePress'];
}

export function useDrawer({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange,
  outsidePress,
}: DrawerOptions = {}): UseDrawerReturn {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen);

  const labelId = useId();
  const descriptionId = useId();
  const nodeId = useFloatingNodeId();

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = onOpenChange ?? setUncontrolledOpen;

  const { context, refs } = useFloating({
    open,
    onOpenChange: setOpen,
  });

  const click = useClick(context, {
    enabled: controlledOpen == null,
  });
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
    outsidePress: outsidePress ?? ((event) => (event.target as HTMLElement).id === drawerBackdropId),
  });
  const role = useRole(context);

  const { getFloatingProps, getReferenceProps } = useInteractions([click, dismiss, role]);

  return {
    open,
    setOpen,
    labelId,
    descriptionId,

    triggerProps: {
      ref: refs.setReference,
      ...getReferenceProps(),
    },

    modalProps: {
      context,
      ref: refs.setFloating,
      ...getFloatingProps(),
      labelId,
      descriptionId,
      nodeId,
      isOpen: open,
      close: () => setOpen(false),
    },
  };
}
