import { useState } from 'react';
import { css } from '@emotion/react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { useQueryClient } from 'react-query';
import {
  ContactDirectoriesAPI,
  ContactDirectoriesMutation,
  ContactDirectoriesQueryKeys,
} from '@frontend/api-contact-directories';
import { EditableText } from '@frontend/editable-text';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Heading, Tray, Text, useFormField, ModalControlModalProps, ContentLoader } from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { AssignDevicesToDirectoryTable } from '../tables';

type Props = ModalControlModalProps & {
  assignableContacts?: Contact[];
};

export const CreateNewDirectory = ({ onClose, assignableContacts }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const [selectedDevicesIds, setSelectedDevicesIds] = useState<string[]>();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const { useReplaceContactDirectoryDevicesMutation, useReplaceContactDirectoryContactsMutation } =
    ContactDirectoriesMutation.useContactDirectoryMutations();
  const { navigate } = useSettingsNavigate();
  const queryClient = useQueryClient();

  const { mutateAsync: mutateAssignDevices } = useReplaceContactDirectoryDevicesMutation({
    onSettled: () => {
      setSelectedDevicesIds(undefined);
    },
  });

  const { mutateAsync: onAssignContacts } = useReplaceContactDirectoryContactsMutation();

  const contactDirectoryNameProps = useFormField({
    required: true,
    type: 'text',
    placeholder: t('New List Name'),
  });

  const createContactList = useMutation(
    (name: string) =>
      ContactDirectoriesAPI.createContactList({
        contactList: {
          name,
          id: '',
        },
        tenantId,
      }),
    {
      onSuccess: async ({ contactList }) => {
        if (selectedDevicesIds) {
          await mutateAssignDevices({ contactListId: contactList?.id, deviceIds: selectedDevicesIds });
        }
        if (!!assignableContacts?.length) {
          const contactIds = assignableContacts.map((contact) => contact.id);
          await onAssignContacts({ contactListId: contactList?.id, contactIds });
          queryClient.invalidateQueries([
            tenantId,
            ...ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactList.id),
          ]);
        }
        navigate({
          to: '/phone/contact-directories/:id',
          params: { id: contactList?.id },
        });
        onClose();
        queryClient.invalidateQueries([
          tenantId,
          ...ContactDirectoriesQueryKeys.queryKeys.getContactDirectoryById(contactList.id),
        ]);
        queryClient.invalidateQueries([tenantId, ...ContactDirectoriesQueryKeys.queryKeys.getContactDirectories()]);
      },
    }
  );

  return (
    <>
      <ContentLoader show={createContactList.isLoading} />
      <Tray.Header>{t('Create New List')}</Tray.Header>
      <Tray.Body>
        <EditableText helperText={t('Contact List Name')} fieldProps={contactDirectoryNameProps} />
        <Heading
          css={css`
            margin-top: ${theme.spacing(3)};
          `}
          level={2}
        >
          {t('Assign List to Devices')}
        </Heading>
        <Text weight='regular' color='light'>
          {t('Select the devices that will use this contact list in their phone directory.')}
        </Text>
        <AssignDevicesToDirectoryTable setSelectedDevicesIds={setSelectedDevicesIds} />
      </Tray.Body>
      <Tray.Actions
        onSecondaryClick={onClose}
        disabledPrimary={!contactDirectoryNameProps.value}
        secondaryLabel={t('Cancel')}
        primaryLabel={t('Save')}
        primaryTrackingId='Create-contact-list'
        onPrimaryClick={() => {
          createContactList.mutate(contactDirectoryNameProps.value);
        }}
      />
    </>
  );
};
