import { memo, useMemo } from 'react';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { PopoverDialog, UsePopoverDialogResponse } from '@frontend/design-system';
import { SourceTenantListItem } from '../../EventsPanel/ScheduleActions/ScheduleRequests/ScheduleRequestModal/types';
import { PersonListContainer } from './PersonSelectorContainer';
import { ManagePersonListContext, ManagePersonListContextType } from './PersonSelectorContext';

type PersonSelectorProps = Omit<ManagePersonListContextType, 'closePopoverDialog'> & {
  width?: number;
  personSelectorDialogProps: UsePopoverDialogResponse;
  patientWritebackSourceTenants?: SourceTenantListItem[];
  shouldAddAndLink?: boolean;
};

export const PersonSelector = memo(
  ({
    locationId,
    personSelectorDialogProps,
    selectedPerson,
    setSelectedPerson,
    defaultSearchValue = '',
    isLoading = false,
    onAddToListHandler,
    handleAddCustomerContact,
    setNotes,
    shouldRenderNotes = false,
    width = 388,
    formFields,
    dataSources,
    clientLocationIds = [],
    personSelectorScreen,
    setPersonSelectorScreen,
    addContactScreenTitle = i18next.t('Custom Contact', { ns: 'personSelector' }),
    showDataSources = false,
    addNewButtonTrackingId = 'person-selector-add-new-button',
    addToListButtonTrackingId = 'person-selector-add-to-list-button',
    cancelButtonTrackingId = 'person-selector-cancel-button',
    patientWritebackSourceTenants,
    shouldAddAndLink,
  }: PersonSelectorProps) => {
    const providerValue: ManagePersonListContextType = useMemo(
      () => ({
        closePopoverDialog: personSelectorDialogProps.close,
        locationId,
        personSelectorScreen,
        selectedPerson,
        defaultSearchValue,
        isLoading,
        setPersonSelectorScreen,
        setSelectedPerson,
        onAddToListHandler,
        handleAddCustomerContact,
        setNotes,
        shouldRenderNotes,
        formFields,
        dataSources,
        clientLocationIds,
        addContactScreenTitle,
        showDataSources,
        addNewButtonTrackingId,
        addToListButtonTrackingId,
        cancelButtonTrackingId,
        patientWritebackSourceTenants,
        shouldAddAndLink,
      }),
      [
        personSelectorScreen,
        selectedPerson,
        defaultSearchValue,
        locationId,
        isLoading,
        onAddToListHandler,
        handleAddCustomerContact,
        shouldRenderNotes,
        formFields,
        dataSources,
        addContactScreenTitle,
        showDataSources,
        addNewButtonTrackingId,
        addToListButtonTrackingId,
        cancelButtonTrackingId,
        clientLocationIds,
        shouldAddAndLink,
      ]
    );

    return (
      <ManagePersonListContext.Provider value={providerValue}>
        <PopoverDialog
          {...personSelectorDialogProps.getDialogProps()}
          css={{ width, padding: 0, borderRadius: theme.borderRadius.small }}
        >
          <PersonListContainer />
        </PopoverDialog>
      </ManagePersonListContext.Provider>
    );
  }
);

PersonSelector.displayName = 'PersonSelector';
