import { TrialStatus } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { SelfUpgradeActionType } from './constants/self-upgrade';
import { FreeTrialEligibilityData, SelfSubscribeEligibilityData } from './types';

export const getActionText = (
  actionType: SelfUpgradeActionType
): { primaryButtonText?: string; actionInfoText?: string } => {
  switch (actionType) {
    case SelfUpgradeActionType.REQUEST_UPGRADE:
      return {
        primaryButtonText: i18next.t('Request Upgrade', { ns: 'featurePromotion>>self-upgrade-actions' }),
        actionInfoText: i18next.t('A request will be sent to your admin', {
          ns: 'featurePromotion>>self-upgrade-actions',
        }),
      };
    case SelfUpgradeActionType.SCHEDULE_CALL:
      return {
        primaryButtonText: i18next.t('Call to Upgrade', { ns: 'featurePromotion>>self-upgrade-actions' }),
      };
    case SelfUpgradeActionType.FREE_TRIAL:
      return {
        primaryButtonText: i18next.t('Try for Free', { ns: 'featurePromotion>>self-upgrade-actions' }),
      };
    case SelfUpgradeActionType.UPGRADE_NOW:
      return {
        primaryButtonText: i18next.t('Upgrade Now', { ns: 'featurePromotion>>self-upgrade-actions' }),
      };
    default:
      return {
        primaryButtonText: '',
        actionInfoText: '',
      };
  }
};

export const getActionIconName = (actionType: SelfUpgradeActionType): IconName => {
  // switch case added for future use
  switch (actionType) {
    default:
      return 'award';
  }
};

export const getNotificationDismissActionText = (
  actionType: FeatureNotificationTypes.FeatureNotificationAction
): string => {
  switch (actionType) {
    case FeatureNotificationTypes.FeatureNotificationAction.ACTION_NEVER_SHOW_AGAIN:
      return i18next.t('Never show again', { ns: 'featurePromotion>>self-upgrade-actions' });
    case FeatureNotificationTypes.FeatureNotificationAction.ACTION_REMIND_AFTER_3_DAYS:
      return i18next.t('Remind me in 3 days', { ns: 'featurePromotion>>self-upgrade-actions' });
    default:
      return i18next.t('Dismiss', { ns: 'featurePromotion>>self-upgrade-actions' });
  }
};

export const checkCanStartTrial = (freeTrialEligibilityData: FreeTrialEligibilityData) => {
  return (
    freeTrialEligibilityData.canManageAddons && freeTrialEligibilityData.trialStatus === TrialStatus.TRIAL_ELIGIBLE
  );
};

export const checkCanSelfSubscribe = (selfSubscribeEligibilityData: SelfSubscribeEligibilityData) => {
  return (
    selfSubscribeEligibilityData.canManageAddons &&
    !selfSubscribeEligibilityData.hasOverdueBalance &&
    !selfSubscribeEligibilityData.isStateActive &&
    selfSubscribeEligibilityData.subscriptionStatus ===
      FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIBE
  );
};

export const getFeatureFreeTrialDays = (feature: Feature): number => {
  switch (feature) {
    case Feature.CALL_INTELLIGENCE:
      return 14;
    default:
      return 30;
  }
};

export const getScheduleDemoLink = (campaign: Feature | 'bundle', email = '') => {
  const demoLink = 'https://www.getweave.com/campaign/upgrade/';
  const searchParams = new URLSearchParams({
    title: 'Schedule a demo to explore your options', // This is for url no need to translate
    utm_campaign: campaign,
    email,
    utm_source: 'product',
  });

  return `${demoLink}?${searchParams.toString()}`;
};

const FeatureChillipiperOnboardingTypeMap: Partial<Record<Feature, string>> = {
  [Feature.FORMS]: '64ff89461c5178020e8bb181',
  [Feature.TEXT_CONNECT]: '5a9f51199cf5260001f8ca67',
  [Feature.ONLINE_SCHEDULING]: '64ff8c1d1c5178020e8bb297',
  [Feature.PRACTICE_ANALYTICS]: '652829d5d593786d70b20303',
};

export const getOnboardingCallLink = (feature: Feature) => {
  const baseUrl = 'https://getweave.chilipiper.com/book/upsell';
  const searchParams = new URLSearchParams({
    type: FeatureChillipiperOnboardingTypeMap[feature] ?? '',
    back: 'true',
  });

  return `${baseUrl}?${searchParams.toString()}`;
};
