import { css } from '@emotion/react';
import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, Chip, NakedButton, styles as dsStyles, SpinningLoader, Stepper, NakedUl } from '@frontend/design-system';
import { useGetAppointmentTypesProviders } from '../../../hooks/booking-site';
import { useBookingSiteStore } from '../../../stores';

export function ApptTypeSelect() {
  const { t } = useTranslation('bookingSite');
  const { selectedAppointmentType, setSelectedAppointmentType, setAvailableProviders } = useBookingSiteStore([
    'selectedAppointmentType',
    'setSelectedAppointmentType',
    'setAvailableProviders',
  ]);
  const { isLoadingAppointments, appointmentTypes, getAvailableProviders } = useGetAppointmentTypesProviders();

  const handleSelectAppointmentType = (appt: AppointmentType) => {
    // Required number of appointment times. If the selected appointment type has a minOpeningsPermitted, use that value. If it doesn't then try to find one that does and use that value. If none of them have a minOpeningsPermitted, default to 3.
    const minSlots =
      appt.minOpeningsPermitted || appointmentTypes.find((a) => !!a.minOpeningsPermitted)?.minOpeningsPermitted;
    setSelectedAppointmentType(appt, minSlots);
    const availableProviders = getAvailableProviders(appt.id);
    setAvailableProviders(availableProviders);
  };

  if (isLoadingAppointments) {
    return (
      <Stepper.Content css={dsStyles.flexCenter}>
        <SpinningLoader size='medium' />
      </Stepper.Content>
    );
  }

  if (!appointmentTypes?.length) {
    return (
      <Stepper.Content>
        <Text color='light' className='left'>
          {t('No Appointments available for this office.')}
        </Text>
      </Stepper.Content>
    );
  }

  return (
    <Stepper.Content>
      <Text>{t('To get started, simply select the type of appointment you need from our list of options.')}</Text>
      <Text color='light' size='small'>
        {t('Appointment Types')}
      </Text>
      <NakedUl css={styles.apptCardContainer}>
        {appointmentTypes.map((appt) => {
          const { name, durationMinutes, id } = appt;
          const isSelected = selectedAppointmentType?.id === id;
          return (
            <li key={id}>
              <NakedButton
                data-cy='request-appointment-appointment-select'
                className={isSelected ? 'selected' : ''}
                onClick={() => handleSelectAppointmentType(appt)}
              >
                {name}
                <Chip css={{ fontWeight: 'normal' }}>{durationMinutes} min</Chip>
              </NakedButton>
            </li>
          );
        })}
      </NakedUl>
    </Stepper.Content>
  );
}

const styles = {
  apptCardContainer: css({
    maxHeight: 300,
    overflowY: 'auto',
    button: {
      border: `1px solid ${theme.colors.neutral20}`,
      borderRadius: theme.borderRadius.small,
      width: '100%',
      textAlign: 'left',
      padding: theme.spacing(2),
      margin: theme.spacing(1, 0),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: theme.spacing(1),
      fontWeight: theme.font.weight.bold,
      fontSize: theme.fontSize(20),
      ':hover': {
        backgroundColor: theme.colors.neutral10,
        '.chip': {
          backgroundColor: theme.colors.primary50,
          color: theme.colors.white,
        },
      },
      '@media (max-width: 699px)': {
        fontSize: theme.fontSize(16),
      },
    },
    'button.selected': {
      border: `2px solid ${theme.colors.primary50}`,
      padding: `calc(${theme.spacing(2)} - 1px)`,
    },
  }),
};
