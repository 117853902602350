import { FC, Dispatch, SetStateAction } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Avatar, Text, Button, ContentLoader } from '@frontend/design-system';
import { StreamUser } from '../../../types';
import { useUserInfo } from './use-user-info';

interface StatusTooltipContentProps {
  user: StreamUser;
  setOpen: Dispatch<SetStateAction<boolean>>;
  showSendMessage?: boolean;
}

export const StatusTooltipContent: FC<StatusTooltipContentProps> = ({ user, setOpen, showSendMessage = false }) => {
  const { onClickStatus, canEdit, hasStatus, name, sendMessage, isLoading, userStatus } = useUserInfo(user, setOpen);

  const { t } = useTranslation('team-chat');

  return (
    <>
      <div css={tooltipAvatarContainerStyle}>
        <Avatar size='large' name={name} isUser disableHoverLabel />
        <Text size='large' weight='bold'>
          {name}
        </Text>
      </div>
      {(hasStatus || (showSendMessage && sendMessage) || canEdit) && (
        <div css={tooltipStatusContainerStyle}>
          {hasStatus && (
            <span css={statusTextContainerStyle}>
              {userStatus.status.emoji && (
                <Text size='large' css={emojiStyle}>
                  {userStatus.status.emoji}
                </Text>
              )}
              {userStatus.status.text && (
                <Text size='large' weight='regular'>
                  {userStatus.status.text}
                </Text>
              )}
            </span>
          )}
          {canEdit && (
            <Button
              variant='secondary'
              onClick={onClickStatus}
              trackingId={
                hasStatus
                  ? 'team-chat-2.0-clear-status-button-from-tooltip'
                  : 'team-chat-2.0-update-status-button-from-tooltip'
              }
            >
              <Text>{hasStatus ? t('Clear Status') : t('Update Status')}</Text>
            </Button>
          )}
          {showSendMessage && sendMessage && (
            <Button onClick={sendMessage} variant='secondary'>
              {t('Send Direct Message')}
            </Button>
          )}
        </div>
      )}
      <ContentLoader show={isLoading} />
    </>
  );
};

const tooltipAvatarContainerStyle = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
});

const tooltipStatusContainerStyle = css({
  borderTop: `1px solid ${theme.colors.neutral20}`,
  padding: theme.spacing(2),
  gap: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
});

const statusTextContainerStyle = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
});

const emojiStyle = css({ minWidth: '24px', fontSize: theme.fontSize(20) });

export const toolTipContainerStyle = css`
  background-color: ${theme.colors.white};
  padding: 0;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.medium};
  box-shadow: ${theme.shadows.floating};
  width: 320px;

  svg {
    display: none;
  }
`;
