import { useReducer, useState } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { ContactDirectoriesAPI, ContactDirectoriesQueryKeys } from '@frontend/api-contact-directories';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Button,
  Modal,
  ModalControlModalProps,
  Text,
  useTooltip,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { BusinessFields } from '../component/business-fields';
import { ContactWithError, ReducerDispatchAction, ReducerState, useContactDetailsReducer } from '../hooks';
import { CancelNewContactsModal } from './cancel-new-contacts';
import { CommonModalWrapper } from './modal-wrapper';
import { SaveNewContactsModal } from './save-new-contacts';

type Props = ModalControlModalProps & {
  contactDirectoryId?: string;
  contactDirectoryName?: string | undefined;
};

export const NewContactModal = ({ contactDirectoryName, contactDirectoryId, onClose, show }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const initalState: ReducerState = [
    {
      id: '0',
      name: '',
      notes: '',
      errors: ['contact-name', 'missing-number-0'],
      phoneNumbers: [{ label: t('Main'), number: '', id: '0' }],
    },
  ];
  const [state, dispatch] = useReducer(useContactDetailsReducer, initalState);
  const [accordionsKey, setAccordionsKey] = useState<string | null>('');
  const errors = state.some((contact, i) => {
    if (i === state.length - 1) return false;
    return !!contact.errors?.length && contact.errors.length > 0;
  });
  const cancelModal = useModalControl();
  const saveModal = useModalControl();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const queryClient = useQueryClient();
  const alerts = useAlert();

  const { mutate: bulkAddContacts } = useMutation(ContactDirectoriesAPI.batchCreateContacts, {
    onSuccess: ({ contacts }) => {
      queryClient.invalidateQueries([tenantId, ...ContactDirectoriesQueryKeys.queryKeys.getContacts()]);
      if (contactDirectoryId) {
        queryClient.invalidateQueries([
          tenantId,
          ...ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId),
        ]);
      }
      alerts.success(t('Successfully added {{count}} contacts', { count: contacts.length }));
      onClose();
    },
    onError: () => {
      alerts.error(t('Failed to add contacts'));
    },
  });

  const onSave = (contactsToSave: ContactWithError[]) => {
    const contacts = contactsToSave.map((contact) => {
      const { errors, ...rest } = contact;
      return {
        ...rest,
      };
    });

    bulkAddContacts({
      contacts,
      tenantId,
      contactListId: contactDirectoryId,
    });
  };

  const onAdd = () => {
    dispatch({
      type: 'ADD_NEW_CONTACT',
      payload: { id: state.length.toString(), name: '' },
    });
  };

  const removeContact = ({ index, name }: { index: number; name: string }) => {
    dispatch({
      type: 'REMOVE_CONTACT',
      payload: { id: index.toString(), name },
    });
  };

  const onDispatch = (action: ReducerDispatchAction) => {
    dispatch(action);
  };

  return (
    <>
      <CommonModalWrapper
        disableCloseOnEscape
        disableCloseOnOverlayClick
        onClose={onClose}
        show={show}
        css={css`
          .wv-modal-footer-helper-text {
            color: ${theme.colors.status.critical};
          }
        `}
      >
        {!cancelModal.modalProps.show && !saveModal.modalProps.show && (
          <>
            <Modal.Header onClose={cancelModal.openModal}>{t('Add New Contact')}</Modal.Header>
            <Modal.Body>
              <Text>{t('Add up to 10 contacts at a time and save them to your list.')}</Text>
              {state.map((contact, index) => {
                const name = contact.errors?.includes('contact-name') ? t('No Name') : contact.name;
                const hasError = (contact.errors?.length ?? 0) > 0 && index !== state.length - 1;

                return (
                  <div key={contact.id} css={{ display: 'flex', alignItems: 'start', gap: theme.spacing(2) }}>
                    <Text color={hasError ? 'error' : 'default'} css={indexStyle(hasError)} weight='bold'>
                      {index + 1}
                    </Text>
                    {index === state.length - 1 ? (
                      <BusinessFields
                        showBorder
                        onDispatch={onDispatch}
                        key={index}
                        contact={{ ...contact, id: index.toString() }}
                        hasError={hasError}
                      />
                    ) : (
                      <>
                        <Accordion
                          size='small'
                          css={accordionStyle}
                          key={index}
                          variant={hasError ? 'error' : 'hybrid'}
                          padding={[1, 0, 1, 2]}
                          showBorderBottomAlways
                          distance={0}
                          controlledValue={accordionsKey}
                          onChange={setAccordionsKey}
                          startAdornment={hasError && <ErrorTooltip errors={contact.errors} />}
                        >
                          <Accordion.Item value={index.toString()}>
                            <Accordion.Header title={name}>
                              <div
                                css={{ marginLeft: 'auto' }}
                                onClick={() => removeContact({ index, name: contact.name })}
                              >
                                <Icon size={24} name='trash-small' color='error' />
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <BusinessFields
                                onDispatch={onDispatch}
                                key={index}
                                contact={{ ...contact, id: index.toString() }}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </>
                    )}
                  </div>
                );
              })}
              <Button
                css={{ display: 'flex', justifyContent: 'flex-start', width: 'fit-content' }}
                variant='tertiary'
                onClick={onAdd}
                iconName='plus'
                disabled={state.length === 10}
              >
                {t('Add Another Contact')}
              </Button>
            </Modal.Body>
            <Modal.Actions
              primaryLabel={t('Save')}
              onPrimaryClick={saveModal.openModal}
              secondaryLabel={t('Cancel')}
              onSecondaryClick={cancelModal.openModal}
              helperText={errors && state.length > 1 ? t(`Fix incomplete or invalid entries`) : ''}
            />
          </>
        )}
        <CancelNewContactsModal onSave={onSave} state={state} closeModal={onClose} {...cancelModal.modalProps} />
        <SaveNewContactsModal
          onSave={onSave}
          closeModal={onClose}
          contactDirectoryName={contactDirectoryName}
          state={state}
          {...saveModal.modalProps}
        />
      </CommonModalWrapper>
    </>
  );
};

const ErrorTooltip = ({ errors }: { errors: ContactWithError['errors'] }) => {
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  const { t } = useTranslation('phone', { keyPrefix: 'contact-directories' });
  const messages = [];

  if (errors?.includes('contact-name')) {
    messages.push(t('Contact Name is required'));
  }
  if (errors?.some((error) => error?.startsWith('missing-number'))) {
    messages.push(t('Phone Number is required'));
  }
  if (errors?.some((error) => error?.startsWith('missing-label'))) {
    messages.push(t('Label is required'));
  }
  return (
    <>
      <div {...triggerProps}>
        <Icon name='error-badge' color='error' />{' '}
      </div>
      <Tooltip {...tooltipProps}>
        {messages?.map((message) => (
          <ul css={{ paddingLeft: theme.spacing(2) }} key={message}>
            <Text as='li' size='medium' color='white'>
              {message}
            </Text>
          </ul>
        ))}
      </Tooltip>
    </>
  );
};

const indexStyle = (error: boolean) => css`
  border: 1px solid ${error ? theme.colors.status.critical : theme.colors.black};
  border-radius: ${theme.borderRadius.small};
  width: ${theme.spacing(3)};
  height: ${theme.spacing(3)};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${theme.spacing(1)};
`;

const accordionStyle = css`
  width: 100%;
  margin: 0;
  .header-container {
    align-items: center;
    height: ${theme.spacing(5)};
    gap: ${theme.spacing(0.5)};
    span {
      font-size: ${theme.fontSize(16)};
    }
  }
`;
