import React, { forwardRef, ReactNode, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { isEmpty } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';

type BadgeOption = {
  chip: ReactNode;
};

type Props = {
  emptyValue: string;
  options: Record<string, BadgeOption>;
  value: string | string[];
  containerWidth?: number;
  trackingId?: string;
};

export const BadgeDropdownInput = forwardRef<HTMLInputElement, Props>(
  ({ emptyValue, options, value, containerWidth = 232, trackingId, ...rest }, ref) => {
    const { t } = useTranslation('analytics');

    const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top-end' });

    const valuesArray = Array.isArray(value) ? value : [value];

    const hiddenMeasureRef = useRef<HTMLDivElement>(null);
    const [visibleCount, setVisibleCount] = useState<number | null>(null);
    const [finalVisibleCount, setFinalVisibleCount] = useState<number | null>(null);

    const haveNoValue = isEmpty(value);

    useLayoutEffect(() => {
      if (!hiddenMeasureRef.current || haveNoValue) {
        setVisibleCount(null);
        setFinalVisibleCount(null);
        return;
      }

      const chips = Array.from(hiddenMeasureRef.current.querySelectorAll('.chip-to-measure'));
      // First, measure all chips to see how many can fit without +N more
      let totalWidth = 0;
      let count = 0;

      for (let i = 0; i < chips.length; i++) {
        const chip = chips[i] as HTMLElement;
        const gap = i === 0 ? 0 : 8;
        const chipWidth = chip.offsetWidth + gap;

        if (totalWidth + chipWidth > containerWidth) {
          break;
        } else {
          totalWidth += chipWidth;
          count++;
        }
      }
      setVisibleCount(count);
    }, [haveNoValue, valuesArray, containerWidth]);

    useLayoutEffect(() => {
      if (!hiddenMeasureRef.current || haveNoValue || visibleCount === null) return;

      const allChips = valuesArray.filter((key) => options[key]).map((key) => options[key].chip);
      // If all fit, no need for +N more
      if (visibleCount >= allChips.length) {
        setFinalVisibleCount(visibleCount);
        return;
      }

      // Not all fit, we need to consider +N more.
      const measureMoreRef = hiddenMeasureRef.current.querySelector('.measure-more-label') as HTMLElement;

      if (!measureMoreRef) {
        // Render +N more inside hidden measure to get its width
        // We'll re-render this effect after it's available.
        return;
      }

      const allChipElements = Array.from(hiddenMeasureRef.current.querySelectorAll('.chip-to-measure'));
      const chipWidths = allChipElements.map((chip, idx) => {
        const c = chip as HTMLElement;
        const gap = idx === 0 ? 0 : 8;
        return c.offsetWidth + gap;
      });

      const moreLabelWidth =
        measureMoreRef.offsetWidth + parseFloat(getComputedStyle(measureMoreRef).marginRight || '0');

      // Now we try reducing visible chips until +N more fits
      let tryCount = visibleCount;
      while (tryCount > 0) {
        // sum width of first tryCount chips
        const widthSum = chipWidths.slice(0, tryCount).reduce((acc, w) => acc + w, 0);
        // Check if we can fit +N more after these tryCount chips
        if (widthSum + moreLabelWidth <= containerWidth) {
          setFinalVisibleCount(tryCount);
          return;
        }
        tryCount--;
      }

      // If we can't fit even one chip plus the +N more label, then we just show +N more label
      setFinalVisibleCount(0);
    }, [haveNoValue, visibleCount, value, options, valuesArray, containerWidth]);

    const allChips = valuesArray.filter((key) => options[key]).map((key) => options[key].chip);

    let displayedChips: React.ReactNode[] = [];
    let moreCount = 0;

    if (haveNoValue) {
      displayedChips = [<Text key='empty'>{emptyValue}</Text>];
    } else if (finalVisibleCount === null) {
      // Still measuring, show nothing
    } else {
      if (finalVisibleCount >= allChips.length) {
        // All fit
        displayedChips = allChips;
      } else {
        displayedChips = allChips.slice(0, finalVisibleCount);
        moreCount = allChips.length - finalVisibleCount;
      }
    }

    const extractText = (chip: ReactNode): string => {
      if (typeof chip === 'string') return chip;
      if (React.isValidElement(chip)) {
        return extractText(chip.props.children);
      }
      return '';
    };

    return (
      <div css={styles.wrapper} ref={ref} data-trackingid={trackingId} {...rest}>
        {haveNoValue && displayedChips}
        {!haveNoValue && finalVisibleCount !== null && (
          <>
            {displayedChips.map((chip, idx) => (
              <span key={idx} css={styles.chipWrapper}>
                {chip}
              </span>
            ))}
            {moreCount > 0 && (
              <>
                <Text
                  as='span'
                  color='subdued'
                  css={css`
                    white-space: nowrap;
                  `}
                  size='small'
                  {...triggerProps}
                >
                  {t('+ {{moreCount}} more', { moreCount })}
                </Text>
                <Tooltip {...tooltipProps} css={styles.tooltipContent}>
                  <Text size='medium' color='white'>
                    {allChips
                      .slice(finalVisibleCount)
                      .map((chip) => extractText(chip))
                      .filter(Boolean)
                      .join(', ')}
                  </Text>
                </Tooltip>
              </>
            )}
          </>
        )}

        {/* Hidden element for measuring chip sizes and more label */}
        <div css={styles.hiddenMeasure} ref={hiddenMeasureRef}>
          {allChips.map((chip, idx) => (
            <span key={idx} className='chip-to-measure' css={styles.chipWrapper}>
              {chip}
            </span>
          ))}

          {allChips.length > (visibleCount ?? 0) && (
            <span className='measure-more-label'>
              <Text as='span' size='small' color='subdued' css={styles.chipWrapper}>
                {t('+ {{moreCount}} more', { moreCount: allChips.length - (visibleCount ?? 0) })}
              </Text>
            </span>
          )}
        </div>
      </div>
    );
  }
);

BadgeDropdownInput.displayName = 'BadgeDropdownInput';

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    cursor: pointer;
    gap: ${theme.spacing(1)};
    width: 100%;
    height: 100%;
  `,
  hiddenMeasure: css`
    position: absolute;
    top: -9999px;
    left: -9999px;
    display: flex;
    gap: ${theme.spacing(1)};
    align-items: center;
    visibility: hidden;
    pointer-events: none;
    white-space: nowrap;
    max-width: none;
    overflow: visible;
  `,
  chipWrapper: css`
    span {
      font-size: ${theme.fontSize(12)};
    }
  `,
  tooltipContent: css`
    max-width: 210px;
  `,
};
