import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type CarouselCounterProps = {
  activeIndex: number;
  total: number;
};

export const CarouselCounter = ({ activeIndex, total }: CarouselCounterProps) => (
  <div
    css={css`
      position: fixed;
      background: ${theme.colors.white};
      display: flex;
      justify-content: center;
      padding: ${theme.spacing(1, 2)};
      bottom: ${theme.spacing(2)};
      left: 50%;
      transform: translateX(-50%);
      border-radius: ${theme.borderRadius.medium};
      z-index: ${theme.zIndex.modals};
      box-shadow: ${theme.shadows.heavy};
    `}
  >
    <Text weight='bold' size='large'>
      {activeIndex + 1} of {total}
    </Text>
  </div>
);
