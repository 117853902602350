import { memo, useEffect } from 'react';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedSMSReport, useFormatBarChartData, useMessagingAnalyticsShallowStore } from '../hooks';

const colors = {
  Inbound: theme.colors.secondary.seaweed30,
  Outbound: theme.colors.warning50,
};

const labels = {
  Inbound: i18next.t('Incoming', { ns: 'analytics' }),
  Outbound: i18next.t('Outgoing', { ns: 'analytics' }),
};

interface IncomingVsOutgoingMessagesChartProps {
  onLoadingStateChange?: (isLoading?: boolean) => void;
}

export const IncomingVsOutgoingMessagesChart = memo(
  ({ onLoadingStateChange }: IncomingVsOutgoingMessagesChartProps) => {
    const { t } = useTranslation('analytics');
    const { data, isLoading } = useFetchAggregatedSMSReport('Direction');
    const { filterHintText, isDemoAccount } = useMessagingAnalyticsShallowStore('filterHintText', 'isDemoAccount');
    const { defaultBarChartAppearance, exportPdfProps, processedData } = useFormatBarChartData({
      data,
      dataKeysSequence: ['Inbound', 'Outbound'],
    });

    useEffect(() => {
      onLoadingStateChange?.(isLoading);
    }, [isLoading]);

    return (
      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={
            <InfoTipPopover>
              {t('Total incoming and outgoing messages. It includes both manual or automated messages')}
            </InfoTipPopover>
          }
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Total Message Count: Incoming vs Outgoing')}
        />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={processedData.chartData} />
        <Chart.Legends formatValue={formatters.value.format} values={processedData.totals} />
      </Chart>
    );
  }
);

IncomingVsOutgoingMessagesChart.displayName = 'IncomingVsOutgoingMessagesChart';
