import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';
import { useTableFilter } from './table-filter-provider';

const notificationStyle = (showNotification: boolean) => css`
  ::after {
    position: absolute;
    background: ${theme.colors.primary50};
    border-radius: 16px;
    border: 1.5px solid white;
    height: 12px;
    width: 12px;
    top: 6px;
    left: 24px;
    z-index: 10;
    content: '';
    opacity: ${showNotification ? 1 : 0};
    /* match the animation and color of the icon button hover */
    transition: border-color, opacity ease-in-out 250ms;
  }
  :hover::after,
  :focus::after {
    border-color: ${theme.colors.neutral10};
  }
`;

export const TableFilterTrigger = (rest: any) => {
  const { showNotification, popoverProps } = useTableFilter();
  const { t } = useTranslation('payments');

  return (
    // container for sizing the notification bubble
    <div css={{ position: 'relative' }}>
      <Button
        variant='secondary'
        size='large'
        iconName='filter-alt'
        {...popoverProps.getTriggerProps()}
        css={notificationStyle(showNotification)}
        hoverLabel={t('Filter')}
        {...rest}
      />
    </div>
  );
};
