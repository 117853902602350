import { CSSProperties, forwardRef, ReactNode } from 'react';
import { css } from '@emotion/react';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Text, useTooltip } from '@frontend/design-system';

export const EditMenu = forwardRef<
  HTMLDivElement,
  {
    children: ReactNode;
    errorMsg: string;
    isLoading?: boolean;
    isPrimaryDisabled?: boolean;
    onSave?: () => void;
    onSuggest?: () => void;
    showSaveChanges?: boolean;
    showSuggestion?: boolean;
    style?: CSSProperties;
    trackingIds?: {
      suggestionBtnId?: string;
      saveBtnId?: string;
    };
  }
>(
  (
    {
      children,
      errorMsg = '',
      isLoading = false,
      isPrimaryDisabled = false,
      onSave,
      onSuggest,
      showSaveChanges = true,
      showSuggestion = true,
      style,
      trackingIds,
    },
    ref
  ) => {
    const { t } = useTranslation('analytics');

    const { tooltipProps, triggerProps: toolTipTriggerProps, Tooltip } = useTooltip();

    const handleSave = () => {
      !!onSave && onSave();
    };

    const handleSuggest = () => {
      !!onSuggest && onSuggest();
    };

    return (
      <div css={styles.menuWrapper} className='edit-menu' ref={ref} style={style}>
        <div css={styles.menuList}>
          <div css={[styles.header, styles.contentMenu]}>
            <Text size='large' weight='bold'>
              {t('Correct Analysis')}
            </Text>
          </div>

          <div css={styles.dynamicContentContainer}>
            {!!errorMsg && (
              <div css={[styles.errorMsgWrapper, styles.contentMenu]}>
                <Text size='small' color='error'>
                  {errorMsg}
                </Text>
              </div>
            )}
            {children}
          </div>
        </div>

        {showSaveChanges && (
          <div css={[styles.menuList, styles.actionMenu]}>
            <Button
              variant='primary'
              css={styles.btnMenuList}
              disabled={isWeaveUser() || isPrimaryDisabled || !!errorMsg}
              loading={isLoading}
              trackingId={trackingIds?.saveBtnId}
              {...toolTipTriggerProps}
              onClick={handleSave}
            >
              {t('Save Changes')}
            </Button>
          </div>
        )}
        {showSuggestion && (
          <div css={[styles.menuList, styles.actionMenu]}>
            <Button
              variant='tertiary'
              css={styles.btnMenuList}
              onClick={handleSuggest}
              trackingId={trackingIds?.suggestionBtnId}
            >
              {t('Make a Suggestion')}
            </Button>
          </div>
        )}

        {isWeaveUser() && (
          <Tooltip {...tooltipProps}>
            {t(
              'This feature is intended solely for Weave customers to correct AI outputs perceived as wrongly classified.'
            )}
          </Tooltip>
        )}
      </div>
    );
  }
);

export default EditMenu;

EditMenu.displayName = 'EditMenu';

const styles = {
  menuWrapper: css`
    width: 166px;
    > div:not(:last-of-type) {
      border-bottom: 1px solid ${theme.colors.neutral20};
    }
  `,
  header: css`
    width: 100%;
  `,
  dynamicContentContainer: css`
    max-height: 188px;
    overflow: auto;
    width: 100%;
  `,
  errorMsgWrapper: css`
    margin-top: ${theme.spacing(1)};
  `,
  menuList: css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(1, 0, 0, 0)};
    align-items: center;
  `,
  btnMenuList: css`
    width: 100%;
    text-transform: none;
  `,
  contentMenu: css`
    padding: ${theme.spacing(0, 2)};
  `,
  actionMenu: css`
    padding: ${theme.spacing(1, 2)};
  `,
};
