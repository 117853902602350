import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Tabs, BannerNotification } from '@frontend/design-system';
import { BuilderFieldDivisionCategories } from '../../../../constants';
import { useFormBuilderStore } from '../../../../provider';
import CategoriesLayout from '../categories-layout/categories-layout.component';
import { useFilteredCategories } from '../hooks';
import { headingStyle, containerStyle } from './syncable-and-non-syncable-medical-history-fields.styles';
import SyncableMedicalHistoryFields from './syncable-medical-history-fields/syncable-medical-history-fields.component';

const WRITEBACK_TABS = {
  WRITEBACK: {
    id: 'writeback-tab',
    control: 'writeback-panel',
  },
  WITHOUT_WRITEBACK: {
    id: 'without-writeback-tab',
    control: 'without-writeback-panel',
  },
};

export const SyncableAndNonSyncableMedicalHistoryFields: FC = () => {
  const { t } = useTranslation('forms');
  const { filteredCategories } = useFilteredCategories({
    categories: BuilderFieldDivisionCategories.GENERIC_MEDICAL_HISTORY_FIELD_CATEGORIES,
  });
  const { otherFieldElements, shouldShowMedicalHistoryFields } = useFormBuilderStore([
    'otherFieldElements',
    'shouldShowMedicalHistoryFields',
  ]);
  const [activeTab, setActiveTab] = useState<string>(
    shouldShowMedicalHistoryFields ? WRITEBACK_TABS.WRITEBACK.id : WRITEBACK_TABS.WITHOUT_WRITEBACK.id
  );

  if (!otherFieldElements) {
    return null;
  }

  return (
    <div css={containerStyle}>
      <Heading level={2} css={headingStyle}>
        {t('Medical History')}
      </Heading>
      <Tabs initialTab={activeTab} onChange={setActiveTab}>
        <Tabs.Bar fullWidth css={{ marginBottom: theme.spacing(2) }}>
          {shouldShowMedicalHistoryFields && (
            <Tabs.Tab id={WRITEBACK_TABS.WRITEBACK.id} controls={WRITEBACK_TABS.WRITEBACK.control}>
              {t('Writeback')}
            </Tabs.Tab>
          )}
          <Tabs.Tab id={WRITEBACK_TABS.WITHOUT_WRITEBACK.id} controls={WRITEBACK_TABS.WITHOUT_WRITEBACK.control}>
            {t("Doesn't Writeback")}
          </Tabs.Tab>
        </Tabs.Bar>

        {shouldShowMedicalHistoryFields && (
          <Tabs.Panel id={WRITEBACK_TABS.WRITEBACK.control} controller={WRITEBACK_TABS.WRITEBACK.id}>
            <SyncableMedicalHistoryFields />
          </Tabs.Panel>
        )}

        <Tabs.Panel id={WRITEBACK_TABS.WITHOUT_WRITEBACK.control} controller={WRITEBACK_TABS.WITHOUT_WRITEBACK.id}>
          <BannerNotification
            css={css`
              margin-bottom: ${theme.spacing(2)};
            `}
            status='info'
            message={'These fields do not sync to your PMS'}
          />
          <CategoriesLayout categories={filteredCategories} fields={otherFieldElements} type='other' />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};
