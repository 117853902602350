import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useNavSize } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Heading, emptyStateGraphics, Text, Button } from '@frontend/design-system';
import { DEVICE_LAYOUT_WIDTH } from '../constants';

type Props = {
  previewOnly?: boolean;
  setShowDeviceLayoutPreview?: (val: boolean) => void;
};
export const EmptyStateLayout = ({ previewOnly, setShowDeviceLayoutPreview }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });
  const navSize = useNavSize();
  const isMobile = navSize.isLte('medium');

  return (
    <div
      css={css`
        ${!isMobile && 'margin: auto;'}
      `}
    >
      {isMobile && previewOnly && (
        <Button iconName='back' variant='secondary' size='large' onClick={() => setShowDeviceLayoutPreview?.(false)}>
          {t('Back')}
        </Button>
      )}
      <div
        css={css`
          margin: auto;
          max-width: ${DEVICE_LAYOUT_WIDTH};
        `}
      >
        <emptyStateGraphics.sync_your_phone
          width={200}
          height={200}
          css={{ margin: 'auto', marginBottom: theme.spacing(2) }}
        />
        <Heading
          level={3}
          textAlign='center'
          css={css`
            color: ${theme.colors.neutral50};
          `}
        >
          {t('No Device Preview Available')}
        </Heading>
        <Text
          textAlign='center'
          css={css`
            max-width: 400px;
            margin: auto;
            color: ${theme.colors.neutral50};
          `}
        >
          {t(
            'We cannot determine the model type or the number of Line Keys that can be added for these devices. Configurations may appear differently on the device once they are saved.'
          )}
        </Text>
      </div>
    </div>
  );
};
