import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash-es';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { useTranslation } from '@frontend/i18n';
import { PickerLocation } from '@frontend/scope';
import { Modal, Text, useAlert } from '@frontend/design-system';
import { trackingId } from '../../tracking';
import { ExtendedCallRoute } from '../../views/settings/types';
import { AddedExtension, ExtensionsAdder } from './extensions-adder';

export const EditExtensionsModal = ({
  callRoute,
  onClose,
  tenantLocation,
}: {
  callRoute: ExtendedCallRoute;
  tenantLocation: PickerLocation;
  onClose: () => void;
}) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();

  const [extensions, setExtensions] = useState<AddedExtension[]>([]);
  // The originalExtensions state is used to store the initial extensions of the call route
  // so that we can compare the initial extensions with the updated extensions to determine
  // if there are any changes.
  const [originalExtensions, setOriginalExtensions] = useState<AddedExtension[]>();

  useEffect(() => {
    if (originalExtensions === undefined && !!extensions.length) {
      setOriginalExtensions(extensions);
    }
  }, [extensions]);

  const { mutate: updateCallRouteExtensions, isLoading: updateCallRouteExtensionsIsLoading } =
    CallRouteV1.Mutations.useUpdateExtensionsMutation({
      options: {
        onSuccess: () => {
          alerts.success(t('Extensions updated successfully.'));
          onClose();
        },
        onError: () => {
          alerts.error(t('Failed to update Extensions.'));
        },
      },
    });

  const validExtensions = useMemo(() => extensions.filter((extension) => extension.isValid), [extensions]);

  const hasChanges = useMemo(
    () =>
      !isEqual(
        originalExtensions?.map((value) => JSON.stringify(value)),
        validExtensions.map((value) => JSON.stringify(value))
      ),
    [extensions, originalExtensions]
  );

  const handleSave = () => {
    const validExtensionsNumbers = validExtensions.map((extension) => Number(extension.value));
    const uniqueExtensions = [...new Set(validExtensionsNumbers)];

    updateCallRouteExtensions({
      callRouteId: callRoute.callRouteId,
      extensionNumbers: uniqueExtensions,
    });
  };

  const shouldDisableButton = () => {
    // Check if there are any extensions that are invalid. If there is only one extension,
    // it is allowed to be invalid since that could mean the user is removing all extensions.
    const hasInvalidExtensions = extensions.some((extension) => !extension.isValid) && !(validExtensions.length === 0);

    return !hasChanges || updateCallRouteExtensionsIsLoading || hasInvalidExtensions;
  };

  return (
    <>
      <Modal.Header onClose={onClose}>{t('Edit Extensions')}</Modal.Header>
      <Modal.Body>
        <Text color='light'>{callRoute.name}</Text>
        <Text>
          {t(
            'Assign extensions to this Call Route so that your staff can call or transfer callers to this Call Route.'
          )}
        </Text>
        <ExtensionsAdder
          extensions={extensions}
          setExtensions={setExtensions}
          tenantLocation={tenantLocation}
          initialExtensions={callRoute.extensionNumbers.map((extension) => String(extension))}
          trackingIdBase={trackingId({ context: 'setting', feature: 'call-routes', details: 'edit-extensions' })}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Save')}
        onPrimaryClick={handleSave}
        primaryTrackingId={trackingId({
          context: 'setting',
          feature: 'call-routes',
          details: 'edit-extensions-save-btn',
        })}
        disablePrimary={shouldDisableButton()}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={onClose}
        secondaryTrackingId={trackingId({
          context: 'setting',
          feature: 'call-routes',
          details: 'edit-extensions-cancel-btn',
        })}
      />
    </>
  );
};
