import React from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Button, ButtonProps, Heading, HeadingProps } from '@frontend/design-system';
import { usePanelContext } from './context';
import { usePanelStore } from './store';

interface PanelHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
}

const Title: React.FC<HeadingProps> = ({ className, children, ...rest }) => <Heading {...rest}>{children}</Heading>;

const Close: React.FC<ButtonProps> = ({ className, ...rest }) => {
  const { id } = usePanelContext();
  const closePanel = usePanelStore((state) => state.panelCloseFunctions[id]);

  if (!closePanel) return null;

  return (
    <Button
      variant='secondary'
      onClick={closePanel}
      css={css`
        padding: ${theme.spacing(1)};
        border-radius: ${theme.borderRadius.medium};
        transition: background-color 200ms;
        &:hover {
          background-color: ${theme.colors.neutral10};
        }
      `}
      aria-label='Close panel'
      iconName='x-small'
      {...rest}
    />
  );
};

const PanelHeader: React.FC<PanelHeaderProps> & {
  Title: typeof Title;
  Close: typeof Close;
} = ({ children, ...rest }) => {
  return (
    <div
      css={css`
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: ${theme.spacing(4)} ${theme.spacing(4)};
        background-color: ${theme.colors.white};
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

PanelHeader.Title = Title;
PanelHeader.Close = Close;

interface PanelContentProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  padding?: boolean;
}

const PanelContent: React.FC<PanelContentProps> = ({ children, padding = true, ...rest }) => {
  return (
    <div
      css={css`
        flex: 1;
        overflow-y: auto;
        background-color: ${theme.colors.white};
        padding: ${padding ? theme.spacing(4) : 0};
      `}
      {...rest}
    >
      {children}
    </div>
  );
};

export { PanelHeader, PanelContent };
