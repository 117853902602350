import { useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { VoicemailBox } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/voicemail.pb';
import { ViewStatus } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/voicemail.pb';
import { UseInfiniteQueryResult } from 'react-query';
import { VoicemailApi, VoicemailTypes as VmTypes, VoicemailTypes } from '@frontend/api-voicemails';
import { ActionsUI } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useScopedQuery } from '@frontend/scope';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Table, TableInstance, useModalControl } from '@frontend/design-system';
import { CachedAudioScrubber } from '../../components/all-calls/cached-audio-scrubber';
import { ProfileCard } from '../../components/all-calls/profile-card';
import { Action, CustomActionCell } from '../../components/all-calls/shared-buttons';
import { VoicemailQueryData, VoicemailTableDataRow } from '../../components/all-calls/types';
import { TranscriptionContext } from '../../components/transcription/types';
import { downloadVoicemail } from '../../components/voicemails/voicemail-utils';
import { Forwardvoicemail } from '../../components/voicemails/voicemails-forward';
import {
  defaultForwardVoicemailModalData,
  ForwardVoicemailDynamicData,
  ForwardVoicemailModalData,
  resetTranscriptPanel,
  SetPageConfigInput,
  TagColumn,
  useShowTranscriptionPanel,
} from '../../components/voicemails/voicemails-multi.component';
import {
  AllCallsTable,
  usePhonePageSettingShallowStore,
  VoicemailFiltersType,
} from '../../hooks/use-phone-config-state-store';
import { usePhoneScopeStore } from '../../hooks/use-phone-scope-store';
import { queryKeys } from '../../query-keys';
import { getActionIcon } from '../../ui-utils';
import { getShortHandName } from '../utils';

export const ListView = ({
  filters,
  pageSize,
  voicemailDataQuery,
  onDeleteVoicemail,
  updateReadStatus,
  trackingId,
}: {
  filters: VoicemailFiltersType;
  pageSize: number;
  voicemailDataQuery: UseInfiniteQueryResult<VoicemailQueryData, unknown>;
  onDeleteVoicemail: (payload: VmTypes.DeletePayload) => void;
  updateReadStatus: (payload: VoicemailTypes.StatusPayload) => Promise<VoicemailTypes.SetVoicemailViewStatusResponse>;
  trackingId: string;
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'unread-voicemail-widget' });
  const { selectedLocationIdsExcludingParent } = usePhoneScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  const { setPersonId } = useContactPanelShallowStore('setPersonId');

  const { show, panelType, context, setShow } = useSlidePanelShallowStore<TranscriptionContext>(
    'setShow',
    'context',
    'panelType',
    'show'
  );

  const { config, setPageNumber } = usePhonePageSettingShallowStore('config', 'setPageNumber');

  const pageNumber = config[AllCallsTable.Unread_Voicemails].pageNumber;

  useEffect(() => {
    setPageNumber(AllCallsTable.Unread_Voicemails, 1);
  }, []);

  const { data: voicemailBoxList } = useScopedQuery({
    queryKey: queryKeys.listVoicemailBox(phoneConfig?.sipProfileId ?? ''),
    queryFn: async () => {
      const { voicemailBoxes: data } = await VoicemailApi.listVoicemailBox({
        locationIds: selectedLocationIdsExcludingParent,
        sipProfileId: phoneConfig?.sipProfileId,
        includeNonSharedMailboxes: true,
      });
      return data ?? [];
    },
    select: (data: VoicemailBox[]) => {
      return (
        data?.sort((a, b) => {
          return a?.mailbox?.name.localeCompare(b?.mailbox?.name ?? '') || 0;
        }) ?? []
      );
    },
  });

  const searchQuery = useMemo(
    () =>
      `${JSON.stringify({
        locationIds: filters.locationIds,
        sipProfileId: filters.syncDeviceSipProfileId,
      })}`,
    [filters.locationIds, filters.syncDeviceSipProfileId]
  );
  const { data: voicemailsData, isFetching, fetchNextPage, refetch } = voicemailDataQuery;

  const setPageConfig = (input: SetPageConfigInput) => {
    const data = typeof input === 'function' ? input({ pageSize, pageNumber }) : input;
    if (data.pageNumber) setPageNumber(AllCallsTable.Unread_Voicemails, data.pageNumber);
  };

  const getHasNext = () => {
    const pageMeta = voicemailsData?.pages[pageNumber - 1]?.meta;
    return pageMeta?.hasNext;
  };

  const tableInstanceRef = useRef<TableInstance<VoicemailTableDataRow[]>>();
  const tableData = voicemailsData?.pages[pageNumber - 1]?.data || [];

  const [forwardVoicemailModalData, setForwardVoicemailModalData] = useState<ForwardVoicemailModalData>(
    defaultForwardVoicemailModalData as ForwardVoicemailModalData
  );
  const doubleActionControl = useModalControl();

  const openForwardVoicemailModal = (data: ForwardVoicemailDynamicData) => {
    setForwardVoicemailModalData({ ...data, voicemailBoxList: voicemailBoxList || [], onDelete: onDeleteVoicemail });
    doubleActionControl.openModal();
  };

  useEffect(() => {
    /**
     * This effect is to update the slide panel context with the new rows from the new table instance when the table instance changes.
     * The table instance will change if the user navigates away from the voicemail table, while having the transcription panel open.
     *
     * If we don't do this, each row's `toggleRowSelected` will not work as expected, since they are tied to the stale table instance.
     */
    if (show && panelType === 'voicemailTranscription' && tableInstanceRef.current) {
      const existingRow = context?.currentRowId ? tableInstanceRef.current?.rowsById[context.currentRowId] : null;

      if (existingRow) {
        if (!existingRow.isSelected) {
          existingRow.toggleRowSelected(true);
        }
        setShow(true, 'voicemailTranscription', {
          updateReadStatus: updateReadStatus,
          deleteVoicemail: onDeleteVoicemail,
          currentRowId: existingRow.id,
          instanceId: tableInstanceRef.current?.tableId ?? '',
          rows: tableData.map((row) => ({ ...row })),
          toggleRowSelected: tableInstanceRef.current.toggleRowSelected,
          voicemailsQueryKey: queryKeys.unreadVoicemails(searchQuery).filter(Boolean),
          openForwardVoicemailModal: openForwardVoicemailModal,
        });
      } else {
        resetTranscriptPanel(setShow, context);
      }
    }
  }, [tableInstanceRef.current?.tableId, tableData]);

  return (
    <div css={styles.wrapper}>
      <Forwardvoicemail
        modalProps={doubleActionControl.modalProps}
        forwardData={forwardVoicemailModalData}
        onForwardSuccess={() => {
          voicemailDataQuery.refetch();
        }}
      />

      <Table
        uniqueRowId={(row, index) => {
          return row ? `${row.channelId}-${row.mailboxId}-${row.mediaId}` : `${index}`;
        }}
        tableInstanceId='unread-voicemails'
        instanceRef={tableInstanceRef}
        data={tableData}
        keepPreviousDataWhileLoading={tableData.length > 0}
        isLoading={isFetching}
        isPaginated
        hasResizeColumns
        fullHeight
        fullHeightConfig={{
          minHeight: 230,
        }}
        styleConfig={{
          columns: [
            {
              id: '*',
              headerStyler: css`
                border-right: none !important;
                border-left: none !important;
              `,
            },
          ],
        }}
        emptyStateConfig={{
          type: 'voicemail',
          header: 'No Items to Display',
          description: 'Voicemails will display here.',
        }}
        colConfig={[
          {
            Header: t('Contact Name'),
            accessor: (row) => row,
            cellRenderer: (_, row) =>
              row ? (
                <ProfileCard
                  trackingId={`phone-unreadvoicemails-profilebtn-tablerowaction-viewprofile`}
                  firstName={row.firstName}
                  lastName={row.lastName}
                  profileName={row.contactName}
                  defaultName={t('Unknown')}
                  locationId={row.locationIds[0]}
                  personID={row.contactId}
                  markedUnread={!row.readAt}
                />
              ) : null,
            disableSortBy: true,
            disableColumnFilter: true,
            id: 'contactName',
            mobileLayoutConfig: {
              order: -1,
              spanFullWidth: false,
              hideHeader: true,
              leftShift: 48,
            },
            width: 190,
          },
          {
            Header: t('Voicemail'),
            cellRenderer: (_, rowData, row) =>
              rowData ? (
                <>
                  {rowData.mediaFilePath ? (
                    <CachedAudioScrubber
                      onPlay={() => {
                        // There should always be a row
                        if (rowData && row?.index !== undefined) {
                          updateReadStatus({
                            index: row.index,
                            voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                            status: ViewStatus.VIEW_STATUS_READ,
                          });
                        }
                      }}
                      filePath={rowData.mediaFilePath}
                      mediaId={rowData.mediaId}
                    />
                  ) : (
                    <span css={styles.noRecordingText}>{'--'}</span>
                  )}
                </>
              ) : null,
            disableSortBy: true,
            id: 'voicemailMessage',
            mobileLayoutConfig: {
              spanFullWidth: true,
            },
            minWidth: 140,
          },
          {
            Header: t('Time'),
            accessor: 'dateTime',
            disableSortBy: true,
            id: 'dateTime',
            mobileLayoutConfig: {
              spanFullWidth: false,
            },
            minWidth: 100,
          },
          {
            Header: t('Tags'),
            accessor: (row) => row,
            id: 'tags',
            cellRenderer: ({ tags, voicemailId }, _rowData, row, _1, _2, tableInstance) => {
              const showPanel = useShowTranscriptionPanel({
                row,
                tableInstance,
                updateReadStatus: updateReadStatus,
                deleteVoicemail: onDeleteVoicemail,
                voicemailsQueryKey: queryKeys.unreadVoicemails(searchQuery).filter(Boolean),
                sectionFocus: ['tags'],
                openForwardVoicemailModal: openForwardVoicemailModal,
              });

              let isEditing = false;
              if (row?.state && 'isEditing' in row.state && typeof row.state.isEditing === 'boolean') {
                isEditing = row.state.isEditing;
              }

              return row ? (
                <TagColumn
                  tags={tags}
                  voicemailId={voicemailId}
                  showPanel={showPanel}
                  queryKey={queryKeys.unreadVoicemails(searchQuery).filter(Boolean)}
                  isEditing={isEditing}
                  setIsEditing={(val) => row.setState({ isEditing: val })}
                />
              ) : null;
            },
            mobileLayoutConfig: {
              spanFullWidth: false,
            },
            width: 130,
            disableSortBy: true,
          },
          {
            Header: '',
            cellRenderer: (_, rowData, row, _1, _2, tableInstance) => {
              if (!rowData) return null;

              const { triggerProps: callTriggerProps, Modal: PhoneCallModal } = ActionsUI.actions.usePhoneCallAction({
                context: {
                  personId: rowData.contactId,
                  phoneNumber: rowData.contactNumber,
                  locationId: selectedLocationIdsExcludingParent[0] ?? '',
                },
              });

              const {
                triggerProps: messageTriggerProps,
                Modal: MessageModal,
                disabled: disableMessageAction,
              } = ActionsUI.actions.useMessageAction({
                context: {
                  personId: rowData.contactId,
                  phoneNumber: rowData.contactNumber,
                  locationId: selectedLocationIdsExcludingParent[0] ?? '',
                },
              });

              const showPanel = useShowTranscriptionPanel({
                row,
                tableInstance,
                updateReadStatus,
                deleteVoicemail: onDeleteVoicemail,
                voicemailsQueryKey: queryKeys.unreadVoicemails(searchQuery).filter(Boolean),
                openForwardVoicemailModal,
              });

              const actions: Action[] = [
                {
                  Icon: () => getActionIcon({ iconName: 'phone' }),
                  label: `${t('Call')} ${getShortHandName(rowData)}`,
                  trackingId: `${trackingId}-action-btn-tablerowaction-call`,
                  onClick: () => {
                    callTriggerProps?.onClick();
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'message' }),
                  disabled: disableMessageAction,
                  label: `${t('Message')} ${getShortHandName(rowData)}`,
                  trackingId: `${trackingId}-action-btn-tablerowaction-message`,
                  onClick: () => {
                    messageTriggerProps?.onClick();
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'voicemail-transcription' }),
                  label: t('View Transcript'),
                  trackingId: `${trackingId}-action-btn-tablerowaction-viewtranscript`,
                  onClick: () => {
                    if (show && panelType === 'voicemailTranscription' && context?.currentRowId === row?.id) {
                      resetTranscriptPanel(setShow, context);
                    } else {
                      showPanel();
                    }
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'user' }),
                  label: t('View Profile'),
                  trackingId: `${trackingId}-action-btn-tablerowaction-viewprofile`,
                  disabled: !rowData.contactId,
                  onClick: () => {
                    if (!!rowData.contactId) {
                      setPersonId(rowData.contactId, true, selectedLocationIdsExcludingParent[0]);
                      setShow(true, 'contact');
                    }
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'voicemail-override' }),
                  label: t('Forward Voicemail'),
                  trackingId: `${trackingId}-action-btn-tablerowaction-forwardvoicemail`,
                  onClick: () => {
                    openForwardVoicemailModal({
                      rowIndex: row?.index,
                      vmId: rowData.voicemailId == '' ? rowData.channelId : rowData.voicemailId,
                      mediaId: rowData.mediaId,
                      mediaPath: rowData.mediaFilePath,
                      currentVoicemailBoxId: rowData.mailboxId,
                      currentVoicemailBoxName: rowData.voiceMailBoxName,
                    } as ForwardVoicemailDynamicData);
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'label' }),
                  label: t('Add Tag'),
                  trackingId: `${trackingId}-action-btn-tablerowaction-tagvoicemail`,
                  onClick: () => {
                    if (row) {
                      row.setState({ isEditing: true });
                    }
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'download' }),
                  label: t('Download Voicemail'),
                  trackingId: `${trackingId}-action-btn-tablerowaction-downloadvoicemail`,
                  onClick: () => {
                    downloadVoicemail(rowData.mediaFilePath, rowData.contactName ?? 'unknown', rowData.dateTime);
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'mark-unread' }),
                  label: t('Mark Unread'),
                  trackingId: `${trackingId}-action-btn-tablerowaction-togglestatus`,
                  hide: !rowData.readAt,
                  onClick: () => {
                    if (!!row) {
                      updateReadStatus({
                        index: row.index,
                        voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                        status: ViewStatus.VIEW_STATUS_UNREAD,
                      });
                    }
                  },
                },
                {
                  Icon: () => getActionIcon({ iconName: 'mark-read' }),
                  label: t('Mark Read'),
                  trackingId: `${trackingId}-action-btn-tablerowaction-togglestatus`,
                  hide: !!rowData.readAt,
                  onClick: () => {
                    if (!!row) {
                      updateReadStatus({
                        index: row.index,
                        voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                        status: ViewStatus.VIEW_STATUS_READ,
                      });
                    }
                  },
                },
                {
                  Icon: (props) => getActionIcon({ iconName: 'trash', color: props.color }),
                  label: t('Delete Voicemail'),
                  trackingId: '${trackingId}-action-btn-tablerowaction-deletevoicemail',
                  destructive: true,
                  onClick: () => {
                    if (!!row) {
                      onDeleteVoicemail({
                        voicemailId: rowData?.forwardedMessageId || rowData.channelId,
                        index: row.index,
                      });
                    }
                  },
                },
              ];

              return (
                <div style={{ width: '100%', display: 'flex', gap: theme.spacing(1), justifyContent: 'flex-end' }}>
                  <CustomActionCell actions={actions} tableTrackingId={'phone-portal-weave2.0-voicemails'} />
                  {PhoneCallModal}
                  {MessageModal}
                </div>
              );
            },
            id: 'actions',
            sticky: 'right',
            width: 70,
            mobileLayoutConfig: {
              hideHeader: true,
              spanFullWidth: false,
              order: -1,
            },
            disableSortBy: true,
          },
        ]}
        manualPaginationConfig={{
          handleChange: (action: 'next' | 'prev') => {
            if (action === 'next') {
              if (voicemailsData?.pages[pageNumber]) {
                refetch<VoicemailQueryData>({
                  refetchPage: (lastPage) => {
                    return lastPage.meta.hasNext && lastPage.data.length < pageSize;
                  },
                });
              } else {
                fetchNextPage();
              }
              setPageConfig((prev) => ({ pageNumber: prev.pageNumber + 1 }));
            } else {
              setPageConfig((prev) => ({ pageNumber: prev.pageNumber - 1 }));
            }
          },
          hasNext: getHasNext(),
          hasPrevious: true,
          page: pageNumber,
          defaultRowsPerPage: pageSize,
          showNumRowSelector: false,
        }}
      />
    </div>
  );
};

const styles = {
  wrapper: css`
    min-width: 100%;
    position: relative;
  `,

  personInfo: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,

  noRecordingText: css`
    width: 100%;
    display: inline-block;
    color: ${theme.colors.neutral90};
    text-align: left;
  `,
};
