import { useMutation } from 'react-query';
import { LimitedSchemaMutationOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import { MedicalHistoryAnswerOptionsToggleIO, SyncPMSMedicalConditionsIO } from '../../types';
import { endpointKeys } from '../keys';

export const useSyncMedicalConditions = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<SyncPMSMedicalConditionsIO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.syncMedicalConditions,
    mutationFn: (req) => SchemaDigitalForms.SyncPMSMedicalConditions(req),
    ...options,
  });
};

export const useMedicalHistoryAnswerOptionsToggle = <E = unknown, C = unknown>(
  options?: LimitedSchemaMutationOptions<MedicalHistoryAnswerOptionsToggleIO, E, C>
) => {
  return useMutation({
    mutationKey: endpointKeys.medicalHistoryAnswerOptionsToggle,
    mutationFn: (req) => SchemaDigitalForms.MedicalHistoryAnswerOptionsToggle(req),
    ...options,
  });
};
