import { css } from '@emotion/react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchantsInfo, useMultiQueryUtils } from '@frontend/payments-hooks';
import { StaticSettingsRoutes, useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Heading, IndexRow } from '@frontend/design-system';
import * as settingStyles from '../styles';

const styles = {
  indexRow: css`
    & > article {
      max-width: none;
      width: 100%;
    }
  `,
};

export const MultiPaymentSettings = () => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { anyLocationHasPayments, anyLocationHasFullPayments } = useMerchantsInfo();
  const { selectedLocationIds } = useMultiQueryUtils();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const navigate = (to: StaticSettingsRoutes) => settingsNavigate({ to });

  const { aggregateValue: hasJustifyOnboardingEnabled } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.EnableJustifyOnboarding,
    locationIds: selectedLocationIds,
  });

  return (
    <>
      <section css={[settingStyles.groupMaxWidth, styles.indexRow]}>
        <Heading level={3}>{t('Account Setup')}</Heading>
        <IndexRow>
          <IndexRow.Item onClick={() => navigate(`/payments/account-info`)}>
            <IndexRow.Item.Title>{t('Account Info and Status')}</IndexRow.Item.Title>
          </IndexRow.Item>
          {anyLocationHasPayments && (
            <IndexRow.Item onClick={() => navigate(`/payments/public-business-information`)}>
              <IndexRow.Item.Title>{t('Public Business Information')}</IndexRow.Item.Title>
            </IndexRow.Item>
          )}
          {hasJustifyOnboardingEnabled && (
            <IndexRow.Item onClick={() => navigate(`/payments/justifi-settings`)}>
              <IndexRow.Item.Title>{t('JustiFi Settings')}</IndexRow.Item.Title>
            </IndexRow.Item>
          )}
        </IndexRow>
        {anyLocationHasPayments && (
          <>
            <Heading level={3} css={{ marginTop: theme.spacing(2) }}>
              {t('Payment Collection')}
            </Heading>
            <IndexRow>
              {anyLocationHasFullPayments && (
                <IndexRow.Item onClick={() => navigate(`/payments/terminals`)}>
                  <IndexRow.Item.Title>{t('Terminals')}</IndexRow.Item.Title>
                </IndexRow.Item>
              )}
              <IndexRow.Item onClick={() => navigate(`/payments/payment-methods`)}>
                <IndexRow.Item.Title>{t('Payment Methods')}</IndexRow.Item.Title>
              </IndexRow.Item>
              <IndexRow.Item onClick={() => navigate(`/payments/online-bill-pay`)}>
                <IndexRow.Item.Title>{t('Online Bill Pay')}</IndexRow.Item.Title>
              </IndexRow.Item>
              <IndexRow.Item onClick={() => navigate(`/payments/general-settings`)}>
                <IndexRow.Item.Title>{t('General Settings')}</IndexRow.Item.Title>
              </IndexRow.Item>
              <IndexRow.Item onClick={() => navigate(`/payments/sunbit`)}>
                <IndexRow.Item.Title>{t('Sunbit')}</IndexRow.Item.Title>
              </IndexRow.Item>
            </IndexRow>
          </>
        )}
      </section>
    </>
  );
};
