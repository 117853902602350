import { Text, TextProps, styles, useTooltip } from '@frontend/design-system';

interface Props extends TextProps {
  text?: string;
}

export const TruncatedText = ({ text = '', ...rest }: Props) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip();

  return (
    <>
      <Text css={styles.truncate} {...triggerProps} {...rest}>
        {text}
      </Text>
      <Tooltip {...tooltipProps}>{text}</Tooltip>
    </>
  );
};
