import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, ChipProps } from '@frontend/design-system';
import { SharedNodeProps } from '../data';

export const StartNode = memo(({ data }: SharedNodeProps) => {
  return (
    <div className='nodrag nopan'>
      <PhoneChip tooltipLabel={data.label}>Incoming Call</PhoneChip>
      <Handle
        type='source'
        position={Position.Bottom}
        id={data.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
    </div>
  );
});
StartNode.displayName = 'StartNode';

const PhoneChip = (props: ChipProps) => {
  return (
    <Chip
      maxWidth={'200px'}
      css={{ background: theme.colors.neutral90, color: theme.colors.white }}
      leftElement={<Icon name='phone-call-small' />}
      {...props}
    />
  );
};
