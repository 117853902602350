import { useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Avatar, Heading, NakedButton, Text, Chip } from '@frontend/design-system';
import { useGetCalendarAvailabilities } from '../../../../hooks/booking-site';
import { useBookingSiteStore } from '../../../../stores';
import { getFullName } from '../../../../utils';

interface Props {
  setAvailableOpenings: (numOfOpenings: number) => void;
}

export const ProviderAvailability = ({ setAvailableOpenings }: Props) => {
  const { t } = useTranslation('bookingSite');
  const { isSchedularV3Enabled, selectedProvider, selectedSlots, minSlots, selectedDay, setSelectedSlots } =
    useBookingSiteStore([
      'isSchedularV3Enabled',
      'selectedProvider',
      'selectedSlots',
      'setSelectedSlots',
      'minSlots',
      'selectedDay',
    ]);
  const { totalSlots, openSlotListGroupByDay } = useGetCalendarAvailabilities({
    providerId: selectedProvider === 'any' ? '' : selectedProvider?.id ?? '',
    providerCalendarId: selectedProvider === 'any' ? '' : selectedProvider?.calendarId ?? '',
  });

  useEffect(() => {
    const element = document?.getElementsByClassName(`request-day-${selectedDay}`)[0];
    element?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!!totalSlots && totalSlots < minSlots) {
      setAvailableOpenings(totalSlots);
    }
  }, [totalSlots, minSlots]);

  const handleSelectSlot = (time: string) => {
    let newSelected = [...selectedSlots];
    if (selectedSlots?.includes(time)) {
      newSelected = selectedSlots.filter((t) => t !== time);
    } else {
      if (newSelected.length === minSlots) {
        newSelected.shift();
      } else if (newSelected.length <= minSlots) {
        newSelected = newSelected.slice(0, minSlots - 1);
      }
      newSelected.push(time);
    }
    setSelectedSlots(newSelected ?? []);
  };

  const sortDays = () => {
    return Object.entries(openSlotListGroupByDay).map(([date, times]) => {
      const displayDate = formatDate(date, 'ddd, MMM D');
      const day = formatDate(date, 'dddd');
      if (times.length === 0) return null;
      return (
        <div key={date} css={styles.dateGroup} className={`request-day-${day}`}>
          <Text weight='bold' css={styles.date}>
            {displayDate}
          </Text>
          {[...new Set(times)].map((time: string) => {
            const isAfterLeadTime = dayjs(time).isAfter(dayjs().format());
            if (!isAfterLeadTime) return null;
            return (
              <NakedButton
                key={time}
                onClick={() => handleSelectSlot(time)}
                className={selectedSlots.includes(time) ? 'timeButton selected' : 'timeButton'}
              >
                {formatDate(time, 'h:mm a')}
              </NakedButton>
            );
          })}
        </div>
      );
    });
  };

  return (
    <div css={styles.availability}>
      {selectedProvider === 'any' ? (
        <section className='provider-info'>
          <Avatar src={''} size='xl' />
          <Text weight='bold'>{t('Choose my provider for me')}</Text>
          <Chip css={styles.availabilityChip}>{t('Most Availability')}</Chip>
          <Text>
            {t(
              "Pick a time slot that works best with your schedule. We've got plenty of options available, so no need to stress. Once you've got your time sorted, we'll take care of picking the best provider for you."
            )}
          </Text>
        </section>
      ) : (
        <section className='provider-info'>
          <Avatar
            name={getFullName(selectedProvider) || selectedProvider?.publicDisplayName || ''}
            src={selectedProvider?.publicDisplayImage}
            size='xl'
          />
          <Text weight='bold'>{selectedProvider?.publicDisplayName || getFullName(selectedProvider)}</Text>
        </section>
      )}
      <section className='time-selection'>
        <Heading as='h3' css={styles.heading}>
          {t('Availability')}
        </Heading>
        <Text>
          {isSchedularV3Enabled
            ? t("Choose time slot that fits into your schedule, and we'll reach out to you to confirm")
            : t("Choose {{count}} time slots that fit into your schedule, and we'll reach out to you to confirm", {
                count: (totalSlots < minSlots ? totalSlots : minSlots) || minSlots,
              })}
        </Text>
        <div css={{ overflowY: 'auto', maxHeight: 350 }}>{sortDays()}</div>
      </section>
    </div>
  );
};

const styles = {
  availability: css({
    display: 'flex',
    flexDirection: 'row',
    '@media only screen and (max-width: 650px)': {
      flexDirection: 'column',
    },
    '.provider-info, .time-selection': {
      '@media only screen and (max-width: 650px)': {
        width: '100%',
      },
    },
    '.provider-info': {
      width: '45%',
      padding: theme.spacing(0, 3),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '@media only screen and (max-width: 650px)': {
        padding: 0,
        width: '100%',
      },
    },
    '.time-selection': {
      width: '55%',
      borderLeft: `1px solid ${theme.colors.neutral20}`,
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(2),
      '@media only screen and (max-width: 650px)': {
        padding: theme.spacing(2, 1, 0, 0),
        borderTop: `1px solid ${theme.colors.neutral20}`,
        borderLeft: 'none',
        width: '100%',
      },
    },
  }),
  dateGroup: css({
    margin: theme.spacing(3, 0, 0, 0),
    '.timeButton': {
      fontSize: theme.fontSize(14),
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(0.5),
      border: `1px solid ${theme.colors.neutral50}`,
      borderRadius: theme.borderRadius.small,
    },
    '.timeButton:hover': {
      backgroundColor: theme.colors.neutral10,
    },
    '.selected': {
      border: `2px solid ${theme.colors.primary50}`,
      margin: `calc(${theme.spacing(0.5)} - 1px)`,
    },
  }),
  heading: css({
    fontSize: theme.fontSize(20),
    margin: theme.spacing(0),
  }),
  date: css({
    fontSize: theme.fontSize(16),
    margin: theme.spacing(0),
  }),
  availabilityChip: css({
    maxWidth: 'unset',
  }),
};
