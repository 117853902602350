import { UseMutationOptions } from 'react-query';
import { useMutation } from '@frontend/react-query-helpers';
import { assignContactListToDevices, deleteContact, replaceContactListContacts } from './api';
import { DeleteContactType, ReplaceContactListContactsType, ReplaceDevicesType } from './types';

export const mutationKeys = {
  baseKey: ['contact-directories'],
  replaceContactDirectoryContacts: () => [...mutationKeys.baseKey, 'replace-contact-directory-contacts'],
  assignContactDirectoryToDevices: () => [...mutationKeys.baseKey, 'replace-contact-directory-devices'],
  deleteContact: () => [...mutationKeys.baseKey, 'delete-contact'],
};

type GenericUseMutationOptions<TRequest, TResponse> = UseMutationOptions<TResponse, unknown, TRequest>;

export const useContactDirectoryMutations = () => {
  const useReplaceContactDirectoryContactsMutation = (
    options?: GenericUseMutationOptions<
      ReplaceContactListContactsType['input'],
      ReplaceContactListContactsType['output']
    >
  ) =>
    useMutation({
      mutationKey: mutationKeys.replaceContactDirectoryContacts(),
      mutationFn: ({ ...req }) => {
        const contactDirectoryId = req.contactListId ?? '';
        const contactIds = req.contactIds ?? [];
        return replaceContactListContacts(contactDirectoryId, contactIds);
      },
      ...options,
    });

  const useReplaceContactDirectoryDevicesMutation = (
    options?: GenericUseMutationOptions<ReplaceDevicesType['input'], ReplaceDevicesType['output']>
  ) =>
    useMutation({
      mutationKey: mutationKeys.assignContactDirectoryToDevices(),
      mutationFn: (req) => {
        const contactDirectoryId = req.contactListId ?? '';
        const deviceIds = req.deviceIds ?? [];
        return assignContactListToDevices({ contactListId: contactDirectoryId, deviceIds });
      },
      ...options,
    });

  const useDeleteContactMutation = (
    options?: GenericUseMutationOptions<DeleteContactType['input'], DeleteContactType['output']>
  ) =>
    useMutation({
      mutationKey: mutationKeys.deleteContact(),
      mutationFn: ({ id }) => {
        const contactId = id ?? '';
        return deleteContact(contactId);
      },
      ...options,
    });

  return {
    useReplaceContactDirectoryContactsMutation,
    useReplaceContactDirectoryDevicesMutation,
    useDeleteContactMutation,
  };
};
