import { useMemo, useState, useRef, MutableRefObject } from 'react';
import { css } from '@emotion/react';
import { Reorder, useDragControls, motion, VariantLabels } from 'motion/react';
import { onlyText } from 'react-children-utilities';
import type { ColumnInstance, IdType, TableInstance } from 'react-table';
import { IconButton, usePopoverDialog, PopoverDialog, CheckboxField, useFormField, Text, TextLink } from '../..';
import { useThemeValues } from '../../../hooks';
import { ColumnsIcon, HamburgerMenuIcon } from '../../../icon';
import { useStyles } from '../../../use-styles';
import { TableData } from '../table-data-type';
import { isReservedCellId, persistUserPreference } from '../utils';

type FilterColumnsContainerProps<T extends TableData> = {
  tableInstance: TableInstance<T>;
  isFilterEnabled?: boolean;
};

export const FilterColumnsContainer = <T extends TableData>({
  tableInstance,
  isFilterEnabled,
}: FilterColumnsContainerProps<T>) => {
  const {
    state: { hiddenColumns },
    filterColumnsConfig,
    tableTrackingIds,
  } = tableInstance;
  const notificationBadgeStyles = useStyles('TableCommonStyles', 'filterNotificationBadgeStyles');
  const { borderRadius, spacing } = useThemeValues();
  const { getTriggerProps, getDialogProps } = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'bottom-end',
    initialOffset: { x: -290, y: 40 },
  });

  const notificationStyle = () => [
    notificationBadgeStyles,
    css`
      ::after {
        opacity: ${hiddenColumns?.length ? 1 : 0};
      }
    `,
  ];

  return (
    <section
      css={[
        css`
          position: relative;
        `,
        isFilterEnabled &&
          css`
            margin-left: ${spacing(1)};
          `,
      ]}
    >
      <IconButton
        showLabelOnHover
        disabled={tableInstance.isLoading}
        css={notificationStyle()}
        label={filterColumnsConfig?.label ?? 'Filter Columns'}
        color='light'
        trackingId={tableTrackingIds.filterColumns}
        data-test-id={tableTrackingIds.filterColumns}
        {...getTriggerProps()}
      >
        <ColumnsIcon />
      </IconButton>
      <PopoverDialog
        {...getDialogProps()}
        css={css`
          width: 330px;
          border-radius: ${borderRadius.medium};
          padding: ${spacing(1, 0)};
        `}
      >
        <ColumnFilters tableInstance={tableInstance} />
      </PopoverDialog>
    </section>
  );
};

type ColumnFilters<T extends TableData> = {
  tableInstance: TableInstance<T>;
};

export const ColumnFilters = <T extends TableData>({ tableInstance }: ColumnFilters<T>) => {
  const filterColumnStyle = useStyles('TableCommonStyles', 'filterColumnStyles');

  const {
    allColumns,
    toggleHideAllColumns,
    visibleColumns,
    state: { hiddenColumns },
    uniqueFilterColumnsId,
    uniqueOrderColumnsId,
    filterColumnsConfig,
    setColumnOrder,
  } = tableInstance;

  const nonActionColumns = useMemo(() => {
    return allColumns.filter((column) => !isReservedCellId(column.id)).length;
  }, [allColumns.length]);

  const nonActionVisibleColumns = useMemo(() => {
    return visibleColumns.filter((column) => !isReservedCellId(column.id)).length;
  }, [visibleColumns.length]);

  const columnsIds = allColumns.map((column) => column.id);

  const [columnList, setColumnList] = useState(columnsIds);
  const [isDragging, setIsDragging] = useState(false);
  const constraintRef = useRef<HTMLUListElement | null>(null);

  return (
    <motion.article
      initial={false}
      layout='position'
      transition={{
        layout: {
          duration: 0,
        },
      }}
      layoutScroll
      css={css`
        width: 100%;
      `}
    >
      <section css={filterColumnStyle}>
        <Text weight='semibold' color='light'>
          {filterColumnsConfig?.label ?? 'Filter Columns'}
        </Text>
        <TextLink
          disabled={nonActionVisibleColumns === nonActionColumns}
          weight='semibold'
          onClick={() => {
            toggleHideAllColumns(false);
            window.localStorage.removeItem(uniqueFilterColumnsId ?? '');
          }}
        >
          {filterColumnsConfig?.showAllLabel ?? 'Show All'}
        </TextLink>
      </section>
      <Reorder.Group
        transition={{
          layout: {
            duration: 0,
          },
        }}
        initial={false}
        layout='position'
        css={css`
          position: relative;
          padding: 0;
        `}
        axis='y'
        ref={constraintRef}
        values={columnList}
        onReorder={setColumnList}
      >
        {columnList.map((listColumnId) => (
          <ColumnFilterItem
            allColumns={allColumns}
            columnList={columnList}
            hiddenColumns={hiddenColumns}
            listColumnId={listColumnId}
            setColumnOrder={setColumnOrder}
            uniqueFilterColumnsId={uniqueFilterColumnsId}
            uniqueOrderColumnsId={uniqueOrderColumnsId}
            constraintRef={constraintRef}
            setIsDragging={setIsDragging}
            isDragging={isDragging}
            key={listColumnId}
          />
        ))}
      </Reorder.Group>
    </motion.article>
  );
};

type ColumnFilterItem<T extends TableData> = {
  allColumns: ColumnInstance<T>[];
  columnList: IdType<T>[];
  hiddenColumns: IdType<T>[] | undefined;
  listColumnId: IdType<T>;
  setColumnOrder: TableInstance<T>['setColumnOrder'];
  uniqueFilterColumnsId: string | undefined;
  uniqueOrderColumnsId: string | undefined;
  constraintRef: MutableRefObject<HTMLUListElement | null>;
  setIsDragging: (isDragging: boolean) => void;
  isDragging: boolean;
};

export const ColumnFilterItem = <T extends TableData>({
  listColumnId,
  setColumnOrder,
  allColumns,
  uniqueOrderColumnsId,
  uniqueFilterColumnsId,
  columnList,
  hiddenColumns,
  constraintRef,
  setIsDragging,
  isDragging,
}: ColumnFilterItem<T>) => {
  const { spacing, colors } = useThemeValues();
  const reorderColumnWhileTapStyles = useStyles('TableCommonStyles', 'reorderColumnWhileTapStyles');

  const { Header, headerLabel, disableColumnFilter, toggleHidden, id, isVisible } = allColumns.find(
    (column) => column.id === listColumnId
  ) as ColumnInstance<T>;
  const dragControls = useDragControls();
  const fieldProps = useFormField({ type: 'checkbox', value: isVisible }, [isVisible]);

  if ((typeof Header === 'function' && !headerLabel) || isReservedCellId(id) || disableColumnFilter) return null;

  const onDragEnd = () => {
    const stickyColumnLeftLists = allColumns.filter((column) => column?.sticky == 'left').map((column) => column.id);
    const stickyColumnRightLists = allColumns.filter((column) => column?.sticky == 'right').map((column) => column.id);

    setColumnOrder([...stickyColumnLeftLists, ...columnList, ...stickyColumnRightLists]);
    persistUserPreference(uniqueOrderColumnsId, columnList);
    setIsDragging(false);
  };

  return (
    <Reorder.Item
      initial={false}
      key={listColumnId}
      id={listColumnId}
      value={listColumnId}
      dragListener={false}
      dragElastic={0.05}
      as={'ul'}
      dragConstraints={constraintRef}
      dragControls={dragControls}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={onDragEnd}
      whileTap={reorderColumnWhileTapStyles as VariantLabels}
      style={{
        position: 'relative',
        minHeight: spacing(5),
        listStyle: 'none',
        display: 'flex',
        alignItems: 'center',
        padding: spacing(1, 2),
        background: colors.white,
      }}
    >
      <HamburgerMenuIcon
        size={20}
        color='light'
        onPointerDown={(event) => dragControls.start(event)}
        css={css`
          margin-right: ${spacing(1.5)};
          cursor: ${isDragging ? 'grabbing' : 'grab'};
        `}
      />

      <CheckboxField
        {...fieldProps}
        name={headerLabel ?? onlyText(Header as React.ReactNode)}
        label={headerLabel ?? onlyText(Header as React.ReactNode)}
        labelPlacement='left'
        onChange={() => {
          if (!hiddenColumns || !uniqueFilterColumnsId) return;

          toggleHidden(fieldProps.value);

          const hiddenColumnsCurrent = fieldProps.value
            ? [...hiddenColumns, id]
            : hiddenColumns.filter((hiddenColumn) => hiddenColumn !== id);

          if (!hiddenColumnsCurrent.length) {
            window.localStorage.removeItem(uniqueFilterColumnsId);
            return;
          }

          persistUserPreference(uniqueFilterColumnsId, hiddenColumnsCurrent);
        }}
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
        `}
      />
    </Reorder.Item>
  );
};
