import { useEffect, useMemo, useState } from 'react';
import { User } from '@sentry/browser';
import { getLocationsForOrgIds, getLocationOrgIds, getOrgsData, locationApiQueryKeys } from '@frontend/api-location';
import { transformMultiOrgTenantsResults, usePhoneTenantsQuery } from '@frontend/api-tenants';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useLocationDataShallowStore, ExtendedLocationResult } from '@frontend/location-helpers';
import { useQuery } from '@frontend/react-query-helpers';
import { restructureList, useAppScopeStore, PickerLocation, type OrgIdMap } from '@frontend/scope';
import { transformLocationData, combineLocationWithTenantIds } from './location-pickers/shared/utils';

export const useQueryLocations = ({ user, locationIds }: { user?: User; locationIds: string[] }) => {
  const [isInitialized, setIsInitialized] = useState(false);
  const { setRegisteredLocations } = useLocationDataShallowStore('setRegisteredLocations');

  const { setAccessibleLocationData, setOrgIds, setOrgIdMap, selectedOrgId, setSelectedOrgId, selectedLocationIds } =
    useAppScopeStore();

  const isAWeaveUser = useMemo(() => isWeaveUser(), []);

  const { data: orgIdsRes } = useQuery({
    queryKey: ['orgIds', user?.userID],
    queryFn: async () => getLocationOrgIds(),
    enabled: !isAWeaveUser,
  });

  const { data: phoneTenants, isLoading: isLoadingTenants } = usePhoneTenantsQuery({
    userId: user?.userID,
    orgIds: orgIdsRes?.orgId ?? [],
    select: (data) => transformMultiOrgTenantsResults({ orgIds: orgIdsRes?.orgId ?? [], tenantsResponse: data }),
    enabled: !!orgIdsRes?.orgId && !!orgIdsRes?.orgId.length,
  });

  const { data: locationsResponse } = useQuery({
    queryKey: locationApiQueryKeys.userLocations(user?.userID),
    queryFn: async () => {
      if (!orgIdsRes?.orgId) return [];
      const locations = await getLocationsForOrgIds(orgIdsRes?.orgId);
      if (locations) {
        const res = locations
          .map(({ locations }) => locations)
          .filter(Boolean)
          .flat()
          // Map (and cast) so that it works with the `restructureList` method
          .map(
            (location) =>
              ({
                ...location,
                locationID: location.locationId,
                parentID: location.parentId,
              } as PickerLocation)
          )
          .sort((a, b) => (a.name && b.name ? a.name.localeCompare(b.name) : 0));
        const locationsWithTenantIds = combineLocationWithTenantIds(res, phoneTenants ?? {});
        const list = restructureList(locationsWithTenantIds ?? []);

        // This endpoint returns all locations in an org - we need to filter down to the ones the user can actually access
        return (
          list
            .map((location) => {
              const newLocation = { ...location };
              if (newLocation.children?.length) {
                newLocation.children = newLocation.children.filter((child) => locationIds.includes(child.locationID));
                /**
                 * We need to keep parent locations around if they have children locations that are accessible.
                 * (This will affect how they show up in the location picker, and the phone settings tenant picker)
                 *
                 * However, we mark them as hidden so we can still filter them out where they should not be shown.
                 */
                newLocation.hidden = !locationIds.includes(location.locationID) && newLocation.children.length > 0;
              }
              return newLocation;
            })
            /**
             * Filter out locations that are not accessible to the user.
             * But keep parent locations that have children locations that are accessible.
             */
            .filter((location) => locationIds.includes(location.locationID) || location.hidden)
        );
      }

      return [];
    },
    enabled: !!orgIdsRes?.orgId && !isLoadingTenants,
  });

  useQuery({
    queryKey: ['orgsData', user?.userID],
    queryFn: async () => {
      const locationForHeader = locationsResponse?.find((loc) => !loc.hidden);
      if (!orgIdsRes?.orgId || !locationForHeader) return [];
      const orgsData = await getOrgsData(orgIdsRes?.orgId, locationForHeader.locationID);
      return orgsData;
    },
    enabled: !!orgIdsRes?.orgId && !!orgIdsRes?.orgId.length && !!locationsResponse,
  });

  const locations = useMemo(() => {
    if (!locationsResponse || !phoneTenants) return;
    return combineLocationWithTenantIds(locationsResponse, phoneTenants);
  }, [locationsResponse, phoneTenants]);

  /* If there's only one organization or location selected, set the organization of that location as the selected organization.
   * This happens only first time, when the user doesn't have an organization selected yet, or in localStorage.
   * If the user has only access to one org, select it automatically.
   */
  useEffect(() => {
    if (!selectedOrgId && orgIdsRes?.orgId?.length === 1) {
      setSelectedOrgId(orgIdsRes?.orgId[0]);
      return;
    }

    if (!selectedOrgId && locations && locations?.length > 0 && selectedLocationIds?.length === 1) {
      const location = locations.find((location) => location.locationID === selectedLocationIds[0]);
      if (location) {
        setSelectedOrgId(location.orgId ?? '');
      }
    }
  }, [locations, orgIdsRes?.orgId, setSelectedOrgId, selectedLocationIds, selectedOrgId]);

  useEffect(() => {
    if (locations) {
      setRegisteredLocations(locations as ExtendedLocationResult[]);
      const accessibleLocationsData = transformLocationData(locations);

      const orgIdLocationMap = locations.reduce((acc, location) => {
        if (!location.orgId) return acc;
        if (orgIdsRes?.orgId?.includes(location.orgId)) {
          if (!acc[location.orgId]) {
            acc[location.orgId] = {
              parents: [],
              locations: [],
            };
          }
          if (location?.children?.length) {
            acc[location.orgId].parents.push(location);
          } else {
            acc[location.orgId].locations.push(location);
          }
        }
        return acc;
      }, {} as OrgIdMap);

      /**
       * If the user has access only to the parent location/s of an organization,
       * set those parent locations as actual locations
       * */

      orgIdsRes?.orgId?.forEach((orgId) => {
        if (orgIdLocationMap?.[orgId]?.locations.length === 0) {
          orgIdLocationMap[orgId].locations = orgIdLocationMap[orgId].parents;
        }
      });

      setOrgIdMap(orgIdLocationMap);
      setAccessibleLocationData(accessibleLocationsData);
      setOrgIds(orgIdsRes?.orgId ?? []);
      setIsInitialized(true);
    }
  }, [locations, orgIdsRes?.orgId, setAccessibleLocationData, setOrgIds, setRegisteredLocations]);

  return { isInitialized };
};
