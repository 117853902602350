import { FC } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { default as OnlineSchedulingBannerImg } from '../../../assets/online-scheduling-banner.png';
import { SUBSCRIPTION_SETTINGS_URL } from '../../../constants/self-upgrade';
import { getScheduleDemoLink } from '../../../self-upgrade.util';
import { FeatureUpgradeModalComponentProps } from '../../../types';
import { FeatureUpgradeDetailsBaseModal } from '../FeatureUpgradeDetailsBaseModal';
import { ONLINE_SCHEDULING_FEATURE_NAME, ONLINE_SCHEDULING_PRICE, ONLINE_SCHEDULING_VIDEO_URL } from './constants';

export const OnlineSchedulingUpgradeDetailsModal: FC<FeatureUpgradeModalComponentProps> = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'online-scheduling-details-modal' });

  const handleScheduleCall = () => {
    window.open(getScheduleDemoLink(Feature.ONLINE_SCHEDULING, getUser()?.username), '_blank');
  };

  return (
    <FeatureUpgradeDetailsBaseModal
      featureAccountSettingsUrl={SUBSCRIPTION_SETTINGS_URL}
      featureName={ONLINE_SCHEDULING_FEATURE_NAME}
      onScheduleCallClick={handleScheduleCall}
      bundleFeature={t('Elite Bundle Feature')}
      {...props}
    >
      <FeatureUpgradeDetailsBaseModal.Title title={ONLINE_SCHEDULING_FEATURE_NAME} price={ONLINE_SCHEDULING_PRICE} />
      <FeatureUpgradeDetailsBaseModal.ScrollableContent>
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.VideoText
          videoUrl={ONLINE_SCHEDULING_VIDEO_URL}
          text={t(
            'Online Scheduling gives your patients a way to schedule appointments at their convenience, helping your office reduce the amount of time it spends on the phone scheduling. Customize your provider and office availability, appointment types, and appointment booking form to capture the scheduling information you need without any staff effort.'
          )}
        />
        <OnlineSchedulingFeatureCards />
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.BannerText
          imageUrl={OnlineSchedulingBannerImg}
          imageAltText={t('Online Scheduling Banner')}
          title={t('Get Online Scheduling')}
        />
      </FeatureUpgradeDetailsBaseModal.ScrollableContent>
      <FeatureUpgradeDetailsBaseModal.Action />
    </FeatureUpgradeDetailsBaseModal>
  );
};

const OnlineSchedulingFeatureCards: FC = () => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'online-scheduling-details-modal' });

  return (
    <div css={featureCardsLayoutStyles}>
      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Easy Scheduling')}
        icon='calendar-confirmed-small'
      >
        <Text size='medium'>
          {t('Allow patients to schedule appointments anytime it is most convenient for them.')}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard title={t('Less Wasted Time')} icon='clock-small'>
        <Text size='medium'>
          {t(
            'Reduce the amount of time that office staff spends on the phones going back-and-forth with customers to schedule appointments.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>
    </div>
  );
};

const featureCardsLayoutStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});
