import { css } from '@emotion/react';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Avatar, Heading, Text, useModalControl } from '@frontend/design-system';
import { useManagePractitionerProfilePhoto } from '../../../context/ManagePractitionerProfilePhotoContext';
import { PractitionerPhotoUploadModal } from './PractitionerPhotoUploadModal';

const defaultNameForAvatar = i18next.t('Practitioner', { ns: 'schedule' });

export const PractitionerPhotoContainer = () => {
  const { t } = useTranslation('schedule');

  return (
    <section css={photoContainerStyles}>
      <PractitionerPhoto />
      <span>
        <Heading level={3}>{t('Profile Picture')}</Heading>
        <Text size='small'>
          {t(
            'Upload a photo for this provider. It will appear in online scheduling and the Weave calendar. If no photo is added, a monogram avatar will be used.'
          )}
        </Text>
      </span>
    </section>
  );
};

const PractitionerPhoto = () => {
  const { practitionerDetails, profilePhotoSrc } = useManagePractitionerProfilePhoto();
  const name = Object.keys(practitionerDetails).length
    ? `${practitionerDetails?.firstName} ${practitionerDetails?.lastName}`
    : defaultNameForAvatar;
  const { modalProps, triggerProps } = useModalControl();

  return (
    <>
      <Avatar
        isUser
        size='xl'
        src={profilePhotoSrc ?? practitionerDetails?.profile?.picUrl}
        name={name || practitionerDetails?.displayName || defaultNameForAvatar}
        {...triggerProps}
      >
        <Avatar.BottomIcon>
          <Icon name='plus-small' size={20} color='white' css={avatarPlusIconStyles} />
        </Avatar.BottomIcon>
      </Avatar>
      <PractitionerPhotoUploadModal modalProps={modalProps} />
    </>
  );
};

const avatarPlusIconStyles = css({
  background: theme.colors.primary50,
  border: `1.5px solid ${theme.colors.white}`,
  padding: theme.spacing(0.5),
  right: `0 !important`,
  bottom: `0 !important`,
});

const photoContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(3),
});
