import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type RowProps = {
  children: React.ReactNode;
  label: string;
  optional: boolean;
  onClick: () => void;
  active: boolean;
  onClear?: () => void;
  onBlur?: () => void;
  disable?: boolean;
};
export const Row = ({ children, label, optional, onClick, active, onClear, disable }: RowProps) => {
  const { t } = useTranslation('payments');
  const textColor = disable ? 'disabled' : 'subdued';

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        cursor: disable ? 'not-allowed' : 'pointer',
        width: '100%',
        '&:hover': {
          backgroundColor: theme.colors.neutral10,
        },
      }}
      onClick={active || disable ? undefined : onClick}
    >
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          gap: theme.spacing(1),
          borderBottom: !active ? `1px solid ${theme.colors.neutral20}` : 'none',
          padding: !active ? theme.spacing(2, 0) : theme.spacing(1),
          flex: 1,
          '> div': { width: '100%' },
        }}
      >
        {active && !disable ? (
          children
        ) : (
          <>
            <Icon name='plus' color={textColor} />
            <Text color={textColor} weight='bold'>
              {label}
            </Text>
            {optional && (
              <Text color={textColor} css={{ fontStyle: 'italic' }}>
                {t('(Optional)')}
              </Text>
            )}
          </>
        )}
      </div>
      <div
        css={{
          padding: theme.spacing(1),
          visibility: active && !disable && onClear ? 'visible' : 'hidden',
        }}
      >
        <Icon name='x-small' onClick={onClear} />
      </div>
    </div>
  );
};
