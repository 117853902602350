import dayjs from 'dayjs';
import i18next from 'i18next';
import { DepartmentsTypes } from '@frontend/api-departments';
import { getFullName } from '../../utils';
import { HydratedCallRecord } from '../all-calls/types';
import { getOfficeUser } from './utils';

/**
 * Based on the the columns names, derive the real value to be inserted into exported file
 */
type DeriveExportColumnValue = {
  columnName: string;
  data: HydratedCallRecord;
  departmentsByLocation: Record<string, DepartmentsTypes.ListDepartmentTypes['output']>;
  locationMap: Record<string, string>;
};

export const deriveExportColumnValue = ({
  columnName,
  data,
  departmentsByLocation,
  locationMap,
}: DeriveExportColumnValue) => {
  switch (columnName) {
    case i18next.t('Contact Name', { ns: 'calls', keyPrefix: 'recent-calls' }):
      return getFullName(data.person?.firstName, data.person?.lastName, i18next.t('Unknown', { ns: 'calls' }));

    case i18next.t('Call Direction', { ns: 'calls', keyPrefix: 'recent-calls' }):
      return data.direction || '--';

    case i18next.t('Time', { ns: 'calls', keyPrefix: 'recent-calls' }):
      return dayjs(data.startedAt ?? '').format('MMM DD YYYY, hh:mm A');

    case i18next.t('Result', { ns: 'calls', keyPrefix: 'recent-calls' }): {
      const { direction = '', status = '--' } = data;
      return direction === 'outbound' ? '--' : status;
    }
    case i18next.t('Contact Number', { ns: 'calls', keyPrefix: 'recent-calls' }): {
      const { callerNumber = '', direction = '', dialedNumber = '' } = data;
      return direction === 'inbound' ? callerNumber : dialedNumber;
    }

    case i18next.t('Office Number', { ns: 'calls', keyPrefix: 'recent-calls' }): {
      const { callerNumber = '', direction = '', dialedNumber = '' } = data;
      return direction === 'outbound' ? callerNumber : dialedNumber;
    }

    case i18next.t('Office User', { ns: 'calls', keyPrefix: 'recent-calls' }):
      return getOfficeUser(data);

    case i18next.t('Location', { ns: 'calls', keyPrefix: 'recent-calls' }):
      return !!data.locationId ? locationMap[data.locationId] : '--';

    case i18next.t('Department', { ns: 'calls', keyPrefix: 'recent-calls' }): {
      const { locationId, departmentId } = data;
      const dept = departmentsByLocation[locationId!]?.departments?.find((item) => item.id === departmentId);
      return !!departmentId ? dept?.name || '--' : 'Main Line';
    }

    default:
      return '-';
  }
};
