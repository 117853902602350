import { useMemo } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ValidNumberStatus } from '@weave/schema-gen-ts/dist/schemas/sms/number/v1/number_service.pb';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { formatPhoneNumber, formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { usePopoverMenu } from '@frontend/design-system';
import { PersonPhoneSelectorState } from '../components';
import { dedupePhoneValidity } from '../utils';

type UsePersonPhoneSelectorArgs = {
  personPhone: string;
  allowCustomPhoneNumber?: boolean;
  alwaysShow?: boolean;
} & (
  | {
      personId?: never;
      person?: never;
      groupId?: string;
    }
  | {
      personId: string;
      person?: Person;
      groupId: string;
    }
);

export const usePersonPhoneSelector = ({
  personPhone,
  personId: providedPersonId,
  allowCustomPhoneNumber,
  groupId,
  person,
}: UsePersonPhoneSelectorArgs): PersonPhoneSelectorState => {
  const personId = providedPersonId || person?.personId || '';
  const { validPhones: personValidPhones, allPhonesWithValidity } = SMSNumberV1.Hooks.usePersonSMSNumbersValidity({
    personId,
    groupIds: groupId ? [groupId] : [],
    person,
  });

  const { data: phoneValidity } = SMSNumberV1.Queries.useGetValidityQuery({
    request: { phoneNumber: personPhone },
    options: { enabled: !!personPhone && (!personId || !groupId) },
    fallbackDataOnError: { isValid: ValidNumberStatus.VALID_NUMBER_STATUS_VALID },
  });

  const phoneWithoutPerson = useMemo(
    () => ({
      number: personPhone,
      isValid: phoneValidity?.isValid === ValidNumberStatus.VALID_NUMBER_STATUS_VALID,
    }),
    [phoneValidity?.isValid, personPhone]
  );
  const { validPhones, allPhones } = useMemo<{
    validPhones: SMSNumberV1.Types.PhoneValidity[];
    allPhones: SMSNumberV1.Types.PhoneValidity[];
  }>(
    () =>
      personId
        ? { validPhones: personValidPhones, allPhones: allPhonesWithValidity }
        : { validPhones: [phoneWithoutPerson].filter(({ isValid }) => isValid), allPhones: [phoneWithoutPerson] },
    [personId, JSON.stringify(personValidPhones), JSON.stringify(phoneWithoutPerson)]
  );
  const dedupedPhones = dedupePhoneValidity(validPhones, 'combine-labels');
  const currentSelectedPhone: SMSNumberV1.Types.PhoneValidity =
    allPhones.find(({ number }) => formatPhoneNumberE164(number) === formatPhoneNumberE164(personPhone)) ??
    phoneWithoutPerson;
  const currentSelectedPhoneLabel = `${
    dedupedPhones.find((phone) => formatPhoneNumberE164(phone.number) === formatPhoneNumberE164(personPhone))?.label ??
    currentSelectedPhone.label ??
    ''
  } ${formatPhoneNumber(currentSelectedPhone.number)}`.trim();

  const showButton = dedupedPhones.length > 1 || (!currentSelectedPhone.isValid && !!dedupedPhones.length);

  const isCurrentSelectedNumberCustom = useMemo<boolean>(
    () =>
      !!allowCustomPhoneNumber &&
      !!currentSelectedPhone.number &&
      !allPhones.find((item) => item.number === currentSelectedPhone.number),
    [JSON.stringify(allPhones), currentSelectedPhone.number]
  );

  const popoverState = usePopoverMenu<HTMLElement>({
    placement: 'bottom',
  });

  return {
    showButton,
    currentSelectedPhone,
    currentSelectedPhoneLabel,
    validPhones,
    isCurrentSelectedNumberCustom,
    popoverState,
  };
};
