import { useMemo } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { AnimatePresence } from 'motion/react';
import { PersonsV3 } from '@frontend/api-person';
import { PetQueries } from '@frontend/api-pet';
import { useLastUsedVerticalShallowStore } from '@frontend/location-helpers';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useGetAppointmentInfo } from '../../hooks';
import { EventCardComponentProps } from '../calendar-view/providers';
import { AppointmentEventCardPreview } from './appointment-event-card';
import { EventCardExpandedView } from './event-card-expanded-view';
import { prepareAppointmentInfo } from './helpers';
import { MotionText } from './motion-text';
import { OutOfOfficeEventActionMenu } from './out-of-office-event-action-menu';

const CACHE_TIME = 5 * 60 * 1000; // 5 minutes

export const EventCardComponent = ({
  duration,
  providerId,
  providerName,
  id,
  title,
  locationName,
  isExpanded,
  eventType,
}: EventCardComponentProps) => {
  const isAppointment = eventType === 'appointment';
  const { appointmentDetails } = useGetAppointmentInfo(id);

  if (isAppointment && !!appointmentDetails) {
    return <AppointmentCardSwitcher duration={duration || ''} appointmentDetails={appointmentDetails} eventId={id} />;
  }

  return (
    <>
      <div css={{ display: 'flex' }}>
        <MotionText hasLayoutAnimation size={isExpanded ? 'large' : 'medium'} weight='bold'>
          {title}
        </MotionText>
        {isExpanded && eventType === 'outOfOffice' && (
          <OutOfOfficeEventActionMenu
            eventId={id}
            eventTitle={title}
            providerId={providerId}
            providerName={providerName}
          />
        )}
      </div>
      <AnimatePresence initial={false}>
        {isExpanded ? (
          <EventCardExpandedView
            duration={duration ?? '-'}
            locationName={locationName ?? '-'}
            providerName={providerName ?? '-'}
          />
        ) : (
          <MotionText
            hasLayoutAnimation
            variants={motionTextAnimationVariants}
            initial='initial'
            animate='animate'
            exit='exit'
            transition={{ duration: 0.3 }}
            size='small'
            color='light'
          >
            {duration ?? '-'}
          </MotionText>
        )}
      </AnimatePresence>
    </>
  );
};

const AppointmentCardSwitcher = ({
  duration,
  appointmentDetails,
  eventId,
}: {
  duration: string;
  appointmentDetails: Appointment;
  eventId: string;
}) => {
  const { lastUsedVertical } = useLastUsedVerticalShallowStore('lastUsedVertical');
  const hasVetify = useHasFeatureFlag('vetify-nwx');

  return hasVetify && lastUsedVertical === 'VET' ? (
    <VetEventCard duration={duration ?? '-'} appointmentDetails={appointmentDetails ?? {}} eventId={eventId} />
  ) : (
    <EventCard eventId={eventId} appointmentDetails={appointmentDetails ?? {}} duration={duration ?? '-'} />
  );
};

const motionTextAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const VetEventCard = ({
  duration,
  appointmentDetails,
  eventId,
}: {
  duration: string;
  appointmentDetails: Appointment;
  eventId: string;
}) => {
  const appointmentInfo = useMemo(() => {
    const appointmentInfoToPrepare = appointmentDetails ? [appointmentDetails] : [];
    return prepareAppointmentInfo(appointmentInfoToPrepare ?? [], eventId);
  }, [appointmentDetails, eventId]);

  const { data: personData } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: appointmentInfo?.personInfo?.id ?? '',
      locationIds: [appointmentInfo?.locationId ?? ''],
    },
    {
      enabled: !!appointmentInfo?.personInfo?.id,
      cacheTime: CACHE_TIME,
      staleTime: CACHE_TIME,
      select: (data) => PersonsV3.PersonHelpers.convertPersonV3ToPerson(data),
    }
  );

  if (!appointmentInfo) {
    return null;
  }

  const personId = appointmentInfo?.personInfo?.id || '';
  const serviceableIds = appointmentInfo?.petInfo?.serviceableIds || [];

  const petQuery = PetQueries.useGetPersonPets({
    personId: personId || '',
    opts: {
      enabled: !!personId,
      select: (data) => data?.filter((pet) => pet.serviceableId === serviceableIds?.[0]),
    },
  });

  return (
    <div>
      <div css={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Text as='span' size='medium' weight='bold' css={{ marginRight: theme.spacing(0.5) }}>
          {petQuery.data?.[0]?.name}
        </Text>
        <Text as='span' size='medium'>
          {personData?.LastName}
        </Text>
      </div>
      <MotionText size='medium'>{duration}</MotionText>
    </div>
  );
};

const EventCard = ({
  eventId,
  duration,
  appointmentDetails,
}: {
  duration: string;
  eventId: string;
  appointmentDetails: Appointment;
}) => {
  const appointmentInfo = useMemo(() => {
    const appointmentInfoToPrepare = appointmentDetails ? [appointmentDetails] : [];
    return prepareAppointmentInfo(appointmentInfoToPrepare ?? [], eventId);
  }, [appointmentDetails, eventId]);

  return <AppointmentEventCardPreview name={appointmentInfo?.personInfo.name || ''} duration={duration} />;
};
