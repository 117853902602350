import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const wrapper = css`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${theme.spacing(1, 1, 3, 1)};
  margin: ${theme.spacing(1, 0)};
  max-width: 600px;
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.colors.neutral20};

  & > form {
    margin: ${theme.spacing(1, 2)};
  }
`;

export const cardTextSpacing = css({
  marginBottom: theme.spacing(2),
  cursor: 'help',
  svg: {
    display: 'inline-block',
    opacity: 0.4,
    transform: 'translateY(2px)',
  },
});

export const leadTimeRow = css`
  margin-bottom: ${theme.spacing(-2)};
`;

export const leadTimeField = css({
  width: '100%',
  minWidth: '100%',
  marginBottom: theme.spacing(2),
});

export const requiredApptTimeDropdown = css({
  width: 'fit-content',
  minWidth: '200px',
});

export const alertMargin = css({
  marginBottom: theme.spacing(2),
});
