import { Bool_Enum } from '@weave/schema-gen-ts/dist/shared/null/types.pb';
import { cloneDeep } from 'lodash-es';
import { PickerLocation, LocationResult } from './types';

/**
 * This method restructures the list of locations so that children locations as nested under parent locations
 */
export const restructureList = (locations: LocationResult[]) => {
  /**
   * Create a base map with all parent locations.
   * Parent locations can have a minimum of 0 children.
   *
   * These locations are guaranteed to be parent locations because they do not have a parentID
   */
  const list = Object.fromEntries<Omit<PickerLocation, 'children'> & { children?: PickerLocation[] }>(
    locations
      .filter((location) => !location.parentID)
      .map((location) => [location.locationID, { ...location, children: [], id: location.locationID }])
  );

  /**
   * Use the inverse filter to find all children locations.
   * Associate each child with the appropriate parent.
   */
  locations
    .filter((location) => location.parentID)
    .filter((location) => location.active === Bool_Enum.TRUE)
    .forEach((location) => {
      if (list[location.parentID]) {
        list[location.parentID]?.children?.push({ ...location, children: [], id: location.locationID });
      } else {
        list[location.locationID] = { ...location, id: location.locationID };
      }
    });

  const listWithLocationTypes = attachLocationTypes(list);

  /**
   * Create an array of locations, with the children locations of its associated parent placed right after it.
   *
   * Example:
   * [
   *    P1,
   *    P2,
   *    [P2-1, P2-2],
   *    P3,
   * ]
   */
  const finalList = Object.values(listWithLocationTypes).reduce<(PickerLocation | PickerLocation[])[]>(
    (acc, location) => {
      if (location.children?.length) {
        return [...acc, location, location.children?.map((child) => ({ ...child, indent: true }))];
      } else {
        return [...acc, location];
      }
    },
    []
  );

  /**
   * Flatten the array so that we have a simple list that we can iterate in our render function.
   *
   * Everything should remain sorted as the input was sorted, accounting for nesting.
   */
  return finalList.flat();
};

export const attachLocationTypes = (locationData: Record<string, PickerLocation>): Record<string, PickerLocation> => {
  const locationMap = cloneDeep(locationData) as Record<string, PickerLocation>;

  for (const id in locationMap) {
    const location = locationMap[id];
    if (location?.children?.length) {
      attachLocationTypesToChildren(location);
    } else if (location && !location?.parentId) {
      location.locationType = 'single';
    }
  }

  return locationMap;
};

const attachLocationTypesToChildren = (location: PickerLocation) => {
  if (!location.children?.length || !location.phoneTenantId) return;
  let childrenWithSameTenantIdCount = 0;

  // We check each child because there might be hybrid locations that have both unify and databeta children.
  for (const child of location.children) {
    if (child.phoneTenantId === location.phoneTenantId) {
      child.locationType = 'unify';
      childrenWithSameTenantIdCount++;
    } else {
      child.locationType = 'databeta';
    }
  }

  // If some children share the same phone-tenant-id, we know it is a unify parent.
  // Conversely, if no children share the same phone-tenant-id, we know it is a databeta parent.
  location.locationType = childrenWithSameTenantIdCount > 0 ? 'unify' : 'databeta';
};
