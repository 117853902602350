import { InvoiceModel } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { Modal, ModalControlModalProps } from '@frontend/design-system';
import { useCancelInvoice } from '../hooks';

type InvoiceCancelModalProps = {
  modalProps: ModalControlModalProps;
  invoice: InvoiceModel | undefined;
  onClose: () => void;
};

export const InvoiceCancelModal = ({ modalProps, invoice, onClose }: InvoiceCancelModalProps) => {
  const { t } = useTranslation('payments');
  const { cancelInvoice } = useCancelInvoice();

  return (
    <Modal {...modalProps}>
      <Modal.Header>{t('Confirm Cancel Invoice')}</Modal.Header>
      <Modal.Body>
        {t(
          'Canceling this invoice will delete it completely from your invoice reports. Do you still want to cancel this invoice?'
        )}
      </Modal.Body>
      <Modal.Footer
        primary={{
          type: 'button',
          destructive: true,
          onClick: () => {
            if (invoice) {
              cancelInvoice(invoice);
            }
            onClose();
          },
          label: t('Cancel Invoice'),
        }}
        secondary={{
          onClick: onClose,
          label: t('Go Back'),
        }}
      />
    </Modal>
  );
};
