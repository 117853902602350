import { css } from '@emotion/react';
import { useMutation } from 'react-query';
import { AppointmentTypesApi, AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { MoneyField, SwitchField, useAlert, Button, useForm, ContentLoader } from '@frontend/design-system';

interface AppointmentTypeSettingsProps {
  apptRequestConfig: AppointmentTypesTypes.ApptRequestConfig;
  refetchApptReqConfig: () => void;
  locationId: string;
}
export const AppointmentTypePaymentsSettings = ({
  apptRequestConfig,
  refetchApptReqConfig,
  locationId,
}: AppointmentTypeSettingsProps) => {
  const alerts = useAlert();
  const { t } = useTranslation('scheduleAppointmentTypes');

  const hasGlobalBookingDeposit = !!(
    apptRequestConfig?.hasGlobalBookingDeposit &&
    apptRequestConfig?.globalBookingDepositAmount &&
    apptRequestConfig?.globalBookingDepositAmount > 0
  );
  const moneyFieldValue = hasGlobalBookingDeposit ? String(apptRequestConfig?.globalBookingDepositAmount) : String(0);

  const { mutateAsync: updateBookingDeposit, isLoading: isSaving } = useMutation({
    mutationFn: (configData: AppointmentTypesTypes.ApptRequestConfig) =>
      AppointmentTypesApi.updateApptRequestConfigData(configData, locationId),
    onSuccess: () => {
      alerts.success(t('Payments settings saved successfully'));
      refetchApptReqConfig();
      seedValues({ ...values }); // Seeding value re-initializes form values and reset `changedValues`, which is used to disable save button until current values are changed
    },
    onError: () => {
      alerts.error(t('Failed to save payments settings'));
    },
  });

  const { formProps, values, changedValues, getFieldProps, getErrors, seedValues } = useForm({
    fields: {
      globalBookingDepositSwitch: {
        type: 'switch',
        value: hasGlobalBookingDeposit,
      },
      globalBookingDepositAmount: {
        type: 'money',
        value: moneyFieldValue,
        validateOnChange: true,
        validator: ({ value }) => (Number(value) <= 0 ? t('Amount must be greater than 0') : ''),
      },
    },
    computeChangedValues: true,
    onSubmit: async (values) => {
      updateBookingDeposit({
        appointmentTypeIDs: apptRequestConfig.appointmentTypeIDs,
        minOpeningsPermitted: apptRequestConfig.minOpeningsPermitted,
        requestBufferDuration: apptRequestConfig.requestBufferDuration,
        hasGlobalBookingDeposit: values.globalBookingDepositSwitch,
        globalBookingDepositAmount: values.globalBookingDepositSwitch ? Number(values.globalBookingDepositAmount) : 0,
      });
    },
  });

  const shouldDisableSaveBtn = isSaving || !changedValues || !!getErrors().globalBookingDepositAmount;

  return (
    <div css={styles}>
      <SwitchField
        {...getFieldProps('globalBookingDepositSwitch')}
        label={t('Request below amount for all appointment types')}
        labelPlacement='right'
      />
      <MoneyField
        {...getFieldProps('globalBookingDepositAmount')}
        css={{ width: 200 }}
        label={t('Amount')}
        disabled={!values.globalBookingDepositSwitch}
      />
      <Button css={{ width: 'fit-content' }} disabled={shouldDisableSaveBtn} onClick={formProps.onSubmit}>
        {t('Save Payments Settings')}
      </Button>
      <ContentLoader show={isSaving} size='medium' />
    </div>
  );
};

const styles = css`
  position: relative;
  max-width: 420px;
  margin-top: ${theme.spacing(3)};
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(3)};
`;
