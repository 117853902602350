import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { FallbackRender } from '@sentry/react';
import { HttpError, SchemaParamValidationError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion, Text } from '@frontend/design-system';

export const ErrorAccordion = ({ error, eventId }: { error: Error; eventId: string }) => {
  const { t } = useTranslation('base');

  return (
    <Accordion css={{ maxWidth: 400, width: '100%' }}>
      <Accordion.Item value='details' css={{ marginBottom: 0 }}>
        <Accordion.Header>{t('Error Details')}</Accordion.Header>
        <Accordion.Body
          css={{
            display: 'grid',
            gap: theme.spacing(1),
          }}
        >
          <ErrorMessage>{error.message}</ErrorMessage>
          <ErrorDetails error={error} eventId={eventId} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export const ErrorMessage = ({ children, error }: { children?: string; error?: Error }) => {
  const { t } = useTranslation('base');
  let content;
  try {
    if (error) {
      const message = error instanceof HttpError ? error.cause?.message : error.message;
      content = message.charAt(0) === '{' ? JSON.stringify(JSON.parse(message), null, 2) : message;
    } else if (children) {
      content = children.charAt(0) === '{' ? JSON.stringify(JSON.parse(children), null, 2) : children;
    }
  } catch (e) {
    content = t('Failed to parse error message');
  }

  return (
    <div
      style={{
        fontSize: theme.fontSize(10),
        background: theme.colors.warning5,
        borderRadius: theme.borderRadius.small,
        color: theme.colors.warning80,
        minWidth: 0,
      }}
    >
      <pre
        css={{
          padding: theme.spacing(1),
          width: '100%',
          overflow: 'auto',
        }}
      >
        <code>{content}</code>
      </pre>
    </div>
  );
};

export const ErrorDetails = ({
  error,
  eventId,
}: Pick<ComponentPropsWithoutRef<FallbackRender>, 'eventId'> & { error: Error }) => {
  const { t } = useTranslation('base');
  if (error instanceof HttpError) {
    return (
      <DetailContainer>
        <DetailText>
          <DetailLabelText>{t('Error Type:')}</DetailLabelText> {error?.cause.code}
        </DetailText>
        <DetailText>
          <DetailLabelText>{t('URL:')}</DetailLabelText> {error.cause.url}
        </DetailText>
        <DetailText>
          <DetailLabelText>{t('Event ID:')}</DetailLabelText> {eventId}
        </DetailText>
      </DetailContainer>
    );
  } else if (error instanceof SchemaParamValidationError) {
    return (
      <DetailContainer>
        <DetailText>
          <DetailLabelText>{t('Error Type:')}</DetailLabelText> {error.name}
        </DetailText>
        <DetailText>
          <DetailLabelText>{t('Method:')}</DetailLabelText> {error.cause.method}
        </DetailText>
        <DetailText>
          <DetailLabelText>{t('Event ID:')}</DetailLabelText> {eventId}
        </DetailText>
      </DetailContainer>
    );
  }

  return (
    <DetailContainer>
      <DetailText>
        <DetailLabelText>{t('Error Type:')}</DetailLabelText> {error.name}
      </DetailText>
      <DetailText>
        <DetailLabelText>{t('Event ID:')}</DetailLabelText> {eventId}
      </DetailText>
    </DetailContainer>
  );
};

const DetailText = ({ children }: { children: ReactNode }) => <Text size='medium'>{children}</Text>;
const DetailLabelText = ({ children }: { children: ReactNode }) => (
  <Text as='span' size='medium' weight='bold'>
    {children}
  </Text>
);
const DetailContainer = ({ children }: { children: ReactNode }) => (
  <div
    style={{
      borderRadius: theme.borderRadius.small,
      background: theme.colors.neutral10,
      padding: theme.spacing(1),
      minWidth: 0,

      display: 'grid',
      gap: theme.spacing(1),
      wordBreak: 'break-word',
    }}
  >
    {children}
  </div>
);
