import { motion } from 'motion/react';
import { FaxTypes } from '@frontend/api-fax';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Avatar, ListRow } from '@frontend/design-system';
import { BulkActionCheckbox } from './bulk-action-checkbox';

const LEAD_CONTAINER = {
  LARGE: 44,
  SMALL: 32,
} as const;

type FaxInboxListItemLeadProps = {
  isBulkSelect: boolean;
  isCheckboxSelected: boolean;
  personName: string;
  contactId?: string;
  locationId?: string;
  setCheckboxSelected?: (value: boolean) => void;
  profileMedia?: string;
  isInbound: boolean;
};

export const FaxInboxListItemLead = ({
  isBulkSelect,
  personName,
  contactId,
  locationId,
  isCheckboxSelected,
  setCheckboxSelected,
  profileMedia,
  isInbound,
}: FaxInboxListItemLeadProps) => {
  const { setShow } = useSlidePanelShallowStore('setShow');

  const openContactForm = () => {
    if (contactId) {
      setShow(true, 'faxContactForm', {
        contactId,
        locationId: locationId ?? '',
        action: FaxTypes.ContactAction.EDIT,
      });
    } else {
      setShow(true, 'faxContactForm', {
        action: FaxTypes.ContactAction.ADD,
        locationId: locationId ?? '',
      });
    }
  };

  return (
    <ListRow.Lead>
      <div
        css={{
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
          width: LEAD_CONTAINER.LARGE,
          padding: theme.spacing(0.25),
        }}
      >
        <motion.div
          initial={false}
          animate={{ x: isBulkSelect ? -LEAD_CONTAINER.LARGE : 0 }}
          transition={{ ease: 'easeInOut' }}
        >
          {profileMedia ? (
            <img
              css={{
                height: theme.spacing(5),
                width: theme.spacing(5),
                minWidth: theme.spacing(5),
                borderRadius: theme.borderRadius.full,
              }}
              src={profileMedia}
            />
          ) : (
            <Avatar
              name={personName.trim()}
              onClick={(e) => {
                e?.stopPropagation();
                openContactForm();
              }}
            />
          )}
        </motion.div>
        <motion.div
          initial={false}
          animate={{
            x: isBulkSelect ? -LEAD_CONTAINER.SMALL : theme.spacing(1),
          }}
          transition={{ ease: 'easeInOut' }}
        >
          <BulkActionCheckbox
            isCheckboxSelected={isCheckboxSelected}
            onSelect={(value) => {
              if (typeof setCheckboxSelected === 'function') {
                setCheckboxSelected(value);
              }
            }}
            bulkSelectionIsActive={isBulkSelect}
            isInbound={isInbound}
          />
        </motion.div>
      </div>
    </ListRow.Lead>
  );
};
