import { useMemo } from 'react';
import { Trans, useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Text, TextLink, useTooltip } from '@frontend/design-system';
import { OverdueMessageBaseProps } from './OverdueBy30DaysMessage';

interface IProps extends OverdueMessageBaseProps {
  warningDayCount: number;
}

export const OverdueBy40DaysMessage = ({
  warningDayCount,
  locationCount,
  locationNames,
  showMultiLocationBanner,
  onAction,
}: IProps) => {
  const { navigate } = useSettingsNavigate();
  const { t } = useTranslation('billingAlerts');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  const goToBilling = () => {
    onAction?.();
    navigate({ to: '/organization/invoice-history' });
  };

  const title = useMemo(() => {
    return showMultiLocationBanner ? (
      <Trans t={t} count={locationCount} values={{ locationCount }}>
        Your bill is critically overdue for{' '}
        <Text as='span' weight='bold' {...triggerProps}>
          {{ locationCount }} locations.
        </Text>
      </Trans>
    ) : (
      t('Your bill is critically overdue.')
    );
  }, [locationCount, showMultiLocationBanner, triggerProps]);

  return (
    <>
      <Text>
        {title}{' '}
        <Trans t={t} count={warningDayCount} values={{ warningDayCount }}>
          If your account balance is not resolved{' '}
          <TextLink as='span' onClick={goToBilling} trackingId='billing-overdue-40-resolve-balance-here-btn'>
            here
          </TextLink>{' '}
          within the next {{ warningDayCount }} days, your account will be suspended.
        </Trans>
      </Text>
      <Tooltip {...tooltipProps}>{locationNames}</Tooltip>
    </>
  );
};
