import { useQuery } from 'react-query';
import { CoreACLs, getUser, hasACL, isWeaveUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { getAccountManagerData, getCSMContactPopupStatusByUserIDAndLocationID } from './api';

export const useAccountManagerDataQuery = () => {
  const { selectedLocationIdsWithParents, accessibleLocationData, selectedParentsIds } = useAppScopeStore();
  const isMultiLocation = !!selectedParentsIds.length;
  const locationId = selectedLocationIdsWithParents[0];
  const locationData = accessibleLocationData[locationId];
  const slug = locationData?.slug ?? '';

  const isAdmin = hasACL(locationId, CoreACLs.USER_WRITE);
  const canViewAccountManagerDetails = (isAdmin && isMultiLocation) || isWeaveUser();
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const hasAccountManagerFlagEnabled = getFlag('account-manager-profile-view');

  return useQuery({
    queryKey: [slug, 'account-manager'],
    queryFn: async () => {
      try {
        return await getAccountManagerData({ slug });
      } catch (error) {
        console.error('Error fetching account manager data', error);
        return undefined;
      }
    },
    enabled: canViewAccountManagerDetails && hasAccountManagerFlagEnabled && !!slug,
    staleTime: 30000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
};

export const useCSMContactPopupStatusByUserIDAndLocationID = ({ enabled = true }: { enabled?: boolean }) => {
  const { selectedParentsIds, selectedLocationIdsWithParents } = useAppScopeStore();
  const isMultiLocation = !!selectedParentsIds.length;
  const locationId = selectedLocationIdsWithParents[0];
  const user = getUser();

  // PT-TODO: Create a new ACL for viewing account manager details
  const isAdmin = hasACL(locationId, CoreACLs.USER_WRITE);
  const canViewAccountManagerDetails = (isAdmin && isMultiLocation) || isWeaveUser();

  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const hasAccountManagerFlagEnabled = flagValues['account-manager-profile-view'];
  const hasCsmWelcomePopupFlagEnabled = flagValues['csm-welcome-popup'];
  const hasFlagsEnabled = hasAccountManagerFlagEnabled && hasCsmWelcomePopupFlagEnabled;

  return useQuery({
    queryKey: [locationId, user?.userID, 'csm-contact-popup-status'],
    queryFn: () => {
      return getCSMContactPopupStatusByUserIDAndLocationID({ locationId, userId: user?.userID ?? '' });
    },
    enabled: enabled && canViewAccountManagerDetails && hasFlagsEnabled && !!locationId && !!user?.userID,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
  });
};
