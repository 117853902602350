import { memo, useMemo } from 'react';
import { AnalyticsCommonTypes, PracticeAnalyticsAggregations, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart, YAxisLabelValueTick } from '@frontend/charts';
import { useAnalyticsOrgLocations } from '../../hooks';
import { formatters } from '../../utils';
import { LocationChip } from '../location-chip';
import { usePracticeAnalyticsShallowStore } from './hooks';

type Props = PracticeAnalyticsTypes.MetricProps & {
  colors: AnalyticsCommonTypes.StringRecord;
  data?: ReturnType<typeof PracticeAnalyticsAggregations.newPatientsSummary>;
  isLoading?: boolean;
  labels: AnalyticsCommonTypes.StringRecord;
};

export const NewPatientsMetricMulti = memo(({ colors, data, isLoading, labels }: Props) => {
  const { isDemoAccount } = usePracticeAnalyticsShallowStore('isDemoAccount');
  const { locationNames } = useAnalyticsOrgLocations({ isDemoAccount, module: 'PA' });

  const chartsData = useMemo(() => {
    const entries = Object.entries(data?.rawData ?? {});
    const totals = {
      newPatients: 0,
      completedProduction: 0,
      uncapturedProduction: 0,
    };

    const newPatients: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => {
        const value = locationDetails?.location.newPatients.totals?.patients ?? 0;
        totals.newPatients += value;

        return {
          name: locationNames[locationId] || locationId,
          values: {
            newPatients: value,
          },
        };
      }),
    };

    const production: BarChartData = {
      groups: entries.map(([locationId, locationDetails]) => {
        const completedProduction = locationDetails?.location.newPatients.totals?.production ?? 0;
        const uncapturedProduction = locationDetails?.location.newPatients.totals?.unscheduledProduction ?? 0;

        totals.completedProduction += completedProduction;
        totals.uncapturedProduction += uncapturedProduction;

        return {
          name: locationNames[locationId] || locationId,
          values: {
            completedProduction,
            uncapturedProduction,
          },
        };
      }),
    };

    return {
      newPatients,
      production,
      totals,
    };
  }, [locationNames, data?.rawData]);

  const renderChart = (data: BarChartData, isProductionChart?: boolean) => (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.BarChart
        appearance={{
          collectiveTooltip: true,
          customTooltipTitle: ({ groupName }) => <LocationChip locationName={groupName} noTruncating />,
          customXAxisTickFormat: isProductionChart ? formatters.currency.shortenNumber : undefined,
          customYAxisTick: ({ labels, groupName, ...rest }) => {
            return <YAxisLabelValueTick {...rest} clipLength={25} label={labels?.[groupName] || groupName} />;
          },
          groupsGap: 24,
          layout: 'vertical',
          margin: { left: 114 },
          mode: isProductionChart ? 'stacked' : 'grouped',
          showGridLines: true,
          showXAxis: true,
          showYAxis: true,
        }}
        data={data}
        formatValue={isProductionChart ? formatters.currency.format : formatters.value.format}
      />
      <Chart.Legends
        formatValue={isProductionChart ? formatters.currency.format : formatters.value.format}
        values={chartsData.totals}
      />
    </Chart>
  );

  return (
    <>
      {renderChart(chartsData.newPatients)}
      {renderChart(chartsData.production, true)}
    </>
  );
});

NewPatientsMetricMulti.displayName = 'NewPatientsMetricMulti';
