import { MouseEventHandler, forwardRef } from 'react';
import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  NakedButton,
  PolymorphicComponentPropWithRef,
  PolymorphicComponentPropWithoutRef,
  PolymorphicRef,
} from '@frontend/design-system';
import { DashboardWidgetActionButton } from './dashboard-widget-action-button';
import { DashboardWidgetItemHeader } from './dashboard-widget-item-header';
import { useDashboardWidget } from './provider';

interface DashboardWidgetItem {
  onRemove?: MouseEventHandler<HTMLButtonElement>;
  isRemovable?: boolean;
}

type DashboardWidgetProps<C extends React.ElementType> = PolymorphicComponentPropWithoutRef<C, DashboardWidgetItem>;

export const DashboardWidget = <C extends React.ElementType = 'div'>({
  onRemove,
  children,
  as,
  isRemovable = true,
  ...rest
}: DashboardWidgetProps<C>) => {
  const { onWidgetRemove, id, isEditMode } = useDashboardWidget();
  const Component = as || 'div';

  return (
    <Component
      className='virtual-dashboard-widget-container'
      css={[
        css`
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          background: ${theme.colors.white};
          position: relative;
          transition: box-shadow 0.3s ease, background 0.3s ease;
          border-radius: ${theme.borderRadius.medium};
          padding: ${theme.spacing(1.25, 2, 2, 2)};
        `,
        rest?.onClick &&
          !isEditMode &&
          css`
            cursor: pointer;
            :hover {
              background: ${theme.colors.primary5};
            }
          `,
      ]}
      {...rest}
    >
      {isEditMode && isRemovable && (
        <RemoveWidgetButton
          onClick={(e) => {
            onWidgetRemove?.(id);
            onRemove?.(e);
          }}
        />
      )}
      {children}
    </Component>
  );
};

interface RemoveWidgetButton {
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const RemoveWidgetButton = ({ onClick }: RemoveWidgetButton) => {
  return (
    <NakedButton
      onClick={onClick}
      onMouseDown={(e) => e.stopPropagation()}
      css={css`
        background: ${theme.colors.neutral70};
        height: 20px;
        width: 20px;
        border-radius: ${theme.borderRadius.full};
        border: 1px solid ${theme.colors.white};
        display: grid;
        place-items: center;
        position: absolute;
        left: ${theme.spacing(-0.75)};
        top: ${theme.spacing(-1)};
        z-index: ${theme.zIndex.hints};
      `}
    >
      <Icon name='x-tiny' color='white' />
    </NakedButton>
  );
};

type DashboardWidgetContentProps<T extends React.ElementType> = PolymorphicComponentPropWithRef<T>;

export const DashboardWidgetContent = forwardRef(
  <T extends React.ElementType = 'section'>(
    { children, as, ...rest }: DashboardWidgetContentProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const Component = as || 'section';

    return (
      <Component ref={ref} {...rest}>
        {children}
      </Component>
    );
  }
);

DashboardWidgetContent.displayName = 'DashboardWidgetContent';

DashboardWidget.Header = DashboardWidgetItemHeader;
DashboardWidget.Content = DashboardWidgetContent;
DashboardWidget.ActionButton = DashboardWidgetActionButton;
