import { useNotificationContext } from '@frontend/notifications';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { usePhoneOverrideShallowStore } from '@frontend/shared';
import { useSoftphoneManager } from '@frontend/weave-softphone-manager';
import { NotificationsDisabledRibbon } from './notifications-disabled';
import { OverrideNotificationRibbon } from './override-notification';
import { SoftphoneInAnotherTabRibbon } from './softphone-in-another-tab';

export const RibbonManager = () => {
  const isActiveTab = useSoftphoneManager((ctx) => ctx.isActiveTab);
  const activeTab = useSoftphoneManager((ctx) => ctx.activeTab);
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const { maxLocationsSelected } = useNotificationContext();
  const { overrides } = usePhoneOverrideShallowStore('overrides');
  const enabledOverrides = overrides?.filter((override) => override.enabled) || [];

  return (
    <div>
      {maxLocationsSelected && <NotificationsDisabledRibbon />}
      {phoneConfig?.deviceType === 'DEVICE_TYPE_SOFTPHONE' && activeTab && !isActiveTab && (
        <SoftphoneInAnotherTabRibbon />
      )}
      {enabledOverrides.length > 0 && <OverrideNotificationRibbon />}
    </div>
  );
};
