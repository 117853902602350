import { useMemo } from 'react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { weaveStatusColors, weaveStatusLabels } from '../constants';
import { useIsAppointmentDemoAccount } from '../hooks';
import { formatStatusGroupsData } from '../utils/format-appointment-data';
import { AppointmentsChartProps } from './shared-types';

export const AppointmentsWeaveStatusChart = ({ appearance, data, isLoading }: AppointmentsChartProps) => {
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsAppointmentDemoAccount();

  const weaveStatusVolume = useMemo(() => {
    const totals: Record<string, number> = {};
    const labels = Object.values(data.weaveStatusCounts).reduce((acc, value) => {
      Object.keys(value).forEach((key) => {
        // Remove multiple consecutive spaces from the key
        const label = key.replace(/\s+/g, ' ');
        if (!acc[key]) {
          acc[key] = label;
        }
        totals[key] = (totals[key] ?? 0) + value[key];
      });
      return acc;
    }, {} as AnalyticsCommonTypes.StringRecord);

    return {
      data: {
        groups: formatStatusGroupsData(data.weaveStatusCounts, labels),
      },
      totals,
    };
  }, [data.weaveStatusCounts]);

  return (
    <Chart colors={weaveStatusColors} isLoading={isLoading} labels={weaveStatusLabels}>
      <Chart.Header
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Appointment Status Count - Weave')}
        trackingIdHelper='appointment-status-weave'
      />
      <Chart.BarChart appearance={appearance} data={weaveStatusVolume.data} formatValue={formatters.value.format} />
      <Chart.Legends formatValue={formatters.value.format} values={weaveStatusVolume.totals} />
    </Chart>
  );
};
