import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { RefundDateObject } from '@frontend/api-refunds';
import { isoDateToDisplay } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { QuickFillBar, quickRangeConfig } from '../../';
import { getRangeText } from '../../../utils';

const styles = {
  root: css`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: ${theme.spacing(2, 0)};
    margin-bottom: ${theme.spacing(2)};
  `,
};

type DateRangeObject = RefundDateObject;

export type DateFiltersProps = {
  displayDates: [Date, Date] | [];
  dateRange?: DateRangeObject;
  onChange: (dateRange: DateRangeObject) => void;
};
export const DateFilters = ({ displayDates, dateRange, onChange }: DateFiltersProps) => {
  const { t } = useTranslation('payments');

  const config = useMemo(() => quickRangeConfig(t), [t]);

  const filter = useMemo(() => {
    return {
      dateRange: {
        ...(dateRange?.gte ? { start: isoDateToDisplay(dateRange?.gte) } : {}),
        ...(dateRange?.lte ? { end: isoDateToDisplay(dateRange?.lte) } : {}),
      },
    };
  }, [dateRange]);

  const handleOnChange = useCallback(
    ([gte, lte]: string[]) => {
      onChange(gte && lte ? { gte, lte } : {});
    },
    [onChange]
  );

  return (
    <div css={styles.root}>
      <Text size='large' weight='bold'>
        {getRangeText(displayDates)}
      </Text>
      <QuickFillBar config={config} onSelect={handleOnChange} filter={filter} />
    </div>
  );
};
