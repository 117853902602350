import { VoicemailMessage } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/voicemail.pb';
import { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import dayjs from 'dayjs';
import { kebabCase } from 'lodash-es';
import { isEqual } from 'lodash-es';
import { VoicemailApi } from '@frontend/api-voicemails';
import { getWeaveToken } from '@frontend/auth-helpers';
import { getInitialParams } from '@frontend/env';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { VoicemailFiltersType } from '../../hooks/use-phone-config-state-store';
import { getFullName } from '../../utils';
import { VoicemailWithCallRecord } from '../all-calls/types';
import { isDatesEqual } from '../all-calls/utils';

export const downloadVoicemail = async (url: string, callerName: string, date?: string) => {
  const blob = await VoicemailApi.downloadVoicemail(url);
  const objectURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = objectURL;
  link.download = `${kebabCase(`${callerName} ${date}`)}-recording.wav`;

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode?.removeChild(link);
  window.URL.revokeObjectURL(objectURL);
};

export const generateMediaPath = (message: VoicemailWithCallRecord | undefined, locationId: string) => {
  const weaveToken = getWeaveToken();
  const currentEnv = getInitialParams().backendApi;
  const mediaId = message?.forwardedMessageId || message?.mediaId;

  if (!mediaId) {
    return '';
  }
  const mediaPath = `${currentEnv}/phone-exp/voicemail/download/${mediaId}?token=${weaveToken}&location_id=${locationId}`;
  return mediaPath;
};

export const createVoicemailObject = (
  voicemail: VoicemailMessage,
  selectedLocationIdsExcludingParent: string[],
  mappedTags: Record<string, Tag>,
  mailboxToNameMap: Record<string, string>
) => {
  const { person, message, tagIds } = voicemail;
  return {
    firstName: person?.firstName ?? '',
    lastName: person?.lastName ?? '',
    // get the fullname from the person object if it exists. Get the callerName if it's an internal message
    contactName: getFullName(person?.firstName, person?.lastName, message?.isInternal ? message?.callerName : ''),
    contactId: person?.personId ?? '',
    locationIds: message?.locationIds ?? [],
    channelId: message?.channelId ?? '',
    mailboxId: message?.mailboxId ?? '',
    voiceMailBoxName: mailboxToNameMap[message?.mailboxId ?? ''] ?? '',
    forwardedMessageId: message?.forwardedMessageId,
    readAt: message?.readAt,
    dateTime: dayjs(message?.createdAt).format('MMM DD YYYY, hh:mm A'),
    mediaFilePath: generateMediaPath(message, selectedLocationIdsExcludingParent?.[0] ?? ''),
    mediaId: message?.forwardedMessageId || message?.mediaId || '',
    contactNumber: formatPhoneNumber(message?.callerNumber),
    voicemailId: message?.voicemailId ?? '',
    createdAt: message?.createdAt ?? '',
    tagIds: tagIds ?? [],
    tags: tagIds?.map((tagId) => mappedTags[tagId]).filter(Boolean) ?? [],
  };
};

export const hasVMFilterChanged = (currentFilter: VoicemailFiltersType, prevFilter: VoicemailFiltersType): boolean => {
  if (
    isEqual(currentFilter.locationIds, prevFilter.locationIds) &&
    isDatesEqual(currentFilter.filterOptions.startTime, prevFilter.filterOptions.startTime) &&
    isDatesEqual(currentFilter.filterOptions.endTime, prevFilter.filterOptions.endTime) &&
    isEqual(currentFilter.filterOptions, prevFilter.filterOptions)
  ) {
    return false;
  }
  return true;
};
