import { useCallback, useEffect } from 'react';
import saveAs from 'file-saver';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  exportInvoiceHistory,
  getInvoice,
  getInvoiceHistory,
  InvoiceModel,
  InvoiceSearchParams,
  InvoiceFilterType,
  getInvoiceQueryKey,
  invoiceCacheOptions,
  InvoiceDetailModel,
  updateInvoiceQueryCache,
} from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedInfiniteQuery } from '@frontend/location-helpers';
import { useMerchant, paymentsQueryKeys, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useScopedInfiniteQuery } from '@frontend/scope';
import { isEmpty } from '@frontend/validation';
import { useAlert } from '@frontend/design-system';
import { getSearchParams } from './helpers';
import { useInvoiceShallowStore } from './invoice-provider';
import { calculateInvoicePageSummary } from './summary-helpers';

type FetchParams = {
  next: string;
  filter: InvoiceFilterType;
  numRows: number;
  currentPage: number;
  order: InvoiceSearchParams['order'];
  paymentsUrl: string | null;
  locationIds: string[];
};

const fetchInvoices = async ({ next, filter, numRows, currentPage, order, paymentsUrl, locationIds }: FetchParams) => {
  let url;
  let params: InvoiceSearchParams = {};

  if (!next) {
    // initial url
    params = {
      ...getSearchParams(filter, locationIds),
      limit: numRows,
      skip: (currentPage - 1) * numRows,
      order: order ?? '-billedAt',
    };
    url = `${paymentsUrl}/v1/search/invoices`;
  } else {
    url = new URL(`${paymentsUrl?.split('/').slice(0, -1).join('/')}${next}`).toString();
  }

  return await getInvoiceHistory(url, params);
};

type FetchUserInvoiceRangeParams = {
  paymentsUrl: string | null;
  personId: string;
  dateRange: InvoiceFilterType['dateRange'];
  locationIds: string[];
  next?: string;
  numRows?: number;
  currentPage?: number;
  filter?: Omit<InvoiceFilterType, 'person' | 'dateRange'>;
  order?: InvoiceSearchParams['order'];
};
const fetchUserInvoicesInRange = async ({
  paymentsUrl,
  personId,
  dateRange,
  locationIds,
  next,
  numRows = 100,
  currentPage = 1,
  filter = {},
  order,
}: FetchUserInvoiceRangeParams) => {
  const url = `${paymentsUrl}/v1/search/invoices`;

  if (next) {
    const fullNextUrl = new URL(`${paymentsUrl?.split('/').slice(0, -1).join('/')}${next}`).toString();

    return await getInvoiceHistory(fullNextUrl, {});
  }
  const params: InvoiceSearchParams = {
    ...getSearchParams({ ...filter, person: `id:${personId}`, dateRange }, locationIds),
    limit: numRows,
    skip: (currentPage - 1) * numRows,
    order: order ?? '-billedAt',
  };
  return getInvoiceHistory(url, params);
};

export const useQueryPaginatedInvoices = () => {
  const { paymentsUrl } = useMerchant();
  const { filter, currentPage, order, numRows, setCurrentPage, resetCurrentPage } = useInvoiceShallowStore(
    'filter',
    'numRows',
    'currentPage',
    'order',
    'setCurrentPage',
    'resetCurrentPage'
  );

  const { getMultiQueryKey, locationIds } = useMultiQueryUtils({ onLocationChange: () => resetCurrentPage() });
  const queryKey = getMultiQueryKey(['invoices', filter, numRows, order]);

  const { data, refetch, fetchNextPage, isLoading, isFetching } = useLocalizedInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) =>
      fetchInvoices({
        next: pageParam,
        paymentsUrl,
        filter,
        numRows,
        currentPage: 1,
        order,
        locationIds,
      }),
    getNextPageParam: (lastPage) => lastPage.meta?.links.next,
    staleTime: 60 * 1000,
    cacheTime: 2 * 60 * 1000,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,
    enabled: !!paymentsUrl,
  });

  const loading = isLoading || isFetching;
  return {
    loading,
    refetch: () => refetch(), // Just to keep API consistent with saga hook
    invoices: !loading && data?.pages ? data.pages[currentPage - 1]?.data?.invoices ?? [] : [], // The backend returns a `null` if there are no items, instead of `[]`
    summary: !loading && data?.pages ? data.pages[currentPage - 1]?.data?.summary : undefined,
    hasMore: !loading && data?.pages ? !!data.pages[currentPage - 1]?.meta?.links.next : false,
    fetchNextPage: () => {
      if (data?.pages && data?.pages.length === currentPage) {
        fetchNextPage();
      }
      setCurrentPage(currentPage + 1);
    },
    fetchPreviousPage: () => {
      setCurrentPage(Math.max(1, currentPage - 1));
    },
    invoicesQueryKey: queryKey,
  };
};

/**
 * This is used for querying all invoices for the print preview
 * Reuses fetched invoices if no date filter applied (current page view)
 * Updates summary in print view to only include selected invoices
 */

export const useQueryAllInvoices = ({ enable = true }: { enable?: boolean } = {}) => {
  const { paymentsUrl } = useMerchant();
  const { getMultiQueryKey, locationIds } = useMultiQueryUtils();
  const { filter, order, numRows, currentPage } = useInvoiceShallowStore('filter', 'order', 'numRows', 'currentPage');
  const NUM_ROWS = !!filter?.dateRange?.start ? 500 : numRows;
  const CUR_PAGE = !!filter?.dateRange?.start ? 1 : currentPage;
  const getQueryKey = () => {
    if (!!filter?.dateRange?.start) {
      return ['print-invoices', filter, NUM_ROWS, order];
    } else {
      return ['invoices', filter, NUM_ROWS, order];
    }
  };
  const { fetchNextPage, hasNextPage, data, isFetchingNextPage, isLoading, isFetching } = useLocalizedInfiniteQuery({
    enabled: !!paymentsUrl && enable,
    queryKey: getMultiQueryKey(getQueryKey()),
    queryFn: ({ pageParam }) =>
      fetchInvoices({
        next: pageParam,
        paymentsUrl,
        filter,
        numRows: NUM_ROWS,
        currentPage: CUR_PAGE,
        order,
        locationIds,
      }),
    getNextPageParam: (lastPage) => {
      return lastPage.meta?.links.next;
    },
  });
  if (hasNextPage && !isFetchingNextPage && !!filter.dateRange?.start) {
    fetchNextPage();
  }

  const loading = isLoading || isFetching || isFetchingNextPage;

  const invoices =
    (!!filter?.dateRange?.start
      ? data?.pages?.flatMap((page) => page.data?.invoices ?? []).filter((x): x is InvoiceModel => !!x)
      : data?.pages?.[currentPage - 1]?.data?.invoices) ?? [];

  const summary = isEmpty(filter)
    ? invoices?.length
      ? calculateInvoicePageSummary(invoices)
      : undefined
    : data?.pages?.[currentPage - 1]?.data?.summary;

  return {
    loading,
    invoices,
    summary,
  };
};

export const useExportInvoicesQuery = () => {
  const { paymentsUrl } = useMerchant();
  const { getMultiQueryKey, locationIds } = useMultiQueryUtils();
  const { filter, order } = useInvoiceShallowStore('filter');
  const alerts = useAlert();
  const { t } = useTranslation('payments');

  const {
    data: invoiceCSV,
    mutate: exportInvoices,
    isLoading: isExporting,
  } = useMutation({
    mutationKey: getMultiQueryKey([paymentsQueryKeys.invoiceExport, filter, order]),
    mutationFn: () => exportInvoiceHistory(paymentsUrl ?? '', getSearchParams(filter, locationIds)),
    onSuccess: (invoiceCSV: Blob) => {
      alerts.success(t('We’re working on exporting your data. It will download shortly.'));
      saveAs(invoiceCSV, 'invoice-history.csv');
    },
    onError: (err) => {
      alerts.error(t('Failed to export invoice history'));
      console.error(err);
    },
  });

  return { invoiceCSV, isExporting, exportInvoices };
};

const useGetInvoice = (invoiceId?: string) => {
  const { paymentsUrl } = useMerchant();
  const { allLocations, locationId } = useMultiQueryUtils();

  const queryKey = getInvoiceQueryKey(locationId, invoiceId!);
  const { isLoading, isError, data, isFetched, refetch } = useQuery({
    queryKey,
    queryFn: () => getInvoice(paymentsUrl!, invoiceId!, allLocations),
    enabled: !!paymentsUrl && !!invoiceId,
    ...invoiceCacheOptions,
  });
  return {
    invoice: data,
    isLoading,
    isError,
    isFetched,
    refetch,
    queryKey,
  };
};

export const useGetInvoiceNonQuery = (invoiceId?: string) => {
  const { paymentsUrl } = useMerchant();
  const { allLocations } = useMultiQueryUtils();

  const callback = useCallback(() => {
    return getInvoice(paymentsUrl!, invoiceId!, allLocations);
  }, []);

  return callback;
};

export const useUpdateInvoiceQueryCache = (invoiceId?: string) => {
  const queryClient = useQueryClient();
  const { locationId } = useMultiQueryUtils();

  const changeCallback = useCallback(
    (toSet: InvoiceDetailModel) => {
      updateInvoiceQueryCache(queryClient, toSet, getInvoiceQueryKey(locationId, invoiceId!));
    },
    [invoiceId, locationId]
  );

  return changeCallback;
};

export const useSelectedInvoice = (invoiceId?: string) => {
  const { selectedInvoiceId, setSelectedInvoiceId } = useInvoiceShallowStore(
    'selectedInvoiceId',
    'setSelectedInvoiceId'
  );
  const invoiceQueryResult = useGetInvoice(invoiceId ?? selectedInvoiceId);

  useEffect(() => {
    if (invoiceId) setSelectedInvoiceId(invoiceId);
  }, [invoiceId]);

  return invoiceQueryResult;
};

export type UserInvoicesInRangeProps = {
  personId: string;
  dateRange: InvoiceFilterType['dateRange'];
  filter?: Omit<InvoiceFilterType, 'person' | 'dateRange'>;
  order?: InvoiceSearchParams['order'];
};

const INVOICE_RANGE_QUERY_KEY = 'user-invoices-range';

export const useInvalidateUserInvoicesInRange = () => {
  const queryClient = useQueryClient();

  return (personId: string) => {
    queryClient.invalidateQueries({
      predicate({ queryKey }) {
        return queryKey.includes(INVOICE_RANGE_QUERY_KEY) && queryKey.includes(personId);
      },
    });
  };
};
export const useUserInvoicesInRange = ({ personId, dateRange, filter, order }: UserInvoicesInRangeProps) => {
  const { paymentsUrl } = useMerchant();
  const { locationIds, getMultiQueryKey } = useMultiQueryUtils();

  const queryKey = getMultiQueryKey([INVOICE_RANGE_QUERY_KEY, personId, dateRange, filter, order]);

  const { data, isLoading, refetch, hasNextPage, isFetchingNextPage, isFetching, fetchNextPage } =
    useScopedInfiniteQuery({
      queryKey,
      queryFn: ({ pageParam }) =>
        fetchUserInvoicesInRange({ next: pageParam, personId, dateRange, filter, order, paymentsUrl, locationIds }),
      enabled: !!paymentsUrl && !!personId,
      getNextPageParam: (lastPage) => {
        return lastPage.meta?.links.next;
      },
    });

  if (hasNextPage && !isFetchingNextPage) {
    fetchNextPage();
  }

  const loading = isLoading || isFetching || isFetchingNextPage;

  const invoices =
    data?.pages?.flatMap((page) => page.data?.invoices ?? []).filter((x): x is InvoiceModel => !!x) || [];

  return {
    isLoading: loading,
    invoices,
    refetch,
  };
};
