import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  padding: ${theme.spacing(1)};
  margin: ${theme.spacing(1)};
  border: 1px solid ${theme.colors.neutral10};
  border-radius: ${theme.borderRadius.small};
`;

export const headingStyle = css`
  color: ${theme.colors.neutral50};
`;
