import { BannerType, GlobalBannerDynamic } from '@frontend/global-info-banner';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useBrandVerification } from '../../hooks/use-brand-verification';

export const BusinessInformationBanner = () => {
  const { t } = useTranslation('messages');
  const { selectedLocationIds } = useAppScopeStore();
  const { navigate } = useSettingsNavigate();
  const { unverifiedCount } = useBrandVerification({
    locationIds: selectedLocationIds,
  });

  const banners = [
    {
      condition: !!unverifiedCount,
      id: 'business-verification-banner',
      title: t('Business Information: 0 of 2 Required Settings Complete!'),
      message: t('Your business needs to be verified with Weave. Action is required to complete your account setup.'),
      action: {
        label: 'Start Business Verification',
        onClick: () => navigate({ to: '/organization/business-verification' }),
      },
      type: 'error',
    },
  ];

  return (
    <>
      {banners.map(
        ({ condition, id, title, message, action, type }) =>
          condition && (
            <GlobalBannerDynamic
              key={id}
              id={id}
              type={type as BannerType}
              title={title}
              message={message}
              action={action ?? undefined}
              isDismissible={false}
            />
          )
      )}
    </>
  );
};
