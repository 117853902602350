import { FC } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { default as PracticeAnalyticsBannerImg } from '../../../assets/practice-analytics-banner.png';
import { SUBSCRIPTION_SETTINGS_URL } from '../../../constants/self-upgrade';
import { getScheduleDemoLink } from '../../../self-upgrade.util';
import { FeatureUpgradeModalComponentProps } from '../../../types';
import { FeatureUpgradeDetailsBaseModal } from '../FeatureUpgradeDetailsBaseModal';
import { PRACTICE_ANALYTICS_PRICE, PRACTICE_ANALYTICS_VIDEO_URL } from './constants';

export const PracticeAnalyticsUpgradeDetailsModal: FC<FeatureUpgradeModalComponentProps> = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'practice-analytics-details-modal' });

  const handleScheduleCall = () => {
    window.open(getScheduleDemoLink(Feature.PRACTICE_ANALYTICS, getUser()?.username), '_blank', 'noopener,noreferrer');
  };

  return (
    <FeatureUpgradeDetailsBaseModal
      featureAccountSettingsUrl={SUBSCRIPTION_SETTINGS_URL}
      featureName={t('Practice Analytics')}
      onScheduleCallClick={handleScheduleCall}
      bundleFeature={t('Ultimate Bundle Feature')}
      {...props}
    >
      <FeatureUpgradeDetailsBaseModal.Title title={t('Practice Analytics')} price={PRACTICE_ANALYTICS_PRICE} />
      <FeatureUpgradeDetailsBaseModal.ScrollableContent>
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.VideoText
          videoUrl={PRACTICE_ANALYTICS_VIDEO_URL}
          text={t(
            "Weave Practice Analytics tool tracks unscheduled treatment plans and provides real-time insights into potential missed revenue. Identify unscheduled opportunities, make data-driven decisions, and optimize workflows to maximize revenue, boosting offices' efficiency and revenue."
          )}
        />
        <PracticeAnalyticsFeatureCards />
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.BannerText
          imageUrl={PracticeAnalyticsBannerImg}
          imageAltText={t('Practice Analytics Banner')}
          title={t('Get Practice Analytics')}
        />
      </FeatureUpgradeDetailsBaseModal.ScrollableContent>
      <FeatureUpgradeDetailsBaseModal.Action />
    </FeatureUpgradeDetailsBaseModal>
  );
};

export const PracticeAnalyticsFeatureCards: FC = () => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'practice-analytics-details-modal' });

  return (
    <div css={featureCardsLayoutStyles}>
      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard title={t('Real Time Data')} icon='clock-small'>
        <Text size='medium'>
          {t(
            'Practice Analytics software provides real-time visibility into treatment plan statuses, ensuring no opportunities are overlooked. Maximize patient care, revenue, and resource utilization with comprehensive tracking and data-driven decision-making.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Advanced Scheduling')}
        icon='schedule-pulse-small'
      >
        <Text size='medium'>
          {t(
            'Practice Analytics offers advanced scheduling features with click-to-call and messaging capabilities, streamlining the process and optimizing appointment slots based on unscheduled patient treatment plans and staff availability. Increase productivity, reduce wait times, and improve patient satisfaction.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Compare Performance')}
        icon='analytics-info-small'
      >
        <Text size='medium'>
          {t(
            'Practice Analytics allows you to benchmark your performance against industry standards and similar practices. Measure conversion rates, treatment plan acceptance, and more to identify areas for improvement and make informed decisions to drive practice growth.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Increase Revenue')}
        icon='dollar-sign-small'
      >
        <Text size='medium'>
          {t(
            'Practice Analytics enables personalized reminders, educational materials, and incentives to engage patients. Increase treatment plan acceptance, foster patient loyalty, optimize resource utilization, and achieve better oral health outcomes for patients while driving revenue growth.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>
    </div>
  );
};

const featureCardsLayoutStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});
