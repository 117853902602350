import { useEffect, useRef } from 'react';
import { CallRouteV1 } from '@frontend/api-call-route-v1';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { TextLink, Tray, Tabs, Heading, Table, Chip, useAlert } from '@frontend/design-system';
import { trackingId } from '../../tracking';
import { ExtendedCallRoute } from '../../views/settings/types';
import { EmptyStateSection } from './empty-state-section';

export enum NumbersPanelTabs {
  PHONE_NUMBERS = 'phone-numbers',
  EXTENSIONS = 'extensions',
}

type Props = {
  initialTab?: NumbersPanelTabs;
  callRoute: ExtendedCallRoute;
  hasChildLocations: boolean;
  onEditPhoneNumbers: (callRouteId: string) => void;
  onEditExtensions: (callRouteId: string) => void;
};

export const NumbersPanel = ({
  callRoute,
  initialTab = NumbersPanelTabs.PHONE_NUMBERS,
  hasChildLocations,
  onEditPhoneNumbers,
  onEditExtensions,
}: Props) => {
  const { t } = useTranslation('phone');
  const alerts = useAlert();
  const { getLocationName } = useAppScopeStore();

  // Keep the latest callRoute in a ref to use in async callbacks (for "undo" actions)
  const callRouteRef = useRef(callRoute);

  useEffect(() => {
    callRouteRef.current = callRoute;
  }, [callRoute]);

  const { mutate: updateCallRoutePhoneNumbers, isLoading: updateCallRoutePhoneNumbersIsLoading } =
    CallRouteV1.Mutations.useUpdatePhoneNumbersMutation();

  const { mutate: updateCallRouteExtensions, isLoading: updateCallRouteExtensionsIsLoading } =
    CallRouteV1.Mutations.useUpdateExtensionsMutation();

  const handleRemovePhoneNumber = (phoneNumberId: string) => {
    const prevPhoneNumberIds = callRoute.phoneNumbers.map((phoneNumber) => phoneNumber.id);
    const newPhoneNumberIds = prevPhoneNumberIds.filter((id) => id !== phoneNumberId);

    updateCallRoutePhoneNumbers(
      {
        callRouteId: callRoute.callRouteId,
        phoneNumberIds: newPhoneNumberIds,
      },
      {
        onSuccess: () => {
          alerts.success({
            message: t('Phone Number removed successfully.'),
            action: {
              label: t('Undo'),
              onClick: () => handleUndoRemovePhoneNumber(phoneNumberId),
            },
          });
        },
        onError: () => {
          alerts.error(t('Failed to remove phone number.'));
        },
      }
    );
  };

  const handleUndoRemovePhoneNumber = (phoneNumberId: string) => {
    const latestCallRoute = callRouteRef.current;
    if (!latestCallRoute) return;

    const phoneNumberIds = [
      // We need to get the phone numbers from the latest version of the callRoute prop as
      // as opposed to the value stored due to lexical scoping in the async method so that
      // when we do the update to add the phone number back, we use the latest value of
      // phone numbers so that we avoid updating with older phone numbers that may have been
      // removed since. This can happen if the user removes multiple phone numbers in quick
      // succession and then tries to undo the removal of a phone number that was removed.
      ...(latestCallRoute?.phoneNumbers.map((phoneNumber) => phoneNumber.id) ?? []),
      phoneNumberId,
    ];
    updateCallRoutePhoneNumbers(
      {
        callRouteId: callRoute.callRouteId,
        phoneNumberIds,
      },
      {
        onSuccess: () => {
          alerts.success(t('Phone Number restored successfully.'));
        },
        onError: () => {
          alerts.error(t('Failed to restore phone number.'));
        },
      }
    );
  };

  const handleRemoveExtension = (extensionToRemove: number) => {
    const prevExtensions = callRoute.extensionNumbers;
    const newExtensions = prevExtensions.filter((ext) => ext !== extensionToRemove);

    updateCallRouteExtensions(
      {
        callRouteId: callRoute.callRouteId,
        extensionNumbers: newExtensions,
      },
      {
        onSuccess: () => {
          alerts.success({
            message: t('Extension removed successfully.'),
            action: {
              label: t('Undo'),
              onClick: () => handleUndoRemoveExtension(extensionToRemove),
            },
          });
        },
        onError: () => {
          alerts.error(t('Failed to remove extension.'));
        },
      }
    );
  };

  const handleUndoRemoveExtension = (removedExtension: number) => {
    const latestCallRoute = callRouteRef.current;
    if (!latestCallRoute) return;

    const extensionNumbers = [...(latestCallRoute?.extensionNumbers ?? []), removedExtension];
    updateCallRouteExtensions(
      {
        callRouteId: callRoute.callRouteId,
        extensionNumbers,
      },
      {
        onSuccess: () => {
          alerts.success(t('Extension restored successfully.'));
        },
        onError: () => {
          alerts.error(t('Failed to restore extension.'));
        },
      }
    );
  };

  return (
    <Tray.Body>
      <Tabs initialTab={initialTab}>
        <Tabs.Bar fullWidth={false}>
          <Tabs.Tab
            id='phone-numbers'
            controls='phone-numbers-panel'
            trackingId={trackingId({
              context: 'setting',
              feature: 'call-routes',
              details: 'side-panel-phone-numbers-tab',
            })}
          >
            {t('Phone Numbers')}
          </Tabs.Tab>
          <Tabs.Tab
            id='extensions'
            controls='extensions-panel'
            trackingId={trackingId({
              context: 'setting',
              feature: 'call-routes',
              details: 'side-panel-extensions-tab',
            })}
          >
            {t('Extensions')}
          </Tabs.Tab>
        </Tabs.Bar>

        <Tabs.Panel id='phone-numbers-panel' controller='phone-numbers'>
          <>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: theme.spacing(2),
              }}
            >
              <Heading level={3}>{t('Phone Numbers')}</Heading>
              <TextLink
                onClick={() => onEditPhoneNumbers(callRoute.callRouteId)}
                weight='bold'
                css={{ marginBottom: theme.spacing(2) }}
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'side-panel-edit-phone-numbers-link',
                })}
              >
                {t('Edit Phone Numbers')}
              </TextLink>
            </div>

            {callRoute.phoneNumbers.length > 0 ? (
              <Table
                data={callRoute.phoneNumbers}
                globalTrackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'side-panel-numbers-table',
                })}
                isPaginated={true}
                colConfig={[
                  {
                    Header: t('Phone Number'),
                    accessor: (phoneNumber) => phoneNumber.phoneNumber.nationalNumber,
                    id: 'phone-number',
                    cellRenderer: (phoneNumber) => formatPhoneNumber(phoneNumber),
                    width: 180,
                  },
                  {
                    Header: t('Location'),
                    accessor: (phoneNumber) => phoneNumber.locationId,
                    cellRenderer: (locationId) =>
                      locationId ? <Chip.SingleChip>{getLocationName(locationId)}</Chip.SingleChip> : '',
                    id: 'location-name',
                    sortType: 'caseInsensitive',
                    width: 190,
                    omit: !hasChildLocations,
                  },
                ]}
                rowActions={{
                  actions: [
                    {
                      label: t('Remove phone number'),
                      Icon: () => <Icon name='remove' />,
                      onClick: (phoneNumber) => handleRemovePhoneNumber(phoneNumber.id),
                      disabled: updateCallRoutePhoneNumbersIsLoading,
                    },
                  ],
                }}
              />
            ) : (
              <EmptyStateSection
                title={t('No Items to Display')}
                subtitle={t('Click “Edit Phone Numbers" to assign a phone number to this Call Route')}
              />
            )}
          </>
        </Tabs.Panel>

        <Tabs.Panel id='extensions-panel' controller='extensions'>
          <>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: theme.spacing(2),
              }}
            >
              <Heading level={3}>{t('Extensions')}</Heading>
              <TextLink
                onClick={() => onEditExtensions(callRoute.callRouteId)}
                weight='bold'
                trackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'side-panel-edit-extensions-link',
                })}
                css={{ marginBottom: theme.spacing(2) }}
              >
                {t('Edit Extensions')}
              </TextLink>
            </div>

            {callRoute.extensionNumbers.length > 0 ? (
              <Table
                data={callRoute.extensionNumbers.map((extension) => ({ extension }))}
                isPaginated={true}
                globalTrackingId={trackingId({
                  context: 'setting',
                  feature: 'call-routes',
                  details: 'side-panel-extensions-table',
                })}
                colConfig={[
                  {
                    Header: t('Extension'),
                    accessor: (row) => row.extension,
                    id: 'extension',
                  },
                ]}
                rowActions={{
                  actions: [
                    {
                      label: t('Remove extension'),
                      Icon: () => <Icon name='remove' />,
                      onClick: (row) => handleRemoveExtension(row.extension),
                      disabled: updateCallRouteExtensionsIsLoading,
                    },
                  ],
                }}
              />
            ) : (
              <EmptyStateSection
                title={t('No Items to Display')}
                subtitle={t('Click “Edit Extensions" to assign an extension to this Call Route')}
              />
            )}
          </>
        </Tabs.Panel>
      </Tabs>
    </Tray.Body>
  );
};
