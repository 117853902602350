import { getFormattedCallerName, getFullName } from '../../utils';
import { HydratedCallRecord } from '../all-calls/types';

export const getFallbackCallerName = (callInfo: HydratedCallRecord) => {
  const { direction, callerNumber, dialedNumber } = callInfo;
  return direction === 'inbound'
    ? getFormattedCallerName(callerNumber ?? '')
    : getFormattedCallerName(dialedNumber ?? '');
};

export const getOfficeUser = (callInfo: HydratedCallRecord) => {
  const { user, status, direction, sipName } = callInfo;
  // if it is an inbound call with status abandoned or missed, then show `--`, otherwise use sipName
  const defaultValue =
    direction === 'inbound' && (status === 'abandoned' || status === 'missed') ? '--' : sipName ? sipName : '';
  const fullName = getFullName(user?.firstName, user?.lastName, defaultValue);
  return fullName;
};

export const getShortHandName = (callInfo: HydratedCallRecord) => {
  const { person } = callInfo;
  return !!person?.firstName ? `${person?.firstName ?? ''}` : getFallbackCallerName(callInfo);
};
