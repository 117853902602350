import { http } from '@frontend/fetch';
import { BASE_URL_ANALYTICS } from '../constants';
import {
  FeedbackMap,
  GetAnalyticsLocationsRequest,
  GetAnalyticsLocationsResponse,
  LastContactedRequest,
  LastContactedResponse,
  SubmitFeedback,
} from './types';

export const submitFeedback = ({ payload, onSuccess, onError }: SubmitFeedback) => {
  const modPayload = { ...payload };

  // If any value not present in feedback_map, omit the key
  Object.keys(modPayload.feedback_map).forEach((key) => {
    const typedKey = key as keyof FeedbackMap;

    if (!modPayload.feedback_map[typedKey]) {
      delete modPayload.feedback_map[typedKey];
    }
  });

  if (!modPayload.feedback_map.action) {
    modPayload.feedback_map.action = 'stay';
  }

  return http.post<unknown>(`/insurance-verification/v1/feedback`, modPayload).then(onSuccess).catch(onError);
};

export const getAnalyticsLocations = async (payload: GetAnalyticsLocationsRequest, isDemoAccount?: boolean) => {
  if (typeof isDemoAccount === 'undefined' || isDemoAccount) {
    return null;
  }

  const response = await http.post<{ data: GetAnalyticsLocationsResponse }>(
    '/analytics-reporting/analytics_gateway/getallstats',
    payload
  );

  return response.data;
};

export const getLastContactedDates = async (payload: LastContactedRequest) => {
  if (!payload.person_id.length || !payload.start_time || !payload.end_time) {
    return [];
  }

  // Use promise to make multiple requests to the api as endpoint tends to fail when huge number of person ids are passed
  const chunkSize = 500;
  const personIdChunks = Array.from({ length: Math.ceil(payload.person_id.length / chunkSize) }, (_, i) =>
    payload.person_id.slice(i * chunkSize, i * chunkSize + chunkSize)
  );

  const requests = personIdChunks.map((ids) =>
    http.post<LastContactedResponse>(`${BASE_URL_ANALYTICS}/phonereports/v2/getpersonlastcontacted`, {
      ...payload,
      person_id: ids,
    })
  );

  const responses = await Promise.all(requests);

  return responses.reduce((acc, { data }) => acc.concat(data), [] as LastContactedResponse['data']);
};
