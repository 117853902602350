import { TCRBrandIdentityStatus } from '@weave/schema-gen-ts/dist/schemas/tendlc/shared/v1/shared.pb';
import { useQuery, useQueries, UseQueryOptions } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaTenCoordinatorV1Service } from '../service';
import { GetLocationTCRBrandIO } from '../types';

export const getGetLocationTCRBrandQueryKey = (req: GetLocationTCRBrandIO['input']) =>
  ['TenCoordinatorV1', 'GetLocationTCRBrand', req] as const;
type QueryKey = ReturnType<typeof getGetLocationTCRBrandQueryKey>;

export const getCompleteBrandVerificationQueryKey = (req: string[]) =>
  ['TenCoordinatorV1', 'GetLocationTCRBrand', 'CompleteBrandVerification', ...req] as const;
type CompleteBrandVerificationQueryKey = ReturnType<typeof getCompleteBrandVerificationQueryKey>;

export const getUnverifiedLocationCountQueryKey = (req: string[]) =>
  ['TenCoordinatorV1', 'GetLocationTCRBrand', 'UnverifiedCount', ...req] as const;
type UnverifiedLocationCountQueryKey = ReturnType<typeof getUnverifiedLocationCountQueryKey>;

/**
 * A hook that returns a query for the `GetLocationTCRBrand` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetLocationTCRBrandQuery = <E = unknown, D = GetLocationTCRBrandIO['output']>(
  req: GetLocationTCRBrandIO['input'],
  options?: LimitedSchemaQueryOptions<GetLocationTCRBrandIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<GetLocationTCRBrandIO['output'], E, D, QueryKey>({
    queryKey: getGetLocationTCRBrandQueryKey(req),
    queryFn: () => SchemaTenCoordinatorV1Service.GetLocationTCRBrand(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `GetLocationTCRBrand` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetLocationTCRBrandQueries = <E = unknown, D = GetLocationTCRBrandIO['output']>(
  argsArr: {
    req: GetLocationTCRBrandIO['input'];
    options?: LimitedSchemaQueryOptions<GetLocationTCRBrandIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getGetLocationTCRBrandQueryKey(req),
      queryFn: () => SchemaTenCoordinatorV1Service.GetLocationTCRBrand(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useGetLocationTCRBrandQuery<E, D>>[];

/**
 * A hook that returns if all locations in a multilocation are brand verified.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetCompleteBrandVerificationQuery = <E = unknown, D = boolean>(req: string[]) =>
  useQuery<boolean, E, D, CompleteBrandVerificationQueryKey>({
    queryKey: getCompleteBrandVerificationQueryKey(req),
    queryFn: async () => {
      const locationData = await Promise.all(
        req.map((locationId) =>
          SchemaTenCoordinatorV1Service.GetLocationTCRBrand(
            {
              locationId,
            },
            {
              headers: {
                'Location-Id': locationId,
              },
            }
          )
        )
      );

      return locationData.every(
        (result) =>
          result.tcrBrand.identityStatus === TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VERIFIED ||
          result.tcrBrand.identityStatus === TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED
      );
    },
  });

/**
 * A hook that returns the count of unverified locations in a multilocation.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetUnverifiedLocationCountQuery = <E = unknown, D = number>(
  req: string[],
  options?: UseQueryOptions<number, E, D, UnverifiedLocationCountQueryKey>
) =>
  useQuery<number, E, D, UnverifiedLocationCountQueryKey>({
    queryKey: getUnverifiedLocationCountQueryKey(req),
    queryFn: async () => {
      const locationData = await Promise.all(
        req.map((locationId) =>
          SchemaTenCoordinatorV1Service.GetLocationTCRBrand(
            {
              locationId,
            },
            {
              headers: {
                'Location-Id': locationId,
              },
            }
          )
        )
      );

      return locationData.filter(
        (result) =>
          result.tcrBrand.identityStatus !== TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VERIFIED &&
          result.tcrBrand.identityStatus !== TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED
      ).length;
    },
    ...options,
  });
