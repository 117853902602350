import { FC, ReactNode } from 'react';
import { css } from '@emotion/react';
import { AnalysisType } from '@weave/schema-gen-ts/dist/shared/call-intelligence/enums.pb';
import { AnalyticsCommonTypes, CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CheckIcon, Chip, FeedbackBadIcon, FeedbackIcon, IconProps, Text, XIcon } from '@frontend/design-system';
import { Thumb } from '../../thumb';
import { FIELD_MAPPING } from '../modal/edit-history-modal';
import { ServiceQualityBadge } from '../service-quality-badge';
import { useCallIntelShallowStore } from './use-call-intel-store';

type Variant = 'array' | 'filterRecord' | 'record';
type Options = { id: string; label: string }[];
export type RecordOptions = Record<string, ReactNode> | AnalyticsCommonTypes.StringRecord;
type OptionsReturnType<V extends Variant> = V extends 'array' ? Options : RecordOptions;

export type OptionType =
  | 'appointmentTypes'
  | 'callDirection'
  | 'categories'
  | 'contactType'
  | 'feedbackIssueTypes'
  | 'schedulingOutcome'
  | 'schedulingOpportunity'
  | 'sentiment'
  | 'serviceQualityFlags';

const renderOpportunityOption = (
  mainText: string,
  subText: string,
  Icon: FC<IconProps>,
  variant: 'primary' | 'neutral'
) => (
  <>
    <div css={styles.chipLabel}>
      <Chip css={styles.chip} variant={variant}>
        <Icon size={16} />
      </Chip>
      <Text>{mainText}</Text>
    </div>
    <Text color='subdued' size='small'>
      {subText}
    </Text>
  </>
);
export const useOptionsProvider = () => {
  const { t } = useTranslation('analytics');
  const { availableAppointmentTypes, availableCategories, chipVariants, dataLabels } = useCallIntelShallowStore(
    'availableAppointmentTypes',
    'availableCategories',
    'chipVariants',
    'dataLabels'
  );

  const feedbackIssueTypesOptions = <V extends Variant>(variant: V): OptionsReturnType<V> => {
    const options = [
      { id: AnalysisType.ANALYSIS_TYPE_APPOINTMENT_TYPE, label: t('Appointment Type') },
      { id: AnalysisType.ANALYSIS_TYPE_CATEGORY, label: t('Category') },
      { id: AnalysisType.ANALYSIS_TYPE_CONTACT_TYPE, label: t('Contact Type') },
      { id: AnalysisType.ANALYSIS_TYPE_SENTIMENT, label: t('Customer Sentiment') },
      { id: AnalysisType.ANALYSIS_TYPE_OTHER, label: t('Other') },
      { id: AnalysisType.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY, label: t('Scheduling Opportunity') },
      { id: AnalysisType.ANALYSIS_TYPE_SUMMARY, label: t('Summary') },
      { id: AnalysisType.ANALYSIS_TYPE_TASKS, label: t('Task') },
      { id: AnalysisType.ANALYSIS_TYPE_TRANSCRIPT, label: t('Transcript') },
    ];

    if (variant === 'array') {
      return options as OptionsReturnType<V>;
    }

    return options.reduce((acc, { id, label }) => {
      acc[id] = label;
      return acc;
    }, {} as RecordOptions) as OptionsReturnType<V>;
  };

  const appointmentTypesOptions = <V extends Variant>(variant: V): OptionsReturnType<V> => {
    const options = availableAppointmentTypes.filter(
      (type) => type !== CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_UNKNOWN
    );

    if (variant === 'record') {
      return options.reduce((acc, type) => {
        acc[type] = (
          <Chip css={styles.chip} variant={chipVariants[type] || 'neutral'}>
            {dataLabels.appointmentTypes?.[type] || type}
          </Chip>
        );
        return acc;
      }, {} as RecordOptions) as OptionsReturnType<V>;
    }

    return options.map((type) => ({
      id: type,
      label: dataLabels.appointmentTypes?.[type] || type,
    })) as OptionsReturnType<V>;
  };

  const callDirectionOptions: RecordOptions = {
    [CallIntelligenceTypes.CallDirectionEnum.DIRECTION_INBOUND]: t('Inbound Calls'),
    [CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND]: t('Outbound Calls'),
  };

  const categoryOptions = <V extends Variant>(variant: V): OptionsReturnType<V> => {
    const options = availableCategories.filter((type) => type !== CallIntelligenceTypes.CategoryEnum.CATEGORY_UNKNOWN);

    if (variant === 'record') {
      return options.reduce((acc, type) => {
        acc[type] = (
          <Chip css={styles.chip} variant={chipVariants[type] || 'neutral'}>
            {dataLabels.categories?.[type] || type}
          </Chip>
        );
        return acc;
      }, {} as RecordOptions) as OptionsReturnType<V>;
    }

    return options.map((type) => ({
      id: type,
      label: dataLabels.categories?.[type] || type,
    })) as OptionsReturnType<V>;
  };

  const contactTypeOptions: RecordOptions = {
    [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NEW_PATIENT]: (
      <Chip css={styles.chip} variant='warn'>
        {t('New Patient')}
      </Chip>
    ),
    [CallIntelligenceTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT]: (
      <Chip css={styles.chip} variant='primary'>
        {t('Existing Patient')}
      </Chip>
    ),
    [CallIntelligenceTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT]: (
      <Chip css={styles.chip} variant='neutral'>
        {t('Not a Patient')}
      </Chip>
    ),
  };

  const schedulingOutcomesOptions = (variant: Variant, additionalProps?: Record<string, any>): RecordOptions => {
    const { withoutLabel, includeEmptyOption } = additionalProps ?? {};
    //This could be simplified more but it has been done intentionally as in future filterrecords will look differently as normal records
    if (variant === 'filterRecord') {
      return {
        [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]: (
          <div css={styles.chipLabel}>
            <Chip css={styles.chip} variant='success'>
              <CheckIcon size={16} />
            </Chip>
            {!withoutLabel && <Text>{t('Scheduled Appointment')}</Text>}
          </div>
        ),
        [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]: (
          <div css={styles.chipLabel}>
            <Chip css={styles.chip} variant='warn'>
              <XIcon size={16} />
            </Chip>
            {!withoutLabel && <Text>{t('Unscheduled Appointment')}</Text>}
          </div>
        ),
      };
    }

    return {
      [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]: (
        <div css={styles.chipLabel}>
          <Chip css={styles.chip} variant='success'>
            <CheckIcon size={16} />
          </Chip>
          {!withoutLabel && <Text>{t('Scheduled')}</Text>}
        </div>
      ),
      [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]: (
        <div css={styles.chipLabel}>
          <Chip css={styles.chip} variant='warn'>
            <XIcon size={16} />
          </Chip>
          {!withoutLabel && <Text>{t('Unscheduled')}</Text>}
        </div>
      ),
      ...(includeEmptyOption && {
        none: FIELD_MAPPING[CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME].emptyValue,
      }),
    };
  };

  const schedulingOpportunityOptions = (variant: Variant, additionalProps?: Record<string, any>): RecordOptions => {
    const { withoutLabel } = additionalProps ?? {};

    if (variant === 'filterRecord') {
      return {
        [CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: renderOpportunityOption(
          t('Identified Scheduling Opportunity'),
          t('There was potential to schedule an appointment during the call.'),
          FeedbackIcon,
          'primary'
        ),
        [CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED]:
          renderOpportunityOption(
            t('No Scheduling Opportunity'),
            t("There wasn't potential to schedule an appointment during the call."),
            FeedbackBadIcon,
            'neutral'
          ),
      };
    }

    return {
      [CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]: (
        <div css={styles.chipLabel}>
          <Thumb up />
          {!withoutLabel && <Text>{t('Opportunity')}</Text>}
        </div>
      ),
      [CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED]: (
        <div css={styles.chipLabel}>
          <Thumb up={false} />
          {!withoutLabel && <Text>{t('No Opportunity')}</Text>}
        </div>
      ),
    };
  };

  const sentimentOptions = <V extends Variant>(variant: V): OptionsReturnType<V> => {
    const options: CallIntelligenceTypes.SentimentEnum[] = [
      CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE,
      CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL,
      CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE,
    ];

    if (variant === 'record') {
      return options.reduce((acc, type) => {
        acc[type] = (
          <Chip css={styles.chip} variant={chipVariants[type] || 'neutral'}>
            {dataLabels.sentimentsWithEmoji?.[type] || type}
          </Chip>
        );
        return acc;
      }, {} as RecordOptions) as V extends 'array' ? Options : RecordOptions;
    }

    return options.map((type) => ({
      id: type,
      label: dataLabels.sentimentsWithEmoji?.[type] || type,
    })) as V extends 'array' ? Options : RecordOptions;
  };

  const serviceQualityOptions = <V extends Variant>(_: V, additionalProps?: Record<string, any>): RecordOptions => {
    return {
      [CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: (
        <ServiceQualityBadge
          label={
            dataLabels?.serviceQualityFlag?.[CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION] ||
            CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION
          }
          type={CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION}
          {...additionalProps}
        />
      ),
      [CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: (
        <ServiceQualityBadge
          label={
            dataLabels?.serviceQualityFlag?.[CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE] ||
            CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE
          }
          type={CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE}
          {...additionalProps}
        />
      ),
    };
  };

  const getOptions = <T extends OptionType, V extends Variant = 'record'>(
    type: T,
    variant?: V,
    additionalProps?: Record<string, any>
  ): OptionsReturnType<V> => {
    const resolvedVariant = variant || 'record';

    switch (type) {
      case 'appointmentTypes':
        return appointmentTypesOptions(resolvedVariant) as OptionsReturnType<V>;
      case 'callDirection':
        return callDirectionOptions as OptionsReturnType<V>;
      case 'categories':
        return categoryOptions(resolvedVariant) as OptionsReturnType<V>;
      case 'feedbackIssueTypes':
        return feedbackIssueTypesOptions(resolvedVariant) as OptionsReturnType<V>;
      case 'schedulingOutcome':
        return schedulingOutcomesOptions(resolvedVariant, additionalProps) as OptionsReturnType<V>;
      case 'contactType':
        return contactTypeOptions as OptionsReturnType<V>;
      case 'schedulingOpportunity':
        return schedulingOpportunityOptions(resolvedVariant, additionalProps) as OptionsReturnType<V>;
      case 'sentiment':
        return sentimentOptions(resolvedVariant) as OptionsReturnType<V>;
      case 'serviceQualityFlags':
        return serviceQualityOptions(resolvedVariant, additionalProps) as OptionsReturnType<V>;
      default:
        throw new Error(`Unsupported option type: ${type}`);
    }
  };

  return { getOptions };
};

const styles = {
  chip: css`
    max-width: none;
  `,
  chipLabel: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(0.5)};
  `,
};
