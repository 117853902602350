import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

interface BannerContentProps {
  children: React.ReactNode;
}

export const BannerContentWrapper = ({ children }: BannerContentProps) => (
  <motion.div
    css={[
      css`
        display: flex;
        align-items: center;
        height: ${theme.spacing(5.5)};

        @container (max-width: ${breakpoints.small.min}px) {
          height: ${theme.spacing(9.5)};
          min-height: ${theme.spacing(9.5)};
        }
      `,
    ]}
  >
    {children}
  </motion.div>
);
