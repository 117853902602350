import { FC, useState, useEffect, useCallback } from 'react';
import { css } from '@emotion/react';
import { Options } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { FormsSourceTenants, FormsMedicalConditions } from '@frontend/api-forms';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion, ContentLoader } from '@frontend/design-system';
import { useLocationAccordionContext } from '../../../context';
import { usePmsSelector } from '../../../shared/hooks';
import { ConditionList } from './condition-list/condition-list.component';
import { applyBackgroundStyle, accordionBaseStyle, spacingFixStyle } from './medical-conditions.style';
import { getMedicalConditionsUIMeta } from './utils';

const AVAILABLE_MEDICAL_CONDITIONS: FormsMedicalConditions.Types.MedicalConditionName[] = [
  'ALLERGY',
  'DISEASE',
  'MEDICATION',
];

const EMPTY_OPTIONS: Options[] = [];

export const PmsDisplayComponent: FC = () => {
  const { t } = useTranslation('forms');
  const [selectedSourceTenantId, setSelectedSourceTenantId] = useState('');
  const { locationId } = useLocationAccordionContext();
  const [availableSources, setAvailableSources] = useState<FormsSourceTenants.Types.ModifiedSourceTenant[]>([]);
  const { isLoadingMedicalConditionSources, getSourcesWithMedicalConditionsForLocation } =
    DigitalFormScopeHooks.useMedicalConditionSources();
  const { PmsSelectorPopoverDialog, pmsSelectorPopoverDialogControls } = usePmsSelector({
    popoverDialogConfig: { placement: 'bottom-start' },
  });

  const { data, isLoading } = DigitalFormsQueries.useGetPMSMedicalConditions(
    {
      companyId: locationId,
      sourceTenantId: selectedSourceTenantId,
    },
    { enabled: !!selectedSourceTenantId }
  );

  // Get the list of sources with medical conditions for the selected location.
  useEffect(() => {
    if (isLoadingMedicalConditionSources) {
      return;
    }

    const sources = getSourcesWithMedicalConditionsForLocation(locationId);
    setAvailableSources(sources);
  }, [locationId, isLoadingMedicalConditionSources, getSourcesWithMedicalConditionsForLocation]);

  // Select the first source when the available sources are loaded.
  useEffect(() => {
    if (selectedSourceTenantId || availableSources.length === 0) {
      return;
    }

    setSelectedSourceTenantId(availableSources[0].sourceTenantId);
  }, [availableSources, selectedSourceTenantId]);

  const selectSourceHandler = useCallback(
    (newSource: FormsSourceTenants.Types.ModifiedSourceTenant) => {
      setSelectedSourceTenantId(newSource.sourceTenantId);
    },
    [setSelectedSourceTenantId]
  );

  if (isLoading) {
    return (
      <section css={containerStyle}>
        <ContentLoader show={isLoading} message={t('Loading PMS data')} />;
      </section>
    );
  }

  return (
    <>
      <PmsSelectorPopoverDialog
        popoverDialogControls={pmsSelectorPopoverDialogControls}
        availableSources={availableSources}
        onSelectSource={selectSourceHandler}
        type='source-tenant-id'
        selectedSourceTenantId={selectedSourceTenantId}
      />
      {data &&
        AVAILABLE_MEDICAL_CONDITIONS.map((condition) => {
          const conditionUIData = getMedicalConditionsUIMeta(condition);
          const medicalCondition = data?.medicalConditions?.find((cond) => cond.name === condition);

          if (!medicalCondition) {
            return null;
          }
          const categoryId = medicalCondition?.id || '';
          const options = medicalCondition?.optionSet?.options || EMPTY_OPTIONS;

          return (
            <div key={conditionUIData.label} css={spacingFixStyle}>
              <div css={applyBackgroundStyle}>
                <Accordion chevronSize={1} key={conditionUIData.label} variant='bordered' css={accordionBaseStyle}>
                  <Accordion.Item value={condition} trackingId={conditionUIData.link}>
                    <Accordion.Header>{conditionUIData.label}</Accordion.Header>
                    <Accordion.Body>
                      <ConditionList
                        id={categoryId}
                        condition={condition}
                        options={options}
                        sourceTenantId={selectedSourceTenantId}
                        saveButtonTrackingId={conditionUIData.saveButtonTrackingId}
                        syncButtonTrackingId={conditionUIData.syncButtonTrackingId}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          );
        })}
    </>
  );
};

const containerStyle = css({
  position: 'relative',
  height: '240px',
  background: theme.colors.white,
});
