import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import dayjs from 'dayjs';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { useAppScopeStore } from '@frontend/scope';
import { useCallIntelShallowStore } from '../components/call-intelligence/hooks';

export const useShowTrialInfoBanner = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const { data: locationWiseCustomizationFlags, isLoading } =
    CustomizationFlagQueries.useMultiCustomizationFlags(selectedLocationIds);

  const { isDemoModal } = useCallIntelShallowStore('isDemoModal');

  const showTrialInfoBanner = useMemo(() => {
    if (isDemoModal || !locationWiseCustomizationFlags?.locationsFeatures) {
      return false;
    }

    return Object.keys(locationWiseCustomizationFlags.locationsFeatures ?? {}).some((locationId) =>
      locationWiseCustomizationFlags.locationsFeatures?.[locationId]?.features?.some((feature) => {
        const isCallIntelFeatureIsOnTrial =
          feature.featureEnum === Feature.CALL_INTELLIGENCE &&
          (feature.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
            feature.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE) &&
          feature.isFreeTrial === true;

        // If the feature is not on trial and is not active, return false no need to do further checks
        if (isCallIntelFeatureIsOnTrial === false) return false;

        const featureStartDate = dayjs(feature.startDate);
        return featureStartDate.isSame(dayjs(), 'date');
      })
    );
  }, [isDemoModal, isLoading, locationWiseCustomizationFlags, selectedLocationIds]);

  return showTrialInfoBanner;
};
