import { useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { css } from '@emotion/react';
import {
  LineKey,
  LineKeyType_Enum,
  UserExtension,
} from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { motion, useAnimate, usePresence } from 'motion/react';
import { LineKeysTypes } from '@frontend/api-line-keys';
import { theme } from '@frontend/theme';
import { useFormField } from '@frontend/design-system';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';
import { Content, LineKeyName } from './card-content';
import { CardDragDrop } from './card-drag-drop';
import { Footer } from './card-footer';
import { Title } from './card-title';

type Props = LineKey & {
  cardId: string;
  overlay?: boolean;
};

export const LineKeyCard = ({ cardId, overlay, ...rest }: Props) => {
  const { lineKeyType, intercomEnabled, presenceEnabled, extensionId } = rest;
  const { lineKeyOptions, maxKeys } = useLineKeyContext();
  const { setFinalLineKeys, finalLineKeys } = useLineKeyState(['setFinalLineKeys', 'finalLineKeys']);
  const [scope, animate] = useAnimate();
  const [isPresent, safeToRemove] = usePresence();
  const [error, setError] = useState(false);

  useEffect(() => {
    const exitAnimation = async () => {
      await animate(scope.current, { opacity: 0 });
      safeToRemove?.();
    };
    if (!isPresent) exitAnimation();
  }, [isPresent]);

  const isCardRemovable =
    lineKeyType !== LineKeyType_Enum.DATA_ENDPOINT && lineKeyType !== LineKeyType_Enum.PERSONAL_VOICEMAIL;

  const presence =
    lineKeyType === LineKeyType_Enum.USER_EXTENSION
      ? ((intercomEnabled
          ? 'intercomEnabled'
          : presenceEnabled
          ? 'presenceEnabled'
          : 'none') satisfies LineKeysTypes.UserExtensionActivityType)
      : undefined;

  const radioFieldProps = useFormField({ type: 'radio', value: presence }, [presence]);

  useEffect(() => {
    const copyfinalLineKeys = { ...finalLineKeys };
    setFinalLineKeys({
      ...copyfinalLineKeys,
      [cardId]: {
        ...rest,
        presenceEnabled: radioFieldProps.value === 'none' || radioFieldProps.value === 'intercomEnabled' ? false : true,
        intercomEnabled: radioFieldProps.value === 'none' || radioFieldProps.value === 'presenceEnabled' ? false : true,
      },
    });
  }, [presence, radioFieldProps.value]);

  const actions = useSortable({
    id: cardId,
    transition: {
      duration: 150,
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const isIntercomNotSupported =
    radioFieldProps.value === 'intercomEnabled' &&
    lineKeyType === LineKeyType_Enum.USER_EXTENSION &&
    !(lineKeyOptions?.[extensionId] as UserExtension)?.intercomSupported;

  const { setNodeRef, newIndex, attributes, active } = actions;

  const removeCard = () => {
    const copyfinalLineKeys = { ...finalLineKeys };
    delete copyfinalLineKeys[cardId];
    setFinalLineKeys({
      ...copyfinalLineKeys,
    });
  };

  return (
    <CardDragDrop cardId={cardId} overlay={overlay} ref={scope} error={error} {...actions}>
      <motion.div
        id={`line-key-card-${newIndex + 1}`}
        css={[
          cardStyle,
          `
            ${overlay && `transform: scale(1.01)`};
            border: ${newIndex + 1 > maxKeys ? `1px solid ${theme.colors.warning50}` : ''};
            border: ${!!error ? `1px solid ${theme.font.colors.error}` : ''};
          `,
        ]}
        ref={setNodeRef}
        initial={{ opacity: 1 }}
        animate={{
          boxShadow: overlay ? `${theme.shadows.heavy}` : `${theme.shadows.light}`,
          cursor: overlay ? 'grabbing' : '',
          opacity: active?.id === cardId ? 0.5 : 1,
        }}
        exit={{ opacity: 0 }}
        {...attributes}
      >
        <LineKeyCard.Title
          removeCard={removeCard}
          isIntercomNotSupported={isIntercomNotSupported}
          isCardRemovable={isCardRemovable}
          type={lineKeyType}
          {...actions}
        />
        <LineKeyCard.Config cardId={cardId} {...rest} />
        <LineKeyCard.Name setError={setError} cardId={cardId} name={rest.name} />
        <LineKeyCard.Footer
          presence={presence}
          isCardRemovable={isCardRemovable}
          cardId={cardId}
          {...radioFieldProps}
        />
      </motion.div>
    </CardDragDrop>
  );
};

LineKeyCard.Title = Title;
LineKeyCard.Config = Content;
LineKeyCard.Name = LineKeyName;
LineKeyCard.Footer = Footer;

export const cardIndexStyle = css`
  width: ${theme.spacing(4)};
  min-width: ${theme.spacing(4)};
`;

export const cardStyle = css`
  background: ${theme.colors.white};
  position: relative;
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.spacing(1)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${theme.spacing(2)};
  height: auto;
  outline: 2px solid transparent;
  box-shadow: ${theme.shadows.light};
  flex: 1 1 auto;
  overflow: hidden;

  :focus {
    animation: outlineOnFocus 5s linear;
    @keyframes outlineOnFocus {
      0% {
        outline-color: transparent;
      }
      25% {
        outline-color: ${theme.colors.primary30};
        box-shadow: 0px 4px 10px 0px ${theme.colors.primary30};
      }
      100% {
        outline-color: transparent;
      }
    }
  }
`;
