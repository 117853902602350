import { useCallback } from 'react';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ValidNumberStatus } from '@weave/schema-gen-ts/dist/schemas/sms/number/v1/number_service.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { PersonsV3, PersonTypes } from '@frontend/api-person';
import { isPhoneNumber } from '@frontend/phone-numbers';
import { PickRequired } from '@frontend/types';
import { useGetValidityQueries } from '../queries';
import { PhoneValidity } from '../types';

const PHONE_CONTACT_TYPES: ContactType_Enum[] = [
  ContactType_Enum.PHONE_MOBILE,
  ContactType_Enum.PHONE_HOME,
  ContactType_Enum.PHONE_WORK,
];

type PersonSMSNumbersValidityArgs = {
  personId: string;
  groupIds: string[];
  person?: Person | PersonTypes.Person;
  defaultToValid?: boolean;
};
type ContactInfoWithDestinationAndType = PickRequired<
  NonNullable<Person['contactInfo']>[number],
  'destination' | 'type'
>;

/**
 * A hook that returns an array of queries for the `GetValidity` endpoint for all phone numbers associated with a person.
 * @param personId - The personId of the person to be fetched.
 * @param groupIds - The groupIds of the person to be fetched. It's fine for this to just be the currently selected locations if it's unknown which groupId the person is associated with.
 * @param person (optional) - The person object to be used. If not provided, the person will be fetched using the personId. This can be the PersonsV3 type or the old Person type.
 * @param defaultToValid (optional) - Whether or not to default to valid. Defaults to true. This will default the validity while the query is loading, or on error.
 */
export const usePersonSMSNumbersValidity = ({
  personId,
  groupIds,
  person,
  defaultToValid = true,
}: PersonSMSNumbersValidityArgs) => {
  const filterContactInfo = useCallback(
    (contactInfo: Partial<NonNullable<Person['contactInfo']>[number]>[]) =>
      contactInfo
        ? contactInfo.reduce<ContactInfoWithDestinationAndType[]>((acc, { destination, type, ...rest }) => {
            const fieldsAreNotEmpty = destination && type;
            if (!fieldsAreNotEmpty) return acc;

            const isPhoneType = PHONE_CONTACT_TYPES.includes(type);
            const isUnspecifiedPhoneNumber = type === ContactType_Enum.UNSPECIFIED && isPhoneNumber(destination);
            if (!isPhoneType && !isUnspecifiedPhoneNumber) {
              return acc;
            }

            acc.push({ type, destination, ...rest });
            return acc;
          }, [])
        : [],
    []
  );

  const { data: fetchedPersonNumbers = [], isLoading: personIsLoading } =
    PersonsV3.PersonQueries.useGetPersonLegacyQuery(
      {
        personId,
        locationIds: groupIds,
      },
      {
        enabled: !person && !!personId && !!groupIds.length,
        select: (data) => filterContactInfo(data?.contactInfo ?? []),
      }
    );
  const providedPersonNumbers: ContactInfoWithDestinationAndType[] =
    person !== undefined
      ? 'personId' in person
        ? filterContactInfo(person.contactInfo ?? [])
        : 'PersonID' in person
        ? filterContactInfo(PersonsV3.PersonHelpers.convertPersonToPersonV3(person).contactInfo ?? [])
        : []
      : [];

  const personNumbers = person ? providedPersonNumbers : fetchedPersonNumbers;
  const phoneValidityQueries = useGetValidityQueries(
    personNumbers.map(({ destination, type }) => ({
      request: {
        phoneNumber: destination,
      },
      options: {
        select: (data): PhoneValidity => ({
          isValid: data?.valid === ValidNumberStatus.VALID_NUMBER_STATUS_VALID,
          number: destination,
          label: PersonsV3.PersonHelpers.contactTypeLabels[type],
        }),
        placeholderData: {
          valid: defaultToValid
            ? ValidNumberStatus.VALID_NUMBER_STATUS_VALID
            : ValidNumberStatus.VALID_NUMBER_STATUS_INVALID,
        },
      },
      fallbackDataOnError: {
        isValid: defaultToValid,
        number: destination,
        label: PersonsV3.PersonHelpers.contactTypeLabels[type],
      } satisfies PhoneValidity,
    }))
  );

  const allPhonesWithValidity = phoneValidityQueries.reduce<PhoneValidity[]>((acc, query) => {
    if (query.data) {
      acc.push(query.data);
    }
    return acc;
  }, []);
  const { validPhones, invalidPhones } = allPhonesWithValidity.reduce<{
    validPhones: PhoneValidity[];
    invalidPhones: PhoneValidity[];
  }>(
    (acc, phoneData) => {
      if (!phoneData) return acc;
      if (phoneData?.isValid) {
        acc.validPhones.push(phoneData);
      } else {
        acc.invalidPhones.push(phoneData);
      }
      return acc;
    },
    { validPhones: [], invalidPhones: [] }
  );

  const validityIsLoading = phoneValidityQueries.some((query) => query.isLoading);

  return { validPhones, invalidPhones, allPhonesWithValidity, isLoading: personIsLoading || validityIsLoading };
};
