import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { dispatchTopBarTraySelectionEvent } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { useCalendarViewV3HeaderContext } from '../../context/CalendarViewV3HeaderContext';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../stores/use-schedule-actions-appointment-list-store';
import { SummaryBox } from '../../views/Calendar/components/HeaderBar/SummaryBoxRow';

export const CalendarViewV3HeaderSummary = () => {
  const { t } = useTranslation('schedule');
  const isMedium = useMatchMedia({ maxWidth: breakpoints.medium.max });
  const { calendarEventsCount, unconfirmedCalendarEventsCount } = useCalendarViewV3HeaderContext();

  const { setStatusFilter } = useScheduleActionsAppointmentListInfoShallowStore('setStatusFilter');

  const openAppointmentList = (filter?: string) => {
    dispatchTopBarTraySelectionEvent('schedulePulse');
    setStatusFilter && setStatusFilter(filter || '');
  };

  return (
    <section css={calendarViewV3HeaderSummaryStyles}>
      <SummaryBox
        count={calendarEventsCount}
        title={isMedium ? t('Appts') : t('Appointments')}
        iconName='calendar'
        onClickBox={() => openAppointmentList()}
      />
      <SummaryBox
        onClickBox={() => openAppointmentList('unconfirmed')}
        count={unconfirmedCalendarEventsCount}
        title={t('Unconfirmed')}
        iconName='pending'
      />
    </section>
  );
};

const calendarViewV3HeaderSummaryStyles = css({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  width: 'fit-content',
  height: theme.spacing(8),
});
