import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { TFunction } from '@frontend/i18n';
import { DefaultTagKeys, TagVariationsList, TagsFullListProps, TagType } from './types';

dayjs.extend(advancedFormat);

export const tagVariationsGenerator = (tagOptions: string[], defaultTag: DefaultTagKeys): string[] => {
  const tags: string[] = [];
  tagOptions.forEach((option) => {
    const snakeCase = option.split(' ').join('_');
    const kabobCase = option.split(' ').join('-');
    tags.push(option, option.toLowerCase(), snakeCase, snakeCase.toLowerCase(), kabobCase, kabobCase.toLowerCase());
  });
  const formattedTags = tags.map((tag) => `{${tag}}`);
  !formattedTags.includes(defaultTag) && formattedTags.push(defaultTag as string);
  return formattedTags;
};

export const tagVariationsList = (t: TFunction): TagVariationsList => {
  return {
    // patient info tags
    [DefaultTagKeys.firstName]: tagVariationsGenerator([t('First Name'), t('Given Name')], DefaultTagKeys.firstName),
    [DefaultTagKeys.lastName]: tagVariationsGenerator(
      [t('Last Name'), t('Surname'), t('Family Name')],
      DefaultTagKeys.lastName
    ),
    [DefaultTagKeys.preferredName]: tagVariationsGenerator(
      [t('Preferred Name'), t('Best Name')],
      DefaultTagKeys.preferredName
    ),

    // practice info tags
    [DefaultTagKeys.practiceName]: tagVariationsGenerator(
      [t('Practice Name'), t('Company Name'), t('Location Name'), t('Location'), t('Office Name')],
      DefaultTagKeys.practiceName
    ),
    [DefaultTagKeys.practicePhone]: tagVariationsGenerator(
      [
        t('Practice Phone'),
        t('Practice Phone Number'),
        t('Practice Info'),
        t('Location Phone'),
        t('Location Phone Number'),
        t('Location Info'),
        t('Office Phone'),
        t('Office Phone Number'),
        t('Office Info'),
      ],
      DefaultTagKeys.practicePhone
    ),
    [DefaultTagKeys.serviceProvider]: tagVariationsGenerator([t('Service Provider')], DefaultTagKeys.serviceProvider),

    // appointment tags
    [DefaultTagKeys.apptDatetime]: tagVariationsGenerator(
      [
        t('Date and Time'),
        t('Date & Time'),
        t('Date + Time'),
        t('Datetime'),
        t('Date Time'),
        t('Appt Datetime'),
        t('Appointment Time'),
        t('Appointment Date'),
        t('Appointment Datetime'),
        t('Appointment'),
      ],
      DefaultTagKeys.apptDatetime
    ),
    [DefaultTagKeys.date]: tagVariationsGenerator(
      [t('Date'), t('Appt Date'), t('Appointment Date')],
      DefaultTagKeys.date
    ),
    [DefaultTagKeys.time]: tagVariationsGenerator(
      [t('Time'), t('Appt Time'), t('Appointment Time')],
      DefaultTagKeys.time
    ),
    [DefaultTagKeys.day]: tagVariationsGenerator(
      [t('Day'), t('Weekday'), t('Appt Day'), t('Appt Weekday'), t('Appointment Day'), t('Appointment Weekday')],
      DefaultTagKeys.day
    ),
    [DefaultTagKeys.dayOfMonth]: tagVariationsGenerator(
      [
        t('Day of Month'),
        t('Day Ordinal'),
        t('Appt Day of Month'),
        t('Appt Day Ordinal'),
        t('Appointment Day of Month'),
        t('Appointment Day Ordinal'),
      ],
      DefaultTagKeys.dayOfMonth
    ),
    [DefaultTagKeys.month]: tagVariationsGenerator(
      [t('Month'), t('Appt Month'), t('Appointment Month')],
      DefaultTagKeys.month
    ),
    [DefaultTagKeys.timeEarly]: tagVariationsGenerator([t('Time Early'), t('Arrival Time')], DefaultTagKeys.timeEarly),

    // scheduling tags
    [DefaultTagKeys.openingDatetime]: tagVariationsGenerator(
      [t('Opening Datetime'), t('Opening Date Time')],
      DefaultTagKeys.openingDatetime
    ),
    [DefaultTagKeys.openingsDatetime]: tagVariationsGenerator(
      [t('Openings Datetime'), t('Openings Date Time')],
      DefaultTagKeys.openingsDatetime
    ),
    [DefaultTagKeys.daysOverdue]: tagVariationsGenerator([t('Days Overdue')], DefaultTagKeys.daysOverdue),
    [DefaultTagKeys.scheduleLink]: tagVariationsGenerator(
      [t('Schedule Link'), t('Schedule Appointment Link'), t('Make Appointment Link')],
      DefaultTagKeys.scheduleLink
    ),

    // payment and billing tags
    [DefaultTagKeys.bnplPaymentLink]: tagVariationsGenerator(
      [
        t('Buy Now Pay Later'),
        t('BNPL'),
        t('Buy Now Pay Later Link'),
        t('Buy Now Pay Later URL'),
        t('Buy Now Pay Later Payment URL'),
        t('Buy Now Pay Later Payment Link'),
        t('BNPL Link'),
        t('BNPL URL'),
        t('BNPL Payment URL'),
        t('BNPL Payment Link'),
      ],
      DefaultTagKeys.bnplPaymentLink
    ),
    [DefaultTagKeys.paymentLink]: tagVariationsGenerator(
      [t('Payment Link'), t('Payment URL'), t('Pay Link'), t('Pay URL')],
      DefaultTagKeys.paymentLink
    ),
    [DefaultTagKeys.onlineBillPayLink]: tagVariationsGenerator(
      [t('Online Bill Pay'), t('Online Bill Pay Link'), t('Online Bill')],
      DefaultTagKeys.onlineBillPayLink
    ),
    [DefaultTagKeys.collectionAmount]: tagVariationsGenerator(
      [t('Collection Amount'), t('Collections Amount'), t('Amount Owed'), t('Amount Due')],
      DefaultTagKeys.collectionAmount
    ),

    // vet specific tags
    [DefaultTagKeys.petName]: tagVariationsGenerator([t('Pet Name'), t('Pet')], DefaultTagKeys.petName),

    // opto specific tags
    [DefaultTagKeys.orderType]: tagVariationsGenerator([t('Order Type')], DefaultTagKeys.orderType),
  };
};

export const tagList = ({
  wantedTags = [],
  locationName,
  locationPhone,
  apptDatetime,
  timeEarly,
  openingDatetime,
  openingsDatetime,
  scheduleLink,
  buyNowPayLaterLink,
  paymentLink,
  onlineBillPayLink,
  t,
}: TagsFullListProps): TagType[] => {
  const fullTagList: Record<DefaultTagKeys, TagType> = {
    // patient tags
    [DefaultTagKeys.firstName]: {
      label: t('First Name'),
      key: tagVariationsList(t)[DefaultTagKeys.firstName],
      value: 'Jonathan',
    },
    [DefaultTagKeys.lastName]: {
      label: t('Last Name'),
      key: tagVariationsList(t)[DefaultTagKeys.lastName],
      value: 'Smith',
    },
    [DefaultTagKeys.preferredName]: {
      label: t('Preferred Name'),
      key: tagVariationsList(t)[DefaultTagKeys.preferredName],
      value: 'Jon',
    },

    // practice tags
    [DefaultTagKeys.practiceName]: {
      label: t('Practice Name'),
      key: tagVariationsList(t)[DefaultTagKeys.practiceName],
      value: locationName || t('OFFICE NAME'),
    },
    [DefaultTagKeys.practicePhone]: {
      label: t('Practice Phone'),
      key: tagVariationsList(t)[DefaultTagKeys.practicePhone],
      value: locationPhone || t('OFFICE PHONE NUMBER'),
    },
    [DefaultTagKeys.serviceProvider]: {
      label: t('Service Provider'),
      key: tagVariationsList(t)[DefaultTagKeys.serviceProvider],
      value: 'Dr. Weaver',
    },

    // appointment tags
    [DefaultTagKeys.apptDatetime]: {
      label: t('Date & Time'),
      key: tagVariationsList(t)[DefaultTagKeys.apptDatetime],
      value: !!apptDatetime ? apptDatetime.format('dddd, MMMM D, YYYY h:mm A') : t('APPOINTMENT DATE TIME'),
    },
    [DefaultTagKeys.date]: {
      label: t('Date'),
      key: tagVariationsList(t)[DefaultTagKeys.date],
      value: !!apptDatetime ? apptDatetime.format('MMM D, YYYY') : t('APPOINTMENT DATE'),
    },
    [DefaultTagKeys.time]: {
      label: t('Time'),
      key: tagVariationsList(t)[DefaultTagKeys.time],
      value: !!apptDatetime ? apptDatetime.format('h:mm A') : t('APPOINTMENT TIME'),
    },
    [DefaultTagKeys.day]: {
      label: t('Weekday'),
      key: tagVariationsList(t)[DefaultTagKeys.day],
      value: !!apptDatetime ? apptDatetime.format('dddd') : t('APPOINTMENT WEEKDAY'),
    },
    [DefaultTagKeys.dayOfMonth]: {
      label: t('Day of Month'),
      key: tagVariationsList(t)[DefaultTagKeys.dayOfMonth],
      value: !!apptDatetime ? apptDatetime.format('Do') : t('APPOINTMENT DAY OF MONTH'),
    },
    [DefaultTagKeys.month]: {
      label: t('Month'),
      key: tagVariationsList(t)[DefaultTagKeys.month],
      value: !!apptDatetime ? apptDatetime.format('MMMM') : t('APPOINTMENT MONTH'),
    },
    [DefaultTagKeys.timeEarly]: {
      label: t('Time Early'),
      key: tagVariationsList(t)[DefaultTagKeys.timeEarly],
      value: timeEarly?.format('h:mm A') || t('TIME EARLY'),
    },

    // scheduling tags
    [DefaultTagKeys.openingDatetime]: {
      label: t('Opening Datetime'),
      key: tagVariationsList(t)[DefaultTagKeys.openingDatetime],
      value: openingDatetime?.format('dddd, MMMM D, YYYY h:mm A') || t('OPENING DATETIME'),
    },
    [DefaultTagKeys.openingsDatetime]: {
      label: t('Openings Datetime'),
      key: tagVariationsList(t)[DefaultTagKeys.openingsDatetime],
      value:
        openingsDatetime?.map((datetime) => datetime.format('dddd, MMMM D, YYYY h:mm A')).join(', ') ||
        t('OPENINGS DATETIME'),
    },
    [DefaultTagKeys.daysOverdue]: {
      label: t('Days Overdue'),
      key: tagVariationsList(t)[DefaultTagKeys.daysOverdue],
      value: t('30'),
    },
    [DefaultTagKeys.scheduleLink]: {
      label: t('Schedule Link'),
      key: tagVariationsList(t)[DefaultTagKeys.scheduleLink],
      value: scheduleLink || t('SCHEDULE LINK'),
    },

    // payment and billing tags
    [DefaultTagKeys.bnplPaymentLink]: {
      label: t('Buy Now Pay Later'),
      key: tagVariationsList(t)[DefaultTagKeys.bnplPaymentLink],
      value: buyNowPayLaterLink || t('BUY NOW PAY LATER LINK'),
    },
    [DefaultTagKeys.paymentLink]: {
      label: t('Payment Link'),
      key: tagVariationsList(t)[DefaultTagKeys.paymentLink],
      value: paymentLink || t('PAYMENT LINK'),
    },
    [DefaultTagKeys.onlineBillPayLink]: {
      label: t('Online Bill Pay Link'),
      key: tagVariationsList(t)[DefaultTagKeys.onlineBillPayLink],
      value: onlineBillPayLink || t('ONLINE BILL PAY LINK'),
    },
    [DefaultTagKeys.collectionAmount]: {
      label: t('Collection Amount'),
      key: tagVariationsList(t)[DefaultTagKeys.collectionAmount],
      value: t('$50.00'),
    },

    // vet specific tags
    [DefaultTagKeys.petName]: {
      label: t('Pet Name'),
      key: tagVariationsList(t)[DefaultTagKeys.petName],
      value: 'Fluffy',
    },

    // opto specific tags
    [DefaultTagKeys.orderType]: {
      label: t('Order Type'),
      key: tagVariationsList(t)[DefaultTagKeys.orderType],
      value: 'Glasses',
    },
  };

  const selectedTags: TagType[] = wantedTags?.map((tagKey) => fullTagList[tagKey]) || Object.values(fullTagList);

  return selectedTags;
};

export const standardizeTag = (t: TFunction, tag: string): string => {
  const replacementTag = Object.keys(tagVariationsList(t)).find((key) => {
    return tagVariationsList(t)[key as DefaultTagKeys].includes(tag);
  });
  return replacementTag || '{undefined}';
};

export const standardizeTemplateTextTags = (t: TFunction, text: string): string => {
  const regex = new RegExp(/\{(.*?)\}/gm);
  const standardizedTemplateText = text.replace(regex, (match) => standardizeTag(t, match));
  return standardizedTemplateText;
};
