import { ElementType } from 'react';
import { RefundModel, RefundType } from '@frontend/api-refunds';
import { Chip, ChipVariants } from '@frontend/design-system';
import { prettify } from '../../../utils';

type RefundStatusChipProps = {
  refund: RefundModel;
  as?: ElementType;
};

const mapStatus = (status: RefundType): ChipVariants => {
  switch (status) {
    case RefundType.PartialRefund:
    case RefundType.FullRefund:
      return 'disabled';
    default:
      return 'primary';
  }
};

export const RefundStatusChip = ({ refund, as, ...rest }: RefundStatusChipProps) => {
  const status = refund.type;

  return (
    <>
      {status && (
        <Chip variant={mapStatus(status)} {...rest}>
          {prettify(status, { split: '_' })}
        </Chip>
      )}
    </>
  );
};
