import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel, toHHmmss } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  abandoned: theme.colors.warning50,
  answered: theme.colors.secondary.seaweed30,
  missed: theme.colors.critical30,
};

const labels = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
};

type ProcessedData = {
  chartData: BarChartData;
  totals: {
    abandoned: number;
    answered: number;
    missed: number;
  };
};

export const AverageIncomingCallDurationChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.IncomingCallsAggregationKey>('incoming_call_status');

  const processeData: ProcessedData = useMemo(() => {
    let groups: BarChartData['groups'] = [];
    const totals: ProcessedData['totals'] = {
      abandoned: 0,
      answered: 0,
      missed: 0,
    };

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { calls, duration }]) => {
        const averageAbandoned = (duration?.abandoned || 0) / (calls?.abandoned || 1);
        const averageAnswered = (duration?.answered || 0) / (calls?.answered || 1);
        const averageMissed = (duration?.missed || 0) / (calls?.missed || 1);

        totals.abandoned += averageAbandoned;
        totals.answered += averageAnswered;
        totals.missed += averageMissed;

        return {
          name: locationNames?.[key] || key,
          values: {
            abandoned: averageAbandoned,
            answered: averageAnswered,
            missed: averageMissed,
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { abandoned, answered, missed } = Object.values(locationData || {})[0] || {};
        const abandonedHourlyCalls = abandoned?.calls?.hourly || [];
        const abandonedHourlyDuration = abandoned?.duration?.hourly || [];
        const answeredHourlyCalls = answered?.calls?.hourly || [];
        const answeredHourlyDuration = answered?.duration?.hourly || [];
        const missedHourlyCalls = missed?.calls?.hourly || [];
        const missedHourlyDuration = missed?.duration?.hourly || [];

        groups = Array.from({ length: 24 }).map((_, i) => {
          const averageAbandoned = (abandonedHourlyDuration[i] || 0) / (abandonedHourlyCalls[i] || 1);
          const averageAnswered = (answeredHourlyDuration[i] || 0) / (answeredHourlyCalls[i] || 1);
          const averageMissed = (missedHourlyDuration[i] || 0) / (missedHourlyCalls[i] || 1);

          totals.abandoned += averageAbandoned;
          totals.answered += averageAnswered;
          totals.missed += averageMissed;

          return {
            name: getHourlyInsightsLabel(i),
            values: {
              abandoned: averageAbandoned,
              answered: averageAnswered,
              missed: averageMissed,
            },
          };
        });
      } else {
        groups = Object.entries(locationData).map(([date, { abandoned, answered, missed }]) => {
          const averageAbandoned = (abandoned?.duration?.daily || 0) / (abandoned?.calls?.daily || 1);
          const averageAnswered = (answered?.duration?.daily || 0) / (answered?.calls?.daily || 1);
          const averageMissed = (missed?.duration?.daily || 0) / (missed?.calls?.daily || 1);

          totals.abandoned += averageAbandoned;
          totals.answered += averageAnswered;
          totals.missed += averageMissed;

          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              abandoned: averageAbandoned,
              answered: averageAnswered,
              missed: averageMissed,
            },
          };
        });
      }
    }

    return {
      chartData: {
        groups,
      },
      totals,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locationNames]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Incoming: Refers to the average time on Answered, Missed and Abandoned calls. It only includes Incoming calls. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Average Call Duration - Incoming')}
      />
      <Chart.BarChart
        appearance={defaultChartAppearance}
        data={processeData.chartData}
        formatValue={(value) => toHHmmss(value)}
      />
      <Chart.Legends formatValue={(value) => toHHmmss(value)} values={processeData.totals} />
    </Chart>
  );
};
