import { DashboardWidgetFC } from '@frontend/grid-dashboard';
import { StickyNoteWidget } from './components/sticky-note';

const config: DashboardWidgetFC['config'] = {
  size: {
    extraSmall: 'medium-narrow',
    small: 'medium-narrow',
    medium: 'medium-narrow',
    large: 'large-narrow',
  },
  feature: 'general',
};

/**
 * @dashboard-widget
 *
 * id: sticky-note-yellow
 * title: Sticky Note
 * description: Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.
 * icon: task-small
 */
export const StickyNoteWidgetYellow: DashboardWidgetFC = () => {
  return <StickyNoteWidget color='yellow' />;
};

StickyNoteWidgetYellow.config = config;

/**
 * @dashboard-widget
 *
 * id: sticky-note-orange
 * title: Sticky Note
 * description: Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.
 * icon: task-small
 */
export const StickyNoteWidgetOrange: DashboardWidgetFC = () => {
  return <StickyNoteWidget color='orange' />;
};

StickyNoteWidgetOrange.config = config;

/**
 * @dashboard-widget
 *
 * id: sticky-note-green
 * title: Sticky Note
 * description: Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.
 * icon: task-small
 */
export const StickyNoteWidgetGreen: DashboardWidgetFC = () => {
  return <StickyNoteWidget color='green' />;
};

StickyNoteWidgetGreen.config = config;

/**
 * @dashboard-widget
 *
 * id: sticky-note-blue
 * title: Sticky Note
 * description: Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.
 * icon: task-small
 */
export const StickyNoteWidgetBlue: DashboardWidgetFC = () => {
  return <StickyNoteWidget color='blue' />;
};

StickyNoteWidgetBlue.config = config;

/**
 * @dashboard-widget
 *
 * id: sticky-note-pink
 * title: Sticky Note
 * description: Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.
 * icon: task-small
 */
export const StickyNoteWidgetPink: DashboardWidgetFC = () => {
  return <StickyNoteWidget color='pink' />;
};

StickyNoteWidgetPink.config = config;

/**
 * @dashboard-widget
 *
 * id: sticky-note-purple
 * title: Sticky Note
 * description: Ditch the paper, and instead use Weave’s digital sticky notes to add your thoughts to your dashboard.
 * icon: task-small
 */
export const StickyNoteWidgetPurple: DashboardWidgetFC = () => {
  return <StickyNoteWidget color='purple' />;
};

StickyNoteWidgetPurple.config = config;
