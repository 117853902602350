import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMultiQueryUtils } from '@frontend/payments-hooks';
import { CreateAndCollectModal } from '@frontend/payments-invoice-create';
import { useModalControl } from '@frontend/design-system';

/**
 * @dashboard-widget
 *
 * id: add-invoice
 * title: New Invoice
 * description: Quickly create a new invoice and collect payments.
 * icon: payments
 */
export const AddInvoiceWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('fax');
  const { modalProps, triggerProps } = useModalControl();
  const { widgetTrackingId } = useDashboardWidget();
  const { locationId } = useMultiQueryUtils();

  const { aggregateValue: newLabel } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: PaymentsFeatureFlags.CollectPaymentOnABTest,
    locationIds: [locationId],
  });

  return (
    <>
      <DashboardWidget.ActionButton
        trackingId={widgetTrackingId('action')}
        iconName='add'
        title={newLabel ? t('Collect Payment') : t('Invoice')}
        {...triggerProps}
      />
      <CreateAndCollectModal {...modalProps} />
    </>
  );
};

AddInvoiceWidget.config = {
  size: 'tiny-slim',
  feature: 'payments',
};
