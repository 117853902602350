import { ScheduleActionsContainerProvider } from '../../../../context/ScheduleActionsContainerContext';
import { ScheduleActionsModalContainer } from './ScheduleActionsModalContainer';

interface Props {
  onClose: () => void;
}

export const SchedulePulseTrayContent = ({ onClose }: Props) => {
  return (
    <ScheduleActionsContainerProvider closeModal={onClose}>
      <ScheduleActionsModalContainer />
    </ScheduleActionsContainerProvider>
  );
};
