import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { animated, useSpring } from 'react-spring';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  CaretRightIconSmall,
  DownloadIcon,
  SpinningLoader,
  Text,
  useSlideFadeTransition,
} from '@frontend/design-system';
import { WaitStateProps } from '../hooks/use-large-downloads';

interface Props {
  options: WaitStateProps;
}

export const DownloadProgressCard: FC<React.PropsWithChildren<Props>> = ({ options }) => {
  const { t } = useTranslation();
  const [isMinimized, setIsMinimized] = useState<boolean | undefined>();

  const minimizeTransition = useSpring({
    borderRadius: isMinimized ? 50 : 4,
    width: isMinimized ? 56 : 300,
  });

  const transitions = useSlideFadeTransition({
    active: options.isWaiting,
    direction: 'left',
    distance: '10%',
  });

  const getFileName = () => {
    const { fileExtension, fileName } = options;
    if (fileName) {
      return fileExtension ? `${fileName}.${fileExtension}` : fileName;
    } else {
      return 'file';
    }
  };

  return (
    <>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} css={styles} style={{ ...props, ...minimizeTransition }}>
              {isMinimized ? (
                <div className='mini-downloader' onClick={() => setIsMinimized(false)} role='button'>
                  <SpinningLoader size='medium' />
                  <DownloadIcon color='light' size={16} />
                </div>
              ) : (
                <>
                  <SpinningLoader size='small' />
                  <Text>{t('Preparing {{fileName}} to download', { fileName: getFileName() })}</Text>
                  <CaretRightIconSmall color='light' onClick={() => setIsMinimized(true)} role='button' />
                </>
              )}
            </animated.div>
          )
      )}
    </>
  );
};

const styles = css`
  align-items: center;
  background-color: ${theme.colors.neutral90};
  box-shadow: ${theme.shadows.heavy};
  color: ${theme.colors.white};
  display: flex;
  gap: ${theme.spacing(1)};
  margin-top: ${theme.spacing(1)};
  min-height: ${theme.spacing(7)};
  padding: ${theme.spacing(1)};

  p {
    color: inherit;
    line-height: 1;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 220px;
  }

  svg {
    cursor: pointer;
  }

  .mini-downloader {
    cursor: pointer;
    height: ${theme.spacing(5)};
    position: relative;
    width: ${theme.spacing(5)};

    > div {
      bottom: 0;
      position: absolute;
      right: 0;
    }

    > svg {
      bottom: ${theme.spacing(1.5)};
      position: absolute;
      right: ${theme.spacing(1.5)};
    }
  }
`;
