import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Button } from '@frontend/design-system';

export const PatientsBreakdownTable = () => {
  const { t } = useTranslation('analytics');
  const [showMore, setShowMore] = useState<boolean>(false);

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div css={styles.wrapper}>
      <Button
        css={styles.showMoreButton}
        iconName={showMore ? 'caret-up' : 'caret-down'}
        onClick={handleShowMore}
        variant={'tertiary'}
      >
        {t('Show More')}
      </Button>

      {/* TODO :: add table view here */}
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    justify-content: flex-end;
  `,

  showMoreButton: css`
    > span {
      flex-direction: row-reverse;
    }
  `,
};
