import { callIntel } from './tracking-ids';

export type TrackingOptions = {
  component?: string; // Component name
  context?: string; // Context in which the tracking is happening
  page?: string; // Page or section identifier for broader tracking
  prefix?: string; // Prefix (defaults to 'call-intel')
  isDemoAccount?: boolean; // Whether this is for a demo account (adds 'demo' if true)
  abTestGroup?: string; // A/B test group identifier
  customName?: string; // Custom name that overrides other options
};

export const generateTrackingId = ({
  component,
  context,
  page,
  prefix = callIntel,
  isDemoAccount = false,
  abTestGroup,
  customName,
}: TrackingOptions): string => {
  if (customName) {
    return `${prefix}-${customName}`;
  }

  const demoSuffix = isDemoAccount ? 'demo' : '';

  const abTestSuffix = abTestGroup ? `-abTestGroup-${abTestGroup}` : '';

  const pagePrefix = page ? `${page}-` : '';

  let baseId = `${prefix}-${pagePrefix}${component ?? ''}`.replace(/-$/, ''); // remove trailing dash if no component

  if (context) {
    baseId += `-${context}`;
  }

  if (demoSuffix) {
    baseId += `-${demoSuffix}`;
  }

  baseId += abTestSuffix;

  return baseId;
};

export const generateCommonTrackingIds = (featureName: string) => ({
  chartsView: `${featureName}-charts-view-tab`,
  clearFilters: `${featureName}-clear-filters`,
  downloadMain: `${featureName}-download-main`,
  filtersOpen: `${featureName}-filters-open`,
  helpVideo: `${featureName}-help-video`,
  tableView: `${featureName}-table-view-tab`,
});

export const resolveTrackingPlaceholders = (context: string, dynamicValues: Record<string, string>): string => {
  return context.replace(/\${(.*?)}/g, (match, key) => {
    return dynamicValues[key] || match;
  });
};
