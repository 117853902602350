import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  width: 100%;
`;

export const textStyle = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

export const iconStyle = css`
  flex-shrink: 0;
`;
