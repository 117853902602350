import type { WidthKey } from './constants';
import { usePanelContext } from './context';
import type { PanelConfig } from './PANEL-CONFIG';
import { usePanelStore } from './store';

/**
 * A hook that returns the current width of the current panel (within a PanelPortal).
 *
 * This hook uses the panel context and panel store to retrieve the current width
 * of the specific panel identified by its ID.
 *
 * @returns {WidthKey} The current width key of the panel
 */
export function useCurrentPanelWidth(): WidthKey {
  const { id } = usePanelContext();
  return usePanelStore((state) => {
    return state.currentPanelWidths[id];
  });
}

/**
 * A hook that returns a function to update the width of the current panel (within a PanelPortal).
 *
 * @returns A function that accepts a WidthKey parameter to set the new width of the current panel.
 *
 * @example
 * ```tsx
 * const updateWidth = useUpdateCurrentPanelWidth();
 * updateWidth('lg'); // Updates the current panel's width to large
 * ```
 */
export function useUpdateCurrentPanelWidth(): (width: WidthKey) => void {
  const { id } = usePanelContext();
  const updatePanelWidth = usePanelStore((state) => state.updatePanelWidth);

  return (width: WidthKey) => {
    updatePanelWidth(id, width);
  };
}

/**
 * A hook that returns the width of a specific panel identified by its ID.
 * It can be used to retrieve the width of any panel that's registered in the panel store.
 *
 * @param id The ID of the panel
 *
 * @returns {WidthKey} The current width key of the panel
 */
export const useGetPanelWidth = (id: PanelConfig) => {
  return usePanelStore((state) => {
    return state.currentPanelWidths[id];
  });
};

/**
 * A hook that returns a function to update the width of a specific panel identified by its ID.
 *
 * @param id The ID of the panel
 *
 * @returns A function that accepts a WidthKey parameter to set the new width of the panel.
 *
 * @example
 * ```tsx
 * const updateWidth = useUpdatePanelWidth('my-panel-id');
 * updateWidth('lg'); // Updates the panel's width to large
 * ```
 */
export const useUpdatePanelWidth = (id: PanelConfig) => {
  const updatePanelWidth = usePanelStore((state) => state.updatePanelWidth);
  return (width: WidthKey) => {
    updatePanelWidth(id, width);
  };
};
