import dayjs from 'dayjs';
import { AreaChartData } from '@frontend/charts';

/**
 * @param index index of the current item in that hourly data array
 * @returns formatted time
 */
export const getHourlyInsightsLabel = (index: number) => {
  return dayjs(`1/1/1 ${index}:00:00`).format('hA');
};

export const formatHourlyInsights = (key: string, values: number[], initialChartData: AreaChartData) =>
  values.map((val, i) => {
    const name = getHourlyInsightsLabel(i);

    const existingIndex = initialChartData.groups.findIndex((group) => group.name === name);
    return {
      name,
      values: {
        ...((initialChartData.groups[existingIndex] || {}).values || {}),
        [key]: val,
      },
    };
  });
