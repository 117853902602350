import { FC, useState } from 'react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { useAlert, ContentLoader } from '@frontend/design-system';
import { useTeamChatStore } from '../../../providers';
import { InfoWithEditor, LeaveChannel } from '../../common';
import { channelSettingsContainerStyle } from '../../common-styles';

interface AboutProps {
  isDm: boolean;
  createdBy: string;
  createdAt: string;
  topic: string;
  description: string;
  channel?: Channel;
  channelName: string;
}

export const About: FC<AboutProps> = ({ isDm, createdBy, createdAt, topic, description, channel, channelName }) => {
  const { t } = useTranslation('team-chat');
  const { error } = useAlert();
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const { conversations } = useTeamChatStore(['conversations']);

  const conversationNames = conversations.groups.map(({ name }) => name.toLowerCase());

  const updateConversationName = async (name: string) => {
    try {
      setShowLoader(true);
      channel && (await channel.updatePartial({ set: { name } }));
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      error(t('Failed to update group name'));
    }
  };

  const updateTopic = async (topic: string) => {
    try {
      setShowLoader(true);
      channel && (await channel.updatePartial({ set: { topic } }));
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      error(t('Failed to update topics'));
    }
  };

  const updateDescription = async (description: string) => {
    try {
      setShowLoader(true);
      channel && (await channel.updatePartial({ set: { description } }));
      setShowLoader(false);
    } catch (err) {
      setShowLoader(false);
      error(t('Failed to update description'));
    }
  };

  return (
    <section css={channelSettingsContainerStyle}>
      <InfoWithEditor
        title={t('Name:')}
        value={channelName}
        placeholder={t('Channel Name')}
        canEdit={!isDm}
        onClickSave={updateConversationName}
        className='channel-settings-item'
        isTextArea={false}
        comment={t(
          'Channels are where conversations happen around a topic. Use a name that is easy to find and understand.'
        )}
        startAdornment={'#'}
        canDisableSave
        conversationNames={conversationNames}
        editButtonTrackingId='team-chat-2.0-edit-channel-name-button'
        saveButtonTrackingId='team-chat-2.0-edit-channel-name-modal-save-button'
        cancelButtonTrackingId='team-chat-2.0-edit-channel-name-modal-cancel-button'
        maxLength={64}
      />
      <InfoWithEditor
        title={t('Topic:')}
        value={topic}
        placeholder={t('Add topic')}
        canEdit
        onClickSave={updateTopic}
        className='channel-settings-item'
        comment={t('Define the focus of your discussion with {{channelName}}.', { channelName })}
        editButtonTrackingId='team-chat-2.0-edit-topic-button'
        saveButtonTrackingId='team-chat-2.0-edit-edit-topic-modal-save-button'
        cancelButtonTrackingId='team-chat-2.0-edit-edit-topic-name-modal-cancel-button'
      />
      <InfoWithEditor
        title={t('Description:')}
        value={description}
        placeholder={t('Add description')}
        canEdit
        onClickSave={updateDescription}
        className='channel-settings-item'
        comment={t(
          'Let people know what {{channelName}} is focused on right now (ex. a project milestone). Descriptions are always visible in the header.',
          { channelName }
        )}
        editButtonTrackingId='team-chat-2.0-edit-description-button'
        saveButtonTrackingId='team-chat-2.0-edit-description-modal-save-button'
        cancelButtonTrackingId='team-chat-2.0-edit-description-modal-cancel-button'
        maxLength={120}
      />
      {/* TODO: commenting it now, will update after discussion with Product team */}
      {/* <InfoWithEditor title='Managed By:' value='' canEdit className='channel-settings-item' /> */}
      {!isDm && (
        <InfoWithEditor
          title={t('Created by:')}
          value={t('{{createdBy}} on {{createdAt}}', { createdBy, createdAt })}
          className='channel-settings-item'
          editButtonTrackingId='team-chat-2.0-edit-created-by-button'
          saveButtonTrackingId='team-chat-2.0-edit-created-by-modal-save-button'
          cancelButtonTrackingId='team-chat-2.0-edit-created-by-modal-cancel-button'
        />
      )}
      {channel && !isDm && <LeaveChannel channel={channel} />}
      <ContentLoader show={showLoader} />
    </section>
  );
};
