import { BASE_ROUTE } from '../base';

const BASE_FORM_BUILDER = `${BASE_ROUTE}/builder`;

export const FORM_BUILDER_ROUTES = {
  base: BASE_FORM_BUILDER,
  new: `${BASE_FORM_BUILDER}/new`,
  edit: (formId?: string) => {
    return formId ? `${BASE_FORM_BUILDER}/${formId}` : `${BASE_FORM_BUILDER}/:id`;
  },
};
