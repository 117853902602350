import { BASE_ROUTE } from '../base';

const BASE_SUBMISSIONS_ROUTE = `${BASE_ROUTE}/submissions`;
const BASE_INBOX_SUBMISSION_ROUTE = `${BASE_SUBMISSIONS_ROUTE}/inbox`;

export const FORM_SUBMISSION_ROUTES = {
  base: BASE_SUBMISSIONS_ROUTE,
  inbox: {
    base: BASE_INBOX_SUBMISSION_ROUTE,
    all: `${BASE_INBOX_SUBMISSION_ROUTE}/all`,
    needsReview: `${BASE_INBOX_SUBMISSION_ROUTE}/needs-review`,
    retrySync: `${BASE_INBOX_SUBMISSION_ROUTE}/retry-sync`,
    completed: `${BASE_INBOX_SUBMISSION_ROUTE}/completed`,
    reviewed: `${BASE_INBOX_SUBMISSION_ROUTE}/reviewed`,
    syncManually: `${BASE_INBOX_SUBMISSION_ROUTE}/sync-manually`,
  },
  sent: `${BASE_SUBMISSIONS_ROUTE}/sent`,
  archived: `${BASE_SUBMISSIONS_ROUTE}/archived`,
};
