import urlRegex from 'url-regex';

const DEFAULT_SHORTENED_URLS_TO = 'https://wv3.io/#######';

/*
    This function calculates the character count of a string.
    @params text: string - the string to calculate the character count of
    @params shortenUrlsTo: string - long urls will be shorted to this defined shortened URL (defaults to DEFAULT_SHORTENED_URLS_TO)
    @returns number - the character count of the string
*/

const getCharCountForShortUrls = (text: string, shortenUrlsTo: string) =>
  text.replaceAll(urlRegex(), shortenUrlsTo).length;

/*
    @type CharCountOptions - a list of options to configure the character count calculation for message templates
    @property lineBreakCharCount: number - the character count for a line break (defaults to 2), it gets added to the character count for each line except for the last line (n - 1 line breaks for n lines)
    @property shortenUrlsTo: string - long urls will be shorted to this defined shortened URL (defaults to DEFAULT_SHORTENED_URLS_TO)
    @property trimWhitespace: boolean - whether to trim whitespace from the start and end of the message template (defaults to false)
*/

export type CharCountOptions = {
  lineBreakCharCount?: number;
  shortenUrlsTo?: string;
  trimWhitespace?: boolean;
};

/*
    This function calculates the character count of a message template.
    @params lines: string[] - an array of strings representing the lines of a message template
    @params options: CharCountOptions - a list of options to configure the character count calculation
    @returns number - the character count of the message template
*/

export const getTemplateCharCount = (
  lines: string[],
  { lineBreakCharCount = 2, shortenUrlsTo = DEFAULT_SHORTENED_URLS_TO, trimWhitespace = false }: CharCountOptions
) => {
  const count = lines.reduce((count, line, index) => {
    if (trimWhitespace && (index === 0 || index === lines.length - 1)) {
      if (index === 0) {
        return count + getCharCountForShortUrls(line.trimStart(), shortenUrlsTo) + lineBreakCharCount;
      }
      return count + getCharCountForShortUrls(line.trimEnd(), shortenUrlsTo) + lineBreakCharCount;
    }
    return count + getCharCountForShortUrls(line, shortenUrlsTo) + lineBreakCharCount;
  }, 0);
  return count - lineBreakCharCount;
};
