import { FC, useEffect } from 'react';
import { Icon } from '@frontend/icons';
import { useUpdateCurrentPanelWidth } from '@frontend/panel-engine';
import { useMatchMedia } from '@frontend/responsiveness';
import { IconButton, WIDTH_DIMS } from '@frontend/design-system';
import { useTeamChatStore } from '../../providers';
import { trayTopBarStyles, rotateCollapseButton } from './tray-top-bar.styles';

interface TrayTopBarProps {
  onClose: () => void;
}

export const TrayTopBar: FC<TrayTopBarProps> = ({ onClose }) => {
  const isSmallSpace = useMatchMedia({ maxWidth: WIDTH_DIMS.xlarge + 40 });
  const { isChatListExpanded, setIsChatListExpanded, activeConversation, isNewConversation, resetActiveConversation } =
    useTeamChatStore([
      'isChatListExpanded',
      'activeConversation',
      'setIsChatListExpanded',
      'isNewConversation',
      'resetActiveConversation',
    ]);
  const updatePanel = useUpdateCurrentPanelWidth();

  const onClickExpand = () => {
    // collapse the chat list only when a conversation is selected either a new or an old one
    setIsChatListExpanded(!isChatListExpanded);
    updatePanel(!isChatListExpanded ? 'xlarge' : 'smallXXL');
  };

  const onClosePanel = () => {
    if (isNewConversation) {
      resetActiveConversation();
    }
    onClose();
  };

  useEffect(() => {
    if (isSmallSpace) {
      setIsChatListExpanded(false);
      updatePanel('smallXXL');
    }
  }, [isSmallSpace]);

  return (
    <header css={[trayTopBarStyles, !activeConversation && { justifyContent: 'flex-end' }]}>
      {activeConversation && (
        <IconButton
          onClick={onClickExpand}
          css={!isChatListExpanded && rotateCollapseButton}
          label=''
          disabled={isSmallSpace}
          trackingId='team-chat-2.0-expand-collapse-chat-list-button'
        >
          <Icon name='collapse-right' size={20} />
        </IconButton>
      )}
      <IconButton label='close' onClick={onClosePanel} trackingId='team-chat-2.0-close-team-chat-button'>
        <Icon name='x' size={20} />
      </IconButton>
    </header>
  );
};
