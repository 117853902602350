import { memo } from 'react';
import { css } from '@emotion/react';
import { Handle, Position } from '@xyflow/react';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, useTooltip } from '@frontend/design-system';
import { SharedNodeProps } from '../data';
import { BaseRoutingNode } from './routing-node';

export const CallGroupNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      icon='call-group'
      title='Call Group'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={(id) => {
        console.log('delete', id);
      }}
      onClickEdit={(id) => {
        console.log('edit', id);
      }}
      onClickLink={onClickLink}
    >
      {data.isPhoneTreeDescendent && (
        <>
          <Text size='small' color='subdued'>
            {i18next.t('Caller Label')}
          </Text>
          <Text>{data.callObject?.callGroupExpansion?.callerLabel || '--'}</Text>
        </>
      )}
    </BaseRoutingNode>
  );
});
CallGroupNode.displayName = 'CallGroupNode';

export const CallQueueNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      icon='call-queue'
      title='Call Queue'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    >
      {data.isPhoneTreeDescendent && (
        <>
          <Text size='small' color='subdued'>
            {i18next.t('Caller Label')}
          </Text>
          <Text>{data.callObject?.callQueueExpansion?.callerLabel || '--'}</Text>
        </>
      )}
    </BaseRoutingNode>
  );
});
CallQueueNode.displayName = 'CallQueueNode';

export const CallRouteNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      icon='call-route'
      title='Call Route'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    ></BaseRoutingNode>
  );
});
CallRouteNode.displayName = 'CallRouteNode';

export const ForwardingDeviceNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      icon='cell-phone'
      title='Device'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    >
      {data.isPhoneTreeDescendent && (
        <>
          <Text size='small' color='subdued'>
            {i18next.t('Caller Label')}
          </Text>
          <Text>{data.callObject?.deviceExpansion?.callerLabel || '--'}</Text>
        </>
      )}
    </BaseRoutingNode>
  );
});
ForwardingDeviceNode.displayName = 'ForwardingDeviceNode';

export const ForwardingNumberNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      icon='send-forward'
      title='Forwarding Number'
      label={data.label}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    />
  );
});
ForwardingNumberNode.displayName = 'ForwardingNumberNode';

export const OfficeHoursNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      icon='clock'
      title='Phone Hours'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    />
  );
});
OfficeHoursNode.displayName = 'OfficeHoursNode';

export const PhoneTreeNode = memo(({ data, type, onClickLink, AudioPlayerComponent }: SharedNodeProps) => {
  const { timeout, maxFailures, maxTimeouts, promptMediaFileName, promptMediaItemId } =
    data.callObject?.phoneTreeExpansion || {};

  return (
    <BaseRoutingNode
      icon='phone-tree'
      title='Phone Tree'
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: ${theme.spacing(1)};
        `}
      >
        <div>
          <Text size='small' color='subdued'>
            {i18next.t('Phone Tree Prompt')}
          </Text>
          <Text>{promptMediaFileName || '--'}</Text>
        </div>

        {AudioPlayerComponent && promptMediaItemId ? (
          <AudioPlayerComponent mediaItemId={promptMediaItemId} />
        ) : undefined}

        <div>
          <Text size='small' color='subdued'>
            {i18next.t('Timeout after')}
          </Text>
          <Text>{((timeout ?? 0) / 1000).toFixed(0)} seconds</Text>
        </div>

        <div>
          <Text size='small' color='subdued'>
            {i18next.t('Max timeouts')}
          </Text>
          <Text>{maxTimeouts || '--'}</Text>
        </div>

        <div>
          <Text size='small' color='subdued'>
            {i18next.t('Max invalid selections')}
          </Text>
          <Text>{maxFailures || '--'}</Text>
        </div>
      </div>
    </BaseRoutingNode>
  );
});
PhoneTreeNode.displayName = 'PhoneTreeNode';

export const TreeOptionNode = memo(({ data }: SharedNodeProps) => {
  const handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  };
  return (
    <div className='nodrag nopan'>
      <Handle
        type='target'
        position={Position.Top}
        id={handles.top.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <div
        style={{
          width: 24,
          height: 24,
          backgroundColor: theme.colors.neutral90,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text as='span' color='white' weight='bold' size='small'>
          {data.label}
        </Text>
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        id={handles.bottom.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
    </div>
  );
});
TreeOptionNode.displayName = 'TreeOptionNode';

export const PlayNode = memo(({ data, type, onClickLink, AudioPlayerComponent }: SharedNodeProps) => {
  const mediaItemId = data.callObject?.playMessageExpansion?.mediaItemId;

  return (
    <BaseRoutingNode
      icon='play'
      title='Play Message'
      label={data.label}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    >
      {AudioPlayerComponent && mediaItemId ? <AudioPlayerComponent mediaItemId={mediaItemId} /> : undefined}
    </BaseRoutingNode>
  );
});
PlayNode.displayName = 'PlayNode';

export const VoicemailNode = memo(({ data, type, onClickLink, AudioPlayerComponent }: SharedNodeProps) => {
  const greetingId = data.callObject?.voicemailExpansion?.greetingMediaItemId;
  const downloadUrl = data.callObject?.voicemailExpansion?.downloadUrl;

  return (
    <BaseRoutingNode
      icon='voicemail'
      title={'Voicemail'}
      label={data.label}
      linkId={data.callObject?.primitiveId}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    >
      {greetingId && downloadUrl && (
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: ${theme.spacing(1)};
          `}
        >
          <div>
            <Text size='small' color='subdued'>
              {i18next.t('Greeting')}
            </Text>
            <Text>{data.callObject?.voicemailExpansion?.greetingMediaFileName || '--'}</Text>
          </div>
          {AudioPlayerComponent && <AudioPlayerComponent mediaItemId={greetingId} downloadUrl={downloadUrl} />}
        </div>
      )}
    </BaseRoutingNode>
  );
});
VoicemailNode.displayName = 'VoicemailNode';

export const RepeatNode = memo(({ data, type, onClickLink }: SharedNodeProps) => {
  return (
    <BaseRoutingNode
      icon='automated-message'
      title={'Repeat Phone Tree'}
      label={data.label}
      type={type}
      onClickDelete={() => {}}
      onClickEdit={() => {}}
      onClickLink={onClickLink}
    />
  );
});
RepeatNode.displayName = 'RepeatNode';

export const FallbackNode = memo(({ data }: SharedNodeProps) => {
  const handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  };

  const { tooltipProps, triggerProps, Tooltip } = useTooltip();

  return (
    <div className='nodrag nopan' {...triggerProps}>
      <Handle
        type='target'
        position={Position.Top}
        id={handles.top.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <div
        style={{
          height: 24,
          padding: theme.spacing(0.25, 1),
          backgroundColor: theme.colors.neutral90,
          borderRadius: theme.borderRadius.small,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Text as='span' color='white' weight='bold' size='small'>
          {data.label}
        </Text>
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        id={handles.bottom.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
      <Tooltip {...tooltipProps}>
        {i18next.t(
          'The fallback is triggered when either the Phone Tree times out or the caller exceeds the maximum number of invalid selections.'
        )}
      </Tooltip>
    </div>
  );
});
FallbackNode.displayName = 'FallbackNode';
