import { FC, useEffect, useState } from 'react';
import { BuilderTypes } from '@frontend/forms-builder-engine';
import { useTranslation } from '@frontend/i18n';
import { Accordion, Text } from '@frontend/design-system';
import { BuilderFieldDivisionTypes } from '../../../../types';
import { textStyle } from './categories-layout.styles';
import DroppableFieldList from './droppable-field-list/droppable-field-list.component';

interface PrimaryFieldCategoriesProps {
  categories: BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory[];
  fields: BuilderTypes.BuilderFormElementTypes.PrimaryFields.ParsedFields;
  type: 'primary';
  usedFields: BuilderTypes.BuilderFormElementTypes.PrimaryFields.UsedPrimaryFields;
}

interface PaymentsFieldCategoriesProps {
  categories: BuilderFieldDivisionTypes.PaymentsFieldsDivisionCategory[];
  fields: BuilderTypes.BuilderFormElementTypes.PaymentsFields.ParsedFields;
  type: 'payments';
}

interface OtherFieldCategoriesProps {
  categories: BuilderFieldDivisionTypes.OtherFieldsDivisionCategory[];
  fields: BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFields;
  type: 'other';
}

type CategoriesLayoutProps = PrimaryFieldCategoriesProps | PaymentsFieldCategoriesProps | OtherFieldCategoriesProps;

const CategoriesLayout: FC<CategoriesLayoutProps> = (props) => {
  const { t } = useTranslation('forms');
  const [isEmpty, setIsEmpty] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    for (const category of props.categories) {
      if (category.fieldsOrder.length > 0) {
        setIsEmpty(false);
        setIsReady(true);
        return;
      }
    }

    setIsEmpty(true);
    setIsReady(true);
  }, [props.categories]);

  if (!isReady) {
    return null;
  }

  if (isEmpty) {
    return (
      <Text textAlign='center' color='subdued' css={textStyle}>
        {t('No matching results found!')}
      </Text>
    );
  }

  return (
    <>
      {props.categories.map((category) => {
        if (category.fieldsOrder.length === 0) {
          return null;
        }

        if (category.label === 'Medical History') {
          // This is added for type checking for the DroppableFieldList component
          if (props.type === 'payments') {
            return null;
          }

          return props.type === 'primary' ? (
            <DroppableFieldList
              key={category.label}
              category={category as BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory}
              fields={props.fields}
              type={props.type}
              usedFields={props.usedFields}
            />
          ) : (
            <DroppableFieldList
              key={category.label}
              category={category as BuilderFieldDivisionTypes.OtherFieldsDivisionCategory}
              fields={props.fields}
              type={props.type}
            />
          );
        }

        return (
          <Accordion.Item value={category.label} key={category.label}>
            <Accordion.Header title={category.label} />
            <Accordion.Body>
              {props.type === 'primary' && (
                <DroppableFieldList
                  category={category as BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory}
                  fields={props.fields}
                  type={props.type}
                  usedFields={props.usedFields}
                />
              )}

              {props.type === 'payments' && (
                <DroppableFieldList
                  category={category as BuilderFieldDivisionTypes.PaymentsFieldsDivisionCategory}
                  fields={props.fields}
                  type={props.type}
                />
              )}

              {props.type === 'other' && (
                <DroppableFieldList
                  category={category as BuilderFieldDivisionTypes.OtherFieldsDivisionCategory}
                  fields={props.fields}
                  type={props.type}
                />
              )}
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </>
  );
};

export default CategoriesLayout;
