import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL, isWeaveUser } from '@frontend/auth-helpers';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';

export const useCanDoAction = () => {
  const { locationId, locationIds } = useMultiQueryUtils();
  const { merchantsData } = useMerchant();

  const canRefund: boolean = hasSchemaACL(locationId, Permission.PAYMENTS_REFUNDS);
  const canExport: boolean = hasSchemaACL(locationId, Permission.PAYMENTS_EXPORTS);
  const paymentsBillingManagerLocations = locationIds.filter((location) =>
    hasSchemaACL(location, [
      Permission.BILLING_INFORMATION_READ,
      Permission.BILLING_INFORMATION_WRITE,
      Permission.PAYMENTS_REFUNDS,
      Permission.PAYMENTS_EXPORTS,
      Permission.PAYMENTS_EXPRESS_DASHBOARD,
    ])
  );
  const canModifyWritebacks = paymentsBillingManagerLocations.includes(locationId);
  const isPaymentsBillingManager = paymentsBillingManagerLocations.includes(locationId) || isWeaveUser();
  const allowedPaymentsLocations = paymentsBillingManagerLocations.filter(
    (locationId) => !!merchantsData[locationId]?.hasPayments
  );

  return { canRefund, canExport, canModifyWritebacks, isPaymentsBillingManager, allowedPaymentsLocations };
};
