import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useSearch } from '@tanstack/react-location';
import { ScheduleRequestHistoryItem } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import dayjs from 'dayjs';
import { CustomizationFlagQueries } from '@frontend/api-customization-flags';
import { ScheduleTypes } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { TableColumnConfig, Table, Text, Chip, ChipProps, Alert } from '@frontend/design-system';
import { useGetScheduleRequestHistoryData } from '../../hooks/scheduler-v3/use-get-schedule-request-history-data';
import { AppointmentTypeChip } from './AppointmentTypeChip';
import { CustomRowsPerPage } from './CustomRowsPerPage';
import { CustomTablePagination } from './CustomTablePagination';
import { LocationFilter } from './LocationFilter';

const DEFAULT_PAGE_SIZE = 25;

export const ScheduleRequestsHistoryTable = () => {
  const { t } = useTranslation('scheduleRequestHistoryTable');
  const { selectedLocationIds } = useAppScopeStore();
  const { locationId: queryParamLocationId } = useSearch<{ Search: { locationId?: string } }>();
  const {
    locationIdWiseCustomizationFlagDetails: locationIdWiseOnlineSchedulingCustomizationFlagDetails,
    isFeatureHiddenInAllLocations,
  } = CustomizationFlagQueries.useAggregateCustomizationFlagDetails({
    locationIds: selectedLocationIds,
    enabled: true,
    customizationFlag: Feature.ONLINE_SCHEDULING,
  });

  const locationIds =
    Object.keys(locationIdWiseOnlineSchedulingCustomizationFlagDetails).reduce((acc, curr) => {
      if (locationIdWiseOnlineSchedulingCustomizationFlagDetails[curr]) {
        acc.push(curr);
      }
      return acc;
    }, [] as string[]) || selectedLocationIds;

  const locationId =
    queryParamLocationId && locationIds.includes(queryParamLocationId) ? queryParamLocationId : locationIds[0];

  const [selectedLocationId, setSelectedLocationId] = useState(locationId);

  const [pageConfig, setPageConfig] = useState<ScheduleTypes.ScheduleRequestHistoryPageConfig>({
    offset: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const {
    data: scheduleRequestsHistoryList,
    isLoading: isLoadingScheduleRequestsHistoryList,
    refetch: refetchScheduleRequestsHistory,
  } = useGetScheduleRequestHistoryData(selectedLocationId, pageConfig);

  const getAppointmentOutcomeColor = (appointmentOutcome: string): ChipProps['variant'] => {
    switch (appointmentOutcome.toLowerCase()) {
      case 'accepted':
        return 'success';
      case 'pending':
        return 'warn';
      case 'confirmed':
        return 'eggplant';
      case 'cancelled':
        return 'critical';
      case 'completed':
        return 'primary';
      default:
        return 'disabled';
    }
  };

  useEffect(() => {
    if (locationId) setSelectedLocationId(locationId);
  }, [locationId]);

  const columnConfig: TableColumnConfig<ScheduleRequestHistoryItem>[] = useMemo(
    () => [
      {
        Header: t('Recipient'),
        id: 'name',
        accessor: (row) => row.recipient,
        cellRenderer: (row) => {
          return (
            <Text weight='bold' size='medium'>
              {row}
            </Text>
          );
        },
      },
      {
        Header: t('Appointment Date & Time'),
        id: 'message-date-time',
        disableSortBy: true,
        accessor: (row) => row?.messageDatetime,
        width: 216,
        cellRenderer: (row) => {
          if (!row) return <Text size='medium'>-</Text>;
          return <Text size='medium'>{dayjs(row).format('MMMM D, YYYY h:mm A')}</Text>;
        },
      },
      {
        Header: t('Reviewed By'),
        id: 'reviewed-by',
        disableSortBy: true,
        accessor: (row) => row?.reviewedBy,
        cellRenderer: (row) => {
          if (!row) return <Text size='medium'>-</Text>;
          return <Text size='medium'>{row}</Text>;
        },
      },
      {
        Header: t('Review Date'),
        id: 'review-date',
        disableSortBy: true,
        width: 216,
        accessor: (row) => row?.reviewedAt,
        cellRenderer: (row) => {
          if (!row) return <Text size='medium'>-</Text>;
          return <Text size='medium'>{dayjs(row).format('MMMM D, YYYY h:mm A')}</Text>;
        },
      },
      {
        Header: t('Appointment Type'),
        id: 'appointment-type',
        disableSortBy: true,
        accessor: (row) => row?.appointmentType,
        cellRenderer: (row) => {
          if (!row) return <Text size='medium'>-</Text>;
          return <AppointmentTypeChip appointmentType={row} />;
        },
      },
      {
        Header: t('Appointment Outcome'),
        id: 'appointment-outcome',
        disableSortBy: true,
        accessor: (row) => row?.appoinmentOutcome,
        cellRenderer: (row) => {
          if (!row) return <Text size='medium'>-</Text>;
          return <Chip variant={getAppointmentOutcomeColor(row)}>{row}</Chip>;
        },
      },
    ],
    []
  );

  const hasOneLocationAvailable = selectedLocationIds.length === 1;

  if (isFeatureHiddenInAllLocations) {
    return (
      <Alert type='warning'>
        {t(
          "It looks like you don't have permission to view this page. Please contact your system administrator for more information."
        )}
      </Alert>
    );
  }

  return (
    <>
      <Table
        colConfig={columnConfig}
        data={scheduleRequestsHistoryList}
        isLoading={isLoadingScheduleRequestsHistoryList}
        hasGlobalSearch
        fullHeight
        fullHeightConfig={{
          minHeight: 230,
          // NOTE - The offset is to account for the height of the custom pagination container
          offset: 40,
        }}
        tableActions={[
          {
            Icon: () => <Icon name='update' size={16} />,
            label: t('Refresh Data'),
            onClick: () => refetchScheduleRequestsHistory(),
            trackingId: 'refresh-data',
            type: 'icon',
          },
        ]}
        customToolbarRender={() => {
          return (
            !hasOneLocationAvailable && (
              <LocationFilter
                initialSelectedLocationId={locationId || selectedLocationIds[0]}
                selectedLocationId={selectedLocationId}
                setSelectedLocationId={setSelectedLocationId}
                locationIds={locationIds}
              />
            )
          );
        }}
      />
      <div css={customPaginationContainerStyles}>
        <CustomRowsPerPage
          rowsPerPageOptions={[DEFAULT_PAGE_SIZE, 50]}
          handleRowsPerPageChange={(pageSize) => {
            setPageConfig((prev) => ({ ...prev, offset: 0, pageSize: pageSize }));
          }}
        />
        <CustomTablePagination
          page={Math.floor(pageConfig.offset / pageConfig.pageSize) + 1}
          hasNextPage={scheduleRequestsHistoryList.length === pageConfig.pageSize}
          hasPrevPage={pageConfig.offset > 0}
          handlePageChange={(pageNumber: number) => {
            setPageConfig((prev) => ({
              ...prev,
              offset: (pageNumber - 1) * pageConfig.pageSize,
              pageSize: pageConfig.pageSize,
            }));
          }}
        />
      </div>
    </>
  );
};

const customPaginationContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
});
