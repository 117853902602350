import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ButtonBar, Heading, PopoverMenu, Text, TextLink, usePopoverMenu } from '@frontend/design-system';

type InfoTipPopoverProps = {
  children: ReactNode | string;
  ctaAction?: () => void;
  ctaTitle?: string;
  title?: string;
  trackingId?: string;
};

export const InfoTipPopover = ({ children, ctaAction, ctaTitle, title, trackingId }: InfoTipPopoverProps) => {
  const { t } = useTranslation('analytics');

  const { getMenuProps, getTriggerProps, close } = usePopoverMenu({
    placement: 'bottom',
  });

  const handleCtaClick = () => {
    close();
    ctaAction?.();
  };

  return (
    <>
      <Button
        {...getTriggerProps()}
        className='info-icon-button'
        css={styles.icon}
        iconName='info'
        label='Info'
        trackingId={`info-tip-for-${trackingId || title?.replaceAll(' ', '-')}`}
        variant='secondary'
      />
      <PopoverMenu {...getMenuProps()}>
        <div css={styles.content}>
          {title && (
            <Heading className='heading' level={3}>
              {title}
            </Heading>
          )}
          {typeof children === 'string' ? <Text>{children}</Text> : children}
          {ctaAction && (
            <ButtonBar css={styles.buttonBar}>
              <TextLink
                onClick={handleCtaClick}
                trackingId={`learn-more-about-${trackingId || title?.replaceAll(' ', '-')}`}
              >
                {ctaTitle || t('Learn More')}
              </TextLink>
            </ButtonBar>
          )}
        </div>
      </PopoverMenu>
    </>
  );
};

const styles = {
  icon: css`
    height: ${theme.spacing(3)};
    min-width: ${theme.spacing(3)};
    padding: 0;
    width: ${theme.spacing(3)};

    svg {
      color: ${theme.colors.neutral60};
    }
  `,

  content: css`
    padding: ${theme.spacing(1, 2)};
    max-width: 400px;
    position: relative;

    .heading {
      margin-bottom: ${theme.spacing(1)};
    }

    p {
      font-size: ${theme.fontSize(14)};
    }
  `,

  buttonBar: css`
    justify-content: flex-start;
    padding: ${theme.spacing(1, 0)};

    button {
      text-decoration: none;

      &:focus {
        text-decoration: none;
      }
    }
  `,
};
