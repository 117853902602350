import { ScheduleApi, ScheduleTypes } from '@frontend/api-schedule';
import { ServiceProvidersConstants } from '@frontend/api-service-providers';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { queryKeys } from '../../query-keys';

const { getOfficeHoursForMultipleLocationIds } = ScheduleApi;
const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

export const useGetOfficeHoursForMultipleLocationIds = (
  request: ScheduleTypes.GetOfficeHoursForMultipleLocationIdsType['input']
) => {
  return useLocalizedQuery({
    queryKey: queryKeys.officeHoursForMultipleLocationIds(request.locationIds ?? []),
    queryFn: () => getOfficeHoursForMultipleLocationIds(request),
    retry: 1,
    cacheTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr
    staleTime: 60 * ONE_MINUTE_IN_MILLISECONDS, // 1hr,
    enabled: !!request.locationIds?.length,
    select: ({ officeHoursData }) => (officeHoursData ? officeHoursData : []),
  });
};
