import { useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { PhoneCallsApi } from '@frontend/api-phone-calls';
import {
  DashboardWidget,
  DashboardWidgetFC,
  TimePeriodListBoxMenu,
  useDashboardWidget,
  useTimePeriodListBoxMenuState,
} from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { LocationFilterButton, useLocationFilter } from '@frontend/location-filter';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { ContentLoader, Text, styles, emptyStateGraphics } from '@frontend/design-system';
import { useFetchAggregatedCallsAndDurationReport } from '../components/phone/hooks';
import { queryKeys } from '../query-keys';
import { appendTime, formatEndDateWithTimeZone, formatStartDateWithTimeZone } from '../utils';

function formatDuration(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return !minutes ? `${remainingSeconds}s` : !remainingSeconds ? `${minutes}m` : `${minutes}m ${remainingSeconds}s`;
}

/**
 * @dashboard-widget
 *
 * id: phones-snapshot-widget
 * title: Phones Snapshot
 * description: View daily average response time, total calls placed, and total calls received.
 * icon: phone-small
 */
export const PhonesSnapshotWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('analytics');
  const { currentSize } = useDashboardWidget();

  const { startDate, endDate, ...timePeriodMenuState } = useTimePeriodListBoxMenuState('week');
  const { getSelectedLocationData } = useAppScopeStore();
  const { buttonProps, filteredLocationIds } = useLocationFilter();

  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const shouldUseCallSnapshotEndpoint = flagValues['nwx-use-callresults-data'] ?? false;

  const { data, isLoading } = useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.CallsAggregationKey>(
    'call_direction',
    {
      end_date: appendTime(dayjs(endDate)),
      location_id: filteredLocationIds,
      start_date: appendTime(dayjs(startDate)),
      time_zone: getSelectedLocationData()[filteredLocationIds[0]]?.timezone,
    },
    !shouldUseCallSnapshotEndpoint
  );

  const payload = {
    startTime: appendTime(dayjs(startDate)) ?? '',
    endTime: appendTime(dayjs(endDate)) ?? '',
  };

  const searchQuery = useMemo(
    () => `${JSON.stringify({ ...payload })}`,
    [payload.startTime, payload.endTime, filteredLocationIds]
  );

  const { data: snapshotData, isLoading: isPhoneSnapshotDataLoading } = useScopedQuery({
    queryKey: queryKeys.phoneSnapshotReport(searchQuery),
    queryFn: () =>
      PhoneCallsApi.getPhoneSnapshot({
        startTime: formatStartDateWithTimeZone(payload.startTime),
        endTime: formatEndDateWithTimeZone(payload.endTime),
        locationIds: filteredLocationIds,
      }),
    enabled: shouldUseCallSnapshotEndpoint && filteredLocationIds.length > 0,
    retry: false,
  });

  const { incomingCalls, outgoingCalls, averageCallDuration, isDataLoading, isDataAvailable } = useMemo(() => {
    let incomingCalls = 0;
    let outgoingCalls = 0;
    let totalCallDuration = 0;

    if (shouldUseCallSnapshotEndpoint) {
      incomingCalls = snapshotData?.totalInboundCalls || 0;
      outgoingCalls = snapshotData?.totalOutboundCalls || 0;
    } else {
      Object.values(data?.aggregatedMultiLocations || {}).forEach(({ calls, duration }) => {
        incomingCalls += calls?.inbound || 0;
        outgoingCalls += calls?.outbound || 0;
        totalCallDuration += (duration?.inbound || 0) + (duration?.outbound || 0);
      });
    }

    const totalCalls = incomingCalls + outgoingCalls;
    const averageCallDuration = totalCallDuration / (totalCalls || 1);

    return {
      incomingCalls,
      outgoingCalls,
      averageCallDuration: !shouldUseCallSnapshotEndpoint ? formatDuration(averageCallDuration) : 0,
      isDataAvailable: totalCalls > 0,
      isDataLoading: shouldUseCallSnapshotEndpoint ? isPhoneSnapshotDataLoading : isLoading,
    };
  }, [shouldUseCallSnapshotEndpoint, snapshotData, data, isLoading, isPhoneSnapshotDataLoading]);

  const isNarrowSize = currentSize === 'large-narrow';
  return (
    <DashboardWidget>
      <DashboardWidget.Header>
        <div css={{ flexGrow: 1, display: 'flex', flexDirection: 'row-reverse' }}>
          <TimePeriodListBoxMenu {...timePeriodMenuState} readonly />
        </div>
      </DashboardWidget.Header>
      <DashboardWidget.Content css={containerStyle}>
        <ContentLoader show={isDataLoading} />
        {!isNarrowSize && filteredLocationIds.length > 1 && <LocationFilterButton {...buttonProps} />}
        {!isDataAvailable && !isDataLoading ? (
          <div
            css={[
              styles.flexCenter,
              { flexDirection: isNarrowSize ? 'column' : 'row', flexGrow: 1, gap: theme.spacing(2) },
            ]}
          >
            {!!filteredLocationIds.length && <emptyStateGraphics.no_calls_made height={150} width={150} />}
            <Text color='light'>
              {!filteredLocationIds.length ? t('No locations selected') : t('No data available')}
            </Text>
          </div>
        ) : (
          <div css={isNarrowSize ? columnStyle : rowStyle}>
            {!shouldUseCallSnapshotEndpoint && (
              <SnapshotItem iconName='clock-small' label={t('Avg. Time to Answer')} value={averageCallDuration} />
            )}
            <SnapshotItem iconName='phone-outgoing-small' label={t('Total Calls Placed')} value={outgoingCalls} />
            <SnapshotItem iconName='phone-incoming-small' label={t('Total Calls Received')} value={incomingCalls} />
          </div>
        )}
      </DashboardWidget.Content>
    </DashboardWidget>
  );
};

PhonesSnapshotWidget.config = {
  size: {
    extraSmall: 'large-narrow',
    small: 'large-narrow',
    medium: 'medium-wide',
    large: 'medium-wide',
  },
  feature: 'phone',
};

const containerStyle = css({
  marginTop: theme.spacing(1),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const rowStyle = css({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
});

const columnStyle = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  height: '100%',
});

const SnapshotItem = ({ iconName, value, label }: { iconName: IconName; value: string | number; label: string }) => (
  <div
    css={{
      border: `1px solid ${theme.colors.neutral20}`,
      borderRadius: theme.borderRadius.medium,
      padding: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      flex: '1 0 0',
    }}
  >
    <Icon name={iconName} />
    <Text weight='bold' css={{ fontSize: theme.fontSize(40), lineHeight: 1 }}>
      {value}
    </Text>
    <Text color='light' size='small'>
      {label}
    </Text>
  </div>
);
