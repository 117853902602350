import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import {
  useInteractions,
  useFloating,
  FloatingOverlay,
  FloatingFocusManager,
  FloatingPortal,
  useDismiss,
  useClick,
} from '@floating-ui/react';
import { motion, AnimatePresence, type DragHandlers } from 'motion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton, KeyNames, useEventListener, Text } from '@frontend/design-system';
import { useGetAppointmentInfo } from '../../../hooks';
import { AppointmentEventCard } from '../../schedule-calendar-components/appointment-event-card';
import { useCalendarView } from '../providers';
import type { EventData } from '../types';

//@ts-ignore This works fine, just the types don't like each other
const MotionFloatingOverlay = motion(FloatingOverlay);

const calculateAnimation = (index: number, direction: 'next' | 'prev', itemsLength: number) => {
  const zIndex = itemsLength - index;
  const opacity = 1 - index * 0.4;
  const scale = 1 - index * 0.15;
  const translateX = index * 150;

  const initial = {
    opacity: 0,
    translateX: direction === 'next' ? 100 : -100,
    scale: 0,
  };

  const animate = {
    opacity,
    translateX,
    scale,
  };

  const exit = {
    opacity: 0,
    scale: 0,
    translateX: direction === 'next' ? -100 : 100,
  };

  const whileHover = {
    translateX: index === 0 ? translateX : translateX + 10,
  };

  return { zIndex, initial, animate, exit, whileHover };
};

const overlayAnimationVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

export const AppointmentEventsCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState<'next' | 'prev'>('next');

  const {
    eventCarouselData: { isOpen, events, locationName },
    setEventCarouselData,
  } = useCalendarView(['eventCarouselData', 'setEventCarouselData']);

  const { refs, context } = useFloating({
    open: isOpen,
    onOpenChange: (isOpen) => {
      if (isOpen) return;
      setActiveIndex(0);
      setEventCarouselData({ isOpen: false, events: [], locationName: '' });
    },
  });

  const click = useClick(context, {});
  const dismiss = useDismiss(context, {
    outsidePressEvent: 'mousedown',
  });

  const { getFloatingProps } = useInteractions([click, dismiss]);

  const handleNext = useCallback(() => {
    setDirection('next');
    setActiveIndex((prevIndex) => (prevIndex + 1) % events.length);
  }, [events.length]);

  const handlePrev = useCallback(() => {
    setDirection('prev');
    setActiveIndex((prevIndex) => (prevIndex - 1 + events.length) % events.length);
  }, [events.length]);

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === KeyNames.Left) {
        handlePrev();
      }
      if (event.key === KeyNames.Right) {
        handleNext();
      }
    },
    [handleNext, handlePrev]
  );

  useEventListener('keydown', handleKeyDown, isOpen);

  const visibleItems = [
    events[activeIndex],
    events[(activeIndex + 1) % events.length],
    events[(activeIndex + 2) % events.length],
  ].slice(0, events.length);

  const handleDragEnd: DragHandlers['onDragEnd'] = (_, info) => {
    const swipeThreshold = 100;
    const swipeDirection = info.offset.x > 0 ? 'right' : 'left';

    if (Math.abs(info.offset.x) > swipeThreshold) {
      if (swipeDirection === 'right') {
        handlePrev();
      } else {
        handleNext();
      }
    }
  };

  return (
    <FloatingPortal
      css={css`
        z-index: ${theme.zIndex.modals};
      `}
    >
      <FloatingFocusManager context={context}>
        <AnimatePresence initial={false} custom={direction} mode='sync'>
          {isOpen && (
            <MotionFloatingOverlay
              lockScroll
              initial='initial'
              animate='animate'
              exit='exit'
              variants={overlayAnimationVariants}
              css={(theme) => css`
                background: rgba(0, 33, 82, 0.3);
                top: ${theme.heightOffset}px !important;
              `}
            >
              <motion.div {...getFloatingProps()}>
                <motion.div style={{ display: 'flex', justifyContent: 'center' }} ref={refs.setFloating}>
                  <AnimatePresence initial={false} custom={direction} mode='sync'>
                    {visibleItems.map((event, index) => {
                      if (!event) return null;

                      const topAppointment = index === 0;
                      const { zIndex, initial, animate, exit, whileHover } = calculateAnimation(
                        index,
                        direction,
                        visibleItems.length
                      );

                      return (
                        <motion.div
                          key={event.id}
                          custom={direction}
                          drag={topAppointment ? 'x' : false}
                          dragConstraints={{ left: 0, right: 0 }}
                          onDragEnd={handleDragEnd}
                          dragElastic={0.1}
                          onClick={() => {
                            if (topAppointment) return;
                            setDirection('next');
                            setActiveIndex((activeIndex + index) % events.length);
                          }}
                          initial={initial}
                          animate={animate}
                          exit={exit}
                          whileHover={whileHover}
                          transition={{ duration: 0.3 }}
                          style={{
                            zIndex,
                            position: 'absolute',
                            width: 'auto',
                            height: '100%',
                            pointerEvents: 'none',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            translateY: '-50%',
                            maxWidth: 600,
                            top: '50%',
                          }}
                        >
                          <CarouselAppointmentEventCard event={event} locationName={locationName} />
                        </motion.div>
                      );
                    })}
                  </AnimatePresence>
                  <>
                    <CarouselCounter activeIndex={activeIndex} total={events.length} />
                    {events.length > 1 && (
                      <>
                        <CarouselButton direction='left' handleOnClick={handlePrev} />
                        <CarouselButton direction='right' handleOnClick={handleNext} />
                      </>
                    )}
                  </>
                </motion.div>
              </motion.div>
            </MotionFloatingOverlay>
          )}
        </AnimatePresence>
      </FloatingFocusManager>
    </FloatingPortal>
  );
};

type CarouselAppointmentEventCardProps = {
  event: EventData;
  locationName?: string;
  eventTypeColor?: string;
};

const maxWidthIncludingMargins = '100% - 48px';

const CarouselAppointmentEventCard = ({ event, locationName, eventTypeColor }: CarouselAppointmentEventCardProps) => {
  const eventColor = eventTypeColor ?? theme.colors.warning50;
  const { setEventCarouselData } = useCalendarView(['setEventCarouselData']);
  const { appointmentDetails } = useGetAppointmentInfo(event?.id ?? '');

  if (!appointmentDetails) return null;

  return (
    <div
      css={css`
        background: ${theme.colors.white};
        pointer-events: auto;
        max-width: min(calc(${maxWidthIncludingMargins}), 600px);
        border-radius: ${theme.borderRadius.medium};
        max-height: calc(100% - ${theme.spacing(8)});
        overflow: auto;
      `}
    >
      <div
        css={css`
          position: relative;
          padding: ${theme.spacing(4)};
          height: 100%;
          ::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 10px;
            background: ${eventColor};
          }
        `}
      >
        <AppointmentEventCard
          eventId={event.eventId}
          locationName={locationName ?? '-'}
          closeAppointmentDetailsModal={() => {
            setEventCarouselData({ isOpen: false, events: [], locationName: '' });
          }}
          appointment={appointmentDetails}
        />
      </div>
    </div>
  );
};

type CarouselButtonProps = {
  direction: 'left' | 'right';
  handleOnClick: () => void;
};

const CarouselButton = ({ direction, handleOnClick }: CarouselButtonProps) => (
  <IconButton
    label={direction === 'left' ? 'Previous' : 'Next'}
    css={css`
      position: fixed;
      top: 50%;
      ${direction}: calc(50% - 360px);
      z-index: ${theme.zIndex.modals};
      background: ${theme.colors.white};
      box-shadow: ${theme.shadows.heavy};
      @media (max-width: 800px) {
        ${direction}: 20%;
        top: initial;
        bottom: ${theme.spacing(1)};
      }
    `}
    onClick={handleOnClick}
    color='light'
  >
    <Icon
      name={`caret-${direction}`}
      css={css`
        color: ${theme.colors.neutral70};
      `}
    />
  </IconButton>
);

type CarouselCounterProps = {
  activeIndex: number;
  total: number;
};

const CarouselCounter = ({ activeIndex, total }: CarouselCounterProps) => (
  <div
    css={css`
      position: fixed;
      background: ${theme.colors.white};
      display: flex;
      justify-content: center;
      padding: ${theme.spacing(1, 2)};
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: ${theme.borderRadius.medium};
      z-index: ${theme.zIndex.modals};
      box-shadow: ${theme.shadows.heavy};
    `}
  >
    <Text weight='bold' size='large'>
      {activeIndex + 1} of {total}
    </Text>
  </div>
);
