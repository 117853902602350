import * as callHistory from './call-history';
import * as callRecordings from './call-recordings';
import * as followUp from './follow-up';
import * as locationComparison from './location-comparison';
import * as overview from './overview';
import * as serviceQuality from './service-quality';

export const CallIntelMockData = {
  ...callRecordings,
  ...followUp,
  ...locationComparison,
  ...overview,
  ...serviceQuality,
  ...callHistory,
};
