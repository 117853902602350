import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { ListRow, Text } from '@frontend/design-system';

type LocationSelectorProps = {
  chargesEnabledLocations: string[];
  setLocation: (locationId: string) => void;
  getLocationName: (locationId?: string) => string | undefined;
};
export const LocationSelector = ({ chargesEnabledLocations, getLocationName, setLocation }: LocationSelectorProps) => {
  const { t } = useTranslation('payments');
  return (
    <div>
      <Text>{t('Select the location making the request')}</Text>
      <div
        css={{
          marginTop: theme.spacing(2),
          border: `1px solid ${theme.colors.neutral20}`,
          borderRadius: theme.borderRadius.medium,
        }}
      >
        {chargesEnabledLocations.map((locationId) => (
          <ListRow
            key={locationId}
            onClick={() => {
              setLocation(locationId);
            }}
          >
            <ListRow.Lead css={{ width: 'max-content' }}>
              <Icon name='location' />
            </ListRow.Lead>
            <ListRow.Content>
              <Text>{getLocationName(locationId)}</Text>
            </ListRow.Content>
            <ListRow.Trail>
              <Icon name='caret-right' />
            </ListRow.Trail>
          </ListRow>
        ))}
      </div>
    </div>
  );
};
