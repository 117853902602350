import Dagre from '@dagrejs/dagre';
import { Node, Edge, useReactFlow } from '@xyflow/react';
import { DataPayload } from './data';

export const calculateLayout = (
  nodes: Node<DataPayload>[],
  edges: Edge[],
  options: { direction: string }
): { nodes: Node<DataPayload>[]; edges: Edge[] } => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));
  g.setGraph({ rankdir: options.direction, ranksep: 100, nodesep: 50 });
  edges.forEach((edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node) =>
    g.setNode(node.id, {
      ...node,
      width: node.measured?.width ?? 0,
      height: node.measured?.height ?? 0,
    })
  );

  Dagre.layout(g);

  return {
    nodes: nodes.map((node) => {
      const position = g.node(node.id);
      const x = position.x - (node.measured?.width ?? 0) / 2;
      // const y = position.y - (node.measured?.height ?? 0) / 2;

      return {
        ...node,
        position: { x, y: position.y },
        type: node.type || 'basic', // Ensure type is always defined
      };
    }),
    edges: edges.map((edge) => {
      return { ...edge };
    }),
  };
};

export const useCalculateLayout = () => {
  const { setNodes, setEdges, getNodes, getEdges } = useReactFlow<Node<DataPayload>>();

  return ({ direction }: { direction: 'TB' | 'LR' }) => {
    const layout = calculateLayout(getNodes(), getEdges(), { direction });

    setNodes([...layout.nodes]);
    setEdges([...layout.edges]);

    return layout;
  };
};
