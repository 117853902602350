import { css } from '@emotion/react';
import { OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ContentLoader, NakedButton, NakedUl, Text } from '@frontend/design-system';
import { NWXDashboardTaskInfo, trackingIds } from '../constants';

const buttonTrackingIdTaskIdMap: Partial<Record<OnboardingModulesTypes.TaskIds, string>> = {
  [OnboardingModulesTypes.TaskIds.EINSetup]: trackingIds.nwxDashboardTasksSection.verifyBusinessInformationButton,
  [OnboardingModulesTypes.TaskIds.UserInviteSetup]: trackingIds.nwxDashboardTasksSection.inviteUserToWeaveButton,
  [OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder]:
    trackingIds.nwxDashboardTasksSection.scheduleTimeNowButton,
  [OnboardingModulesTypes.TaskIds.PaymentsStripeSetup]: trackingIds.nwxDashboardTasksSection.setupPaymentsButton,
  [OnboardingModulesTypes.TaskIds.Schedule1on1Training]:
    trackingIds.nwxDashboardTasksSection.schedule1on1TrainingButton,
};

const hideButtonTrackingIdTaskIdMap: Partial<Record<OnboardingModulesTypes.TaskIds, string>> = {
  [OnboardingModulesTypes.TaskIds.Schedule1on1Training]:
    trackingIds.nwxDashboardTasksSection.hideAction.schedule1on1TrainingHideButton,
};

interface Props {
  isLoading?: boolean;
  taskList?: NWXDashboardTaskInfo[];
  onTaskClick: (task: NWXDashboardTaskInfo) => void;
  onTaskHide: (task: NWXDashboardTaskInfo) => void;
}

export const NWXDashboardTaskList = ({ taskList = [], isLoading, onTaskClick, onTaskHide }: Props) => {
  const { t } = useTranslation('onboarding-modules');
  return (
    <NakedUl css={ulStyle}>
      <ContentLoader
        size='medium'
        show={isLoading}
        backgroundOpacity={0.5}
        css={{ borderRadius: theme.borderRadius.medium }}
      />
      {taskList.map((task) => (
        <li key={task.id} css={liStyle}>
          <Text color='light' size='medium'>
            {task.description}
          </Text>
          <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button
              variant='tertiary'
              css={iconButtonStyle}
              trackingId={buttonTrackingIdTaskIdMap[task.id]}
              iconName={task.icon}
              onClick={() => onTaskClick(task)}
            >
              {task.title}
            </Button>
            {task.showHideTask && (
              <NakedButton trackingId={hideButtonTrackingIdTaskIdMap[task.id]} onClick={() => onTaskHide(task)}>
                <Text size='medium' weight='bold' color='subdued'>
                  {t('Hide')}
                </Text>
              </NakedButton>
            )}
          </div>
        </li>
      ))}
    </NakedUl>
  );
};

const ulStyle = css({
  position: 'relative',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fill, minmax(330px, 1fr))',
  gap: theme.spacing(1),
  margin: theme.spacing(0, 1),
});

const liStyle = css({
  background: theme.colors.white,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  justifyContent: 'space-between',
  padding: theme.spacing(2),
  minWidth: 330,
  flex: '1 1 330px',
  borderRadius: theme.borderRadius.medium,
});

const iconButtonStyle = css({
  height: 32,
  maxWidth: 'max-content',
  color: theme.colors.primary50,
  fontWeight: theme.font.weight.bold,
  fontSize: theme.font.size.medium,
});
