import { css } from '@emotion/react';
import { AnalyticsCommonTypes, BASE_URL_ANALYTICS } from '@frontend/api-analytics';
import { largeDownloadsRef } from '@frontend/file-download-helper';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, ModalControlModalProps, Text, useAlert } from '@frontend/design-system';
import { DateBlock, LocationBlock } from '.';

type GeneratePayload = {
  currentLocationId: string;
  parentLocationId: string;
};

interface Props<T> {
  apiCall: (payload: T) => Promise<AnalyticsCommonTypes.ExportDataReferenceResponse[]>;
  downloadDataUrl: string;
  downloadKey: string;
  endDate?: string;
  fileName: string;
  generatePayload: (args: GeneratePayload) => T;
  locationIds?: string[];
  locationNames: Record<string, string>;
  modalProps: ModalControlModalProps;
  primaryButtonTrackingId?: string;
  referenceCheckUrl: string;
  secondaryButtonTrackingId?: string;
  startDate?: string;
}

const MORE_LOCATIONS_CHECK_COUNT = 6;

export function ExportDataModal<T>({
  apiCall,
  downloadDataUrl,
  downloadKey,
  endDate,
  fileName,
  generatePayload,
  locationIds = [],
  locationNames,
  modalProps,
  primaryButtonTrackingId,
  referenceCheckUrl,
  secondaryButtonTrackingId,
  startDate,
}: Props<T>) {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { selectedLocationIdsWithParents, selectedLocationIds, selectedOrgId } = useAppScopeStore();

  const exportFile = useMutation(apiCall, {
    onSuccess: (data) => {
      if (!data || !data.length) {
        alert.warning(t('Unable to generate download media reference id.'));
      } else {
        const { download: isDownloadReady, id: mediaId, location_id: locationId, media_ref: refId } = data[0];
        largeDownloadsRef.current?.waitForDownload({
          downloadKey,
          isDownloadReady,
          options: {
            downloadReferenceUrl: `${BASE_URL_ANALYTICS}/${referenceCheckUrl}/${refId}`,
            downloadUrl: `${BASE_URL_ANALYTICS}/${downloadDataUrl}/${isDownloadReady ? mediaId : '<mediaID>'}/download`,
            fileName,
            locationId: locationId || selectedLocationIdsWithParents[0],
          },
          selectedLocationIds,
        });
      }
      modalProps.onClose();
    },
    onError: () => {
      alert.error(t('Something went wrong. Please try again.'));
    },
  });

  const handleConfirm = () => {
    exportFile.mutate(
      generatePayload({
        currentLocationId: selectedLocationIdsWithParents[0],
        parentLocationId: selectedOrgId || selectedLocationIdsWithParents[0],
      })
    );
  };

  return (
    <Modal {...modalProps} maxWidth={600}>
      <Modal.Header>{t('Export Data to CSV')}</Modal.Header>
      <Modal.Body>
        <Text textAlign='center'>
          {t('Do you wish to export a CSV file of this data set with the following location and date filters?')}
        </Text>

        {startDate && endDate && (
          <div css={styles.datesContainer}>
            <DateBlock date={startDate} label={t('Start date')} />
            <DateBlock date={endDate} label={t('End date')} />
          </div>
        )}

        <div css={styles.locationsContainer}>
          {locationIds.length > MORE_LOCATIONS_CHECK_COUNT ? (
            <>
              {locationIds.map(
                (locationId, index) =>
                  index < MORE_LOCATIONS_CHECK_COUNT && (
                    <LocationBlock key={locationId} locationName={locationNames[locationId]} />
                  )
              )}
              <LocationBlock
                key='more-locations'
                locationName={t('+{{count}} More', { count: locationIds.length - MORE_LOCATIONS_CHECK_COUNT })}
              />
            </>
          ) : (
            Object.values(locationIds).map((locationId) => (
              <LocationBlock key={locationId} locationName={locationNames[locationId]} />
            ))
          )}
        </div>
        <ContentLoader show={exportFile.isLoading} />
      </Modal.Body>
      <Modal.Actions
        disablePrimary={exportFile.isLoading}
        disableSecondary={exportFile.isLoading}
        onPrimaryClick={handleConfirm}
        onSecondaryClick={modalProps.onClose}
        primaryLabel={t('Confirm')}
        primaryTrackingId={primaryButtonTrackingId}
        secondaryLabel={t('Cancel')}
        secondaryTrackingId={secondaryButtonTrackingId}
      />
    </Modal>
  );
}

const styles = {
  datesContainer: css`
    display: flex;
    gap: ${theme.spacing(10)};
    justify-content: center;
    margin: ${theme.spacing(3, 0, 5)};
    text-align: center;
  `,

  locationsContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(0.5)};
    justify-content: center;
    padding-bottom: ${theme.spacing(3)};
    text-align: center;
  `,
};
