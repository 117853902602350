import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { AddToGoogleBusinessProfileIllustration } from '../../../images';
import { bookingSiteSetupTrackingIds } from '../trackingIds';

export const AddToGoogleBusinessProfilePlaceholder = () => {
  const { t } = useTranslation('schedule');
  const { navigate } = useSettingsNavigate();

  return (
    <section css={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <AddToGoogleBusinessProfileIllustration />
      <Text color='light' textAlign='center'>
        {t(
          "Sign in to your Google Business Account in Social Platforms settings. Don't forget to return here to finish your booking link setup."
        )}
      </Text>
      <Button
        onClick={() => {
          navigate({ to: '/reviews' });
        }}
        trackingId={bookingSiteSetupTrackingIds.takeMeThereBtn}
        css={{ marginTop: theme.spacing(2) }}
      >
        {t('Take Me There')}
      </Button>
    </section>
  );
};
