import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const title = css({
  margin: theme.spacing(2, 0),
});

export const gutterBottomSmall = css({
  marginBottom: theme.spacing(1),
});

export const gutterBottomLarge = css({
  marginBottom: theme.spacing(4),
});

export const divider = css({
  margin: theme.spacing(3, 1),
  border: `solid 1px ${theme.colors.neutral10}`,
});

export const automatedRecallMessagesImg = css({
  width: 285,
  height: 512,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(4),
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.small,
});
