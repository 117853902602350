import {
  MutationFunction,
  QueryFunction,
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { aggregatedDataQueryByLocationFn, aggregatedDataQueryFn, http } from '@frontend/fetch';
import { usePhoneSettingsShallowStore } from '../store/settings';

const hackyMutationFn =
  <TData = unknown, TVariables = unknown>(
    mutationFn: MutationFunction<TData, TVariables>,
    selectedLocationIds: string[]
  ) =>
  async (args: TVariables) => {
    const prevLocationId = http.getLocationIdHeader() ?? '';
    return Promise.all(
      selectedLocationIds.map((selectedLocationId) => {
        http.setLocationIdHeader(selectedLocationId);
        return mutationFn?.(args);
      })
    )
      .then((data) => {
        /**
         * Assume that if only one locationId was provided, then the queryFn only returns one result
         */
        if (selectedLocationIds.length === 1) {
          return data[0];
        }
        /**
         * Otherwise, flatten the array of results.
         * This means that results for all locationIds will be merged together into a single array and
         * we won't be able to tell which result is associated with which locationId
         */
        return data.flat();
      })
      .finally(() => {
        http.setLocationIdHeader(prevLocationId);
      });
  };

/**
 * This query functions attempt to use the settingsTenantLocation if it exists, otherwise it falls back to the locationId selected in the single location picker
 */
export function usePhoneSettingsQuery<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  arg: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey> & { locationIds?: string[]; groupByLocationId?: boolean }
) {
  const { queryKey, queryFn, staleTime, locationIds, groupByLocationId, ...rest } = arg;

  const { settingsTenantLocation, selectedSettingsLocationIds } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'selectedSettingsLocationIds'
  );

  if (!queryFn) {
    throw new Error('queryFn is required');
  } else if (!settingsTenantLocation && !locationIds) {
    console.warn('Either settingsTenantLocation or locationIds is required');
  }

  let selectedLocationIds = selectedSettingsLocationIds ?? [];

  if (locationIds) {
    selectedLocationIds = locationIds;
  }

  let key: unknown[] = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: QueryKey = [selectedLocationIds, ...key];
  const modifiedArguments = {
    queryKey: localizedQueryKey,
    staleTime: staleTime ?? 0,
    queryFn: aggregatedDataQueryFn(queryFn, selectedLocationIds),
    ...rest,
  } as typeof arg;

  return useQuery(modifiedArguments);
}

type TModifiedData<OriginalType> = Record<string, OriginalType>;
type ModifiedArguments<TQueryFnData, TError, TData = TQueryFnData, TQueryKey extends QueryKey = QueryKey> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'select' | 'queryFn'
> & {
  select: (data: TModifiedData<TQueryFnData>) => TData;
  queryFn: QueryFunction<TModifiedData<TQueryFnData>, TQueryKey>;
};

export function usePhoneSettingsQueryByLocations<
  TQueryFnData,
  TError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  arg: Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'select'> & {
    select: (data: TModifiedData<TQueryFnData>) => TData;
  } & { locationIds?: string[] }
) {
  const { queryKey, queryFn, staleTime, locationIds, ...rest } = arg;

  const { settingsTenantLocation, selectedSettingsLocationIds } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'selectedSettingsLocationIds'
  );

  if (!queryFn) {
    throw new Error('queryFn is required');
  } else if (!settingsTenantLocation && !locationIds) {
    console.warn('Either settingsTenantLocation or locationIds is required');
  } else if (!queryKey) {
    throw new Error('queryKey is required');
  }

  let selectedLocationIds = selectedSettingsLocationIds ?? [];

  if (locationIds) {
    selectedLocationIds = locationIds;
  }

  let key: QueryKey = [];
  if (typeof queryKey === 'string') {
    key = [queryKey];
  } else if (Array.isArray(queryKey)) {
    key = queryKey;
  }

  const localizedQueryKey: any = [selectedLocationIds, ...key];
  const modifiedArguments: ModifiedArguments<TQueryFnData, TError, TData, TQueryKey> = {
    queryKey: localizedQueryKey,
    staleTime: staleTime ?? 0,
    queryFn: aggregatedDataQueryByLocationFn<TQueryFnData, TQueryKey>(queryFn, selectedLocationIds),
    ...rest,
  };

  return useQuery<TModifiedData<TQueryFnData>, TError, TData, TQueryKey>(
    modifiedArguments as UseQueryOptions<TModifiedData<TQueryFnData>, TError, TData, TQueryKey>
  );
}

export function usePhoneSettingsMutation<TQueryFnData, TError, TVariables, TContext>(
  args: UseMutationOptions<TQueryFnData, TError, TVariables, TContext> & { locationIds?: string[] }
) {
  const { mutationFn, locationIds, ...rest } = args;
  const { settingsTenantLocation, selectedSettingsLocationIds } = usePhoneSettingsShallowStore(
    'settingsTenantLocation',
    'selectedSettingsLocationIds'
  );

  if (!mutationFn) {
    throw new Error('mutationFn is required');
  } else if (!settingsTenantLocation && !locationIds) {
    console.warn('Either settingsTenantLocation or locationIds is required');
  }

  let selectedLocationIds = selectedSettingsLocationIds ?? [];

  if (locationIds) {
    selectedLocationIds = locationIds;
  }

  const modifiedArguments = {
    mutationFn: hackyMutationFn(mutationFn, selectedLocationIds),
    ...rest,
  } as typeof args;

  return useMutation<TQueryFnData, TError, TVariables, TContext>(modifiedArguments);
}
