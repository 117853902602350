import { FC } from 'react';
import { css } from '@emotion/react';
import { FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { OnlineSchedulingPromoToastCard } from './OnlineSchedulingPromoToastCard';

interface OnlineSchedulingMessagingPromoCardProps {
  patientName?: string;
  locationId: string;
}

export const OnlineSchedulingMessagingPromoCard: FC<OnlineSchedulingMessagingPromoCardProps> = ({
  locationId,
  patientName,
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'online-scheduling-promo' });

  return (
    <div css={{ padding: theme.spacing(2) }}>
      <OnlineSchedulingPromoToastCard
        locationIds={locationId ? [locationId] : []}
        source={FeatureNotificationTypes.FeatureNotificationSource.SOURCE_MESSAGING_TEASER_BANNER}
        styles={cardStyles}
        title={
          patientName
            ? t('Need to schedule an appointment for {{patientName}}?', { patientName })
            : t('Need to schedule an appointment?')
        }
        subtitle={t('Patients can request an appointment with Online Scheduling.')}
      />
    </div>
  );
};

const cardStyles = css({
  width: '100%',
  maxWidth: 'unset',
});
