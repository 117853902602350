import { useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Button, ModalControlModalProps, TableFilters, useModalControl } from '@frontend/design-system';
import { filtersStyles } from '../views/common-styles';

export type FiltersTrayProps =
  | {
      modalProps?: ModalControlModalProps;
      hideFiltersIcon: boolean;
    }
  | {
      modalProps?: never;
      hideFiltersIcon?: never;
    };

type Props = FiltersTrayProps & {
  children: React.ReactNode;
  disableApply?: boolean;
  disableTray?: boolean;
  headerLabel?: string;
  onApplyFilters: () => void;
  onResetFilters?: () => void;
  onRevertLocalChanges?: () => void;
  showFilteredBadge?: boolean;
  trackingId?: string;
};

export const FiltersTray = ({
  children,
  disableApply,
  disableTray,
  headerLabel,
  hideFiltersIcon,
  modalProps: controlledModalProps,
  onApplyFilters,
  onResetFilters,
  onRevertLocalChanges,
  showFilteredBadge,
  trackingId,
}: Props) => {
  const { t } = useTranslation('analytics');
  const { modalProps: selfModalProps, triggerProps } = useModalControl();

  // Override modalProps if provided
  const modalProps = controlledModalProps || selfModalProps;

  const handleResetFilters = () => {
    onResetFilters?.();
    modalProps.onClose();
  };

  useEffect(() => {
    // Revert local changes when modal is closed without applying filters
    if (!modalProps.show) {
      onRevertLocalChanges?.();
    }
  }, [modalProps.show]);

  return (
    <>
      {!hideFiltersIcon && (
        <div
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
          }}
        >
          <Button
            {...triggerProps}
            css={showFilteredBadge ? filtersStyles.filteredBadge : undefined}
            disabled={disableTray}
            iconName='filter-alt'
            label={t('Filters')}
            size='large'
            trackingId={trackingId}
            variant='secondary'
          />
        </div>
      )}

      <TableFilters
        {...modalProps}
        css={wrapperStyles}
        disableApplyButton={disableApply}
        headerLabel={headerLabel}
        onApplyClick={onApplyFilters}
        onClearAllClick={handleResetFilters}
        width='medium'
      >
        {children}
      </TableFilters>
    </>
  );
};

const wrapperStyles = css`
  section {
    > div {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`;
