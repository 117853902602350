import { GetSoftphoneSettingsRequest } from '@weave/schema-gen-ts/dist/schemas/phone/softphones/softphones.pb';
import { http } from '@frontend/fetch';
import { SoftphoneSchemaService, SoftphoneSettingsService } from '@frontend/schema';
import { UsersTypes } from '@frontend/user-helpers';
import { GetLineGenerateTypes } from './types';
import type { SoftphoneTypes } from './';

const baseUrl = 'portal/v1/phone/softphone';

export const list = () => {
  return http.getData<SoftphoneTypes.SoftphoneInfo[]>(baseUrl);
};

export const getCurrentUserSoftphoneData = ({ req = {} }: { req?: GetSoftphoneSettingsRequest }) => {
  return SoftphoneSettingsService.GetSoftphoneSettings(req).catch((err) => {
    if (err.message.includes('no softphones found')) {
      return null;
    }
    throw err;
  });
};

export const getUserSoftphone = async (userId: UsersTypes.UserProfile['UserID']) => {
  const res = await http.getData<SoftphoneTypes.SoftphoneInfo>(`${baseUrl}/${userId}`);
  return res;
};

export const create = (settings: GetLineGenerateTypes['input']): Promise<GetLineGenerateTypes['output']> => {
  return SoftphoneSchemaService.Generate(settings);
};

export const destroy = async (userId: UsersTypes.UserProfile['UserID']) => {
  const res = await http.delete<Record<string, never>>(`${baseUrl}/${userId}`);
  return res;
};
