import { useTranslation } from '@frontend/i18n';
import { Page } from '@frontend/page';
import { theme } from '@frontend/theme';
import { DecoratedLocationPicker } from '../location-picker';

export const LocationPickerPage = () => {
  const { t } = useTranslation('locations');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '100%',
        padding: theme.spacing(3),
      }}
    >
      <Page
        maxWidth={600}
        css={{
          border: `1px solid ${theme.colors.neutral20}`,
          borderRadius: theme.borderRadius.small,
        }}
      >
        <Page.Header>
          <Page.Header.Title title={t('Office Locations')} />
          <Page.Header.Subtitle subtitle={t('Manage multiple offices with ease.')} />
        </Page.Header>
        <DecoratedLocationPicker simple />
      </Page>
    </div>
  );
};
