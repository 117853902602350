import { ReactNode, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { EventType } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { motion } from 'motion/react';
import { OnboardingModulesApi, OnboardingModulesQueries } from '@frontend/api-onboarding-modules';
import { getUser } from '@frontend/auth-helpers';
import { ErrorPage } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { ContentLoader, PrimaryButton, SecondaryButton, SpinningLoader, useAlert } from '@frontend/design-system';
import { TRACKING_IDS } from '../../constants';
import { useTaskTrayShallowStore } from '../../hooks';
import { StepInfo } from './step-info';
import { StepSuccess } from './step-success';

const StepContentContainer = ({ children, containerKey }: { children: ReactNode; containerKey: number | string }) => {
  return (
    <motion.div
      key={containerKey}
      css={{ flexGrow: 1, overflowY: 'auto' }}
      initial={{ x: 50, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
    >
      {children}
    </motion.div>
  );
};

interface Props {
  successStepText: string;
  onNextTask?: () => void;
  isOptionalTask?: boolean;
}

export const StepList = ({ isOptionalTask, successStepText, onNextTask }: Props) => {
  const { t } = useTranslation('onboarding-modules');
  const alerts = useAlert();
  const [isNextClickFromLastStep, setIsNextClickFromLastStep] = useState(false);
  const { selectedStepIndex, selectedTaskId, selectedModuleId, setSelectedStepIndex, setSelectionInfo } =
    useTaskTrayShallowStore(
      'selectedStepIndex',
      'selectedTaskId',
      'selectedModuleId',
      'setSelectedStepIndex',
      'setSelectionInfo'
    );
  const user = getUser();
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const {
    isError,
    isLoading,
    steps,
    refetch: refetchSteps,
  } = OnboardingModulesQueries.useTaskStepsQuery(selectedTaskId);
  const {
    isLoading: isTasksLoading,
    isError: isTasksError,
    refetch: refetchTasks,
    isTaskCompleted,
  } = OnboardingModulesQueries.useIsSelectedTaskCompletedQuery(selectedModuleId!, selectedTaskId!);
  const markLocationTaskCompleteMutation = OnboardingModulesQueries.useMarkLocationTaskCompletedMutation();

  useEffect(() => {
    if (isNextClickFromLastStep) {
      // resetting flag as selected task has been changed to avoid showing success step
      setIsNextClickFromLastStep(false);
    }
  }, [selectedTaskId]);

  const isShowSuccessStep = isNextClickFromLastStep && isTaskCompleted;
  const currentStepIndex = selectedStepIndex ?? 0;
  const isLastStep = currentStepIndex + 1 === steps.length;
  const isFirstStep = currentStepIndex === 0;

  useEffect(() => {
    if (!isNextClickFromLastStep) {
      return;
    }
    if (isTasksLoading) return;
    if (isTasksError) {
      alerts.error({ message: t('An error occurred while getting task status. Please try again later.') });
      return;
    }

    if (!isTaskCompleted) {
      alerts.warning({ message: t('You have not completed this task yet.') });
      setIsNextClickFromLastStep(false);
    }
  }, [isTasksError, isTasksLoading, isTaskCompleted]);

  useEffect(() => {
    if (markLocationTaskCompleteMutation.isError) {
      alerts.error({ message: t('An error occurred while updating task status. Please try again.') });
    } else if (markLocationTaskCompleteMutation.isSuccess) {
      refetchTasks();
      setIsNextClickFromLastStep(true);
    }
  }, [markLocationTaskCompleteMutation.isError, markLocationTaskCompleteMutation.isSuccess]);

  const setStepIndex = (nextStepIndex: number | null) => {
    if (isNextClickFromLastStep) {
      setIsNextClickFromLastStep(false);
    }
    setSelectedStepIndex(nextStepIndex);
  };

  const handleBackToModulesClick = () => {
    setSelectionInfo({
      moduleId: selectedModuleId,
      taskId: isShowSuccessStep ? null : selectedTaskId,
      stepIndex: null,
    });
  };

  const handleNextClick = () => {
    if (isLastStep && isOptionalTask) {
      markLocationTaskCompleteMutation.mutate(selectedTaskId!);
      return;
    }

    if (isLastStep) {
      OnboardingModulesApi.sendTaskEvent({
        locationId: selectedLocationIdsWithParents[0],
        userId: user?.userID ?? '',
        guideName: '',
        guideVersion: 1,
        eventDescription: 'Task completed in Task Tray',
        stepNumber: currentStepIndex,
        moduleName: `${selectedModuleId}`,
        urlRoute: window.location.href,
        eventType: EventType.EVENT_COMPLETE_TASK,
        taskId: selectedTaskId ?? 0,
      });
    }

    if (isLastStep) {
      refetchTasks();
      setIsNextClickFromLastStep(true);
      return;
    }

    setStepIndex(currentStepIndex + 1);
  };

  const handleBackClick = () => {
    if (isFirstStep) return;
    setStepIndex(currentStepIndex - 1);
  };

  const handleNextTaskClick = () => {
    if (!onNextTask) {
      return;
    }
    onNextTask();
    setStepIndex(0);
  };

  if (isLoading) {
    return (
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
        <SpinningLoader />
      </div>
    );
  }

  if (isError || !steps.length) {
    return <ErrorPage onPrimaryButtonClick={() => refetchSteps()} />;
  }

  if (isShowSuccessStep) {
    return (
      <>
        <StepContentContainer containerKey='success-step'>
          <StepSuccess description={successStepText} />
        </StepContentContainer>
        <footer css={footerStyle}>
          <SecondaryButton
            size='large'
            onClick={handleBackToModulesClick}
            trackingId={TRACKING_IDS.successStep.backToModulesButton}
          >
            {t('Back to Modules')}
          </SecondaryButton>
          {!!onNextTask && (
            <PrimaryButton
              size='large'
              onClick={handleNextTaskClick}
              trackingId={TRACKING_IDS.successStep.nextTaskButton}
            >
              {t('Next Task')}
            </PrimaryButton>
          )}
        </footer>
      </>
    );
  }

  const stepProgressText = t('Step {{currentStep}} of {{totalStep}}', {
    currentStep: currentStepIndex + 1,
    totalStep: steps.length,
  });

  return (
    <>
      <ContentLoader show={isTasksLoading || markLocationTaskCompleteMutation.isLoading} />
      <StepContentContainer containerKey={currentStepIndex}>
        <StepInfo stepInfo={steps[currentStepIndex]} progressText={stepProgressText} />
      </StepContentContainer>

      <footer css={footerStyle}>
        <SecondaryButton
          size='large'
          disabled={isFirstStep}
          onClick={handleBackClick}
          trackingId={TRACKING_IDS.step.backButton}
        >
          {t('Back')}
        </SecondaryButton>
        <PrimaryButton
          size='large'
          onClick={handleNextClick}
          trackingId={TRACKING_IDS.step[isLastStep ? 'doneButton' : 'nextButton']}
        >
          {isLastStep ? t('Done') : t('Next')}
        </PrimaryButton>
      </footer>
    </>
  );
};

const footerStyle = css({
  alignSelf: 'center',
  display: 'flex',
  gap: theme.spacing(2),
  padding: theme.spacing(2, 3, 3, 3),
  button: {
    maxWidth: 'fit-content',
  },
});
