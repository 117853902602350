import { createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { Nav } from '@frontend/components';
import { useMediaMatches } from '@frontend/responsiveness';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useOnClickOutside } from '@frontend/design-system';
import { primaryNavContent } from '../nav-content';
import { GlobalNavNav } from './global-nav-nav';

export const FULL_NAV_WIDTH = 267;

interface INavContext {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  hamburgerButtonRef?: React.RefObject<HTMLButtonElement>;
}

const NavContext = createContext<INavContext>({ open: false, setOpen: () => {}, hamburgerButtonRef: undefined });

export const NavContextProvider = ({ children }: { children: ReactNode }) => {
  const [open, setOpen] = useState(false);
  const hamburgerButtonRef = useRef<HTMLButtonElement>(null);
  return <NavContext.Provider value={{ open, setOpen, hamburgerButtonRef }}>{children}</NavContext.Provider>;
};

export const useNavTrigger = () => {
  const { open = false, setOpen, hamburgerButtonRef } = useContext(NavContext);

  return { open, setOpen, hamburgerButtonRef };
};

export const GlobalNav = () => {
  const { open, setOpen, hamburgerButtonRef } = useNavTrigger();
  const insideRef = useRef<HTMLDivElement>(null);
  const { matches } = useMediaMatches();
  const navigate = useNavigate();
  const settingsNavigate = useSettingsNavigate();
  const { current } = useLocation();
  const navItems = primaryNavContent();

  const closeOnClickOutside = useCallback(() => {
    setOpen(false);
  }, []);

  useOnClickOutside({
    ref: insideRef,
    handler: closeOnClickOutside,
    active: matches.largeMax(),
    captureClick: false,
    ignoreConditions: [() => document.getElementById('pendo-resource-center-container') !== null],
    ...(hamburgerButtonRef && { ignoreRefs: [hamburgerButtonRef] }),
  });

  return (
    <div className='hideforprint' style={{ height: '100%' }}>
      <Nav
        open={open}
        ref={insideRef}
        setOpen={setOpen}
        items={navItems}
        currentPath={current.pathname as (typeof navItems)[number]['root']}
        onClick={(path) => {
          if (path.startsWith('#settings')) {
            settingsNavigate.navigate({ to: path.replace('#settings', '') } as any);
          } else {
            navigate({ to: path });
          }
        }}
      />
    </div>
  );
};

GlobalNav.Nav = GlobalNavNav;
