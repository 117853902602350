export const featureFlags = {
  // Appointment
  appointmentAnalyticsDemoData: 'appointment-analytics-demo-data',

  // Auto Recall
  autoRecallDemoData: 'auto-recall-analytics-demo-data',

  // Call Intel
  enableCallIntelSearch: 'enable-call-intel-search',
  enableCallIntelEmailSettings: 'enable-call-intel-email-settings',
  enableCallIntelFollowUps: 'enable-call-intel-follow-ups',
  enableCallIntelServiceQuality: 'enable-call-intel-service-quality',
  enableCallIntelEditAi: 'enable-call-intel-edit-ai-output',
  editAiDesignVariant: 'edit-ai-output-design-variant',

  // Messaging
  messagingAnalytics: 'sms-reporting-enabled',
  messagingAnalyticsDemoData: 'messaging-analytics-demo-data',

  // Phone
  phoneAnalytics: 'phone-reporting-enabled',
  phoneAnalyticsDemoData: 'phone-analytics-demo-data',
  phoneAnalyticsExtension: 'phone-analytics-extensions',

  // Practice
  paBulkMessaging: 'practice-analytics-bulk-messaging',
  practiceAnalyticsDemo: 'analytics-demo-data',
  practiceAnalyticsFreeTrial: 'practice-analytics-free-trial-enabled',

  // ROI
  missedCallTextRoiV2: 'missed-call-text-roi-v2',
};
