import { contextFactory } from '@frontend/design-system';
import type { PanelConfig } from './PANEL-CONFIG';

interface PanelContextValue {
  id: PanelConfig;
}

export const [PanelContext, usePanelContext] = contextFactory<PanelContextValue>(
  'usePanelContext should be used within a PanelProvider'
);
