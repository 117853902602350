import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { ListDevice } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useTranslation } from '@frontend/i18n';
import { useNavSize } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Button, Heading } from '@frontend/design-system';
import { DeviceLayout } from '../device-layout';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';
import { EmptyStateLayout } from './empty-state';
import { LineKeyPagination } from './pagination';

type Props = {
  previewOnly?: boolean;
  setShowDeviceLayoutPreview?: (val: boolean) => void;
};

export const Layout = ({ setShowDeviceLayoutPreview, previewOnly }: Props) => {
  const { device, lineKeys: lineKeysObj, model } = useLineKeyContext();
  const { finalLineKeys } = useLineKeyState(['finalLineKeys']);

  const lineKeysValues = useMemo(() => {
    return Object.values(finalLineKeys ?? lineKeysObj ?? {});
  }, [lineKeysObj, finalLineKeys]);

  const paginationRef = useRef<HTMLDivElement>(null);
  const [pageNum, setPageNum] = useState(1);

  const keysPerPage = (model?.lineKeys?.keysPerPageLeft ?? 0) + (model?.lineKeys?.keysPerPageRight ?? 0);
  const totalPages = ((lineKeysValues?.length ?? 0) / keysPerPage < 1 ? 1 : model?.lineKeys?.pages) ?? 1;

  const isPaginationEnabled = !!model?.lineKeys?.pages && model?.lineKeys?.pages > 1 && totalPages > 1;

  useEffect(() => {
    if (!isPaginationEnabled) setPageNum(1);
  }, [isPaginationEnabled]);

  return (
    <>
      {!model ? (
        <EmptyStateLayout setShowDeviceLayoutPreview={setShowDeviceLayoutPreview} previewOnly={previewOnly} />
      ) : (
        <Container previewOnly={previewOnly}>
          <ContainerHeader
            setShowDeviceLayoutPreview={setShowDeviceLayoutPreview}
            previewOnly={previewOnly}
            device={device}
          />
          <DeviceLayout previewOnly={previewOnly}>
            <DeviceLayout.Header />
            <DeviceLayout.Body isPaginationEnabled={isPaginationEnabled} pageNum={pageNum} lineKeys={lineKeysValues} />
            <DeviceLayout.Footer />
          </DeviceLayout>
          <LineKeyPagination
            ref={paginationRef}
            setPageNum={setPageNum}
            totalPages={model?.lineKeys?.pages}
            isPaginationEnabled={isPaginationEnabled}
            pageNum={pageNum}
          />
        </Container>
      )}
    </>
  );
};

const Container = ({ children, previewOnly }: { previewOnly?: boolean; children: ReactNode }) => {
  return <section css={containerStyles({ previewOnly })}>{children}</section>;
};

type ContainerHeaderProps = {
  setShowDeviceLayoutPreview?: (val: boolean) => void;
  previewOnly?: boolean;
  device?: ListDevice;
};

const ContainerHeader = ({ setShowDeviceLayoutPreview, previewOnly, device }: ContainerHeaderProps) => {
  const navSize = useNavSize();
  const isMobile = navSize.isLte('medium');
  const { t } = useTranslation('phone', { keyPrefix: 'line-keys' });

  return (
    <>
      {isMobile && (
        <Button
          iconName='back'
          variant='secondary'
          size='large'
          onClick={() => setShowDeviceLayoutPreview?.(false)}
          css={css`
            border: 0;
          `}
        >
          {t('Back')}
        </Button>
      )}
      <Heading css={{ marginBottom: theme.spacing(3) }} level={2}>
        {previewOnly
          ? t('{{name}} Layout', { name: device?.name })
          : t('Preview {{name}} Layout', { name: device?.name })}
      </Heading>
    </>
  );
};

const containerStyles = ({ previewOnly }: { previewOnly?: boolean }) => css`
  position: relative;
  ${!previewOnly && `padding: ${theme.spacing(8, 3, 0)}`};
  flex: 1;
  width: 100%;
  overflow-x: auto;
`;
