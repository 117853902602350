import { ReactNode, useContext, useState } from 'react';
import { css } from '@emotion/react';
import { Handle, Position } from '@xyflow/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, Heading, styles, Text, TextLink, Tray, useModalControl } from '@frontend/design-system';
import { NodeTypes, SharedNodeProps } from '../data';
import { InteractionContext } from '../provider';
import { useCalculateLayout } from '../util';

type HandleOptions = {
  id: string;
};

type Handles = {
  top: HandleOptions;
  bottom: HandleOptions;
};

type Props = {
  handles?: Handles;
  title: string;
  label: string;
  icon: IconName;
  type: NodeTypes;
  linkId?: string;
  onClickEdit: (id: string) => void;
  onClickDelete: (id: string) => void;
  onClickLink?: SharedNodeProps['onClickLink'];
  children?: ReactNode;
};

export function BaseRoutingNode({
  handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  },
  icon,
  title,
  label,
  linkId,
  type,
  onClickDelete,
  onClickEdit,
  onClickLink,
  children,
}: Props) {
  const { modalProps } = useModalControl();
  const [isExpanded, setIsExpanded] = useState(false);
  const { mode } = useContext(InteractionContext);
  const calculate = useCalculateLayout();
  return (
    <div
      style={{
        padding: theme.spacing(2),
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
        background: theme.colors.white,
        width: 'min-content',
      }}
      className='nodrag nopan'
    >
      {handles.top && (
        <Handle
          type='target'
          position={Position.Top}
          id={handles.top.id}
          isConnectable={true}
          style={{ visibility: 'hidden' }}
        />
      )}
      <div
        css={{
          display: 'grid',
          gridTemplateAreas: '"icon title actions" "icon subtitle actions"',
          gridTemplateColumns: 'auto 1fr auto',
          columnGap: theme.spacing(1),
          alignItems: 'center',
          height: 40,
          width: 320,
          '> *': { minWidth: 0 },
        }}
      >
        <Icon name={icon} css={{ gridArea: 'icon', color: theme.colors.neutral70 }} />
        <Text as='span' size='medium' weight='bold' css={[{ gridArea: 'title' }, styles.truncate]}>
          {title}
        </Text>
        {linkId ? (
          <TextLink
            trackingId={`call-route-canvas-${type}-call-object-link`}
            onClick={() => {
              onClickLink?.({ callObjectId: linkId, type });
            }}
            size='medium'
            css={[{ gridArea: 'subtitle', textAlign: 'start' }, styles.truncate]}
          >
            {label}
          </TextLink>
        ) : (
          <Text as='span' size='medium' css={[{ gridArea: 'subtitle' }, styles.truncate]}>
            {label}
          </Text>
        )}
        <div style={{ gridArea: 'actions' }}>
          {mode === 'edit' && (
            <>
              <Button
                size='small'
                variant='secondary'
                iconName='edit-small'
                css={{
                  '.button-icon': {
                    color: theme.colors.neutral70,
                  },
                }}
                onClick={() => {
                  if (linkId) {
                    onClickEdit(linkId);
                  }
                }}
              />
              <Button
                size='small'
                variant='secondary'
                iconName='trash-small'
                css={{
                  '.button-icon': {
                    color: theme.colors.neutral70,
                  },
                }}
                onClick={() => {
                  if (linkId) {
                    onClickDelete(linkId);
                  }
                }}
              />
            </>
          )}
          {children && (
            <Button
              size='small'
              variant='secondary'
              iconName='caret-down-small'
              trackingId={`call-route-canvas-${type}-expand`}
              css={{
                width: 'fit-content',
                '.button-icon': {
                  transform: `rotate(${isExpanded ? 180 : 0}deg)`,
                  color: theme.colors.neutral70,
                },
              }}
              onClick={() => {
                setIsExpanded((prev) => !prev);
                setTimeout(() => {
                  calculate({ direction: 'TB' });
                }, 10);
              }}
            />
          )}
        </div>
      </div>
      {isExpanded && (
        <div
          css={css`
            border-top: 1px solid ${theme.colors.neutral20};
            margin-top: ${theme.spacing(2)};
            padding-top: ${theme.spacing(2)};
          `}
        >
          {children}
        </div>
      )}

      {handles.bottom && (
        <Handle
          type='source'
          position={Position.Bottom}
          id={handles.bottom.id}
          isConnectable={true}
          style={{ visibility: 'hidden' }}
        />
      )}

      {linkId && (
        <Tray width='medium' {...modalProps}>
          <Heading level={2}>{title}</Heading>
        </Tray>
      )}
    </div>
  );
}
