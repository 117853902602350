import { useMemo, useState, useRef } from 'react';
import { css } from '@emotion/react';
import { type QuickActionsResponse } from '@weave/schema-gen-ts/dist/schemas/quick-actions/qa_messages.pb';
import { motion } from 'motion/react';
import { useMutation, useQueryClient } from 'react-query';
import { DashboardTrackingIds } from '@frontend/dashboard';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { SchemaQuickActionAPIService } from '@frontend/schema';
import { theme } from '@frontend/theme';
import { Heading, Tray, IconButton, type ModalControlModalProps } from '@frontend/design-system';
import { QuickActions } from './actions';
import { QuickActionsGroup, QuickActionItem, QuickActionReorderItem } from './atoms';
import { regulatedEnabledData } from './helpers';

type QuickActionsTrayProps = {
  modalProps: ModalControlModalProps;
  data: QuickActionsResponse | undefined;
  closeModal: () => void;
  actions: QuickActions;
};

const quickActionsTrackingIds = DashboardTrackingIds.featureModule('quick-actions');

export const QuickActionsTray = ({ modalProps, data, closeModal, actions }: QuickActionsTrayProps) => {
  const { t } = useTranslation('quick-actions');

  return (
    <Tray
      {...modalProps}
      autoFocusTimeout={3600}
      width='medium'
      mountTarget='#app-container'
      css={css`
        padding: ${theme.spacing(3)};
        border-top: 1px solid ${theme.colors.neutral20};
      `}
    >
      <Tray.Header
        Buttons={
          <>
            <IconButton label={t('Close')} onClick={() => closeModal()}>
              <Icon name='x' />
            </IconButton>
          </>
        }
      >
        <Heading
          level={2}
          css={css`
            margin-bottom: 0;
            display: flex;
            gap: ${theme.spacing(2)};
            align-items: center;
          `}
        >
          {t('Quick Actions')}
        </Heading>
      </Tray.Header>
      <QuickActionsTrayBody actions={actions} data={data} modalProps={modalProps} closeModal={closeModal} />
    </Tray>
  );
};

const QuickActionsTrayBody = ({ data, closeModal, actions }: QuickActionsTrayProps) => {
  const { t } = useTranslation('quick-actions');

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (variable: string[]) => SchemaQuickActionAPIService.UpdateQuickActionOfUser({ enabled: variable }),
    onSuccess: () => {
      queryClient.invalidateQueries('quickActions');
    },
  });
  const constraintRef = useRef<HTMLUListElement | null>(null);

  const enabledData = useMemo(() => regulatedEnabledData(data)?.filter((action) => !!actions[action]), [data, actions]);
  const [allEnabled, setAllEnabled] = useState(enabledData ?? []);

  return (
    <>
      <Tray.Body
        as={motion.section}
        layoutScroll
        css={css`
          margin: ${theme.spacing(0, -3, 0, -3)};
          background-color: ${theme.colors.neutral5};
          border-bottom: 1px solid ${theme.colors.neutral20};
        `}
      >
        <QuickActionsGroup
          title='Added to Home Screen'
          subtitle={!!allEnabled?.length ? undefined : t('You can add Quick Actions from the list below.')}
          type='reorder'
          values={allEnabled}
          onReorder={setAllEnabled}
          constraintRef={constraintRef}
        >
          {allEnabled?.map((action) => {
            if (!actions[action]) return null;

            return (
              <QuickActionReorderItem
                key={`remove-${action}`}
                onClick={() =>
                  setAllEnabled(
                    allEnabled?.length === 1 ? [] : allEnabled.filter((enabledLabel) => enabledLabel !== action)
                  )
                }
                label={action}
                type='remove'
                constraintRef={constraintRef}
              />
            );
          })}
        </QuickActionsGroup>
        <QuickActionsGroup title={t('All Available Actions')}>
          {data?.all?.map((action) => {
            if (!actions[action.label ?? '']) return null;

            return !allEnabled?.includes(action?.label ?? '') ? (
              <QuickActionItem
                key={`add-${action.label}`}
                onClick={() => setAllEnabled([...allEnabled, action.label ?? ''])}
                label={action.label ?? ''}
              />
            ) : null;
          })}
        </QuickActionsGroup>
      </Tray.Body>
      <Tray.Actions
        onSecondaryClick={() => closeModal()}
        secondaryLabel={t('Cancel')}
        primaryLabel={t('Save')}
        primaryTrackingId={quickActionsTrackingIds('save-quick-actions')}
        secondaryTrackingId={quickActionsTrackingIds('cancel-quick-actions')}
        onPrimaryClick={() => {
          mutate(allEnabled);
          closeModal();
        }}
      />
    </>
  );
};
