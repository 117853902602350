import { createProvider } from '@frontend/store';
import { FormsLibraryV2Store } from './types';

export const { Provider: FormsLibraryV2StoreProvider, useStore: useFormsLibraryV2Store } =
  createProvider<FormsLibraryV2Store>()((set) => ({
    noFormsFound: false,
    setNoFormsFound: (value: boolean) => set({ noFormsFound: value }),

    isLoadingForms: false,
    setIsLoadingForms: (value: boolean) => set({ isLoadingForms: value }),

    noPacketsFound: false,
    setNoPacketsFound: (value: boolean) => set({ noPacketsFound: value }),

    isLoadingPackets: false,
    setIsLoadingPackets: (value: boolean) => set({ isLoadingPackets: value }),

    searchValue: '',
    setSearchValue: (value: string) => set({ searchValue: value }),

    isLocationMetadataReady: false,
    setIsLocationMetadataReady: (value: boolean) => set({ isLocationMetadataReady: value }),

    formsEnabledLocationIds: [],
    setFormsEnabledLocationIds: (value: string[]) => set({ formsEnabledLocationIds: value }),

    paymentsEnabledLocationIdsMap: {},
    setPaymentsEnabledLocationIdsMap: (value: Record<string, boolean>) => set({ paymentsEnabledLocationIdsMap: value }),

    sourceTenantIdOfEaglesoftPMS: '',
    setSourceTenantIdOfEaglesoftPMS: (value: string) => set({ sourceTenantIdOfEaglesoftPMS: value }),

    sourceIdOfEaglesoftPMS: '',
    setSourceIdOfEaglesoftPMS: (value: string) => set({ sourceIdOfEaglesoftPMS: value }),
  }));
