import { ReactNode } from 'react';
import { AnimatePresence, Variants, motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { MOBILE_POPUP_SELECTOR_CLOSE_BUTTON_HEIGHT } from '../../constants';
import { usePopupBarManagerContext } from '../provider';

const VARIANTS = {
  open: {
    bottom: MOBILE_POPUP_SELECTOR_CLOSE_BUTTON_HEIGHT,
    backdropFilter: 'blur(2px)',
    gap: theme.spacing(2),
    opacity: 1,
  },
  closed: {
    bottom: 0,
    backdropFilter: 'none',
    gap: 0,
    opacity: 0,
  },
} satisfies Variants;

export const MobilePopoutSelectionOverlay = ({ children }: { children: ReactNode }) => {
  const { mobileSelectorIsOpen } = usePopupBarManagerContext();

  return (
    <motion.div
      variants={VARIANTS}
      initial='closed'
      animate={mobileSelectorIsOpen ? 'open' : 'closed'}
      exit='closed'
      css={{
        position: 'fixed',
        display: 'flex',
        flexDirection: 'column',
        padding: theme.spacing(2),
        left: 0,
        right: 0,
        zIndex: theme.zIndex.overlay,
      }}
    >
      <AnimatePresence>{children}</AnimatePresence>
    </motion.div>
  );
};
