import { useEffect, useState } from 'react';
import { BulkMessagingQueries, BulkMessagingTypes } from '@frontend/api-bulk-messaging';
import { useDebouncedValue } from '@frontend/design-system';

const removeEmptyValues = (obj: Record<string, any>): Record<string, any> => {
  return Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) =>
        value !== null && value !== undefined && value !== '' && !(Array.isArray(value) && value.length === 0)
    )
  );
};

export const useCountSegmentRecipients = (campaignId: string, filters: BulkMessagingTypes.AudienceFilters) => {
  const cleanedFilters = removeEmptyValues(filters);
  const debouncedFilters = useDebouncedValue(cleanedFilters, 2000);
  const [recipientCount, setRecipientCount] = useState<number | undefined>();

  useEffect(() => {
    let isMounted = true;
    setRecipientCount(undefined);

    BulkMessagingQueries.countSegmentRecipients({
      campaignId,
      segment: {
        segmentId: '', // TODO: See if the backend can make this optional
        filterOptions: debouncedFilters,
      },
    }).then((response) => {
      if (isMounted) {
        setRecipientCount(response.recipientCount ?? 0);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [campaignId, JSON.stringify(debouncedFilters)]);

  return recipientCount;
};
