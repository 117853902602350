import { debounce } from 'lodash-es';
import { getUser, localStorageHelper } from '@frontend/auth-helpers';
import { StickyColor } from './colors';

export interface ArchivedNote {
  id: string;
  text: string;
  archivedAt: string;
}

export interface StickyNoteStorage {
  currentNote: string;
  archivedNotes: ArchivedNote[];
}

const STICKY_NOTE_KEY = 'sticky-note';
const DEBOUNCE_DELAY = 500; // 500ms debounce delay

export const saveToStorageImmediate = (data: StickyNoteStorage, color: StickyColor): void => {
  const user = getUser();
  localStorageHelper.create(`${user?.userID}${STICKY_NOTE_KEY}-${color}`, data);
};

export const saveStickyNoteToStorage = debounce(saveToStorageImmediate, DEBOUNCE_DELAY);

export const getStickyNoteFromStorage = (color: StickyColor): StickyNoteStorage => {
  const user = getUser();
  return (
    (localStorageHelper.get(`${user?.userID}${STICKY_NOTE_KEY}-${color}`) as StickyNoteStorage) ?? {
      currentNote: '',
      archivedNotes: [],
    }
  );
};
