import { forwardRef, useCallback } from 'react';
import { css } from '@emotion/react';
import { motion, type PanInfo, type DragControls } from 'motion/react';
import { theme } from '@frontend/theme';
import { StickyColor, stickyColors } from '../colors';
import { customFontStyle } from '../common-styles';
import { useStickyNoteContext, useStickyNoteStore } from '../providers';

export const DraggableStickyNote = forwardRef<
  HTMLDivElement,
  {
    dragControls: DragControls;
    handleDragEnd: () => void;
  }
>(({ dragControls, handleDragEnd }, ref) => {
  const { draggedText, setIsHoveredOverArchive } = useStickyNoteStore(['draggedText', 'setIsHoveredOverArchive']);
  const { color } = useStickyNoteContext();

  const handleDrag = useCallback((event: PointerEvent, info: PanInfo) => {
    event.preventDefault();
    const archiveIcon = document.getElementById('archive-icon-container');
    if (!archiveIcon) return;

    const rect = archiveIcon.getBoundingClientRect();
    const padding = 8;
    const isOver =
      info.point.x >= rect.left - padding &&
      info.point.x <= rect.right + padding &&
      info.point.y >= rect.top - padding &&
      info.point.y <= rect.bottom + padding;

    setIsHoveredOverArchive(isOver);
  }, []);

  return (
    <motion.div
      key='dragging-note'
      ref={ref}
      dragControls={dragControls}
      dragListener={false}
      dragMomentum={false}
      drag
      onDrag={handleDrag}
      onDragEnd={handleDragEnd}
      css={draggableStickyNoteStyle(color)}
    >
      <p css={draggableStickyNoteParagraphStyle}>{draggedText}</p>
    </motion.div>
  );
});

const draggableStickyNoteStyle = (color: StickyColor) => css`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${stickyColors[color]};
  box-shadow: ${theme.shadows.light};
  padding: ${theme.spacing(1.5)};
  pointer-events: none;
  opacity: 0;
  display: flex;
  overflow: hidden;
  z-index: ${theme.zIndex.high};
`;

const draggableStickyNoteParagraphStyle = [
  css`
    width: 100%;
    height: 100%;
    overflow: auto;
    white-space: pre-wrap;
  `,
  customFontStyle,
];
