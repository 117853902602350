import dayjs, { QUnitType } from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(quarterOfYear);
dayjs.extend(timezone);

/**
 * Find the date range based on the given offset and range type
 * @param offset the offset from the current date, default is 0, value should always be positive
 * @param oneDayLimit if set true, only one day dates will be returned based on the given offset, applicable only for 'day' range
 * @param range the range type, possible values are 'day', 'week', 'month'
 * @param type the type of the range, possible values are 'past', 'future'
 */
type Range = 'day' | 'week' | 'month' | 'quarter';

type FindDateRange = {
  offset?: number;
  oneDayLimit?: boolean;
  range?: Range;
  type?: 'past' | 'future';
};

export type DateRange = {
  startDate: string;
  endDate: string;
};

const getDatesBlock = (offset: number, range: Range, type: 'past' | 'future') => {
  let startDate, endDate;
  const today = dayjs();

  if (!offset) {
    startDate = dayjs().startOf(range).format('YYYY-MM-DDTHH:mm:ss');
    const lastDay = dayjs().endOf(range);

    // If lastDay is larger then today, then set endDate to today
    endDate = lastDay.isAfter(today) ? today.format('YYYY-MM-DDTHH:mm:ss') : lastDay.format('YYYY-MM-DDTHH:mm:ss');
  } else {
    const date =
      type === 'past' ? dayjs().subtract(offset, range as QUnitType) : dayjs().add(offset, range as QUnitType);
    startDate = date.startOf(range).format('YYYY-MM-DDTHH:mm:ss');

    const lastDay = date.endOf(range);

    // If lastDay is larger then today, then set endDate to today
    endDate = lastDay.isAfter(today) ? today.format('YYYY-MM-DDTHH:mm:ss') : lastDay.format('YYYY-MM-DDTHH:mm:ss');
  }

  return { startDate, endDate };
};

export const findDatesRange = ({ offset = 0, oneDayLimit, range = 'day', type = 'past' }: FindDateRange): DateRange => {
  switch (range) {
    case 'day': {
      let startDate, endDate;
      if (!offset) {
        startDate = dayjs().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        endDate = dayjs().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      } else {
        if (type === 'past') {
          startDate = dayjs().subtract(offset, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss');
          endDate = dayjs()
            .subtract(oneDayLimit ? offset : 1, 'day')
            .endOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
        } else {
          startDate = dayjs()
            .add(oneDayLimit ? offset : 1, 'day')
            .startOf('day')
            .format('YYYY-MM-DDTHH:mm:ss');
          endDate = dayjs().add(offset, 'day').endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        }
      }
      return { startDate, endDate };
    }

    case 'week':
      return getDatesBlock(offset, 'week', type);

    case 'month':
      return getDatesBlock(offset, 'month', type);

    case 'quarter':
      return getDatesBlock(offset, 'quarter', type);

    default:
      throw new Error('Unsupported range type');
  }
};

export const defaultDateRangeMap = {
  today: findDatesRange({ range: 'day' }),

  // PAST DAYS
  yesterday: findDatesRange({ offset: 1, range: 'day' }),
  'last-7-days': findDatesRange({ offset: 7, range: 'day' }),
  'last-14-days': findDatesRange({ offset: 14, range: 'day' }),
  'last-30-days': findDatesRange({ offset: 30, range: 'day' }),

  // FUTURE DAYS
  tomorrow: findDatesRange({ offset: 1, range: 'day', type: 'future' }),
  'next-7-days': findDatesRange({ offset: 7, range: 'day', type: 'future' }),
  'next-14-days': findDatesRange({ offset: 14, range: 'day', type: 'future' }),
  'next-30-days': findDatesRange({ offset: 30, range: 'day', type: 'future' }),

  // WEEKS
  'this-week': findDatesRange({ range: 'week' }),
  'last-week': findDatesRange({ offset: 1, range: 'week' }),

  // MONTHS
  'this-month': findDatesRange({ range: 'month' }),
  'last-month': findDatesRange({ offset: 1, range: 'month' }),

  // QUARTERS
  'this-quarter': findDatesRange({ range: 'quarter' }),
  'last-quarter': findDatesRange({ offset: 1, range: 'quarter' }),
};

type GetMatchingDateRangeKey = {
  datesMap?: Record<string, DateRange>;
  endDate?: string;
  startDate?: string;
};

export const getMatchingDateRangeKey = ({
  datesMap = defaultDateRangeMap,
  endDate,
  startDate,
}: GetMatchingDateRangeKey) => {
  if (startDate && endDate) {
    const period = Object.entries(datesMap).find(([_period, { startDate: date1, endDate: date2 }]) => {
      const isStartDateEqual = dayjs(date1).isSame(startDate, 'day');
      const isEndDateEqual = dayjs(date2).isSame(endDate, 'day');
      return isStartDateEqual && isEndDateEqual;
    });

    return period ? period[0] : undefined;
  }
  return undefined;
};

// This will append local time stamp to date-time before they are intercepted
// Required for the valid time conversion based on the time of the current location
export const appendLocalTimeStamp = (date: string) => {
  const localDateTime = new Date();
  return `${dayjs(date).format(
    'YYYY-MM-DDT'
  )}${localDateTime.getHours()}:${localDateTime.getMinutes()}:${localDateTime.getSeconds()}`;
};

// Returns the formatted date according to current time zone
export const formatDateByTimezone = (date: string, originalTimeStampDate?: string, timeZone?: string) => {
  // If originalTimeStampDate or timeZone are not available then local date conversion cannot be done
  if (!originalTimeStampDate || !timeZone) {
    return dayjs(date).format('MMM DD, YYYY');
  }
  // Add original timezone's timestamp to the response date
  if (date.length === 10) {
    // If the date is in the format of 'YYYY-MM-DD' or 'YYYY/MM/DD', then add the timestamp to it
    // And the format should be like "2024-10-08T00:00:00Z"
    date = `${date}T00:00:00Z`;
  }

  const dateWithTimestamp = date.replace(
    date.substring(11, 19),
    dayjs(originalTimeStampDate).tz(timeZone).format('HH:mm:ss')
  );
  // Parse date to the location's timezone
  const dateInLocationTimeZone = dayjs.tz(dateWithTimestamp, timeZone);
  // Convert date to local timezone
  const localDateTime = dateInLocationTimeZone.tz(dayjs.tz.guess());
  // Return the formatted date string
  return localDateTime.format('MMM DD, YYYY');
};

/**
 * @param date time zone will be appended to this date
 * @returns a string date with a time zone appended to it at the end
 */
export const appendCurrentTimeZone = (date: string) => {
  const utcOffset = dayjs().utcOffset();

  // Calculate the hours and minutes separately
  const hours = Math.floor(Math.abs(utcOffset) / 60);
  const minutes = Math.abs(utcOffset) % 60;

  // Determine the sign for the timezone offset
  const sign = utcOffset >= 0 ? '+' : '-';

  // Format the hours and minutes with leading zeros
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${date}${sign}${formattedHours}:${formattedMinutes}`;
};

export const formatStartDateWithTimeZone = (date: string | undefined) => {
  return date ? dayjs(date).startOf('day').tz(dayjs.tz.guess(), true).format('YYYY-MM-DD[T00:00:00]Z') : '';
};

export const formatEndDateWithTimeZone = (date: string | undefined) => {
  return date ? dayjs(date).endOf('day').tz(dayjs.tz.guess(), true).format('YYYY-MM-DD[T23:59:59]Z') : '';
};

export const calculateDateDifference = (
  startDate: string,
  endDate: string,
  unit: QUnitType = 'day',
  round = false
): number => {
  const difference = dayjs(endDate).diff(dayjs(startDate), unit, !round);
  return round ? Math.floor(difference) : difference;
};
