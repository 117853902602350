import { ReactNode } from 'react';
import { Body, Container, Head, Html, Preview } from '@react-email/components';
import { useContentComposer } from './content-composer-provider';
import { ImageOutputStyles, TextOutputStyles } from './outputs';

export const DOCTYPE =
  '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">';

type Props = {
  preview?: string;
  header?: ReactNode;
  children?: ReactNode;
  renderMode?: string;
};

export const HtmlTemplate = ({ preview, header, renderMode, children }: Props) => {
  const { composerMode } = useContentComposer();

  if (composerMode === 'feedback' && renderMode !== 'preview') {
    return (
      <div style={headlessBodyStyle}>
        <ImageOutputStyles />
        <TextOutputStyles />
        {!!children && <Container style={headlessContainerStyle}>{children}</Container>}
      </div>
    );
  }

  return (
    <Html style={{ overflowX: 'hidden' }}>
      <Head> </Head>
      {!!preview && <Preview>{preview}</Preview>}
      <Body style={bodyStyle}>
        {!!header && header}
        <ImageOutputStyles />
        <TextOutputStyles />
        {!!children && <Container style={container}>{children}</Container>}
      </Body>
    </Html>
  );
};

const bodyStyle = {
  fontFamily: '"DM Sans", sans-serif',
  backgroundColor: '#ffffff', // TODO: Should this be black instead?
  margin: '0',
  padding: '0',
};

const headlessBodyStyle = {
  maxWidth: '600px',
  borderRadius: '8px',
  backgroundColor: '#fff',
  boxShadow: '0 4px 10px 0 rgb(42, 38, 56, 14%)',
  padding: '4px',
  margin: '24px auto',
  fontFamily: '"DM Sans", sans-serif',
};

const headlessContainerStyle = {
  margin: '0 auto',
  maxWidth: '100%',
  position: 'relative' as const,
};

const container = {
  margin: '0 auto',
  width: 'min(648px, 100%)',
  maxWidth: '100%',
  position: 'relative' as const,
};
