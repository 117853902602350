import { useEffect, useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { formatCallVolumeAndDurationCharts } from '../utils/format-total-call-volume-and-duration-charts';
import { ChartProps } from './shared-types';

const colors = {
  inbound: theme.colors.secondary.seaweed30,
  outbound: theme.colors.warning50,
  total: theme.colors.primary20,
};

const labels = {
  inbound: i18next.t('Incoming', { ns: 'analytics' }),
  outbound: i18next.t('Outgoing', { ns: 'analytics' }),
  total: i18next.t('Total', { ns: 'analytics' }),
};

export const TotalCallVolumeChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.CallsAggregationKey>('call_direction');

  const processedData = useMemo(
    () =>
      formatCallVolumeAndDurationCharts({
        data,
        dataKey: 'calls',
        isHourlyData,
        isMultiLocation,
        locations: locationNames,
        startDate: filters.start_date,
        timeZone: filters.time_zone,
      }),
    [data, filters, isHourlyData, isMultiLocation, locationNames]
  );

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Total number of incoming and outgoing calls')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Total Call Volume')}
      />
      <Chart.BarChart appearance={defaultChartAppearance} data={processedData.chartData} />
      <Chart.Legends formatValue={formatters.value.format} values={processedData.totals} />
    </Chart>
  );
};
