import { CallGroupsApi, CallGroupsTypes } from '@frontend/api-call-groups';
import { CallQueueApi, CallQueueTypes } from '@frontend/api-call-queue-legacy';
import { DepartmentsApi, DepartmentsTypes } from '@frontend/api-departments';
import { DevicesApi, DevicesTypes } from '@frontend/api-devices';
import { PhoneTreeApi, PhoneTreeTypes, InstructionsTypes } from '@frontend/api-phone-tree';
import { VoiceMailboxApi, VoiceMailboxTypes } from '@frontend/api-voicemail-boxes';
import { i18next } from '@frontend/i18n';
import { queryKeys } from '../../../../query-keys';
import { alphabeticalSort } from '../../../../utils/phone-utils';
import { DropdownCascadingNode } from '../../../phone-tree/phone-tree-switch';

export type FetchDeptInstructions =
  | InstructionsTypes.Instruction.CallGroup
  | InstructionsTypes.Instruction.CallQueue
  | InstructionsTypes.Instruction.DataEndpoint
  | InstructionsTypes.Instruction.DepartmentId
  | InstructionsTypes.Instruction.ForwardDevice
  | InstructionsTypes.Instruction.ForwardNumber
  | InstructionsTypes.Instruction.Hangup
  | InstructionsTypes.Instruction.InstructionSet
  | InstructionsTypes.Instruction.IVRMenu
  | InstructionsTypes.Instruction.Play
  | InstructionsTypes.Instruction.VoicemailBox
  | InstructionsTypes.Instruction.VoicemailPrompt
  | PhoneTreeTypes.MenuTypes.SubTree
  | PhoneTreeTypes.MenuTypes.Navigate;

/**
 * If an option here is marked as disabled, it will not show up in the list for users to select in the UI.
 * However, it is still possible to have it as an option, for cases where it was set in the backend.
 */
export const nodeMaps: {
  [T in FetchDeptInstructions]: any;
} = {
  [InstructionsTypes.Instruction.CallGroup]: {
    id: InstructionsTypes.Instruction.CallGroup,
    value: InstructionsTypes.Instruction.CallGroup,
    label: i18next.t('departments>>Call Group', { ns: 'phone' }),
    addLink: '../../call-groups',
    queryParams: {
      queryKey: queryKeys.callGroups(),
      queryFn: () => CallGroupsApi.listCallGroups(),
      select: (data: CallGroupsTypes.CallGroup[]) => {
        return data
          .map((d) => ({ id: d.ID, value: d.ID, label: d.name }))
          .sort((a, b) => alphabeticalSort(a.label, b.label));
      },
    },
  },
  [InstructionsTypes.Instruction.CallQueue]: {
    id: InstructionsTypes.Instruction.CallQueue,
    value: InstructionsTypes.Instruction.CallQueue,
    label: i18next.t('departments>>Call Queue', { ns: 'phone' }),
    addLink: '../../call-queues',
    queryParams: {
      queryKey: queryKeys.callQueues(),
      queryFn: CallQueueApi.list,
      select: (data: CallQueueTypes.CallQueueListItem[]) => {
        return data
          .map((d) => ({ id: d.ID, value: d.ID, label: d.Name }))
          .sort((a, b) => alphabeticalSort(a.label, b.label));
      },
    },
  },
  [InstructionsTypes.Instruction.DataEndpoint]: {
    id: InstructionsTypes.Instruction.DataEndpoint,
    value: InstructionsTypes.Instruction.DataEndpoint,
    label: i18next.t('phone-tree>>Data Endpoint', { ns: 'phone' }),
    nodes: [],
    disabled: true,
  },
  [InstructionsTypes.Instruction.DepartmentId]: {
    id: InstructionsTypes.Instruction.DepartmentId,
    value: InstructionsTypes.Instruction.DepartmentId,
    label: i18next.t('phone-tree>>Department', { ns: 'phone' }),
    addLink: '../../departments',
    queryParams: {
      queryKey: ['departments'],
      queryFn: DepartmentsApi.listDept,
      select: (data: DepartmentsTypes.ListDepartmentTypes['output']) => {
        const depts: DepartmentsTypes.DepartmentModel[] = data.departments || [];
        return depts
          .map((d) => ({ id: d.id, value: d.id, label: d.name }))
          .sort((a, b) => (!a.label || !b.label ? 0 : alphabeticalSort(a.label, b.label)));
      },
    },
  },
  [InstructionsTypes.Instruction.ForwardDevice]: {
    id: InstructionsTypes.Instruction.ForwardDevice,
    value: InstructionsTypes.Instruction.ForwardDevice,
    label: i18next.t('departments>>Device', { ns: 'phone' }),
    displayLabel: i18next.t('departments>>Device', { ns: 'phone' }),
    addLink: '../../devices',
    queryParams: {
      queryKey: queryKeys.devices(),
      queryFn: DevicesApi.listDevices,
      select: (data: DevicesTypes.DeviceWithAddressModel[]) => {
        return data
          .map((device) => device.sipDevice)
          .filter((device) => device.type === 'desk_phone' || device.type === 'softphone')
          .map((d) => ({ id: d.ID, value: d.ID, label: d.name }))
          .sort((a, b) => alphabeticalSort(a.label, b.label));
      },
    },
  },
  [InstructionsTypes.Instruction.ForwardNumber]: {
    id: InstructionsTypes.Instruction.ForwardNumber,
    value: InstructionsTypes.Instruction.ForwardNumber,
    label: i18next.t('departments>>Forwarding Number', { ns: 'phone' }),
    queryParams: {
      queryKeys: ['null-forwarding'],
    },
  },
  [InstructionsTypes.Instruction.Hangup]: {
    id: InstructionsTypes.Instruction.Hangup,
    value: InstructionsTypes.Instruction.Hangup,
    label: i18next.t('phone-tree>>Hangup', { ns: 'phone' }),
    disabled: true,
    queryParams: {
      queryKeys: ['null-hangup'],
    },
  },
  [InstructionsTypes.Instruction.InstructionSet]: {
    id: InstructionsTypes.Instruction.InstructionSet,
    value: InstructionsTypes.Instruction.InstructionSet,
    label: i18next.t('phone-tree>>Instruction Set', { ns: 'phone' }),
    nodes: [],
    disabled: true,
    queryParams: {
      queryKeys: ['null-instruction'],
    },
  },
  [InstructionsTypes.Instruction.IVRMenu]: {
    id: InstructionsTypes.Instruction.IVRMenu,
    value: InstructionsTypes.Instruction.IVRMenu,
    label: i18next.t('departments>>Phone Tree', { ns: 'phone' }),
    addLink: '../../phone-tree',
    queryParams: {
      queryKey: queryKeys.phoneTrees(),
      queryFn: () => PhoneTreeApi.list({}),
      select: (data: PhoneTreeTypes.ListPhoneTrees['output']) => {
        const phoneTreeData = data?.data;
        if (!phoneTreeData) {
          return [];
        }
        return phoneTreeData
          .map((d) => ({ id: d.ivrMenuId, value: d.ivrMenuId, label: d.name }))
          .sort((a, b) => (!a.label || !b.label ? 0 : alphabeticalSort(a.label, b.label)));
      },
    },
  },
  [InstructionsTypes.Instruction.Play]: {
    id: InstructionsTypes.Instruction.Play,
    value: InstructionsTypes.Instruction.Play,
    label: i18next.t('departments>>Play Message', { ns: 'phone' }),
    queryParams: {
      queryKeys: ['null-play-message'],
    },
  },
  [InstructionsTypes.Instruction.VoicemailBox]: {
    id: InstructionsTypes.Instruction.VoicemailBox,
    value: InstructionsTypes.Instruction.VoicemailBox,
    label: i18next.t('departments>>Voicemail Box', { ns: 'phone' }),
    queryParams: {
      queryKey: queryKeys.voiceMailboxes(),
      queryFn: VoiceMailboxApi.list,
      select: (data: VoiceMailboxTypes.Mailbox[]) => {
        return data
          .map((d) => ({ id: d.mailboxID, value: d.mailboxID, label: d.name }))
          .sort((a, b) => alphabeticalSort(a.label, b.label));
      },
    },
    disabled: true,
  },
  [InstructionsTypes.Instruction.VoicemailPrompt]: {
    id: InstructionsTypes.Instruction.VoicemailPrompt,
    value: InstructionsTypes.Instruction.VoicemailPrompt,
    label: i18next.t('departments>>Voicemail Message', { ns: 'phone' }),
    queryParams: {
      queryKey: queryKeys.voiceMailboxes(),
      queryFn: VoiceMailboxApi.list,
      select: (data: VoiceMailboxTypes.Mailbox[]) => {
        return data
          .map((d) => ({ id: d.mailboxID, value: d.mailboxID, label: d.name }))
          .sort((a, b) => alphabeticalSort(a.label, b.label));
      },
    },
  },
  [PhoneTreeTypes.MenuTypes.Navigate]: {
    id: PhoneTreeTypes.MenuTypes.Navigate,
    value: PhoneTreeTypes.MenuTypes.Navigate,
    label: i18next.t('phone-tree>>Repeat Phone Tree Greeting', { ns: 'phone' }),
    trackingId: 'phone-portal-phoneTree-repeatGreeting-option',
    queryParams: {
      queryKey: queryKeys.phoneTrees(),
      queryFn: () => PhoneTreeApi.list({}),
      select: (data: PhoneTreeTypes.ListPhoneTrees['output']) => {
        const phoneTreeData = data?.data;
        if (!phoneTreeData) {
          return [];
        }
        return phoneTreeData
          .map((d) => ({ id: d.ivrMenuId, value: d.ivrMenuId, label: d.name }))
          .sort((a, b) => (!a.label || !b.label ? 0 : alphabeticalSort(a.label, b.label)));
      },
    },
  },
  [PhoneTreeTypes.MenuTypes.SubTree]: {
    id: PhoneTreeTypes.MenuTypes.SubTree,
    value: PhoneTreeTypes.MenuTypes.SubTree,
    label: i18next.t('phone-tree>>To Another Phone Tree', { ns: 'phone' }),
    displayLabel: i18next.t('phone-tree>>Subtree', { ns: 'phone' }),
    queryParams: {
      queryKey: queryKeys.phoneTrees(),
      queryFn: () => PhoneTreeApi.list({}),
      select: (data: PhoneTreeTypes.ListPhoneTrees['output']) => {
        const phoneTreeData = data?.data;
        if (!phoneTreeData) {
          return [];
        }
        return phoneTreeData
          .map((d) => ({ id: d.ivrMenuId, value: d.ivrMenuId, label: d.name }))
          .sort((a, b) => (!a.label || !b.label ? 0 : alphabeticalSort(a.label, b.label)));
      },
    },
    disabled: true,
  },
};

type NodesInstructions = {
  nodelist: FetchDeptInstructions[];
  disabledInstructions?: FetchDeptInstructions[];
};

/**
 * Whether the option is VISIBLE for selection is dictated by the `disabled` prop as defined in the nodeMap
 */
export const generateFallbackNodes = (nodesInstruction: NodesInstructions, label: string): DropdownCascadingNode => {
  return {
    id: 'instruction',
    label: label,
    value: 'instruction',
    nodes: nodesInstruction.nodelist?.map((node: FetchDeptInstructions) => {
      const nodeData = nodeMaps[node];
      // generating fallback nodes for departments and phone tree page
      // some instructions which are allowed in departments, are not allowed in phone tree for selection
      const isSelectionAllowed = nodesInstruction?.disabledInstructions?.includes(node) || nodeData.disabled;
      return { ...nodeData, disabled: isSelectionAllowed };
    }),
  };
};
