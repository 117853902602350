import { memo, useCallback } from 'react';
import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import { GlobalBannerControls } from './global-banner-controls';

export const styles = {
  container: css`
    display: flex;
    overflow: hidden;
    margin: 0;
    margin-top: auto;
    justify-content: space-between;
    width: 100%;
  `,

  content: css`
    padding-top: ${theme.spacing(1)};
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  `,

  indicatorsWrapper: css`
    display: flex;
    margin: initial;
    margin-left: ${theme.spacing(6.25)};
    gap: ${theme.spacing(1.5)};
    @container (max-width: ${breakpoints.small.min}px) {
      margin-left: ${theme.spacing(5.5)};
    }
  `,

  indicator: (isActive: boolean) => css`
    width: 8px;
    height: 8px;
    border-radius: ${theme.borderRadius.full};
    background-color: ${isActive ? theme.colors.neutral60 : theme.colors.neutral20};
    transition: background-color 0.2s;
    padding: 0;

    &:hover {
      background-color: ${isActive ? theme.colors.neutral70 : theme.colors.neutral30};
    }
  `,
};

interface IndicatorsProps {
  total: number;
  currentIndex: number;
  onSelect: (index: number) => void;
}

export const Indicators = memo(function Indicators({ total, currentIndex, onSelect }: IndicatorsProps) {
  const handleSelect = useCallback(
    (index: number) => {
      onSelect(index);
    },
    [onSelect]
  );

  return (
    <motion.div css={styles.indicatorsWrapper}>
      {Array.from({ length: total }, (_, index) => (
        <NakedButton
          key={index}
          css={styles.indicator(index === currentIndex)}
          onClick={() => handleSelect(index)}
          aria-label={`Go to banner ${index + 1}`}
        />
      ))}
    </motion.div>
  );
});

interface GlobalBannerPaginationProps {
  show: boolean;
  currentIndex: number;
  total: number;
  onSelect: (index: number) => void;
  onNavigate: (direction: 'next' | 'previous') => void;
}

export const GlobalBannerPagination = ({
  show,
  currentIndex,
  total,
  onSelect,
  onNavigate,
}: GlobalBannerPaginationProps) => {
  return (
    <AnimatePresence initial={false}>
      {show ? (
        <motion.div initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }} css={styles.container}>
          <motion.div css={styles.content}>
            <Indicators total={total} currentIndex={currentIndex} onSelect={onSelect} />
            <GlobalBannerControls show onPrevious={() => onNavigate('previous')} onNext={() => onNavigate('next')} />
          </motion.div>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
};
