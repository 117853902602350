import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text, NotificationBadge } from '@frontend/design-system';
import { ScheduleRequestsTrackingIds } from './trackingIds';

interface Props {
  count: number;
  isLoading: boolean;
}

export const ScheduleRequestTabHeader = ({ count, isLoading }: Props) => {
  const { t } = useTranslation('schedule-pulse');

  return (
    <div
      data-trackingid={ScheduleRequestsTrackingIds.requestTabBtn}
      css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}
    >
      <Text as='span' size='medium'>
        {t('Requests')}
      </Text>
      {isLoading ? <SpinningLoader size='xs' /> : count ? <NotificationBadge>{count}</NotificationBadge> : null}
    </div>
  );
};
