import { memo, useEffect } from 'react';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedSMSReport, useFormatBarChartData, useMessagingAnalyticsShallowStore } from '../hooks';

const colors = {
  'appointments-reminders': theme.colors.success20,
  'manual-messages': theme.colors.warning50,
  'messaging-preference': theme.colors.primary20,
  'missed-call': theme.colors.critical30,
  'payment-reminder': theme.colors.warning80,
  'product-ready': theme.colors.secondary.seaweed40,
  'recall-text': theme.colors.warning60,
  'review-requests': theme.colors.neutral30,
  'save-the-date': theme.colors.primary60,
  birthday: theme.colors.secondary.eggplant30,
  blank: theme.colors.secondary.seaweed30,
  marketing: theme.colors.secondary.seaweed50,
  na: theme.colors.secondary.seaweed30,
};

export const messagingCategoriesLabels = {
  'appointments-reminders': i18next.t('Appointment Reminder', { ns: 'analytics' }),
  'manual-messages': i18next.t('Manual - Outgoing', { ns: 'analytics' }),
  'messaging-preference': i18next.t('Messaging Preference', { ns: 'analytics' }),
  'missed-call': i18next.t('Missed Call', { ns: 'analytics' }),
  'payment-reminder': i18next.t('Payment Reminder', { ns: 'analytics' }),
  'product-ready': i18next.t('Product Ready', { ns: 'analytics' }),
  'recall-text': i18next.t('Recall Text', { ns: 'analytics' }),
  'review-requests': i18next.t('Review Requests', { ns: 'analytics' }),
  'save-the-date': i18next.t('Save The Date', { ns: 'analytics' }),
  birthday: i18next.t('Birthday', { ns: 'analytics' }),
  blank: i18next.t('Incoming', { ns: 'analytics' }),
  marketing: i18next.t('Marketing', { ns: 'analytics' }),
  na: i18next.t('NA', { ns: 'analytics' }),
};

const allStackKeys = [
  'birthday',
  'blank',
  'marketing',
  'messaging-preference',
  'missed-call',
  'na',
  'payment-reminder',
  'product-ready',
  'recall-text',
  'review-requests',
  'save-the-date',
];

const dataKeysSequence = ['appointments-reminders', 'manual-messages', ...allStackKeys];

// From the list of allStackKeys, we need to get the keys that are present in the data to avoid chart rendering issues
const getDataBasedStackKeys = (valueKeysInData: string[]) => {
  return allStackKeys.filter((key) => valueKeysInData.includes(key));
};

interface MessagesCategoriesChartProps {
  onLoadingStateChange?: (isLoading?: boolean) => void;
}

export const MessagesCategoriesChart = memo(({ onLoadingStateChange }: MessagesCategoriesChartProps) => {
  const { t } = useTranslation('analytics');
  const { data, isLoading } = useFetchAggregatedSMSReport('MessageCategory');
  const { filterHintText, isDemoAccount } = useMessagingAnalyticsShallowStore('filterHintText', 'isDemoAccount');
  const { defaultBarChartAppearance, exportPdfProps, processedData } = useFormatBarChartData({
    data,
    dataKeysSequence,
  });

  useEffect(() => {
    onLoadingStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={messagingCategoriesLabels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={<InfoTipPopover>{t('Tells you the number of messages for each category or type')}</InfoTipPopover>}
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Message Categories')}
      />
      <Chart.BarChart
        appearance={defaultBarChartAppearance}
        data={{
          ...processedData.chartData,
          stackKeys: getDataBasedStackKeys(Object.keys(processedData.chartData?.groups[0]?.values || {})),
        }}
      />
      <Chart.Legends formatValue={formatters.value.format} values={processedData.totals} />
    </Chart>
  );
});

MessagesCategoriesChart.displayName = 'MessagesCategoriesChart';
