import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Tabs } from '@frontend/design-system';
import { quickfillTrackingIds } from '../quickfill-tracking-ids';
import { QuickfillManageList } from './QuickfillManageList';
import { QuickfillSendMessage } from './QuickfillSendMessage';

export const QuickfillTabListPanel = () => {
  const { t } = useTranslation('scheduleQuickfill');
  return (
    <Tabs initialTab='send-messages'>
      <Tabs.Bar fullWidth={false} css={{ padding: theme.spacing(0, 3) }}>
        <Tabs.Tab
          trackingId={quickfillTrackingIds.quickfillSendMessageTabButton}
          id='send-messages'
          controls='send-messages-panel'
        >
          {t('Send Messages')}
        </Tabs.Tab>
        <Tabs.Tab
          trackingId={quickfillTrackingIds.quickfillManageListTabButton}
          id='manage-list'
          controls='manage-list-panel'
        >
          {t('Manage List')}
        </Tabs.Tab>
        {/* NOTE - Removing access to this feature for now. We might want to revert this change in the future. */}
        {/* <Tabs.Tab id='history' controls='history-panel'>
          {t('History')}
        </Tabs.Tab> */}
      </Tabs.Bar>
      <Tabs.Panel css={tabListPanelContainerStyles} id='send-messages-panel' controller='send-messages'>
        <QuickfillSendMessage />
      </Tabs.Panel>
      <Tabs.Panel css={tabListPanelContainerStyles} id='manage-list-panel' controller='manage-list'>
        <QuickfillManageList />
      </Tabs.Panel>
      {/* <Tabs.Panel css={tabListPanelContainerStyles} id='history-panel' controller='history'>
        <QuickfillHistory />
      </Tabs.Panel> */}
    </Tabs>
  );
};

const tabListPanelContainerStyles = css({
  padding: theme.spacing(3),
  backgroundColor: theme.colors.neutral5,
  height: '100%',
  overflowY: 'auto',
});
