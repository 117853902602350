import { css } from '@emotion/react';
import { PaymentsTerminalQueries } from '@frontend/api-payments-terminal';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Button, Text, useModalControl } from '@frontend/design-system';
import { useTerminalSettings } from '../use-terminal-settings';
import { TerminalAddressModal, TerminalAddressModalMode } from './terminal-address-modal';

const styles = {
  addressLine: css`
    display: flex;
    flex-direction: row;
    align-items: center;
  `,
  subtitle: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

export const TerminalAddress = () => {
  const { t } = useTranslation('payments');
  const { locationId, paymentsUrl, merchant } = useMerchant();
  const { modalProps, triggerProps } = useModalControl();
  const { data } = PaymentsTerminalQueries.useTerminalAddressQuery(paymentsUrl, [locationId]);
  const locationAddress = data?.terminalLocations?.[locationId]?.address;
  const { hasTerminalsEnabled } = useTerminalSettings();

  if (!hasTerminalsEnabled) return;
  return (
    <div>
      {merchant?.hasTerminalLocation && locationAddress ? (
        <>
          <Text color='light'>{t('Business Address')}</Text>
          <div css={styles.addressLine}>
            <Text>{`${locationAddress.line1}, ${locationAddress.city}, ${locationAddress.state} ${locationAddress.postalCode}`}</Text>
            <Button iconName='edit-small' onClick={triggerProps.onClick} color='subdued' variant='secondary' />
          </div>
        </>
      ) : (
        <Button onClick={triggerProps.onClick} size='small'>
          {t('Add Business Address')}
        </Button>
      )}
      <Text color='light' size='small' css={styles.subtitle}>
        {t('The address you use for your Stripe account')}
      </Text>
      <TerminalAddressModal
        modalProps={modalProps}
        onSubmit={modalProps.onClose}
        mode={merchant?.hasTerminalLocation ? TerminalAddressModalMode.Edit : TerminalAddressModalMode.Add}
      />
    </div>
  );
};
