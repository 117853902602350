import { ReactNode } from 'react';
import { SlideContainer } from './atoms/slide-container';
import { SlidePanel } from './atoms/slide-panel';
import { SlideCarouselProvider } from './provider';
import { SlideNavigation } from './slide-navigation';

interface SlideCarouselProps {
  children: ReactNode;
  slides: string[];
  initialSlide?: string;
  canDrag?: boolean;
}

export const SlideCarousel = ({ children, slides, initialSlide, canDrag = true }: SlideCarouselProps) => {
  return (
    <SlideCarouselProvider slides={slides} initialSlide={initialSlide} canDrag={canDrag}>
      {children}
    </SlideCarouselProvider>
  );
};

export { useSlideCarousel } from './provider';

SlideCarousel.Container = SlideContainer;
SlideCarousel.Panel = SlidePanel;
SlideCarousel.Navigation = SlideNavigation;
