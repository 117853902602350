import { css } from '@emotion/react';

export const containerStyle = css`
  display: grid;
  height: 100%;
`;

export const detailsContentPanelStyle = css`
  overflow: auto;
`;
