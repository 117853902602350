import { useMemo } from 'react';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { useIsAppointmentDemoAccount } from '../hooks';
import { AppointmentsChartProps } from './shared-types';

const colors = {
  total: theme.colors.secondary.seaweed30,
};

const labels = {
  total: i18next.t('Appointments', { ns: 'analytics' }),
};

export const AppointmentsCountChart = ({ appearance, data, isLoading }: AppointmentsChartProps) => {
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsAppointmentDemoAccount();

  const allAppointmentsVolume = useMemo(() => {
    let total = 0;
    const groups = Object.entries(data.allAppointmentCounts).map(([name, value]) => {
      total += value;

      return {
        name,
        values: {
          total: value,
        },
      };
    });

    return {
      chartData: {
        groups,
      },
      total,
    };
  }, [data.allAppointmentCounts]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        leftElement={isDemoAccount && <DemoChip />}
        title={t('All Appointments Count')}
        trackingIdHelper='all-appointments'
      />
      <Chart.BarChart
        appearance={appearance}
        data={allAppointmentsVolume.chartData}
        formatValue={formatters.value.format}
      />
      <Chart.Legends formatValue={formatters.value.format} values={{ total: allAppointmentsVolume.total }} />
    </Chart>
  );
};
