import { FC } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { NewInsuranceVerificationSVG } from '@frontend/assets';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { SUBSCRIPTION_SETTINGS_URL } from '../../../constants/self-upgrade';
import { getScheduleDemoLink } from '../../../self-upgrade.util';
import { FeatureUpgradeModalComponentProps } from '../../../types';
import { FeatureUpgradeDetailsBaseModal } from '../FeatureUpgradeDetailsBaseModal';
import { WIV_PRICE, WIV_VIDEO_URL } from './constants';

export const WivUpgradeDetailsModal: FC<FeatureUpgradeModalComponentProps> = (props) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'wiv-details-modal' });

  const handleScheduleCall = () => {
    window.open(getScheduleDemoLink(Feature.WEAVE_VERIFY, getUser()?.username), '_blank');
  };

  return (
    <FeatureUpgradeDetailsBaseModal
      featureAccountSettingsUrl={SUBSCRIPTION_SETTINGS_URL}
      featureName={t('Weave Insurance Verification')}
      onScheduleCallClick={handleScheduleCall}
      bundleFeature={t('Elite Feature')}
      {...props}
    >
      <FeatureUpgradeDetailsBaseModal.Title title={t('Weave Insurance Verification')} price={WIV_PRICE} />
      <FeatureUpgradeDetailsBaseModal.ScrollableContent>
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.VideoText
          videoUrl={WIV_VIDEO_URL}
          text={t(
            'Spend fewer hours each week verifying insurance information so you can focus on running your business. Weave Insurance Verification automatically looks up up-to-date, accurate, easy-to-search insurance eligibility information with the click of a button. You can then attach the eligibility report to the patient profile in your practice management system.'
          )}
        />
        <WivFeatureCards />
        <FeatureUpgradeDetailsBaseModal.ScrollableContent.BannerText
          imageUrl={NewInsuranceVerificationSVG}
          imageAltText={t('WIV Banner')}
          subTitle={t('Get Weave Insurance Verification')}
        />
      </FeatureUpgradeDetailsBaseModal.ScrollableContent>
      <FeatureUpgradeDetailsBaseModal.Action />
    </FeatureUpgradeDetailsBaseModal>
  );
};

export const WivFeatureCards: FC = () => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'wiv-details-modal' });

  return (
    <div css={featureCardsLayoutStyles}>
      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard title={t('Save Time')} icon={'clock-small'}>
        <Text size='medium'>
          {t(
            'Reduce the hours your staff spends every week on calling and verifying insurance information, so they can focus on providing better service to your patients.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Reduce Errors')}
        icon={'check-please-small'}
      >
        <Text size='medium'>
          {t(
            'With Weave Insurance Verification, you can be confident that the information provided is as up-to-date as possible to make better decisions for your practice and your patients.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>

      <FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard
        title={t('Focus On Your Patients')}
        icon={'emoji-small'}
      >
        <Text size='medium'>
          {t(
            'Spend more time creating an exceptional patient experience and less time verifying insurance with Weave Insurance Verification.'
          )}
        </Text>
      </FeatureUpgradeDetailsBaseModal.ScrollableContent.FeatureCard>
    </div>
  );
};

const featureCardsLayoutStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
});
