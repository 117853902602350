import { useMemo } from 'react';
import { InvoiceFilterType } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { QuickFillBar, quickRangeConfig } from '../../QuickFill';

interface InvoicesQuickFillBarProps {
  showAllTimeButton?: boolean;
  enableDropDownFilter?: boolean;
  dateRange: InvoiceFilterType['dateRange'];
  onChange?: (filter: InvoiceFilterType['dateRange']) => void;
}

export const InvoicesQuickFillBar = ({
  dateRange,
  onChange,
  showAllTimeButton = false,
  enableDropDownFilter = false,
}: InvoicesQuickFillBarProps) => {
  const { t } = useTranslation('payments');
  const config = useMemo(() => quickRangeConfig(t, showAllTimeButton), []);

  const handleSetFilterRange = ([start, end]: string[]) => {
    onChange?.(start && end ? { start, end } : undefined);
  };

  return (
    <QuickFillBar
      trackingId='pay-portal-invoices-quickfillbar'
      config={config}
      onSelect={handleSetFilterRange}
      filter={{ dateRange }}
      enableDropDownFilter={enableDropDownFilter}
    />
  );
};
