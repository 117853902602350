import dayjs from 'dayjs';
import { createStore, createShallowStore } from '@frontend/store';
import { APPOINTMENT_STATUS_CACHE_TIME_IN_MINUTES } from '../helpers';
import { AppointmentStatusType } from './types';

type AppointmentStatusStoreState = {
  appointmentStatus: AppointmentStatusType[];
};

type AppointmentStatusStoreActions = {
  setAppointmentStatus: (value: AppointmentStatusType) => void;
  resetAppointmentStatus: () => void;
};

const useAppointmentStatusStore = createStore<AppointmentStatusStoreActions & AppointmentStatusStoreState>(
  (set, get) => ({
    appointmentStatus: new Array<AppointmentStatusType>(),
    setAppointmentStatus: (value: AppointmentStatusType) => {
      const { appointmentStatus } = get();
      const duplicatesRemoved = appointmentStatus.filter((status) => status.id !== value.id);
      const updatedValues = [...duplicatesRemoved, value];
      set(
        (state) => {
          state.appointmentStatus = [...updatedValues];
          return state;
        },
        false,
        'SET_APPOINTMENT_STATUS'
      );
    },
    resetAppointmentStatus: () => {
      const { appointmentStatus } = get();
      const valuesToKeep = appointmentStatus.filter(
        (status) => dayjs().diff(dayjs(status.updatedAt), 'minutes') < APPOINTMENT_STATUS_CACHE_TIME_IN_MINUTES
      );
      const updatedValues = [...valuesToKeep];
      set(
        (state) => {
          state.appointmentStatus = updatedValues;
          return state;
        },
        false,
        'RESET_APPOINTMENT_STATUS'
      );
    },
  }),
  { name: 'AppointmentStatusStore', trace: true }
);

export const useAppointmentStatusShallowStore = createShallowStore(useAppointmentStatusStore);
