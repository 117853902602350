import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';
import { dispatchTopBarTraySelectionEvent } from '@frontend/shared';

/**
 * @dashboard-widget
 *
 * id: add-quick-fill
 * title: Add to Quick Fill
 * description: Add patients to your Quick Fill list to schedule last-minute appointments.
 * icon: quickfill-small
 */
export const AddQuickFillWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('schedule-quick-fill');

  const { widgetTrackingId } = useDashboardWidget();

  return (
    <DashboardWidget.ActionButton
      trackingId={widgetTrackingId('action')}
      iconName='add'
      title={t('Quick Fill')}
      onClick={(e) => dispatchTopBarTraySelectionEvent('quickfill', e)}
    />
  );
};

AddQuickFillWidget.config = {
  size: 'tiny-slim',
  feature: 'schedule',
};
