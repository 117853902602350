import { useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { ScheduleTypes } from '@frontend/api-schedule';
import { AvailableLanguages, useCurrentLanguage, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { classNames } from '@frontend/string';
import { theme } from '@frontend/theme';
import {
  FullscreenModal,
  IconButton,
  Modal,
  PopoverMenuItem,
  PopoverMenu,
  PrimaryButton,
  Text,
  useModalControl,
  usePopoverMenu,
} from '@frontend/design-system';
import { CopyHelperSmall } from '../CopyHelper';
import { formDisplayStyles } from './styles';
import { downloadCSV, getCopyableText } from './utils';

interface FormDisplayProps {
  form: Partial<ScheduleTypes.PatientInfo>;
  customFields?: ScheduleTypes.CustomField[];
  overrideFields?: Record<keyof ScheduleTypes.PatientInfo, ScheduleTypes.CustomField>;
  fullsize?: boolean;
}

function FormDisplay({ form, customFields, overrideFields, fullsize = false }: FormDisplayProps) {
  const { t } = useTranslation('schedule');
  const { getTriggerProps, getMenuProps } = usePopoverMenu({ placement: 'left' });
  const triggerProps = getTriggerProps();
  const menuProps = getMenuProps();
  const lang = useCurrentLanguage() as AvailableLanguages;

  const handleDownloadCSV = useCallback(() => {
    downloadCSV({ t, form, customFields, overrideFields, lang });
  }, [form, customFields, overrideFields]);

  const handleDownloadJSON = useCallback(() => {
    downloadCSV({ t, form, customFields, overrideFields, lang });
  }, [form, customFields, overrideFields]);

  const { defaultFields, optionalFields } = useMemo(() => {
    return {
      defaultFields: Object.values(overrideFields || {}).filter((f) => !f.hideable),
      optionalFields: Object.values(overrideFields || {}).filter((f) => f.hideable),
    };
  }, []);

  return (
    <div css={formDisplayStyles}>
      <Text>{t('Click on any value to copy')}</Text>
      <IconButton className='download-button' {...triggerProps} label={t('Download request form data')}>
        <Icon name='download' />
      </IconButton>
      <PopoverMenu css={css({ zIndex: theme.zIndex.alerts })} title={t('Download')} {...menuProps}>
        <PopoverMenuItem onClick={handleDownloadCSV}>{t('CSV')}</PopoverMenuItem>
        <PopoverMenuItem onClick={handleDownloadJSON}>{t('JSON')}</PopoverMenuItem>
      </PopoverMenu>
      <article className={classNames({ fullsize })}>
        <dl>
          {defaultFields.map((field) => {
            if (field.show) {
              return (
                <>
                  <dt>{field.translations?.[lang] || t(field.label)}</dt>
                  <dd>
                    <CopyHelperSmall
                      helperText={t('Click to copy {{label}}', { label: field.translations?.[lang] || field.label })}
                      copyText={getCopyableText(t, form?.[field.key as keyof ScheduleTypes.PatientInfo], field, lang)}
                    />
                  </dd>
                </>
              );
            }
            return null;
          })}
        </dl>
        <dl>
          {optionalFields.map((field) => {
            if (field.show) {
              return (
                <>
                  <dt>{field.translations?.[lang] || t(field.label)}</dt>
                  <dd>
                    <CopyHelperSmall
                      helperText={t('Click to copy {{label}}', { label: field.translations?.[lang] || field.label })}
                      copyText={getCopyableText(t, form?.[field.key as keyof ScheduleTypes.PatientInfo], field, lang)}
                    />
                  </dd>
                </>
              );
            }
            return null;
          })}
          {customFields?.map((field) => (
            <>
              <dt>{field.translations?.[lang] || field.label}</dt>
              <dd>
                <CopyHelperSmall
                  helperText={t('Click to copy {{label}}', { label: field.translations?.[lang] || field.label })}
                  copyText={getCopyableText(t, form?.custom?.[field?.key] || '', field, lang)}
                />
              </dd>
            </>
          ))}
        </dl>
      </article>
    </div>
  );
}

type FormDisplayModalProps = FormDisplayProps & {
  triggerLabel?: string;
  modalHeader?: string;
  modalPrimaryLabel?: string;
};

export function FormDisplayModal({
  form,
  customFields,
  triggerLabel,
  modalHeader,
  modalPrimaryLabel,
  overrideFields,
  fullsize,
}: FormDisplayModalProps) {
  const { t } = useTranslation('schedule');
  const modalControl = useModalControl();
  return (
    <>
      <PrimaryButton {...modalControl.triggerProps}>{triggerLabel || t('Preview')}</PrimaryButton>
      {fullsize ? (
        <FullscreenModal {...modalControl.modalProps}>
          <FullscreenModal.Header title={modalHeader || t('Patient Information')} />
          <FullscreenModal.Body css={css({ position: 'relative' })}>
            <FormDisplay fullsize={fullsize} form={form} customFields={customFields} overrideFields={overrideFields} />
          </FullscreenModal.Body>
          <FullscreenModal.Actions onAction={modalControl.closeModal} actionName={modalPrimaryLabel || t('Done')} />
        </FullscreenModal>
      ) : (
        <Modal {...modalControl.modalProps} maxWidth={800}>
          <Modal.Header>{modalHeader || t('Patient Information')}</Modal.Header>
          <Modal.Body>
            <FormDisplay form={form} customFields={customFields} overrideFields={overrideFields} />
          </Modal.Body>
          <Modal.Actions onPrimaryClick={modalControl.closeModal} primaryLabel={modalPrimaryLabel || t('Done')} />
        </Modal>
      )}
    </>
  );
}
