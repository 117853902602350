export const refundListQueryString = `
  query refunds(
    $pageInfo: PageInfoInput
    $filter: RefundsFilterInput
  ) {
    refunds(pageInfo: $pageInfo, filter: $filter) {
      edges {
        cursor
        node {
          id
          locationId
          refundAmount
          type
          dateRefunded
          invoiceId
          person {
            id
            name
          }
          payment {
            id
            date
            brand
            last4
            amount
            origin
            paymentType
          }
        }
      }
      pageInfo {
        endCursor
        startCursor
        hasNextPage
        hasPreviousPage
      }
      summary {
        totalAmount
      }
    }
  }
`;

export const allRefundsQueryString = `
  query refunds(
    $filter: RefundsFilterInput
  ) {
    refunds(filter: $filter) {
      edges {
        node {
          id
          locationId
          refundAmount
          type
          dateRefunded
          invoiceId
          person {
            id
            name
          }
          payment {
            id
            date
            brand
            last4
            amount
            origin
            paymentType
          }
        }
      }
      summary {
        totalAmount
      }
    }
  }
`;
