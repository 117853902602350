import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Options } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useQueryClient } from 'react-query';
import { DigitalFormsMutations, DigitalFormsQueries } from '@frontend/api-digital-forms';
import { FormsMedicalConditions } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import {
  ConfirmationModal,
  ContentLoader,
  SearchField,
  useDebouncedValue,
  useFormField,
  useModalControl,
  useAlert,
  Button,
  NakedButton,
  Text,
} from '@frontend/design-system';
import { useLocationAccordionContext } from '../../../../context/location-accordion/location-accordion.context';
import { getMedicalConditionsUIMeta } from '../utils';
import { conditionListWrapperStyle, conditionListStyle } from './condition-list.style';
import MedicalConditionOption from './condition-option.component';

export interface ToggleMedicalConditionStatePayload {
  value: string;
  state: boolean;
}

interface ConditionListProps {
  id: string;
  sourceTenantId: string;
  condition: FormsMedicalConditions.Types.MedicalConditionName;
  options: Options[];
  syncButtonTrackingId?: string;
  saveButtonTrackingId?: string;
}

// Utility function to calculate initial state
const getInitialOptionState = (options: Options[]): Record<string, boolean> => {
  const initialState: Record<string, boolean> = {};
  options.forEach((opt) => {
    if (opt.value) {
      initialState[opt.value] = !!opt.disabled;
    }
  });
  return initialState;
};

// Utility function to check if there are changes between current and initial state
const hasStateChanged = (currentState: Record<string, boolean>, initialState: Record<string, boolean>): boolean => {
  for (const key in currentState) {
    if (currentState[key] !== initialState[key]) {
      return true;
    }
  }
  return false;
};

type OptionState = Record<string, boolean>;

export const ConditionList: FC<ConditionListProps> = ({
  id,
  condition,
  options,
  sourceTenantId,
  syncButtonTrackingId,
  saveButtonTrackingId,
}) => {
  const { t } = useTranslation('forms');
  const searchProps = useFormField({
    type: 'text',
    placeholder: t('Search'),
  });

  const [optionState, setOptionState] = useState<OptionState>({});
  const [changedOptions, setChangedOptions] = useState<OptionState>({});
  const [hasChanges, setHasChanges] = useState(false);
  const { triggerProps, modalProps, closeModal } = useModalControl();

  const queryClient = useQueryClient();

  const { mutateAsync: toggleMedicalConditions, isLoading: isToggling } =
    DigitalFormsMutations.useMedicalHistoryAnswerOptionsToggle({
      onSuccess: () => {
        queryClient.invalidateQueries(DigitalFormsQueries.endpointKeys.medicalConditions);
        alert.success(t('Changes saved successfully'));
      },
      onError: () => {
        alert.error(t('Failed to save changes'));
      },
    });

  const { locationId } = useLocationAccordionContext();
  const { mutateAsync: syncMedicalConditions, isLoading: isSyncing } = DigitalFormsMutations.useSyncMedicalConditions({
    onSuccess: () => {
      queryClient.invalidateQueries(DigitalFormsQueries.endpointKeys.medicalConditions);
      alert.success(t('Synced options successfully!'));
    },
    onError: () => {
      alert.error(t('Failed to sync options'));
    },
  });

  const searchTerm = useDebouncedValue(searchProps.value);
  const alert = useAlert();

  const initialStateRef = useRef<OptionState>({});

  useEffect(() => {
    const initialState = getInitialOptionState(options);
    initialStateRef.current = initialState;
    setOptionState(initialState);
  }, [options]);

  const conditions = useMemo(() => {
    return options.filter((opt) => opt.label?.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [options, searchTerm]);

  async function onSync() {
    await syncMedicalConditions({
      companyId: locationId,
      conditionName: condition,
      sourceTenantId: sourceTenantId,
    });
  }

  const meta = getMedicalConditionsUIMeta(condition);

  // Updated onToggle with recommended approach
  const onToggle = ({ state, value }: ToggleMedicalConditionStatePayload) => {
    let updatedOptions: OptionState = {};

    setChangedOptions((prevState) => {
      const newState = {
        ...prevState,
        [value]: state,
      };
      updatedOptions = { ...newState };
      return newState;
    });

    setOptionState((prevState) => ({
      ...prevState,
      [value]: state,
    }));

    setHasChanges(hasStateChanged(updatedOptions, initialStateRef.current));
  };

  const onSave = () => {
    if (!hasChanges) {
      alert.warning(t('There are no changes to save.'));
      return;
    }

    toggleMedicalConditions({
      companyId: locationId,
      id: id,
      name: condition,
      medicalHistoryAnswerOptionStatus: { ...changedOptions },
    });

    setChangedOptions({});
    initialStateRef.current = { ...optionState };
    setHasChanges(false);
  };

  const onCancel = () => {
    setChangedOptions({});
    setOptionState(initialStateRef.current);
    setHasChanges(false);
  };

  const cancelHandler = () => {
    if (!hasStateChanged(optionState, initialStateRef.current)) {
      alert.warning(t('There are no changes to discard.'));
      return;
    }
    triggerProps.onClick();
  };

  const onBatchSelect = (selected: boolean) => {
    const updatedOptions: OptionState = {};
    options.forEach((opt) => {
      if (opt.value) {
        updatedOptions[opt.value] = !selected;
      }
    });

    setOptionState(updatedOptions);
    setChangedOptions(updatedOptions);

    const isThereAChange = hasStateChanged(updatedOptions, initialStateRef.current);
    setHasChanges(isThereAChange);
  };

  return (
    <div css={conditionListWrapperStyle}>
      <ContentLoader show={isSyncing} message={t('Syncing {{type}}', { type: meta.label })} />
      <ContentLoader show={isToggling} message={t('Saving changes . . .')} />
      <ConfirmationModal
        {...modalProps}
        destructive
        confirmLabel={t('Discard')}
        onConfirm={onCancel}
        onCancel={closeModal}
        title={t('Discard changes')}
        message={t('Are you sure you want to discard your changes?')}
      />

      <div className='search-row'>
        <span className='seach-container'>
          <SearchField className='search-conditions' {...searchProps} name='search' />
        </span>
        <Button
          variant='secondary'
          size='large'
          className='sync-btn'
          onClick={onSync}
          trackingId={syncButtonTrackingId}
        >
          {t(`Sync ${meta.label}`)}
        </Button>
      </div>
      <div className='text-condition-actions'>
        <NakedButton onClick={() => onBatchSelect(true)}>
          <Text as='span' color='subdued'>
            {t('Select all')}
          </Text>
        </NakedButton>
        <NakedButton onClick={() => onBatchSelect(false)}>
          <Text as='span' color='subdued'>
            {t('Deselect all')}
          </Text>
        </NakedButton>
      </div>
      <ul css={conditionListStyle}>
        {conditions.map(({ disabled = false, label = '', value = '' }) => (
          <MedicalConditionOption
            state={!optionState[value]}
            key={label}
            label={label}
            value={value}
            disabled={disabled}
            onToggle={onToggle}
          />
        ))}
      </ul>
      <div className='save-actions'>
        <Button variant='secondary' onClick={cancelHandler} disabled={!hasChanges}>
          {t('Cancel')}
        </Button>
        <Button variant='primary' onClick={onSave} disabled={!hasChanges} trackingId={saveButtonTrackingId}>
          {t('Save')}
        </Button>
      </div>
    </div>
  );
};
