import { LineKeysTypes } from '@frontend/api-line-keys';
import { DeviceLayoutTouchScreen } from './device-layout-touch-screen';
import { DeviceLayoutWithSideKeys } from './device-layout-with-side-keys';

export type DeviceLayoutWithKeysProps = {
  previewOnly?: boolean;
  isPaginationEnabled: boolean;
  pageNum: number;
  lineKeys: LineKeysTypes.GetLineKeysType['output']['lineKeys'];
};

export const DeviceLayoutBody = ({ ...rest }: DeviceLayoutWithKeysProps) => {
  return <DeviceLayoutBody.LayoutWithKeys {...rest} />;
};

DeviceLayoutBody.LayoutWithKeys = DeviceLayoutWithSideKeys;
DeviceLayoutBody.TouchScreen = DeviceLayoutTouchScreen;
