import { ListLocationFlagsResponse } from '@weave/schema-gen-ts/dist/schemas/feature-flags/feature_flags_service_api.pb';
import {
  FormListRequest,
  PacketListRequest,
} from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { AutoMessageType_Slug, Rule } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/models.pb';
import {
  CreateRuleRequest,
  DeleteRuleRequest,
  ListAutoMessageTypesRequest,
  ListRulesRequest,
  ListRulesResponse,
  PreviewEmailTemplateRequest,
  RuleHistoryRequest,
  RuleRequest,
  ToggleRuleRequest,
  UpdateRuleRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/auto-rules/v1/service.pb';
import { State } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/platform-enums/location_feature_enum.pb';
import { ListDataSourcesRequest } from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/datasource.pb';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useQueries, useQuery, UseQueryOptions } from 'react-query';
import { SchemaDigitalForms } from '@frontend/api-digital-forms';
import { http } from '@frontend/fetch';
import { SchemaFeatureFlags, SchemaLocationService, SchemaPlatformLocationFeature } from '@frontend/schema';
import { SchemaAutoRules, SchemaIntegrationsService } from './service';

export const queryKeys = {
  base: 'messages',
  autoruleHistory: (ruleId: string) => [queryKeys.base, 'autorule-history', ruleId],
  customizationFlags: (locationIds: string[]) => [queryKeys.base, 'customization-flags', locationIds],
  featureFlag: (locationId: string) => [queryKeys.base, 'feature-flags', locationId],
  formList: (request: FormListRequest) => [queryKeys.base, 'forms', request],
  listRules: (request: ListRulesRequest) => [queryKeys.base, 'list-rules', request],
  listDataSourcesByLocationID: (request: ListDataSourcesRequest) => [queryKeys.base, 'data-sources', request],
  locationData: (locationId: string) => [queryKeys.base, 'location-data', locationId],
  messageTypes: (request: ListAutoMessageTypesRequest) => [
    queryKeys.base,
    'message-types',
    request.orgId,
    request.businessGroupIds,
  ],
  packetList: (request: PacketListRequest) => [queryKeys.base, 'packets', request],
  previewEmailTemplate: (request: PreviewEmailTemplateRequest) => [queryKeys.base, 'preview-email-template', request],
  rule: (ruleId: string) => [queryKeys.base, 'autorule', ruleId],
};

const sortLocationIds = (locationIds?: string[]) =>
  locationIds ? locationIds.sort((a, b) => (a > b ? 1 : -1)) : undefined;

const defaultOptions = {
  retry: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  refetchOnReconnect: false,
};

export type GroupedListRules = Record<AutoMessageType_Slug, Rule[]>;
export const groupListRulesBySlug = (response: ListRulesResponse) =>
  (response.rules || []).reduce((acc, item) => {
    const type = item?.autoMessageType?.slug as AutoMessageType_Slug;
    return {
      ...acc,
      [type]: acc[type] ? [...acc[type], item] : [item],
    };
  }, {} as GroupedListRules);

export const useListRules = <T>(request: ListRulesRequest, options?: UseQueryOptions<ListRulesResponse, unknown, T>) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.listRules({ ...request, businessGroups: sortLocationIds(request.businessGroups) }),
    queryFn: () => SchemaAutoRules.ListRules(request),
    enabled: !!request.orgId && !!request?.businessGroups?.length,
    ...options,
  });

export const useRule = (request: RuleRequest) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.rule(request.ruleId!),
    queryFn: () => SchemaAutoRules.Rule(request),
    enabled: !!(request.ruleId && request.ruleId !== 'new' && request.orgId),
    select: (data) => data.rule,
    refetchOnMount: true,
  });

export const useRuleHistory = (request: RuleHistoryRequest) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.autoruleHistory(request.ruleId!),
    queryFn: () => SchemaAutoRules.RuleHistory(request),
    enabled: !!request.ruleId && !!request.orgId,
    select: (data) => data.rules,
  });

export const useListAutoMessageTypes = (request: ListAutoMessageTypesRequest) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.messageTypes({ ...request, businessGroupIds: sortLocationIds(request.businessGroupIds) }),
    queryFn: () => SchemaAutoRules.ListAutoMessageTypes(request),
    select: (data) => data.messageTypes,
    enabled: !!request.orgId && !!request?.businessGroupIds?.length,
  });

export const usePreviewEmailTemplate = (request: PreviewEmailTemplateRequest) =>
  SchemaAutoRules.PreviewEmailTemplate(request);

export const useGetFormList = (request: FormListRequest) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.formList(request),
    queryFn: () => SchemaDigitalForms.GetFormList(request),
    enabled: !!request.companyId,
    select: (response) => response.data?.map((form) => ({ ...form, documentType: 'form' })),
  });

export const useGetPacketList = (request: PacketListRequest) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.packetList(request),
    queryFn: () => SchemaDigitalForms.GetPacketList(request),
    enabled: !!request.companyId,
    select: (response) => response.data?.map((packet) => ({ ...packet, documentType: 'packet' })),
  });

// gets all the customization features for a single organization
export const useCustomizationFlags = (locationIds: string[]) =>
  useQuery({
    queryKey: queryKeys.customizationFlags(locationIds),
    queryFn: () => SchemaPlatformLocationFeature.LocationsFeatures({ locationIds }),
    enabled: !!locationIds.length,
    select: (data) => {
      return [
        ...new Set(
          Object.values(data.locationsFeatures || {})
            .map((item) => item.features ?? [])
            .flat()
            .filter((feature) => feature.state === State.ACTIVE || feature.state === State.PROMOTE)
            .map((item) => item.featureEnum) as Feature[]
        ),
      ];
    },
  });

// gets all the feature flags for a single organization
export const useFeatureFlags = (locationIds: string[]) =>
  useQueries(
    locationIds.map((locationId) => ({
      queryKey: queryKeys.featureFlag(locationId),
      queryFn: () => SchemaFeatureFlags.ListLocationFlags({ locationId }),
      enabled: !!locationId,
      select: (data: ListLocationFlagsResponse) => {
        const flags = (data.flags || []).filter((flag) => flag.value);
        return { locationId, flags };
      },
    }))
  );

export const useGetLocationData = (locationId: string) =>
  useQuery({
    queryKey: queryKeys.locationData(locationId),
    queryFn: () => SchemaLocationService.GetLocation({ locationId }),
    enabled: !!locationId,
  });

// TODO: move these to a mutations file and refactor the useMutation calls in the components
export const useCreateRule = (request: CreateRuleRequest) => SchemaAutoRules.CreateRule(request);
export const useDeleteRule = (request: DeleteRuleRequest) => SchemaAutoRules.DeleteRule(request);
export const useToggleRule = (request: ToggleRuleRequest) => SchemaAutoRules.ToggleRule(request);
export const useUpdateRule = (request: UpdateRuleRequest) => SchemaAutoRules.UpdateRule(request);

export const useListDataSourcesByLocationID = (request: ListDataSourcesRequest) => {
  return useQuery({
    queryKey: queryKeys.listDataSourcesByLocationID(request),
    queryFn: () => SchemaIntegrationsService.ListDataSourcesByLocationID(request),
    enabled: !!request.locationId,
  });
};

export const useAudioLibraryDialerPreview = (request: { text: string; lang?: string }) =>
  http.post('/phone/audio-library/v1/dialer/preview', request, { responseType: 'blob' });
