import { FC, useEffect, useMemo, useState } from 'react';
import { BarChartAppearance } from '@frontend/charts';
import { useAnalyticsOrgLocations } from '../../hooks';
import { ExportUtils, toHHmmss } from '../../utils';
import {
  AverageCallDurationChart,
  AverageIncomingCallDurationChart,
  LongDurationCallsChart,
  PatientsVsUnknownCallDurationChart,
  TotalCallDurationChart,
} from './charts';
import { usePhoneAnalyticsShallowStore } from './hooks';

interface Props {
  defaultChartAppearance?: Partial<BarChartAppearance>;
  onFetchStateChange?: (isFetching?: boolean) => void;
}

type ChartLoadingState = {
  averageCallDuration?: boolean;
  averageIncomingCallDuration?: boolean;
  longDurationCalls?: boolean;
  patientsVsUnknownCallDuration?: boolean;
  totalCallDuration?: boolean;
};

export const DurationAnalyticsPanel: FC<React.PropsWithChildren<Props>> = ({
  defaultChartAppearance = {},
  onFetchStateChange,
}) => {
  const { filters, isDemoAccount } = usePhoneAnalyticsShallowStore('filters', 'isDemoAccount');
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });
  const [chartLoadingState, setChartLoadingState] = useState<ChartLoadingState>({});

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.location_id || [], locationNames),
    [filters.location_id, locationNames]
  );

  const handleChartLoadingStateChange = (chartName: keyof ChartLoadingState, isLoading?: boolean) => {
    setChartLoadingState((prevState) => ({
      ...prevState,
      [chartName]: isLoading,
    }));
  };

  const commonProps = {
    defaultChartAppearance: {
      ...defaultChartAppearance,
      customYAxisTick: undefined, // TODO :: check why TS is forcing to add this prop
      customYAxisTickFormat: toHHmmss,
    },
    exportPdfProps,
  };

  useEffect(() => {
    if (onFetchStateChange) {
      const isFetching = Object.values(chartLoadingState).some((isLoading) => isLoading);
      onFetchStateChange(isFetching);
    }
  }, [chartLoadingState]);

  return (
    <>
      <TotalCallDurationChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('totalCallDuration', value)}
      />

      <PatientsVsUnknownCallDurationChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('patientsVsUnknownCallDuration', value)}
      />

      <AverageCallDurationChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('averageCallDuration', value)}
      />

      <AverageIncomingCallDurationChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('averageIncomingCallDuration', value)}
      />

      <LongDurationCallsChart
        {...commonProps}
        onFetchStateChange={(value) => handleChartLoadingStateChange('longDurationCalls', value)}
      />
    </>
  );
};
