import { useEffect, useMemo, useRef, useState } from 'react';
import { InsuranceStatus } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';
import { InsuranceDetailQueries } from '@frontend/api-insurance-detail';
import { PersonTypes } from '@frontend/api-person';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { WivPromotionCard, useWivTeaserHandler } from '@frontend/self-upgrade';
import { useContactPanelShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Heading, SpinningLoader, styles, useModalControl } from '@frontend/design-system';
import {
  ActionsMenu,
  ErrorBanner,
  InsuranceDetailList,
  VerificationMetricSidePanel,
  VerifyInsuranceSidePanel,
  ToggleExpandContainer,
} from './components';
import { VerifiedInsuranceDetailsList } from './components/verified-insurance-details-list';
import { DateRangeOptionValue } from './constants';
import { prepareInsuranceDetails } from './helpers';
import { InsuranceStatusChipButton } from './insurance-status-chip-button';
import { PersonInfoStoreProvider, usePersonInfoStore } from './providers';
import { DropdownOption } from './types';

export const InsuranceDetailsComponent = () => {
  const { t } = useTranslation('insuranceDetails');
  const ref = useRef<HTMLDivElement>(null);
  const [defaultDateRange, setDefaultDateRange] = useState<DateRangeOptionValue>('today');
  const verifyInsuranceModalControl = useModalControl();
  const verificationMetricModalControl = useModalControl();
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID as string;

  const { isLoading: isLoadingWivEligibility, showWeaveVerify } = useWivTeaserHandler({ locationIds: [locationId!] });

  const { data: insuranceDetails, isLoading: isLoadingInsuranceDetails } =
    InsuranceDetailQueries.useGetInsuranceByPersonId(personId, locationId!);

  const hasCoverageDetails = !!insuranceDetails?.coverage?.thirdPartyCoverage?.coverage;

  const { data: verificationDetails, isLoading: isLoadingVerificationDetails } =
    InsuranceDetailQueries.useGetInsuranceVerificationDetailsPersonId(personId, locationId!, {
      disabled: !showWeaveVerify && !hasCoverageDetails,
    });

  const { data: payerListResponse } = InsuranceDetailQueries.useGetPayerList(locationId!);
  const insuranceInfo = useMemo(() => {
    if (insuranceDetails) {
      const payerList =
        payerListResponse?.map<DropdownOption>((item) => ({
          label: item.name,
          value: item.id,
        })) ?? [];

      if (!insuranceDetails.coverage) {
        // Setting up the coverage value
        insuranceDetails.coverage = verificationDetails?.coverage || {};
      }

      return prepareInsuranceDetails(insuranceDetails, payerList);
    }
    return null;
  }, [insuranceDetails, payerListResponse]);

  const { scrollTo, setScrollTo } = useContactPanelShallowStore('scrollTo', 'setScrollTo');
  useEffect(() => {
    if (scrollTo === 'insurance-details') {
      ref.current?.scrollIntoView({ behavior: 'smooth' });
      setScrollTo(undefined);
    }
  }, [scrollTo]);

  const handleReportLearnMoreClick = () => {
    setDefaultDateRange('this-month');
    verificationMetricModalControl.openModal();
  };
  const handleVerificationMetricActionMenuClick = () => {
    setDefaultDateRange('today');
    verificationMetricModalControl.openModal();
  };

  const hasEmptyInsuranceDetails = useMemo(() => {
    if (!insuranceInfo) return true;
    return (
      !insuranceInfo.memberId || !insuranceInfo.payerId || !insuranceInfo.payerName || !insuranceInfo.nationalProviderId
    );
  }, [insuranceInfo]);

  if (isLoadingInsuranceDetails || isLoadingWivEligibility || isLoadingVerificationDetails) {
    return (
      <div css={styles.flexCenter}>
        <SpinningLoader size='small' />
      </div>
    );
  }

  if (!personId || !insuranceInfo) return null;

  const hasActiveOrVerifiedInsurance =
    insuranceInfo.thirdPartyCoverageStatus.toUpperCase() === InsuranceStatus.ACTIVE ||
    insuranceInfo.thirdPartyCoverageStatus.toUpperCase() === InsuranceStatus.VERIFIED;

  const shouldShowVerifiedInsuranceDetails = hasActiveOrVerifiedInsurance && hasEmptyInsuranceDetails;

  return (
    <>
      <section ref={ref} css={{ padding: theme.spacing(2, 2, 1) }}>
        <header css={{ display: 'flex', justifyContent: 'space-between', marginBottom: theme.spacing(2) }}>
          <Heading level={3}>{t('Insurance')}</Heading>
          <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
            <InsuranceStatusChipButton
              personId={personId}
              locationId={locationId!}
              onClick={verifyInsuranceModalControl.openModal}
            />
            <ActionsMenu
              verificationStatus={insuranceInfo.thirdPartyCoverageStatus}
              onVerifyInsuranceClick={verifyInsuranceModalControl.openModal}
              onVerificationMetricClick={handleVerificationMetricActionMenuClick}
            />
          </div>
        </header>
        <ErrorBanner
          isRetryButtonVisible
          errorDetails={insuranceDetails?.coverage?.thirdPartyCoverage?.errorDetails}
          onRetryClick={verifyInsuranceModalControl.openModal}
        />
        <ToggleExpandContainer>
          {shouldShowVerifiedInsuranceDetails ? (
            <VerifiedInsuranceDetailsList personId={personId} />
          ) : (
            <InsuranceDetailList insuranceDetails={insuranceInfo} />
          )}
        </ToggleExpandContainer>
      </section>
      <VerifyInsuranceSidePanel
        {...verifyInsuranceModalControl.modalProps}
        onReportLearnMoreClick={handleReportLearnMoreClick}
      />
      <VerificationMetricSidePanel {...verificationMetricModalControl.modalProps} dateRange={defaultDateRange} />
    </>
  );
};

interface Props {
  person?: PersonTypes.Person;
}

export const InsuranceDetails = ({ person }: Props) => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const { t } = useTranslation('insuranceDetails');

  const {
    isLoading: isLoadingWivEligibility,
    showWeaveVerify,
    showWeaveVerifyPromotion,
    handleWivUpgradeSuccess,
  } = useWivTeaserHandler({ locationIds: [locationId] });

  if (!person || (!showWeaveVerify && !showWeaveVerifyPromotion)) return null;

  if (isLoadingWivEligibility) {
    return (
      <div css={[styles.flexCenter, { padding: theme.spacing(2) }]}>
        <SpinningLoader size='small' />
      </div>
    );
  }

  if (showWeaveVerifyPromotion) {
    return (
      <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(1), padding: theme.spacing(2) }}>
        <Heading level={3}>{t('Insurance')}</Heading>
        <WivPromotionCard locationIds={[locationId]} source='contacts' onUpgradeSuccess={handleWivUpgradeSuccess} />
      </div>
    );
  }

  return (
    <PersonInfoStoreProvider initialState={{ personInfo: person, locationId }}>
      <InsuranceDetailsComponent />
    </PersonInfoStoreProvider>
  );
};
