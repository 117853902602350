import { useQuery, useQueries } from 'react-query';
import { Options } from '@frontend/fetch';
import { LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaTenCoordinatorV1Service } from '../service';
import { GetLocationsBrandComplianceIO } from '../types';

export const getGetLocationsBrandComplianceQueryKey = (req: GetLocationsBrandComplianceIO['input']) =>
  ['TenCoordinatorV1', 'GetLocationsBrandCompliance', req] as const;
type QueryKey = ReturnType<typeof getGetLocationsBrandComplianceQueryKey>;

/**
 * A hook that returns a query for the `GetLocationsBrandCompliance` endpoint.
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetLocationsBrandComplianceQuery = <E = unknown, D = GetLocationsBrandComplianceIO['output']>(
  req: GetLocationsBrandComplianceIO['input'],
  options?: LimitedSchemaQueryOptions<GetLocationsBrandComplianceIO, E, D, QueryKey>,
  httpOptions?: Options
) =>
  useQuery<GetLocationsBrandComplianceIO['output'], E, D, QueryKey>({
    queryKey: getGetLocationsBrandComplianceQueryKey(req),
    queryFn: () => SchemaTenCoordinatorV1Service.GetLocationsBrandCompliance(req, httpOptions),
    ...options,
  });

/**
 * A hook that returns an array of queries for the `GetLocationsBrandCompliance` endpoint.
 * It takes an array of objects with the following properties:
 * @param req The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 */
export const useGetLocationsBrandComplianceQueries = <E = unknown, D = GetLocationsBrandComplianceIO['output']>(
  argsArr: {
    req: GetLocationsBrandComplianceIO['input'];
    options?: LimitedSchemaQueryOptions<GetLocationsBrandComplianceIO, E, D, QueryKey>;
    httpOptions?: Options;
  }[]
) =>
  useQueries(
    argsArr.map(({ req, options, httpOptions }) => ({
      queryKey: getGetLocationsBrandComplianceQueryKey(req),
      queryFn: () => SchemaTenCoordinatorV1Service.GetLocationsBrandCompliance(req, httpOptions),
      ...options,
    }))
  ) as ReturnType<typeof useGetLocationsBrandComplianceQuery<E, D>>[];
