import { useCallback } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { useStickyNonReactiveStore, useStickyNoteStore } from '../providers';

type DraggableAreaProps = {
  handleDragEnd: () => void;
  handlePointerDownOnHandle: (e: React.PointerEvent<HTMLDivElement>) => void;
};

export const DraggableArea = ({ handleDragEnd, handlePointerDownOnHandle }: DraggableAreaProps) => {
  const { noteText, setIsHovering, isDragging } = useStickyNoteStore(['noteText', 'setIsHovering', 'isDragging']);
  const stickyStore = useStickyNonReactiveStore();
  const stickyHasText = noteText.length > 0;

  const onMouseLeave = useCallback(() => {
    setIsHovering(false);
  }, [setIsHovering]);

  const onPointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (!stickyHasText) return;
      handlePointerDownOnHandle(e);
    },
    [stickyHasText, handlePointerDownOnHandle]
  );

  const onMouseEnter = useCallback(() => {
    if (!stickyHasText) return;
    if (!isDragging) {
      setIsHovering(true);
    }
  }, [stickyHasText, setIsHovering, stickyStore, isDragging]);

  return (
    <div
      onPointerDown={onPointerDown}
      onPointerUp={handleDragEnd}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      css={draggableAreaStyle(stickyHasText, isDragging)}
    />
  );
};

const draggableAreaStyle = (hasText: boolean, isDragging: boolean) => [
  css`
    z-index: ${theme.zIndex.highest};
    position: absolute;
    width: 40px;
    height: 40px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  hasText &&
    css`
      cursor: grab;
    `,
  isDragging &&
    css`
      cursor: grabbing;
    `,
];
