import { FC, useEffect } from 'react';
import { GlobalBannerDynamic } from '@frontend/global-info-banner';
import { BillingAlertModal } from '../components';
import { useBillingAlerts } from '../hooks/useBillingAlerts';

export const BillingAlertGlobalBanner: FC = () => {
  const { isLoading, billingAlertRender, isTerminating, billingAlertModalProps, openBillingAlertModal } =
    useBillingAlerts();

  useEffect(() => {
    if (!!billingAlertRender) {
      openBillingAlertModal();
    }
  }, [billingAlertRender]);

  if (isLoading || !billingAlertRender) {
    return null;
  }

  return (
    <>
      <GlobalBannerDynamic
        type='error'
        id='billing-alerts-banner'
        message={billingAlertRender}
        isDismissible={false}
        title=''
      />
      <BillingAlertModal
        billingAlertMessageContent={billingAlertRender}
        isTerminating={isTerminating}
        primaryTrackingId='billing-alerts-modal-close-primary-btn'
        {...billingAlertModalProps}
      />
    </>
  );
};
