import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useIsMulti } from '../../../hooks/use-is-multi';
import { queryKeys as AnalyticsQueryKeys } from '../../../query-keys';
import { ApiPayload } from './shared-types';
import { useHasMCTRoiFeature } from './use-has-mct-roi-feature';

type ApiParams<Request extends object> = Request & {
  payload: ApiPayload;
};

type Props<Request extends object, Response> = {
  api: (args: ApiParams<Request>) => Promise<Response>;
  apiParams: ApiParams<Request>;
  errorMessage: string;
  isEnabled?: boolean;
  uniqueId: string;
};

export const useFetchRoiData = <Request extends object, Response>({
  api,
  apiParams,
  errorMessage,
  isEnabled = true,
  uniqueId,
}: Props<Request, Response>) => {
  const { payload, ...rest } = apiParams;
  const alert = useAlert();
  const { isMCTRoiEligibleLocation } = useHasMCTRoiFeature();
  const { isMulti, parentId: orgId } = useIsMulti();
  const stringifiedRest = JSON.stringify(rest || {});

  const isApiEnabled =
    isEnabled &&
    isMCTRoiEligibleLocation &&
    !!payload.location_id?.length &&
    !!payload.start_date &&
    !!payload.end_date &&
    !!orgId;

  const queryKey = useMemo(() => {
    const startDate = dayjs(payload.start_date).format('YYYY-MM-DD');
    const endDate = dayjs(payload.end_date).format('YYYY-MM-DD');

    const filtersString = JSON.stringify({
      ...payload,
      start_date: startDate,
      end_date: endDate,
    });

    return AnalyticsQueryKeys.roiCharts(`aggregated-${uniqueId}-${filtersString}-${stringifiedRest}`);
  }, [payload, stringifiedRest, uniqueId]);

  const { data, isLoading } = useScopedQuery({
    queryKey,
    queryFn: () =>
      api({
        ...apiParams,
        payload: {
          ...payload,
          start_date: payload.start_date ? `${dayjs(payload.start_date).format('YYYY-MM-DD')}T00:00:00.000` : '',
          end_date: payload.end_date ? `${dayjs(payload.end_date).format('YYYY-MM-DD')}T23:59:59.999` : '',
          org_id: orgId,
          is_multi: isMulti,
        },
      }),
    onError: () => {
      alert.error(errorMessage);
    },
    enabled: isApiEnabled,
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  return {
    data,
    isLoading,
  };
};
