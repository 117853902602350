import { CallRoute } from '@weave/schema-gen-ts/dist/schemas/phone/callroute/beta/v1/callroute_service.pb';
import { PhoneNumber } from '../../views/settings/types';

//  The phone numbers that are not allocated to any call route for a specific tenant.
export const getUnallocatedPhoneNumbers = (
  allPhoneNumbers: PhoneNumber[],
  callRoutes: CallRoute[],
  tenantId: string
) => {
  if (!allPhoneNumbers || !callRoutes || !tenantId) {
    return [];
  }

  // Phone number ids for all phone numbers that currently used in call routes.
  const allocatedPhoneNumbersIds = (callRoutes ?? []).reduce((acc, route) => {
    return [...acc, ...(route.phoneNumberIds ?? [])];
  }, [] as string[]);

  const unallocatedPhoneNumbers = allPhoneNumbers.filter(
    (phoneNumber) => !allocatedPhoneNumbersIds.includes(phoneNumber.id) && phoneNumber.tenantId === tenantId
  );

  return unallocatedPhoneNumbers;
};
