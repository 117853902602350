import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CustomizationFlagQueries, CustomizationFlagTypes } from '@frontend/api-customization-flags';
import { getUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useIsCallIntelEligibleAccount } from '../components/call-intelligence/hooks';

export const useShowCallIntelNav = () => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();

  const { isCallIntelEligibleAccount, isLoading: isElligibilityLoading } = useIsCallIntelEligibleAccount();

  const { data: locationWiseCustomizationFlags, isLoading } =
    CustomizationFlagQueries.useMultiCustomizationFlags(selectedLocationIdsWithParents);

  const isCallIntelActive = useMemo(() => {
    if (!selectedLocationIdsWithParents || !locationWiseCustomizationFlags?.locationsFeatures) {
      return false;
    }

    return Object.keys(locationWiseCustomizationFlags.locationsFeatures ?? {}).some((locationId) =>
      locationWiseCustomizationFlags.locationsFeatures?.[locationId]?.features?.some(
        (feature) =>
          (feature.featureEnum === Feature.CALL_INTELLIGENCE &&
            (feature.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
              feature.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE)) ||
          feature.isFreeTrial === true
      )
    );
  }, [locationWiseCustomizationFlags, selectedLocationIdsWithParents]);

  const isWeaveDemoUser = getUser()?.username.toLowerCase().includes('@demoweave') || false;

  if (isElligibilityLoading && isLoading) {
    return false;
  }

  return isCallIntelActive || isCallIntelEligibleAccount || isWeaveDemoUser;
};
