import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { isPhoneNumber } from '@frontend/phone-numbers';

export type ContactWithError = Contact & {
  errors?: (ErrorMessages | undefined)[];
};

export type ReducerState = ContactWithError[];

type ErrorMessages = 'contact-name' | `missing-label-${string}` | `missing-number-${string}`;
export type ReducerDispatchAction = {
  type:
    | 'UPDATE_NUMBER'
    | 'UPDATE_LABEL'
    | 'UPDATE_CONTACT_NAME'
    | 'UPDATE_NOTE'
    | 'ADD_NEW_CONTACT'
    | 'ADD_NEW_NUMBER'
    | 'REMOVE_NUMBER'
    | 'REMOVE_CONTACT';
  payload: Contact &
    PhoneNumberType & {
      error?: ErrorMessages;
    };
};

type PhoneNumberType = {
  phoneNumberIndex?: string;
  phoneNumberLabel?: string;
  phoneNumberNumber?: string;
};

export const useContactDetailsReducer = (state: ReducerState, action: ReducerDispatchAction) => {
  switch (action.type) {
    case 'ADD_NEW_CONTACT': {
      const copyState = [...state];

      copyState.push({
        id: action.payload?.id,
        name: '',
        notes: '',
        phoneNumbers: [{ label: 'Main', number: '', id: '0' }],
        errors: ['contact-name', 'missing-number-0'],
      });

      return copyState;
    }
    case 'UPDATE_NOTE':
      return state.map((c) => {
        if (c.id === action.payload?.id) {
          return {
            ...c,
            notes: action.payload?.notes ?? '',
          };
        }
        return c;
      });
    case 'UPDATE_CONTACT_NAME': {
      const a = state.map((c) => {
        if (c.id === action.payload?.id) {
          return {
            ...c,
            name: action.payload?.name ?? '',
            errors: !action.payload.name
              ? ([...(c.errors ?? []), 'contact-name'] as ErrorMessages[])
              : c.errors?.includes('contact-name')
              ? c.errors.filter((error) => error !== 'contact-name')
              : [...(c.errors ?? [])],
          };
        }
        return c;
      });
      return a;
    }
    case 'UPDATE_LABEL':
    case 'UPDATE_NUMBER': {
      const copyState = [...state];
      const contactIndex = copyState.findIndex((s) => s.id === action.payload.id);
      const copyContact = { ...copyState[contactIndex] };
      if (copyContact.phoneNumbers && !!action.payload.phoneNumberIndex) {
        copyContact.phoneNumbers[+action.payload.phoneNumberIndex] = {
          id: action.payload.phoneNumberIndex,
          label: action.payload.phoneNumberLabel ?? '',
          number: action.payload.phoneNumberNumber ?? '',
        };
      }

      if (action.type === 'UPDATE_NUMBER') {
        const isPhoneNumberValid =
          !!action.payload.phoneNumberNumber && isPhoneNumber(action.payload.phoneNumberNumber);

        if (isPhoneNumberValid) {
          copyContact.errors = copyContact.errors?.filter(
            (error) => error !== `missing-number-${action.payload.phoneNumberIndex}`
          );
        } else if (
          !!action.payload.phoneNumberIndex &&
          !copyContact.errors?.includes(`missing-number-${action.payload.phoneNumberIndex}`)
        ) {
          copyContact.errors = [...(copyContact.errors ?? []), `missing-number-${action.payload.phoneNumberIndex}`];
        }
      }

      if (action.type === 'UPDATE_LABEL') {
        const isLabel = !!action.payload.phoneNumberLabel;
        if (isLabel) {
          copyContact.errors = copyContact.errors?.filter(
            (error) => error !== `missing-label-${action.payload.phoneNumberIndex}`
          );
        } else if (
          !!action.payload.phoneNumberIndex &&
          !copyContact.errors?.includes(`missing-label-${action.payload.phoneNumberIndex}`)
        ) {
          copyContact.errors = [...(copyContact.errors ?? []), `missing-label-${action.payload.phoneNumberIndex}`];
        }
      }

      copyState[contactIndex] = copyContact;

      return copyState;
    }
    case 'ADD_NEW_NUMBER': {
      const copyState = [...state];
      const contactIndex = copyState.findIndex((s) => s.id === action.payload.id);
      const phoneNumbersLength = copyState[contactIndex]?.phoneNumbers?.length ?? 0;

      if (contactIndex !== -1) {
        const updatedContact = {
          ...copyState[contactIndex],
          phoneNumbers: [
            ...(copyState[contactIndex]?.phoneNumbers ?? []),
            { label: 'Main', number: '', id: `${phoneNumbersLength}` },
          ],
          errors: [...(copyState[contactIndex]?.errors ?? []), `missing-number-${phoneNumbersLength}` as ErrorMessages],
        };

        copyState[contactIndex] = updatedContact;
      }

      return copyState;
    }
    case 'REMOVE_NUMBER': {
      const copyState = [...state];
      const contactIndex = copyState.findIndex((s) => s.id === action.payload.id);
      const { phoneNumberIndex } = action.payload;

      if (contactIndex !== -1) {
        const updatedContact = {
          ...copyState[contactIndex],
          phoneNumbers: copyState[contactIndex].phoneNumbers
            ?.filter((num) => num.id !== phoneNumberIndex)
            .map((number, index) => {
              return {
                ...number,
                id: index.toString(),
              };
            }),
          errors: copyState[contactIndex]?.errors?.filter((error) => error !== `missing-number-${phoneNumberIndex}`),
        };

        copyState[contactIndex] = updatedContact;
      }

      return copyState;
    }
    case 'REMOVE_CONTACT': {
      const newState = state.filter((c) => c.id !== action.payload?.id);

      const reorderedState = newState.reduce<ContactWithError[]>((acc, currentKey, index) => {
        return [
          ...acc,
          {
            ...currentKey,
            id: index.toString(),
          },
        ];
      }, []);

      return reorderedState;
    }
  }
  4;
};
