import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyMinutesClip = lazy(() => import('./svg-components/minutes-clip'));

const MinutesClip = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyMinutesClip {...rest} />
  </Suspense>
);

export default MinutesClip;
