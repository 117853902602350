import { useMemo } from 'react';
import { Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { formatters } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { useIsAppointmentDemoAccount } from '../hooks';
import { AppointmentsChartProps } from './shared-types';

const colors = {
  existingPatient: theme.colors.primary50,
  newPatient: theme.colors.secondary.eggplant60,
  retained: theme.colors.secondary.seaweed30,
};

const labels = {
  existingPatient: i18next.t('Existing Patients', { ns: 'analytics' }),
  newPatient: i18next.t('New Patients', { ns: 'analytics' }),
  retained: i18next.t('Retained Patients', { ns: 'analytics' }),
};

export const AppointmentsPatientsTypeChart = ({ appearance, data, isLoading }: AppointmentsChartProps) => {
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsAppointmentDemoAccount();

  const patientsTypeVolume = useMemo(() => {
    const totals: Record<string, number> = {};
    const groups = Object.entries(data.patientsTypeCounts).map(([name, value]) => ({
      name,
      values: Object.entries(value).reduce((acc, [name, value]) => {
        totals[name] = (totals[name] ?? 0) + value;
        return {
          ...acc,
          [name]: value,
        };
      }, {}),
    }));

    return {
      chartData: {
        groups,
      },
      totals,
    };
  }, [data.patientsTypeCounts]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Patient Type')}
        trackingIdHelper='patient-type'
      />
      <Chart.BarChart
        appearance={appearance}
        data={patientsTypeVolume.chartData}
        formatValue={formatters.value.format}
      />
      <Chart.Legends formatValue={formatters.value.format} values={patientsTypeVolume.totals} />
    </Chart>
  );
};
