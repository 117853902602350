import { memo, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { AreaChartData, Chart, sequentialChartColors } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { formatters, getHourlyInsightsLabel } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { DropDownSelector } from '../../filter-selectors';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedHourlyInsights, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

type HourlyInsightsSelectorProps = {
  hourlyInsightsCallTypeOptions: { id: string; label: string; level: number }[];
  selectedHourlyCallsInsights: PhoneAnalyticsTypes.HourlyInsightsAggregationKey;
  setSelectedHourlyCallsInsights: (value: PhoneAnalyticsTypes.HourlyInsightsAggregationKey) => void;
  total?: number;
};

type DropdownOption = {
  id: PhoneAnalyticsTypes.HourlyInsightsAggregationKey;
  label: string;
  level: number;
};

const hourlyInsightsCallTypeOptions: DropdownOption[] = [
  { id: 'total_calls', label: i18next.t('Total Calls', { ns: 'analytics' }), level: 1 },
  { id: 'total_inbound_calls', label: i18next.t('Incoming', { ns: 'analytics' }), level: 1 },
  { id: 'total_missed_incoming_calls', label: i18next.t('Missed', { ns: 'analytics' }), level: 2 },
  { id: 'total_missed_incoming_calls_open', label: i18next.t('Open Office', { ns: 'analytics' }), level: 3 },
  { id: 'total_missed_incoming_calls_closed', label: i18next.t('Closed Office', { ns: 'analytics' }), level: 3 },
  { id: 'total_incoming_answered_calls', label: i18next.t('Answered', { ns: 'analytics' }), level: 2 },
  { id: 'total_incoming_abandoned_calls', label: i18next.t('Abandoned', { ns: 'analytics' }), level: 2 },
  { id: 'total_outbound_calls', label: i18next.t('Outgoing', { ns: 'analytics' }), level: 1 },
  { id: 'total_outbound_answered_calls', label: i18next.t('Answered', { ns: 'analytics' }), level: 2 },
  { id: 'total_outbound_abandoned_calls', label: i18next.t('Abandoned', { ns: 'analytics' }), level: 2 },
];

const HourlyInsightsSelector = memo(
  ({
    hourlyInsightsCallTypeOptions,
    selectedHourlyCallsInsights,
    setSelectedHourlyCallsInsights,
    total,
  }: HourlyInsightsSelectorProps) => {
    const { t } = useTranslation('analytics');

    return (
      <div className='no-pdf' css={styles.hourlyInsightsWrapper}>
        <Text className='totals'>{t('Total Calls: {{total}}', { total })}</Text>
        <DropDownSelector
          label={t('Call Type')}
          onChange={(value) =>
            value && setSelectedHourlyCallsInsights(value as PhoneAnalyticsTypes.HourlyInsightsAggregationKey)
          }
          options={hourlyInsightsCallTypeOptions}
          value={selectedHourlyCallsInsights}
        />
      </div>
    );
  }
);

export const HourlyInsightsChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });
  const { data, isLoading } = useFetchAggregatedHourlyInsights();
  const [selectedHourlyCallsInsights, setSelectedHourlyCallsInsights] =
    useState<PhoneAnalyticsTypes.HourlyInsightsAggregationKey>('total_calls');

  const processedData = useMemo(() => {
    const outputData: {
      chartData: Partial<Record<PhoneAnalyticsTypes.HourlyInsightsAggregationKey, AreaChartData>>;
      colors: Record<string, string>;
      labels: Record<string, string>;
      totals: Partial<Record<PhoneAnalyticsTypes.HourlyInsightsAggregationKey, number>>;
    } = {
      chartData: {},
      colors: {},
      labels: {},
      totals: {},
    };

    Object.entries(data?.locations || {}).forEach(([locationId, locationData]) => {
      const name = locationNames[locationId] || locationId;
      outputData.colors[name] = sequentialChartColors[Object.keys(outputData.colors).length] || theme.colors.primary40;
      outputData.labels[name] = name;

      Object.entries(locationData).forEach(([key, { hourly = [], total = 0 }]) => {
        const typedKey = key as PhoneAnalyticsTypes.HourlyInsightsAggregationKey;

        if (!outputData.chartData[typedKey]) {
          outputData.chartData[typedKey] = {
            groups: Array.from({ length: hourly.length }, (_, i) => ({
              name: getHourlyInsightsLabel(i),
              values: {},
            })),
          };
        }

        const chartGroup = outputData.chartData[typedKey]?.groups;

        hourly.forEach((count, index) => {
          if (chartGroup) {
            chartGroup[index].values[name] = count;
          }
        });

        outputData.totals[typedKey] = (outputData.totals[typedKey] || 0) + total;
      });
    });

    return outputData;
  }, [data, locationNames]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={processedData.colors} isLoading={isLoading} labels={processedData.labels}>
      <Chart.Header
        allowExportToPdf
        bottomElement={
          <HourlyInsightsSelector
            hourlyInsightsCallTypeOptions={hourlyInsightsCallTypeOptions}
            selectedHourlyCallsInsights={selectedHourlyCallsInsights}
            setSelectedHourlyCallsInsights={setSelectedHourlyCallsInsights}
            total={processedData?.totals?.[selectedHourlyCallsInsights] || 0}
          />
        }
        infoTip={
          <InfoTipPopover>
            {t(
              'It shows the hour-wise spread of call volume during the day. Data is grouped as per the local time at the location when the call occurred.'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        pdfDetails={[
          {
            label: t('Total Calls'),
            value: formatters.value.format(processedData?.totals?.[selectedHourlyCallsInsights] || 0),
          },
          ...exportPdfProps.pdfDetails,
        ]}
        subtitle={filterHintText}
        title={t('Hourly Insights')}
      />
      {(filters.location_id || []).length > 1 && <Chart.Legends />}
      <Chart.AreaChart
        appearance={defaultChartAppearance}
        data={processedData?.chartData?.[selectedHourlyCallsInsights] || { groups: [] }}
      />
    </Chart>
  );
};

HourlyInsightsSelector.displayName = 'HourlyInsightsSelector';

const styles = {
  hourlyInsightsWrapper: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: ${theme.spacing(1, 0)};

    .totals {
      font-weight: ${theme.font.weight.semibold};
    }
  `,
};
