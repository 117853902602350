import { useQuery } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonAPI, PersonTypes } from '@frontend/api-person';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import {
  Heading,
  Listbox,
  PopoverDialog,
  SearchField,
  Text,
  useDebouncedValue,
  useFormField,
  useListboxState,
  usePopoverDialog,
} from '@frontend/design-system';
import { UserIconContainer } from './user-icon-container';

type UserSelectorProps = {
  label: string;
  placeHolder: string;
  setSelected: (person: PersonTypes.Person) => void;
  value?: string;
};
const API_RESPONSE_LIMIT = 50;
export const UserSelector = ({ label, placeHolder, setSelected, value }: UserSelectorProps) => {
  const { locationId } = useMultiQueryUtils();
  const listboxProps = useListboxState('');
  const searchField = useFormField({ type: 'text' });
  const searchValue = useDebouncedValue(searchField.value, API_RESPONSE_LIMIT);
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');
  const { getTriggerProps, getDialogProps, close } = usePopoverDialog<HTMLElement>({
    placement: 'bottom-start',
  });

  const personsQuery = useQuery({
    queryKey: [locationId, 'payment-plan-person', searchValue, ...demoSourceIds],
    queryFn: () =>
      PersonAPI.getPersonsV2({
        order: ['last_name', 'first_name'],
        status: 'Active',
        query: searchValue,
        locationId,
        dataSourceIds: demoSourceIds,
      }),
    enabled: !!searchValue,
  });

  const fullName = (patient: PersonTypes.Person) => `${patient?.FirstName ?? ''} ${patient?.LastName ?? ''}`;

  return (
    <>
      <div
        css={{
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.colors.neutral5,
          },
        }}
        {...getTriggerProps()}
      >
        <Heading level={3} css={{ color: theme.font.colors.subdued }}>
          {label}
        </Heading>
        <div
          css={{
            display: 'flex',
            gap: theme.spacing(1),
            marginTop: theme.spacing(1),
            marginLeft: theme.spacing(1),
          }}
        >
          {!value && <UserIconContainer width={32} height={32} />}
          <Heading
            level={3}
            css={{
              color: value ? theme.font.colors.default : theme.font.colors.subdued,
              borderBottom: value ? `1px solid ${theme.colors.neutral20}` : 'none',
            }}
          >
            {value || placeHolder}
          </Heading>
        </div>
      </div>
      <PopoverDialog {...getDialogProps()} returnFocus={false}>
        <SearchField
          name='search-user'
          {...searchField}
          onChange={(e) => {
            searchField.onChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        <Listbox
          {...listboxProps}
          onSelect={(selectedValue) => {
            listboxProps.onSelect(selectedValue);
            const person = personsQuery.data?.rows.find((person) => person.PersonID === selectedValue);
            if (person) setSelected(person);
            close();
          }}
          css={{ marginTop: theme.spacing(1) }}
        >
          {personsQuery.isLoading ? (
            <Listbox.Option key='loading' disabled value='loading'>
              Loading...
            </Listbox.Option>
          ) : null}
          {personsQuery.data?.rows.map((person) => (
            <Listbox.Option key={person.PersonID} value={person.PersonID}>
              <div
                css={{
                  display: 'flex',
                  gap: theme.spacing(2),
                }}
              >
                <Photos.ContactProfilePhoto
                  personId={person.PersonID}
                  name={fullName(person)}
                  size='small'
                  disableClick
                />
                <Text>{fullName(person)}</Text>
              </div>
            </Listbox.Option>
          ))}
        </Listbox>
      </PopoverDialog>
    </>
  );
};
