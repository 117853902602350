import { ReactNode, Ref, forwardRef } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useLineKeyContext } from '../store/line-key-provider';
import { cardIndexStyle } from './card';

type Props = ReturnType<typeof useSortable> & {
  overlay?: boolean;
  children: ReactNode;
  cardId: string;
  error: boolean;
};

export const CardDragDrop = forwardRef(
  ({ cardId, error, overlay, children, ...rest }: Props, ref: Ref<HTMLDivElement>) => {
    const { transform, transition, index, newIndex } = rest;
    const { maxKeys } = useLineKeyContext();

    const style: React.CSSProperties = {
      transform: CSS.Translate.toString(transform),
      transition,
      display: 'flex',
      zIndex: overlay ? `${theme.zIndex.middle}` : `${theme.zIndex.low}`,
    };

    return (
      <motion.div style={style} ref={ref}>
        {!overlay && (
          <Text
            weight='bold'
            css={css`
              ${cardIndexStyle};
              color: ${newIndex + 1 > maxKeys ? theme.colors.warning80 : error ? theme.colors.critical50 : ''};
            `}
          >
            {index > -1 && newIndex + 1}
          </Text>
        )}
        {children}
      </motion.div>
    );
  }
);
