import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, TextField, useFormField } from '@frontend/design-system';
import { ReducerDispatchAction, ContactWithError } from '../hooks/use-contact-details-reducer';
import { ContactTextField } from './fields';

type Props = {
  contact: ContactWithError;
  onDispatch: (action: ReducerDispatchAction) => void;
  showBorder?: boolean;
  hasError?: boolean;
};

export const BusinessFields = ({ onDispatch, contact, showBorder, hasError }: Props) => {
  const { t } = useTranslation('phone');
  const notes = contact.notes;
  const [showNote, setShowNote] = useState(!!notes);
  const nameFieldProps = useFormField(
    {
      type: 'text',
      value: contact.name,
      required: true,
      validateOnChange: true,
    },
    []
  );
  const noteFieldProps = useFormField({ type: 'text', value: contact.notes }, []);

  const onRemoveNote = () => {
    setShowNote(false);
  };

  return (
    <div
      css={[
        styles,
        css`
          ${showBorder &&
          `
            border: 1px solid ${hasError ? theme.colors.status.critical : theme.colors.neutral20};
            padding: ${theme.spacing(2)};
            border-radius: ${theme.borderRadius.medium};
            `}
        `,
      ]}
    >
      <TextField
        containerCss={css`
          width: 100%;
        `}
        name='name-field'
        label={t('Name')}
        {...nameFieldProps}
        onChange={(e) => {
          nameFieldProps.onChange(e);
          onDispatch({
            type: 'UPDATE_CONTACT_NAME',
            payload: {
              id: contact.id,
              // @ts-ignore design system types
              name: e.target.value,
            },
          });
        }}
      />
      {contact.phoneNumbers?.map((number, i) => {
        const removeable = i !== 0;
        return (
          <ContactTextField
            contactId={contact.id}
            contactName={contact.name}
            key={number.id}
            index={`${i}`}
            onDispatch={onDispatch}
            removeable={removeable}
            label={number.label}
            number={number.number}
          />
        );
      })}
      <Button
        variant='tertiary'
        onClick={() => {
          onDispatch({
            type: 'ADD_NEW_NUMBER',
            payload: {
              id: contact.id,
              name: contact.name,
            },
          });
        }}
        iconName='add'
      >
        {t('Add Phone Number')}
      </Button>
      {showNote && (
        <div
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            width: '100%',
          }}
        >
          <Button
            destructive
            onClick={onRemoveNote}
            variant='secondary'
            css={{ minWidth: theme.spacing(5) }}
            iconName='remove-small'
          />
          <TextField
            containerCss={css`
              width: 100%;
            `}
            {...noteFieldProps}
            label={t('Note')}
            name='notes'
            onChange={(e) => {
              noteFieldProps.onChange(e);
              onDispatch({
                type: 'UPDATE_NOTE',
                payload: {
                  id: contact.id,
                  name: contact.name,
                  // @ts-ignore design system types
                  notes: e.target.value,
                },
              });
            }}
          />
        </div>
      )}
      <Button variant='tertiary' onClick={() => setShowNote(true)} iconName='add' disabled={showNote}>
        {t('Add Note')}
      </Button>
    </div>
  );
};

const styles = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
  align-items: start;
  width: 100%;
`;
