import { useIsIntegratedOffice, useIsMulti } from '../../../hooks';

export const useHasAppointmentAnalyticsFeature = () => {
  const { isIntegratedOffice } = useIsIntegratedOffice();
  const { isMulti } = useIsMulti();

  if (isMulti) {
    // Appointment analytics is not supported for multi-location yet
    return false;
  }

  return !!isIntegratedOffice;
};
