import { useCallback, useEffect, useRef, useState } from 'react';
import { isFunction } from 'lodash-es';
import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { PickPartial, Prettify } from '@frontend/types';
import { ITerminalStrategyError, PaymentsTerminalError, StoredReader, TerminalPaymentStatus } from '..';
import { discoverReaders } from '../server-driven-strategy/discover-readers-api';
import { createTerminalSession } from '../terminal-session';
import { storeReader } from '../utils';
import { useCanceledIntentRef } from './';

export enum ReaderConnectErrors {
  alreadyInUse = 'Reader is currently in use.',
}

type PaymentIntentInfo = { clientSecret: string; id: string };

type TerminalReader = Awaited<ReturnType<typeof discoverReaders>>[number];

const delay = (timeInMs: number) => new Promise((resolve) => setTimeout(resolve, timeInMs));

type UseTerminalPaymentSessionProps = Prettify<
  PickPartial<
    Omit<Parameters<typeof createTerminalSession>[number], 'onError' | 'onPaymentStatusChange' | 'enableServerDriven'>,
    'reader' | 'paymentsUrl' | 'sdkFailIfReaderInUse'
  >
> & {
  onPaymentSuccess?: () => void;
  enableServerDriven: boolean | undefined;
  invoiceId: string | undefined;
  paymentIntent: PaymentIntentInfo | undefined;
  paymentId: string | undefined;
  goBack: () => void;
  retryCreatePaymentIntent: () => Promise<{
    paymentIntent: PaymentIntentInfo;
    paymentId: string;
  }>;
  discoverReaders: () => Promise<TerminalReader[]>;
};
type TerminalActionsResponse = Awaited<ReturnType<typeof createTerminalSession>>;
type TerminalActions = TerminalActionsResponse & {
  readerId: string | undefined;
};

type CollectAndProcessPaymentArgs = Parameters<
  Awaited<ReturnType<typeof createTerminalSession>>['collectAndProcessPayment']
>[0] & {
  actions?: TerminalActions;
  isRetryAction?: boolean;
};

type RetryPaymentOptions = {
  paymentIntent: CollectAndProcessPaymentArgs['paymentIntent'];
  paymentId: CollectAndProcessPaymentArgs['paymentId'];
  invoiceId: CollectAndProcessPaymentArgs['invoiceId'];
  failIfReaderInUse: boolean;
  selectedReader: StoredReader;
};

type OptionalProps<T> = Partial<Record<keyof T, undefined>>;
type TightProps<T> = T | OptionalProps<T>;
type ReaderPaymentRetryHandlerOptions = TightProps<{
  selectedReader: StoredReader | undefined;
  failIfReaderInUse: boolean;
}>;

export type ReaderPaymentRetryHandler = (options?: ReaderPaymentRetryHandlerOptions) => void;

type CancelPreviousServerPaymentArgs = {
  failIfReaderInUse: boolean;
  discoveredServerReader: TerminalReader;
  paymentIntent: PaymentIntentInfo;
};

export const RESTRICTED_TERMINAL_STATES: TerminalPaymentStatus[] = ['success', 'processing'];

/**
 * This hook is used to manage the terminal payment session of both the server-driven and sdk strategies.
 *
 * See [Terminal Payments Flowchart]{@link https://drive.google.com/file/d/1ZHd1HElTDGwqnVPENfrwkXSXqouKHArO/view?usp=sharing}
 */
export const useTerminalPaymentSession = ({
  reader,
  paymentsUrl,
  locationId,
  appData,
  onPaymentSuccess,
  enableServerDriven,
  paymentIntent,
  paymentId,
  invoiceId,
  goBack,
  retryCreatePaymentIntent,
  discoverReaders,
}: UseTerminalPaymentSessionProps) => {
  const [terminalActions, setTerminalActions] = useState<TerminalActions>();
  const [error, setError] = useState<ITerminalStrategyError>();
  const [status, setStatus] = useState<TerminalPaymentStatus>('not_ready');
  // to ensure only one terminal payment happens, set to false to allow retries
  const isCollectingPayment = useRef(false);
  const {
    setCancelationCompleted,
    setCancelationStarted,
    isIntentCanceled,
    isIntentCancelationCompleted,
    cancelledPaymentIntent,
  } = useCanceledIntentRef();

  const mounted = useRef(true);
  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  const collectionStartedReader = useRef<string>();
  // reset the collectionStartedReader when the reader changes
  useEffect(() => {
    if (reader && collectionStartedReader.current !== reader.readerId) collectionStartedReader.current = undefined;
  }, [reader]);

  const initializePaymentSession = useCallback(
    async (selectedReader = reader, sdkFailIfReaderInUse = true) => {
      let initializedActions: TerminalActions | undefined;
      let initializationError: ITerminalStrategyError | undefined;
      const defaultResult = { initializedActions, initializationError };

      if (RESTRICTED_TERMINAL_STATES.includes(status)) return defaultResult;
      setTerminalActions(undefined);
      setStatus('not_ready');
      if (!selectedReader || !paymentsUrl || !locationId || !appData) return defaultResult;

      try {
        // If terminalActions is already set, cancel the current action
        if (isCollectingPayment.current) {
          await cancelPayment(selectedReader.readerId, paymentIntent?.id);
        }

        const newActions = await createTerminalSession({
          locationId,
          paymentsUrl,
          reader: selectedReader,
          appData,
          enableServerDriven,
          onPaymentStatusChange(status) {
            if (!mounted.current) return;
            setStatus(status);

            if (status === 'success') {
              onPaymentSuccess?.();
            }
          },
          sdkFailIfReaderInUse,
        });
        initializedActions = { ...newActions, readerId: selectedReader.readerId };
        setTerminalActions(initializedActions);
      } catch (error) {
        if (error instanceof PaymentsTerminalError) {
          initializationError = { action: 'initialize', message: error.message };
        } else {
          initializationError = {
            action: 'initialize',
            message: 'An unexpected error occurred',
          };
        }
        setStatus('not_ready');
        setError(initializationError);
      }

      return { initializedActions, initializationError };
    },
    [reader, paymentsUrl, locationId, enableServerDriven, appData]
  );

  useEffect(() => {
    initializePaymentSession();
  }, [initializePaymentSession]);

  const cancelPreviousServerPayment = useCallback(
    async ({ failIfReaderInUse, discoveredServerReader, paymentIntent }: CancelPreviousServerPaymentArgs) => {
      const readerPaymentIntentId = discoveredServerReader.action?.ProcessPaymentIntent?.id;
      const hasRequiredValues = paymentsUrl && locationId && readerPaymentIntentId;
      const isCancelingCurrentIntent = readerPaymentIntentId === paymentIntent?.id;
      if (
        hasRequiredValues &&
        discoveredServerReader.action?.status === 'in_progress' &&
        !isCancelingCurrentIntent &&
        !isIntentCanceled(readerPaymentIntentId)
      ) {
        if (!failIfReaderInUse) {
          try {
            //as this cancelation is awaited in the flow itself, we don't have to store a reference of the promise on a ref
            await PaymentsTerminalApi.cancelTerminalAction({
              readerId: discoveredServerReader.id,
              paymentIntentId: readerPaymentIntentId || '',
              paymentsUrl,
              locationId,
            });

            await delay(500);
          } catch (err) {
            console.log('Error in cancelPreviousPayment', err);
          }
        } else {
          throw new PaymentsTerminalError('collect', ReaderConnectErrors.alreadyInUse);
        }
      }
    },
    [paymentsUrl, locationId]
  );

  const collectAndProcessPayment = useCallback(
    async ({
      paymentIntent,
      paymentId,
      invoiceId,
      actions,
      //failIfReaderInUse will be false only when overriding existing payments
      failIfReaderInUse = true,
      isRetryAction = false,
    }: CollectAndProcessPaymentArgs) => {
      // Check needs to be here to prevent duplicate collects from running
      if (isCollectingPayment.current) {
        return;
      }

      actions = actions ?? terminalActions;
      if ((!isRetryAction && error) || !actions || !actions.readerId) {
        setStatus('not_ready');
        setError({ action: 'collect', message: 'Terminal session not initialized' });
        return;
      }

      try {
        collectionStartedReader.current = actions?.readerId;

        if (!isIntentCancelationCompleted(actions?.readerId, paymentIntent?.id)) {
          await cancelledPaymentIntent.current?.cancelationPromise;
        }
        if (actions?.usingServerDrivenFlow) {
          const readers = await discoverReaders();
          const discoveredServerReader = readers.find((reader) => reader.id === actions?.readerId);
          // we are cancelling here instead of inside the controller because the controller is not aware of the previuos paymentIntent
          // this will only work for the server driven because of the in_progress status check in the cancelPreviousServerPayment
          if (discoveredServerReader) {
            await cancelPreviousServerPayment({
              failIfReaderInUse,
              discoveredServerReader,
              paymentIntent,
            });
          } else {
            throw new PaymentsTerminalError('connect', 'Reader not found');
          }

          if (isFunction(retryCreatePaymentIntent) && isIntentCanceled(paymentIntent.id)) {
            const newPaymentIntentData = await retryCreatePaymentIntent();
            paymentIntent = newPaymentIntentData.paymentIntent;
            paymentId = newPaymentIntentData.paymentId;
          }
        }
        isCollectingPayment.current = true;
        return await actions?.collectAndProcessPayment({
          paymentIntent,
          paymentId,
          invoiceId,
          failIfReaderInUse,
        });
      } catch (error) {
        if (!mounted.current) return;
        isCollectingPayment.current = false;
        setStatus('not_ready');
        if (error instanceof PaymentsTerminalError) {
          if (error.code === 'canceled') return; // good idea?
          setError(error);
        } else if (error instanceof Error) {
          console.log('Error in collectAndProcessPayment', error);
          setError({ action: 'collect', message: error.message });
        } else {
          setError({ action: 'collect', message: 'An unexpected error occurred' });
        }
        return;
      }
    },
    [terminalActions, error, cancelPreviousServerPayment]
  );

  const retryPayment = useCallback(
    async ({ paymentIntent, paymentId, invoiceId, failIfReaderInUse, selectedReader }: RetryPaymentOptions) => {
      setError(undefined);
      isCollectingPayment.current = false;

      let actions = terminalActions;
      const isReaderNotConnectedByActions = actions && selectedReader && actions.readerId !== selectedReader?.readerId;
      if (!actions || isReaderNotConnectedByActions) {
        const { initializedActions, initializationError } = await initializePaymentSession(
          selectedReader,
          failIfReaderInUse
        );
        if (initializationError) return;
        else actions = initializedActions;
      }
      await collectAndProcessPayment({
        paymentIntent,
        paymentId,
        invoiceId,
        actions,
        isRetryAction: true,
        failIfReaderInUse,
      });
    },
    [error, terminalActions, initializePaymentSession, collectAndProcessPayment]
  );

  const cancelPayment = useCallback(
    async (currentReaderId?: string, currentPaymentIntentId?: string) => {
      const readerId = terminalActions?.readerId;
      const paymentIntentId = paymentIntent?.id;
      const isCancelingUninitializedReader =
        currentReaderId &&
        currentPaymentIntentId &&
        readerId === currentReaderId &&
        paymentIntentId === currentPaymentIntentId;
      if (
        !readerId ||
        !terminalActions?.cancelCurrentAction ||
        isCancelingUninitializedReader ||
        !terminalActions ||
        !paymentIntentId ||
        !isCollectingPayment.current ||
        isIntentCanceled(paymentIntentId)
      ) {
        // do nothing because terminal session not created yet
        return;
      }
      setError(undefined);
      setStatus('not_ready');
      const cancelationPromise = terminalActions?.cancelCurrentAction();
      setCancelationStarted(readerId, paymentIntentId, cancelationPromise);
      await cancelationPromise;
      isCollectingPayment.current = false;
      setCancelationCompleted();
    },
    [terminalActions?.readerId, paymentIntent]
  );

  // Cancel payment on unmount
  useEffect(() => {
    const cancelPaymentOnUnmount = () => {
      cancelPayment().catch((error) => {
        if (!mounted.current) return;
        if (error instanceof PaymentsTerminalError) {
          setError(error);
        } else {
          setError({ action: 'cancel', message: 'An unexpected error occurred' });
        }
      });
    };

    window.addEventListener('beforeunload', cancelPaymentOnUnmount);
    return () => {
      window.removeEventListener('beforeunload', cancelPaymentOnUnmount);
      cancelPaymentOnUnmount();
    };
  }, [cancelPayment]);

  // Store reader when successful
  const storeOnce = useRef('');
  useEffect(() => {
    const hasRequiredValues = paymentsUrl && reader && locationId && appData;
    if (!hasRequiredValues || status !== 'success' || storeOnce.current === reader.readerId) {
      return;
    }
    storeReader(locationId, paymentsUrl, reader);
    storeOnce.current = reader.readerId;
  }, [status, locationId, paymentsUrl, reader, appData]);

  // INITIATE COLLECTION
  useEffect(() => {
    const hasRequiredValues = !!paymentIntent && !!paymentId && !!invoiceId;
    const isReaderReady = !!reader && !!terminalActions?.readerId;
    const hasUpdatedReaderInUseEffect = terminalActions?.readerId === reader?.readerId;
    const readerHadItsCollectionStarted = collectionStartedReader.current === reader?.readerId;
    if (
      error ||
      !isReaderReady ||
      !hasRequiredValues ||
      !hasUpdatedReaderInUseEffect ||
      readerHadItsCollectionStarted
    ) {
      return;
    }

    collectAndProcessPayment({
      paymentIntent,
      paymentId,
      invoiceId,
    });
  }, [paymentIntent, paymentId, invoiceId, error, reader, collectAndProcessPayment, terminalActions?.readerId]);

  const handleCancel = async () => {
    try {
      await cancelPayment();
    } catch (error) {
      console.log('Error cancelling payment', { error });
    } finally {
      goBack();
    }
  };

  const handleTryAgain: ReaderPaymentRetryHandler = async ({
    selectedReader = reader,
    failIfReaderInUse = true,
  } = {}) => {
    if (!selectedReader) return;
    try {
      let retryPaymentIntent = paymentIntent;
      let retryPaymentId = paymentId;
      if (!retryPaymentIntent || !retryPaymentId) {
        if (isFunction(retryCreatePaymentIntent)) {
          const newPaymentIntentData = await retryCreatePaymentIntent();
          retryPaymentIntent = newPaymentIntentData.paymentIntent;
          retryPaymentId = newPaymentIntentData.paymentId;
        } else {
          throw new Error('Payment data missing');
        }
      }
      await retryPayment({
        paymentIntent: retryPaymentIntent,
        paymentId: retryPaymentId,
        invoiceId,
        failIfReaderInUse,
        selectedReader,
      });
    } catch (error) {
      console.log('Error retrying payment', { error });
    }
  };

  const paymentSuccessful = status === 'success';

  return {
    error,
    status,
    handleCancel,
    handleTryAgain,
    paymentSuccessful,
  };
};
