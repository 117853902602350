import { useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, IconButton, TextButton } from '@frontend/design-system';
import { useBookingSiteStore } from '../../../../stores';
import { ProviderAvailability } from './provider-availability';
import { ProviderList } from './provider-list';

dayjs.extend(isoWeek);
dayjs.extend(isBetween);

interface Props {
  setAvailableOpenings: (numOfOpenings: number) => void;
}

export function ProviderSelect({ setAvailableOpenings }: Props) {
  const { t } = useTranslation('bookingSite');
  const {
    selectedAppointmentType,
    selectedAppointmentLeadTime,
    weekInView,
    availableProviders,
    selectedProvider,
    clearSelectedProvider,
    setWeekInView,
  } = useBookingSiteStore([
    'selectedAppointmentType',
    'selectedAppointmentLeadTime',
    'weekInView',
    'setWeekInView',
    'availableProviders',
    'selectedProvider',
    'setSelectedProvider',
    'clearSelectedProvider',
  ]);
  const [dateIsDisabled, setDateIsDisabled] = useState(true);

  const weekRangeText = `${dayjs(weekInView).startOf('isoWeek').format('ddd, MMM D')} - ${dayjs(weekInView)
    .endOf('isoWeek')
    .format('ddd, MMM D')}`;

  const nextWeekInView = () => {
    const nextWeekStart = dayjs(weekInView).startOf('isoWeek').add(7, 'day');
    setWeekInView(nextWeekStart.toISOString());
    setDateIsDisabled(false);
  };

  const previousWeekInView = () => {
    const previousWeek = dayjs(weekInView).startOf('isoWeek').subtract(7, 'day');
    const dateCheck = previousWeek.isBefore(selectedAppointmentLeadTime, 'day')
      ? selectedAppointmentLeadTime
      : previousWeek.toISOString();

    const isInWeek = dayjs(selectedAppointmentLeadTime).isBetween(
      formatDate(previousWeek, 'YYYY-MM-DD'),
      dayjs(previousWeek).endOf('isoWeek').format('YYYY-MM-DD'),
      'day',
      '[]'
    );

    if (isInWeek) {
      setWeekInView(dateCheck);
      setDateIsDisabled(false);
    }

    if (previousWeek > dayjs(selectedAppointmentLeadTime)) {
      setWeekInView(dateCheck);
      setDateIsDisabled(false);
    } else {
      setDateIsDisabled(true);
    }
  };

  if (selectedProvider && selectedAppointmentType) {
    return (
      <>
        <TextButton
          css={{
            margin: theme.spacing(2, 0, 4, 0),
          }}
          onClick={() => {
            clearSelectedProvider();
            setAvailableOpenings(0);
          }}
        >
          {'< '}
          {t('Back to provider list')}
        </TextButton>
        <ProviderAvailability setAvailableOpenings={setAvailableOpenings} />
      </>
    );
  }

  if (!availableProviders?.length) {
    return (
      <Text as='h3' color='light' className='left'>
        {t('No Providers Available for your selected appointment type')}
      </Text>
    );
  }

  return (
    <div>
      <Text>
        {t(
          'Browse through the list of providers and check their upcoming appointment availability with just a glance. If you need more details, like specific time slots, just click on their name in the table.'
        )}
      </Text>
      <div>
        <div css={navSectionStyle}>
          <Text as='h3' weight='bold' size='large'>
            {weekRangeText}
          </Text>
          <IconButton label={t('Previous Week')} disabled={dateIsDisabled} onClick={() => previousWeekInView()}>
            <Icon name='caret-left' />
          </IconButton>
          <IconButton label={t('Next Week')} onClick={() => nextWeekInView()}>
            <Icon name='caret-right' />
          </IconButton>
        </div>
        <div css={{ maxHeight: 380, overflow: 'auto' }}>
          <ProviderList />
        </div>
      </div>
    </div>
  );
}

const navSectionStyle = css({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: theme.spacing(2),
  gap: theme.spacing(0.5),
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  h3: {
    margin: 0,
    paddingRight: theme.spacing(1),
    fontSize: theme.fontSize(20),
  },
  button: {
    padding: 0,
    width: 24,
    minWidth: 24,
  },
});
