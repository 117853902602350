import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ButtonBar } from '@frontend/design-system';

type Props = {
  disableNext?: boolean;
  disablePrevious?: boolean;
  onNextClick: () => void;
  onPreviousClick: () => void;
  trackingIdBase?: string;
};

export const NextPreviousButtons = ({
  disableNext,
  disablePrevious,
  onNextClick,
  onPreviousClick,
  trackingIdBase,
}: Props) => {
  const { t } = useTranslation('analytics');

  return (
    <ButtonBar css={styles.wrapper}>
      <Button
        disabled={disablePrevious}
        onClick={onPreviousClick}
        style={disablePrevious ? { color: theme.colors.neutral40 } : undefined}
        trackingId={`${trackingIdBase}-previous-button`}
        variant='secondary'
      >
        {t('Previous')}
      </Button>
      <Button
        disabled={disableNext}
        onClick={onNextClick}
        style={disableNext ? { color: theme.colors.neutral40 } : undefined}
        trackingId={`${trackingIdBase}-previous-next`}
        variant='secondary'
      >
        {t('Next')}
      </Button>
    </ButtonBar>
  );
};

const styles = {
  wrapper: css`
    width: auto;
    padding: 0;
    > button {
      width: auto;
    }
  `,
};
