import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { BASE_URL_ANALYTICS, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useGetChartPayloadAndConfig } from './use-get-chart-payload-and-config';
import { usePhoneAnalyticsShallowStore } from './use-phone-analytics-store';

type DatedData = {
  daily?: number;
  date: string;
  hourly?: number[];
};

type AnswerRate = {
  abandoned?: number;
  answered?: number;
  dates?: DatedData[];
  missed?: number;
  rate?: number;
};

type CallDuration = {
  avg?: number;
  dates?: DatedData[];
  totalLongCalls?: number;
};

type TotalCalls = {
  dates?: DatedData[];
  inbound?: number;
  outbound?: number;
  total?: number;
};

export type CallsSummaryResponse = {
  answerRate: AnswerRate;
  callDuration: CallDuration;
  totalCalls: TotalCalls;
};

type FormattedDates = {
  [date: string]: {
    daily: number;
    hourly: number[];
  };
};

export type CallsSummaryDataState = {
  answerRate?: Omit<AnswerRate, 'dates'> & { dates: FormattedDates };
  callDuration?: Omit<CallDuration, 'dates'> & { dates: FormattedDates };
  totalCalls?: Omit<TotalCalls, 'dates'> & { dates: FormattedDates };
};

type useFetchAggregatedCallsSummary = {
  data?: CallsSummaryDataState;
  isHourlyData?: boolean;
  isLoading?: boolean;
};

const formatDatesData = (dates?: DatedData[]): FormattedDates => {
  return (
    dates?.reduce<FormattedDates>((acc, { date, daily = 0, hourly = [] }) => {
      acc[date] = {
        daily,
        hourly,
      };
      return acc;
    }, {}) || {}
  );
};

const formatData = (data?: CallsSummaryResponse): CallsSummaryDataState => {
  return {
    answerRate: {
      ...(data?.answerRate || {}),
      dates: formatDatesData(data?.answerRate.dates),
    },
    callDuration: {
      ...(data?.callDuration || {}),
      dates: formatDatesData(data?.callDuration.dates),
    },
    totalCalls: {
      ...(data?.totalCalls || {}),
      dates: formatDatesData(data?.totalCalls.dates),
    },
  };
};

const api = async (filters: Partial<PhoneAnalyticsTypes.Filters>) => {
  const { data } = await http.post<{ data: CallsSummaryResponse }>(
    `${BASE_URL_ANALYTICS}/${'phonereports/v2/getcallsummary'}`,
    PhoneAnalyticsApi.getDatesIntercepted(filters)
  );
  return data;
};

export const useFetchAggregatedCallsSummary = (
  customFilters?: PhoneAnalyticsTypes.Filters,
  shouldFetch = true
): useFetchAggregatedCallsSummary => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { demoData, filters, isDemoAccount } = usePhoneAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const [data, setData] = useState<CallsSummaryDataState | undefined>();
  const { applicableFilters, config } = useGetChartPayloadAndConfig(customFilters || filters, 'call-summary');

  const isHourlyData =
    !!applicableFilters.start_date &&
    !!applicableFilters.end_date &&
    dayjs(applicableFilters.start_date).isSame(applicableFilters.end_date, 'day');

  const hasRequiredFilters =
    !!applicableFilters.start_date && !!applicableFilters.end_date && !!applicableFilters.location_id?.length;

  const { data: callsSummary, isLoading } = useScopedQuery({
    ...config,
    queryFn: () => api(applicableFilters),
    onError: () => {
      alert.error(t("Couldn't load the aggregated calls summary. Please try again."));
    },
    enabled: shouldFetch && hasRequiredFilters,
  });

  useEffect(() => {
    const dataToUse = isDemoAccount ? demoData?.chartsData.callsSummary : callsSummary;

    if (!dataToUse) {
      return;
    }
    setData(formatData(dataToUse));
  }, [callsSummary, demoData, isDemoAccount]);

  return {
    data,
    isHourlyData,
    isLoading,
  };
};
