import { useNavigate } from '@tanstack/react-location';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { NEW_CAMPAIGN_ID } from '../constants';
import { BulkEmailEditorView, CampaignsActiveFeature } from '../types';
import { useBulkEmailUrlData } from './use-bulk-email-url-data';

export const useBulkMessagingNavigator = (useCurrentState = false) => {
  const urlData = useBulkEmailUrlData();
  const navigate = useNavigate();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const baseUrl = urlData.isDraft ? '/bulk/drafts' : '/bulk/all-campaigns';

  const generateEmailEditorNavigate = (view: BulkEmailEditorView) => {
    return (campaignId?: string, seedCampaignId?: string, copyAudience = false) => {
      if (seedCampaignId) {
        if (copyAudience) {
          navigate({ to: `${baseUrl}/email/${NEW_CAMPAIGN_ID}/${view}?seedId=${seedCampaignId}&copyAudience=true` });
        } else {
          navigate({ to: `${baseUrl}/email/${NEW_CAMPAIGN_ID}/${view}?seedId=${seedCampaignId}` });
        }
        return;
      }
      if (campaignId) {
        navigate({ to: `${baseUrl}/email/${campaignId}/${view}` });
        return;
      }
      if (useCurrentState) {
        switch (urlData.mode) {
          case 'create':
            navigate({ to: `${baseUrl}/email/${NEW_CAMPAIGN_ID}/${view}` });
            return;
          case 'duplicate':
            if (urlData.copyAudience) {
              navigate({
                to: `${baseUrl}/email/${NEW_CAMPAIGN_ID}/${view}?seedId=${urlData.seedCampaignId}&copyAudience=true`,
              });
            } else {
              navigate({ to: `${baseUrl}/email/${NEW_CAMPAIGN_ID}/${view}?seedId=${urlData.seedCampaignId}` });
            }
            return;
          case 'edit':
            navigate({ to: `${baseUrl}/email/${urlData.campaignId}/${view}` });
            return;
        }
      }
      navigate({ to: `${baseUrl}/email/${NEW_CAMPAIGN_ID}/${view}` });
    };
  };

  return {
    campaigns: (feature: CampaignsActiveFeature = 'email') => {
      navigate({ to: `${baseUrl}/${feature}` });
    },
    drafts: () => {
      navigate({ to: `/bulk/drafts` });
    },
    emailComposer: generateEmailEditorNavigate('compose'),
    emailComposerWithTemplateId: (templateId: string, campaignId: string = NEW_CAMPAIGN_ID, isEdit?: boolean) => {
      navigate({
        to: `${baseUrl}/email/${campaignId}/compose?templateId=${templateId}` + (isEdit ? '&isEdit=true' : ''),
      });
    },
    emailEditor: generateEmailEditorNavigate('editor'),
    emailSettings: () => {
      settingsNavigate({ to: '/email' });
    },
    emailTemplates: generateEmailEditorNavigate('templates'),
    newEmail: generateEmailEditorNavigate('new-email'),
    subscriptions: () => {
      settingsNavigate({ to: '/subscriptions/feature/:id', params: { id: 'bulk-messaging' } });
    },
  };
};
