import { useCallback } from 'react';
import { FormsSourceTenants } from '@frontend/api-forms';
import { isSubsetOfMainArray } from '@frontend/array';
import { useDigitalFormsWritebackCapabilitiesContext, useDigitalFormsSourceTenantsContext } from '../../providers';
import { GetLocationIdsWithSourceIdFn } from '../../providers/writeback-capabilities/types';

type GetSourcesWithMedicalConditionsForLocationFn = (
  locationId: string
) => FormsSourceTenants.Types.ModifiedSourceTenant[];

type GetSourcesWithMedicalConditionsForSelectedLocationsFn = (
  locationIds?: string[]
) => FormsSourceTenants.Types.ModifiedSourceTenant[];

interface GetCommonSourcesWithMedicalConditionsForSelectedLocationsFnResults {
  commonSourceIdsMap: Map<string, boolean>; // Source ID -> `true` if the source is common across all locations
  commonSources: FormsSourceTenants.Types.ModifiedSourceTenant[]; // Common sources across all locations
}

type GetCommonSourcesWithMedicalConditionsForSelectedLocationsFn = (
  locationIds: string[]
) => GetCommonSourcesWithMedicalConditionsForSelectedLocationsFnResults;

interface UseMedicalConditionSourcesResults {
  isLoadingMedicalConditionSources: boolean;

  /**
   * Returns the sources that are capable of medical conditions for the given location.
   */
  getSourcesWithMedicalConditionsForLocation: GetSourcesWithMedicalConditionsForLocationFn;

  /**
   * Returns the sources that are capable of medical conditions for
   * all the selected locations in the GLS (by defualt),
   * or for the given locationIds.
   */
  getSourcesWithMedicalConditionsForSelectedLocations: GetSourcesWithMedicalConditionsForSelectedLocationsFn;

  /**
   * Returns the locationIds with the given sourceId.
   */
  getLocationIdsWithSourceId: GetLocationIdsWithSourceIdFn;

  /**
   * Returns the common sources across all the given locations.
   */
  getCommonSourcesWithMedicalConditionsForSelectedLocations: GetCommonSourcesWithMedicalConditionsForSelectedLocationsFn;
}

export const useMedicalConditionSources = (): UseMedicalConditionSourcesResults => {
  const {
    hasFetchedWritebackCapabilities,
    getSourceIdsCapableOfMedicalConditionForLocation,
    getSourceDataCapableOfMedicalConditionForSelectedLocations,
    getLocationIdsWithSourceId,
  } = useDigitalFormsWritebackCapabilitiesContext();
  const { hasFetchedSourceTenants, getSourceTenantDetailsById } = useDigitalFormsSourceTenantsContext();

  const getSourcesWithMedicalConditionsForLocation = useCallback<GetSourcesWithMedicalConditionsForLocationFn>(
    (locationId) => {
      const sourceIds = getSourceIdsCapableOfMedicalConditionForLocation(locationId);
      const sources: FormsSourceTenants.Types.ModifiedSourceTenant[] = [];
      for (const sourceId of sourceIds) {
        const source = getSourceTenantDetailsById(locationId, sourceId);
        if (source) {
          sources.push(source);
        }
      }

      return sources;
    },
    [getSourceIdsCapableOfMedicalConditionForLocation, getSourceTenantDetailsById]
  );

  const getSourcesWithMedicalConditionsForSelectedLocations =
    useCallback<GetSourcesWithMedicalConditionsForSelectedLocationsFn>(
      (locationIds) => {
        const sourceIdToLocationIdsAssociation =
          getSourceDataCapableOfMedicalConditionForSelectedLocations(locationIds);
        const sources: FormsSourceTenants.Types.ModifiedSourceTenant[] = [];

        for (const { sourceId, associatedLocationIds } of sourceIdToLocationIdsAssociation) {
          const source = getSourceTenantDetailsById(associatedLocationIds[0], sourceId);
          if (source) {
            sources.push(source);
          }
        }

        return sources;
      },
      [getSourceDataCapableOfMedicalConditionForSelectedLocations, getSourceTenantDetailsById]
    );

  const getCommonSourcesWithMedicalConditionsForSelectedLocations =
    useCallback<GetCommonSourcesWithMedicalConditionsForSelectedLocationsFn>(
      (locationIds) => {
        const sourceIdToLocationIdsAssociation =
          getSourceDataCapableOfMedicalConditionForSelectedLocations(locationIds);
        const commonSourceIdsMap = new Map<string, boolean>();
        const commonSources: FormsSourceTenants.Types.ModifiedSourceTenant[] = [];

        for (const { sourceId, associatedLocationIds } of sourceIdToLocationIdsAssociation) {
          // Check if the source is associated with all the asked locations
          const isCommonSource = isSubsetOfMainArray(associatedLocationIds, locationIds);
          if (isCommonSource) {
            commonSourceIdsMap.set(sourceId, true);
            const source = getSourceTenantDetailsById(associatedLocationIds[0], sourceId);
            if (source) {
              commonSources.push(source);
            }
          }
        }

        return {
          commonSourceIdsMap,
          commonSources,
        };
      },
      [getSourceDataCapableOfMedicalConditionForSelectedLocations, getSourceTenantDetailsById]
    );

  return {
    isLoadingMedicalConditionSources: !hasFetchedSourceTenants || !hasFetchedWritebackCapabilities,
    getSourcesWithMedicalConditionsForLocation,
    getSourcesWithMedicalConditionsForSelectedLocations,
    getLocationIdsWithSourceId,
    getCommonSourcesWithMedicalConditionsForSelectedLocations,
  };
};
