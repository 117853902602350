import { lazy, Suspense } from 'react';
import { LazyAssetsFallback, type LazyAssetProps } from '@frontend/internal-assets';

const LazyFormsClip = lazy(() => import('./svg-components/forms-clip'));

const FormsClip = ({ assetsFallbackProps = {}, customFallback, ...rest }: LazyAssetProps) => (
  <Suspense fallback={customFallback || <LazyAssetsFallback {...assetsFallbackProps} />}>
    <LazyFormsClip {...rest} />
  </Suspense>
);

export default FormsClip;
