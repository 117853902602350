import { memo } from 'react';
import { Handle, Position } from '@xyflow/react';
import { Chip } from '@frontend/design-system';
import { NodeType, SharedNodeProps } from '../data';

export const HoursTypeNode = memo(({ data, type }: SharedNodeProps) => {
  if (type !== NodeType.OpenPhoneHours && type !== NodeType.ClosedPhoneHours && type !== NodeType.BreakPhoneHours) {
    throw new Error('Invalid node type for HoursTypeNode');
  }

  const handles = {
    top: { id: 'top' },
    bottom: { id: 'bottom' },
  };
  return (
    <div className='nodrag nopan'>
      <Handle
        type='target'
        position={Position.Top}
        id={handles.top.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />

      {/* Open Hours */}
      {type === NodeType.OpenPhoneHours && (
        <Chip variant='success' tooltipLabel={data.label}>
          Open
        </Chip>
      )}

      {/* Closed Hours */}
      {type === NodeType.ClosedPhoneHours && (
        <Chip variant='critical' tooltipLabel={data.label}>
          Closed
        </Chip>
      )}

      {/* Break Hours */}
      {type === NodeType.BreakPhoneHours && (
        <Chip maxWidth={'350px'} variant='indigo' tooltipLabel={data.label}>
          {data.label}
        </Chip>
      )}

      <Handle
        type='source'
        position={Position.Bottom}
        id={handles.bottom.id}
        isConnectable={true}
        style={{ visibility: 'hidden' }}
      />
    </div>
  );
});
HoursTypeNode.displayName = 'HoursTypeNode';
