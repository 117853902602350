import { useEffect, useState } from 'react';
import { PersonMessageHistory } from '@weave/schema-gen-ts/dist/schemas/schedule/quickfill/v2/quickfill.pb';
import { QuickFillApi, QuickFillTypes } from '@frontend/api-quick-fill';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  TableColumnConfig,
  Table,
  Text,
  Chip,
  usePopoverMenu,
  PopoverMenu,
  PopoverMenuItem,
  useFormField,
  SearchField,
  useDebouncedValue,
  TextLink,
} from '@frontend/design-system';
import { formatDate } from '../../utils';
import { quickfillHistoryTrackingIds } from '../../views/Calendar/EventsPanel/Quickfill/quickfill-tracking-ids';

const DEFAULT_PAGE_SIZE = 25;

export const QuickfillFullHistoryTable = () => {
  const { t } = useTranslation('scheduleQuickfill');
  const [selectedLocationId, setSelectedLocationId] = useState<string>('');
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const [pageConfig, setPageConfig] = useState<QuickFillTypes.QuickFillPageConfig>({
    pageNumber: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    pageToken: 1,
  });

  const searchFieldProps = useFormField({ type: 'text' });
  const debouncedSearchValue = useDebouncedValue(searchFieldProps.value, 1000);

  const { getTriggerProps, getMenuProps, close, open, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
    middlewareOptions: { offset: 0 },
  });
  const { ref: filterTriggerRef, ...triggerProps } = getTriggerProps();

  // useEffect to reset the page number when the search value changes
  useEffect(() => {
    setPageConfig((prev) => ({ ...prev, pageToken: 1, pageNumber: 1 }));
  }, [searchFieldProps.value]);

  const { data: quickfillHistoryList, isLoading: isLoadingQuickfillHistoryList } = useLocalizedQuery({
    queryKey: [
      'quickfill-history',
      pageConfig.pageNumber,
      pageConfig.pageSize,
      pageConfig.pageToken,
      selectedLocationId ? selectedLocationId : [...selectedLocationIds],
      debouncedSearchValue,
    ],

    queryFn: () =>
      QuickFillApi.getQuickFillMessageHistory({
        limit: pageConfig.pageSize,
        page: pageConfig.pageToken,
        locationIds: selectedLocationId ? [selectedLocationId] : selectedLocationIds,
        queryParam: debouncedSearchValue,
      }),
  });

  const columnConfig: TableColumnConfig<PersonMessageHistory>[] = [
    ...(!selectedLocationId
      ? [
          {
            Header: t('Location'),
            id: 'location',
            disableSortBy: true,
            accessor: (row: PersonMessageHistory) => getLocationName(row.locationId),
            cellRenderer: (row: string) => {
              return <Chip.Location>{row}</Chip.Location>;
            },
          },
        ]
      : []),
    {
      Header: t('Name'),
      id: 'name',
      accessor: (row) => `${row.personName}`,
      cellRenderer: (row) => {
        return (
          <Text weight='bold' size='medium'>
            {row}
          </Text>
        );
      },
    },
    {
      Header: t('Message Date & Time'),
      id: 'message-date-time',
      disableSortBy: true,
      accessor: (row) => `${row.messageSentAt}`,
      cellRenderer: (row) => {
        return (
          <Text weight='bold' size='medium'>
            {formatDate(row)}
          </Text>
        );
      },
    },
  ];

  const handleLocationSelectorChange = (locationId: string) => {
    setSelectedLocationId(locationId);
    close();
  };

  const hasOneLocationAvailable = selectedLocationIds.length === 1;

  return (
    <>
      <div
        css={{
          padding: theme.spacing(2, 0),
          display: 'flex',
          alignItems: 'center',
          justifyContent: `${hasOneLocationAvailable ? 'flex-end' : 'space-between'} `,
        }}
      >
        {!hasOneLocationAvailable && (
          <div ref={filterTriggerRef} css={{ display: 'flex', alignItems: 'center' }}>
            <Chip.DropdownFilter
              trackingId={quickfillHistoryTrackingIds.historyTableLocationSelector}
              filterIsActive={selectedLocationId !== ''}
              {...triggerProps}
              onClick={isOpen ? close : open}
              css={{ width: 'unset', maxWidth: 'unset' }}
              leftElement={
                <Icon name='location-small' color={selectedLocationId === '' ? 'default' : 'white'} size={18} />
              }
            >
              {selectedLocationId === '' ? t('All locations') : getLocationName(selectedLocationId).replace(/-/g, ' ')}
            </Chip.DropdownFilter>
            <PopoverMenu {...getMenuProps()}>
              {selectedLocationIds.map((locationId) => (
                <PopoverMenuItem
                  onClick={() => handleLocationSelectorChange(locationId)}
                  key={locationId}
                  value={locationId}
                  trackingId={quickfillHistoryTrackingIds.getHistoryTableLocationSelectorListItem(locationId)}
                >
                  {getLocationName(locationId)}
                </PopoverMenuItem>
              ))}
            </PopoverMenu>
            {selectedLocationId && (
              <TextLink
                weight='bold'
                size='large'
                css={{ marginLeft: theme.spacing(2) }}
                onClick={() => setSelectedLocationId('')}
                trackingId={quickfillHistoryTrackingIds.historyTableLocationSelectorClearAllBtn}
              >
                {t('Clear All')}
              </TextLink>
            )}
          </div>
        )}
        <SearchField
          {...searchFieldProps}
          label={t('Search by name')}
          data-trackingId={quickfillHistoryTrackingIds.historyTableSearchField}
          name={t('search-field')}
        />
      </div>
      <Table
        colConfig={columnConfig}
        data={quickfillHistoryList?.personsMessageHistory ?? []}
        isLoading={isLoadingQuickfillHistoryList}
        fullHeight
        fullHeightConfig={{
          minHeight: 230,
        }}
        isPaginated
        manualPaginationConfig={{
          showNumRowSelector: true,
          showGoToPageField: true,
          page: pageConfig.pageNumber,
          rowsPerPageOptions: [pageConfig.pageSize, 50],
          total: Math.ceil((quickfillHistoryList?.totalCount ?? 0) / pageConfig.pageSize),
          onNumRowsChange: (pageSize) => {
            setPageConfig((prev) => ({ ...prev, pageSize, pageToken: 0, pageNumber: 1 }));
          },
          handleChange: (pageNumber: number) => {
            setPageConfig((prev) => ({
              ...prev,
              pageToken: pageNumber,
              pageNumber,
            }));
          },
        }}
      />
    </>
  );
};
