import { Node, Text as SlateText } from 'slate';
import { isTagNode } from '../message-template.models';
import { CharCountOptions, getTemplateCharCount } from './get-template-char-count';

/*
    This function is used to convert a slate node to text. Each node can have text, dynamic fields/tags, and children (nodes with text and dynamic fields/tags).
    @params node: Node - a slate node representing a message template (i.e. text and dynamic fields/tags)
    @returns string - the simplified text representation of the slate node
*/

const transformNodeToText = (node: Node): string => {
  let text = '';
  if (SlateText.isText(node)) {
    text += Node.string(node);
  }

  if (isTagNode(node) && !node.tag.invalid) {
    text += node.tag.value;
  }

  if (node.children) {
    const children = node.children as Node[];
    text += children.reduce((acc, child) => acc + transformNodeToText(child), '');
  }

  return text;
};

/*
    This function is used directly within the MessageTemplate component to calculate the character count of a message template.
    @params nodes: Node[] - an array of slate nodes representing the message template (i.e. text and dynamic fields/tags)
    @params options: CharCountOptions - a list of options to configure the character count calculation
    @returns number - the character count of the message template
*/

export const getNodeCharCount = (nodes: Node[], options: CharCountOptions): number => {
  const transformedNodes = nodes.map((node) => transformNodeToText(node));
  return getTemplateCharCount(transformedNodes, options);
};
