import { CSSProperties } from 'react';
import { motion } from 'motion/react';
import { HTMLMotionProps } from 'motion/react';
import { theme } from '@frontend/theme';
import { ThreadSendingMediaItem } from '../../types';
import { MediaSendingPreviewItem } from './media-sending-preview-item';

type MediaSendingPreviewAreaProps = HTMLMotionProps<'ul'> & {
  media: ThreadSendingMediaItem[];
  onRemoveMediaItem: (id: string) => void;
  maxHeight?: CSSProperties['maxHeight'];
};

export const MediaSendingPreviewArea = ({
  media,
  onRemoveMediaItem,
  maxHeight = 200,
  ...rest
}: MediaSendingPreviewAreaProps) => {
  const isEmpty = !media.length;

  return (
    <motion.ul
      css={{
        display: 'inline-block',
        whiteSpace: 'nowrap',
        gap: theme.spacing(2),
        width: '100%',
        overflowX: 'auto',
        overflowY: 'hidden',
        margin: 0,
      }}
      initial={{
        display: 'block',
        maxHeight: 0,
        borderTop: 'none',
        padding: 0,
      }}
      animate={{
        // Switching to block when empty avoids a weird empty space issue caused by inline-block when the list is empty
        display: isEmpty ? 'block' : 'inline-block',
        maxHeight: isEmpty ? 0 : maxHeight,
        borderTop: isEmpty ? 'none' : `1px solid ${theme.colors.neutral20}`,
        padding: isEmpty ? 0 : theme.spacing(2),
      }}
      {...rest}
    >
      {media.map((mediaItem, i) => (
        <MediaSendingPreviewItem
          key={mediaItem.id}
          isLoading={mediaItem.uploading}
          hasError={mediaItem.hasError}
          previewSrc={mediaItem.previewSrc}
          file={mediaItem.file}
          onRemove={() => onRemoveMediaItem(mediaItem.id)}
          css={{
            marginRight: i === media.length - 1 ? 0 : theme.spacing(2),
          }}
        />
      ))}
    </motion.ul>
  );
};
