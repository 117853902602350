import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export const DeviceLayoutTouchScreen = () => {
  const a = [];
  for (let i = 0; i < 30; i++) {
    a.push(i);
  }
  const columns = 5;
  const rows = 6;
  return (
    <div className='touch-screen-container' css={[gridStyles({ columns, rows }), touchScreenStyles]}>
      {a.map((b, i) => {
        return (
          <div
            key={b}
            className={`touch-screen-tile` + ` ${i === 0 ? 'static' : ''}` + ` ${i > 6 ? 'inactive' : 'active'}`}
            css={gridItemStyles({ i })}
          >
            {i !== 0 && (
              <Text className='touch-screen-tile-key' as='span'>
                {b}
              </Text>
            )}
            {i < 6 && (
              <Text className='touch-screen-tile-name' as='span'>
                {'Hello Test'}
              </Text>
            )}
          </div>
        );
      })}
    </div>
  );
};

const gridStyles = ({ columns, rows }: { columns: number; rows: number }) => css`
  display: grid;
  grid-template-columns: repeat(${columns}, 1fr);
  grid-template-rows: repeat(${rows}, 1fr);
  gap: 20px;
`;

const gridItemStyles = ({ i }: { i: number }) => {
  const columnMap = [
    { maxIndex: 5, column: 1 },
    { maxIndex: 10, column: 5 },
    { maxIndex: 15, column: 2 },
    { maxIndex: 21, column: 3 },
    { maxIndex: 29, column: 4 },
  ];

  const column = columnMap.find((range) => i <= range.maxIndex)?.column || 1;
  const row = (i % 6) + 1;
  console.log({ i, row, column, divisible: i % 6 });
  return css`
    grid-column: ${column};
    grid-row: ${row};
  `;
};

const touchScreenStyles = css`
  width: calc(100% - ${theme.spacing(7)});
  background: ${theme.colors.neutral10};
  margin: auto;
  padding: ${theme.spacing(5, 2)};
  border-radius: ${theme.borderRadius.large};
  border: 2px solid ${theme.colors.neutral90};
  overflow-x: auto;

  .touch-screen-tile {
    border: 2px solid ${theme.colors.neutral30};
    border-radius: ${theme.borderRadius.medium};
    background: ${theme.colors.white};
    padding: ${theme.spacing(1)};
    //   width: ${theme.spacing(13.5)};
    width: auto;
    height: ${theme.spacing(7.5)};
    font-weight: ${theme.font.weight.bold};
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1.5)};
  }

  .touch-screen-tile-key {
    color: ${theme.colors.neutral50};
  }

  .touch-screen-tile-name {
    color: ${theme.colors.neutral90};
  }

  .touch-screen-tile.static {
    border: 2px solid ${theme.colors.neutral20};
  }

  .touch-screen-tile.active:not(.static):hover {
    background: ${theme.colors.neutral5};
    color: ${theme.colors.neutral50};
    cursor: pointer;
  }

  .touch-screen-tile.active:not(.static):active {
    background: ${theme.colors.neutral10};
    color: ${theme.colors.neutral50};
  }

  .touch-screen-tile.static .touch-screen-tile-name {
    color: ${theme.colors.neutral30};
  }

  .touch-screen-tile.inactive {
    border-color: transparent;
  }

  .touch-screen-tile.inactive .touch-screen-tile-key {
    color: ${theme.colors.neutral20};
  }
`;
