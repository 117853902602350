import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { ModalCarousel } from '@frontend/full-screen-carousel';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { StickyColor, stickyColors } from '../colors';
import { customFontStyle, stickyShadow } from '../common-styles';
import { useStickyNoteStore, useStickyNoteContext } from '../providers';

const formatArchiveDate = (date: string) => dayjs(date).format('MMMM D, YYYY');

export function ArchivedNotesCarousel({
  isCarouselOpen,
  onCarouselClose,
}: {
  isCarouselOpen: boolean;
  onCarouselClose: () => void;
}) {
  const { archivedNotes } = useStickyNoteStore(['archivedNotes']);
  const { color } = useStickyNoteContext();

  return (
    <>
      <ModalCarousel
        isOpen={isCarouselOpen}
        onClose={onCarouselClose}
        items={archivedNotes}
        getUniqueId={(item) => item.id}
        renderItem={({ archivedAt, text }) => (
          <div css={carouselContainerStyle}>
            <header css={carouselHeaderStyle}>
              <Icon name='task-small' />
              <Text weight='bold'>Sticky Note</Text>
              <span>({formatArchiveDate(archivedAt)})</span>
            </header>
            <div css={[stickyNoteContainerStyle(color), stickyShadow()]}>
              <div css={[stickyNoteContentStyle, customFontStyle]}>{text}</div>
            </div>
          </div>
        )}
      />
    </>
  );
}

const carouselContainerStyle = css`
  padding: ${theme.spacing(2, 4, 4, 4)};
  border-radius: ${theme.borderRadius.medium};
  background: white;
  box-shadow: ${theme.shadows.light};
`;

const carouselHeaderStyle = css`
  display: flex;
  align-items: center;

  gap: ${theme.spacing(0.5)};
  margin-bottom: ${theme.spacing(2)};
  margin-left: -${theme.spacing(2)};
`;

const stickyNoteContainerStyle = (color: StickyColor) => css`
  width: 316px;
  height: 280px;
  background: ${stickyColors[color]};
  padding: ${theme.spacing(1.5)};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: ${theme.zIndex.low};
`;

const stickyNoteContentStyle = css`
  width: 100%;
  height: 100%;
  overflow: auto;
  white-space: pre-wrap;
`;
