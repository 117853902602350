import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAnalyticsOrgLocations } from '../../../hooks';
import { formatDateByTimezone, getHourlyInsightsLabel, toHHmmss } from '../../../utils';
import { DemoChip } from '../../demo-chip';
import { InfoTipPopover } from '../../info-tip-popover';
import { useFetchAggregatedCallsAndDurationReport, usePhoneAnalyticsShallowStore } from '../hooks';
import { ChartProps } from './shared-types';

const colors = {
  patient: theme.colors.primary20,
  unknown: theme.colors.secondary.eggplant30,
};

const labels = {
  patient: i18next.t('Patient', { ns: 'analytics' }),
  unknown: i18next.t('Unknown', { ns: 'analytics' }),
};

type ProcessedData = {
  chartData: BarChartData;
  totals: {
    patient: number;
    unknown: number;
  };
};

export const PatientsVsUnknownCallDurationChart = ({
  defaultChartAppearance = {},
  exportPdfProps,
  onFetchStateChange,
}: ChartProps) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { locationNames } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'PHONE',
  });
  const { data, isHourlyData, isLoading, isMultiLocation } =
    useFetchAggregatedCallsAndDurationReport<PhoneAnalyticsTypes.SourceTypeAggregationKey>('source_type');

  const processedData: ProcessedData = useMemo(() => {
    let groups: BarChartData['groups'] = [];
    const totals: ProcessedData['totals'] = {
      patient: 0,
      unknown: 0,
    };

    if (isMultiLocation) {
      // There will be multiple location objects with single/multiple dates
      groups = Object.entries(data?.aggregatedMultiLocations || {}).map(([key, { duration = {} }]) => {
        const patient = duration?.current_patient || 0;
        const unknown = duration?.unknown || 0;

        totals.patient += patient;
        totals.unknown += unknown;

        return {
          name: locationNames?.[key] || key,
          values: {
            patient,
            unknown,
          },
        };
      });
    } else {
      // For hourly data: There will be only one object with single date and hourly data for the given location
      // For daily data: There will be only one object with multiple dates for the given location
      const locationData = Object.values(data?.locations || {})[0] || {};

      if (isHourlyData) {
        // Hourly data is restricted to a single date, hence only one object
        const { current_patient, unknown } = Object.values(locationData || {})[0] || {};
        const hourlyPatients = current_patient?.duration?.hourly || [];
        const hourlyUnknown = unknown?.duration?.hourly || [];

        groups = hourlyPatients.map((patient, i) => {
          totals.patient += patient;
          totals.unknown += hourlyUnknown[i] || 0;

          return {
            name: getHourlyInsightsLabel(i),
            values: {
              patient,
              unknown: hourlyUnknown[i],
            },
          };
        });
      } else {
        groups = Object.entries(locationData).map(([date, { current_patient, unknown }]) => {
          const patientDuration = current_patient?.duration?.daily || 0;
          const unknownDuration = unknown?.duration?.daily || 0;

          totals.patient += patientDuration;
          totals.unknown += unknownDuration;

          return {
            name: dayjs(date).isValid() ? formatDateByTimezone(date, filters.start_date, filters.time_zone) : date,
            values: {
              patient: patientDuration,
              unknown: unknownDuration,
            },
          };
        });
      }
    }

    return {
      chartData: {
        groups,
      },
      totals,
    };
  }, [data, filters, isHourlyData, isMultiLocation, locationNames]);

  useEffect(() => {
    onFetchStateChange?.(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={colors} isLoading={isLoading} labels={labels}>
      <Chart.Header
        {...exportPdfProps}
        infoTip={
          <InfoTipPopover>
            {t(
              'Refers to total time duration office spent while talking to patients vs unknown phone numbers. Value is in hh:mm:ss'
            )}
          </InfoTipPopover>
        }
        leftElement={isDemoAccount && <DemoChip />}
        subtitle={filterHintText}
        title={t('Patient vs Unknown')}
      />
      <Chart.BarChart
        appearance={defaultChartAppearance}
        data={processedData.chartData}
        formatValue={(value) => toHHmmss(value)}
      />
      <Chart.Legends formatValue={(value) => toHHmmss(value)} values={processedData.totals} />
    </Chart>
  );
};
