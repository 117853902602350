import { ReactNode } from 'react';
import { motion } from 'motion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, NakedButton } from '@frontend/design-system';
import { CHAT_HEIGHTS } from '../../constants';
import { buttonStyles } from './thread-options-drawer';

type ThreadOptionsSubmenuProps = {
  children: ReactNode;
  backLabel: string;
  trailingAdornment?: ReactNode;
  onBack: () => void;
};

export const ThreadOptionsSubmenu = ({ children, backLabel, trailingAdornment, onBack }: ThreadOptionsSubmenuProps) => {
  return (
    <motion.div layout='position'>
      <NakedButton css={buttonStyles} onClick={onBack}>
        <span css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}>
          <Icon name='caret-left-small' />
          <Text>{backLabel}</Text>
        </span>
        {typeof trailingAdornment === 'string' || typeof trailingAdornment === 'number' ? (
          <Text color='light' size='small'>
            {trailingAdornment}
          </Text>
        ) : (
          trailingAdornment
        )}
      </NakedButton>
      <div
        css={{
          maxHeight: `calc(${CHAT_HEIGHTS.full}px * 0.25)`,
          overflowY: 'auto',
        }}
      >
        {children}
      </div>
    </motion.div>
  );
};
