import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PaymentOrigin } from '@frontend/api-invoices';
import { PersonsV3 } from '@frontend/api-person';
import { SetupFutureUsage } from '@frontend/api-weave-pay';
import { HttpError } from '@frontend/fetch';
import { useMerchant } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import {
  ITerminalStrategyError,
  PaymentsTerminalController,
  ReaderConnectErrors,
  RESTRICTED_TERMINAL_STATES,
  TerminalAppData,
  useTerminalPaymentSession,
} from '@frontend/payments-terminal-controller';
import { useShell } from '@frontend/shell-utils';
import {
  CollectPaymentModalSteps,
  useCreatePaymentIntent,
  useDiscoverReaderQuery,
  useInvoicePerson,
  usePaymentModalContext,
} from '../hooks';
import { useTerminalFlags } from '../hooks/use-terminal-flags';
import { CollectReaderPaymentDisplay } from '../reader-payment-deprecated';
import { useSelectedReader } from './provider';
import { ReaderStatusAdapter } from './reader-status';

export const CollectionStepReaderPayment = () => {
  const { resetSteps, closeModal } = useMultiStepModal();
  const { paymentsUrl, locationId, stripeLocationId } = useMerchant();
  const shell = useShell();

  // PAYMENT INTENT
  const { invoice, refetch: refetchInvoice } = useSelectedInvoice();
  const { person } = useInvoicePerson(invoice);

  const { onPaymentSuccess } = usePaymentModalContext() ?? {};

  /**
   * Should only use "offSession" when we have a "save for later"
   * checkbox and corresponding address fields, etc.
   */
  const setupFutureUsage: SetupFutureUsage | null = null;

  const {
    paymentIntent,
    error: createIntentError,
    retryCreatePaymentIntent,
  } = useCreateTerminalPaymentIntent({ person, invoice, setupFutureUsage });

  // TERMINAL

  const discoverReaderQuery = useDiscoverReaderQuery({
    locationId,
    stripeLocationId,
    paymentsUrl: paymentsUrl ?? undefined,
  });
  const { enableServerDriven } = useTerminalFlags(locationId);
  const goBack = () => resetSteps(CollectPaymentModalSteps.PaymentFlowList);

  const { selectedReader, setSelectedReader } = useSelectedReader();
  const appData: TerminalAppData = useMemo(
    () =>
      shell.isShell
        ? {
            type: 'shell',
            version: shell.version,
          }
        : {
            type: 'nwx',
            version: undefined,
          },
    [shell]
  );

  const discoverReaders = useCallback(
    () => discoverReaderQuery.refetch().then((res) => res.data ?? []),
    [discoverReaderQuery]
  );

  const {
    error: readerError,
    status: readerStatus,
    handleCancel,
    handleTryAgain,
    paymentSuccessful,
  } = useTerminalPaymentSession({
    onPaymentSuccess: () => {
      refetchInvoice();
      onPaymentSuccess?.();
    },
    reader: selectedReader,
    paymentsUrl: paymentsUrl ?? undefined,
    locationId,
    appData,
    enableServerDriven,
    goBack,
    paymentIntent: paymentIntent?.paymentIntent,
    paymentId: paymentIntent?.paymentId,
    invoiceId: invoice?.id,
    retryCreatePaymentIntent,
    discoverReaders,
  });

  const _handleEndActivePayment = () => {
    handleTryAgain({ selectedReader, failIfReaderInUse: false });
  };

  // TODO: implement terminal is in use
  const error = createIntentError ?? readerError;
  const isTerminalInUse = !!(error?.message === ReaderConnectErrors.alreadyInUse);

  return (
    <CollectReaderPaymentDisplay
      selectedReader={selectedReader}
      readerError={readerError}
      isTerminalInUse={isTerminalInUse}
      paymentSuccessful={paymentSuccessful}
      handleEndActivePayment={_handleEndActivePayment}
      onCancelClick={handleCancel}
      onChangePaymentMethodClick={resetSteps}
      onCloseClick={closeModal}
      onTryAgainClick={handleTryAgain}
      ReaderStatusOverrideComponent={
        <ReaderStatusAdapter
          status={readerStatus}
          error={error}
          isTerminalInUse={isTerminalInUse}
          handleEndActivePayment={_handleEndActivePayment}
        />
      }
      availableReaders={discoverReaderQuery.data}
      onSelectedReaderChange={(terminal) => {
        if (!paymentsUrl) return; // this should never happen, we only show readers if there is a payments url
        return setSelectedReader(PaymentsTerminalController.createStoredReader(locationId, paymentsUrl, terminal));
      }}
      disableTerminalSelection={RESTRICTED_TERMINAL_STATES.includes(readerStatus)}
    />
  );
};
type UseCreatePaymentIntentProps = {
  person: ReturnType<typeof useInvoicePerson>['person'];
  invoice: ReturnType<typeof useSelectedInvoice>['invoice'];
  setupFutureUsage?: SetupFutureUsage | null;
};

const useCreateTerminalPaymentIntent = ({
  person,
  invoice,
  setupFutureUsage = SetupFutureUsage.offSession,
}: UseCreatePaymentIntentProps) => {
  const [paymentIntent, setPaymentIntent] = useState<Awaited<ReturnType<typeof createPaymentIntent>>>();
  const [error, setError] = useState<ITerminalStrategyError>();

  const personEmail = person ? PersonsV3.PersonHelpers.getEmailContactType(person) : '';
  const { createPaymentIntent } = useCreatePaymentIntent({
    amount: invoice?.billedAmount ?? 0,
    email: personEmail,
    personId: person?.personId,
    setupFutureUsage: setupFutureUsage || undefined,
    locationId: invoice?.locationId,
    origin: PaymentOrigin.Terminal,
    invoiceId: invoice?.id,
  });

  const createOnce = useRef(false);

  const tryCreatePaymentIntent = useCallback(async () => {
    let intent: typeof paymentIntent | undefined;
    let paymentIntentError: ITerminalStrategyError | undefined;
    if (!createOnce.current) {
      createOnce.current = true;
      try {
        intent = await createPaymentIntent();
        setPaymentIntent(intent);
      } catch (error) {
        if (error instanceof HttpError || error instanceof Error) {
          paymentIntentError = { action: 'initialize', message: error.message };
          setError(paymentIntentError);
        }
      }
    }
    return { intent, paymentIntentError };
  }, [createPaymentIntent]);

  useEffect(() => {
    tryCreatePaymentIntent();
  }, [createPaymentIntent, paymentIntent?.clientSecret]);

  const retryCreatePaymentIntent = async () => {
    createOnce.current = false;
    const { intent, paymentIntentError } = await tryCreatePaymentIntent();
    if (paymentIntentError || !intent?.paymentIntent || !intent.paymentId)
      throw paymentIntentError ?? new Error('Failed to create payment intent');
    return { paymentIntent: intent.paymentIntent, paymentId: intent.paymentId };
  };

  return { paymentIntent, error, retryCreatePaymentIntent };
};
