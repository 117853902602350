import { FC, useEffect } from 'react';
import { BuilderFieldDivisionCategories } from '../../../constants';
import { useFormBuilderStore } from '../../../provider';
import { PrimaryFieldsDivisionCategory } from '../../../types/field-divisions';
import CategoriesLayout from './categories-layout/categories-layout.component';
import { useFilteredCategories } from './hooks';

export const PrimaryFields: FC = () => {
  const { filteredCategories, setCompleteCategories } = useFilteredCategories<PrimaryFieldsDivisionCategory>({
    categories: [],
  });
  const { primaryFieldElements, usedPrimaryFieldElements } = useFormBuilderStore([
    'primaryFieldElements',
    'usedPrimaryFieldElements',
  ]);

  useEffect(() => {
    setCompleteCategories(BuilderFieldDivisionCategories.PRIMARY_FIELD_CATEGORIES);
  }, []);

  if (!primaryFieldElements) {
    return null;
  }

  return (
    <CategoriesLayout
      categories={filteredCategories}
      fields={primaryFieldElements}
      type='primary'
      usedFields={usedPrimaryFieldElements}
    />
  );
};
