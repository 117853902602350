import React, { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { isEmpty, isNull } from 'lodash-es';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { getUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { i18next, useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedQuery } from '@frontend/scope';
import { useContactPanelStore, useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  Button,
  Modal,
  ModalControlModalProps,
  PhoneOutgoingIcon,
  SkeletonLoaders,
  Text,
  useAlert,
} from '@frontend/design-system';
import { featureFlags } from '../../../feature-flags';
import { queryKeys } from '../../../query-keys';
import { generateTrackingId } from '../../../tracking';
import { callIntelligenceUtils } from '../../../utils';
import { UserCard } from '../../user-card';
import { diffValues } from '../call-analysis';
import { CallAnalyzeStatus } from '../call-analyze-status';
import { CallIntelMockData } from '../demo-data';
import { useCallTakeawayPanelShallowStore } from '../hooks';
import { OptionType, useOptionsProvider } from '../hooks/use-options-provider';
import RestoreHistoryModal from './restore-history-modal';

const mapEditableFieldsToCall = (
  editableFields: CallIntelligenceTypes.CallEditableFields
): Partial<CallIntelligenceTypes.Call> => {
  const { contactType, sentiment, schedulingOutcome, schedulingOpportunity, categories, appointmentTypes } =
    editableFields;
  return {
    contactType,
    sentiment,
    schedulingOutcome,
    schedulingOpportunity,
    categories,
    appointmentTypes: appointmentTypes?.appointmentType,
  };
};

type EditHistoryModalProps = {
  isFailedCall?: boolean;
  isPoorAudio?: boolean;
  isSkippedCall?: boolean;
  modalProps: ModalControlModalProps;
  onClose: () => void;
  updateEditedCallInList: (updatedFields: Partial<CallIntelligenceTypes.Call>, isRestore?: boolean) => void;
};
type Suggestion = {
  title: string;
  description: string;
};

type FieldMapping = {
  [key in CallIntelligenceTypes.AnalysisTypeEnum]: {
    emptyValue: string;
    dependentAnalysis: CallIntelligenceTypes.AnalysisTypeEnum[];
    label: string;
    optionType: OptionType;
    type: keyof CallIntelligenceTypes.CallEditableFields;
    suggestion?: Suggestion;
  };
};

type HistorLoaderProps = {
  count: number;
};

type RenderOptionsParams = {
  allFields: CallIntelligenceTypes.CallEditableFields;
  analysisType: CallIntelligenceTypes.AnalysisTypeEnum;
  isOriginal: boolean;
  value: any;
};

type RestoreConfig = {
  isRestoreOpen: boolean;
  analysisType: string;
};

/**These will go to constants, TBR in coming PRs */
export const FIELD_MAPPING: FieldMapping = {
  [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_APPOINTMENT_TYPE]: {
    type: 'appointmentTypes',
    emptyValue: '-',
    dependentAnalysis: [],
    label: i18next.t('Appointment Type', {
      ns: 'analytics',
    }),
    optionType: 'appointmentTypes',
    suggestion: {
      title: i18next.t('Suggest an Appointment Type', {
        ns: 'analytics',
      }),
      description: i18next.t(
        'Please suggest an Appointment Type that would best fit this call and our Call Intelligence team will review it. Thank you!',
        {
          ns: 'analytics',
        }
      ),
    },
  },
  [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CATEGORY]: {
    type: 'categories',
    emptyValue: '-',
    dependentAnalysis: [],
    label: i18next.t('Category', {
      ns: 'analytics',
    }),
    optionType: 'categories',
    suggestion: {
      title: i18next.t('Suggest a Category', {
        ns: 'analytics',
      }),
      description: i18next.t(
        'Please suggest a Category that would best fit this call and our Call Intelligence team will review it. Thank you!',
        {
          ns: 'analytics',
        }
      ),
    },
  },
  [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CONTACT_TYPE]: {
    type: 'contactType',
    emptyValue: '-',
    dependentAnalysis: [],
    label: i18next.t('Contact Type', {
      ns: 'analytics',
    }),
    optionType: 'contactType',
  },
  [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY]: {
    type: 'schedulingOpportunity',
    emptyValue: '-',
    dependentAnalysis: [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME],
    label: i18next.t('Scheduling Opportunity', {
      ns: 'analytics',
    }),
    optionType: 'schedulingOpportunity',
  },
  [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME]: {
    type: 'schedulingOutcome',
    emptyValue: i18next.t('N/A', {
      ns: 'analytics',
    }),
    dependentAnalysis: [],
    label: i18next.t('Scheduling Outcome', {
      ns: 'analytics',
    }),
    optionType: 'schedulingOutcome',
  },
  [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SENTIMENT]: {
    type: 'sentiment',
    emptyValue: '-',
    dependentAnalysis: [],
    label: i18next.t('Customer Sentiment', {
      ns: 'analytics',
    }),
    optionType: 'sentiment',
  },
  [CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SERVICE_QUALITY]: {
    type: 'serviceQualityFlags',
    emptyValue: '-',
    dependentAnalysis: [],
    label: i18next.t('Service Quality', {
      ns: 'analytics',
    }),
    optionType: 'serviceQualityFlags',
    suggestion: {
      title: i18next.t('Suggest a Service Quality Label', {
        ns: 'analytics',
      }),
      description: i18next.t(
        'Please suggest a Service Quality Label that would best fit this call and our Call Intelligence team will review it. Thank you!',
        {
          ns: 'analytics',
        }
      ),
    },
  },
};

const HistoryLoader = ({ count }: HistorLoaderProps) => {
  const loadersToShow = Math.min(count, 3);

  return (
    <>
      {Array.from({ length: loadersToShow }).map((_, idx) => (
        <SkeletonLoaders.Loader key={idx} height={theme.spacing(12)} />
      ))}
    </>
  );
};

const EditHistoryModal: React.FC<EditHistoryModalProps> = ({
  isFailedCall,
  isPoorAudio,
  isSkippedCall,
  modalProps,
  onClose,
  updateEditedCallInList,
}) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { isDemoAccount, selectedCall: call } = useCallTakeawayPanelShallowStore('isDemoAccount', 'selectedCall');
  const { personId } = useContactPanelStore();
  const { getOptions } = useOptionsProvider();
  const [demoRestoredAnalysis, setDemoRestoredAnalysis] = useState<CallIntelligenceTypes.AnalysisTypeEnum[]>([]);

  const isVariantB = useHasFeatureFlag(featureFlags.editAiDesignVariant); // true for design B , false for Deisgn A

  const [restoreConfig, setRestoreConfig] = useState<RestoreConfig>({
    isRestoreOpen: false,
    analysisType: '',
  });

  const [pendingUpdateCallInList, setPendingUpdateCallInList] = useState<null | Partial<CallIntelligenceTypes.Call>>(
    null
  );

  const isContactPanelOpen = call?.id === call?.id && personId === call?.person?.id;

  const isOnlyPoorAudio = isPoorAudio && !isSkippedCall && !isFailedCall;

  const demoCallHistory = useMemo(() => call && CallIntelMockData.getCallEditHistory(call), [call?.id]);
  const demoCallRestoredHistory = useMemo(() => {
    const allRestoredAnalysis = Array.from(
      new Set([...demoRestoredAnalysis, restoreConfig.analysisType as CallIntelligenceTypes.AnalysisTypeEnum])
    );
    setDemoRestoredAnalysis(allRestoredAnalysis);
    return call && CallIntelMockData.getCallEditHistory(call, allRestoredAnalysis);
  }, [call, restoreConfig.analysisType]);

  const { data, isLoading, refetch } = useScopedQuery({
    queryKey: queryKeys.callIntelligence(`call-edit-history-${JSON.stringify(call)}-${isDemoAccount}`),
    queryFn: () =>
      call?.id && call?.locationId
        ? isDemoAccount
          ? restoreConfig.analysisType
            ? demoCallRestoredHistory
            : demoCallHistory
          : CallIntelligenceApi.getCallEditHistory(call.id, call.locationId)
        : CallIntelligenceApi.noopMutationFn(null),

    onError: () => {
      alert.error(t('Failed to fetch call edit history'));
    },
    refetchOnWindowFocus: false,
    select: (data) => data as CallIntelligenceTypes.GetCallEditHistoryResponse,
  });

  const { mutate: restoreApi } = useMutation({
    mutationFn: !isDemoAccount ? CallIntelligenceApi.editAIOutput : CallIntelligenceApi.noopMutationFn,
    onSuccess: (_, payload) => {
      const editedFields = mapEditableFieldsToCall(payload.edited_fields);
      const changedValues = diffValues(call ?? {}, editedFields);
      setPendingUpdateCallInList(changedValues);
      refetch();
      alert.success(t('Successfully restored original analysis!'));
    },
    onError: (err) => {
      console.error(err);
      if (http.isHttpError(err)) {
        const errorMessage =
          ((err.data as Record<string, any>)?.code as number) !== 3
            ? t('Unable to restore analysis. Please try again.')
            : t('Updates were made by another user. Please refresh and try again.');
        alert.error(errorMessage);
      }
    },
    onSettled: () => {
      handleRestoreCancel();
    },
  });

  const renderOptions = ({ allFields, analysisType, isOriginal, value }: RenderOptionsParams) => {
    const fieldMapping = FIELD_MAPPING[analysisType];

    const { emptyValue, optionType } = fieldMapping;
    const options = getOptions(optionType);

    switch (analysisType) {
      case CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY: {
        const resolvedValue = value as CallIntelligenceTypes.CallEditableFields['schedulingOpportunity'];

        return !isNull(resolvedValue) ? (
          resolvedValue ? (
            options[CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED]
          ) : (
            options[CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED]
          )
        ) : (
          <Text size='medium'>{emptyValue}</Text>
        );
      }

      case CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CATEGORY: {
        const resolvedValue = value as CallIntelligenceTypes.CallEditableFields['categories'];

        return resolvedValue && !isEmpty(resolvedValue) ? (
          <div css={styles.chipsWrapper}>
            {resolvedValue.map((category) => (
              <div key={category}>{options[category]}</div>
            ))}
          </div>
        ) : (
          <Text size='medium'>{emptyValue}</Text>
        );
      }

      case CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SENTIMENT:
      case CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_CONTACT_TYPE:
        return !isEmpty(value) ? options[value] : <Text size='medium'>{emptyValue}</Text>;

      case CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME: {
        let schedulingOpportunity = false;

        if (isOriginal) {
          schedulingOpportunity = !!allFields.schedulingOpportunity;
        } else {
          const schedulingOpportunityEdit = data?.current.find(
            (edit) => edit.analysisType === CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY
          );

          if (schedulingOpportunityEdit === undefined) {
            schedulingOpportunity = !!data?.original.schedulingOpportunity;
          } else {
            schedulingOpportunity = !!schedulingOpportunityEdit?.fields?.schedulingOpportunity;
          }
        }

        if (!schedulingOpportunity) {
          return <Text size='medium'>{emptyValue}</Text>;
        }

        return !isNull(value) ? (
          value ? (
            options[CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED]
          ) : (
            options[CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED]
          )
        ) : (
          <Text size='medium'>{emptyValue}</Text>
        );
      }

      case CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_APPOINTMENT_TYPE: {
        const resolvedValue = value as CallIntelligenceTypes.AppointmentTypeEnum[] | null;

        return resolvedValue && !isEmpty(resolvedValue) ? (
          <div css={styles.chipsWrapper}>
            {resolvedValue.map((appointmentType) => (
              <div key={appointmentType}>{options[appointmentType]}</div>
            ))}
          </div>
        ) : (
          <Text size='medium'>{emptyValue}</Text>
        );
      }

      case CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SERVICE_QUALITY: {
        const resolvedValue = value as CallIntelligenceTypes.CallEditableFields['serviceQualityFlags'];

        if (!resolvedValue || isEmpty(resolvedValue)) {
          return <Text size='medium'>{emptyValue}</Text>;
        }

        if (isOriginal) {
          return (
            <>
              {resolvedValue.map(({ isDisabled, serviceQualityFlag }) =>
                !isDisabled ? (
                  getOptions(optionType)[serviceQualityFlag]
                ) : (
                  <Text size='medium' key={serviceQualityFlag}>
                    {emptyValue}
                  </Text>
                )
              )}
            </>
          );
        }

        return (
          <>
            {resolvedValue.map(({ isDisabled, serviceQualityFlag }) =>
              !isDisabled
                ? getOptions(optionType)[serviceQualityFlag]
                : getOptions(optionType, 'record', { disabled: true })[serviceQualityFlag]
            )}
          </>
        );
      }
    }
  };

  const editHistoryItems = useMemo(() => {
    if (!data) return [];
    const { original, current } = data;

    return current.map((edit) => {
      const { analysisType, fields, editedBy, editedAt } = edit;
      const { type, label } = FIELD_MAPPING[analysisType];

      type FieldValueType = CallIntelligenceTypes.CallEditableFields[typeof type];

      // Default values
      let originalValue: FieldValueType = original[type];
      let recentValue: FieldValueType = fields[type];
      let canRestore = JSON.stringify(originalValue) !== JSON.stringify(recentValue);

      if (analysisType === CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_APPOINTMENT_TYPE) {
        originalValue = (original.appointmentTypes?.appointmentType ?? original.appointmentTypes) as FieldValueType;
        recentValue = (fields.appointmentTypes?.appointmentType ?? fields.appointmentTypes) as FieldValueType;
      } else if (analysisType == CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME) {
        const schedulingOpportunityEdit = data?.current.find(
          (edit) => edit.analysisType === CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY
        );
        const isOpportunityTrue = !!schedulingOpportunityEdit?.fields?.schedulingOpportunity;

        const adjustedOriginalValue = original.schedulingOpportunity ? originalValue : originalValue || 'none';
        const adjustedRecentValue = isOpportunityTrue ? recentValue : recentValue || 'none';

        canRestore = JSON.stringify(adjustedOriginalValue) !== JSON.stringify(adjustedRecentValue);
      }

      return {
        analysisType,
        canRestore,
        editedBy: `${editedBy.firstName} ${editedBy.lastName || ''}`.trim(),
        editedDate: dayjs(editedAt).format('MMM D, YYYY, h:mm A'),
        fieldLabel: label,
        originalValue,
        OriginalOption: renderOptions({ allFields: original, value: originalValue, analysisType, isOriginal: true }),
        recentValue,
        RecentionOption: renderOptions({ allFields: fields, value: recentValue, analysisType, isOriginal: false }),
      };
    });
  }, [data]);

  const handleRestore = (type: CallIntelligenceTypes.AnalysisTypeEnum) => {
    setRestoreConfig({
      isRestoreOpen: true,
      analysisType: type,
    });
  };

  const handleRestoreCancel = () => {
    setRestoreConfig({ isRestoreOpen: false, analysisType: '' });
  };

  const handleRestoreOriginal = () => {
    const user = getUser();
    const selectedAnalysisType = restoreConfig.analysisType;

    const editableField = FIELD_MAPPING[selectedAnalysisType as CallIntelligenceTypes.AnalysisTypeEnum]?.type;

    const originalAnalysisValue = data?.original[editableField];
    const mostRecentEdit = data?.current.find((edit) => edit.analysisType === selectedAnalysisType);

    // Add additional logic for dependent analysis types
    let additionalEditedFields = {};
    let additionalCurrentFields = {};

    if (selectedAnalysisType === CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY) {
      additionalEditedFields = {
        schedulingOutcome: data?.original['schedulingOutcome'],
      };

      const mostRecentEdit = data?.current.find(
        (edit) => edit.analysisType === CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME
      );

      additionalCurrentFields = {
        schedulingOutcome: mostRecentEdit?.fields?.schedulingOutcome,
      };
    } else if (selectedAnalysisType === CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OUTCOME) {
      additionalEditedFields = {
        schedulingOpportunity: data?.original['schedulingOpportunity'],
      };

      const mostRecentEdit = data?.current.find(
        (edit) => edit.analysisType === CallIntelligenceTypes.AnalysisTypeEnum.ANALYSIS_TYPE_SCHEDULING_OPPORTUNITY
      );

      additionalCurrentFields = {
        ...(mostRecentEdit && { schedulingOpportunity: mostRecentEdit?.fields?.schedulingOpportunity }),
      };
    }

    restoreApi({
      callId: call?.id as string,
      type: 'restore',
      location_id: call?.locationId as string,
      user_id: user?.userID as string,
      current_fields: { [editableField]: mostRecentEdit?.fields?.[editableField], ...additionalCurrentFields },
      edited_fields: {
        [editableField]: originalAnalysisValue,
        ...additionalEditedFields,
      },
    });
  };

  useEffect(() => {
    if (pendingUpdateCallInList) {
      updateEditedCallInList(pendingUpdateCallInList, true);
      setPendingUpdateCallInList(null);
    }
  }, [pendingUpdateCallInList]);

  if (restoreConfig.isRestoreOpen) {
    const { analysisType } = restoreConfig;

    return (
      <RestoreHistoryModal
        analysisType={FIELD_MAPPING[analysisType as CallIntelligenceTypes.AnalysisTypeEnum].label}
        dependentAnalysis={FIELD_MAPPING[analysisType as CallIntelligenceTypes.AnalysisTypeEnum].dependentAnalysis.map(
          (analysis) => FIELD_MAPPING[analysis].label
        )}
        modalProps={modalProps}
        onClose={handleRestoreCancel}
        onRestore={handleRestoreOriginal}
      />
    );
  }

  return (
    <Modal {...modalProps} maxWidth={600} css={styles.modalWrapper} onClose={onClose}>
      <Modal.Header onClose={onClose}>{t('Call Takeaways Edit History')}</Modal.Header>
      <Modal.Body css={styles.body}>
        <div css={styles.callerInfo}>
          <UserCard
            firstName={call?.person?.firstName}
            lastName={call?.person?.lastName}
            key={call?.person?.id}
            openProfileOnClick={!isDemoAccount && !isContactPanelOpen}
            phoneNumber={callIntelligenceUtils.getPhoneNumber(call?.phoneNumber)}
            rightElement={
              call?.direction === CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND ? (
                <span style={{ color: theme.colors.neutral70 }}>
                  <PhoneOutgoingIcon size={16} />
                </span>
              ) : null
            }
            showOnlyName
            userId={call?.person?.id || ''}
            locationId={call?.locationId}
          />

          <Text>{dayjs(call?.startTime).format('MMM D, YYYY, h:mm A')}</Text>
        </div>

        {isOnlyPoorAudio && (
          <CallAnalyzeStatus
            css={css`
              margin-top: ${theme.spacing(-2)};
              margin-bottom: ${theme.spacing(0)};
            `}
            isFailedCall={isFailedCall}
            isPoorAudio={isPoorAudio}
            isSkippedCall={isSkippedCall}
            type='editHistory'
          />
        )}

        {isLoading ? (
          <HistoryLoader count={3} />
        ) : (
          editHistoryItems.map((item) => (
            <div key={item.analysisType} css={styles.historyItemWrapper}>
              <div css={styles.historyHeadingWrapper}>
                <Text weight='bold'>{item.fieldLabel}</Text>
                <Button
                  variant='tertiary'
                  destructive
                  iconName='send-back'
                  disabled={!item.canRestore}
                  onClick={() => handleRestore(item.analysisType)}
                  trackingId={generateTrackingId({
                    component: 'edit-history',
                    context: 'restore-btn-click',
                    isDemoAccount,
                    abTestGroup: isVariantB ? 'design-B' : 'design-A',
                  })}
                >
                  {t('Restore')}
                </Button>
              </div>
              <div css={styles.editHistoryGrid}>
                <div css={styles.gridRow}>
                  {/* Original Analysis */}
                  <div css={styles.gridColumn}>
                    <Text color='subdued' size='medium'>
                      {t('Original Analysis')}
                    </Text>
                    <div css={styles.gridValueCell}>{item.OriginalOption}</div>
                  </div>

                  <div css={styles.gridColumn}>
                    {/* Most Recent Edit */}
                    <Text color='subdued' size='medium'>
                      {t('Most Recent Edit')}
                    </Text>
                    <div css={styles.gridValueCell}>
                      {item.RecentionOption}
                      {!item.canRestore && (
                        <Text size='small' color='subdued' css={styles.restoredText}>
                          {t('Restored')}
                        </Text>
                      )}
                    </div>
                  </div>

                  <div css={styles.gridColumn}>
                    {/* Edit Details */}
                    <Text color='subdued' size='medium'>
                      {t('Edit Details')}
                    </Text>
                    <div css={styles.gridValueCell}>
                      <Text size='medium'>{item.editedBy} </Text>
                      <Text size='medium'> {dayjs(item.editedDate).format('MMM D, YYYY, h:mm A')}</Text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  modalWrapper: css`
    width: 100%;
    @media screen and (min-width: 648px) {
      width: 100%;
    }
  `,
  body: css`
    gap: ${theme.spacing(3)};
  `,
  callerInfo: css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.spacing(2, 0)};
    border-top: 1px solid ${theme.colors.neutral20};
    border-bottom: 1px solid ${theme.colors.neutral20};

    @media screen and (max-width: ${breakpoints.small.min}px) {
      align-items: flex-start;
      flex-direction: column;
      gap: ${theme.spacing(1)};
    }
  `,
  historyItemWrapper: css`
    padding: ${theme.spacing(1.5, 2)};
    display: flex;
    gap: ${theme.spacing(1)};
    flex-direction: column;
    border: 1px solid ${theme.colors.neutral20};
    border-radius: ${theme.borderRadius.small};
  `,
  historyHeadingWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  editHistoryGrid: css`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: ${theme.spacing(2)};
    row-gap: ${theme.spacing(1)};

    @media screen and (max-width: ${breakpoints.small.min}px) {
      grid-template-columns: 1fr;
    }
  `,
  gridColumn: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(1)};
  `,
  gridRow: css`
    display: contents; /* Allows grid items to span rows */
    align-items: center;

    @media screen and (max-width: ${breakpoints.small.min}px) {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      gap: ${theme.spacing(2)};
    }
  `,
  gridValueCell: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  chipsWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1)};
  `,
  restoredText: css`
    margin-top: ${theme.spacing(0.5)};
    font-style: italic;
  `,
};

export default EditHistoryModal;
