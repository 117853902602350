import { useState } from 'react';
import { css } from '@emotion/react';
import { TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { formatCentsToCurrency } from '@frontend/currency';
import { SendInThreadModalContents } from '@frontend/integrated-messaging';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { ContentLoader, formatDate, Text } from '@frontend/design-system';
import { useValidPhoneNumberFromInvoice } from '../hooks';
import { useBnplLink } from './hooks';

interface ShareInMessageProps {
  isBnplRequest?: boolean;
}

const styles = {
  loader: css`
    height: 200px;
  `,
};

export const ShareInMessage = ({ isBnplRequest = false }: ShareInMessageProps) => {
  const { invoice } = useSelectedInvoice();
  const { firstValidPhoneNumber } = useValidPhoneNumberFromInvoice(invoice);
  const { closeModal, goBack } = useMultiStepModal();

  const [selectedPhone, setSelectedPhone] = useState<string>(firstValidPhoneNumber ?? '');
  const { bnplTtpLink, bnplLinkLoading } = useBnplLink({ personPhone: selectedPhone, isBnplRequest });

  if (!invoice) return null;
  if (bnplLinkLoading && isBnplRequest)
    return (
      <section css={styles.loader}>
        <ContentLoader show={true} />
      </section>
    );

  return (
    <SendInThreadModalContents
      groupId={invoice.locationId}
      onBack={goBack}
      personId={invoice.person.id || ''}
      initPersonPhone={firstValidPhoneNumber}
      templateType={TemplateType_Slug.MANUAL_PAYMENT_REQUEST}
      linkData={{
        link: isBnplRequest && !!bnplTtpLink ? bnplTtpLink : invoice?.links?.payment ?? '',
        relatedId: invoice.id,
      }}
      onThreadChange={({ personPhone }) => setSelectedPhone(personPhone)}
      onSchedule={closeModal}
      contextPreviewProps={{
        iconName: 'dollar-sign',
        title: <Text weight='bold'>{formatCentsToCurrency(invoice?.billedAmount)}</Text>,
        subtitle: <Text>Due Date: {formatDate(invoice?.billedAt, 'MMM D, YYYY')}</Text>,
      }}
    />
  );
};
