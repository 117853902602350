import { ReactNode, useLayoutEffect } from 'react';
import { useMultiStepModal } from './use-multi-step-modal';

export interface ModalStepProps {
  id: string;
  children: ReactNode;
  title: string;
  hideModalHeader?: boolean;
  disableCloseOnOverlayClick?: boolean;
  disableCloseOnEscape?: boolean;
  blueHeader?: boolean;
  hideBackButton?: boolean;
}

export const ModalStep = ({
  children,
  id,
  title,
  disableCloseOnOverlayClick = false,
  disableCloseOnEscape = false,
  hideModalHeader = false,
  blueHeader = false,
  hideBackButton,
}: ModalStepProps) => {
  const {
    hideBackButtonDefault,
    activeStep,
    setTitle,
    setDisableCloseOnOverlayClick,
    setDisableCloseOnEscape,
    setHideHeader,
    setBlueHeader,
    setHideBackButton,
  } = useMultiStepModal();
  const isActiveStep = activeStep === id;

  useLayoutEffect(() => {
    if (isActiveStep) {
      if (title) {
        setTitle?.(title);
      }
      setDisableCloseOnOverlayClick(disableCloseOnOverlayClick);
      setDisableCloseOnEscape(disableCloseOnEscape);
      setHideHeader(hideModalHeader);
      setBlueHeader(blueHeader);
      setHideBackButton(hideBackButton ?? hideBackButtonDefault);
    }
  }, [
    title,
    isActiveStep,
    disableCloseOnOverlayClick,
    disableCloseOnEscape,
    hideModalHeader,
    blueHeader,
    hideBackButton,
    hideBackButtonDefault,
  ]);

  if (!isActiveStep) return null;
  return children;
};
