import { FC, useRef, useCallback } from 'react';
import { isWeaveUser } from '@frontend/auth-helpers';
import { PaddedContent } from '@frontend/chat';
import { ErrorAccordion, ErrorMessage } from '@frontend/error-boundary';
import { PanelPortal } from '@frontend/panel-engine';
import { theme } from '@frontend/theme';
import { Button, Text } from '@frontend/design-system';
import { useTeamChatFocus } from '../hooks';
import { useTeamChatStore } from '../providers';
import { ChatListView } from './chat-list/chat-list-view/chat-list-view';
import { paddedStyle, panelStyle, weaveUserContainerStyle, chatsContainerStyle } from './chat-tray.styles';
import { Conversation } from './conversation';
import { TrayTopBar } from './tray-top-bar/tray-top-bar';

export const ChatPanel: FC = () => {
  const { isTrayOpen, setTrayOpen, errorObject } = useTeamChatStore(['setTrayOpen', 'isTrayOpen', 'errorObject']);
  const chatRef = useRef<HTMLDivElement | null>(null);

  useTeamChatFocus({ chatRef });

  let content = errorObject ? (
    <div css={paddedStyle}>
      <TrayTopBar onClose={() => setTrayOpen(!isTrayOpen)} />
      <div style={{ display: 'grid', gap: theme.spacing(2) }}>
        <ErrorMessage error={errorObject.error} />
        <ErrorAccordion error={errorObject.error} eventId={errorObject.eventId} />
      </div>
    </div>
  ) : (
    <>
      <TrayTopBar onClose={() => setTrayOpen(!isTrayOpen)} />
      <div css={chatsContainerStyle}>
        <ChatListView />
        <Conversation />
      </div>
    </>
  );

  const onClose = useCallback(() => setTrayOpen(false), [setTrayOpen]);

  if (isWeaveUser()) {
    content = (
      <PaddedContent css={paddedStyle}>
        <div css={weaveUserContainerStyle}>
          <Button iconName='x' aria-label='close' variant='secondary' onClick={() => setTrayOpen(!isTrayOpen)} />
        </div>
        <Text>Weave users do not have access to Team Chat</Text>
      </PaddedContent>
    );
  }

  return (
    isTrayOpen && (
      <PanelPortal enableAnimations id='teamchat' width='smallXXL' onClose={onClose} css={panelStyle} ref={chatRef}>
        {content}
      </PanelPortal>
    )
  );
};
