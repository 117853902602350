import { css } from '@emotion/react';
import { RefundSummary } from '@frontend/api-refunds';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { SummaryItem } from '../../';
import { usePaymentRole } from '../../../hooks';

export * from './date-filters';

export type RefundSummaryProps = {
  summary?: RefundSummary;
  loading: boolean;
  displayDateLabel?: string;
};

export const RefundSummaryCard = ({ summary, loading }: RefundSummaryProps) => {
  const { t } = useTranslation('payments');
  const { locationId } = useMerchant();
  const { hasAnalyticsRole } = usePaymentRole(locationId);

  return (
    <>
      {hasAnalyticsRole && (
        <div
          css={css`
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            max-width: 720px;
            flex-wrap: wrap;
          `}
        >
          <SummaryItem
            label={t('Amount Refunded')}
            amount={summary?.totalAmount}
            loading={loading}
            infoText={t(
              'Total amount refunded from your account. Active report filters will impact the displayed data.'
            )}
            data-trackingid='pay-portal-invoices-btn-amountprocessedhint'
          />
        </div>
      )}
    </>
  );
};
