import { useCallback, MouseEvent } from 'react';
import { useEventListener } from '@frontend/event';

export type SelectableIcons =
  | 'location'
  | 'notifications'
  | 'profile'
  | 'chat'
  | 'taskTray'
  | 'multiLocations'
  | 'quickfill'
  | 'schedulePulse'
  | 'chatV2'
  | 'actions';

const SET_SELECTED_TOP_BAR_TRAY = 'setSelectedTopBarTray';

type TopBarTraySelectionEvent = CustomEvent<{ icon: SelectableIcons; mouseEvent?: MouseEvent }>;

/**
 * Dispatches a custom event to select top bar tray icon (which ultimately open panel as well).
 */
export const dispatchTopBarTraySelectionEvent = (icon: SelectableIcons, mouseEvent?: MouseEvent) => {
  const event: TopBarTraySelectionEvent = new CustomEvent(SET_SELECTED_TOP_BAR_TRAY, {
    detail: { icon, mouseEvent },
  });
  window.dispatchEvent(event);
};

/**
 * Hook to listen for top bar tray icon selection custom event.
 */
export const useTopBarTraySelection = ({
  callback,
}: {
  callback: (icon: SelectableIcons, mouseEvent?: MouseEvent) => void;
}) => {
  const eventHandler = useCallback(
    (e: TopBarTraySelectionEvent) => callback(e.detail.icon, e.detail.mouseEvent),
    [callback]
  );

  useEventListener(SET_SELECTED_TOP_BAR_TRAY as any, eventHandler, true);
};
