import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { PhoneSyncButton } from '@frontend/phone-sync';
import { theme } from '@frontend/theme';
import { Button, Modal, Text } from '@frontend/design-system';
import { useDialpadEstablishConnectionControl } from '../providers/dialpad-connection-provider';

export const PairDeviceModal = () => {
  const { t } = useTranslation('dialpad');
  const pairDeviceModalProps = useDialpadEstablishConnectionControl((ctx) => ctx.pairDeviceModalProps);
  const { onClose } = pairDeviceModalProps;
  const phoneSyncButtonProps = useDialpadEstablishConnectionControl((ctx) => ctx.phoneSyncButtonProps);

  const closeModal = () => {
    pairDeviceModalProps.onClose();
  };

  return (
    <Modal {...pairDeviceModalProps} css={{ padding: theme.spacing(4, 1) }} maxWidth={400}>
      <Modal.Header textAlign='left' onClose={onClose}>
        {t('No Device Paired')}
      </Modal.Header>
      <Modal.Body>
        <div
          css={{
            paddingTop: theme.spacing(1),
          }}
        >
          <Text>{t('You must pair a device to enable the Dial Pad.')}</Text>
        </div>
      </Modal.Body>
      <Modal.Footer
        css={css`
          grid-template-columns: 1fr auto;
          justify-items: end;
        `}
      >
        <Button variant='secondary' onClick={onClose}>
          {t('Cancel')}
        </Button>
        <PhoneSyncButton
          {...phoneSyncButtonProps}
          onClick={closeModal}
          btnLabel={t('Pair New Device')}
          trackingId='phn-portal-dialpad'
        />
      </Modal.Footer>
    </Modal>
  );
};
