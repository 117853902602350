import { FC, PropsWithChildren, ReactNode, useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { FloatingNode, FloatingPortal, useFloatingNodeId } from '@floating-ui/react';
import { Navigate } from '@tanstack/react-location';
import {
  CreateTaskEventRequest,
  EventType,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { QueryClient, QueryClientProvider } from 'react-query';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { OnboardingModulesApi, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { SkipLinks } from '@frontend/accessibility';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { BillingAlertGlobalBanner } from '@frontend/billing-alerts';
import {
  ChatStrategyProvider,
  ChatTitleBar,
  streamStrategy,
  StreamChatComponent,
  useChatStatusShallowStore,
} from '@frontend/chat';
import { PageFrame } from '@frontend/components';
import { useDevToolsStore } from '@frontend/devtools';
import { DialpadManagerProvider } from '@frontend/dialpad';
import { ErrorBoundary, MainBoundary, NavBarBoundary, TopBarBoundary } from '@frontend/error-boundary';
import { LargeDownloads, largeDownloadsRef } from '@frontend/file-download-helper';
import { GlobalBannerDynamic, GlobalBannerManager, GlobalBannerProvider } from '@frontend/global-info-banner';
import { InboxChatComponent, InboxTitleBar } from '@frontend/inbox';
import { MessagingComplianceBanners } from '@frontend/messaging-compliance';
import { NotificationProvider, NotificationQueue } from '@frontend/notifications';
import { PanelLayout } from '@frontend/panel-engine';
import { useCallPopDemo } from '@frontend/phone';
import { CallPopNotification } from '@frontend/pop';
import { PopupBarManagerProvider, PopupBarManager, ExtensiblePopup } from '@frontend/popup-bar';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { useHasFeatureFlag } from '@frontend/shared';
import { TaskTraySidePanel } from '@frontend/task-tray';
import { TeamChatProvider, TeamChatWrapper, ChatPanel, useTeamChatStore } from '@frontend/team-chat';
import { TourGuideComponent } from '@frontend/tour-guide';
import { WeaveLoader } from '@frontend/weave-loader';
import { SoftphoneManager } from '@frontend/weave-softphone-manager';
import { ContentLoader } from '@frontend/design-system';
import { GlobalNav, IntakeFormNav, NavContextProvider } from '../layout';
import { AppStatusProvider } from '../providers/app-status.provider';
import { useApp } from '../providers/app.provider';
import { sharedQueryCache } from '../utils/query-cache';
import { WebsocketSubscriptionsProvider } from '../websocket-subscriptions/websocket-subscriptions-provider';
import { CommandPalette } from './command-palette';
import { DevTools } from './devtools';
import { InfoModal } from './info-modal';
import { LocationPickerPage } from './location-pickers/location-picker-page';
import { ResponsiveSlidePanel } from './responsive-slide-panel';
import { SalesDemoFeatureModal } from './sales-demo-feature-modal';
import { SettingsModal } from './settings/settings-modal';
import { StackErrorPage } from './stack-error-page';
import { SimpleTopBar, TopBar } from './top-bar';
import { RibbonManager } from './top-bar-ribbons/ribbon-manager';
import { AppStateType, useInitializeApp } from './use-initialize-app';

const ConditionalPageFrame: FC<PropsWithChildren<{ hasPageFrame: boolean }>> = ({ children, hasPageFrame }) =>
  hasPageFrame ? <PageFrame>{children}</PageFrame> : <>{children}</>;

const Nav = () => {
  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');

  return isShowIntakeForm ? <IntakeFormNav /> : <GlobalNav />;
};

export const AuthenticatedAppWrapper = ({ children }: { children: ReactNode }) => {
  const appState = useInitializeApp();

  return (
    <ErrorBoundary fallback={MainBoundary}>
      <AuthenticatedApp appState={appState}>{children}</AuthenticatedApp>
    </ErrorBoundary>
  );
};

const queryClient = new QueryClient({
  queryCache: sharedQueryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    },
  },
});

const AuthenticatedApp = ({ children, appState }: { children: ReactNode; appState: AppStateType }) => {
  if (appState.state === 'LOADING' || appState.state === 'REDIRECTING') {
    return <WeaveLoader />;
  } else if (appState.state === 'ONBOARDING') {
    return (
      <>
        <ContentLoader show />
        <Navigate to={appState.meta.onboardingRedirectUrl} replace />
      </>
    );
  }

  /**
   * Before this point, the actual app (Providers and content) has not been rendered yet.
   * Once we have initialized location data, we can proceed with rendering the app.
   *
   */

  let content = null;
  const isTeamChatV2Enabled = useHasFeatureFlag('team-chat-2.0');

  const hasActionBar = useMatchMedia({ minWidth: breakpoints.small.min });

  if (appState.state === 'INITIALIZED') {
    content = (
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('topic', 'errorBoundary');
          scope.setLevel('fatal');
        }}
        fallback={(props) => <StackErrorPage {...props} />}
      >
        {children}
      </ErrorBoundary>
    );
  } else if (appState.state === 'LOCATION_SELECTION') {
    return (
      <TeamChatProvider>
        <div
          css={(theme) => ({
            display: 'grid',
            gridTemplateRows: 'auto 1fr',
            height: `calc(100% - ${theme.heightOffset}px)`,
            overflow: 'auto',
          })}
        >
          <SimpleTopBar />
          <LocationPickerPage />
        </div>
      </TeamChatProvider>
    );
  }

  /**
   * We wrap the Main content and Settings modal in a different query client provider so that we can use default settings for those components,
   * versus the one used in the top level of the app.
   *
   * The difference is that we turn on the error boundary for the top level components, but team-implemented pages will not trigger the error boundary from a React Query perspective.
   * This will change in the future, but we want to ensure that teams handle their errors before turning on the error boundary for all components.
   */
  return (
    <>
      <GlobalBannerProvider>
        <AppProviders>
          <TopBarContainer />
          <RibbonManager />
          <GlobalBannerSystem />
          <div
            id='main-container'
            style={{
              display: 'flex',
              overflow: 'hidden',
              height: '100%',
              position: 'relative',
            }}
          >
            <div
              id='app-container'
              style={{
                width: hasActionBar ? 'calc(100% - 57px)' : '100%',
                display: 'flex',
                overflow: 'hidden',
                height: '100%',
                position: 'relative',
              }}
            >
              <ErrorBoundary fallback={NavBarBoundary}>
                <Nav />
              </ErrorBoundary>
              <ConditionalPageFrame hasPageFrame={true}>
                <QueryClientProvider client={queryClient}>
                  <Main>{content}</Main>
                </QueryClientProvider>
              </ConditionalPageFrame>
              <ResponsiveSlidePanel />
              <PanelLayout />
              <TaskTraySidePanel />
              {isTeamChatV2Enabled && <ChatPanel />}
            </div>
          </div>
          <QueryClientProvider client={queryClient}>
            <Settings appState={appState} />
          </QueryClientProvider>
        </AppProviders>
        <Miscellaneous />
      </GlobalBannerProvider>
    </>
  );
};

const TopBarContainer = () => {
  return (
    <ErrorBoundary fallback={TopBarBoundary}>
      <TopBar PopupBarManager={PopupBarManager} ChatProviders={ChatProviders} />
    </ErrorBoundary>
  );
};

const AppProviders = ({ children }: { children: ReactNode }) => {
  const { setShouldPreventUpdate } = useApp();
  const { setPopupStatus } = useChatStatusShallowStore('setPopupStatus');
  const showTools = hasSchemaACL('weave', Permission.DEV_TOOLS) || import.meta.env.MODE === 'development';

  const onActiveCall = useCallback(() => {
    setShouldPreventUpdate(true);
  }, []);
  const onAllCallsEnded = useCallback(() => {
    setShouldPreventUpdate(false);
  }, []);

  const onPopupBarChange = ({ popupList, activePopup }: { popupList: ExtensiblePopup[]; activePopup: string[] }) => {
    const hasActiveChatPopup =
      activePopup[0] && popupList.some((popup) => popup.type === 'chat' && activePopup[0].includes(popup.id));
    setPopupStatus(hasActiveChatPopup ? 'open' : 'closed');
  };

  return (
    <NotificationProvider>
      <DialpadManagerProvider>
        <SoftphoneManager onAllCallsEnded={onAllCallsEnded} onCallIsActive={onActiveCall}>
          <PopupBarManagerProvider
            components={{
              chat: {
                Content: StreamChatComponent,
                TitleBar: ChatTitleBar,
              },
              message: { Content: InboxChatComponent, TitleBar: InboxTitleBar },
            }}
            onChange={onPopupBarChange}
          >
            <WebsocketSubscriptionsProvider>
              <AppStatusProvider>
                <SkipLinks />
                <NavContextProvider>
                  {/* Adding a provider here so that new chat panel could have a provider. But the old chat
                  provider continue using the ChatStrategyProvider. When we migrate to the new chat we will
                  remove the old chat strategy provider. Hence conditionally using the providers. */}
                  <ChatProviderV2>{children}</ChatProviderV2>
                </NavContextProvider>
              </AppStatusProvider>
            </WebsocketSubscriptionsProvider>
          </PopupBarManagerProvider>
        </SoftphoneManager>
      </DialpadManagerProvider>
      <NotificationGutter>
        <CallPopNotification />
        <NotificationQueue />
      </NotificationGutter>
      {showTools && <DevTools />}
    </NotificationProvider>
  );
};

const Miscellaneous = () => {
  const showTools = hasSchemaACL('weave', Permission.DEV_TOOLS) || import.meta.env.MODE === 'development';

  const tourGuideActionHandler = useCallback(
    (eventInfo: CreateTaskEventRequest, taskType?: OnboardingModulesTypes.TaskType) => {
      const isCompleteTaskEvent = eventInfo.eventType === EventType.EVENT_COMPLETE_TASK;
      const isLocationBasedTask = taskType === OnboardingModulesTypes.TaskType.Location;

      if (isCompleteTaskEvent && isLocationBasedTask) {
        OnboardingModulesApi.markLocationTaskCompleted(
          eventInfo.taskId,
          OnboardingModulesTypes.TaskCompletionType.Modules
        );
      }

      OnboardingModulesApi.sendTaskEvent(eventInfo);
    },
    []
  );

  return (
    <>
      {showTools && <CommandPalette />}
      <LargeDownloads ref={largeDownloadsRef} />
      <InfoModal />
      <TourGuideComponent.TourGuide onAction={tourGuideActionHandler} />
      {<SalesDemoFeatureModal />}
    </>
  );
};

const Settings = ({ appState }: { appState: AppStateType }) => {
  return appState.state === 'INITIALIZED' ? <SettingsModal /> : null;
};

const Main = ({ children }: { children: ReactNode }) => {
  useCallPopDemo();

  return (
    <main
      id='main-content'
      tabIndex={0}
      css={css`
        width: 100%;
        overflow-y: auto;

        // min-width: 0 prevents inner flex components from causing this to overflow its bounds
        // https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
        min-width: 0;
      `}
    >
      {children}
    </main>
  );
};

const NotificationGutter = ({ children }: { children: ReactNode }) => {
  const nodeId = useFloatingNodeId();
  return (
    <FloatingNode id={nodeId}>
      <FloatingPortal>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: 0,
            top: 0,
          }}
        >
          {children}
        </div>
      </FloatingPortal>
    </FloatingNode>
  );
};

const ChatProviders = ({ children }: { children: ReactNode }) => {
  const isChatEnabled = useHasFeatureFlag('new-chat-experience');
  const isTeamChatV2Enabled = useHasFeatureFlag('team-chat-2.0');

  return (
    <ChatStrategyProvider enabled={isChatEnabled && !isTeamChatV2Enabled} strategy={streamStrategy}>
      {children}
    </ChatStrategyProvider>
  );
};

const ChatProviderV2 = ({ children }: { children: ReactNode }) => {
  const isTeamChatV2Enabled = useHasFeatureFlag('team-chat-2.0');
  if (!isTeamChatV2Enabled) {
    return <TeamChatProvider>{children}</TeamChatProvider>;
  }

  return (
    <TeamChatProvider>
      <TeamChatErrorBoundary>{children}</TeamChatErrorBoundary>
    </TeamChatProvider>
  );
};

const GlobalBannerSystem = () => {
  const {
    options: { isDebugModeOn },
  } = useDevToolsStore();

  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');

  return (
    <>
      <GlobalBannerManager />
      <BillingAlertGlobalBanner />
      {!isShowIntakeForm && <MessagingComplianceBanners.BusinessInformationBanner />}
      {isDebugModeOn && <ExampleGlobalBanners />}
    </>
  );
};

//This is temporary, as an example
const ExampleGlobalBanners = () => {
  const [show, setShow] = useState(true);

  return (
    <>
      {show && (
        <GlobalBannerDynamic
          id='example-id'
          type='error'
          title='Example'
          message="Whoops! Our cats knocked over the server while chasing a laser pointer. We're cleaning up the mess now."
          action={{
            label: 'Do Something',
            onClick: () => console.log('Whoops'),
          }}
          onDismiss={() => setShow(false)}
        />
      )}
      {/**
       * GlobalBannerDynamic components can be placed anywhere in the app! The moment they get rendered, they'll subscribe to the global banner system
       */}
      <GlobalBannerDynamic
        id='another-example-id'
        type='warning'
        title='Another Example'
        message="Whoops! Our cats knocked over the server while chasing a laser pointer. We're cleaning up the mess now."
        action={{
          label: 'Do Something',
          onClick: () => console.log('Whoops'),
        }}
        isDismissible={false}
      />
    </>
  );
};

const TeamChatErrorBoundary = ({ children }: { children: ReactNode }) => {
  const { setErrorObject } = useTeamChatStore(['setErrorObject']);

  return (
    <ErrorBoundary
      onError={(error, componentStack, eventId) => {
        setErrorObject(error as Error, componentStack, eventId);
      }}
      fallback={<>{children}</>}
    >
      <TeamChatWrapper>{children}</TeamChatWrapper>
    </ErrorBoundary>
  );
};
