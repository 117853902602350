import appConfig from '@frontend/env';
import { http } from '@frontend/fetch';
import { flattenWithDots } from '@frontend/uri';
import {
  PaymentPlanUpdateFields,
  PaymentPlanDetailsResponse,
  PaymentPlanDetailsHistoryResponse,
  CreatePlanData,
  PersonPaymentMethodsResponse,
  PaymentMethodResponse,
  PaymentPlanSearchParams,
  PaymentPlanListResponse,
  ClientSecretLinkResponse,
  ClientSecretResponse,
  PaymentMethod,
  CreatePlanResponse,
  PaymentPlanUpdateReviewResponse,
  PaymentPlanStatus,
  PlanConfirmationRequest,
  PlanConfirmationResponse,
  SendConfirmationEmailRequest,
  PaymentPlanConfirmationStatus,
  CreatePMRegistrationLinkRequest,
  CancelPlanResponse,
} from './types';

const { PAY_BACKEND_API } = appConfig;

const LOCATION_ID_HEADER = 'Location-Id';
const addLocationHeader = (locationId: string, headers: Record<string, string> = {}) => ({
  headers: { [LOCATION_ID_HEADER]: locationId, ...headers },
});

export const getPaymentPlanList = async (paymentsUrl: string | null, filter: PaymentPlanSearchParams) =>
  await http.getData<PaymentPlanListResponse>(`${paymentsUrl}/v2/paymentplans`, { params: flattenWithDots(filter) });

export const createPaymentPlan = async (paymentsUrl: string | null, planDetails: CreatePlanData) =>
  await http
    .post<CreatePlanResponse>(
      `${paymentsUrl}/v2/paymentplans`,
      { ...planDetails },
      { headers: { 'Content-Type': 'application/json' } }
    )
    .then((response) => response.data);

export const getPaymentPlanDetails = async (paymentsUrl: string | null, paymentPlanId: string) =>
  await http.get<PaymentPlanDetailsResponse>(`${paymentsUrl}/v2/paymentplans/${paymentPlanId}`);

export const getPaymentPlanOptions = async (paymentsUrl: string | null) =>
  await http.get(`${paymentsUrl}/v2/paymentplans/options`);

export const getPaymentPlanHistory = async (paymentsUrl: string | null, paymentPlanId: string) =>
  await http.get<PaymentPlanDetailsHistoryResponse>(`${paymentsUrl}/v2/paymentplans/${paymentPlanId}/history`);

export const deletePaymentPlan = async (paymentsUrl: string | null, paymentPlanId: string) =>
  await http.delete(`${paymentsUrl}/v2/paymentplans/${paymentPlanId}`, {
    headers: { 'Content-Type': 'application/json' },
  });

export const cancelPaymentPlan = async (paymentsUrl: string | null, paymentPlanId: string) =>
  await http.patch<CancelPlanResponse>(
    `${paymentsUrl}/v2/paymentplans/${paymentPlanId}`,
    { status: PaymentPlanStatus.Canceled },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const reviewUpdatePaymentPlan = async (
  paymentsUrl: string | null,
  paymentPlanId: string,
  updates: PaymentPlanUpdateFields
) =>
  await http.patch<PaymentPlanUpdateReviewResponse>(
    `${paymentsUrl}/v2/paymentplans/${paymentPlanId}/review`,
    { ...updates },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const updatePaymentPlan = async (
  paymentsUrl: string | null,
  paymentPlanId: string,
  updates: PaymentPlanUpdateFields
) =>
  await http.patch<PlanConfirmationResponse>(
    `${paymentsUrl}/v2/paymentplans/${paymentPlanId}`,
    { ...updates },
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

export const pausePaymentPlan = async (paymentsUrl: string | null, paymentPlanId: string, reason: string) =>
  await http.patch<PlanConfirmationResponse>(
    `${paymentsUrl}/v2/paymentplans/${paymentPlanId}`,
    {
      status: PaymentPlanStatus.Paused,
      confirmationStatus: PaymentPlanConfirmationStatus.PausedByOffice,
      notes: reason,
    },
    { headers: { 'Content-Type': 'application/json' } }
  );

export const resumePaymentPlan = async (paymentsUrl: string | null, paymentPlanId: string, resumeDate: string) =>
  await http.patch<PlanConfirmationResponse>(
    `${paymentsUrl}/v2/paymentplans/${paymentPlanId}`,
    { status: PaymentPlanStatus.Active, startAt: resumeDate },
    { headers: { 'Content-Type': 'application/json' }, responseType: 'none' }
  );

// PAYMENT METHOD APIS

// Gets all payment methods sorted by person id for a location
export const getLocationPaymentMethods = async (paymentsUrl: string | null, locationId: string) => {
  const { personPaymentMethods, links } = await http.getData<PersonPaymentMethodsResponse>(
    `${paymentsUrl}/locations/${locationId}/paymentMethods`,
    addLocationHeader(locationId)
  );
  return { personPaymentMethods, links };
};

// Gets details on person payment method id
export const getPersonPaymentMethod = async (
  paymentsUrl: string | null,
  paymentMethodId: string,
  locationId: string
) => {
  const { paymentMethod } = await http.getData<PaymentMethodResponse>(
    `${paymentsUrl}/paymentMethods/${paymentMethodId}`,
    addLocationHeader(locationId)
  );
  return paymentMethod;
};

export const deletePaymentMethod = async ({
  paymentsUrl,
  paymentMethodId,
  locationId,
}: {
  paymentsUrl: string;
  paymentMethodId: string;
  locationId: string;
}) => await http.delete(`${paymentsUrl}/paymentMethods/${paymentMethodId}`, null, addLocationHeader(locationId));

// Gets all payment methods associated with a person
export const getPersonPaymentMethods = async (paymentsUrl: string | null, patientId: string, locationId: string) =>
  http.get<PaymentMethod[]>(`${paymentsUrl}/person/${patientId}/paymentMethods`, addLocationHeader(locationId));

// Sets default payment method with id
export const setDefaultPaymentMethod = async (
  paymentsUrl: string | null,
  paymentMethodId: string,
  locationId: string
) => {
  const paymentSet = await http.patch(
    `${paymentsUrl}/paymentMethods/${paymentMethodId}/default`,
    addLocationHeader(locationId, { 'Content-Type': 'application/json' })
  );
  return paymentSet;
};

// CLIENT SECRET APIS FOR STRIPE

export const getClientSecretLink = async (
  setupLink: string | undefined,
  data: { data: { personId?: string; locationId: string } }
) => {
  if (setupLink) {
    const response = await http.post<ClientSecretLinkResponse>(setupLink, data, {
      ...addLocationHeader(data.data.locationId, { 'Content-Type': 'application/json' }),
      responseType: 'none',
    });
    return response.headers.get('location');
  }
  return;
};

export const getClientSecret = async (secretLink: string | undefined, locationId: string) => {
  if (secretLink) {
    const response = await http.getData<ClientSecretResponse>(
      secretLink,
      addLocationHeader(locationId, { 'Content-Type': 'application/json' })
    );
    return response;
  }
  return;
};

export const getPaymentPlanConfirmationDetails = async (planId: string) =>
  http.get<PaymentPlanDetailsResponse>(`${PAY_BACKEND_API}/payments/v2/${planId}/confirm`, {
    headers: { 'Content-Type': 'application/json' },
    skipValidation: true,
  });

export const confirmPaymentPlan = (planConfirmationRequest: PlanConfirmationRequest, paymentsUrl?: string | null) =>
  http.patch<PlanConfirmationResponse, PlanConfirmationRequest>(
    `${paymentsUrl?.replace(/\/payments$/, '') ?? PAY_BACKEND_API}/payments/v2/confirm`,
    planConfirmationRequest,
    {
      headers: { 'Content-Type': 'application/json' },
      skipValidation: true,
    }
  );

export const sendConfirmationEmail = (
  sendConfirmationEmailRequest: SendConfirmationEmailRequest,
  paymentsUrl: string
) =>
  http.post<unknown, SendConfirmationEmailRequest>(
    `${paymentsUrl}/v2/paymentplans/authorize`,
    sendConfirmationEmailRequest,
    { headers: { 'Content-Type': 'application/json' } }
  );

export const createPaymentRegistrationLink = ({
  personId,
  email,
  locationId,
  paymentsUrl,
}: CreatePMRegistrationLinkRequest) =>
  http.post<unknown, Omit<CreatePMRegistrationLinkRequest, 'paymentsUrl'>>(
    `${paymentsUrl}/commands/service/person/${personId}/paymentMethods/registration:link`,
    { email, personId, locationId },
    addLocationHeader(locationId)
  );
