import {
  CalendarException,
  ListAppointmentsResponse,
  ListCalendarExceptionReply,
} from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import {
  OfficeHoursDaySchedule,
  OfficeHoursForMultiLocation,
} from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { RecurringRules } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { UseQueryResult } from 'react-query';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ScheduleAvailabilityHoursTypes } from '@frontend/schedule-availability-hours';

export type OfficeHoursDayScheduleType = OfficeHoursDaySchedule & {
  providerId?: string;
};

export type AppointmentsCalendarViewContextValueType = {
  selectedLocationIds: string[];
  defaultFilteredLocationIds: string[];
  providersList: ScheduleTypes.SchemaProvider[];
  selectedDate: string | string[];
  setSelectedDate: (date: string) => void;
  appointments: ListAppointmentsResponse;
  officeHours?: OfficeHoursForMultiLocation[];
  providersExceptions?: CalendarException[];
  providersOfficeHours?: OfficeHoursDayScheduleType[];
  officeHoursExceptions?: CalendarException[];
  setSelectedMultiLocationIds: (locationIds: string[]) => void;
};

export type CalendarViewRefetchMethodContext = {
  refetchProvidersExceptions: UseQueryResult<ListCalendarExceptionReply>['refetch'];
  refetchOfficeExceptions: UseQueryResult<ListCalendarExceptionReply>['refetch'];
  refetchAppointments: UseQueryResult<ListAppointmentsResponse>['refetch'];
};

export type ScheduleActionsContainerTypes = {
  setHideTabs: (val: boolean) => void;
  hideTabs: boolean;
  selectedLocationId: string | null;
  setSelectedLocationId: (val: string | null) => void;
  closeModal?: () => void;
  selectedDate: string;
  setSelectedDate: (val: string) => void;
};

export type ScheduleAlertsContextType = {
  refetchScheduleAlerts: () => void;
  clearAlert: (id: string, locationId?: string) => void;
  setSelectedLocationId: (locationId: string) => void;
};

// RRule Utils Types

export const daysMap: { [key: string]: string } = {
  monday: 'MO',
  tuesday: 'TU',
  wednesday: 'WE',
  thursday: 'TH',
  friday: 'FR',
  saturday: 'SA',
  sunday: 'SU',
};

export const daysMapReverse: { [key: number]: ScheduleAvailabilityHoursTypes.DayOfWeek } = {
  0: 'monday',
  1: 'tuesday',
  2: 'wednesday',
  3: 'thursday',
  4: 'friday',
  5: 'saturday',
  6: 'sunday',
};

type ScheduleHoursType = 'working_hours' | 'break_hours';

export type ConversionType = 'rrule_to_schedule_hours' | 'schedule_hours_to_rrule';

export type ScheduleHoursSessionMap = {
  days: string[];
  startTime: string;
  endTime: string;
  duration: number;
  type: ScheduleHoursType;
};

export type AddEntryParamsType = {
  scheduleHoursMap: Map<string, ScheduleHoursSessionMap>;
  dayAbbreviation: string;
  startTime: string;
  endTime: string;
  duration: number;
  type: ScheduleHoursType;
};

export type ScheduleHours = Omit<RecurringRules, 'override'>;

export enum ExceptionStatus {
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export type ExceptionListItem = {
  id: string;
  name: string;
  status: ExceptionStatus;
  startDatetime?: Date;
  endDatetime?: Date;
  duration: number;
};

export type HeaderFilterDataType = {
  label: string;
  id: string;
  locationId: string;
};

export type CalendarViewV3HeaderContextType = {
  selectedMultiLocationIds: string[];
  calendarEventsCount: number;
  unconfirmedCalendarEventsCount: number;
  practitionersList: HeaderFilterDataType[];
  appointmentTypeList: HeaderFilterDataType[];
  defaultPractitionerIds: string[];
  defaultAppointmentTypeIds: string[];
  defaultDate?: string;
  selectedFilteredLocationIds: string[];
  setSelectedFilteredLocationIds: (locationIds: string[]) => void;
};

export type SelectedFiltersType = {
  practitionerIds: string[];
  appointmentTypeIds: string[];
  isUnconfirmedStatusOnly: boolean;
};
export enum ExceptionTableFilter {
  Upcoming = 'Upcoming',
  Past = 'Past',
  All = 'All Exceptions',
}
export type CalendarEventContextType = {
  selectedLocationIds: string[];
  providersList: ScheduleTypes.SchemaProvider[];
};
