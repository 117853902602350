import { FallbackRender } from '@sentry/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, Modal, Text, useModalControl } from '@frontend/design-system';
import { ErrorDetails, ErrorMessage } from './common';

export const ButtonBoundary: FallbackRender = ({ error, eventId, resetError }) => {
  const { triggerProps, modalProps } = useModalControl();
  const { t } = useTranslation('base');

  if (!(error instanceof Error)) {
    return <Text weight='bold'>{t('Something went wrong.')}</Text>;
  }
  return (
    <div
      css={{
        display: 'grid',
        gridAutoFlow: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        css={{
          width: 40,
          height: 40,
          background: theme.colors.warning5,
          '.button-icon': {
            color: theme.colors.warning80,
          },
          ':hover': {
            background: theme.colors.warning10,
          },
        }}
        variant='secondary'
        iconName='warning-badge-small'
        {...triggerProps}
      />
      <Modal {...modalProps}>
        <Modal.Header onClose={modalProps.onClose}>{t('Error Details')}</Modal.Header>
        <Modal.Body>
          <ErrorMessage>{error.message}</ErrorMessage>

          <ErrorDetails error={error} eventId={eventId} />
        </Modal.Body>
        <Modal.Footer
          secondary={{ label: t('Close'), onClick: modalProps.onClose }}
          primary={{
            label: t('Try again'),
            onClick: resetError,
          }}
        />
      </Modal>
    </div>
  );
};
