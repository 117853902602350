import React, { useMemo } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceApi } from '@frontend/api-analytics';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text, TextField, useAlert, useFormField } from '@frontend/design-system';
import { useCallTakeawayPanelShallowStore } from '../hooks';
import { FIELD_MAPPING } from './edit-history-modal';

type SuggestionModalProps = {
  fieldType: string;
  modalProps: ModalControlModalProps;
  name?: string;
  onClose: () => void;
};

const SuggestionModal: React.FC<SuggestionModalProps> = ({ fieldType, modalProps, name = 'suggestion', onClose }) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();

  const { isDemoAccount, selectedCall: call } = useCallTakeawayPanelShallowStore('isDemoAccount', 'selectedCall');

  const fieldProps = useFormField({
    type: 'text',
    value: '',
  });

  const { analysisType, title, description } = useMemo(() => {
    const analysisType = Object.entries(FIELD_MAPPING).find(
      ([_, fieldConfig]) => fieldConfig.type === fieldType
    )?.[0] as keyof typeof FIELD_MAPPING | undefined;

    const field = analysisType ? FIELD_MAPPING[analysisType] : null;

    return {
      analysisType,
      title: field?.suggestion?.title,
      description: field?.suggestion?.description,
    };
  }, [fieldType]);

  const { mutate: createSuggestionApi } = useMutation({
    mutationFn: !isDemoAccount ? CallIntelligenceApi.createSuggestion : CallIntelligenceApi.noopMutationFn,
    onSuccess: () => {
      onClose();
      alert.success(t('Successfully submitted suggestion!'));
    },
    onError: () => {
      alert.error(t('Error submitting suggestion! Please try again.'));
    },
  });

  const handleSubmit = () => {
    const value = fieldProps.value.trim();
    if (value && analysisType) {
      const user = getUser();

      createSuggestionApi({
        callId: call?.id as string,
        location_id: call?.locationId as string,
        user_id: user?.userID as string,
        suggestion: value,
        type: analysisType,
      });
    }
  };

  const handleCancel = () => {
    fieldProps.onChange({ name, value: '' });
    onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={400} onClose={handleCancel}>
      <Modal.Header onClose={handleCancel}>{title}</Modal.Header>
      <Modal.Body>
        <Text>{description}</Text>
        <TextField
          {...fieldProps}
          css={styles.textField}
          clearable
          hideErrorText
          label=''
          name={name}
          placeholder={t('Suggestion')}
        />
      </Modal.Body>
      <Modal.Actions
        disablePrimary={!fieldProps.value}
        onPrimaryClick={handleSubmit}
        onSecondaryClick={handleCancel}
        primaryLabel={t('Submit')}
        secondaryLabel={t('Cancel')}
      />
    </Modal>
  );
};

const styles = {
  textField: css`
    margin-top: ${theme.spacing(2)};
  `,
};

export default SuggestionModal;
