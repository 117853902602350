import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Modal, useModalControl } from '@frontend/design-system';

type Props = {
  scheduleName: string;
  updating: boolean;
  onSubmit: () => void;
} & Pick<ReturnType<typeof useModalControl>, 'modalProps' | 'closeModal'>;

/// ~~~~~~~~~~~~~~~~~~~~ App/Settings/Phone/OfficeHours ~~~~~~~~~~~~~~~~~~~~
/// ~~~~~~~~~~~~~~~~~~~~ App/Portal/Departments/OfficeHours ~~~~~~~~~~~~~~~~~~~~
export const OfficeHoursDeleteModal = ({ modalProps, scheduleName, updating, closeModal, onSubmit }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });

  return (
    <Modal {...modalProps} maxWidth={568}>
      <Modal.Body>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}
        >
          {t('You are about to delete routing settings for {{scheduleName}}.', { scheduleName: scheduleName })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primary={{
          label: t('Confirm Delete'),
          onClick: onSubmit,
          disabled: updating,
          destructive: true,
        }}
        secondary={{
          label: t('Cancel'),
          onClick: closeModal,
          disabled: updating,
        }}
      />
    </Modal>
  );
};
