import { memo, useEffect, useMemo, useState } from 'react';
import { useLocation } from '@tanstack/react-location';
import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/appointment_type.pb';
import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { Schedule } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import dayjs from 'dayjs';
import { SchedulerV3Queries, SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { contextFactory } from '@frontend/design-system';
import {
  useGetAppointmentTypesV3Data,
  useGetCalendarEventsV3Data,
  useGetIntegrationDetails,
  useSchedulingLocationInfo,
  useGetPractitionersV3Data,
  useGetV3CalendarStartAndEndDateTime,
} from '../hooks';
import { useCalendarViewV3HeaderFilterStore } from '../stores/use-calendar-view-v3-header-filter-store';
import { useIntegrationDetailsShallowStore } from '../stores/use-integration-details';
import { calendarViewV3ApiQueryLimit } from '../utils';

type CalendarEventsV3ContextType = {
  selectedMultiLocationIds: string[];
  calendarEvents: CalendarEvent[];
  practitioners: Practitioner[];
  scheduleEntries: Schedule[];
  scheduleEntriesOfficeData: Schedule[];
  appointmentTypes: AppointmentType[];
  calendarEventsParams: SchedulerV3Types.ListCalendarEventsParamsType;
  calendarEventsTotalCount?: number;
  hasSelectedMultiLocationIds: boolean;
  isIntegratedOffice: boolean;
  isLoading: boolean;
  setCalendarEventsParams: (params: SchedulerV3Types.ListCalendarEventsParamsType) => void;
  setSelectedMultiLocationIds: (locationIds: string[]) => void;
  refetchCalendarEvents: () => void;
  refetchPractitionerData: () => void;
  refetchScheduleEntriesData: () => void;
  refetchScheduleEntriesOfficeData: () => void;
  refetchAppointmentTypesData: () => void;
  setHasSelectedMultiLocationIds: (hasSelectedMultiLocationIds: boolean) => void;
};

export const [CalendarEventV3Context, useCalendarEventV3Context] = contextFactory<CalendarEventsV3ContextType>(
  'useCalendarEventV3Context must be used within a CalendarEventV3Context'
);

type CalendarEventsV3ProviderProps = {
  children: React.ReactNode;
};
export const CalendarEventsV3Provider = memo(({ children }: CalendarEventsV3ProviderProps) => {
  const { current } = useLocation();
  const { isOpen: isSettingModalOpen } = useSettingsNavigate();
  const { selectedLocationIds } = useSchedulingLocationInfo();

  const [selectedMultiLocationIds, setSelectedMultiLocationIds] = useState<string[]>(selectedLocationIds ?? []);
  const [hasSelectedMultiLocationIds, setHasSelectedMultiLocationIds] = useState<boolean>(false);

  const { startDateTime, endDateTime } = useGetV3CalendarStartAndEndDateTime(dayjs().toString());

  const [calendarEventsParams, setCalendarEventsParams] = useState<SchedulerV3Types.ListCalendarEventsParamsType>({
    startDateTime,
    endDateTime,
    page: 1,
    limit: calendarViewV3ApiQueryLimit,
  });

  const { setIntegrationDetails, setIsIntegratedOffice } = useIntegrationDetailsShallowStore(
    'setIntegrationDetails',
    'setIsIntegratedOffice'
  );

  const {
    isIntegratedOffice,
    isLoading: isLoadingIntegrationDetails,
    data: integrationDetails,
  } = useGetIntegrationDetails({
    selectedLocationId: selectedLocationIds[0],
  });

  const {
    data: calendarEventsData,
    isLoading: isCalendarEventsLoading,
    refetch: refetchCalendarEvents,
  } = useGetCalendarEventsV3Data({
    calendarEventsParams,
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedMultiLocationIds,
  });

  const {
    data: practitionerData,
    isLoading: isLoadingPractitionerData,
    refetch: refetchPractitionerData,
  } = useGetPractitionersV3Data({
    showOnCalendar: true,
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedMultiLocationIds,
    pageConfig: {
      page: 1,
      limit: calendarViewV3ApiQueryLimit,
    },
  });

  const practitionerIds = useMemo(
    () => (practitionerData?.providers ?? []).map((provider) => provider.id),
    [practitionerData?.providers]
  );

  const {
    data: scheduleEntriesData,
    isLoading: isLoadingScheduleEntriesData,
    refetch: refetchScheduleEntriesData,
  } = SchedulerV3Queries.useListScheduleEntries({
    entityIds: practitionerIds,
    locationId: selectedLocationIds[0],
    opts: {
      enabled: !!selectedLocationIds[0] && !!practitionerIds.length,
    },
  });

  const {
    data: scheduleEntriesOfficeData,
    isLoading: isLoadingScheduleEntriesOfficeData,
    refetch: refetchScheduleEntriesOfficeData,
  } = SchedulerV3Queries.useListScheduleEntries({
    entityIds: selectedLocationIds,
    locationId: selectedLocationIds[0],
    opts: {
      enabled: !!selectedLocationIds?.length,
    },
  });

  const {
    data: appointmentTypesData,
    isLoading: isLoadingAppointmentTypesData,
    refetch: refetchAppointmentTypesData,
  } = useGetAppointmentTypesV3Data({
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedMultiLocationIds,
    pageConfig: {
      page: 1,
      limit: calendarViewV3ApiQueryLimit,
    },
  });

  // useEffect to reset filters when location is changed
  useEffect(() => {
    if (selectedLocationIds.length) {
      useCalendarViewV3HeaderFilterStore.reset();
    }
  }, [selectedLocationIds]);

  useEffect(() => {
    if (!isLoadingIntegrationDetails && integrationDetails) {
      setIntegrationDetails(integrationDetails);
      setIsIntegratedOffice(isIntegratedOffice);
    }
  }, [integrationDetails, isIntegratedOffice, isLoadingIntegrationDetails]);

  // useEffect to refetch data if changes are made in the global settings
  useEffect(() => {
    const hasVisitedProvidersSettings = current.hash === 'settings/schedule/manage-providers';
    const hasVisitedOfficeHoursSettings = current.hash === 'settings/schedule/office-hours';
    const hasVisitedAppointmentTypesSettings = current.hash === 'settings/schedule/appointment-types';

    if (!isSettingModalOpen && hasVisitedProvidersSettings) {
      refetchPractitionerData();
      refetchScheduleEntriesData();
    }

    if (!isSettingModalOpen && hasVisitedOfficeHoursSettings) {
      refetchScheduleEntriesOfficeData();
    }

    if (!isSettingModalOpen && hasVisitedAppointmentTypesSettings) {
      refetchAppointmentTypesData();
    }
  }, [isSettingModalOpen, current]);

  const isLoading =
    isCalendarEventsLoading ||
    isLoadingPractitionerData ||
    isLoadingScheduleEntriesData ||
    isLoadingAppointmentTypesData ||
    isLoadingIntegrationDetails ||
    isLoadingScheduleEntriesOfficeData;

  const calendarEventV3ContextValue = useMemo<CalendarEventsV3ContextType>(() => {
    return {
      calendarEvents: calendarEventsData?.events || [],
      practitioners: practitionerData?.providers || [],
      scheduleEntries: scheduleEntriesData?.schedules || [],
      scheduleEntriesOfficeData: scheduleEntriesOfficeData?.schedules || [],
      appointmentTypes: appointmentTypesData?.appointmentTypes || [],
      calendarEventsTotalCount: calendarEventsData?.totalCount || 0,
      calendarEventsParams,
      selectedMultiLocationIds,
      hasSelectedMultiLocationIds,
      isIntegratedOffice,
      isLoading,
      setHasSelectedMultiLocationIds,
      setSelectedMultiLocationIds,
      refetchCalendarEvents,
      refetchPractitionerData,
      refetchScheduleEntriesData,
      refetchScheduleEntriesOfficeData,
      refetchAppointmentTypesData,
      setCalendarEventsParams,
    };
  }, [
    calendarEventsData?.events,
    calendarEventsData?.totalCount,
    practitionerData?.providers,
    appointmentTypesData?.appointmentTypes,
    scheduleEntriesData?.schedules,
    scheduleEntriesOfficeData?.schedules,
    calendarEventsParams,
    selectedMultiLocationIds,
    isIntegratedOffice,
    hasSelectedMultiLocationIds,
    isLoading,
  ]);

  return (
    <CalendarEventV3Context.Provider value={calendarEventV3ContextValue}>{children}</CalendarEventV3Context.Provider>
  );
});

CalendarEventsV3Provider.displayName = 'CalendarEventsV3Provider';
