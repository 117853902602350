import { useEffect, useState } from 'react';
import { PaymentsTerminalApi } from '@frontend/api-payments-terminal';
import { useTranslation } from '@frontend/i18n';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useMultiStepModal } from '@frontend/payments-multistep-modal';
import { PaymentsTerminalController, TerminalReader } from '@frontend/payments-terminal-controller';
import { ConfirmationModal, useModalControl } from '@frontend/design-system';
import { CollectPaymentModalSteps, useDiscoverReaderQuery } from '../hooks';
import { useSelectedReader } from './provider/selected-reader-provider';
import { SelectReaderDisplay } from './reader-select';

export const CollectionStepReaderSelection = () => {
  const { stripeLocationId, paymentsUrl } = useMerchant();
  const { t } = useTranslation('payments');
  const { selectedReader, setSelectedReader } = useSelectedReader();
  const { goToStep } = useMultiStepModal();
  const { locationId } = useMultiQueryUtils();
  const { modalProps, triggerProps } = useModalControl();
  const [tempReader, setTempReader] = useState<TerminalReader | null>(null);

  const handleReaderSelect = async (reader: TerminalReader) => {
    try {
      if (!locationId || !paymentsUrl || !reader) return;
      const selectedReaderTemp = query.data?.find((terminal) => terminal.id === reader.id);
      if (selectedReaderTemp?.action?.status === 'in_progress') {
        setTempReader(reader);
        triggerProps.onClick();
      } else {
        setSelectedReader(PaymentsTerminalController.createStoredReader(locationId, paymentsUrl, reader));
        goToStep(CollectPaymentModalSteps.CollectReaderPayment);
      }
    } catch (error) {
      console.error('error in connecting to the terminal', error);
    }
  };

  const onConfirm = async () => {
    try {
      if (!locationId || !paymentsUrl || !tempReader) return;
      const selectedReaderTemp = query.data?.find((terminal) => terminal.id === tempReader.id);
      await PaymentsTerminalApi.cancelTerminalAction({
        readerId: selectedReaderTemp?.id || '',
        paymentIntentId: selectedReaderTemp?.action?.ProcessPaymentIntent?.id || '',
        paymentsUrl,
        locationId,
      });
      setSelectedReader(PaymentsTerminalController.createStoredReader(locationId, paymentsUrl, tempReader));
      goToStep(CollectPaymentModalSteps.CollectReaderPayment);
    } catch (error) {
      console.error('error canceling in progress payment', error);
    }
  };

  const query = useDiscoverReaderQuery({ locationId, stripeLocationId, paymentsUrl: paymentsUrl ?? undefined });

  useEffect(() => {
    const storedReader = PaymentsTerminalController.getStoredReader(locationId);
    /* by checking for the selectedReader, we are automatically redirecting the user to the 
    collection flow only for the first time until the modal is closed and opened again
    */
    if (storedReader && !selectedReader) {
      const storedReaderIsOnline = query.data?.find(
        (reader) => reader.id === storedReader.readerId && reader.status === 'online'
      );
      if (!storedReaderIsOnline) return;

      const storedReaderIsInUse = storedReaderIsOnline?.action?.status === 'in_progress';
      if (storedReaderIsInUse) return;

      setSelectedReader(storedReader);
      goToStep(CollectPaymentModalSteps.CollectReaderPayment);
    }
  }, [query.data, locationId, selectedReader]);

  return (
    <>
      <SelectReaderDisplay
        loading={query.isLoading || query.isRefetching}
        loadingMessage={t('Discovering terminals...')}
        readers={query.data ?? []}
        onReaderClick={(reader) => handleReaderSelect(PaymentsTerminalController.formatReaderToTerminalReader(reader))}
        locationId={locationId}
        refetchTerminals={query.refetch}
      />
      <ConfirmationModal
        {...modalProps}
        title={t('This terminal is in use')}
        message={t('Override terminal payment, or select another terminal.')}
        onConfirm={onConfirm}
        confirmLabel={t('Override Terminal Payment')}
        onCancel={modalProps.onClose}
      />
    </>
  );
};
