import React from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Text } from '@frontend/design-system';
import { featureFlags } from '../../../feature-flags';
import { generateTrackingId } from '../../../tracking';
import { useCallTakeawayPanelShallowStore } from '../hooks';

type RestoreHistoryModalProps = {
  analysisType: string;
  dependentAnalysis?: string[];
  modalProps: ModalControlModalProps;
  onClose: () => void;
  onRestore: () => void;
};

const RestoreHistoryModal: React.FC<RestoreHistoryModalProps> = ({
  analysisType,
  dependentAnalysis,
  modalProps,
  onClose,
  onRestore,
}) => {
  const { t } = useTranslation('analytics');
  const { isDemoAccount } = useCallTakeawayPanelShallowStore('isDemoAccount');

  const isVariantB = useHasFeatureFlag(featureFlags.editAiDesignVariant); // true for design B , false for Deisgn A

  return (
    <Modal {...modalProps} maxWidth={400} css={styles.modalWrapper} onClose={onClose}>
      <Modal.Header onClose={onClose}>{t('Restore Original Analysis')}</Modal.Header>

      <Modal.Body css={styles.body}>
        <div css={styles.bodyContent}>
          <Text>{t('Are you sure you want to restore the original {{analysisType}} analysis?', { analysisType })}</Text>
          {!!dependentAnalysis?.length && (
            <Text>
              {t('The original {{dependencies}} analysis will also be restored.', {
                dependencies: dependentAnalysis.join(', '),
              })}
            </Text>
          )}
          <Text>{t('This action will discard your most recent edit.')}</Text>
        </div>
        <Modal.Actions
          destructive
          onSecondaryClick={onClose}
          onPrimaryClick={onRestore}
          primaryLabel={t('Restore Original')}
          secondaryLabel={t('Cancel')}
          primaryTrackingId={generateTrackingId({
            component: 'restore-history',
            context: 'restore-original-btn-click',
            isDemoAccount,
            abTestGroup: isVariantB ? 'design-B' : 'design-A',
          })}
          secondaryTrackingId={generateTrackingId({
            component: 'restore-history',
            context: 'cancel-btn-click',
            isDemoAccount,
            abTestGroup: isVariantB ? 'design-B' : 'design-A',
          })}
        />
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  modalWrapper: css`
    width: 100%;

    @media screen and (min-width: 648px) {
      width: 100%;
    }
  `,
  body: css`
    gap: ${theme.spacing(4)};
  `,
  bodyContent: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(3)};
  `,
};

export default RestoreHistoryModal;
