import { AgreementContentService } from '@weave/schema-gen-ts/dist/schemas/agreementcontent/v1/agreementcontent.pb';
import { InsysAgreementsAPI } from '@weave/schema-gen-ts/dist/schemas/agreements/v1/insys_agreements.pb';
import { CommHistoryService } from '@weave/schema-gen-ts/dist/schemas/comm/history/v1/history_service.pb';
import { CommPhoneNumberService } from '@weave/schema-gen-ts/dist/schemas/comm/phone-number/v1/phone_number_service.pb';
import { CommPreferenceConsentService } from '@weave/schema-gen-ts/dist/schemas/comm-preference/consent/v1/consent_service.pb';
import { CommPreferenceService } from '@weave/schema-gen-ts/dist/schemas/comm-preference/preference/v1/preference_service.pb';
import { CallIntelligenceService } from '@weave/schema-gen-ts/dist/schemas/data-labeling/call-intelligence/v1/transcript.pb';
import { FaxDraftService } from '@weave/schema-gen-ts/dist/schemas/fax/draft/v1/fax_draft_service.pb';
import { FaxMediaService } from '@weave/schema-gen-ts/dist/schemas/fax/media/v1/media.pb';
import { FaxService } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';
import { FeatureFlagsServiceAPI as FF } from '@weave/schema-gen-ts/dist/schemas/feature-flags/feature_flags_service_api.pb';
import { FinanceServiceAPI } from '@weave/schema-gen-ts/dist/schemas/finance/finance_service_api.pb';
import { WFormsAPI as DigitalFormsService } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { InsuranceVerificationAPIService } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/api-service/v1/insurance_verification_api.pb';
import { InsuranceVerificationAdapterService as InsuranceVerificationAdapterServiceV1 } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v1/insurance_verification_adapter.pb';
import { InsuranceVerificationAdapterService as InsuranceVerificationAdapterServiceV2 } from '@weave/schema-gen-ts/dist/schemas/insurance-verification/v2/insurance_verification_adapter.pb';
import { InsysOnboardingIntakeFormAPI } from '@weave/schema-gen-ts/dist/schemas/insys/intake-form/v1/insys_onboarding_intake_form.pb';
import { OnboardingProgressService } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-progress/onboarding_progress.pb';
import { OnboardingTasksAPI as OnboardingTasksService } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { LocationProvisionerAPI as LocationProvisionerService } from '@weave/schema-gen-ts/dist/schemas/location/v1/provision/location_provision_service.pb';
import { LocationService } from '@weave/schema-gen-ts/dist/schemas/location/v2/data-location/data_location.pb';
import { LoginFeatures } from '@weave/schema-gen-ts/dist/schemas/login-features/v1/service.pb';
import { EmailTemplatesApi as EmailTemplatesService } from '@weave/schema-gen-ts/dist/schemas/massemail/templates/v1/templates.pb';
import { BulkMessageService } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { MetlBulkService } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/bulk/v1/service.pb';
import { MetlHistoryService } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/history/v1/service.pb';
import { MetlService } from '@weave/schema-gen-ts/dist/schemas/messaging/etl/v1/service.pb';
import { ManualSmsScheduledService } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/manual-sms/v1/manualsms.pb';
import { ScheduledService } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/v1/scheduled.pb';
import { TemplatorService } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v1/templator.pb';
import { TemplatorService as TemplatorV2Service } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/service.pb';
import { OrgService } from '@weave/schema-gen-ts/dist/schemas/organization/v1/org.pb';
import { PackageService } from '@weave/schema-gen-ts/dist/schemas/package-service/v1/package.pb';
import { CommandsService as PaymentsCommandsService } from '@weave/schema-gen-ts/dist/schemas/payments/commands/service/service.pb';
import { ViewService as PaymentsViewsService } from '@weave/schema-gen-ts/dist/schemas/payments/views/service/service.pb';
import { JustfiIntegration as JustifiIntegration } from '@weave/schema-gen-ts/dist/schemas/payments-platform/justifi-integration/justifi_integration.pb';
import { PersonService } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons_service.pb';
import { AddressService } from '@weave/schema-gen-ts/dist/schemas/phone/address/v1/address_service.pb';
import { AudioLibraryService } from '@weave/schema-gen-ts/dist/schemas/phone/audio-library/v1/audio_library_service.pb';
import { PhoneCallConfigAPI } from '@weave/schema-gen-ts/dist/schemas/phone/call-config/v2/call_config.pb';
import { CallGroupService } from '@weave/schema-gen-ts/dist/schemas/phone/callgroup/v1/callgroup_service.pb';
import { ContactListsAPI as ContactListsService } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contact-lists/contact_lists.pb';
import { ContactsAPI as ContactsService } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import { DeviceContactListsAPI as DeviceContactListsService } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/devices/devices.pb';
import { DevicesAPI as DevicesService } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { ExtensionsAPI as ExtensionService } from '@weave/schema-gen-ts/dist/schemas/phone/extensions/extensions.pb';
import { PhoneNumberService } from '@weave/schema-gen-ts/dist/schemas/phone/number-service/v2/number_service.pb';
import { PhoneOverrideService } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override_service.pb';
import { PortingDataService } from '@weave/schema-gen-ts/dist/schemas/phone/porting/porting-data/v1/porting_data_service.pb';
import { SoftphonesAPI } from '@weave/schema-gen-ts/dist/schemas/phone/softphones/softphones.pb';
import { TenantAPI } from '@weave/schema-gen-ts/dist/schemas/phone/tenant/tenant_api.pb';
import { UserService } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { VoicemailConfigService } from '@weave/schema-gen-ts/dist/schemas/phone/voicemail-config/v1/voicemail_config.pb';
import { LineAPI } from '@weave/schema-gen-ts/dist/schemas/phone-devices/lines/line_api.pb';
import { DepartmentsService as DepartmentsApiV1 } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/departments_service.pb';
import { DepartmentsService as DepartmentsApiV2 } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/api.pb';
import { MediaService } from '@weave/schema-gen-ts/dist/schemas/phone-exp/media/media_service.pb';
import { PhoneCallsAPI } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-call/v1/phone_call_api.pb';
import { PhoneNumbersService as PhoneNumberAPI } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-numbers/phone_numbers_service.pb';
import { PhoneRecordsAPI } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/phone_records_api.pb';
import { IVRService as IVRApi } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-tree/ivr_service.pb';
import { VoicemailService as voicemailApi } from '@weave/schema-gen-ts/dist/schemas/phone-exp/voicemail/api.pb';
import { PlatformLocationFeature as PlatformLocationFeatureService } from '@weave/schema-gen-ts/dist/schemas/platform-location-feature/v1/location_features.pb';
import { CustomerObjectService } from '@weave/schema-gen-ts/dist/schemas/plg/customer-object/v1/customer_object_service.pb';
import { QuickActionService } from '@weave/schema-gen-ts/dist/schemas/quick-actions/v1/api_service.pb';
import { SalesforceService } from '@weave/schema-gen-ts/dist/schemas/salesforce/v1/salesforce.pb';
import { ScheduleAPI as ScheduleService } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { SchedulerService } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/scheduler.pb';
import { SMSService } from '@weave/schema-gen-ts/dist/schemas/sms/data/v3/sms_service.pb';
import { SMSNotifierService } from '@weave/schema-gen-ts/dist/schemas/sms/notifier/v1/notifier_service.pb';
import { SMSNumberService } from '@weave/schema-gen-ts/dist/schemas/sms/number/v1/number_service.pb';
import { SMSNumberBlockService } from '@weave/schema-gen-ts/dist/schemas/sms/number-block/v1/number_block_service.pb';
import { SMSSearchService } from '@weave/schema-gen-ts/dist/schemas/sms/search/v1/sms_search_service.pb';
import { SMSSearchService as SMSSearchV2Service } from '@weave/schema-gen-ts/dist/schemas/sms/search/v2/sms_search_service.pb';
import { SMSSendService } from '@weave/schema-gen-ts/dist/schemas/sms/send/v3/send_service.pb';
import { SMSSignatureService } from '@weave/schema-gen-ts/dist/schemas/sms/signature/v1/signature_service.pb';
import { SMSWritebackService } from '@weave/schema-gen-ts/dist/schemas/sms/writeback/v1/writeback_service.pb';
import { SubscriptionManagerService } from '@weave/schema-gen-ts/dist/schemas/subscription-manager-service/v1/subscription_manager_service.pb';
import { CSVDataDistillerAPI as CSVDataDistillerService } from '@weave/schema-gen-ts/dist/schemas/syncapp/csv-data-distiller/v1/csv_data_distiller.pb';
import { IntegrationsService } from '@weave/schema-gen-ts/dist/schemas/syncapp/integrations-service/integrations_service.pb';
import { MappingsService } from '@weave/schema-gen-ts/dist/schemas/syncapp/mappings/v1/service.pb';
import { SyncAppMonitoringService } from '@weave/schema-gen-ts/dist/schemas/syncapp/monitoring/sync_app_monitoring.pb';
import { Support } from '@weave/schema-gen-ts/dist/schemas/syncapp/support/support.pb';
import { TagService } from '@weave/schema-gen-ts/dist/schemas/tag/v1/tag_service.pb';
import { TenDLCCoordinatorService as TenDLCCoordinator } from '@weave/schema-gen-ts/dist/schemas/tendlc/coordinator/v1/coordinator_service.pb';
import { API as tosAPI } from '@weave/schema-gen-ts/dist/schemas/threads-of-success/v1/api.pb';
import { WgptService } from '@weave/schema-gen-ts/dist/schemas/wgpt/v1/wgpt_service.pb';
import { bindHTTP } from './schema.utils';

export const SchemaFeatureFlags = bindHTTP(FF);

export const SchemaTenDLCCoordinator = bindHTTP(TenDLCCoordinator);

export const SchemaLocationService = bindHTTP(LocationService);

export const SchemaScheduledService = bindHTTP(ScheduledService);

export const SchemaMetl = bindHTTP(MetlService);

export const SchemaBulkMessage = bindHTTP(BulkMessageService);

export const SchemaBulkMetl = bindHTTP(MetlBulkService);

export const SchemaHistoryMetl = bindHTTP(MetlHistoryService);

export const SchemaTemplator = bindHTTP(TemplatorService);

export const SchemaTemplatorV2 = bindHTTP(TemplatorV2Service);

export const SchemaEmailTemplates = bindHTTP(EmailTemplatesService);

export const SchemaLoginFeatures = bindHTTP(LoginFeatures);

export const DepartmentsSchemaServiceV2 = bindHTTP(DepartmentsApiV2);

export const DepartmentsSchemaServiceV1 = bindHTTP(DepartmentsApiV1);

export const IVRSchemaService = bindHTTP(IVRApi);

export const VoicemailSchemaService = bindHTTP(voicemailApi);

export const PhoneRecordsSchemaService = bindHTTP(PhoneRecordsAPI);

export const PhoneCallsService = bindHTTP(PhoneCallsAPI);

export const PhoneNumberSchemaService = bindHTTP(PhoneNumberAPI);

export const SoftphoneSchemaService = bindHTTP(LineAPI);

export const SchemaSalesforce = bindHTTP(SalesforceService);

export const MediaSchemaService = bindHTTP(MediaService);

export const SchemaOnboardingTasksService = bindHTTP(OnboardingTasksService);

export const SchemaPhoneOverrideService = bindHTTP(PhoneOverrideService);

export const SchemaFaxService = bindHTTP(FaxService);

export const SchemaSMSService = bindHTTP(SMSService);

export const SchemaSMSNumberService = bindHTTP(SMSNumberService);

export const SchemaSMSSendService = bindHTTP(SMSSendService);

export const SchemaSMSSearchService = bindHTTP(SMSSearchService);

export const SchemaSMSNotifierService = bindHTTP(SMSNotifierService);

export const SchemaSMSBlockNumberService = bindHTTP(SMSNumberBlockService);

export const SchemaSMSWritebackService = bindHTTP(SMSWritebackService);

export const SchemaSMSSignatureService = bindHTTP(SMSSignatureService);

export const SchemaManualSMSScheduledService = bindHTTP(ManualSmsScheduledService);

export const SchemaTagService = bindHTTP(TagService);

export const SchemaDigitalForms = bindHTTP(DigitalFormsService);

export const SchemaWGPT = bindHTTP(WgptService);

export const SchemaPackageService = bindHTTP(PackageService);

export const SchemaCommPreference = bindHTTP(CommPreferenceService);

export const SchemaCommPreferenceConsent = bindHTTP(CommPreferenceConsentService);

export const SchemaContactLists = bindHTTP(ContactListsService);

export const SchemaContacts = bindHTTP(ContactsService);

export const SchemaDeviceContactLists = bindHTTP(DeviceContactListsService);

export const SchemaDevices = bindHTTP(DevicesService);

export const SchemaExtensions = bindHTTP(ExtensionService);

export const SchemaPhoneNumberService = bindHTTP(PhoneNumberService);

export const SchemaPhoneUserService = bindHTTP(UserService);

export const SchemaPlatformLocationFeature = bindHTTP(PlatformLocationFeatureService);

export const SchemaIntegrationsService = bindHTTP(IntegrationsService);

export const SchemaSyncAppMonitoringService = bindHTTP(SyncAppMonitoringService);

export const SchemaSyncAppSupportService = bindHTTP(Support);

export const SchemaSubscriptionManagerService = bindHTTP(SubscriptionManagerService);

export const SchemaPortingDataService = bindHTTP(PortingDataService);

export const SchemaScheduleService = bindHTTP(ScheduleService);

export const SchedulerServiceV3 = bindHTTP(SchedulerService);

export const SchemaMappingsService = bindHTTP(MappingsService);

export const SchemaOrgService = bindHTTP(OrgService);

export const SchemaCustomerObjectService = bindHTTP(CustomerObjectService);

export const SchemaAgreementContentService = bindHTTP(AgreementContentService);

export const SchemaInsysAgreementsAPI = bindHTTP(InsysAgreementsAPI);

export const SchemaCommHistoryService = bindHTTP(CommHistoryService);

export const SoftphoneSettingsService = bindHTTP(SoftphonesAPI);

export const SchemaCommPhoneNumberService = bindHTTP(CommPhoneNumberService);

export const SchemaSMSSearchV2Service = bindHTTP(SMSSearchV2Service);

export const SchemaPersonV3Service = bindHTTP(PersonService);

export const SchemaCallIntelligenceService = bindHTTP(CallIntelligenceService);

export const SchemaInsuranceVerificationAdapterServiceV1 = bindHTTP(InsuranceVerificationAdapterServiceV1);

export const SchemaInsuranceVerificationAdapterServiceV2 = bindHTTP(InsuranceVerificationAdapterServiceV2);

export const SchemaInsuranceVerificationAPIService = bindHTTP(InsuranceVerificationAPIService);

export const SchemaQuickActionAPIService = bindHTTP(QuickActionService);

export const SchemaFinanceService = bindHTTP(FinanceServiceAPI);

export const SchemaFaxDraftService = bindHTTP(FaxDraftService);

export const SchemaFaxMediaService = bindHTTP(FaxMediaService);

export const SchemaTenantService = bindHTTP(TenantAPI);

export const SchemaVoicemailConfigService = bindHTTP(VoicemailConfigService);

export const SchemaPaymentsViewsService = bindHTTP(PaymentsViewsService);

export const AddressSchemaService = bindHTTP(AddressService);

export const SchemaPhoneCallConfigAPI = bindHTTP(PhoneCallConfigAPI);

export const SchemaCallGroupService = bindHTTP(CallGroupService);

export const SchemaLocationProvisionerService = bindHTTP(LocationProvisionerService);

export const SchemaAudioLibraryService = bindHTTP(AudioLibraryService);

export const SchemaInsysOnboardingIntakeFormAPI = bindHTTP(InsysOnboardingIntakeFormAPI);

export const SchemaPaymentsCommandsService = bindHTTP(PaymentsCommandsService);

export const SchemaOnboardingProgressService = bindHTTP(OnboardingProgressService);

export const SchemaCSVDataDistillerService = bindHTTP(CSVDataDistillerService);

export const SchemaJustifiIntegration = bindHTTP(JustifiIntegration);

export const SchemaThreadsOfSuccessService = bindHTTP(tosAPI);
