import { useCallback } from 'react';
import {
  Appointment,
  CalendarException,
} from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { OfficeHoursDaySchedule } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { uniqBy } from 'lodash-es';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ProviderEventType } from '../components/calendar-view/types';
import { useGetAppointmentsEvents } from './use-get-appointments-events';
import { useGetProvidersEvents } from './use-get-providers-events';

type UseGetProvidersDetailsForCalendarType = {
  providersList: ScheduleTypes.SchemaProvider[];
  appointments: Appointment[];
  providersExceptions: CalendarException[];
  providersOfficeHours: OfficeHoursDaySchedule[];
};

export const useGetProvidersDetailsForCalendar = ({
  appointments,
  providersExceptions,
  providersList,
  providersOfficeHours,
}: UseGetProvidersDetailsForCalendarType) => {
  const { getProvidersBreakEvents, getProvidersExceptionsEvent } = useGetProvidersEvents();

  const { getAppointmentsEventByProviderId } = useGetAppointmentsEvents();

  const getProvidersForCalendarObject = useCallback(
    (locationId: string): ProviderEventType[] => {
      return (
        providersList
          ?.filter(({ locationId: providerLocationId }) => providerLocationId === locationId)
          ?.map((provider) => {
            const fullName =
              provider.firstName || provider.lastName ? `${provider.firstName} ${provider.lastName}`.trim() : '';

            const providersExceptionsEvent = getProvidersExceptionsEvent(providersExceptions ?? [], provider.id ?? '');
            const appointmentsEvent = getAppointmentsEventByProviderId({
              appointments: appointments ?? [],
              providerId: provider.id ?? '',
              locationId,
              providerName: fullName,
              providerDisplayName: provider.publicDisplayName,
            });
            const providersBreakEvents = getProvidersBreakEvents(providersOfficeHours ?? [], provider.id ?? '');

            return {
              name: provider.publicDisplayName || fullName || provider.resourceName || '',
              providerId: provider.id ?? '',
              src: provider.publicDisplayImage || '',
              events: uniqBy(
                [...providersExceptionsEvent, ...appointmentsEvent, ...providersBreakEvents],
                (event) => event.eventId
              ),
            };
          }) ?? []
      );
    },
    [providersList, appointments, providersExceptions, providersOfficeHours]
  );

  return { getProvidersForCalendarObject };
};
