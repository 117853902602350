import { useReducer, useState } from 'react';
import { css } from '@emotion/react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/phone/contacts/contacts/contacts.pb';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useQueryClient } from 'react-query';
import { ContactDirectoriesAPI, ContactDirectoriesQueryKeys } from '@frontend/api-contact-directories';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import {
  Button,
  ModalControlModalProps,
  Tray,
  Text,
  ButtonBar,
  useModalControl,
  ButtonSize,
  useAlert,
  styles,
  Heading,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { BusinessFields } from '../component/business-fields';
import { ReducerDispatchAction, ReducerState, useContactDetailsReducer } from '../hooks';
import { DeleteContactModal } from '.';

type Props = ModalControlModalProps & {
  contact: Contact | undefined;
  contactDirectoryId?: string;
  contactIds?: string[];
  onRemove?: (data: Contact) => Promise<void> | undefined;
  isEditModeEnabled?: boolean;
};

const initalState = (contact: Contact): ReducerState => {
  return [
    {
      ...contact,
      errors: [],
    },
  ];
};

export const ViewContactDetailsModal = ({
  contact,
  contactIds,
  contactDirectoryId,
  onRemove,
  isEditModeEnabled,
  ...modalProps
}: Props) => {
  if (!contact) return null;
  const { t } = useTranslation('phone');
  const queryClient = useQueryClient();
  const alerts = useAlert();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const tenantId = settingsTenantLocation?.phoneTenantId ?? '';
  const { modalProps: deleteModalProps, triggerProps } = useModalControl();
  const [isEditEnabled, setIsEditEnabled] = useState(isEditModeEnabled);
  const [state, dispatch] = useReducer(useContactDetailsReducer, initalState(contact));

  const hasErrors = (state[0].errors?.length ?? 0) > 0;

  const { mutate: updateContact } = useMutation(ContactDirectoriesAPI.updateContact, {
    onSuccess: () => {
      queryClient.invalidateQueries([tenantId, ...ContactDirectoriesQueryKeys.queryKeys.getContacts()]);
      if (contactDirectoryId) {
        queryClient.invalidateQueries([
          tenantId,
          ...ContactDirectoriesQueryKeys.queryKeys.getContactsbyContactDirectoryId(contactDirectoryId),
        ]);
      }
      alerts.success(t('Successfully updated contact information'));
      setIsEditEnabled(false);
      modalProps.onClose();
    },
    onError: () => {
      alerts.error(t('Failed to update contact information'));
    },
  });

  const handleEditMode = () => {
    setIsEditEnabled(true);
  };

  const onCancel = () => {
    setIsEditEnabled(false);
    modalProps.onClose();
  };

  const removeButtonProps = {
    size: isEditEnabled ? 'large' : 'small',
    label: isEditEnabled ? '' : t('Remove from List'),
  };

  const deleteButtonProps = {
    size: isEditEnabled ? 'large' : 'small',
    label: isEditEnabled ? '' : t('Delete Contact'),
  };

  const onSave = () => {
    const { notes, name, phoneNumbers } = state[0];

    updateContact({
      id: contact.id,
      notes,
      name,
      phoneNumbers,
    });
  };

  const onDispatch = (action: ReducerDispatchAction) => {
    dispatch(action);
  };

  return (
    <>
      <Tray.Header Buttons={<Button variant='secondary' onClick={modalProps.onClose} iconName='x' />}>
        {isEditEnabled ? t('Edit Business Contact') : contact?.name}
      </Tray.Header>
      <Tray.Body
        css={css`
          padding-top: ${theme.spacing(1)};
        `}
      >
        {isEditEnabled ? (
          <BusinessFields contact={state[0]} onDispatch={onDispatch} />
        ) : (
          <>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingBottom: theme.spacing(2),
              }}
            >
              <Heading level={3}>{t('Contact Information')}</Heading>
              <Button variant='tertiary' onClick={handleEditMode}>
                {t('Edit')}
              </Button>
            </div>
            {contact?.phoneNumbers?.map((number) => {
              return (
                <div key={number.id} css={listStyles}>
                  <Text css={[styles.truncate, { width: theme.spacing(11), flex: 'none' }]} size='medium' color='light'>
                    {number.label}
                  </Text>
                  <Text weight='bold'>{formatPhoneNumber(number.number, false)}</Text>
                  <CopyToClipboard text={number.number}>
                    <Button variant='secondary' iconName='copy-small' />
                  </CopyToClipboard>
                </div>
              );
            })}
            {!!contact?.notes && (
              <div css={listStyles}>
                <Text css={{ width: theme.spacing(11) }} size='medium' color='light'>
                  {t('Note')}
                </Text>
                <Text weight='bold'>{contact?.notes}</Text>
              </div>
            )}
          </>
        )}
      </Tray.Body>
      <ButtonBar css={buttonStyles}>
        {contactDirectoryId && (
          <Button
            variant='secondary'
            iconName='user-minus'
            size={removeButtonProps.size as ButtonSize}
            onClick={() => {
              if (contact) {
                onRemove?.(contact)?.then(() => {
                  modalProps.onClose();
                });
              }
            }}
          >
            {removeButtonProps.label}
          </Button>
        )}
        <Button
          variant='secondary'
          destructive
          iconName='trash'
          size={deleteButtonProps.size as ButtonSize}
          onClick={triggerProps.onClick}
        >
          {deleteButtonProps.label}
        </Button>
        {isEditEnabled && (
          <>
            <Button css={{ marginLeft: 'auto' }} variant='secondary' onClick={onCancel}>
              {t('Cancel')}
            </Button>
            <Button disabled={hasErrors} onClick={onSave}>
              {t('Save')}
            </Button>
          </>
        )}
      </ButtonBar>
      <DeleteContactModal
        {...deleteModalProps}
        closeModal={modalProps.onClose}
        contactDirectoryId={contactDirectoryId}
        selectedContacts={{
          contacts: [contact],
        }}
      />
    </>
  );
};

const listStyles = { display: 'flex', gap: theme.spacing(1), alignItems: 'center', paddingBottom: theme.spacing(2) };

const buttonStyles = css`
  padding: 0;
  justify-content: center;
  align-items: center;
`;
