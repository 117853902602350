import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { CalendarEventType } from '../views/Calendar/types';

type CalendarEventsConfigurationStoreType = {
  configuredCalendarEvent: CalendarEventType | null;
  setConfiguredCalendarEvent: (value: CalendarEventType | null) => void;
};

const useCalendarEventsConfigurationStore = createStoreWithSubscribe<CalendarEventsConfigurationStoreType>(
  (set) => ({
    configuredCalendarEvent: null,
    setConfiguredCalendarEvent: (value) => set({ configuredCalendarEvent: value }),
  }),
  { name: 'calendarEventsConfigurationStore' }
);

export const useCalendarEventsConfigurationShallowStore = createShallowStore(useCalendarEventsConfigurationStore);
