import React, { createContext, useContext, useMemo, useState } from 'react';
import { ScheduleActionsContainerTypes } from '../types';

// context
const ScheduleActionsContainerContext = createContext<ScheduleActionsContainerTypes>(
  {} as ScheduleActionsContainerTypes
);

// provider
interface ScheduleActionsContainerProps {
  children: React.ReactNode;
  closeModal?: () => void;
}

export const ScheduleActionsContainerProvider = React.memo(
  ({ children, closeModal }: ScheduleActionsContainerProps) => {
    const [selectedLocationId, setSelectedLocationId] = useState<string | null>(null);
    const [hideTabs, setHideTabs] = useState(false);
    const [selectedDate, setSelectedDate] = useState<string>('');

    const actions = useMemo(
      () => ({
        setHideTabs,
        hideTabs,
        selectedLocationId,
        setSelectedLocationId,
        closeModal,
        setSelectedDate,
        selectedDate,
      }),
      [hideTabs, selectedLocationId, selectedDate, closeModal]
    );

    return (
      <ScheduleActionsContainerContext.Provider value={actions}>{children}</ScheduleActionsContainerContext.Provider>
    );
  }
);

ScheduleActionsContainerProvider.displayName = 'ScheduleActionsContainerProvider';

// hook
export const useScheduleActionsContainerMethod = () => useContext(ScheduleActionsContainerContext);
