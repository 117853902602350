import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import {
  DEVICE_LAYOUT_WIDTH,
  LAYOUT_HEIGHT_HEADER_FOOTER,
  LAYOUT_TOUCH_SCREEN_HEIGHT_HEADER_FOOTER,
  LINE_KEY_FONT_SIZE,
} from '../constants';
import { useLineKeyContext } from '../store/line-key-provider';
import { DeviceLayoutBody } from './device-layout-body';
import { DeviceLayoutFooter } from './device-layout-footer';
import { DeviceLayoutHeader } from './device-layout-header';

type Props = {
  previewOnly?: boolean;
  children: ReactNode;
};

export const DeviceLayout = ({ previewOnly, children }: Props) => {
  const { model } = useLineKeyContext();

  return (
    <article className={`${model?.make}-${model?.model}`} css={layoutStyles({ previewOnly })}>
      {children}
    </article>
  );
};

DeviceLayout.Header = DeviceLayoutHeader;
DeviceLayout.Body = DeviceLayoutBody;
DeviceLayout.Footer = DeviceLayoutFooter;

const layoutStyles = ({ previewOnly }: { previewOnly?: boolean }) => {
  return css`
    background: ${theme.colors.neutral80};
    ${!previewOnly && `max-width: ${DEVICE_LAYOUT_WIDTH}`};
    height: max-content;
    border-radius: ${theme.borderRadius.large};
    border: 2px solid ${theme.colors.neutral80};
    width: 100%;

    .device-model-layout-header {
      font-size: ${LINE_KEY_FONT_SIZE};
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .device-model-layout-header,
    .device-model-layout-footer {
      height: ${LAYOUT_HEIGHT_HEADER_FOOTER};
    }

    .device-model-layout-header.touch-screen,
    .device-model-layout-footer.touch-screen {
      height: ${LAYOUT_TOUCH_SCREEN_HEIGHT_HEADER_FOOTER};
    }

    ul {
      padding: 0;
    }
  `;
};
