import { useMemo } from 'react';
import { css } from '@emotion/react';
import { DeviceMake_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { useNavSize } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { LINE_KEY_FONT_SIZE } from '../constants';
import { useLineKeyContext, useLineKeyState } from '../store/line-key-provider';
import type { DeviceLayoutWithKeysProps } from './device-layout-body';
import { LayoutKeyRender } from './key-renderer/layout-key-render';
import { LayoutNameRender } from './key-renderer/layout-name-render';

export const DeviceLayoutWithSideKeys = ({
  isPaginationEnabled,
  pageNum,
  previewOnly,
  lineKeys,
}: DeviceLayoutWithKeysProps) => {
  const { setIsMenuBarOpen } = useLineKeyState(['setIsMenuBarOpen']);
  const { device, model } = useLineKeyContext();
  const isPolycom = model?.make === DeviceMake_Enum.POLYCOM;
  const navSize = useNavSize();
  const isMobile = navSize.isLte('medium');

  const extensionsCount = device?.extensions?.length ?? 1;
  const { leftPageKeys, rightPageKeys } = useMemo(() => {
    /* We start at page 1 but when we determining the position of the line key index we start at index 0 */
    const currentPageIndex = pageNum - 1;
    const leftPageKeysCount = model?.lineKeys?.keysPerPageLeft ?? 0;
    const rightPageKeysCount = model?.lineKeys?.keysPerPageRight ?? 0;

    const currLeftStartingIndex = currentPageIndex * leftPageKeysCount * 2;
    const currRightStartingIndex = currentPageIndex * leftPageKeysCount * 2 + leftPageKeysCount;
    const currLeftEndingIndex = currLeftStartingIndex + leftPageKeysCount;
    const currRightEndingIndex = currRightStartingIndex + rightPageKeysCount;

    const leftKeys = new Array(currLeftEndingIndex - currLeftStartingIndex).fill(undefined);
    const rightKeys = new Array(currRightEndingIndex - currRightStartingIndex).fill(undefined);

    const leftPageKeys = leftKeys.map((_, i) => {
      const currentPosition = currLeftStartingIndex + i;
      const isAnExtension = extensionsCount >= currentPosition + 1;
      return isAnExtension ? device?.extensions?.[i].name : currentPosition - currentPageIndex;
    });

    const rightPageKeys = rightKeys.map((_, i) => {
      const currentPosition = currRightStartingIndex + i;
      const isAnExtension = extensionsCount >= currentPosition + 1;

      if (isPaginationEnabled && currentPosition === (model?.lineKeys?.paginationLineKey ?? 0) * pageNum - 1) {
        return `< Page ${pageNum} >`;
      }
      return isAnExtension ? device?.extensions?.[i].name : currentPosition - currentPageIndex;
    });
    return { leftPageKeys, rightPageKeys };
  }, [model, pageNum, extensionsCount, isPaginationEnabled]);

  return (
    <div css={styles(isMobile)}>
      {/* Left Side */}
      <ul className={`line-key-left-side-keys ${isPolycom ? 'polycom' : ''}`}>
        <LayoutKeyRender
          keys={leftPageKeys}
          side='left'
          preview={previewOnly}
          lineKeys={lineKeys}
          make={model?.make ?? DeviceMake_Enum.UNSPECIFIED}
          setIsMenuBarOpen={setIsMenuBarOpen}
        />
      </ul>

      {/* Middle Section with names  */}
      <div className='line-key-middle-container'>
        <ul className='left-side-names'>
          <LayoutNameRender keys={leftPageKeys} lineKeys={lineKeys} />
        </ul>
        <ul className='right-side-names'>
          <LayoutNameRender keys={rightPageKeys} lineKeys={lineKeys} />
        </ul>
      </div>

      {/* Right Side  */}
      <ul className={`line-key-right-side-keys ${isPolycom ? 'polycom' : ''}`}>
        <LayoutKeyRender
          keys={rightPageKeys}
          side='right'
          preview={previewOnly}
          lineKeys={lineKeys}
          make={model?.make ?? DeviceMake_Enum.UNSPECIFIED}
          setIsMenuBarOpen={setIsMenuBarOpen}
        />
      </ul>
    </div>
  );
};

const styles = (isMobile: boolean) => {
  const keyContainerWidth = theme.spacing(13.5);
  const lineKeyHeight = theme.spacing(5);

  return css`
    display: flex;

    .line-key-left-side-keys,
    .line-key-right-side-keys,
    .line-key-middle-container {
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        height: ${lineKeyHeight};
        margin-bottom: ${theme.spacing(3)};

        span {
          font-weight: bold;
        }

        &:first-of-type {
          margin-top: ${theme.spacing(3)};
        }
      }
    }

    .line-key-left-side-keys.polycom {
      li {
        margin-right: ${theme.spacing(2)};
        &:nth-child(odd) {
          margin-right: ${theme.spacing(4)};
        }
      }
    }

    .line-key-right-side-keys.polycom {
      li {
        margin-left: ${theme.spacing(2)};
        &:nth-child(odd) {
          margin-left: ${theme.spacing(4)};
        }
      }
    }

    .line-key-left-side-keys,
    .line-key-right-side-keys {
      width: ${keyContainerWidth};
    }

    .line-key-middle-container {
      display: flex;
      width: 100%;
      background: ${theme.colors.white};
      border-radius: ${theme.spacing(2)};
      width: 100%;
      flex: 1;
      padding: ${theme.spacing(0, 2)};
      min-width: 300px;

      .left-side-names,
      .right-side-names {
        flex: 1;
      }

      li {
        span {
          font-size: ${LINE_KEY_FONT_SIZE};
        }
        span.line-key-placeholder {
          color: ${theme.colors.neutral30};
        }
      }

      .right-side-names {
        text-align: right;
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;

        li {
          justify-content: end;
          text-align: right;
        }
      }

      .left-side-names {
        li {
          justify-content: start;
          text-align: left;
        }
      }
    }

    ${isMobile &&
    `
          width: 100%;
          min-width: 450px;
  
          .line-key-middle-container {
            min-width: 200px;
            padding: ${theme.spacing(0, 1)};
          }
  
          .line-key-left-side-keys,
          .line-key-right-side-keys {
            width: ${theme.spacing(9)};
  
            li {
              width: ${theme.spacing(6)};
            }
          }
  
        `}
  `;
};
