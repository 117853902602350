import { useMemo, useRef } from 'react';
import { AnimatePresence, motion, Variant } from 'motion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Dot, NakedButton, Text, useOnClickOutside } from '@frontend/design-system';
import { HEADER_HEIGHT } from '../../constants';
import { useMiniChatShallowStore } from '../../stores';
import { MobileChatSelectionItem } from './mobile-chat-selection-item';

type MobileMiniChatSelectionMenuButtonProps = {
  chatSelectionMenuIsOpen: boolean;
  onClick: () => void;
  selectChat: (chatId: string) => void;
};

const MotionButton = motion(NakedButton);
type VariantName = 'menuIsOpen' | 'menuIsClosed' | 'menuIsHidden';
const variants: Record<VariantName, Variant> = {
  menuIsOpen: {
    right: 0,
    width: '100%',
    minWidth: '100%',
    borderTopLeftRadius: 'none',
    borderLeft: 'solid 0px transparent',
    transform: 'translateX(0)',
  },
  menuIsClosed: {
    right: 0,
    width: HEADER_HEIGHT + 12,
    minWidth: HEADER_HEIGHT + 12,
    borderTopLeftRadius: theme.borderRadius.medium,
    borderLeft: `solid 1px ${theme.colors.neutral20}`,
    transform: 'translateX(0)',
  },
  menuIsHidden: {
    right: 0,
    width: HEADER_HEIGHT + 12,
    minWidth: HEADER_HEIGHT + 12,
    borderTopLeftRadius: theme.borderRadius.medium,
    borderLeft: `solid 1px ${theme.colors.neutral20}`,
    transform: 'translateX(100%)',
  },
} as const;

export const MobileMiniChatSelectionMenuButton = ({
  chatSelectionMenuIsOpen,
  onClick,
  selectChat,
}: MobileMiniChatSelectionMenuButtonProps) => {
  const { chats, removeChat } = useMiniChatShallowStore('chats', 'removeChat');
  const chatCount = chats.length;
  const unreadChatCount = chats.filter(({ unreadCount }) => !!unreadCount).length;

  const variant = useMemo<VariantName>(() => {
    if (!chatCount) return 'menuIsHidden';
    if (chatSelectionMenuIsOpen) return 'menuIsOpen';
    return 'menuIsClosed';
  }, [chatSelectionMenuIsOpen, !!chatCount]);

  const selectionMenuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  useOnClickOutside({
    active: chatSelectionMenuIsOpen,
    handler: () => {
      onClick();
    },
    ref: selectionMenuRef,
    ignoreRefs: [buttonRef],
  });

  return (
    <AnimatePresence>
      {chatSelectionMenuIsOpen && (
        <motion.div
          key='chat-selection-menu'
          initial={{
            opacity: 0,
            transform: 'translateX(100%)',
          }}
          animate={{
            opacity: 1,
            transform: 'translateX(0)',
          }}
          exit={{
            opacity: 0,
            transform: 'translateX(100%)',
          }}
          css={{
            position: 'absolute',
            padding: theme.spacing(2),
            bottom: HEADER_HEIGHT,
            // add blurry background with fade on top edge
            '::after': {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              content: '""',
              backdropFilter: 'blur(3px)',
              pointerEvents: 'none',
              mask: `linear-gradient(to top, black 95%, transparent)`,
            },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
            gap: theme.spacing(1),
            right: 0,
            width: '100%',
            overflow: 'hidden',
            zIndex: theme.zIndex.high,
          }}
          layout='position'
          ref={selectionMenuRef}
        >
          {chats.map(({ unreadCount, personPhone, groupId, chatId }, index) => (
            <MobileChatSelectionItem
              key={'chat-selection-item-' + index}
              onClick={() => selectChat(chatId)}
              onRemove={() => {
                const shouldClose = chats.length === 1;
                removeChat(chatId);
                if (shouldClose) onClick();
              }}
              unreadCount={unreadCount}
              personPhone={personPhone}
              groupId={groupId}
              css={{
                zIndex: theme.zIndex.highest,
                width: '100%',
              }}
            />
          ))}
        </motion.div>
      )}
      {!!chatCount && (
        <MotionButton
          key='chat-selection-button'
          variants={variants}
          animate={variant}
          initial={'menuIsHidden'}
          exit={'menuIsHidden'}
          whileHover={{
            backgroundColor: theme.colors.neutral5,
          }}
          whileFocus={{
            backgroundColor: theme.colors.neutral5,
          }}
          onClick={onClick}
          css={{
            bottom: 0,
            height: HEADER_HEIGHT,
            padding: theme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            position: 'absolute',
            backgroundColor: theme.colors.white,
            borderTop: `solid 1px ${theme.colors.neutral20}`,
            zIndex: theme.zIndex.high,
          }}
          ref={buttonRef}
        >
          {variant === 'menuIsOpen' ? (
            <Icon name='x' />
          ) : (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {!!unreadChatCount && (
                <Dot
                  color='critical'
                  css={{
                    position: 'absolute',
                    left: theme.spacing(-0.75),
                    top: theme.spacing(-0.75),
                    color: theme.font.colors.white,
                    fontSize: theme.fontSize(14),
                    fontWeight: theme.font.weight.bold,
                    width: 18,
                    height: 18,
                    border: 'none',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {unreadChatCount}
                </Dot>
              )}
              <Icon name='message-thread' css={{ minWidth: 24 }} />
              <Text size='small'>{chatCount}</Text>
            </div>
          )}
        </MotionButton>
      )}
    </AnimatePresence>
  );
};
