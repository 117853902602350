import { MenuOption_MenuNavigation } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-tree/ivr_service.pb';
import { PhoneTreeTypes, InstructionsTypes } from '@frontend/api-phone-tree';

export type PartialMenuOption = Partial<
  | PhoneTreeTypes.InstructionSetMenuOption
  | PhoneTreeTypes.DepartmentMenuOption
  | PhoneTreeTypes.SubTreeMenuOption
  | PhoneTreeTypes.NavigateMenuOption
>;

type BasicPayload = {
  targetId: string;
  callerName?: string;
};

type MainReducerAction =
  | {
      type: 'pick-instruction';
      instruction: InstructionsTypes.Instruction | PhoneTreeTypes.MenuTypes.SubTree | PhoneTreeTypes.MenuTypes.Navigate;
    }
  | { type: 'edit-instruction'; payload: InstructionAction };

export type InstructionAction =
  | { type: InstructionsTypes.Instruction.CallGroup; payload: BasicPayload }
  | { type: InstructionsTypes.Instruction.CallQueue; payload: BasicPayload }
  | { type: InstructionsTypes.Instruction.DataEndpoint; payload: BasicPayload }
  | { type: InstructionsTypes.Instruction.DepartmentId; payload: BasicPayload }
  | { type: InstructionsTypes.Instruction.ForwardDevice; payload: BasicPayload }
  | { type: InstructionsTypes.Instruction.ForwardNumber; payload: BasicPayload }
  | { type: InstructionsTypes.Instruction.Hangup }
  | {
      type: InstructionsTypes.Instruction.InstructionSet;
      payload: { instructionSetId: string; instructions: PhoneTreeTypes.AnyInstruction[]; conditionId: string };
    }
  | { type: InstructionsTypes.Instruction.IVRMenu; payload: BasicPayload }
  | { type: InstructionsTypes.Instruction.Play; payload: BasicPayload }
  | {
      type: InstructionsTypes.Instruction.VoicemailBox;
      payload: {
        targetId: string;
      };
    }
  | {
      type: InstructionsTypes.Instruction.VoicemailPrompt;
      payload: PhoneTreeTypes.AnyVoicemailPrompt;
    }
  | { type: PhoneTreeTypes.MenuTypes.Navigate; payload: BasicPayload }
  | { type: PhoneTreeTypes.MenuTypes.SubTree; payload: BasicPayload };

/**
 * This is used within each PhoneTreeOptionGroup to mutate the option group (instruction + value) after a selection is
 * The bulk of the mutating work is done in the `instructionReducer`
 */
export const mainReducer = (
  state: PhoneTreeTypes.InstructionWithIds,
  action: MainReducerAction
): PhoneTreeTypes.InstructionWithIds => {
  const { type } = action;
  if (type === 'pick-instruction') {
    const { instruction } = action;
    const newInstruction = Object.assign(
      {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: instruction,
      },
      state.instructionId ? { instructionId: state.instructionId } : {},
      state.instructionSetId ? { instructionSetId: state.instructionSetId } : {}
    ) as PhoneTreeTypes.InstructionWithIds;
    return newInstruction;
  } else if (type === 'edit-instruction') {
    return instructionReducer(state, action.payload);
  }

  return state;
};

type ReducerAction =
  | { type: 'update-number'; payload: number }
  | {
      type: 'update-type';
      payload:
        | PhoneTreeTypes.MenuTypes.InstructionSet
        | PhoneTreeTypes.MenuTypes.SubTree
        | PhoneTreeTypes.MenuTypes.Navigate;
    }
  | { type: 'add-instruction' }
  | { type: 'update-instruction'; payload: PhoneTreeTypes.InstructionSet }
  | { type: 'update-subtree'; payload: PartialMenuOption }
  | { type: 'update-department'; payload: PartialMenuOption }
  | { type: 'update-navigation'; payload: PartialMenuOption }
  | { type: 'change-to-instruction'; payload: InstructionsTypes.Instruction }
  | { type: 'change-instruction-to-subtree' }
  | { type: 'change-to-department' }
  | { type: 'change-instruction-to-navigation' }
  | { type: 'remove-instruction'; payload: number };

/**
 * This mutates the state of each dial option
 */
export const optionReducer = (state: PartialMenuOption, action: ReducerAction): PartialMenuOption => {
  const { type } = action;

  switch (type) {
    case 'update-number': {
      return { ...state, number: action.payload };
    }
    case 'update-type': {
      return { ivrEntryId: state.ivrEntryId, number: state.number, type: action.payload };
    }
    case 'update-subtree': {
      return action.payload;
    }
    case 'update-department': {
      return action.payload;
    }
    case 'update-navigation': {
      return action.payload;
    }
    case 'add-instruction': {
      if ('instructionSet' in state) {
        return {
          ...state,
          instructionSet: {
            ...state.instructionSet,
            instructionSetId: state.instructionSet?.instructionSetId,
            instructions: [
              ...(state.instructionSet?.instructions ?? []),
              {
                type: InstructionsTypes.Instruction.CallGroup,
                instructionId: null,
                instructionSetId: null,
                callGroup: { callGroupId: '', callerName: '' },
              },
            ],
          },
        };
      }

      return state;
    }
    case 'update-instruction': {
      if ('instructionSet' in state) {
        return { ...state, instructionSet: action.payload };
      } else {
        return state;
      }
    }
    case 'change-to-instruction': {
      return {
        number: state.number,
        type: PhoneTreeTypes.MenuTypes.InstructionSet,
        readOnly: false,
        instructionSet: {
          /**
           * The first argument to the mainReducer is irrelevant with this invocation.
           * `mainReducer` is expected to return a brand new instruction
           */
          instructions: [
            mainReducer({} as PhoneTreeTypes.InstructionWithIds, {
              type: 'pick-instruction',
              instruction: action.payload,
            }),
          ],
        },
      };
    }
    case 'change-instruction-to-subtree': {
      return {
        ivrEntryId: state.ivrEntryId,
        number: state.number,
        type: PhoneTreeTypes.MenuTypes.SubTree,
        subTree: {} as PhoneTreeTypes.PhoneTreeModel,
      };
    }
    case 'change-to-department': {
      return {
        ivrEntryId: state.ivrEntryId,
        number: state.number,
        type: InstructionsTypes.Instruction.DepartmentId,
        readOnly: false,
      };
    }
    case 'change-instruction-to-navigation': {
      return {
        ivrEntryId: state.ivrEntryId,
        number: state.number,
        type: PhoneTreeTypes.MenuTypes.Navigate,
        navigate: MenuOption_MenuNavigation.REPEAT,
      };
    }
    case 'remove-instruction': {
      if ('instructionSet' in state && state.instructionSet?.instructions) {
        const newState = { ...state };
        const instructions = newState.instructionSet?.instructions ?? [];
        if (newState.instructionSet && instructions) {
          newState.instructionSet.instructions = [
            ...instructions.slice(0, action.payload),
            ...instructions.slice(action.payload + 1),
          ];
        }
        return newState;
      }

      return state;
    }
    default: {
      return state;
    }
  }
};

export const instructionReducer = (
  state: PhoneTreeTypes.InstructionWithIds,
  action: InstructionAction
): PhoneTreeTypes.InstructionWithIds => {
  const { type } = action;

  switch (type) {
    case InstructionsTypes.Instruction.CallGroup: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,

        type: InstructionsTypes.Instruction.CallGroup,
        callGroup: {
          callGroupId: action.payload.targetId,
          callerName: action.payload.callerName,
        },
      };
    }
    case InstructionsTypes.Instruction.CallQueue: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.CallQueue,
        callQueue: {
          callQueueId: action.payload.targetId,
          callerName: action.payload.callerName,
        },
      };
    }
    case InstructionsTypes.Instruction.DataEndpoint: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.DataEndpoint,
        dataEndpoint: {
          dataEndpointId: action.payload.targetId,
        },
      };
    }
    // case InstructionsTypes.Instruction.DepartmentId: {
    //   return {
    //     instructionId: state.instructionId,
    //     instructionSetId: state.instructionSetId,
    //     type: InstructionsTypes.Instruction.DepartmentId,
    //     [InstructionsTypes.Instruction.DepartmentId]: action.payload.targetId,
    //   };
    // }
    case InstructionsTypes.Instruction.ForwardDevice: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.ForwardDevice,
        forwardDevice: {
          deviceId: action.payload.targetId,
          callerName: action.payload.callerName,
        },
      };
    }
    case InstructionsTypes.Instruction.ForwardNumber: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.ForwardNumber,
        forwardNumber: {
          forwardingNumberId: action.payload.targetId,
        },
      };
    }
    case InstructionsTypes.Instruction.Hangup: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.Hangup,
        hangup: {},
      };
    }
    case InstructionsTypes.Instruction.InstructionSet: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.InstructionSet,
        instructionSet: {
          instructionSetId: action.payload.instructionSetId,
          instructions: action.payload.instructions,
        },
        conditionId: action.payload.conditionId,
      };
    }
    case InstructionsTypes.Instruction.IVRMenu: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.IVRMenu,
        ivrMenu: {
          phoneTreeId: action.payload.targetId,
        },
      };
    }
    case InstructionsTypes.Instruction.Play: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.Play,
        play: {
          mediaFileId: action.payload.targetId,
        },
      };
    }
    case InstructionsTypes.Instruction.VoicemailBox: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.VoicemailBox,
        voicemailBox: {
          voicemailBoxId: action.payload.targetId,
        },
      };
    }
    case InstructionsTypes.Instruction.VoicemailPrompt: {
      return {
        instructionId: state.instructionId,
        instructionSetId: state.instructionSetId,
        type: InstructionsTypes.Instruction.VoicemailPrompt,
        voicemailPrompt: action.payload,
      };
    }
  }

  return state;
};
