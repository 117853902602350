import { useRef, useState, useLayoutEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text, Chip, useTooltip } from '@frontend/design-system';

type FeedbackIssueInputProps = {
  onRemoveLabel: (label: string) => void;
  selectedLabels: string[];
  selectedKeys: string[];
};

const FeedbackIssueInput = ({ onRemoveLabel, selectedLabels, selectedKeys }: FeedbackIssueInputProps) => {
  const [visibleCount, setVisibleCount] = useState<number | null>(null);
  const [finalVisibleCount, setFinalVisibleCount] = useState<number | null>(null);
  const hiddenMeasureRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { Tooltip, triggerProps, tooltipProps } = useTooltip({ placement: 'top-end' });

  useLayoutEffect(() => {
    if (!(hiddenMeasureRef.current && containerRef.current)) return;

    const chips = Array.from(hiddenMeasureRef.current.querySelectorAll('.chip-to-measure'));
    let totalWidth = 0;
    let count = 0;

    const maxVisibleWidth = containerRef.current.offsetWidth - 120;

    for (let i = 0; i < chips.length; i++) {
      const chip = chips[i] as HTMLElement;
      const gap = i === 0 ? 0 : 8; // 8px gap between chips
      const chipWidth = chip.offsetWidth + gap;

      if (totalWidth + chipWidth > maxVisibleWidth) break;
      totalWidth += chipWidth;
      count++;
    }
    setVisibleCount(count);
  }, [selectedLabels]);

  useLayoutEffect(() => {
    if (!hiddenMeasureRef.current || visibleCount === null) return;

    const allChips = selectedLabels;
    if (visibleCount >= allChips.length) {
      setFinalVisibleCount(visibleCount);
      return;
    }

    const allChipElements = Array.from(hiddenMeasureRef.current.querySelectorAll('.chip-to-measure'));
    const chipWidths = allChipElements.map((chip, idx) => {
      const c = chip as HTMLElement;
      const gap = idx === 0 ? 0 : 8;
      return c.offsetWidth + gap;
    });

    let tryCount = visibleCount;
    while (tryCount > 0) {
      const widthSum = chipWidths.slice(0, tryCount).reduce((acc, w) => acc + w, 0);
      if (widthSum <= hiddenMeasureRef.current.offsetWidth) {
        setFinalVisibleCount(tryCount);
        return;
      }
      tryCount--;
    }

    setFinalVisibleCount(0);
  }, [visibleCount, selectedLabels]);

  const displayedChips = selectedLabels.slice(0, finalVisibleCount || 0);
  const moreCount = selectedLabels.length - (finalVisibleCount || 0);

  return (
    <div css={styles.wrapper} ref={containerRef}>
      <div
        css={styles.chipsContainer}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {displayedChips.map((label, idx) => (
          <Chip.Removable
            key={label}
            onClick={() => {
              onRemoveLabel(selectedKeys[idx]);
            }}
            css={styles.chip}
          >
            {label}
          </Chip.Removable>
        ))}
      </div>
      {moreCount > 0 && (
        <div css={styles.moreContainer}>
          <Text
            as='span'
            color='subdued'
            css={css`
              white-space: nowrap;
              cursor: pointer;
            `}
            {...triggerProps}
          >
            +{moreCount} more
          </Text>
          <Tooltip {...tooltipProps} css={styles.tooltipContent}>
            <Text size='medium' color='white'>
              {selectedLabels.slice(finalVisibleCount || 0).join(', ')}
            </Text>
          </Tooltip>
        </div>
      )}
      <div css={styles.hiddenMeasure} ref={hiddenMeasureRef}>
        {selectedLabels.map((label) => (
          <Chip.Removable className='chip-to-measure' key={label} css={styles.chip} onClick={() => {}}>
            {label}
          </Chip.Removable>
        ))}
        {selectedLabels.length > (visibleCount ?? 0) && (
          <span className='measure-more-label'>
            <Text as='span' size='small' color='subdued'>
              +{selectedLabels.length - (visibleCount || 0)} more
            </Text>
          </span>
        )}
      </div>
    </div>
  );
};

const styles = {
  wrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    width: 100%;
  `,
  chipsContainer: css`
    display: flex;
    gap: ${theme.spacing(1)};
    overflow: hidden;
    flex-wrap: nowrap;
    max-width: calc(100% - 113px);
  `,
  moreContainer: css`
    margin-left: auto;
    white-space: nowrap;
    margin-right: ${theme.spacing(4)};
  `,
  chip: css`
    background-color: ${theme.colors.primary5};
    font-size: ${theme.fontSize(12)};
    border: none;
    color: ${theme.colors.neutral90};
    max-width: ${theme.spacing(28)};

    button {
      padding: ${theme.spacing(0.5)};
      svg {
        width: ${theme.spacing(1)};
        height: ${theme.spacing(1)};
        color: ${theme.colors.neutral90};
      }
    }
  `,
  hiddenMeasure: css`
    gap: ${theme.spacing(1)};
    display: flex;
    position: absolute;
    top: -9999px;
    left: -9999px;
    visibility: hidden;
    pointer-events: none;
    white-space: nowrap;
    background-color: red;
    align-items: center;
  `,
  tooltipContent: css`
    max-width: 210px;
  `,
};

export default FeedbackIssueInput;
