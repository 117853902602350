import { useEffect } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { CaretDownIconSmall, NakedButton } from '@frontend/design-system';
import { DateRangeFieldProps, QuickFillButtonFilter, useDateFilters } from '../../hooks';
import { QuickFillConfigOption } from '../../types';
import { kebabCase } from '../../utils';
import { CustomDatePicker } from './custom-date-picker';

type QuickFillButtonProps = {
  onSelect: (range: string[]) => void;
  filter: QuickFillButtonFilter;
  option: QuickFillConfigOption;
  dateRangeProps: DateRangeFieldProps;
};

const style = css`
  position: relative;
  & > button {
    outline: none;
    padding: ${theme.spacing(1)};
    color: ${theme.colors.neutral80};
    border: thin solid ${theme.colors.neutral20};
    background: ${theme.colors.white};
    border-radius: ${theme.spacing(1)};
    font-size: ${theme.fontSize(12)};
    display: flex;
    &.selected {
      color: ${theme.colors.white};
      font-weight: 900;
      border: thin solid ${theme.colors.primary50};
      background: ${theme.colors.primary50};
    }
    &:hover {
      color: ${theme.colors.neutral80};
      border: thin solid ${theme.colors.neutral20};
      background: ${theme.colors.neutral5};
    }
    &:focus {
      border: thin solid ${theme.colors.primary50};
      outline: none;
    }
    &.selected:focus {
      background: ${theme.colors.primary70};
      border: thin solid ${theme.colors.primary70};
      outline: none;
    }
    &.selected:hover {
      color: ${theme.colors.white};
      background: ${theme.colors.primary70};
      border: thin solid ${theme.colors.primary70};
    }
    &.selected:focus:hover {
      color: ${theme.colors.white};
      background: ${theme.colors.primary50};
      border: thin solid ${theme.colors.primary50};
    }
    &.custom-range-button {
      padding: ${theme.spacing(1, 1, 0.5, 1)};
    }
  }
  & .custom-date-range {
    min-width: 350px;
    position: absolute;
    right: 0;
    top: calc(100% + ${theme.spacing(1)});
    background: ${theme.colors.white};
    padding: ${theme.spacing(3, 2, 0)};
    border-radius: ${theme.spacing(1)};
    border: thin solid ${theme.colors.neutral20};
    box-shadow: ${theme.shadows.heavy};
    z-index: ${theme.zIndex.dropdown};
  }
`;

export const QuickFillButton = ({ onSelect, filter, option, dateRangeProps }: QuickFillButtonProps) => {
  const { isSelected, handleClick, getTitle, customDown, setCustomDown } = useDateFilters({
    filter,
    onSelect,
    dateRangeProps,
  });

  useEffect(() => {
    if (getIsSelectClass() !== 'selected') {
      setCustomDown(false);
    }
  }, [filter]);

  const getIsSelectClass = () => {
    if (isSelected(option)) {
      return 'selected';
    } else if (option.period === 'custom') {
      return 'custom-range-button';
    }
    return '';
  };

  return (
    <div css={style}>
      <NakedButton
        title={getTitle(option)}
        className={getIsSelectClass()}
        trackingId={`pay-portal-invoicelist-quick-range-btn-${kebabCase(option.label)}`}
        onClick={() => handleClick(option)}
      >
        {option.label}
        {option.period === 'custom' && (
          <CaretDownIconSmall
            css={css`
              cursor: pointer;
              transform: scale(0.6) translateY(-2px);
              margin-left: ${theme.spacing(1)};
            `}
          />
        )}
      </NakedButton>
      {customDown && (
        <CustomDatePicker
          dateRangeProps={dateRangeProps}
          closePicker={() => setCustomDown(false)}
          onSelect={onSelect}
        />
      )}
    </div>
  );
};
