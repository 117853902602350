import { css } from '@emotion/react';
import { motion } from 'motion/react';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';

type QuickActionChipProps = {
  children?: React.ReactNode;
  onClick: () => void;
  variant?: 'action' | 'empty';
  leftElement?: JSX.Element;
  trackingId?: string;
};

export const QuickActionChip = ({
  children,
  onClick,
  variant = 'action',
  leftElement,
  trackingId,
}: QuickActionChipProps) => {
  const isAction = variant === 'action';

  return (
    <Chip
      trackingId={trackingId}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      as={motion.div}
      layout
      onClick={onClick}
      variant={isAction ? 'outline' : 'interactive'}
      css={[
        css`
          max-width: 100%;
          cursor: pointer;
          background: ${theme.colors.white};
          width: auto;
          height: 32px;
          font-weight: ${theme.font.weight.bold};
          padding: ${theme.spacing(0, 1.5)};
        `,
        isAction &&
          css`
            color: ${theme.colors.neutral70};
          `,
      ]}
      leftElement={leftElement}
    >
      {children}
    </Chip>
  );
};
