import { motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, NakedButton } from '@frontend/design-system';
import { buttonStyles, MenuOption } from './thread-options-drawer';

type ThreadOptionsMenuProps = {
  setSelectedMenu: (newVal: MenuOption | undefined) => void;
  hideOptions?: MenuOption[];
  associatedContactsCount?: number;
};

export const ThreadOptionsMenu = ({
  setSelectedMenu,
  hideOptions = [],
  associatedContactsCount,
}: ThreadOptionsMenuProps) => {
  const { t } = useTranslation('mini-chat');

  return (
    <motion.div layout='position'>
      {!hideOptions.includes('change-thread') && (
        <NakedButton
          css={buttonStyles}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedMenu('change-thread');
          }}
        >
          <span
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Icon name='message-thread-small' />
            <Text>{t('Change Thread')}</Text>
          </span>
          <span
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Icon name='caret-right-small' />
          </span>
        </NakedButton>
      )}
      {!hideOptions.includes('associated-contacts') && (
        <NakedButton
          css={buttonStyles}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setSelectedMenu('associated-contacts');
          }}
        >
          <span
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            <Icon name='family-small' />
            <Text>{t('Associated Contacts')}</Text>
          </span>
          <span
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            {associatedContactsCount && (
              <Text color='light' size='small'>
                {associatedContactsCount}
              </Text>
            )}
            <Icon name='caret-right-small' />
          </span>
        </NakedButton>
      )}
    </motion.div>
  );
};
