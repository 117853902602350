import { StatusType } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { InfiniteData } from 'react-query';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';

type CallRecordings = {
  appointmentType?: CallIntelligenceTypes.AppointmentTypeEnum;
  category?: CallIntelligenceTypes.CategoryEnum;
  count: number;
  sentiment?: CallIntelligenceTypes.SentimentEnum;
  showOnlyUnscheduled?: boolean;
  serviceQualityFlags?: CallIntelligenceTypes.ServiceQualityFlagEnum[];
};

// Constants
export const appointmentTypes: CallIntelligenceTypes.AppointmentTypeEnum[] = [
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS,
];

export const categories: CallIntelligenceTypes.CategoryEnum[] = [
  CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING,
];

export const contactTypes: CallIntelligenceTypes.ContactTypeEnum[] = [
  CallIntelligenceTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT,
  CallIntelligenceTypes.ContactTypeEnum.CONTACT_NEW_PATIENT,
  CallIntelligenceTypes.ContactTypeEnum.CONTACT_NOT_A_PATIENT,
];

const serviceQualityDetailsMapping: Partial<
  Record<CallIntelligenceTypes.ServiceQualityFlagEnum, { summary: string; tsStart: string }[]>
> = {
  [CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE]: [
    {
      summary:
        'The patient, Frank David, had an $800 insurance claim for a root canal procedure that remains unpaid after two months.',
      tsStart: '00:41:00',
    },
    {
      summary:
        "The insurance company claimed they needed additional information from the dental office, which the office had reportedly sent but the insurance company said they hadn't received.",
      tsStart: '01:06:00',
    },
    {
      summary:
        'This back-and-forth had been going on for weeks, causing frustration for the patient who was still stuck with the unpaid $800 bill.',
      tsStart: '01:25:00',
    },
  ],
  [CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION]: [
    {
      summary:
        "Joan effectively addressed the patient's financial concern by assuring they would escalate the unresolved $800 insurance claim to the office manager Sarah and ask her to personally follow up with the insurance company.",
      tsStart: '01:35:00',
    },
    {
      summary: 'Joan also promised a callback with an update by 5pm the next day',
      tsStart: '02:50:00',
    },
    {
      summary: "and offered to apply a courtesy credit if the issue wasn't resolved by that time.",
      tsStart: '02:11:00',
    },
  ],
};

const taskDetailsMapping: Partial<
  Record<CallIntelligenceTypes.TaskTypeEnum, { title: string; description: string; citation: string }>
> = {
  [CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE]: {
    title: 'Call insurance company to follow up on claim.',
    description:
      'The insurance company may need additional information. Call the insurance company and follow up on claim for root canal procedure performed two months ago. Confirm receipt of information sent last week and escalate to Sarah. Sarah will call the patient by the end of the day tomorrow with an update.',
    citation:
      "I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly.",
  },
  [CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING]: {
    title: 'Apply Courtesy Credit',
    description:
      "If insurance hasn't processed the claim by tomorrow, apply $800 courtesy credit to Frank David's account.",
    citation:
      "If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to your account for the full amount while we continue to work with the insurance company.",
  },
  [CallIntelligenceTypes.TaskTypeEnum.TYPE_WAITLIST]: {
    title: 'Add patient to the waitlist for an evening cleaning appointment.',
    description:
      'Call patient if an evening cleaning appointment opens up. His previous cleaning was five months ago. The office does not have any evening appointments available for three weeks. He prefers to be contacted after 5 PM on weekdays.',
    citation:
      "What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call.",
  },
};

export const officeUsers: CallIntelligenceTypes.OfficeUser[] = demoDataUtils
  .generateRandomUserNames(20)
  .map((name, id) => ({
    ...name,
    status: StatusType.ACTIVE,
    userId: `demo-${id}`,
  }));

export const phoneNumbers: string[] = [
  '1234567890',
  '2345678901',
  '3456789012',
  '4567890123',
  '5678901234',
  '6789012345',
  '7890123456',
  '8901234567',
  '9012345678',
  '0123456789',
];

export const taskTypes: CallIntelligenceTypes.TaskTypeEnum[] = [
  CallIntelligenceTypes.TaskTypeEnum.TYPE_WAITLIST,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING,
];

export const serviceQualityFlags: CallIntelligenceTypes.ServiceQualityFlagEnum[] = [
  CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION,
  CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE,
];

export const sentiments: CallIntelligenceTypes.SentimentEnum[] = [
  CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE,
  CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL,
  CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE,
  CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN,
];

// Utility Functions
export const getRandomElement = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)];

export const getRandomElementNoMatch = <T>(array: T[], exclude?: T): T => {
  if (exclude == null) {
    return getRandomElement(array);
  }
  const filtered = array.filter((item) => item !== exclude);
  return filtered.length ? getRandomElement(filtered) : getRandomElement(array);
};

export const randomBooleanNoMatch = (exclude?: boolean) => {
  if (exclude == null) return Math.random() > 0.5;
  return !exclude;
};

export const getRandomSentiment = (): CallIntelligenceTypes.SentimentEnum => getRandomElement(sentiments);

export const getRandomContactType = (): CallIntelligenceTypes.ContactTypeEnum => getRandomElement(contactTypes);

export const getRandomDirection = (): CallIntelligenceTypes.CallDirectionEnum =>
  getRandomElement([
    CallIntelligenceTypes.CallDirectionEnum.DIRECTION_INBOUND,
    CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND,
  ]);

const getRandomServiceQualityFlags = (): CallIntelligenceTypes.ServiceQualityFlagEnum[] => {
  const randomCount = Math.floor(Math.random() * serviceQualityFlags.length) + 1;
  return [...serviceQualityFlags].sort(() => 0.5 - Math.random()).slice(0, randomCount);
};

const getRandomTaskTypes = (count: number): CallIntelligenceTypes.TaskTypeEnum[] =>
  [...taskTypes].sort(() => 0.5 - Math.random()).slice(0, count);

const getRandomTaskStatus = (): CallIntelligenceTypes.TaskStatusEnum =>
  getRandomElement([
    CallIntelligenceTypes.TaskStatusEnum.STATUS_TODO,
    CallIntelligenceTypes.TaskStatusEnum.STATUS_IN_PROGRESS,
    CallIntelligenceTypes.TaskStatusEnum.STATUS_COMPLETED,
  ]);

const getRandomTasks = (
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[],
  callId: string
): CallIntelligenceTypes.Task[] => {
  const defaultTaskDetails = {
    title: '',
    description: '',
    citation: '',
  };

  return taskTypes.map((taskType, idx) => {
    const taskDetails = taskDetailsMapping[taskType] || defaultTaskDetails;
    return {
      assignee: '',
      citation: '',
      description: taskDetails.description,
      id: `demo-${idx}`,
      locationId: '',
      metadata: JSON.stringify({ citation: taskDetails.citation }),
      sourceId: callId,
      status: getRandomTaskStatus(),
      title: taskDetails.title,
      type: taskType,
    };
  });
};

// Demo Data Function
export const callRecordings = ({
  appointmentType,
  category,
  count,
  sentiment,
  serviceQualityFlags,
  showOnlyUnscheduled,
}: CallRecordings): InfiniteData<CallIntelligenceTypes.CallsResponse> => {
  return {
    pages: [
      {
        calls: demoDataUtils.generateRandomUserNames(count).map((name, id) => {
          const schedulingOpportunity = Math.random() > 0.5;
          const taskCount = Math.floor(Math.random() * taskTypes.length) + 1;

          return {
            appointmentTypes: [appointmentType ?? getRandomElement(appointmentTypes)],
            categories: [category ?? getRandomElement(categories)],
            contactType: getRandomContactType(),
            direction: getRandomDirection(),
            id: `demo-${id}`,
            locationId: '001',
            mosScore: Math.floor(Math.random() * 6),
            officeNumber: {
              countryCode: 1,
              nationalNumber: getRandomElement(phoneNumbers),
            },
            officeUser: getRandomElement(officeUsers),
            person: {
              ...name,
              id: `demo-${id}`,
            },
            phoneNumber: {
              countryCode: 1,
              nationalNumber: '234567890',
            },
            schedulingOpportunity: true,
            schedulingOutcome: showOnlyUnscheduled ? false : schedulingOpportunity ? Math.random() > 0.5 : false,
            sentiment: sentiment ?? getRandomSentiment(),
            serviceQualityFlags: serviceQualityFlags ?? getRandomServiceQualityFlags(),
            startTime: demoDataUtils.getRandomDateAndTime(),
            status: CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_COMPLETE,
            taskCount,
            taskTypes: getRandomTaskTypes(taskCount),
          };
        }),
        pageConfig: {
          pageNumber: 1,
          pageSize: 10,
        },
      },
    ],
    pageParams: [],
  };
};

export const callMetadataResponse = (): CallIntelligenceTypes.CallMetadataResponse => {
  return {
    summary:
      "[PERSON_NAME] contacted Happy Smiles Dental regarding a delayed insurance claim for a root canal procedure. The office staff acknowledged the issue and confirmed that they had sent the necessary information to the insurance company but would resend it immediately. To expedite resolution, the office manager, Sarah, will follow up with the insurance company and provide an update to [PERSON_NAME] by the end of the next day. Additionally, as a courtesy, if the claim is not processed by then, a credit will be applied to [PERSON_NAME]'s account for the full amount while the issue is resolved. While on the call, [PERSON_NAME] also requested to schedule a cleaning appointment, but due to limited availability, he was added to a waitlist for an earlier or evening appointment.",
    transcript: `
    [office] <0 --> 5> Happy Smiles Dental, this is Joan speaking. How may I assist you?
    [caller] <5 --> 15> Hi Joan, this is [PERSON_NAME]. I'm calling again about the insurance claim for my root canal procedure from two months ago. I've been trying to get this resolved for weeks now.
    [office] <16 --> 25> I apologize for the ongoing issue, Mr. [PERSON_NAME]. Let me pull up your file. Can you confirm your date of birth for me, please?
    [caller] <25 --> 28> It's December 13, 1976.
    [office] <28 --> 40> Thank you. I see your file here. Yes, I can see the notes from your previous calls about this claim. I understand how frustrating this must be for you. Can you remind me what the insurance company told you when you last spoke with them?
    [caller] <41 --> 65> They said they needed additional information from your office. I called you guys last week, and someone told me they would send it right away. But when I called the insurance company yesterday, they said they still hadn't received anything. This has been going on for almost two months now, and I'm still stuck with this $800 bill that should be covered.
    [office] <66 --> 85> I sincerely apologize for this delay, Mr. [PERSON_NAME]. Let me look into this right away. I see here that we did send the additional information last week, but it seems there might have been an issue with the transmission. I'm going to resend it right now while we're on the phone, and I'll also call the insurance company to confirm they've received it.
    [caller] <85 --> 95> I appreciate that, but I've heard similar things before. I really need this resolved. I can't keep calling back and forth between you and the insurance company.
    [office] <95 --> 120> I completely understand your frustration, Mr. [PERSON_NAME]. This shouldn't have taken so long to resolve. Here's what I'm going to do: I'm resending the information right now, and I'm also going to escalate this to our office manager, Sarah. She'll personally follow up with the insurance company today to ensure they process your claim correctly. We'll take care of this for you so you don't have to make any more calls about this issue.
    [caller] <121 --> 130> That sounds better, but I've been told before that it would be handled. How can I be sure it'll be different this time?
    [office] <131 --> 160> You're right to be skeptical given the delays you've experienced. I assure you that we take this very seriously. Sarah will personally call you by the end of the day tomorrow with an update, regardless of the outcome. If for any reason the insurance company still hasn't processed the claim by then, we'll apply a courtesy credit to your account for the full amount while we continue to work with the insurance company. This way, you won't have to worry about the bill anymore. Does that sound fair?
    [caller] <160 --> 170> Yes, that does sound better. I appreciate you taking this seriously. I'll look forward to hearing from Sarah by tomorrow evening then.
    [office] <170 --> 185> Excellent. I've made a note in your file about this conversation and our commitment. Sarah will call you by 5 PM tomorrow at the latest. If you don't hear from her by then, please call us back immediately and ask to speak with her.
    [caller] <186 --> 205> Thank you. While we're on the phone, I also wanted to schedule my next cleaning appointment. My last one was about five months ago, so I think I'm due for another one soon.
    [office] <206 --> 225> Of course, Mr. [PERSON_NAME]. I'd be happy to help you schedule your next cleaning. Let me check our availability. We have an opening next Tuesday at 2 PM with Dr. Johnson. Would that work for you?
    [caller] <225 --> 235> Tuesday afternoons are tough for me. Do you have anything later in the day or maybe on a Thursday or Friday?
    [office] <235 --> 255> I understand. Let me see what else we have. We have a Thursday slot at 4:30 PM two weeks from now, or a Friday morning appointment at 10 AM in three weeks. Would either of those be more convenient for you?
    [caller] <256 --> 275> The Thursday slot would be better, but two weeks is a bit far out. And Friday morning won't work because I have standing meetings at that time. Do you have anything sooner in the evenings, maybe after 5 PM?
    [office] <276 --> 300> I apologize, Mr. [PERSON_NAME], but we don't have any evening appointments available in the next three weeks. Our latest appointment is usually at 4:30 PM. We do occasionally have Saturday morning appointments, but the next available one isn't for about a month. Would you like me to book that for you?
    [caller] <300 --> 320> No, that's too far out. I was hoping to get something sooner. I guess I'll have to check my schedule and call back later to see if anything opens up. It's just been difficult to find a time that works with my current work schedule.
    [office] <320 --> 350> I completely understand, Mr. [PERSON_NAME]. I'm sorry we couldn't find a suitable time for you today. What I can do is add you to our waitlist for any earlier or evening appointments that might open up. If something becomes available, we'll give you a call. In the meantime, please feel free to check back with us in a week or so, and we'll see if we have any new openings that might work better for you. Is there a best time of day to reach you if an appointment opens up?
    [caller] <351 --> 360> You can try me anytime after 5 PM on weekdays. I appreciate you adding me to the waitlist.
    [office] <361 --> 385> You're welcome, Mr. [PERSON_NAME]. I've added you to our waiting list for evening appointments, noting your preference for after 5 PM on weekdays. We'll do our best to accommodate you as soon as possible. Is there anything else I can help you with today?
    [caller] <385 --> 390> No, that's all for now. Thank you for your help, Joan.
    [office] <390 --> 410> You're welcome, Mr. [PERSON_NAME]. Again, I apologize for the inconvenience with both the insurance issue and the scheduling. We appreciate your patience. Remember, you'll hear from Sarah about your insurance claim by 5 PM tomorrow, and we'll call you if a suitable cleaning appointment opens up. Have a good day, Mr. [PERSON_NAME].
    [caller] <411 --> 413> Thank you. Goodbye.
    [office] <413 --> 415> Goodbye, Mr. [PERSON_NAME].
  `,
    callMetadata: {
      caller: JSON.stringify({
        date_of_birth: '12/13/1986',
        insurance: {
          name: 'Delta Dental of Massachusetts',
          phone: null,
          fax: null,
          email: null,
          insurance_ids: ['111-000-1111'],
        },
        dependents: [],
      }),
      receptionist: JSON.stringify({}),
    },
  };
};

export const generateTasksForCalls = (
  calls: CallIntelligenceTypes.Call[]
): CallIntelligenceTypes.CallTaskListResponse => {
  const tasks = calls.flatMap((call) => {
    const { id: callId, taskTypes } = call;
    return getRandomTasks(taskTypes, callId);
  });

  return { tasks };
};

export const callTaskListResponse = (
  taskCount: number,
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[],
  callId: string
): CallIntelligenceTypes.CallTaskListResponse => {
  return {
    tasks: taskCount > 0 ? getRandomTasks(taskTypes, callId) : [],
  };
};

export const getFilteredCallRecordings = (
  callRecordings: InfiniteData<CallIntelligenceTypes.CallsResponse>,
  filters: CallIntelligenceTypes.Filters
): InfiniteData<CallIntelligenceTypes.CallsResponse> => {
  if (!callRecordings) return callRecordings;

  const filterCall = (call: CallIntelligenceTypes.Call): boolean => {
    const contactType =
      filters?.contactTypes?.length && call.contactType ? filters.contactTypes.includes(call.contactType) : true;

    const officeUsers =
      filters?.officeUsers?.length && call.officeUser?.userId
        ? filters.officeUsers.includes(call.officeUser?.userId)
        : true;

    const schedulingOutcome = filters?.schedulingOutcomes?.length
      ? (filters?.schedulingOutcomes?.includes(
          CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED
        ) &&
          call.schedulingOutcome === true) ||
        (filters?.schedulingOutcomes?.includes(
          CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED
        ) &&
          call.schedulingOutcome === false)
      : true;

    return contactType && officeUsers && schedulingOutcome;
  };

  return {
    pages: callRecordings.pages.map((page) => ({
      calls: page.calls?.filter(filterCall),
      pageConfig: { ...page.pageConfig },
    })),
    pageParams: callRecordings.pageParams,
  };
};

export const callServiceQualityResponse = (
  serviceQualityFlags: CallIntelligenceTypes.ServiceQualityFlagEnum[]
): CallIntelligenceTypes.ServiceQualityResponse => {
  const issues: CallIntelligenceTypes.ServiceQualityFlagData[] = [];
  const resolution: CallIntelligenceTypes.ServiceQualityFlagData[] = [];

  serviceQualityFlags.forEach((flag) => {
    const details = serviceQualityDetailsMapping[flag];
    if (!details) return;

    if (flag === CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_UNRESOLVED_ISSUE) {
      issues.push(...details.map(({ summary, tsStart }) => ({ summary, tsStart })));
    } else if (flag === CallIntelligenceTypes.ServiceQualityFlagEnum.FLAG_EXCELLENT_RESOLUTION) {
      resolution.push(...details.map(({ summary, tsStart }) => ({ summary, tsStart })));
    }
  });

  return {
    issues,
    resolution,
  };
};
