import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { DropdownField, Text, PopoverDialog, useControlledField, usePopoverDialog } from '@frontend/design-system';

type LocationSelectorDialogProps = {
  defaultSelectedLocationId?: string;
  locationIds?: string[];
  setSelectedLocationId: (locationId: string) => void;
  dialogProps: ReturnType<typeof usePopoverDialog>;
  width?: number;
};

export const LocationSelectorDialog = ({
  defaultSelectedLocationId = '',
  locationIds = [],
  setSelectedLocationId,
  dialogProps,
  width = 388,
}: LocationSelectorDialogProps) => {
  const { t } = useTranslation('scheduleQuickfill');
  const { selectedLocationIds, getLocationName } = useAppScopeStore();

  const locationIdsToRender = locationIds?.length ? locationIds : selectedLocationIds;

  const hasMoreThanSixLocations = locationIdsToRender.length > 6;

  const locationSelectorDropdownField = useControlledField({
    type: 'dropdown',
    value: defaultSelectedLocationId,
    required: hasMoreThanSixLocations,
    onChange: (value: string) => {
      if (value) setSelectedLocationId(value);
    },
  });

  return (
    <PopoverDialog css={{ width }} {...dialogProps.getDialogProps()}>
      <Text weight='bold' size='large'>
        {t('Select a Location')}
      </Text>
      <section css={{ marginTop: theme.spacing(1) }}>
        {hasMoreThanSixLocations ? (
          <div css={{ marginTop: theme.spacing(3), marginBottom: theme.spacing(6) }}>
            <DropdownField
              {...locationSelectorDropdownField}
              label={t('Select Location')}
              name={t('Location Selector')}
            >
              {locationIdsToRender.map((locationId) => (
                <DropdownField.Option key={locationId} value={locationId}>
                  {getLocationName(locationId)}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </div>
        ) : (
          <div css={locationGridStyle}>
            {locationIdsToRender.map((locationId) => (
              <LocationSelectorTile
                key={locationId}
                locationId={locationId}
                locationName={getLocationName(locationId)}
                setSelectedLocationId={setSelectedLocationId}
              />
            ))}
          </div>
        )}
      </section>
    </PopoverDialog>
  );
};

const locationGridStyle = css({
  display: 'grid',
  gap: theme.spacing(1),
  gridTemplateColumns: 'repeat(auto-fill, minmax(170px, 1fr))',
  overflowY: 'auto',
});

type LocationSelectorTileProps = {
  locationId: string;
  locationName: string;
  setSelectedLocationId: (locationId: string) => void;
};

const LocationSelectorTile = ({ locationId, locationName, setSelectedLocationId }: LocationSelectorTileProps) => {
  return (
    <section css={locationSelectorTile} onClick={() => setSelectedLocationId(locationId)}>
      <Icon name='location' />
      <Text size='medium'>{locationName}</Text>
    </section>
  );
};

const locationSelectorTile = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.small,
  padding: theme.spacing(1, 2),
  cursor: 'pointer',
  ': hover >': {
    backgroundColor: theme.colors.neutral10,
  },
});
