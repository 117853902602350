import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';
import { useAppointmentsCalendarViewProps } from '../../../../context/AppointmentsCalendarViewContext';

type LocationChipProps = {
  show?: boolean;
};

export const LocationChip = ({ show = false }: LocationChipProps) => {
  const { t } = useTranslation('schedule');
  const { selectedLocationIds, getLocationName } = useAppScopeStore();
  const { selectedLocationIds: calendarLocationIds } = useAppointmentsCalendarViewProps();

  const calendarLocationCount = calendarLocationIds?.length || selectedLocationIds?.length;

  if (!show || selectedLocationIds?.length <= 1) return null;

  const showSingleChip = calendarLocationIds?.length === 1 && selectedLocationIds.length > 1;

  if (showSingleChip) {
    return <Chip.SingleChip css={chipStyle}>{getLocationName(calendarLocationIds[0])}</Chip.SingleChip>;
  }

  return <Chip.MultiChip css={chipStyle}>{t(`{{count}} Locations`, { count: calendarLocationCount })}</Chip.MultiChip>;
};

const chipStyle = css({
  alignSelf: 'flex-end',
  width: 'fit-content',
  maxWidth: 'unset',
  margin: theme.spacing(2),
});
