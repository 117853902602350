import { theme } from '@frontend/theme';
import { Text, emptyStateGraphics, Heading } from '@frontend/design-system';

const EmptyStateGraphic = emptyStateGraphics.sync_your_phone;

export const EmptyStateSection = ({ title, subtitle, ...rest }: { title: string; subtitle?: string }) => {
  return (
    <section
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
        alignItems: 'center',
        padding: theme.spacing(3, 5),
      }}
      {...rest}
    >
      <EmptyStateGraphic
        css={{
          maxWidth: '200px',
          maxHeight: '200px',
        }}
      />
      <Heading level={3} css={{ color: theme.colors.neutral50 }}>
        {title}
      </Heading>
      <Text color='light' textAlign='center'>
        {subtitle}
      </Text>
    </section>
  );
};
