import {
  BASE_LIBRARY_ROUTE,
  BASE_FORMS_LIBRARY_ROUTE,
  BASE_IMPORTED_FORMS_LIBRARY_ROUTE,
  BASE_PACKETS_LIBRARY_ROUTE,
} from './forms-library.constants';
import {
  getFormV2ListRoute,
  getFormV2DetailsRoute,
  getPacketsV2ListRoute,
  getPacketsV2DetailsRoute,
} from './forms-library.utils';

export const FORMS_LIBRARY_ROUTES = {
  base: BASE_LIBRARY_ROUTE,
  formsV2: getFormV2ListRoute,
  formV2Details: getFormV2DetailsRoute,
  packetsV2: getPacketsV2ListRoute,
  packetV2Details: getPacketsV2DetailsRoute,
  /** @deprecated */
  forms: BASE_FORMS_LIBRARY_ROUTE,
  /** @deprecated */
  formDetails: (formId?: string) => {
    return formId ? `${BASE_FORMS_LIBRARY_ROUTE}/${formId}` : `${BASE_FORMS_LIBRARY_ROUTE}/:id`;
  },
  /** @deprecated */
  packets: BASE_PACKETS_LIBRARY_ROUTE,
  /** @deprecated */
  packetDetails: (packetId?: string) => {
    return packetId ? `${BASE_PACKETS_LIBRARY_ROUTE}/${packetId}` : `${BASE_PACKETS_LIBRARY_ROUTE}/:id`;
  },
  /** @deprecated */
  imported: BASE_IMPORTED_FORMS_LIBRARY_ROUTE,
  /** @deprecated */
  importedFormDetails: (formId?: string) => {
    return formId ? `${BASE_IMPORTED_FORMS_LIBRARY_ROUTE}/${formId}` : `${BASE_IMPORTED_FORMS_LIBRARY_ROUTE}/:id`;
  },
};
