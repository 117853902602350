import { useAppScopeStore } from '@frontend/scope';

export const useIsMulti = () => {
  const { getSelectedLocationData, selectedLocationIds = [], selectedLocationIdsWithParents = [] } = useAppScopeStore();

  let parentId: string | undefined = '';
  let isMulti = false;

  // When user uses new GLS, selectedLocationIdsWithParents is missing the parent id of the selected location, hence need conditional logic
  if (selectedLocationIdsWithParents.length !== selectedLocationIds.length) {
    // User used old location picker
    const selectedLocationData = getSelectedLocationData()[selectedLocationIdsWithParents[0]];
    parentId = selectedLocationData?.parentId || selectedLocationData?.parentID;
    const hasChildren = !!selectedLocationData?.children?.length;
    isMulti = selectedLocationIdsWithParents.length > 1 || !!parentId || hasChildren;
  } else {
    // User used new GLS
    // If non active locations are in the selected list, they are also missing on parent id details which is causing data issues
    // Hence, iterating though the locations to get the parent id of the selected location
    const locationWithParentId = Object.values(getSelectedLocationData() || {}).find(
      (location) => !!(location?.parentId || location?.parentID)
    );
    parentId = locationWithParentId?.parentId || locationWithParentId?.parentID;
    isMulti = selectedLocationIdsWithParents.length > 1 || !!parentId;
  }

  return {
    isMulti,
    parentId: parentId ?? selectedLocationIdsWithParents[0],
  };
};
