import { SerializedStyles, css } from '@emotion/react';
import { motion, AnimatePresence } from 'motion/react';
import { theme } from '@frontend/theme';
import { AnimateNumber } from '../animate-number';

export interface CircularProgressBarProps {
  size?: number;
  progress?: number;
  labelValue?: string | number;
  labelStyle?: SerializedStyles;
  trackWidth?: number;
  trackColor?: string;
  indicatorWidth?: number;
  indicatorColor?: string;
  indicatorCap?: 'round' | 'butt' | 'square';
}

// Ref: https://blog.logrocket.com/build-svg-circular-progress-component-react-hooks/
export const CircularProgressBar = ({
  size = 150,
  progress = 0,
  trackWidth = 10,
  indicatorWidth = 10,
  trackColor = theme.colors.neutral10,
  indicatorColor = theme.colors.primary50,
  indicatorCap = 'round',
  labelValue,
  labelStyle,
}: CircularProgressBarProps) => {
  const center = size / 2,
    radius = center - (trackWidth > indicatorWidth ? trackWidth : indicatorWidth),
    dashArray = 2 * Math.PI * radius,
    dashOffset = dashArray * ((100 - progress) / 100);

  return (
    <AnimatePresence>
      <div css={{ position: 'relative', width: size, height: size }}>
        <svg css={{ transform: 'rotate(-90deg)', width: size, height: size }}>
          <circle cx={center} cy={center} fill='transparent' r={radius} stroke={trackColor} strokeWidth={trackWidth} />
          <motion.circle
            cx={center}
            cy={center}
            fill='transparent'
            r={radius}
            transition={{ duration: 1, ease: 'easeInOut' }}
            initial={{ strokeDashoffset: dashArray }}
            animate={{ strokeDashoffset: dashOffset }}
            stroke={indicatorColor}
            strokeWidth={indicatorWidth}
            strokeDasharray={dashArray}
            strokeLinecap={indicatorCap}
          />
        </svg>
        <div css={[textStyle, { color: indicatorColor }, labelStyle]}>
          {typeof labelValue === 'string' && labelValue}
          {typeof labelValue === 'number' && <AnimateNumber from={0} to={labelValue} />}
        </div>
      </div>
    </AnimatePresence>
  );
};

const textStyle = css({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  userSelect: 'none',
  fontWeight: theme.font.weight.bold,
  textAlign: 'center',
});
