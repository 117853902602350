import { LineKeyType_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { theme } from '@frontend/theme';

export const DEVICE_LAYOUT_WIDTH = '704px';
export const LINE_KEY_FONT_SIZE = theme.fontSize(20);
export const LAYOUT_HEIGHT_HEADER_FOOTER = theme.spacing(11);
export const LAYOUT_TOUCH_SCREEN_HEIGHT_HEADER_FOOTER = theme.spacing(6);

export const LineKeyCardTitle: Record<LineKeyType_Enum, string> = {
  UNSPECIFIED: 'Unspecified',
  DATA_ENDPOINT: 'Data Endpoint',
  LOCAL_CONTACT: 'Contact',
  OFFICE_EXTENSION: 'Office Extension',
  PARK_SLOT: 'Hold',
  PERSONAL_VOICEMAIL: 'Personal Voicemail',
  USER_EXTENSION: 'User Extension',
  VOICEMAIL_EXTENSION: 'Voicemail',
};

export const LineKeyCardDropdownLabel: Record<LineKeyType_Enum, string> = {
  UNSPECIFIED: 'Unspecified',
  DATA_ENDPOINT: 'Data Endpoint',
  LOCAL_CONTACT: 'Contact',
  OFFICE_EXTENSION: 'Extension Name',
  PARK_SLOT: 'Hold Slot',
  PERSONAL_VOICEMAIL: 'Voicemail Box',
  USER_EXTENSION: 'Device Name',
  VOICEMAIL_EXTENSION: 'Voicemail Box',
};
