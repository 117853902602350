import { usePersonInfoStore } from '../providers';

interface GetInsuranceEligibilityReportUrlParams {
  locationId: string;
  personId: string;
  sourceId: string;
  personPMSId: string;
}

const getInsuranceEligibilityReportUrl = ({
  locationId,
  personId,
  sourceId,
  personPMSId,
}: GetInsuranceEligibilityReportUrlParams) => {
  const baseUrl = window.location.origin;
  const userToken = window.localStorage.getItem('token');

  return `${baseUrl}/insurance-eligibility-report/?locationId=${locationId}&personId=${personId}&sourceId=${sourceId}&personPMSId=${personPMSId}&authToken=${userToken}`;
};

export const useOpenInsuranceReport = () => {
  const { personInfo, locationId } = usePersonInfoStore(['locationId', 'personInfo']);
  const personId = personInfo?.PersonID as string;
  const sourceId = personInfo?.SourceID;
  const personPMSId = personInfo?.PMID;

  const handleOpenInsuranceReport = () => {
    const url = getInsuranceEligibilityReportUrl({
      personId,
      locationId: locationId ?? '',
      sourceId: sourceId ?? '',
      personPMSId: personPMSId ?? '',
    });
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return { handleOpenInsuranceReport };
};
