import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { DataSourcesApi, DataSourcesTypes } from '@frontend/api-data-sources';
import { ServiceProvidersConstants } from '@frontend/api-service-providers';

const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

type MappedIntegrations = { [sourceId: string]: string };

type UseSyncAppBySourceReturnType = {
  access: DataSourcesTypes.AccessDataResponse;
  syncApps: MappedIntegrations;
  syncAppDataSources: DataSourcesTypes.DataSource[];
  isLoading: boolean;
};

const defaultAccessValue: DataSourcesTypes.AccessDataResponse = { ClientLocations: [], Datasources: [], ParentID: '' };

export const useSyncAppBySource = (locationId: string, parentId?: string): UseSyncAppBySourceReturnType => {
  const { data: accessData, isLoading: isAccessDataLoading } = useQuery({
    queryKey: ['accessData', locationId ?? ''],
    queryFn: async () => await DataSourcesApi.getAccessData(locationId ?? ''),
    enabled: !!locationId,
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
  });

  const { data: integrations, isLoading: isIntegrationsDataLoading } = useQuery({
    queryKey: ['integrations', parentId ?? '', locationId ?? ''],
    queryFn: async () => {
      const { SyncApps } = await DataSourcesApi.getAccessibleIntegrations((parentId || locationId) ?? '');
      return SyncApps;
    },
    enabled: !!locationId,
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 minutes
  });

  const syncAppMappedData = useMemo(() => {
    return integrations?.reduce(
      (prev, { SourceID, PracticeManagementSystem }) => ({
        ...prev,
        [SourceID]: PracticeManagementSystem,
      }),
      {}
    );
  }, [integrations]);

  return {
    access: accessData || defaultAccessValue,
    syncApps: syncAppMappedData || [],
    syncAppDataSources: integrations || [],
    isLoading: isIntegrationsDataLoading || isAccessDataLoading,
  };
};
