import { useCallback, useRef } from 'react';
import { EventType } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-call/v1/call_pops.pb';
import { CountUnreadVoicemailsResponse } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-records/v1/phone_records_api.pb';
import {
  VoicemailEventType,
  VoicemailUpdateEvent,
} from '@weave/schema-gen-ts/dist/shared/phone/v1/voicemail/message.pb';
import { QueryClient, useQueryClient } from 'react-query';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { NotificationQueries } from '@frontend/api-notifications';
import { PersonHelpers } from '@frontend/api-person';
import { PhoneCallsQueries } from '@frontend/api-phone-calls';
import { useIncomingCallNotification } from '@frontend/notifications';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { CallPopInterface, CallPopActionHandler, useCallPopStateSync, useSubscribeToPopAction } from '@frontend/pop';
import { allowedNotificationTypes } from '@frontend/settings-routing';
import TempoTracing from '@frontend/tempo-tracing';
import { sentry } from '@frontend/tracking';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';
import { useCallQueueMetricsStore } from './components/call-queue-stats/use-metrics-store';
import { useVoicemailUpdateStore } from './hooks/use-new-voicemail-store';
import { usePhoneScopeStore } from './hooks/use-phone-scope-store';

type Props = { enabled: boolean };
export const useWebsocketDeskPhoneEventSubscription = ({ enabled }: Props) => {
  const { create } = useIncomingCallNotification();
  const { notifications, addNotification } = useCallPopStateSync();
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  // This is meant to track the trace for an inbound call through the life of it (until answer, reject or hangup) even in between various WS events and user interactions
  const currentInboundTrace = useRef<string | undefined>(undefined);

  const { data: notificationSettings } = NotificationQueries.useNotificationSettingsQuery();
  const callPopNotificationId = allowedNotificationTypes.find(({ type }) => type === 'incoming-call')?.id;
  const autoShowCallerProfile =
    callPopNotificationId && !!notificationSettings?.find(({ id }) => id === callPopNotificationId)?.sendNotification;

  const handlerV2 = useCallback<GetWebsocketEventHandler<'PhoneSystemEventsV2'>>(
    (payload) => {
      const allNotifications = notifications;
      let traceId: string | undefined;
      let event = 'unknown';
      try {
        event = payload.params.event || 'unknown';
        traceId = payload.trace_id;
        if (!!traceId) {
          TempoTracing.continueTrace(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
            parentSpanName: TempoTracing.spanNameGenerators.websocketSpan(),
            endTraceOnTimeout: true,
          });
        }
        if (!enabled) {
          if (!!traceId && event === 'inbound_call') {
            TempoTracing.addEvent(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
              eventMessage: 'Call pop notifications for deskphone are disabled',
              eventType: EventType.EVENT_TYPE_INFO,
              timestamp: new Date().toISOString(),
            });
            TempoTracing.endSpan(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event));
          }
          return;
        }
        switch (event) {
          case 'inbound_call': {
            if (!!traceId) {
              currentInboundTrace.current = traceId;
              TempoTracing.addEvent(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
                eventMessage: 'Showing call pop',
                eventType: EventType.EVENT_TYPE_INFO,
                timestamp: new Date().toISOString(),
              });
            }
            addNotification({
              id: payload.params.channel_id,
              timestamp: Date.now(),
              payload: {
                type: 'default',
                autoShowCallerProfile: !!autoShowCallerProfile,
                callerContext: payload.params.caller_context,
                recipientLocationName: payload.params.recipient_location_name,
                headOfHousehold: payload.params.call_pop_head_of_household,
                contacts: payload.params.contact_matches
                  ? payload.params.contact_matches
                      .filter((contact) =>
                        demoSourceIds.length > 0 ? demoSourceIds.includes(contact.source_id) : true
                      )
                      .map((contact) => ({
                        personId: contact.person_id,
                        matchedLocationId: contact.weave_locations_matched[0]?.location_id ?? '',
                        callerName: PersonHelpers.getFullName({
                          FirstName: contact.first_name,
                          LastName: contact.last_name,
                        }),
                        callerNumber: payload.params.caller_id_number,
                        recipientLocationName: payload.params.recipient_location_name,
                        gender: contact.gender,
                        birthdate: contact.birthdate.seconds,
                        source: contact.data_source_name,
                        patientId: contact.patient_id,
                        householdId: contact.household_id,
                      }))
                  : [
                      {
                        personId: '',
                        patientId: '',
                        householdId: '',
                        // An empty caller name will show "Unknown Caller" in the call pop
                        callerName: '',
                        callerNumber: payload.params.caller_id_number,
                        recipientLocationName: payload.params.recipient_location_name,
                        gender: '',
                        birthdate: 0,
                        matchedLocationId: payload.params.recipient_location_id ?? '',
                        source: '',
                      },
                    ],
              },
            });
            return;
          }
          case 'answer_call': {
            if (autoShowCallerProfile) {
              const notification = allNotifications?.find((n) => n.id === payload.params.channel_id);
              if (notification) {
                if (!!traceId) {
                  TempoTracing.addEvent(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
                    eventMessage: `Call pop auto-opening caller profile on: ${event}`,
                    eventType: EventType.EVENT_TYPE_INFO,
                    timestamp: new Date().toISOString(),
                  });
                }
                CallPopInterface.inspect(notification);
              }
            } else {
              return;
            }
          }
          case 'outbound_call':
          case 'hangup_call':
            if (!!currentInboundTrace.current) {
              traceId = currentInboundTrace.current;
            }
            if (!!traceId) {
              TempoTracing.addEvent(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
                eventMessage: `Call pop dismissed with action: ${event}`,
                eventType: EventType.EVENT_TYPE_INFO,
                timestamp: new Date().toISOString(),
              });
            }
            // Remove the call pop for the call that just ended
            CallPopInterface.dismiss(payload.params.channel_id);

            create({
              id: payload.id,
              location: payload.params.recipient_location_name,
              timestamp: new Date().toDateString(),
              type: 'missed-call',
              payload: {
                callerName: payload.params.caller_id_name,
                callerNumber: payload.params.caller_id_number,
                recipientLocationName: payload.params.recipient_location_name,
              },
              state: {
                paused: true, //initially paused. timer will begin when call ends
                timeout: 0,
                status: 'unread',
              },
            } satisfies GetWeavePopNotificationByType<'missed-call'>);
            if (!!traceId) {
              TempoTracing.endSpan(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event));
              currentInboundTrace.current = undefined;
            }
            return;
          default: {
            const _exhaustive = event;
            return _exhaustive;
          }
        }
      } catch (err) {
        sentry.error({
          topic: 'notification',
          error: err,
          addContext: {
            name: 'notification',
            context: {
              errMessage: 'error during websocket processing in desktop phone, failed to handle',
            },
          },
        });
        console.error(err);
        if (!!traceId) {
          TempoTracing.addEvent(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
            eventMessage: `Call pop websocket event not handled in deskphone handler due to error: ${err}`,
            eventType: EventType.EVENT_TYPE_INFO,
            timestamp: new Date().toISOString(),
          });
          TempoTracing.endSpan(traceId, TempoTracing.spanNameGenerators.callPopDesktopSpan(event));
        }
        return;
      }
    },
    [notifications, addNotification, enabled]
  );

  const callPopActionHandler: CallPopActionHandler<'dismiss'> = useCallback(() => {
    if (!!currentInboundTrace.current) {
      TempoTracing.addEvent(
        currentInboundTrace.current,
        TempoTracing.spanNameGenerators.callPopDesktopSpan('inbound_call'),
        {
          eventMessage: `User dismissed call using deskphone call pop notification`,
          eventType: EventType.EVENT_TYPE_INFO,
        }
      );
      TempoTracing.endSpan(
        currentInboundTrace.current,
        TempoTracing.spanNameGenerators.callPopDesktopSpan('inbound_call')
      );
    }
  }, []);

  useSubscribeToPopAction('dismiss', callPopActionHandler);

  useWebsocketEventSubscription('PhoneSystemEventsV2', handlerV2);
};

export const useWebsocketCallQueueEventSubscription = () => {
  const { metrics, setMetrics } = useCallQueueMetricsStore();
  useWebsocketEventSubscription('PhoneCallQueueEvents', (payload) => {
    const event = payload.params;

    setMetrics({
      [event.queue_id]: {
        callCount: event.call_count,
        averageWaitTimeInSeconds: event.average_wait_time_in_seconds,
        waitTimesInSeconds: event.wait_times_in_seconds,
        name: metrics?.[event.queue_id]?.name ?? '',
        locationIds: metrics?.[event.queue_id]?.locationIds ?? [],
      },
    });
  });
};

export const useWebsocketVoicemailEventSubscription = () => {
  const queryClient = useQueryClient();
  const { selectedLocationIds } = usePhoneScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const { setVoicemailEvent } = useVoicemailUpdateStore();

  useWebsocketEventSubscription('VOICEMAIL', (payload) => {
    const event = payload.params;
    updateVoicemailUnreadCount(event, selectedLocationIds, phoneConfig?.sipProfileId ?? '', queryClient);

    if (window.location.href.includes('calls/voicemails')) {
      // if the user is on the voicemails page, update the voicemail data in the cache
      setVoicemailEvent(event);
    }
  });
};

// This function updates the unread voicemail count in the navbar
const updateVoicemailUnreadCount = (
  event: VoicemailUpdateEvent,
  selectedLocationIds: string[],
  sipProfileId: string,
  queryClient: QueryClient
) => {
  const voicemailUnReadCountPerMailbox = queryClient.getQueryData([
    selectedLocationIds,
    ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(sipProfileId),
  ]) as CountUnreadVoicemailsResponse;

  if (!voicemailUnReadCountPerMailbox?.countPerMailbox) {
    voicemailUnReadCountPerMailbox.countPerMailbox = {};
  }
  if (!voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId]) {
    // initialize mailbox unread vm count to 0, if it doesn't exist
    voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId] = 0;
  }

  switch (event.type) {
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_NEW:
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_UNREAD: {
      voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId] =
        voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId] + 1;
      break;
    }
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_READ: {
      const newCount = voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId] - 1;
      if (newCount < 0) {
        // if the count is invalid, don't update the count. Ideally this shouldn't
        // be happening but just in case
        break;
      }
      voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId] = newCount;

      break;
    }
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_DELETE: {
      if (event.voicemail?.markedRead) {
        // if already read voicemail is deleted, then don't update the count
        break;
      }
      const newCount = voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId] - 1;
      if (newCount < 0) {
        break;
      }
      voicemailUnReadCountPerMailbox.countPerMailbox[event.mailboxId] = newCount;
      break;
    }
    case VoicemailEventType.VOICEMAIL_EVENT_TYPE_UNSPECIFIED:
      console.error('unspecified voicemail event received');
      return;
  }

  // update the unread voicemail count. This updates the count in navbar
  queryClient.setQueryData(
    [selectedLocationIds, ...PhoneCallsQueries.queryKeys.unreadVoicemailCount(sipProfileId)],
    voicemailUnReadCountPerMailbox
  );
};
