import { useEffect, useState, useMemo, useRef, ReactNode } from 'react';
import { cloneDeep } from 'lodash-es';
import type { Node } from 'slate';
import { withHistory } from 'slate-history';
import { Slate } from 'slate-react';
import { usePreviousValue } from '../../hooks';
import { TemplateEditor } from './editor';
import { MessageTemplateEditor, useCreateEditor } from './message-template-utils';
import { TagContext, EditorValueContext, TemplateSettingsContext } from './message-template.context';
import { AddTagToTemplateFun } from './message-template.models';
import { parseTemplate } from './parse-template/parse-template';
import { TemplatePreview } from './preview';
import { serializePlainText, serializePlainTextValues } from './serialize-template';
import { TagList } from './tags/tag-list';
import { EditorChildrenType, NodeTagType, TagType } from './types';

type MessageTemplateProps = {
  editor?: MessageTemplateEditor;
  children: ReactNode;
  initialTemplate?: string;
  tags: TagType[];
  onChange?: (plainTextTemplate: string, plainTextValues: string) => void;
  draggableTags?: boolean;
  readOnly?: boolean;
  disableEmoji?: boolean;
  shortenUrlsTo?: string;
  isNewTag?: boolean;
  hasDynamicTags?: boolean;
  correspondingTags?: Record<string, TagType[]>;
  sharedTags?: TagType[];
  setSharedTags?: (tags: TagType[]) => void;
};

const DEFAULT_VALUE = [{ children: [{ text: '' }] }];

/**
 * For editing message templates with drag and drop tags.
 * @prop onChange - Receives plain text with tags and values of editor. Should be side-effect free.
 * @prop initialTemplate - Plain text message with template tags. The value passed as initialTemplate should never be the same one that's updated in the onChange event since Slate is getting updated.
 * @prop tags - an array of tags that can be used in the editor
 */
export const MessageTemplate = ({
  editor: editorProp,
  children,
  initialTemplate,
  tags,
  onChange,
  draggableTags = false,
  readOnly = false,
  disableEmoji = false,
  shortenUrlsTo,
  isNewTag = false,
  hasDynamicTags = false,
  correspondingTags,
  sharedTags,
  setSharedTags,
}: MessageTemplateProps) => {
  const addTagToTemplateListeners = useRef<AddTagToTemplateFun[]>([]);
  const [editorValue, setEditorValue] = useState<Node[]>(
    !!initialTemplate ? parseTemplate(initialTemplate, tags, isNewTag) : DEFAULT_VALUE
  );
  const dynamicTags = hasDynamicTags ? JSON.stringify(tags) : undefined;
  const watchedSharedTags = JSON.stringify(sharedTags);
  const hasRequiredReadOnlyTags = tags.some((tag) => tag.readOnly && !tag.optional);
  const requiredReadOnlyTags = ((editorValue?.[0]?.children as EditorChildrenType) || []).filter(
    (node) => (node.tag as NodeTagType)?.readOnly && !(node.tag as NodeTagType)?.optional
  );
  const hasNonRemovableTags = tags.some((tag) => tag.nonRemovable);

  useEffect(() => {
    setEditorValue(!!initialTemplate ? parseTemplate(initialTemplate, tags, isNewTag) : DEFAULT_VALUE);
  }, [initialTemplate, tags.map((tags) => tags.label).join(' ')]);

  const settingsProviderValue = useMemo(
    () => ({
      shortenUrlsTo,
      readOnly,
    }),
    [shortenUrlsTo, readOnly]
  );

  const tagProviderValue = useMemo(
    () => ({
      tags,
      addTagToTemplate: (e, tag) => addTagToTemplateListeners.current.forEach((fn) => fn(e, tag)),
      handleAddTagToTemplate: (fn: AddTagToTemplateFun) => addTagToTemplateListeners.current.push(fn),
      draggableTags,
      readOnly,
      isNewTag,
      editorValue,
      correspondingTags,
      sharedTags,
      setSharedTags,
    }),
    [draggableTags, readOnly, editorValue, dynamicTags, watchedSharedTags]
  );

  const editor = useCreateEditor(tags, disableEmoji, isNewTag);
  const editorWithHistory = useMemo(() => withHistory(editor), [editor]);

  const [prevPlainText, plainText] = usePreviousValue(serializePlainText(editorValue));

  const compareKeys = (key1: NodeTagType['key'], key2: NodeTagType['key']) => {
    const key1Arr = typeof key1 === 'string' ? [key1] : key1;
    const key2Arr = typeof key2 === 'string' ? [key2] : key2;

    return key1Arr.some((key) => key2Arr.includes(key));
  };

  const editorHasTags = (nodes: Node[], tags: TagType[]) => {
    return tags.every((tag) => {
      const allChildren = nodes.flatMap((node) => node.children as EditorChildrenType);
      return allChildren.some((node) => {
        if (node.type !== 'tag') return false;
        const nodeTag = node.tag as NodeTagType;
        return compareKeys(nodeTag.key, tag.key);
      });
    });
  };

  useEffect(() => {
    if (editorValue && typeof onChange === 'function' && plainText !== prevPlainText) {
      onChange(plainText!, serializePlainTextValues(editorValue));
    }
  }, [editorValue]);

  const manageEditorValueChange = (value: Node[]) => {
    const newValue = cloneDeep(value);
    if (hasRequiredReadOnlyTags) {
      const editorWithReadOnlyTags = requiredReadOnlyTags.filter((item) => {
        return ((value[0]!.children as EditorChildrenType) || []).find(
          (node) => node.type === 'tag' && (node.tag as NodeTagType).key === (item.tag as NodeTagType).key
        );
      });
      if (!editorWithReadOnlyTags.length) editorWithHistory.undo();
    }
    if (
      hasNonRemovableTags &&
      !editorHasTags(
        newValue,
        tags.filter((tag) => !!tag.nonRemovable)
      )
    ) {
      return;
    }
    setEditorValue(newValue);
  };

  return (
    <TemplateSettingsContext.Provider value={settingsProviderValue}>
      <TagContext.Provider value={tagProviderValue}>
        <EditorValueContext.Provider value={editorValue}>
          <Slate editor={editorProp ?? editor} value={editorValue} onChange={(value) => manageEditorValueChange(value)}>
            {children}
          </Slate>
        </EditorValueContext.Provider>
      </TagContext.Provider>
    </TemplateSettingsContext.Provider>
  );
};

MessageTemplate.Editor = TemplateEditor;
MessageTemplate.TagList = TagList;
MessageTemplate.Preview = TemplatePreview;
