import { useCallback, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { BannerNotification, Button, Tabs, Text, useModalControl } from '@frontend/design-system';
import { FeedbackButton } from '../components';
import {
  CallIntelDemoBanner,
  CallIntelFilters,
  CallIntelOverviewPanel,
  CallRecordsTable,
  ExploreLocationsTray,
  SummaryCard,
} from '../components/call-intelligence';
import BetaBadge from '../components/call-intelligence/beta-badge';
import { CallRecordingsBanner } from '../components/call-intelligence/call-recordings-banner';
import { CallIntelMockData } from '../components/call-intelligence/demo-data';
import {
  useCallIntelLocations,
  useCallIntelShallowStore,
  useCallTakeawayPanelShallowStore,
  useIsCallIntelDemoAccount,
  useUpdateDefaultCallIntelStore,
} from '../components/call-intelligence/hooks';
import { useLocationsMatch } from '../components/call-intelligence/hooks/use-locations-match';
import { ServiceQualityPanel } from '../components/call-intelligence/tab-panels/service-quality-panel';
import { featureFlags } from '../feature-flags';
import { useShowTrialInfoBanner } from '../hooks';
import { trackingIds } from '../tracking-ids';
import { callIntelStyles, filtersStyles, pageStyles, stickyTabsStyles } from './common-styles';

const dummyLocationNames = CallIntelMockData.dummyLocationNames();

export const CallIntelligenceAnalyticsPOC = () => {
  const { t } = useTranslation('analytics');
  const stickyTabsRef = useRef<HTMLDivElement | null>(null);
  const { modalProps, triggerProps } = useModalControl();
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const search = useSearch();
  const navigate = useNavigate();
  const isServiceQualityEnabled = useHasFeatureFlag(featureFlags.enableCallIntelServiceQuality);
  const showTrailInfoBanner = useShowTrialInfoBanner();

  const {
    activeTab,
    activeMultiView = 'summary',
    defaultFilters,
    filterHintText,
    filters,
    filtersToRestore,
    resetStore,
    setActiveTab,
    setActiveMultiView,
    setFilters,
    setFiltersToRestore,
    setInitialQueryParamsFilters,
    setSubView,
    setIsDemoAccount,
    setLocationIds,
  } = useCallIntelShallowStore(
    'activeTab',
    'activeMultiView',
    'defaultFilters',
    'filterHintText',
    'filters',
    'filtersToRestore',
    'resetStore',
    'setActiveTab',
    'setActiveMultiView',
    'setFilters',
    'setFiltersToRestore',
    'setLocationIds',
    'setInitialQueryParamsFilters',
    'setIsDemoAccount',
    'setSubView'
  );

  const { setEditedCallIds } = useCallTakeawayPanelShallowStore('setEditedCallIds');

  const [isLoadingAggregatedData, setIsLoadingAggregatedData] = useState<boolean>(false);

  const isDemoAccount = useIsCallIntelDemoAccount();
  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? dummyLocationNames : undefined,
  });

  useUpdateDefaultCallIntelStore();

  const areLocationsMatching = useLocationsMatch(locations);

  const getPageSubtitleText = () =>
    `${
      isMultiLocation && filters.locations?.length
        ? filters.locations.length === Object.keys(locations).length
          ? t('all locations, ')
          : filters.locations.length === 1
          ? t('1 location, ')
          : t('{{count}} locations, ', { count: filters.locations.length })
        : ''
    }${filterHintText}`;

  const handleTabChange = useCallback(
    (tab: string) => {
      setFiltersToRestore(filters);
      setActiveTab(tab as CallIntelligenceTypes.CallIntelTab);
      setEditedCallIds([]);
    },
    [filters]
  );

  const handleClickOnSummaryCard = useCallback(
    (card: SummaryCard, locationId?: string) => {
      let filtersMod = { ...filters };

      if (card === 'serviceQuality') {
        setActiveTab('serviceQuality');
        return;
      } else if (card === 'callsAnalyzed') {
        filtersMod = { ...defaultFilters };
        filtersMod.locations = locationId ? [locationId] : filters.locations;
        filtersMod.startDate = filters.startDate;
        filtersMod.endDate = filters.endDate;
      } else {
        filtersMod.schedulingOpportunity =
          CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED;

        if (locationId) {
          filtersMod.locations = [locationId];
        }

        if (card === 'unscheduled') {
          filtersMod.schedulingOutcomes = [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED];
        } else if (card === 'conversionRate') {
          filtersMod.schedulingOutcomes = [CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED];
        }
      }

      setFilters(filtersMod);
      setFiltersToRestore(filtersMod);
      setActiveTab('records');
    },
    [filters]
  );

  useEffect(() => {
    // Reset when location is switched
    const pickerLocationIds = Object.keys(locations);

    if (pickerLocationIds.length) {
      setLocationIds(pickerLocationIds);
    }

    if (pickerLocationIds.length && !areLocationsMatching) {
      resetStore();
    }
  }, [locations, areLocationsMatching]);

  useEffect(() => {
    if (search?.filters) {
      const queryFilters = search.filters;

      if (queryFilters) {
        try {
          const decodedFilters = atob(queryFilters as string);
          const appliedFilters = JSON.parse(decodedFilters);
          setActiveTab('records');
          setInitialQueryParamsFilters(appliedFilters);

          const { filters: _, ...newSearch } = search;

          navigate({ search: newSearch, replace: true });
        } catch (error) {
          console.error('Failed to process filters:', error);
        }
      }
    } else if (search['submit-feedback']) {
      setShowFeedbackModal(true);
      const { 'submit-feedback': _, ...newSearch } = search;
      navigate({ search: newSearch, replace: true });
    }
  }, [search, navigate]);

  useEffect(() => {
    // On main call intel dashboard we need to restore the filters to its original state when user navigates back from the sub view (drill down pages)
    if (filtersToRestore) {
      setFilters(filtersToRestore);
    } else if (
      (!filters.endDate || !filters.startDate || !filters.locations?.length) &&
      defaultFilters.startDate &&
      defaultFilters.endDate &&
      defaultFilters.locations?.length
    ) {
      // If user was auto redirected from sub view, we need to reset the filters so data can be loaded
      setFilters(defaultFilters);
    }
  }, [defaultFilters]);

  useEffect(() => {
    // Always reset sub view state
    setSubView({});

    // TODO :: This is temporary and need to be updated by allowing passing ref to the Page component
    const scrollableParent = stickyTabsRef.current?.parentElement?.parentElement?.parentElement;

    const handleScroll = () => {
      if (scrollableParent && stickyTabsRef.current) {
        const { top: stickyTop } = stickyTabsRef.current.getBoundingClientRect();
        const { top: pageTop } = scrollableParent.getBoundingClientRect();

        if (pageTop === stickyTop) {
          stickyTabsRef.current.classList.add('sticky-shadow');
        } else {
          stickyTabsRef.current.classList.remove('sticky-shadow');
        }
      }
    };

    scrollableParent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableParent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  return (
    <>
      <CallIntelDemoBanner />
      <CallRecordingsBanner />
      <Page
        action={
          <FeedbackButton
            productCode={Feature.CALL_INTELLIGENCE}
            showModal={showFeedbackModal}
            trackingId={trackingIds.callIntel.generalFeedbackMainPage}
          />
        }
        css={[styles.page, activeTab === 'records' ? pageStyles.tableFullHeight : undefined]}
        subtitle={t('Showing results for {{filtersInfo}}', { filtersInfo: getPageSubtitleText() })}
        title={t('Call Intelligence')}
      >
        {showTrailInfoBanner && (
          <BannerNotification
            css={css`
              margin-bottom: ${theme.spacing(4)};
            `}
            status='info'
            message={t(
              'We’re loading 14 days of data to give you the most valuable insights during your free trial. Your dashboard will be updated by the next business day.'
            )}
          />
        )}
        <Tabs controlledTab={activeTab} initialTab={activeTab} onChange={handleTabChange}>
          <div css={stickyTabsStyles} ref={stickyTabsRef}>
            <Tabs.Bar>
              <Tabs.Tab controls='overview-panel' id='overview' trackingId={trackingIds.callIntel.overviewTab}>
                {t('Overview')}
              </Tabs.Tab>
              {isServiceQualityEnabled && (
                <Tabs.Tab
                  controls='service-quality-panel'
                  id='serviceQuality'
                  trackingId={trackingIds.callIntel.serviceQualityTab}
                >
                  <div css={styles.tabContentWrapper}>
                    <Text>{t('Service Quality')}</Text>
                    {isServiceQualityEnabled && <BetaBadge />}
                  </div>
                </Tabs.Tab>
              )}
              <Tabs.Tab controls='records-panel' id='records' trackingId={trackingIds.callIntel.recordsTab}>
                {t('All Call Recordings')}
              </Tabs.Tab>
            </Tabs.Bar>
            {['overview', 'serviceQuality'].includes(activeTab) && (
              <div
                css={filtersStyles.mainWrapper}
                style={{ marginBottom: 0, marginTop: theme.spacing(3), gap: theme.spacing(2) }}
              >
                <CallIntelFilters
                  activeTab='overview'
                  hideFilters={['officeUsers']}
                  isDemoAccount={isDemoAccount}
                  isLoadingData={isLoadingAggregatedData}
                  onlyChipFilters
                />

                {isMultiLocation && activeTab === 'overview' && (
                  <div css={callIntelStyles.multiComparison}>
                    <Button
                      {...triggerProps}
                      size='large'
                      style={{ fontSize: theme.fontSize(14), textWrap: 'nowrap' }}
                      variant='secondary'
                    >
                      <span className='icon'>
                        <Icon color='light' name='locations' />
                      </span>
                      {t('Explore Location Data')}
                    </Button>
                    <ExploreLocationsTray {...modalProps} onChange={setActiveMultiView} value={activeMultiView} />
                  </div>
                )}
              </div>
            )}
          </div>

          <Tabs.Panel controller='overview' css={styles.chartsTabPanel} id='overview-panel'>
            {activeTab === 'overview' && (
              <CallIntelOverviewPanel
                onChangeLoadingState={setIsLoadingAggregatedData}
                onClickSummaryCard={handleClickOnSummaryCard}
              />
            )}
          </Tabs.Panel>

          <Tabs.Panel controller='serviceQuality' css={styles.chartsTabPanel} id='service-quality-panel'>
            {activeTab === 'serviceQuality' && <ServiceQualityPanel />}
          </Tabs.Panel>

          <Tabs.Panel controller='records' css={styles.recordsTablePanel} id='records-panel'>
            {activeTab === 'records' && <CallRecordsTable isDemoAccount={isDemoAccount} marginTop={0} />}
          </Tabs.Panel>
        </Tabs>
      </Page>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
    min-height: auto;
    height: auto;
  `,

  chartsTabPanel: css`
    padding: ${theme.spacing(1, 0)};
  `,

  recordsTablePanel: css`
    flex-grow: 1;
    padding-top: ${theme.spacing(1)};
  `,
  tabContentWrapper: css`
    display: flex;
    align-items: center;
    gap: ${theme.spacing(1)};
  `,
};
