import { memo, useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { TaskStatus } from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import {
  OnboardingEvent,
  OnboardingProgressStatus,
} from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { motion } from 'motion/react';
import { BundleHooks, BundleUtils } from '@frontend/api-bundle';
import {
  OnboardingModulesApi,
  OnboardingModulesQueries,
  OnboardingModulesTypes,
} from '@frontend/api-onboarding-modules';
import { getUser, hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useHasFeatureFlag } from '@frontend/shared';
import { sentry } from '@frontend/tracking';
import { useLocalStorage } from '@frontend/web-storage';
import { theme } from '@frontend/theme';
import { BannerNotification, Heading, useModalControl } from '@frontend/design-system';
import { IncompleteTasksIntroModal, NWXDashboardTaskList, ScheduleCallWithOnboarderModal } from '../components';
import {
  BundleWiseTask,
  NEW_SINGLE_SOFTWARE_ONLY_FLOW_FEATURE_FLAG,
  NWXDashboardTaskInfo,
  NWX_DASHBOARD_TASK_LIST,
} from '../constants';
import { incompleteTasksIntroModalLocalStorageHelper } from '../helpers';

const RunActionOnMount = ({ action }: { action: () => void | Promise<void> }) => {
  useEffect(() => {
    if (typeof action === 'function') {
      action();
    }
  }, []);
  return null;
};

const TASK_HIDE_STORAGE_KEY = 'nwx-dashboard-tasks-section-hide';

// This component will be used to display the tasks on the NWX dashboard
// for only single software only locations
export const NWXDashboardTasksSection = memo(() => {
  const { t } = useTranslation('onboarding-modules');
  const { navigate } = useSettingsNavigate();
  const userInfo = getUser();
  const userId = userInfo?.userID || '';
  const { selectedLocationIdsWithParents, accessibleLocationData } = useAppScopeStore();
  const selectedLocationId = selectedLocationIdsWithParents?.[0] ?? '';
  const selectedLocationSlug = accessibleLocationData?.[selectedLocationId]?.slug ?? '';
  const isAdminUser = hasSchemaACL(selectedLocationId, Permission.USER_WRITE);
  const isSelfActivationSSOLocationFFEnabled = useHasFeatureFlag(NEW_SINGLE_SOFTWARE_ONLY_FLOW_FEATURE_FLAG);
  const latestOnboardingProgressQuery =
    OnboardingModulesQueries.useGetLatestOnboardingProgressByLocationID(selectedLocationId);
  const isSelfActivationSSOLocation =
    !latestOnboardingProgressQuery.isLoading &&
    !!latestOnboardingProgressQuery.data &&
    latestOnboardingProgressQuery.data !== OnboardingProgressStatus.ONBOARDING_PROGRESS_STATUS_UNSPECIFIED;
  const scheduleCallModalControl = useModalControl();

  const [hideTaskData, setHideTaskData] = useLocalStorage<{
    [locationId: string]: { [taskId: string]: { hide: boolean; expiry?: string } };
  }>({
    key: TASK_HIDE_STORAGE_KEY,
    defaultValue: {},
    subscribeToChanges: true,
  });

  const tasksQuery = OnboardingModulesQueries.useGetModulesAndTasksByLocationId(selectedLocationId, {
    enabled: isSelfActivationSSOLocationFFEnabled && isSelfActivationSSOLocation,
    // refetch on mount every time to get the latest tasks
    refetchOnMount: true,
    staleTime: 0,
  });

  const { data: salesforceBundleList = [] } = BundleHooks.useMultiIsGbbSalesforceBundle({
    locationIds: [selectedLocationId],
  });

  const bundleTaskList = useMemo<NWXDashboardTaskInfo[]>(() => {
    return Array.from(
      new Set(
        salesforceBundleList.map(({ salesforceProductBundleEnum }) =>
          BundleUtils.getBundleTypeBySfEnum(salesforceProductBundleEnum)
        )
      )
    )
      .map((bundleType) => BundleWiseTask[bundleType] ?? [])
      .flat()
      .filter(({ id }) => {
        const config = hideTaskData?.[selectedLocationId]?.[id];
        return !(config?.hide && (!config?.expiry || new Date() < new Date(config.expiry)));
      });
  }, [hideTaskData, salesforceBundleList, selectedLocationId]);

  const { requiredTasks, incompleteTasks, hasIncompleteTasks, hasTasks, isAllRequiredTaskCompleted } = useMemo(() => {
    const taskStatusList = (tasksQuery.data ?? []).flatMap((module) =>
      module.tasks.map(({ id, status }) => ({ id, isCompleted: status === TaskStatus.COMPLETE }))
    );

    const allTasks = (isAdminUser ? NWX_DASHBOARD_TASK_LIST : []).reduce<NWXDashboardTaskInfo[]>((acc, task) => {
      const taskStatus = taskStatusList.find((taskStatus) => taskStatus.id === task.id);
      if (taskStatus) {
        acc.push({ ...task, isCompleted: taskStatus.isCompleted });
      }
      return acc;
    }, []);

    const hasTasks = !!allTasks.length;
    const incompleteTasks = allTasks.filter((task) => !task.isCompleted);
    const requiredTasks = allTasks.filter((task) => task.isRequiredTask);
    const hasIncompleteTasks = !!incompleteTasks.length;
    const isAllRequiredTaskCompleted = requiredTasks.every((task) => task.isCompleted);

    return { requiredTasks, incompleteTasks, hasTasks, hasIncompleteTasks, isAllRequiredTaskCompleted };
  }, [tasksQuery.data, isAdminUser]);

  // Clear the shown flag from local storage if all required tasks are completed
  const shouldClearIncompleteTasksIntroModalLocalStorage =
    isAdminUser &&
    hasTasks &&
    isAllRequiredTaskCompleted &&
    isSelfActivationSSOLocationFFEnabled &&
    isSelfActivationSSOLocation;
  useEffect(() => {
    if (shouldClearIncompleteTasksIntroModalLocalStorage) {
      incompleteTasksIntroModalLocalStorageHelper.clearShown(selectedLocationId);
    }
  }, [shouldClearIncompleteTasksIntroModalLocalStorage, selectedLocationId]);

  const handleStartTask = useCallback((taskInfo: NWXDashboardTaskInfo) => {
    // If this is the "Schedule a call with your onboarder" task, open the modal
    if (taskInfo.id === OnboardingModulesTypes.TaskIds.ScheduleCallWithOnboarder) {
      scheduleCallModalControl.openModal();
    } else if (taskInfo.externalUrl) {
      window.open(taskInfo.externalUrl, '_blank', 'noopener noreferrer');
    } else if (taskInfo.settingsRedirectConfig) {
      navigate(taskInfo.settingsRedirectConfig);
    } else {
      console.error('Redirect url not found for onboarding task', taskInfo.id);
      sentry.error({
        error: 'Redirect url not found for onboarding task',
        topic: 'onboarding',
        addContext: {
          name: 'task-url',
          context: { taskId: taskInfo.id },
        },
      });
    }
  }, []);

  const handleHideTask = useCallback(
    (taskInfo: NWXDashboardTaskInfo) => {
      let expiryTime = '';

      // Set expiry to start of next quarter for 1 on 1 training task
      if (taskInfo.id === OnboardingModulesTypes.TaskIds.Schedule1on1Training) {
        const currentDate = new Date();
        const quarterEndMonth = Math.floor(currentDate.getMonth() / 3) * 3 + 2;
        const quarterEndDate = new Date(currentDate.getFullYear(), quarterEndMonth + 1, 1);
        expiryTime = quarterEndDate.toISOString();
      }

      setHideTaskData({
        ...hideTaskData,
        [selectedLocationId]: {
          ...hideTaskData?.[selectedLocationId],
          [taskInfo.id]: { hide: true, expiry: expiryTime },
        },
      });
    },
    [hideTaskData, selectedLocationId]
  );

  const handleScheduleCallSuccess = useCallback(async () => {
    await OnboardingModulesApi.publishOnboardingEvent({
      locationId: selectedLocationId,
      slug: selectedLocationSlug,
      onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_ONBOARDER_CALL_SCHEDULED,
    });
    tasksQuery.refetch();
  }, []);

  const logFirstTimeAppLoadEvent = () => {
    OnboardingModulesApi.publishOnboardingEvent({
      locationId: selectedLocationId,
      slug: selectedLocationSlug,
      onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_FIRST_TIME_APP_LOAD,
    });
  };

  const shouldShowComponent =
    !!bundleTaskList.length ||
    (hasIncompleteTasks && isSelfActivationSSOLocationFFEnabled && isSelfActivationSSOLocation);

  if (!shouldShowComponent) {
    return null;
  }

  const combinedIncompleteTaskList = [...incompleteTasks, ...bundleTaskList];

  return (
    <>
      {!isAdminUser && hasIncompleteTasks && (
        <section css={[containerStyle, { margin: theme.spacing(0, 1) }]}>
          <BannerNotification
            status='warn'
            fullWidth
            message={t(
              'Weave setup must be completed for some features to work properly. Reach out to an admin to complete the setup process.'
            )}
          />
        </section>
      )}
      <motion.section initial={{ opacity: 0, y: -5 }} animate={{ opacity: 1, y: 0 }} css={containerStyle}>
        <header css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <Icon name='graduation-cap' />
          <Heading level={3} css={{ fontSize: theme.fontSize(16) }}>
            {hasIncompleteTasks ? t("Let's finish setting Weave up") : t('Get the most out of Weave!')}
          </Heading>
        </header>
        <NWXDashboardTaskList
          taskList={combinedIncompleteTaskList}
          isLoading={tasksQuery.isFetching}
          onTaskClick={handleStartTask}
          onTaskHide={handleHideTask}
        />
        <IncompleteTasksIntroModal
          taskList={requiredTasks}
          locationId={selectedLocationId}
          userId={userId}
          onTaskClick={handleStartTask}
        />
        <ScheduleCallWithOnboarderModal
          {...scheduleCallModalControl.modalProps}
          onSuccess={handleScheduleCallSuccess}
          locationSlug={selectedLocationSlug}
        />
        {hasTasks && <RunActionOnMount action={logFirstTimeAppLoadEvent} />}
      </motion.section>
    </>
  );
});

NWXDashboardTasksSection.displayName = 'NWXDashboardTasksSection';

const containerStyle = css({
  backgroundColor: theme.colors.neutral5,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4, 4, 0, 4),
  '> ul, > section': {
    maxWidth: 1125,
  },
});
