import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { BarChartAppearance, Chart } from '@frontend/charts';
import { theme } from '@frontend/theme';
import { URLs } from '../../../constants';
import { CompareLocationsButton } from '../../compare-locations-button';
import { InfoTipPopover } from '../../info-tip-popover';
import { useROIShallowStore } from '../hooks';
import { AttributonTypeWithPracticeValue } from '../hooks/shared-types';

interface ROIConversionChartProps {
  achievedValue?: number;
  appearance?: BarChartAppearance;
  attributeType?: AttributonTypeWithPracticeValue;
  colors: Record<string, string>;
  description: ReactNode;
  infoTip?: string;
  isLoading?: boolean;
  labels: Record<string, string>;
  title: string;
  totalValue?: number;
}

export const ROIConversionChart = ({
  achievedValue = 0,
  appearance = {},
  attributeType,
  colors,
  description,
  infoTip,
  isLoading,
  labels,
  title,
  totalValue = 0,
}: ROIConversionChartProps) => {
  const { filters } = useROIShallowStore('filters');
  const isMultiComparisonEnabled = !!attributeType && (filters?.locationIds?.length || 0) > 1;
  const navigate = useNavigate();

  return (
    <Chart
      colors={colors}
      isLoading={isLoading}
      labels={{
        ...labels,
        backfill: labels?.totalValue,
      }}
      onClick={
        isMultiComparisonEnabled
          ? () =>
              navigate({
                to: URLs.ROI_MISSED_CALL_TEXT_MULTI,
                search: {
                  attributeType,
                },
              })
          : undefined
      }
      style={{ marginBottom: 0 }}
    >
      <Chart.Header
        title={title}
        bottomElement={isMultiComparisonEnabled && <CompareLocationsButton />}
        infoTip={infoTip ? <InfoTipPopover>{infoTip}</InfoTipPopover> : null}
      />
      <Chart.HorizontalContainer marginBottom={0}>
        <div css={styles.grid}>{description}</div>
        <div css={styles.grid} style={{ flexDirection: 'column' }}>
          <Chart.BarChart
            appearance={appearance}
            data={{
              groups: [
                {
                  name: 'achievedValue',
                  values: {
                    achievedValue,
                  },
                },
                {
                  name: 'totalValue',
                  values: {
                    totalValue,
                  },
                },
              ],
            }}
          />
          <Chart.Legends style={{ marginTop: theme.spacing(2) }} />
        </div>
      </Chart.HorizontalContainer>
    </Chart>
  );
};

const styles = {
  grid: css`
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .description {
      max-width: 400px;
    }
  `,
};
