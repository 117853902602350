import { isObjectLike } from 'lodash-es';
import type { NumberRange } from '@frontend/types';

export type FailedErrorResponse = {
  data: Record<string, any> | string | undefined;
};

export class SchemaParamValidationError extends Error {
  cause: { code: string; method: string; status: number; message: string };
  constructor(message: string, cause: { code: string; method: string; status: number; message: string }) {
    super(message);
    this.name = 'SchemaParamValidationError';
    this.cause = cause ?? {
      code: 'SchemaParamValidationError',
      method: 'GET',
      status: 400,
      message: 'Invalid query parameter',
    };
  }
}
export class HttpError extends Error {
  response: Response & FailedErrorResponse;
  data: FailedErrorResponse['data'];
  status: Response['status'];
  statusText: Response['statusText'];
  cause: { code: string; url: string; status: number; message: string };

  constructor(
    response: Response & FailedErrorResponse,
    options?: { cause: { code: string; url: string; status: number; message: string } }
  ) {
    const { statusText, status } = response;
    super(statusText || String(status), options);

    this.name = 'HttpError';
    this.data = response.data;
    this.message = `${this.displayError()}`;
    this.status = response.status;
    this.statusText = response.statusText;
    this.response = response;
    this.cause = options?.cause || {
      code: 'HttpError',
      url: response.url,
      status: response.status,
      message: 'Error retrieving data',
    };
  }

  private displayError() {
    return typeof this.data === 'object' ? JSON.stringify(this.data) : this.data;
  }

  public toString() {
    const displayError = this.displayError();
    return `Http Error ${this.response.status} ${displayError}`;
  }
}

function assertNonNullish<T>(value: T, message: string): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw Error(message);
  }
}

async function callErrorMiddleware(response: Response, errorHandlers: ErrorMiddleware[]) {
  let finalResponse = response;
  for (const errorHandler of errorHandlers) {
    finalResponse = await errorHandler(finalResponse);
  }
  return finalResponse;
}

async function handleErrors(response: Response, errorHandlers: ErrorMiddleware[]) {
  await callErrorMiddleware(response, errorHandlers);
  /**
   * If none of the error middleware throw an error, we reject with an error here
   */
  const message = await response.text();
  let data;
  try {
    data = JSON.parse(message);
  } catch {
    data = message;
  }

  return Promise.reject(
    new HttpError(
      Object.assign(response, {
        data,
      })
    )
  );
}

type Percentage = NumberRange<1, 101>;
type OnDownloadProgress = (progress: Percentage) => void;
type OnDownloadStream = (progress: number) => void;
export type ErrorMiddleware = (response: Response) => Promise<Response>;

/**
 * The purpose of this function is to provide a means for monitoring the progress of a response download.
 * This capability can be leveraged for various purposes, such as displaying a progress bar to indicate
 * the download's progress to the end-user.
 * @param response The response whose body you want to listen to
 * @param onDownloadProgress A callback that will be called on every download progress event
 * @param onDownloadStream A callback that will be called on every download stream event
 */

async function handleDownload(
  response: Response,
  onDownloadProgress?: OnDownloadProgress,
  onDownloadStream?: OnDownloadStream
) {
  if (!onDownloadProgress && !onDownloadStream) return response;

  if (!response.body) {
    throw Error('ReadableStream not yet supported in this browser.');
  }

  const contentEncoding = response.headers.get('content-encoding');
  const contentLength = response.headers.get(contentEncoding ? 'x-file-size' : 'content-length');

  assertNonNullish(contentLength, 'Response size header unavailable');

  const total = parseInt(contentLength, 10);
  let loaded = 0;

  return new Response(
    new ReadableStream({
      start(controller) {
        assertNonNullish(response.body, 'HTTP response has no body or is null');
        const reader = response.body.getReader();

        read();
        function read() {
          reader
            .read()
            .then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              const currentByteLength = loaded + value.byteLength;
              const progress = Math.round((currentByteLength / total) * 100) as Percentage;
              if (Math.round((loaded / total) * 100) !== progress) {
                onDownloadProgress?.(progress);
              }
              loaded = currentByteLength;
              onDownloadStream?.(currentByteLength);
              controller.enqueue(value);
              read();
            })
            .catch((error) => {
              controller.error(error);
              console.error(error);
            });
        }
      },
    })
  );
}

async function handleReturn(response: Response, responseType?: ResponseType) {
  if (responseType === 'json') {
    /**
     * using JSON.parse allows an empty response body to be returned as ''
     */
    return response.text().then((res) => (res ? JSON.parse(res) : res));
  }

  if (responseType === 'blob') {
    return response.blob();
  }

  if (responseType === 'arrayBuffer') {
    return response.arrayBuffer();
  }

  if (responseType === 'text') {
    return response.text();
  }

  if (responseType === 'none') {
    return response;
  }
}

async function fetchData(
  responseType: ResponseType,
  request: Request,
  onDownloadProgress?: OnDownloadProgress,
  onDownloadStream?: OnDownloadStream,
  errorHandlers: ErrorMiddleware[] = []
) {
  const response = await fetch(request);

  if (!response.ok) {
    return handleErrors(response, errorHandlers);
  }

  return handleDownload(response, onDownloadProgress, onDownloadStream).then((response) =>
    handleReturn(response, responseType)
  );
}

const LOCATION_ID_HEADER = 'Location-Id';
const AUTHORIZATION_HEADER = 'Authorization';

export interface IHTTP {
  baseUrl?: string;
  headers?: Record<string, string>;
  children?: React.ReactNode;
}

type ResponseType = 'blob' | 'arrayBuffer' | 'json' | 'text' | 'none';

export interface Options {
  skipValidation?: boolean;
  headers?: RequestInit['headers'];
  responseType?: ResponseType;
  //... add options as needed ...//
  signal?: RequestInit['signal'];
  params?: Record<string, any>;
  onDownloadProgress?: OnDownloadProgress;
  onDownloadStream?: OnDownloadStream;
}

interface PollingOptions {
  /**
   * Time interval (in milliseconds) between each polling attempt.
   * @default 1000
   */
  interval?: number;

  /**
   * Maximum number of polling attempts.
   * @default 15
   */
  maxAttempts?: number;

  /**
   * Maximum number of retries after encountering an error.
   * @default 3
   */
  maxRetries?: number;

  /**
   * Multiplier to increase the interval between retries.
   * @default 1
   */
  backoffMultiplier?: number;
}

interface RequestParams {
  url: string;
  body?: BodyInit;
  options?: Options;
}

interface MakeRequestParams extends RequestParams {
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
}

export type APIResponse<T> = {
  data: T;
};

export function getResponseData<T>(response: APIResponse<T>) {
  return 'data' in response ? response.data : response;
}

type Builder<T, UsedKeys extends keyof T = never> = {
  addParam: <K extends keyof Omit<T, UsedKeys>>(key: K, value: T[K], condition?: boolean) => Builder<T, UsedKeys | K>;
  build: () => Pick<T, UsedKeys>;
};

export class HTTP {
  temporaryHeaders = [] as { id: string; headers: Headers }[];
  headers = new Headers();
  baseUrl = '';
  globalSkipValidation = false;
  middleware: Array<(request: Request) => Promise<Request>> = [];
  errorMiddleware: ErrorMiddleware[] = [];

  constructor({ baseUrl, headers }: IHTTP = {}) {
    if (headers) {
      this.headers = new Headers(headers);
    }

    if (baseUrl) {
      this.baseUrl = baseUrl;
    }
  }

  useMiddleware = (middleware: (request: Request) => Promise<Request>) => {
    this.middleware.push(middleware);
  };

  clearMiddleware = () => {
    this.middleware = [];
  };

  useErrorMiddleware = (middleware: ErrorMiddleware) => {
    this.errorMiddleware.unshift(middleware);
  };

  clearErrorMiddleware = () => {
    this.errorMiddleware = [];
  };

  validateHeaders = (options?: Options, requiredHeaders = [AUTHORIZATION_HEADER, LOCATION_ID_HEADER]) => {
    requiredHeaders.forEach((header) => {
      if (!this.headers.has(header) && !(options?.headers && new Headers(options.headers).has(header))) {
        throw new Error(`${header} not configured`);
      }
    });
  };

  private robustEncode(str: string) {
    const charMap = {
      '!': '%21',
      "'": '%27',
      '(': '%28',
      ')': '%29',
      '~': '%7E',
      '%20': '+',
      '%00': '\x00',
    };

    const replacer = (match: string) => {
      return charMap[match as keyof typeof charMap];
    };

    return encodeURIComponent(str).replace(/[!'()~]|%20|%00/g, replacer);
  }

  private generateQueryParams(params: Record<string, any>) {
    return Object.keys(params)
      .reduce<string[]>((acc, key) => {
        if (params[key] === undefined) {
          return [...acc];
        } else if (Array.isArray(params[key])) {
          return [...acc, ...params[key].map((item: any) => `${this.robustEncode(key)}=${this.robustEncode(item)}`)];
        } else {
          return [...acc, `${this.robustEncode(key)}=${this.robustEncode(params[key])}`];
        }
      }, [])
      .join('&');
  }

  makeRequest = async <T>({ url, method, body, options = {} }: MakeRequestParams): Promise<T> => {
    const { headers, skipValidation, onDownloadProgress, onDownloadStream, ...otherOptions } = options;
    if (!skipValidation && !this.globalSkipValidation) {
      this.validateHeaders(options);
    }

    const requestHeaders = new Headers(this.headers);
    if (headers) {
      if (Array.isArray(headers)) {
        headers.forEach(([key, val]) => {
          requestHeaders.set(key, val);
        });
      } else if (typeof headers === 'object') {
        Object.entries(headers).map(([name, value]) => requestHeaders.set(name, value));
      }
    }

    // all temporary headers are added sequentially, with latest taking precendence
    if (this.temporaryHeaders.length > 0) {
      this.temporaryHeaders.forEach(({ headers }) => {
        headers.forEach((value, key) => {
          requestHeaders.set(key, value);
        });
      });
    }

    let resolvedUrl = url.startsWith('http') ? url : `${this.baseUrl}/${url.startsWith('/') ? url.slice(1) : url}`;

    if (options?.params) {
      resolvedUrl += (url.indexOf('?') === -1 ? '?' : '&') + this.generateQueryParams(options?.params);
      delete options.params;
    }

    let request = new Request(resolvedUrl, {
      headers: requestHeaders,
      method,
      body,
      ...otherOptions,
    });
    for (const middlewareFunction of this.middleware) {
      request = await middlewareFunction(request);
    }
    return fetchData(
      options?.responseType || 'json',
      request,
      onDownloadProgress,
      onDownloadStream,
      this.errorMiddleware
    );
  };

  setBaseUrl = (url: string) => {
    this.baseUrl = url;
  };

  setLocationIdHeader = (id: string | undefined) => {
    if (id === null || id === undefined) {
      this.headers.delete(LOCATION_ID_HEADER);
    } else {
      this.headers.set(LOCATION_ID_HEADER, id);
    }
  };

  getLocationIdHeader = () => this.headers.get(LOCATION_ID_HEADER);

  setAuthorizationHeader = (token: string | undefined) => {
    if (token === null || token === undefined) {
      this.headers.delete(AUTHORIZATION_HEADER);
    } else {
      this.headers.set(AUTHORIZATION_HEADER, `Bearer ${token}`);
    }
  };

  setHeaders = (headers: Record<string, string | null | undefined>) => {
    Object.entries(headers).forEach(([key, value]) => {
      if (value === null || value === undefined) {
        this.headers.delete(key);
      } else {
        this.headers.set(key, value);
      }
    });
  };

  setGlobalSkipValidation = (globalSkipValidation: boolean) => {
    this.globalSkipValidation = globalSkipValidation;
  };

  setHeader = (key: string, value: string) => {
    this.headers.set(key, value);
  };

  deleteHeader = (key: string) => {
    this.headers.delete(key);
  };

  private isValidRequestBody(payload: unknown): payload is XMLHttpRequestBodyInit {
    if (!payload) return false;

    const validPayloadTypes = [ArrayBuffer, Blob, File, URLSearchParams, FormData];

    return (
      typeof payload === 'string' ||
      validPayloadTypes.some((payloadType) => {
        return payload instanceof payloadType;
      })
    );
  }

  paramBuilder<T>(): Builder<T> {
    const object: any = {};

    function addParam<K extends keyof T>(key: K, value: T[K], condition?: boolean): Builder<T, keyof T> {
      if (condition || condition === undefined) {
        object[key] = value;
      }
      return { addParam, build } as Builder<T, keyof T>;
    }

    function build(): Partial<T> {
      return object;
    }

    return { addParam, build };
  }

  get = <T>(url: string, options?: Options): Promise<T> => this.makeRequest<T>({ url, method: 'GET', options });

  publicGet = <T>(url: string, options?: Options): Promise<T> =>
    this.makeRequest<T>({ url, method: 'GET', options: { ...options, skipValidation: true } });

  publicPost = <T, U>(url: string, body: U, options?: Options): Promise<T> =>
    this.post<T, U>(url, body, { ...options, skipValidation: true });

  /**
   * This is a convenience method that extracts the `data` property from the API response
   */
  getData = <T>(url: string, options?: Options): Promise<T> =>
    this.makeRequest<APIResponse<T>>({ url, method: 'GET', options }).then((res) => res.data);

  publicGetData = <T>(url: string, options?: Options): Promise<T> => {
    const opts: Options = { ...options, skipValidation: true };
    return this.getData(url, opts);
  };

  post = <T, U = XMLHttpRequestBodyInit | Record<string, unknown>>(
    url: string,
    body?: U,
    options?: Options
  ): Promise<T> =>
    this.makeRequest<T>({
      url,
      method: 'POST',
      body: this.isValidRequestBody(body) ? body : JSON.stringify(body),
      options,
    });

  put = <T, U = XMLHttpRequestBodyInit | Record<string, unknown>>(
    url: string,
    body?: U,
    options?: Options
  ): Promise<T> =>
    this.makeRequest({
      url,
      body: this.isValidRequestBody(body) ? body : JSON.stringify(body),
      method: 'PUT',
      options,
    });

  patch = <T, U = XMLHttpRequestBodyInit | Record<string, unknown>>(
    url: string,
    body?: U,
    options?: Options
  ): Promise<T> =>
    this.makeRequest({
      url,
      body: this.isValidRequestBody(body) ? body : JSON.stringify(body),
      method: 'PATCH',
      options,
    });

  delete = <T, U = Record<string, unknown>>(url: string, body?: U, options?: Options): Promise<T> =>
    this.makeRequest({
      url,
      body: body && JSON.stringify(body),
      method: 'DELETE',
      options,
    });

  poll = <T>(
    url: string,
    validate: (response: T) => boolean,
    options?: Options,
    pollingOptions?: PollingOptions
  ): Promise<T> => {
    let attempts = 0;
    let retries = 0;
    let interval = pollingOptions?.interval ?? 1000;
    const maxAttempts = pollingOptions?.maxAttempts ?? 15;
    const maxRetries = pollingOptions?.maxRetries ?? 3;
    const backoffMultiplier = pollingOptions?.backoffMultiplier ?? 1;

    return new Promise((resolve, reject) => {
      const pollAgain = async () => {
        try {
          const response = await this.makeRequest<T>({ url, method: 'GET', options });
          attempts++;

          if (validate(response)) {
            resolve(response);
          }
          if (attempts >= maxAttempts) {
            reject(new Error('Max polling attempts exceeded'));
          }

          interval *= backoffMultiplier;
          setTimeout(pollAgain, interval);
        } catch (error) {
          retries++;
          if (retries > maxRetries) {
            reject(error);
          }

          interval *= backoffMultiplier;
          setTimeout(pollAgain, interval);
        }
      };
      pollAgain();
    });
  };

  isHttpError(error: unknown): error is HttpError {
    return isObjectLike(error) && error instanceof HttpError;
  }

  /**
   * ### ⚠️ Warning
   * Do not use this if onDownloadProgress or onDownloadStream is utilized,
   * as the thrown ```DOMException``` error will not propagate in Chrome. This will result
   * in a ***"TypeError: Failed to fetch"*** error.
   *
   * The proper method for determining if a request was cancelled is to examine the
   * "aborted" status of the signal provided to the request.
   */
  isAbortError(error: any): error is DOMException {
    if (error && error.name === 'AbortError') {
      return true;
    }
    return false;
  }
}
