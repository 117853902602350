import {
  ReviewsRequest,
  ReviewsResponse,
  Review,
} from '@weave/schema-gen-ts/dist/schemas/reviews/aggregator/v1/reviews_aggregator.pb';
import {
  CreateReviewCompletionRequest,
  CreateReviewCompletionResponse,
} from '@weave/schema-gen-ts/dist/schemas/wgpt/v1/wgpt_service.pb';
import { http, getResponseData, APIResponse } from '@frontend/fetch';
import {
  Mappings,
  Settings,
  AggregatedReviews,
  ReviewCommentPayload,
  SiteString,
  GetGoogleIntegrationStatusResponse,
} from './reviews-types';

/** @deprecated use the new ReviewsAggregatorV1 service from `api-reviews-aggregator` lib */
export const getMappings = () => http.getData<Mappings>('portal/v1/reviews/mappings');

export const getSettings = (locationId?: string) => {
  const url = locationId ? `reviews/v1/settings?locationId=${locationId}` : 'reviews/v1/settings';
  return http.get<Settings>(url);
};

export const updateSettings = (data: Settings) => http.put<Settings, Settings>('reviews/v1/settings', data);

export const getAggregatedReviewsInfinite = async () => ({
  rows: await http
    .get<APIResponse<AggregatedReviews>>('reviews/aggregated')
    .then((data) => getResponseData(data).reviews),
  nextOffset: 0 + 1,
});

export const getAggregatedReviews = () =>
  http.get<APIResponse<AggregatedReviews>>('reviews/aggregated').then(getResponseData);

export const createReviewComment = (payload: ReviewCommentPayload) => {
  const { locationId, ...data } = payload;
  const headers = locationId ? { 'Location-Id': locationId } : undefined;

  return http.post<AggregatedReviews>('reviews/replies', data, { headers });
};

export const updateComment = (payload: ReviewCommentPayload) => {
  const { locationId, ...data } = payload;
  const headers = locationId ? { 'Location-Id': locationId } : undefined;

  return http.put('reviews/replies', data, { headers });
};

export const deleteComment = (payload: ReviewCommentPayload) => {
  const { locationId, ...data } = payload;
  const headers = locationId ? { 'Location-Id': locationId } : undefined;

  return http.delete(`reviews/replies/${data.site}?reply_id=${data.id}`, data, { headers });
};

export const createReviewCompletion = (data: CreateReviewCompletionRequest) => {
  return http.post<CreateReviewCompletionResponse>(`/wgpt/v1/review/completion`, data);
};

export const updateReviewReadStatus = (payload: Omit<ReviewCommentPayload, 'comment'>) => {
  const { id, site, locationId } = payload;
  const headers = locationId ? { 'Location-Id': locationId } : undefined;

  return http.put(`/reviews/read/${site}?review_id=${id}`, undefined, { headers });
};

type ReviewDetails = {
  site: SiteString;
  id: string;
};

export const getReviewDetails = ({ site, id }: ReviewDetails) => {
  return http.get<Review>(`/reviews/${site}-reviews/v1/reviews/${id}`);
};

export const getAggregatorReviews = (params: ReviewsRequest) => {
  return http.get<ReviewsResponse>('reviews/aggregator/v1/list', { params });
};

export const getGoogleIntegrationStatus = (params: { locationId: string; isGoogle: boolean }) => {
  const paramsURL = params.isGoogle ? `google=true` : '';
  return http.get<GetGoogleIntegrationStatusResponse>(`reviews/integrationsstatus?${paramsURL}`);
};
