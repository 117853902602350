import { css } from '@emotion/react';
import { AnimatePresence, motion } from 'motion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { useStickyNoteStore } from '../providers';

export function ArchiveArea() {
  const { showArchive, isHoveredOverArchive } = useStickyNoteStore(['showArchive', 'isHoveredOverArchive']);
  const { t } = useTranslation('sticky-note-widget');
  return (
    <AnimatePresence>
      {showArchive && (
        <motion.div
          id='archive-icon-container'
          key='archive-icon'
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{
            opacity: 1,
            scale: isHoveredOverArchive ? 1.1 : 1,
          }}
          exit={{ opacity: 0, scale: 0.5 }}
          transition={{ type: 'spring', stiffness: 260, damping: 20 }}
          css={archiveAreaStyle(isHoveredOverArchive)}
        >
          <Icon name='archive' />
          <Text>{t('Archive')}</Text>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const archiveAreaStyle = (isHoveredOverArchive: boolean) => css`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  left: 0;
  bottom: 0;
  background: ${theme.colors.white};
  border-radius: ${theme.borderRadius.medium};
  color: ${isHoveredOverArchive ? theme.colors.critical50 : theme.colors.neutral70};
  padding: ${theme.spacing(1)};
  box-shadow: ${theme.shadows.light};
  z-index: ${theme.zIndex.high};
  cursor: pointer;
`;
