export const WIDTH_DIMS = {
  small: 360,
  smallXL: 400,
  smallXXL: 434,
  medium: 500,
  large: 600,
  xlarge: 700,
} as const;

export type WidthKey = keyof typeof WIDTH_DIMS;

export const LAYOUT_CONSTANTS = {
  NAV: {
    REGULAR: 284,
    SLIM: 120,
  },
  CONTENT: {
    MIN_WIDTH: 440,
  },
  ACTION_BAR: {
    WIDTH: 57,
  },
} as const;
