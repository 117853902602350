import { FormTemplatesTypes } from '../../../types';
import { BASE_ROUTE } from '../base';
import { RouterPayload } from '../route.types';
import { BASE_FORMS_TEMPLATES_ROUTE } from './form-templates.constants';

export interface FormTemplatesListRoutePayload {
  view?: FormTemplatesTypes.View;
  sort?: FormTemplatesTypes.SortBy;
  relativePath?: boolean;
}

export function getFormTemplatesListRoute(payload: FormTemplatesListRoutePayload = {}): RouterPayload {
  const { view = 'grid', sort = 'name--asc', relativePath = false } = payload;
  const path = relativePath ? BASE_FORMS_TEMPLATES_ROUTE.split(BASE_ROUTE)[1] : BASE_FORMS_TEMPLATES_ROUTE;
  const searchParams: Record<string, string> = {
    view,
    sort,
  };

  return {
    to: path,
    search: searchParams,
  };
}

export interface FormTemplateDetailsRoutePayload extends FormTemplatesListRoutePayload {
  locationId?: string;
  templateId?: string;
}

export function getFormTemplateDetailsRoute(payload: FormTemplateDetailsRoutePayload = {}): RouterPayload {
  const { templateId, locationId, ...rest } = payload;
  const { relativePath = false } = rest;
  const listRoute = getFormTemplatesListRoute(rest);
  const searchParams = { ...(listRoute.search || {}) };
  let path = relativePath ? BASE_FORMS_TEMPLATES_ROUTE.split(BASE_ROUTE)[1] : BASE_FORMS_TEMPLATES_ROUTE;

  if (templateId) {
    path = `${path}/${templateId}`;
  } else {
    path = `${path}/:id`;
  }

  if (locationId) {
    searchParams.locationId = locationId;
  }

  return {
    to: path,
    search: searchParams,
  };
}
