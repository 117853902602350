import { useCallback, useEffect, useMemo, useState } from 'react';
import { UseQueryResult, useQueries } from 'react-query';
import { PollWorkflowResponse, pollEventWorkflow } from '@frontend/api-payments';
import { useAlert } from '@frontend/design-system';
import { useMerchant } from './use-merchant';

export type UsePollWorkflowOptions = {
  workflowIds: string[];
  setWorkflowIds: (workflowIds: string[]) => void;
  refetch: () => Promise<unknown>;
  onComplete?: (workflowId?: string) => void;
  onFailure?: (workflowId?: string) => void;
  workflowName?: string;
  completedStatus?: string;
  failedStatus?: string;
  pollInterval?: number;
  failureMessage?: string;
};

const POLL_WORKFLOW_INTERVAL = 5000;

type WorkflowPollResults = Record<
  string,
  Omit<UseQueryResult<PollWorkflowResponse, any>, 'status'> & { status: PollWorkflowResponse['status'] | undefined }
>;

const ERROR_STATUS = 'query-failed';

export const usePollWorkflows = ({
  workflowIds,
  workflowName = 'payments',
  completedStatus = 'Completed',
  failedStatus = 'Failed',
  onComplete,
  onFailure,
  refetch,
  setWorkflowIds,
  pollInterval = POLL_WORKFLOW_INTERVAL,
  failureMessage,
}: UsePollWorkflowOptions) => {
  const { paymentsUrl } = useMerchant();
  const alert = useAlert();
  const [loading, setLoading] = useState<boolean>(false);

  const workflowPollQueryResults = useQueries(
    workflowIds.map((workflowId) => ({
      queryKey: [workflowName, workflowId],
      queryFn: () => pollEventWorkflow(paymentsUrl, workflowId),
      enabled: !!workflowId,
      staleTime: 0,
      refetchIntervalInBackground: true,
      refetchInterval: pollInterval,
    }))
  );

  const { workflowPollResults, isLoading, isRefetching, pollStatuses } = useMemo(
    () =>
      workflowPollQueryResults.reduce(
        (pollResults, queryResult, idx) => {
          const pollStatus = queryResult.isError ? ERROR_STATUS : queryResult.data?.status;
          pollResults.workflowPollResults[workflowIds[idx]] = { ...queryResult, status: pollStatus };
          pollResults.isLoading = pollResults.isLoading || queryResult.isLoading;
          pollResults.isRefetching = pollResults.isRefetching || queryResult.isRefetching;
          pollResults.pollStatuses.push(pollStatus);

          return pollResults;
        },
        {
          isLoading: false,
          isRefetching: false,
          workflowPollResults: {} as WorkflowPollResults,
          pollStatuses: [] as (string | undefined)[],
        }
      ),
    [workflowPollQueryResults]
  );

  const callRefetch = async () => {
    setLoading(true);
    await refetch();
    setLoading(false);
  };

  const handleStatusChange = (workflowId: string) => {
    const { status } = workflowPollResults[workflowId];

    let refetch = false;
    let failed = false;
    let completed = false;

    const hasWorkflowEnded = status === failedStatus || status === completedStatus;
    if (hasWorkflowEnded && workflowId) {
      refetch = true;
      setWorkflowIds(workflowIds.filter((id) => id !== workflowId));
      if (status === completedStatus) {
        onComplete?.(workflowId);
        completed = true;
      } else if (status === failedStatus) {
        onFailure?.(workflowId);
        failed = true;
      }
    } else if (status === ERROR_STATUS && workflowId) {
      refetch = true;
      setWorkflowIds(workflowIds.filter((id) => id !== workflowId));
    }
    return { refetch, failed, completed };
  };

  useEffect(() => {
    const workflowActions = pollStatuses.map((_, idx) => handleStatusChange(workflowIds[idx]));
    const refetchWorkflowStatus = workflowIds.some((_, idx) => workflowActions[idx].refetch);
    if (refetchWorkflowStatus) {
      callRefetch();
    }
    const showErrorMessage = workflowIds.some((_, idx) => workflowActions[idx].failed);
    if (showErrorMessage && failureMessage?.trim()) {
      alert.error(failureMessage);
    }
  }, [pollStatuses.join(',')]);

  useEffect(() => {
    if (workflowIds.length && (isLoading || isRefetching)) {
      setLoading(true);
    }
  }, [workflowIds.join(',')]);

  return {
    ...workflowPollQueryResults[0],
    refetch: callRefetch,
    loading,
    workflowPollResults,
  };
};

export type UsePollWorkflowsHookProps = Omit<UsePollWorkflowOptions, 'refetch' | 'setWorkflowIds' | 'workflowIds'> & {
  onPollingEnd: () => Promise<void> | void;
};

/**
 * Wraps `usePollWorkflows`. Does not require shallow store.
 * Receives `onPollingEnd` callback to be called when polling ends.
 *
 * Returns { startPolling } to be called with workflow Ids to start polling.
 */
export const usePollWorkflowsHook = ({ onPollingEnd, ...props }: UsePollWorkflowsHookProps) => {
  const [workflowIds, setWorkflowIds] = useState<string[]>([]);

  const { refetch, loading } = usePollWorkflows({
    ...props,
    workflowIds: workflowIds,
    setWorkflowIds,
    refetch: async () => {
      return onPollingEnd();
    },
  });

  const startPolling = useCallback((workflowIds: string[]) => {
    setWorkflowIds(workflowIds);
  }, []);

  return { loading, startPolling, refetch };
};
