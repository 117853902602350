import { ModalControlModalProps, Tray } from '@frontend/design-system';
import type { SelectedContactsType } from '../types';
import { ViewContactDetailsModal } from '.';

type Props = ModalControlModalProps & {
  selectedContacts: SelectedContactsType;
  contactDirectoryId?: string;
};
export const EditContactModal = ({ selectedContacts, contactDirectoryId, ...modalProps }: Props) => {
  return (
    <Tray width='smallXL' {...modalProps} disableCloseOnEscape>
      <ViewContactDetailsModal
        isEditModeEnabled
        {...modalProps}
        contactDirectoryId={contactDirectoryId}
        contact={selectedContacts.contacts[0]}
      />
    </Tray>
  );
};
