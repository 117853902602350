import { memo } from 'react';
import { css, CSSObject } from '@emotion/react';
import { UseFloatingReturn } from '@floating-ui/react';
import { Emoji } from 'emoji-picker-react';
import { humanizeDateTime } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Text, styles as dsStyles } from '@frontend/design-system';
import { DEFAULT_STATUS_EMOJI_UNIFIED } from '../../../constants';
import { useTeamChatStore } from '../../../providers';

interface Props {
  name: string;
  date: string;
  userID: string;
  css?: CSSObject;
  tooltipTriggerProps?: { ref: UseFloatingReturn['refs']['setReference'] };
}

export const Author = memo(({ date, name, userID, css, tooltipTriggerProps }: Props) => {
  const { userStatus } = useTeamChatStore(['userStatus']);

  const emojiText = userStatus[userID]?.status?.emoji;
  const hasStatusText = !!userStatus[userID]?.status?.text;

  return (
    <div css={[styles.wrapper, css]} {...(tooltipTriggerProps && { ...tooltipTriggerProps })}>
      <Text css={dsStyles.truncate} weight='bold'>
        {name}
      </Text>
      {!!hasStatusText &&
        (emojiText ? (
          <Text css={styles.statusEmoji}>{emojiText}</Text>
        ) : (
          <Emoji unified={DEFAULT_STATUS_EMOJI_UNIFIED} size={20} />
        ))}
      <Text as='time' color='subdued' size='medium'>
        {humanizeDateTime(date)}
      </Text>
    </div>
  );
});

Author.displayName = 'Author';

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    width: fit-content;
  `,
  statusEmoji: css({
    minWidth: '24px',
    fontSize: theme.fontSize(20),
  }),
};
