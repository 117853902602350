import { css } from '@emotion/react';
import { ChildLocation } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedButton, NakedUl, Stepper, Text } from '@frontend/design-system';
import { useBookingSiteStore } from '../../../stores';

interface Props {
  locations?: ChildLocation[];
}

export function LocationSelect({ locations = [] }: Props) {
  const { t } = useTranslation('bookingSite');
  const { selectedLocationId, setSelectedLocation } = useBookingSiteStore([
    'selectedLocationId',
    'setSelectedLocation',
  ]);

  const handleLocationSelect = (location: ChildLocation) => {
    setSelectedLocation(location.locationId ?? '', location.officeName ?? '', location.paymentLocationPortalSlug ?? '');
  };

  return (
    <Stepper.Content>
      <Text>
        {t(
          'Start by selecting your preferred location from the list below. Our friendly team is here to provide you with exceptional care at your convenience.'
        )}
      </Text>
      <Text color='light' size='small'>
        {t('Locations')}
      </Text>
      <NakedUl>
        {locations.map((location) => (
          <li key={location.locationId}>
            <NakedButton
              css={buttonStyle}
              className={selectedLocationId === location.locationId ? 'selected' : ''}
              onClick={() => handleLocationSelect(location)}
            >
              {location.officeName ?? ''}
            </NakedButton>
          </li>
        ))}
      </NakedUl>
    </Stepper.Content>
  );
}

const buttonStyle = css({
  border: `1px solid ${theme.colors.neutral20}`,
  borderRadius: theme.borderRadius.small,
  width: '100%',
  textAlign: 'left',
  padding: theme.spacing(2),
  margin: theme.spacing(1, 0),
  fontSize: theme.fontSize(20),
  fontWeight: theme.font.weight.bold,
  ':hover, :focus': {
    backgroundColor: theme.colors.neutral10,
  },
  '&.selected': {
    border: `2px solid ${theme.colors.primary50}`,
    padding: `calc(${theme.spacing(2)} - 1px)`,
  },
  '@media (max-width: 699px)': {
    fontSize: theme.fontSize(16),
  },
});
