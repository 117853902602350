import { memo, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useAlert, ContentLoader } from '@frontend/design-system';
import { MESSAGES_QUERY_LIMIT } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { getPreviousMessages, getUnreadIndex } from '../../utils';
import { NewConversation } from '../common';
import { MessageComposer } from '../composer';
import { ConversationList } from './conversation-list';
import { EmptyConversation } from './empty-conversation';

export const ConversationBody = memo(() => {
  const { activeConversation, isNewConversation, streamClient, setActiveConversation, currentUser, isTeamChatFocused } =
    useTeamChatStore([
      'activeConversation',
      'isNewConversation',
      'streamClient',
      'setActiveConversation',
      'currentUser',
      'isTeamChatFocused',
    ]);

  const messageUnreadIndex = useMemo(() => {
    return getUnreadIndex(
      activeConversation?.messages,
      activeConversation?.lastReadMessageId,
      activeConversation?.unreadCount
    );
  }, [activeConversation?.messages.length, activeConversation?.lastReadMessageId, activeConversation?.unreadCount]);

  const shouldFetchMessages = useRef<boolean>(
    !!activeConversation?.messages?.length && activeConversation.messages.length >= MESSAGES_QUERY_LIMIT
  );
  const [isFetching, setIsFetching] = useState(false);
  const [unreadIndex, setUnreadIndex] = useState(-1);
  const [shouldShowMessages, setShouldShowMessages] = useState<boolean>(
    !(messageUnreadIndex === -1 && !!activeConversation?.unreadCount)
  );
  const channelIdRef = useRef<string | undefined>(activeConversation?.channelId);

  const { t } = useTranslation('team-chat');
  const { error } = useAlert();

  // TODO: fetch previous messages if last read message
  const fetchPreviousMessages = async () => {
    try {
      if (activeConversation && streamClient?.user && currentUser && shouldFetchMessages.current) {
        setIsFetching(true);

        const { conversation: newConversation, hasMoreMessages } = await getPreviousMessages({
          channelId: activeConversation.channelId,
          client: streamClient,
          conversation: activeConversation,
          currentUserId: currentUser.userID,
        });
        setActiveConversation(newConversation);
        shouldFetchMessages.current = hasMoreMessages;
        setIsFetching(false);
      }
    } catch (err) {
      error('Failed to fetch old messages');
      setIsFetching(false);
    }
  };

  useEffect(() => {
    if (activeConversation?.messages) {
      if (messageUnreadIndex === -1 && !!activeConversation.unreadCount) {
        fetchPreviousMessages();
      } else {
        const hasLastReadMessageIndex = !!activeConversation.lastReadMessageId;
        const indexMismatch =
          messageUnreadIndex !== -1 && messageUnreadIndex !== activeConversation.messages.length - 1;
        const shouldSetIndex =
          unreadIndex === -1 || channelIdRef.current !== activeConversation.channelId || !isTeamChatFocused;
        if (shouldSetIndex && (indexMismatch || !hasLastReadMessageIndex)) {
          setUnreadIndex(messageUnreadIndex);
        }
        if (!shouldShowMessages) {
          setShouldShowMessages(true);
        }
        if (channelIdRef.current !== activeConversation.channelId) {
          channelIdRef.current = activeConversation.channelId;
        }
      }
    }
  }, [messageUnreadIndex, activeConversation?.unreadCount, activeConversation?.channelId, isTeamChatFocused]);

  return (
    <>
      {isNewConversation && <NewConversation />}
      {shouldShowMessages && activeConversation?.messages.length ? (
        <ConversationList
          messages={activeConversation.messages}
          unreadCount={activeConversation.unreadCount}
          fetchPreviousMessages={fetchPreviousMessages}
          isFetching={isFetching}
          channelId={activeConversation.channelId}
          streamClient={streamClient}
          unreadIndex={unreadIndex}
          isTeamChatFocused={isTeamChatFocused}
        />
      ) : (
        <EmptyConversation
          memberCount={activeConversation?.members.length || 0}
          conversationType={activeConversation?.type ?? 'DM'}
        />
      )}
      <MessageComposer />
      <ContentLoader show={!shouldShowMessages} message={t('Getting latest messages')} />
    </>
  );
});

ConversationBody.displayName = 'ConversationBody';
