import { UseQueryOptions, useQuery } from 'react-query';
import { useLocationDataShallowStore, useLocalizedQuery } from '@frontend/location-helpers';
import { SchemaFeatureFlags, SchemaIO } from '@frontend/schema';

type Get = SchemaIO<(typeof SchemaFeatureFlags)['FlagEnabled']>;
export const useFeatureFlagIsEnabledQuery = (name: string, opts?: UseQueryOptions<Get['output']>) => {
  const { locationId } = useLocationDataShallowStore('locationId');
  return useLocalizedQuery({
    queryKey: ['feature-flag', name],
    queryFn: () => SchemaFeatureFlags.FlagEnabled({ flagName: name, locationId: locationId }),
    ...opts,
  });
};

type MultiFeatureFlagIsEnabledRequest = {
  flagName: string;
  groupIds: string[];
};
export const useMultiFeatureFlagIsEnabledQuery = (
  { flagName, groupIds }: MultiFeatureFlagIsEnabledRequest,
  opts?: UseQueryOptions<Record<string, boolean>>
) =>
  useQuery({
    queryKey: ['feature-flags', flagName, ...groupIds],
    queryFn: async () => {
      const res: Record<string, boolean> = {};
      await Promise.all(
        groupIds.map(async (locationId) => {
          const { enabled } = await SchemaFeatureFlags.FlagEnabled(
            { flagName, locationId },
            { headers: { 'Location-Id': locationId }, skipValidation: true }
          );
          res[locationId] = !!enabled;
          return enabled;
        })
      );
      return res;
    },
    ...opts,
  });

export enum AggregationStrategy {
  ANY = 'any',
  ALL = 'all',
}

// Default global aggregation strategy for feature flags is ANY
// Use this map to update the default behavior for specific flags
const DEFAULT_AGGREGATION_STRATEGY: Record<string, AggregationStrategy> = {
  'team-chat-use-stream': AggregationStrategy.ALL,
};

type AggregateFeatureFlagRequest = {
  flagName: string;
  locationIds: string[] | undefined;
  defaultValue?: boolean;
  aggregationStrategy?: AggregationStrategy;
};

/**
 *  Helper hook to derive a single feature flag value across multiple locations according to an aggregation strategy.
 * @param flagName The name of the feature flag to query
 * @param locationIds The list of location IDs that are selected (typically obtained from app scope store)
 * @param defaultValue The default feature flag value to return if the query is loading or there are any failures
 * @param aggregationStrategy The aggregation strategy to use when aggregating the feature flag values across multiple locations
 **/
export const useAggregateFeatureFlagQuery = ({
  flagName,
  locationIds,
  defaultValue = false,
  aggregationStrategy = DEFAULT_AGGREGATION_STRATEGY[flagName] || AggregationStrategy.ANY,
}: AggregateFeatureFlagRequest) => {
  const {
    data: flagsByGroupId,
    isLoading,
    isError,
  } = useMultiFeatureFlagIsEnabledQuery(
    {
      flagName,
      groupIds: locationIds ?? [],
    },
    { enabled: !!locationIds?.filter(Boolean)?.length, retry: false }
  );

  const countOfEnabledFlags = Object.values(flagsByGroupId ?? {}).filter((flagValue) => flagValue).length;
  const countOfDisabledFlags = Object.values(flagsByGroupId ?? {}).filter((flagValue) => !flagValue).length;
  const aggregateValue = flagsByGroupId
    ? aggregationStrategy === AggregationStrategy.ANY
      ? countOfEnabledFlags > 0
      : countOfDisabledFlags === 0
    : defaultValue;
  const isMixed = countOfEnabledFlags > 0 && countOfDisabledFlags > 0;

  return {
    isError,
    isLoading,
    aggregateValue: isLoading ? defaultValue : aggregateValue,
    hasMixedValues: isMixed,
    flagsByGroupId: flagsByGroupId ?? {},
  };
};
