import { motion } from 'motion/react';

type Props = {
  size?: number;
};

export const EmptyAlertsGraphic = ({ size = 1000 }: Props) => (
  <motion.svg
    xmlns='http://www.w3.org/2000/svg'
    id='Layer_1'
    viewBox='0 0 366 359'
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    css={{
      height: size,
      width: size,
      margin: 'auto',
    }}
  >
    <path
      d='M181.06 358.33C270.806 358.33 343.56 285.576 343.56 195.83C343.56 106.084 270.806 33.33 181.06 33.33C91.3137 33.33 18.56 106.084 18.56 195.83C18.56 285.576 91.3137 358.33 181.06 358.33Z'
      fill='#EBF3FF'
    />
    <path
      d='M342.47 195.61C347.082 195.61 350.82 191.872 350.82 187.26C350.82 182.648 347.082 178.91 342.47 178.91C337.858 178.91 334.12 182.648 334.12 187.26C334.12 191.872 337.858 195.61 342.47 195.61Z'
      fill='#9EC5FF'
    />
    <path
      d='M121.77 14C125.636 14 128.77 10.866 128.77 7C128.77 3.13401 125.636 0 121.77 0C117.904 0 114.77 3.13401 114.77 7C114.77 10.866 117.904 14 121.77 14Z'
      fill='#9EC5FF'
    />
    <path
      d='M7 251.47C10.866 251.47 14 248.336 14 244.47C14 240.604 10.866 237.47 7 237.47C3.13401 237.47 0 240.604 0 244.47C0 248.336 3.13401 251.47 7 251.47Z'
      fill='#DCEBFE'
    />
    <path
      d='M353.06 95.07C359.964 95.07 365.56 89.4736 365.56 82.57C365.56 75.6664 359.964 70.07 353.06 70.07C346.156 70.07 340.56 75.6664 340.56 82.57C340.56 89.4736 346.156 95.07 353.06 95.07Z'
      fill='#DCEBFE'
    />
    <path
      d='M288.1 111.12H75.81C68.4922 111.12 62.56 117.052 62.56 124.37V285.24C62.56 292.558 68.4922 298.49 75.81 298.49H288.1C295.418 298.49 301.35 292.558 301.35 285.24V124.37C301.35 117.052 295.418 111.12 288.1 111.12Z'
      fill='#9EC5FF'
    />
    <path
      d='M288.11 115.12C293.21 115.12 297.35 119.27 297.35 124.36V285.24C297.35 290.34 293.2 294.48 288.11 294.48H75.8C70.7 294.48 66.56 290.33 66.56 285.24V124.37C66.56 119.27 70.71 115.13 75.8 115.13H288.1M288.11 107.12H75.8C66.28 107.12 58.56 114.84 58.56 124.36V285.24C58.56 294.76 66.28 302.48 75.8 302.48H288.1C297.62 302.48 305.34 294.76 305.34 285.24V124.37C305.34 114.85 297.62 107.13 288.1 107.13L288.11 107.12Z'
      fill='#6BA6FF'
    />
    <path
      d='M322.08 280.29H219.18C217.69 280.29 216.38 281.26 215.94 282.69L214.21 288.38C213.82 289.65 212.65 290.52 211.33 290.52H150.8C149.47 290.52 148.3 289.65 147.92 288.38L146.19 282.69C145.76 281.26 144.44 280.29 142.95 280.29H40.05C35.72 280.29 32.21 283.8 32.21 288.13V299.08C32.21 303.41 35.72 306.92 40.05 306.92H322.07C326.4 306.92 329.91 303.41 329.91 299.08V288.13C329.91 283.8 326.4 280.29 322.07 280.29H322.08Z'
      fill='#6BA6FF'
    />
    <path
      d='M249.95 69.66H112.19C101.144 69.66 92.19 78.6143 92.19 89.66V222.24C92.19 233.286 101.144 242.24 112.19 242.24H249.95C260.996 242.24 269.95 233.286 269.95 222.24V89.66C269.95 78.6143 260.996 69.66 249.95 69.66Z'
      fill='white'
    />
    <path
      d='M112.19 69.66H249.95C260.99 69.66 269.95 78.62 269.95 89.66V97.94H92.19V89.66C92.19 78.62 101.15 69.66 112.19 69.66Z'
      fill='#6BA6FF'
    />
    <path d='M225.73 63.75V76.27' stroke='#9EC5FF' strokeWidth='12' strokeMiterlimit='3' strokeLinecap='round' />
    <path d='M136.4 63.75V76.27' stroke='#9EC5FF' strokeWidth='12' strokeMiterlimit='3' strokeLinecap='round' />
    <path
      d='M133.98 158.75H121.86C119.408 158.75 117.42 160.738 117.42 163.19V175.31C117.42 177.762 119.408 179.75 121.86 179.75H133.98C136.432 179.75 138.42 177.762 138.42 175.31V163.19C138.42 160.738 136.432 158.75 133.98 158.75Z'
      fill='#DCEBFE'
    />
    <path
      d='M169.41 158.75H157.29C154.838 158.75 152.85 160.738 152.85 163.19V175.31C152.85 177.762 154.838 179.75 157.29 179.75H169.41C171.862 179.75 173.85 177.762 173.85 175.31V163.19C173.85 160.738 171.862 158.75 169.41 158.75Z'
      fill='#DCEBFE'
    />
    <path
      d='M204.84 158.75H192.72C190.268 158.75 188.28 160.738 188.28 163.19V175.31C188.28 177.762 190.268 179.75 192.72 179.75H204.84C207.292 179.75 209.28 177.762 209.28 175.31V163.19C209.28 160.738 207.292 158.75 204.84 158.75Z'
      fill='#DCEBFE'
    />
    <path
      d='M133.98 197.5H121.86C119.408 197.5 117.42 199.488 117.42 201.94V214.06C117.42 216.512 119.408 218.5 121.86 218.5H133.98C136.432 218.5 138.42 216.512 138.42 214.06V201.94C138.42 199.488 136.432 197.5 133.98 197.5Z'
      fill='#DCEBFE'
    />
    <path
      d='M169.41 197.5H157.29C154.838 197.5 152.85 199.488 152.85 201.94V214.06C152.85 216.512 154.838 218.5 157.29 218.5H169.41C171.862 218.5 173.85 216.512 173.85 214.06V201.94C173.85 199.488 171.862 197.5 169.41 197.5Z'
      fill='#DCEBFE'
    />
    <path
      d='M204.84 197.5H192.72C190.268 197.5 188.28 199.488 188.28 201.94V214.06C188.28 216.512 190.268 218.5 192.72 218.5H204.84C207.292 218.5 209.28 216.512 209.28 214.06V201.94C209.28 199.488 207.292 197.5 204.84 197.5Z'
      fill='#002F75'
    />
    <path
      d='M169.41 120.27H157.29C154.838 120.27 152.85 122.258 152.85 124.71V136.83C152.85 139.282 154.838 141.27 157.29 141.27H169.41C171.862 141.27 173.85 139.282 173.85 136.83V124.71C173.85 122.258 171.862 120.27 169.41 120.27Z'
      fill='#DCEBFE'
    />
    <path
      d='M204.84 120.27H192.72C190.268 120.27 188.28 122.258 188.28 124.71V136.83C188.28 139.282 190.268 141.27 192.72 141.27H204.84C207.292 141.27 209.28 139.282 209.28 136.83V124.71C209.28 122.258 207.292 120.27 204.84 120.27Z'
      fill='#DCEBFE'
    />
    <path
      d='M240.26 120.27H228.14C225.688 120.27 223.7 122.258 223.7 124.71V136.83C223.7 139.282 225.688 141.27 228.14 141.27H240.26C242.712 141.27 244.7 139.282 244.7 136.83V124.71C244.7 122.258 242.712 120.27 240.26 120.27Z'
      fill='#DCEBFE'
    />
    <path
      d='M245.64 214.27C267.422 214.27 285.08 196.612 285.08 174.83C285.08 153.048 267.422 135.39 245.64 135.39C223.858 135.39 206.2 153.048 206.2 174.83C206.2 196.612 223.858 214.27 245.64 214.27Z'
      fill='#FFC700'
    />
    <path
      d='M225.2 206.01L204.51 206.32C201.91 206.36 200.21 203.6 201.41 201.29L211 182.95L225.2 206V206.01Z'
      fill='#FFC700'
    />
    <path
      d='M244.38 158.54H246.9C254.87 158.54 261.33 165.01 261.33 172.97V181.77H229.94V172.97C229.94 165 236.41 158.54 244.37 158.54H244.38Z'
      fill='white'
    />
    <path
      d='M261 179.63H230.29C228.727 179.63 227.46 180.895 227.46 182.455C227.46 184.015 228.727 185.28 230.29 185.28H261C262.563 185.28 263.83 184.015 263.83 182.455C263.83 180.895 262.563 179.63 261 179.63Z'
      fill='white'
    />
    <path
      d='M245.64 195.25C249.64 195.25 252.98 192.4 253.73 188.63H237.54C238.3 192.41 241.63 195.25 245.63 195.25H245.64Z'
      fill='white'
    />
    <path
      d='M245.36 155.2C246.81 155.2 247.98 156.38 247.98 157.82V160.05H242.74V157.82C242.74 156.37 243.92 155.2 245.36 155.2Z'
      fill='white'
    />
    <path
      d='M233.18 173.03C232.95 172.8 232.83 172.48 232.86 172.14C233.06 170.22 233.68 168.41 234.69 166.75C235.55 165.34 236.65 164.13 237.96 163.16C238.45 162.8 239.13 162.9 239.5 163.38C239.86 163.87 239.76 164.56 239.28 164.92C238.19 165.73 237.28 166.73 236.57 167.9C235.73 169.28 235.22 170.78 235.05 172.37C234.99 172.97 234.45 173.41 233.84 173.35C233.58 173.32 233.35 173.21 233.18 173.03Z'
      fill='#FFC700'
    />
  </motion.svg>
);
