import { i18next } from '@frontend/i18n';

export const exportChartToPdfProps = (locationIds: string[], locations: Record<string, string>) => {
  const locationNames = locationIds.map((id) => locations[id]) ?? [];

  return {
    allowExportToPdf: true,
    pdfDetails: [
      {
        label:
          locationNames.length > 1
            ? i18next.t('Locations', { ns: 'analytics' })
            : i18next.t('Location', { ns: 'analytics' }),
        value: locationNames.join(', '),
      },
    ],
  };
};
