import { GlobalSearchMain } from './global-search-main';
import { GlobalSearchSection, GlobalSearchContent, GlobalSearchFilters } from './molecules';
import { GlobalSearchEmptyState } from './molecules/global-search-empty-state';
import { GlobalSearchProvider } from './provider';

type GlobalSearchProps = {
  children: React.ReactNode;
  className?: string;
};

export const GlobalSearch = ({ children, className }: GlobalSearchProps) => {
  return (
    <GlobalSearchProvider>
      <GlobalSearchMain className={className}>{children}</GlobalSearchMain>
    </GlobalSearchProvider>
  );
};

GlobalSearch.Filters = GlobalSearchFilters;
GlobalSearch.Content = GlobalSearchContent;
GlobalSearch.Section = GlobalSearchSection;
GlobalSearch.EmptyState = GlobalSearchEmptyState;
