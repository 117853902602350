import { useState, useRef, useMemo, useContext, createContext, useCallback, ReactNode } from 'react';
import {
  useInteractions,
  useFloatingNodeId,
  useFloating,
  autoUpdate,
  offset,
  useClick,
  useRole,
  useDismiss,
  useListNavigation,
  FloatingNode,
  FloatingPortal,
  FloatingFocusManager,
  FloatingList,
  useListItem,
  flip,
} from '@floating-ui/react';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { AnimatePresence, motion } from 'motion/react';
import { i18next, useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Chip, NakedUl, NakedButton, DateRangeField, useFormField, Button } from '@frontend/design-system';
import { TimeRangeOption, TimeRangeChangeValue } from './time-range-types';

dayjs.extend(utc);
dayjs.extend(timezone);

type SelectProps = {
  activeIndex: number | null;
  selectedIndex: number | null;
  getItemProps: ReturnType<typeof useInteractions>['getItemProps'];
  handleSelect: (index: number | null) => void;
};

export const SelectContext = createContext({} as SelectProps);
const MENU_WIDTH = 350;

export const TimeRangeFilter = ({
  options = defaultTimeRanges,
  onChange,
  className,
}: {
  options?: TimeRangeOption[];
  onChange?: (value: TimeRangeChangeValue) => void;
  className?: string;
}) => {
  const { t } = useTranslation('base');
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const labelsRef = useRef<Array<string | null>>([]);
  const listItemsRef = useRef<Array<HTMLLIElement | null>>([]);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(() => {
    const defaultOptionIndex = options.findIndex((opt) => opt.isDefault);
    return defaultOptionIndex !== -1 ? defaultOptionIndex : null;
  });
  const [selectedLabel, setSelectedLabel] = useState<string | null>(
    selectedIndex !== null ? options[selectedIndex].label : null
  );
  const [showCustom, setShowCustom] = useState(false);

  const nodeId = useFloatingNodeId();
  const dateRangeField = useFormField({ type: 'dateRange', value: [], required: true });
  const isDateRangeValid =
    dateRangeField.value.length === 2 && dateRangeField.value[0] !== '' && dateRangeField.value[1] !== '';

  const { refs, context, floatingStyles } = useFloating<HTMLInputElement>({
    nodeId,
    whileElementsMounted: autoUpdate,
    open: isOpen,
    onOpenChange: (nextOpen) => {
      setIsOpen(nextOpen);
      if (!nextOpen) {
        setActiveIndex(null);

        const selectedOption = selectedIndex !== null ? options[selectedIndex] : null;

        /**
         * If the selected option is custom and the date range is not valid, reset the selected option to the default one upon closing the menu.
         */
        if (selectedOption?.definition === 'custom' && !isDateRangeValid) {
          const defaultOptionIndex = options.findIndex((option) => option.isDefault);
          setSelectedLabel(defaultOptionIndex !== -1 ? options[defaultOptionIndex].label : null);
          setSelectedIndex(defaultOptionIndex !== -1 ? defaultOptionIndex : null);
        }

        if (selectedOption?.definition !== 'custom') {
          setShowCustom(false);
        }
      }
    },
    middleware: [offset(3), flip()],
    placement: 'bottom-start',
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
    useClick(context),
    useRole(context, { role: 'listbox' }),
    useDismiss(context),
    useListNavigation(context, {
      activeIndex,
      listRef: listItemsRef,
      loop: true,
      onNavigate: setActiveIndex,
      openOnArrowKeyDown: true,
      selectedIndex,
    }),
  ]);

  const handleSelect = useCallback(
    (index: number | null) => {
      if (index !== null) {
        const option = options[index];
        /**
         * Do not apply custom definition until the user clicks on the apply button.
         */
        if (option.definition !== 'custom') {
          setShowCustom(false);
          setSelectedIndex(index);
          setSelectedLabel(labelsRef.current[index]);
          const [startDate, endDate] = option.getDateRange();
          onChange?.({
            label: options[index].label,
            definition: options[index].definition,
            startDate,
            endDate,
          });
        } else {
          /**
           * If the user selects the custom option, show the custom date range input.
           */
          setShowCustom(true);
        }
      }
    },
    [options, onChange, dateRangeField.value]
  );

  const selectContext = useMemo(
    () => ({
      activeIndex,
      selectedIndex,
      getItemProps,
      handleSelect,
    }),
    [activeIndex, getItemProps, selectedIndex, handleSelect]
  );

  const isDefaultSelected = selectedIndex !== null && options[selectedIndex].isDefault;

  return (
    <>
      <Chip.DropdownFilter
        {...getReferenceProps()}
        leftElement={<Icon name='clock-small' size={16} />}
        ref={refs.setReference}
        isOpen={isOpen}
        css={{
          maxWidth: 300,
          backgroundColor: isDefaultSelected ? 'inherit' : theme.colors.primary50,
          color: isDefaultSelected ? 'inherit' : theme.colors.white,
          border: isDefaultSelected ? '' : 'none',
        }}
        className={className}
      >
        {selectedLabel ?? 'Time Range'}
      </Chip.DropdownFilter>
      <FloatingNode id={nodeId}>
        <SelectContext.Provider value={selectContext}>
          <AnimatePresence>
            {isOpen && (
              <FloatingPortal>
                <FloatingFocusManager context={context} initialFocus={0}>
                  <motion.div
                    style={{
                      zIndex: 100000,
                      ...floatingStyles,
                      width: MENU_WIDTH,
                    }}
                    ref={refs.setFloating}
                  >
                    <motion.div
                      {...getFloatingProps()}
                      initial='closed'
                      animate={isOpen ? 'open' : 'closed'}
                      variants={{
                        open: { opacity: 1, transform: 'translateY(8px)' },
                        closed: { opacity: 0, transform: 'translateY(0)' },
                      }}
                      exit='closed'
                      transition={{ duration: 0.2, ease: 'easeInOut' }}
                      style={{
                        boxShadow: theme.shadows.heavy,
                        borderRadius: theme.borderRadius.medium,
                        backgroundColor: theme.colors.white,
                        zIndex: theme.zIndex.popover,
                        overflow: 'hidden',

                        display: 'grid',
                        gridTemplateRows: 'minmax(0, 1fr) auto',
                      }}
                    >
                      <div>
                        <NakedUl
                          css={{
                            padding: theme.spacing(1, 0),
                          }}
                        >
                          <FloatingList elementsRef={listItemsRef} labelsRef={labelsRef}>
                            {options.map(({ label, definition }) => (
                              <Option key={definition}>{label}</Option>
                            ))}
                          </FloatingList>
                        </NakedUl>
                      </div>
                      <AnimatePresence>
                        {showCustom && (
                          <motion.div
                            initial='closed'
                            animate='open'
                            variants={{
                              open: { height: 'auto' },
                              closed: { height: 0 },
                            }}
                            exit='closed'
                            transition={{ duration: 0.2, ease: 'easeInOut' }}
                          >
                            <div
                              style={{
                                padding: theme.spacing(2),
                                borderTop: `1px solid ${theme.colors.neutral10}`,
                                width: MENU_WIDTH,
                                display: 'grid',
                                gap: theme.spacing(2),
                              }}
                            >
                              <DateRangeField {...dateRangeField} name='date' label='' />
                              <Button
                                disabled={!isDateRangeValid}
                                onClick={() => {
                                  const [startDate, endDate] = dateRangeField.value;
                                  onChange?.({
                                    label: `Custom: ${startDate} - ${endDate}`,
                                    definition: 'custom',
                                    startDate: dayjs(startDate).toISOString(),
                                    endDate: dayjs(endDate).endOf('day').toISOString(),
                                  });
                                  setSelectedIndex(options.findIndex((opt) => opt.definition === 'custom'));
                                  setSelectedLabel(`Custom: ${startDate} - ${endDate}`);
                                }}
                              >
                                {t('Apply')}
                              </Button>
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                    </motion.div>
                  </motion.div>
                </FloatingFocusManager>
              </FloatingPortal>
            )}
          </AnimatePresence>
        </SelectContext.Provider>
      </FloatingNode>
    </>
  );
};

const Option = ({ children }: { children: ReactNode }) => {
  const { activeIndex, selectedIndex, getItemProps, handleSelect } = useContext(SelectContext);
  const { ref, index } = useListItem();

  const isActive = activeIndex === index;
  const isSelected = selectedIndex === index;
  return (
    <NakedButton
      role='option'
      tabIndex={isActive ? 0 : -1}
      ref={ref}
      css={{
        textAlign: 'left',
        minHeight: 40,
        display: 'grid',
        alignItems: 'center',
        padding: theme.spacing(1, 2),
        backgroundColor: isActive ? theme.colors.neutral5 : 'transparent',
        outline: 'none',
        width: '100%',
        gridTemplateColumns: '1fr auto',
        gap: theme.spacing(2),

        ':hover': { backgroundColor: theme.colors.neutral5 },
      }}
      {...getItemProps({
        onClick: () => {
          handleSelect(index);
        },
      })}
    >
      {children}
      {isSelected && <Icon name='check' color='primary' />}
    </NakedButton>
  );
};

export const defaultTimeRanges: TimeRangeOption[] = [
  {
    isDefault: true,
    definition: 'td',
    label: i18next.t('Today', { ns: 'base' }),
    getDateRange: () => [dayjs().startOf('day').toISOString(), dayjs().toISOString()],
  },
  {
    definition: 'td-1',
    label: i18next.t('Yesterday', { ns: 'base' }),
    getDateRange: () => [
      dayjs().startOf('day').subtract(1, 'day').toISOString(),
      dayjs().endOf('day').subtract(1, 'day').toISOString(),
    ],
  },
  {
    definition: 'td-7',
    label: i18next.t('Last 7 Days', { ns: 'base' }),
    getDateRange: () => [dayjs().startOf('day').subtract(7, 'day').toISOString(), dayjs().endOf('day').toISOString()],
  },
  {
    definition: 'td-30',
    label: i18next.t('Last 30 Days', { ns: 'base' }),
    getDateRange: () => [dayjs().startOf('day').subtract(30, 'day').toISOString(), dayjs().endOf('day').toISOString()],
  },
  {
    definition: 'tw-x',
    label: i18next.t('This Week', { ns: 'base' }),
    getDateRange: () => [dayjs().startOf('week').toISOString(), dayjs().toISOString()],
  },
  {
    definition: 'tm-x',
    label: i18next.t('This Month', { ns: 'base' }),
    getDateRange: () => [dayjs().startOf('month').toISOString(), dayjs().toISOString()],
  },
  {
    definition: 'custom',
    label: i18next.t('Custom', { ns: 'base' }),
    getDateRange: () => ['custom', 'custom'],
  },
];
