import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  width: fit-content;
`;

export const getButtonStyle = (pmsOptionsLength: number) => css`
  background-color: ${theme.colors.white};
  margin-bottom: ${theme.spacing(2)};
  cursor: ${pmsOptionsLength === 1 ? 'default' : 'pointer'};

  &:hover {
    ${pmsOptionsLength === 1
      ? 'background-color: initial;' // No hover effect if pmsOptions length is 1
      : `background-color: ${theme.colors.neutral10};`}
  }
`;

export const textStyleForButton = css`
  margin-left: '4px';
  font-weight: 100;
`;
