import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { useIsIntegratedOffice } from '../../../hooks';

export const useHasMCTRoiFeature = () => {
  const { isIntegratedOffice } = useIsIntegratedOffice();
  const { flags } = useCustomizationFlagShallowStore('flags');
  const isCFEnabled = flags.missedcalltext?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;

  return {
    isMCTRoiEligibleLocation: !!isIntegratedOffice && !!isCFEnabled,
  };
};
