import React, { useEffect } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { CheckboxField, Modal, ModalControlModalProps, Text, useFormField } from '@frontend/design-system';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
import { featureFlags } from '../../../feature-flags';
import { generateTrackingId } from '../../../tracking';
import { useCallTakeawayPanelShallowStore } from '../hooks';

type UnsavedChangesModalProps = {
  modalProps: ModalControlModalProps;
  onClose: () => void;
  onDiscard: () => void;
  onSave: () => void;
};

const UnsavedChangesModal: React.FC<UnsavedChangesModalProps> = ({ modalProps, onClose, onDiscard, onSave }) => {
  const { t } = useTranslation('analytics');
  const isVariantB = useHasFeatureFlag(featureFlags.editAiDesignVariant);

  const { isDemoAccount, setUnsavedEditChangesConfig, unsavedEditChangesConfig } = useCallTakeawayPanelShallowStore(
    'isDemoAccount',
    'setUnsavedEditChangesConfig',
    'unsavedEditChangesConfig'
  );

  const fieldProps = useFormField({
    type: 'checkbox',
    value: false,
  });

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.CALL_INTEL_UNSAVED_CHANGES_MODAL_PREF, String(fieldProps.value));
    setUnsavedEditChangesConfig({ ...unsavedEditChangesConfig, dontAskAgain: fieldProps.value });
  }, [fieldProps.value]);

  return (
    <Modal {...modalProps} maxWidth={400} css={styles.modalWrapper} onClose={onClose} className='unsaved-changes-modal'>
      <Modal.Header onClose={onClose}>{t('Unsaved Changes')}</Modal.Header>

      <Modal.Body css={styles.body}>
        <div css={styles.bodyContent}>
          <Text>{t('Changes to the Call Takeaways have not been saved.')}</Text>
          <Text>{t('Would you like to discard or save changes before you leave?')}</Text>
          <CheckboxField
            {...fieldProps}
            name='dontAskAgain'
            label={t('Don’t ask me again')}
            trackingId={generateTrackingId({
              component: 'unsaved-changes-modal',
              context: 'dont-ask-again-checkbox-click',
              isDemoAccount,
              abTestGroup: isVariantB ? 'design-B' : 'design-A',
            })}
          />
        </div>
        <Modal.Actions
          primaryLabel={t('Save Changes')}
          primaryTrackingId={generateTrackingId({
            component: 'unsaved-changes-modal',
            context: 'save-changes-btn-click',
            isDemoAccount,
            abTestGroup: isVariantB ? 'design-B' : 'design-A',
          })}
          secondaryLabel={t('Discard')}
          secondaryTrackingId={generateTrackingId({
            component: 'unsaved-changes-modal',
            context: 'discard-btn-click',
            isDemoAccount,
            abTestGroup: isVariantB ? 'design-B' : 'design-A',
          })}
          onPrimaryClick={onSave}
          onSecondaryClick={onDiscard}
        />
      </Modal.Body>
    </Modal>
  );
};

const styles = {
  modalWrapper: css`
    width: 100%;

    @media screen and (min-width: 648px) {
      width: 100%;
    }
  `,
  body: css`
    gap: ${theme.spacing(4)};

    footer {
      padding: ${theme.spacing(0)};
    }

    footer button:first-child {
      color: ${theme.colors.critical50};
    }
  `,
  bodyContent: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(4)};
  `,
};

export default UnsavedChangesModal;
