import type { Tag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import { useQueryClient, useMutation, InfiniteData } from 'react-query';
import { VoicemailApi } from '@frontend/api-voicemails';
import { useAppScopeStore } from '@frontend/scope';
import type { VoicemailQueryData } from '../components/all-calls/types';

// Add the tag to a voicemail before even the request is made
// assuming it will succeed. If it fails, we will revert the change
const optimisticAddTag = ({
  oldData,
  tag,
  voicemailId,
}: {
  oldData?: InfiniteData<VoicemailQueryData>;
  tag: Tag;
  voicemailId: string;
}) => {
  if (oldData) {
    return {
      pageParams: oldData.pageParams,
      pages: oldData.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((row) => {
            if (row.voicemailId === voicemailId) {
              return {
                ...row,
                tags: [...row.tags, tag],
              };
            }
            return row;
          }),
        };
      }),
    };
  }

  return undefined;
};

const optimisticRemoveTag = ({
  oldData,
  tag,
  voicemailId,
}: {
  oldData?: InfiniteData<VoicemailQueryData>;
  tag: Tag;
  voicemailId: string;
}) => {
  if (oldData) {
    return {
      pageParams: oldData.pageParams,
      pages: oldData.pages.map((page) => {
        return {
          ...page,
          data: page.data.map((row) => {
            if (row.voicemailId === voicemailId) {
              return {
                ...row,
                tags: row.tags.filter((t) => t.id !== tag.id),
              };
            }
            return row;
          }),
        };
      }),
    };
  }

  return undefined;
};

export const useVoicemailTagMutation = ({
  queryKey,
  voicemailId,
  onSuccess,
  onFailure,
}: {
  queryKey: string[];
  voicemailId: string;
  onSuccess?: (args: { tag: Tag; op: 'remove' | 'add' }) => void;
  onFailure?: (args: { tag: Tag; op: 'remove' | 'add' }) => void;
}) => {
  const { selectedLocationIds } = useAppScopeStore();

  const qc = useQueryClient();

  const { mutate: addTag } = useMutation({
    mutationFn: async (tag: Tag) => {
      return VoicemailApi.tagVoicemail({ voicemailMessageId: voicemailId, tagIds: [tag.id] });
    },
    onMutate: async (tag: Tag) => {
      const oldData = qc.getQueryData<InfiniteData<VoicemailQueryData> | undefined>([selectedLocationIds, ...queryKey]);
      qc.setQueryData<InfiniteData<VoicemailQueryData> | undefined>([selectedLocationIds, ...queryKey], (oldData) => {
        return optimisticAddTag({ oldData, tag, voicemailId });
      });
      return { oldData };
    },
    onError: (_err, tag, context) => {
      if (context?.oldData) {
        // if the request fails, revert the change with oldData without new tag
        qc.setQueryData<InfiniteData<VoicemailQueryData> | undefined>(
          [selectedLocationIds, ...queryKey],
          context.oldData
        );
      }

      onFailure?.({ tag, op: 'add' });
    },
    onSuccess: (_, tag) => {
      onSuccess?.({ tag, op: 'add' });
    },
    //We could invalidate when the request has settled, but this will cause the table to re-render the skeleton loaders
    // onSettled: () => {
    //   qc.invalidateQueries([selectedLocationIds, ...queryKey]);
    // },
  });

  const { mutate: removeTag } = useMutation({
    mutationFn: async (tag: Tag) => {
      return VoicemailApi.untagVoicemail({ voicemailMessageId: voicemailId, tagIds: [tag.id] });
    },
    onMutate: async (tag: Tag) => {
      const oldData = qc.getQueryData<InfiniteData<VoicemailQueryData> | undefined>([selectedLocationIds, ...queryKey]);
      qc.setQueryData<InfiniteData<VoicemailQueryData> | undefined>([selectedLocationIds, ...queryKey], (oldData) => {
        return optimisticRemoveTag({ oldData, tag, voicemailId });
      });
      return { oldData };
    },
    onError: (_err, tag, context) => {
      if (context?.oldData) {
        qc.setQueryData<InfiniteData<VoicemailQueryData> | undefined>(
          [selectedLocationIds, ...queryKey],
          context.oldData
        );
      }
      onFailure?.({ tag, op: 'remove' });
    },
    onSuccess: (_, tag) => {
      onSuccess?.({ tag, op: 'remove' });
    },
    //We could invalidate when the request has settled, but this will cause the table to re-render the skeleton loaders
    // onSettled: () => {
    //   qc.invalidateQueries([selectedLocationIds, ...queryKey]);
    // },
  });

  return { addTag, removeTag };
};
