import { ListIO } from '../types';
import { useCallRouteV1Queries } from './use-call-route-v1-queries';
import { UseCallRouteV1QueryEndpointArgs, useCallRouteV1Query } from './use-call-route-v1-query';

/**
 * A hook that returns a query for the `List` endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListQuery = <E = unknown, D = ListIO['output']>(args: UseCallRouteV1QueryEndpointArgs<'List', E, D>) =>
  useCallRouteV1Query({
    endpointName: 'List',
    ...args,
  });

/**
 * A hook that returns an array of queries for the `List` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListQueries = <E = unknown, D = ListIO['output']>(
  argsArr: UseCallRouteV1QueryEndpointArgs<'List', E, D>[]
) =>
  useCallRouteV1Queries(
    argsArr.map((args) => ({
      endpointName: 'List',
      ...args,
    }))
  );
