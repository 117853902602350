import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { SwitchChipGroup } from '@frontend/design-system';
import { quickFilterStyles } from '../../../styles';

export type QuickFiltersProps = {
  selectedValue?: QuickFilterOptions;
  onChange: (selected: QuickFilterOptions) => void;
};

export type QuickFilterOptions = 'allInvoices' | 'paid' | 'refunded' | 'unpaid';

const DEFAULT_SELECTED_VALUE = 'allInvoices';

export const QuickFilters = ({ selectedValue = DEFAULT_SELECTED_VALUE, onChange }: QuickFiltersProps) => {
  const { t } = useTranslation('payments');

  const selectedValues = useMemo(() => [selectedValue], [selectedValue]);

  const buttonsConfig = [
    {
      value: 'allInvoices',
      label: t('All Invoices'),
    },
    {
      value: 'paid',
      label: t('Paid'),
    },
    {
      value: 'refunded',
      label: t('Refunded'),
    },
    {
      value: 'unpaid',
      label: t('Outstanding'),
    },
  ];

  const handleOptionChange = (vs: string[] | ((prevState: string[]) => string[])) => {
    if (typeof vs === 'function') {
      vs = vs([]);
    }

    if (Array.isArray(vs)) {
      if (vs.length) {
        onChange(vs[0] as QuickFilterOptions);
      } else {
        onChange(DEFAULT_SELECTED_VALUE);
      }
    }
  };

  return (
    <SwitchChipGroup css={quickFilterStyles.buttonContainer} value={selectedValues} setValue={handleOptionChange}>
      {buttonsConfig.map(({ label, value }) => {
        return (
          <SwitchChipGroup.Option trackingId={`pay-portal-invoices-quickfilters-${value}`} key={value} value={value}>
            {label}
          </SwitchChipGroup.Option>
        );
      })}
    </SwitchChipGroup>
  );
};
