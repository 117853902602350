import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { useTeamChatStore } from '../.././../providers';
import { StreamUser } from '../../../types';
import { getFullName, createChannel } from '../../../utils';

export const useUserInfo = (user: StreamUser, setOpen: (value: boolean) => void) => {
  const { userStatus, streamClient, setStatusModalOpen, currentUser, conversations, setActiveConversation } =
    useTeamChatStore([
      'userStatus',
      'streamClient',
      'setStatusModalOpen',
      'currentUser',
      'conversations',
      'setActiveConversation',
      'isTrayOpen',
    ]);
  const { selectedOrgId: orgId } = useAppScopeStore();
  const [isLoading, setIsLoading] = useState(false);
  const { error } = useAlert();
  const { t } = useTranslation('team-chat');

  const currentUserStatus = userStatus[user.userID];
  const name = getFullName(user);
  const canEdit = currentUser?.userID === user.userID;
  const hasStatus = !!currentUserStatus?.status?.text;

  const onClickStatus = async () => {
    if (currentUserStatus?.status?.emoji || currentUserStatus?.status?.text) {
      // clear the status
      try {
        if (streamClient) {
          await streamClient?.upsertUser({
            id: user.userID,
            name: name,
            currentUserStatus: {
              statusText: null,
              statusDuration: null,
              statusExpiration: null,
              emoji: null,
            },
            ...(currentUserStatus?.presence && { weavePresence: currentUserStatus.presence }),
          });
        }
      } catch (err) {
        error('Unable to clear status at the moment, please try again later');
      }
    } else {
      setOpen(false);
      setStatusModalOpen(true);
    }
  };

  const sendMessage = async () => {
    try {
      setIsLoading(true);
      const foundConversation = conversations.dm.find(
        (conversation) => conversation.members.length === 1 && conversation.members[0].userID === user.userID
      );
      if (foundConversation) {
        // set this is as active conversation
        setActiveConversation(foundConversation);
        setIsLoading(false);
      } else {
        // create a new conversation, with this user
        // set this as active conversation
        if (streamClient) {
          const newConversation = await createChannel({
            streamClient,
            orgId,
            members: [user],
            name: '',
            isDm: true,
          });
          setActiveConversation(newConversation);
          setIsLoading(false);
        }
      }
    } catch (e) {
      error(t('Unable to send message at the moment, please try again later'));
    }
  };

  return {
    name,
    canEdit,
    hasStatus,
    onClickStatus,
    sendMessage: !canEdit ? sendMessage : undefined,
    isLoading,
    userStatus: currentUserStatus,
  };
};
