import { memo, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { AnalyticsTrackingIds } from '../..';
import { useAnalyticsOrgLocations, useHasAclAccess } from '../../hooks';
import { appendCurrentTimeZone, formatters } from '../../utils';
import { weaveStatusColors, weaveStatusLabels } from '../appointment/constants';
import { useFetchAggregatedAppointmentsData, useIsAppointmentDemoAccount } from '../appointment/hooks';
import { DemoChip } from '../demo-chip';
import { Insights } from './insights';

type Props = {
  filters: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  onLoading: (isLoading: boolean) => void;
};

export const AppointmentAnalyticsOnDashboard = memo(({ filters, onLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const { current } = useLocation();
  const hasAclAccess = useHasAclAccess([Permission.ANALYTICS_READ]);
  const isDemoAccount = useIsAppointmentDemoAccount();
  const { isLoadingLocations, locationIds } = useAnalyticsOrgLocations({
    isDemoAccount,
    module: 'APPOINTMENT',
  });

  const { data, isLoading } = useFetchAggregatedAppointmentsData({
    customFilters: {
      EndDate: appendCurrentTimeZone(filters.endDate.format('YYYY-MM-DDT23:59:59.999')),
      LocationID: locationIds || [],
      StartDate: appendCurrentTimeZone(filters.startDate.format('YYYY-MM-DDT00:00:00.000')),
      TimeZone: filters.timeZone,
    },
  });

  const formattedData = useMemo(() => {
    let total = 0;

    const values = Object.values(data.weaveStatusCounts).reduce<Record<string, number>>((acc, value) => {
      Object.entries(value).forEach(([key, count = 0]) => {
        acc[key] = (acc[key] || 0) + count;
        total += count;
      });

      return acc;
    }, {});

    return {
      chartData: {
        groups: Object.keys(values)
          .sort()
          .map((key) => ({
            name: key,
            values: {
              [key]: values[key],
            },
          })),
      },
      total,
    };
  }, [data]);

  useEffect(() => {
    onLoading(isLoading);
  }, [isLoading]);

  return (
    <Chart colors={weaveStatusColors} isLoading={isLoading || isLoadingLocations} labels={weaveStatusLabels}>
      <Chart.Header
        actions={[
          {
            label: t('Show More'),
            omit: !hasAclAccess,
            onClick: () => {
              navigate({ to: current.pathname.replace('dashboard', 'appointment') });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMoreAppointmentAnalytics,
          },
        ]}
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Appointment Status')}
      />
      <Chart.BarChart
        appearance={{
          hideTooltipTitle: true,
          margin: { bottom: 20 },
          showXAxis: true,
        }}
        data={formattedData.chartData}
        formatValue={formatters.value.format}
      />
      <Insights title={t('Total Appointments')} value={formattedData.total} />
    </Chart>
  );
});

AppointmentAnalyticsOnDashboard.displayName = 'AppointmentAnalyticsOnDashboard';
