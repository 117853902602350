import { useEffect } from 'react';
import { css } from '@emotion/react';
import { FallbackRender } from '@sentry/react/types/errorboundary';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { ErrorAccordion } from '@frontend/error-boundary';
import { Trans, useTranslation } from '@frontend/i18n';
import { ErrorPage } from './error-page';
// import Laugh from './kefka-laugh.mp3';

type Props = Parameters<FallbackRender>[0];

export const StackErrorPage = ({ error, eventId }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const loc = useLocation();

  //Once the error occurs, treat the next location change with a full refresh
  useEffect(() => {
    loc.subscribe(() => {
      window.location.reload();
    });
  }, []);

  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
    >
      <ErrorPage
        title={t("There's Been An Error")}
        subTitle={
          <Trans t={t}>
            We ran into an error on the page you're visiting. The error has been reported. If you continue experiencing
            this issue, please call Customer Support at <a href='tel:+18885795668'>+1-888-579-5668</a>.
          </Trans>
        }
        primaryButtonText={t('Try Again')}
        primaryButtonOnClick={() => window.location.reload()}
        secondaryButtonText={t('Go to Home Page')}
        secondaryButtonOnClick={() => navigate({ to: '/home/dashboard' })}
      />
      <ErrorAccordion error={error as Error} eventId={eventId} />
    </section>
  );
};
